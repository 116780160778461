import React, { useState, useEffect } from "react";
import icon_img from "../../images/arrow-bright.svg";
import s_icon from "../../images/sicon.svg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import useTranslation from "../customHooks/translations";
import "./Tabs.css";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import { useSelector } from "react-redux";
import SummaryTable from "./SummaryTable";
import { Modal } from "react-bootstrap";
import Journey1 from "../../images/journey1.svg";
import Journey2 from "../../images/journey2.svg";
import Journey3 from "../../images/journey3.svg";
import Journey4 from "../../images/journey4.svg";
import Journey5 from "../../images/journey5.svg";
import Journey6 from "../../images/journey66.svg";
import useAxios from "../../axiosinstance";
import Button from "../custom/button/Button";
import { yearDates } from "../../utilities/DateFormate";
import FormVisible from "./FormVisible";
export default function CheckIn({ data }) {
  let lang = sessionStorage.getItem("lang_");
  const translation = useTranslation();
  const [key, setKey] = useState("summary");
  const [modelShow1, setModelShow1] = useState(false);
  const [akptId, setAkptId] = useState("");
  const [limit] = useState(100);
  const [offset, setOffset] = useState(10);
  const [dob, setDob] = useState("");
  const [status, setStatus] = useState("");
  const [profile, setProfile] = useState({});
  const [AllData, setData] = useState([]);
  const [shared, setShared] = useState("");
  const [complete, setComplete] = useState("");
  const [pending, setPending] = useState("");
  const [expired, setExpired] = useState("");
  const [incomplete, setIncomplete] = useState("");
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [refs, setRefs] = useState(false);
  const [modalTrue, setModalTrue] = useState(false);
  const [delerteResourceId, setdelerteResourceId] = useState("");
  const [typeForm, setType] = useState("");
  const [formattedDate, setFormattedDate] = useState("");
  let axiosInstance = useAxios();
  let model = localStorage.getItem("model");
 
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (login) {
      setProfile(login.patient);
      setAkptId(login.patient.ak_id);
      setDob(login.patient.dob);
      localStorage.setItem("patientId", login.patient.ak_id)
    }
  }, [login]);
  const handleSelect = (key) => {
    setLoading(false);
    setOffset(0);
    if (key === "summary") {
      //   setshow1(false);
      setKey(key);
      setStatus("");
    } else if (key === "shared") {
      //   setshow1(false);
      setKey(key);
      setStatus(1);
    } else if (key === "completed") {
      setKey(key);
      setStatus(2);
    } else if (key === "pending") {
      setKey(key);
      setStatus(3);
    } else if (key === "expired") {
      setKey(key);
      setStatus(-1);
    } else if (key === "incomplete") {
      setKey(key);
      setStatus(0);
    }
  };
  const getData = async (e) => {
    setLoading(true);
    setData([]);
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/get_summary?ak_id=${akptId}&limit=${limit}&offset=0&status=${e}`
      );
      if (result.status === 200) {
        // console.log(result, "resultResult");
        setLoading(false);
        setData(result.data.data);
        // if (result.data.totalData === 0) {
        //   setLoading(true);
        // }
      }
    } catch (err) {
     
      setLoading(false);
      setData([]);
    } finally {
      console.log("api response finally");
    }
  };

  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/get_summary_count?ak_id=${login.patient.ak_id}`
      );
      if (result.status === 200) {
        localStorage.setItem("incom", result.data.incompleted);
        localStorage.setItem("pend", result.data.pending);
        setShared(result.data.shared);
        setComplete(result.data.completed);
        setPending(result.data.pending);
        setExpired(result.data.expired);
        setIncomplete(result.data.incompleted);
        if (result.data.pending !== 0 || result.data.incompleted !== 0) {
          setModelShow1(true);
          if (model !== "false") {
            localStorage.setItem("model", true);
          }
        } else {
          localStorage.setItem("model", false);
        }
      }
    } catch (err) {
  
    }
  };

  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/patients/get_summary?ak_id=${akptId}&limit=${limit}&offset=${offset}&status=${status}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setData(AllData.concat(resdata));
    }, 500);
  };
  const fetchMoreData = () => {
    addMoreData();
  };

  let pendingss = localStorage.getItem("pend");
  let inccc = localStorage.getItem("incom");


  useEffect(() => {
    if (Number(pendingss) !== 0 || Number(inccc) !== 0) {
      if (akptId !== "") {
        setModelShow1(true);
        getData(status);
      }
    } else {
      if (akptId !== "") {
        getData(status);
      }
    }
  }, [refs, akptId, status, pendingss, inccc]);

  useEffect(() => {
    if (akptId) handleConnectMe();
  }, [refs, akptId, login?.patient.dateFormat]);

  const handleFormOpen = async (e) => {
    // alert(e);
    let url =
      process.env.REACT_APP_FORM_URL +
      "patient-personal-data" +
      `/${window.btoa(akptId)}` +
      `/${window.btoa(e)}`;

    setType(Number(e));
    try {
      const response = await axiosInstance.current.get(
        `extapp/patients/incomplete_forms?ak_id=${akptId}&type=${e}`
      );
      if (response.data.status) {
      
        setdelerteResourceId(response.data.data[0].resource_id);
        setFormattedDate(
          response.data.data[0].created_at
            ? response.data.data[0].created_at
            : "2024-01-13T07:23:55.724Z"
        );
        setModalTrue(true);
      } else {
        setModalTrue(false);
        window.location.href = url;
        // window.open(
        //   process.env.REACT_APP_FORM_URL +
        //     "patient-personal-data" +
        //     `/${window.btoa(akptId)}` +
        //     `/${window.btoa(e)}`,
        //   "_blank"
        // );
      }
    } catch (error) {
      window.location.href = url;
      // window.open(
      //   process.env.REACT_APP_FORM_URL +
      //     "patient-personal-data" +
      //     `/${window.btoa(akptId)}` +
      //     `/${window.btoa(e)}`,
      //   "_blank"
      // );
      setModalTrue(false);
      console.log(error);
    }

    // return

    // window.open(
    //   process.env.REACT_APP_FORM_URL +
    //     "patient-personal-data" +
    //     `/${window.btoa(akptId)}` +
    //     `/${window.btoa(e)}`,
    //   "_blank"
    // );
  };


  const handleCloseShow1 = () => {
    setModelShow1(false);
    localStorage.removeItem("incom");
    localStorage.removeItem("pend");
    localStorage.setItem("model", false);
  };
  const handleCloseShow12 = () => {
    handleSelect('pending')
    setModelShow1(false);
    localStorage.removeItem("incom");
    localStorage.removeItem("pend");
    localStorage.setItem("model", false);
    setTimeout(() => {
      scrollToBottom()
    }, 1200);
  };
  const handleFirst2 = () => {
    setModelShow1(false);
  };
  function scrollToBottom() {
    window.scroll({
      top: 1000,
      left: 0,
      // behavior: 'smooth'
    });
  }


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="">
          <div className="text-center mt-5">
            <h4 className="fw-bolder">
              {translation.Welcome_to_akunah}, {profile.firstname}{" "}
              {profile.lastname}
            </h4>
            <p className="f_500">
              {translation.Get_started_by_completing_your} <br></br>{" "}
              {translation.and_exploring_your_progress}
            </p>
          </div>
          <div className="mt_64 mb-3">
            <h6 className="f_600">
              {translation.Fill_up_your_PROMs_questionnaire}
            </h6>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-4">
              <div
                className="whitebox c_pointer p-4 bordertopH"
                onClick={() => handleFormOpen(4)}
              >
                <img className="icn_img" src={Journey1} alt="icon"></img>
                <label>{translation.shoulder}</label>
                <img
                  className="float-end ms-5 mt-2 arrow-bright"
                  src={icon_img}
                  alt="icon"
                ></img>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-4">
              <div
                className="whitebox c_pointer p-4 bordertopH"
                onClick={() => handleFormOpen(22)}
              >
                <img className="icn_img" src={Journey2} alt="icon"></img>
                <label>{translation.Elbow}</label>
                <img
                  className="float-end ms-5 mt-2 arrow-bright"
                  src={icon_img}
                  alt="icon"
                ></img>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-4">
              <div
                className="whitebox c_pointer p-4 bordertopH"
                onClick={() => handleFormOpen(23)}
              >
                <img className="icn_img" src={Journey3} alt="icon"></img>
                <label>{translation.Hand_And_Wrist}</label>
                <img
                  className="float-end ms-5 mt-2 arrow-bright"
                  src={icon_img}
                  alt="icon"
                ></img>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-4">
              <div
                className="whitebox c_pointer p-4 bordertopH"
                onClick={() => handleFormOpen(6)}
              >
                <img className="icn_img" src={Journey4} alt="icon"></img>
                <label>{translation.Hip}</label>
                <img
                  className="float-end ms-5 mt-2 arrow-bright"
                  src={icon_img}
                  alt="icon"
                ></img>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-4">
              <div
                className="whitebox c_pointer p-4 bordertopH"
                onClick={() =>
                  handleFormOpen(yearDates(profile.dob) >= 13 ? 5 : 20)
                }
              >
                <img className="icn_img" src={Journey5} alt="icon"></img>
                <label>{translation.Knee}</label>
                <img
                  className="float-end ms-5 mt-2 arrow-bright"
                  src={icon_img}
                  alt="icon"
                ></img>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-4">
              <div
                className="whitebox c_pointer p-4 bordertopH"
                onClick={() => handleFormOpen(21)}
              >
                <img className="icn_img" src={Journey6} alt="icon"></img>
                <label>{translation.Foot_And_Ankle}</label>
                <img
                  className="float-end ms-5 mt-2 arrow-bright"
                  src={icon_img}
                  alt="icon"
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-4">
              <div
                className="whitebox c_pointer p-4 bordertopH"
                onClick={() => handleFormOpen(33)}
              >
                <img className="icn_img" src={Journey1} alt="icon"></img>
                <label>{translation.General}</label>
                <img
                  className="float-end ms-5 mt-2 arrow-bright"
                  src={icon_img}
                  alt="icon"
                ></img>
              </div>
            </div>
          </div>

          <div className="panel">
            <div className="panel-heading  pannel_pd pt_20">
              <div className="row">
                <div className="col-sm-6 col-md-9">
                  <h4 className="black">{translation.My_Progress}</h4>
                </div>
              </div>
            </div>
            <div className="panel-body p_25">
              <div className="my_visit_screen home_tab mb_100 p-0">
                <Tabs
                  id="case-list-tabs"
                  activeKey={key}
                  onSelect={handleSelect}
                  // onSelect={(k) => setKey(k)}
                  className="border-0 pe-0"
                >
                  <Tab eventKey="summary" title={translation.summary}>
                    {AllData.length === 0 ? (
                      <>
                        <div className="panel">
                          <div className="panel-body p_25">
                            <div className="no_data">
                              {!loading
                                ? translation.No_data_found
                                : translation.loading + "..."}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <SummaryTable
                        data={AllData}
                        updatePagination={fetchMoreData}
                        status={status}
                        refs={refs}
                        setRefs={setRefs}
                      />
                    )}
                  </Tab>

                  <Tab
                    eventKey="shared"
                    title={
                      <>
                        <span className="circle_gap ms-2">{shared}</span>
                        <span>{translation.shared}</span>
                      </>
                    }
                  >
                    {AllData.length === 0 ? (
                      <>
                        <div className="panel">
                          <div className="panel-body p_25">
                            <div className="no_data">
                              {!loading
                                ? translation.No_data_found
                                : translation.loading + "..."}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <SummaryTable
                        data={AllData}
                        updatePagination={fetchMoreData}
                        status={status}
                        refs={refs}
                        setRefs={setRefs}
                      />
                    )}
                  </Tab>

                  <Tab
                    eventKey="completed"
                    title={
                      <>
                        <span className="circle_gap ms-2">{complete}</span>
                        <span>{translation.Completed}</span>
                      </>
                    }
                  >
                    {AllData.length === 0 ? (
                      <>
                        <div className="panel">
                          <div className="panel-body p_25">
                            <div className="no_data">
                              {!loading
                                ? translation.No_data_found
                                : translation.loading + "..."}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <SummaryTable
                        data={AllData}
                        updatePagination={fetchMoreData}
                        status={"nikhil"}
                        data1={data}
                        refs={refs}
                        setRefs={setRefs}
                      />
                    )}
                  </Tab>

                  <Tab
                    eventKey="pending"
                    title={
                      <>
                        <span className="circle_gap ms-2">{pending}</span>
                        <span>{translation.progress}</span>
                      </>
                    }
                  >
                    {AllData.length === 0 ? (
                      <div className="panel">
                        <div className="panel-body p_25">
                          <div className="no_data">
                            {!loading
                              ? translation.No_data_found
                              : translation.loading + "..."}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <SummaryTable
                        data={AllData}
                        updatePagination={fetchMoreData}
                        status={status}
                        refs={refs}
                        setRefs={setRefs}
                      />
                    )}
                  </Tab>

                  <Tab
                    eventKey="expired"
                    title={
                      <>
                        <span className="circle_gap ms-2">{expired}</span>
                        <span>{translation.Expired}</span>
                      </>
                    }
                  >
                    {AllData.length === 0 ? (
                      <>
                        <div className="panel">
                          <div className="panel-body p_25">
                            <div className="no_data">
                              {!loading
                                ? translation.No_data_found
                                : translation.loading + "..."}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <SummaryTable
                        data={AllData}
                        updatePagination={fetchMoreData}
                        status={status}
                        refs={refs}
                        setRefs={setRefs}
                      />
                    )}
                  </Tab>

                  <Tab
                    eventKey="incomplete"
                    title={
                      <>
                        <span className="circle_gap ms-2">{incomplete}</span>
                        <span>{translation.Incomplete}</span>
                      </>
                    }
                  >
                    {AllData.length === 0 ? (
                      <>
                        <div className="panel">
                          <div className="panel-body p_25">
                            <div className="no_data">
                              {!loading
                                ? translation.No_data_found
                                : translation.loading + "..."}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <SummaryTable
                        data={AllData}
                        updatePagination={fetchMoreData}
                        status={status}
                        refs={refs}
                        setRefs={setRefs}
                      />
                    )}
                  </Tab>

                </Tabs>
              </div>
              <FormVisible
                openModal={modalTrue}
                setModalTrue={setModalTrue}
                setdelerteResourceId={setdelerteResourceId}
                delerteResourceId={delerteResourceId}
                typeForm={typeForm}
                setType={setType}
                refs={refs}
                setRefs={setRefs}
                formattedDate={formattedDate}
              />
            </div>

            <Modal
              show={model === "true" ? true : false}
              // onHide={handleCloseShow1}

              dialogClassName="modal-md patient_notes_popup"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title className="text-center">
                  {translation.You_have_pending_Patient_Scoring_Forms}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="">
                <div className="d-flex flex-row justify-content-center py-3">
                  <div className="text-center  justify-content-center scoring_status_box me-3">
                    <div className="status_title">{translation.Pending}</div>
                    <span className="font_30 ">{pending}</span>
                  </div>
                  <div className="text-center  justify-content-center scoring_status_box">
                    <div className="status_title">{translation.Incomplete}</div>{" "}
                    <span className="font_30 ">{incomplete}</span>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ justifyContent: "space-between" }}>
                <Button
                  onClick={handleCloseShow12}
                  value={translation.Address_Now}
                  buttonStyle="btn_fill scorefoot__btn"
                />
                <Button
                  // show={modelShow2}
                  onClick={handleCloseShow1}
                  value={
                    translation.Address_Later // {translation.Continue}
                  }
                  buttonStyle="btn_success scorefoot__btn"
                />
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
}
