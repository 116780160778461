import React, { useState } from "react";
import { useTable } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import { DateFormate } from "../../utilities/DateFormate";
import viewIcon from "../../images/icon-view.svg";
import VideoPlayer from "../custom/video-player/VideoPlayer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from "react-bootstrap/Modal";
import useTranslation from "../customHooks/translations";
import { Hours_Minutes_Format } from "../../utilities/DateFormate";
import downloadicon from "../../images/download-icon.svg";
import deleteIcon from "../../images/delete-icon.svg";
import { s3UrlLinkFile, s3UrlLink } from "../../utilities/S3Url";
import { DocType, WordCutter2 } from "../../utilities/DocumentType";
import axios from "axios";
import Button from "../custom/button/Button";
import DeleteConfirmation from "../custom/delete-confirmation/DeleteConfirmation";
// let akToken = localStorage.getItem("AkTOKENI");

import useAxios from "../../axiosinstance";
function DocumentTable1({
  data,
  updatePagination,
  DataIdget,
  timezoneD,
  timeFormat,
  setRefress,
}) {
 
  const translation = useTranslation();
  const [show, setShow] = useState(false);
  const [videoLink, setvideoLink] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [fileName, setFileName] = useState("");
  const axiosInstance = useAxios();
  const [Reff, setReff] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: translation.Organization_ID,
        accessor: "msg_type",
      },
      {
        Header: translation.Organization_Name,
        accessor: "message",
      },
    ],
    []
  );

  const { rows } = useTable({
    columns,
    data,
  });

  const WordCutter = (text, maxWords) => {
    const words = text.split(" ");
    const truncatedWords = words.slice(0, maxWords);
    const truncatedText = truncatedWords.join(" ");
    return (
      <span>
        {truncatedText}
        {words.length > 10 ? "..." : " "}
      </span>
    );
  };
  const handleView = (
    file_extension,
    file_path,
    resource_type,
    document_name
  ) => {
    setvideoLink(file_path);
    // setMessage(msg);
    setType(file_extension);
    setShow(true);
    setFileName(document_name);
  };
  const handleCloseShow = () => {
    setShow(false);
  };

  const downloadURI = (url, name) => {
    axios
      .get(s3UrlLink(url, name), {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
     
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch((err) => {
        
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [rowId, setrowId] = useState("");
  const showDeleteModal = (id) => {
    setrowId(id);
    setDisplayConfirmationModal(true);
  };
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rowId}`
      );
      if (result) {
        setReff(!Reff);
        setRefress(!Reff);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("api response finally");
    }
  };
  const submitDelete = () => {
    handleDelByrID();
    hideConfirmationModal();
  };
  // const docs = [{ uri: s3UrlLinkFile(filelinkUrl, fileName), fileType: filetype }];
  return (
    <>
      <div
        className="tableContainer bg-white "
        id="rrpScrollableDivs"
        style={{ height: "500px" }}
      >
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rrpScrollableDivs"
          loader={""}
        >
          <table role="table" className="custom-tbl mt-3">
            <thead>
              <tr role="row">
                <th colSpan={1} role="columnheader" className="col-orgid">
                  {translation.Document_Name}
                  {/* Document Name */}
                </th>

                <th colSpan={1} role="columnheader" className="col-status">
                  {translation.Date_Time}

                  {/* type */}
                </th>

                {/* <th colSpan={1} role="columnheader" className="col-status"> */}
                {/* {translation.Type} */}
                {/* Access Type */}
                {/* </th> */}

                <th colSpan={1} role="columnheader" className="col-status" style={{paddingLeft:"20px"}}>
                  {translation.Action}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr role="row">
                  <td role="cell" className="col-orgid" data-bs-toggle="tooltip"
                      title={row.file_name}>
                         
                         {row.file_name.length > 20 ? `${row.file_name.substring(0, 20)}...` : row.file_name}
                  </td>
                  <td className="col">
                    {row.created_at}{" "}
                    {Hours_Minutes_Format(
                      row.time,
                      timeFormat === "1" ? true : false,
                      timezoneD
                    )}
                  </td>

                  <td role="cell" className="col-orgname">
                    {row.file_extension === "hevc" ||
                    row.file_extension == "mov" ||
                    row.file_extension == "doc" ||
                    row.file_extension == "xlsx" ? (
                      ""
                    ) : (
                      <img
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={translation.View}
                        className={`action_icons c_pointer ms-3`}
                        src={viewIcon}
                        alt="Icon"
                        onClick={() =>
                          handleView(
                            row.file_extension,
                            row.file_path,
                            row.resource_type,
                            row.file_name
                          )
                        }
                      />
                    )}

                    <img
                      src={downloadicon}
                      onClick={() => {
                        downloadURI(row.file_path, row.file_name);
                      }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={translation.download}
                      className={`action_icons c_pointer ms-3`}
                    ></img>

                    <img
                      src={deleteIcon}
                      alt="Icon"
                      className={`action_icons c_pointer ms-3`}
                      onClick={() => showDeleteModal(row.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      <Modal
        show={show}
        onHide={handleCloseShow}
        dialogClassName="modal-lg patient_notes_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{translation.View}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          {/* {videoLink} */}
          {/* <div className="msg-box">
            <span className=""> {message}</span>
          </div> */}
          {/* {type === "mp4" ? (
            <VideoPlayer url={s3UrlLinkFile(videoLink, fileName)} />
          ) : ( */}
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            prefetchMethod="GET"
            documents={[
              { uri: s3UrlLinkFile(videoLink, fileName), fileType: type },
            ]}
            // requestHeaders={headers}
            config={{
              // loadingRenderer: {
              //   overrideComponent: MyLoadingRenderer,
              // },
              header: {
                disableHeader: false,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
            style={{ height: "75vh" }}
          />
          {/* )} */}
        </Modal.Body>
        {/* <Modal.Footer style={{ justifyContent: "flex-start" }}>
                <Button
                  onClick={handleContinue}
                  value="Continue"
                  buttonStyle="btn_fill mr_20 "
                />
              </Modal.Footer> */}
      </Modal>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        // message={notetitle}
        // type={rowType}
        // id={rowId}
      />
    </>
  );
}

export default DocumentTable1;
