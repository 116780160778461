import React, {useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../images/back.png";
import nextWhiteIcon from "../../images/next-white.png";
import nextIcon from "../../images/next.png";
import InputField from "../custom/inputfield/InputField";
import Checkbox from "../custom/checkbox/Checkbox";
import Dropdown from "../custom/dropdown/Dropdown";
import { Validators } from "../../utilities/Validator";
// import { validateCheckbox } from "../../utilities/Validator";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import { useRef } from "react";
import useTranslation from "../customHooks/translations"

function MyProfileStep4() {
  const translation = useTranslation()
  const [isLoading, setIsLoading] = useState(true);
  const [secQs1, setSecQs1] = useState("");
  const [secAns1, setSecAns1] = useState("");
  const [secQs2, setSecQs2] = useState("");
  const [secAns2, setSecAns2] = useState("");
  const [acceptance, setAcceptance] = useState(false);
  const [error, setError] = useState(false);

  const secQs1Ref = useRef();
  const secAns1Ref = useRef();
  const secQs2Ref = useRef();
  const secAns2Ref = useRef();
  const acceptanceRef = useRef();

  const navigate = useNavigate();

  const handleSecQs1 = (e) => {
    setSecQs1(e);
  };
  const handleSecAns1 = (e) => {
    setSecAns1(e);
  };
  const handleSecQs2 = (e) => {
    setSecQs2(e);
  };
  const handleSecAns2 = (e) => {
    setSecAns2(e);
  };
  const handleAcceptance =(e)=>{
    setAcceptance(e)
  }

  const continueRedirect = (e) => {
    if (!secQs1) {
      secQs1Ref.current.errUpdate();
    }
    if (!secQs2) {
      secQs2Ref.current.errUpdate();
    }
    if (!secAns1) {
      secAns1Ref.current.errUpdate();
    }
    if (!secAns2) {
      secAns2Ref.current.errUpdate();
    }
    if (!secAns2) {
      secAns2Ref.current.errUpdate();
    }
    if (!acceptance) {
      acceptanceRef.current.errUpdate();
    }
    if (!secQs1 || !secQs2 || !secAns1 || !secAns2 || !acceptance) {


    }else if(secQs1 === secQs2){
      setError(true)
    }else {
      navigate("/");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);



  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="myPprofile_screen mb_100">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
            <h4 className="ph_title">{translation.myProfile}</h4>
            </div>
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <Dropdown
                    data={[
                      {
                        value: "bornCityQ1",
                        label: translation.in_which_city_were_you_born,
                      },
                      {
                        value: "petNameQ1",
                        label: translation.what_is_your_favorite_pet_name,
                      },
                      {
                        value: "maidenNameQ1",
                        label: translation.what_is_your_mother_maiden_name,
                      },
                      {
                        value: "highSchoolQ1",
                        label: translation.what_is_your_high_school_name,
                      },
                      {
                        value: "firstSchoolQ1",
                        label: translation.what_was_the_name_of_first_school,
                      },
                    ]}
                    label={translation.security_question1}
                    styleClass=" br_10"
                    value={secQs1}
                    ref={secQs1Ref}
                    placeholder={translation.select_your_first_question}
                    onChange={handleSecQs1}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.answer1}
                    value={secAns1}
                    ref={secAns1Ref}
                    type="text"
                    placeholder={translation.answer1}
                    onChange={handleSecAns1}
                    styleClass="br_10 "
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <Dropdown
                    data={[
                      {
                        value: "bornCityQ1",
                        label: translation.in_which_city_were_you_born,
                      },
                      {
                        value: "petNameQ1",
                        label: translation.what_is_your_favorite_pet_name,
                      },
                      {
                        value: "maidenNameQ1",
                        label: translation.what_is_your_mother_maiden_name,
                      },
                      {
                        value: "highSchoolQ1",
                        label: translation.what_is_your_high_school_name,
                      },
                      {
                        value: "firstSchoolQ1",
                        label: translation.what_was_the_name_of_first_school,
                      },
                    ]}
                    label={translation.security_question2}
                    styleClass=" br_10"
                    value={secQs2}
                    ref={secQs2Ref}
                    placeholder={translation.select_your_second_question}
                    onChange={handleSecQs2}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.answer2}
                    value={secAns2}
                    ref={secAns2Ref}
                    type="text"
                    placeholder={translation.answer2}
                    onChange={handleSecAns2}
                    styleClass="br_10 "
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10 col-md-10 mt_20 mb_20">
                  <div
                    className={`form-group custom_check_box tick f_400 large`}
                  >

                    <Checkbox
                      label={translation.I_agree}
                      selected={acceptance}
                      ref={acceptanceRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: translation.This_field_is_required,
                        },
                      ]}
                      onChange={handleAcceptance}
                      styleClass="custom_check_box"
                      id="agree_details"
                      link={  (  <a
                        target="_blank"
                        href="https://akunah.com/privacy-policy"
                        rel="noopener noreferrer"
                        className="pt_privaa_btn"
                      >
                        {translation.Privacy_Policy}                        
                      </a>)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {error && (
            <div className="row  p_25 ">
              <div>
                <p className="text-danger ">{translation.please_choose_different_question}</p>
              </div>
            </div>

            )}
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6">
                <Link to="/my-profile3" className="assm_back_btn">
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </Link>
              </div>
              <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MyProfileStep4;
