import React, { useState, useEffect } from "react";
import PatientProfile from "../profile/Profile";
import FormTimeline from "../../components/forms/FormTimeline";
import useAxios from "../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import RangeMotionTable from "../range-of-motion/RangeMotionTable";
import ScoreChart from "../score-chart/ScoreChart";
import ScoreCircleProgress from "../score-circle-progress/ScoreCircleProgress";
import ScoreCircleProgressEq5 from "../score-circle-progress/ScoreCircleProgressEq5";
import ScoreChartSingles from "../score-chart/ScoreChartSingles";
import ScoreProgressLine from "../../components/score-progress-line/ScoreProgressLine";
import { UserDateFormat } from "../../utilities/DateFormate";
import ScoreWidget from "../score-widget/ScoreWidget";
import "./PatientDetails.css";
import ResultCircleProgress from "../custom/result-circle-progress/ResultCircleProgress";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
// import ScoreWidget from '../score-widget/ScoreWidget'
import breadcrumbStyles from "./DocumentInnerBreadcrumb.module.css";
import { useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";

const ScoreWidgets = () => {
  const translation=useTranslation();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  let akToken = localStorage.getItem("AkTOKENI");
  const [data, setDatas] = useState([]);
  const [load, setLoad] = useState(false);
  const [scoringData, setScoringData] = useState([]);
  const navigate = useNavigate();
  // const [scoringDataGraph, setScoringDataGraph] = useState([]);
  // const [scoringDataCircle, setScoringDataCircle] = useState([]);

  const [status, setStatus] = useState(false);

  // const [typeOfForm] = useState("");

  const login = useSelector((state) => state.loginPatient.arr[0]);
  const queryParams = new URLSearchParams(window.location.search);

  const formType = queryParams.get("formType");

  // const [, setdefaultOrg] = useState("");
  // const [, setdefaultType] = useState("");
  // const [, setAllData] = useState("");
  
  // console.log(scoringData, "scoringDatascoringData");

  const options = {
    spanGaps: true,
    plugins: {
      legend: {
        display: false,
        align: "end",
      },
    },
    scales: {
      x: {
        display: true,
      },
      "left-y-axis": {
        type: "linear",
        position: "left",
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
      "right-y-axis": {
        type: "linear",
        position: "left",
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };
  useEffect(() => {
    const getScore = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/patients/patient_graph?ak_id=${akToken}&type=${window.atob(
            formType
          )}`
        );
        if (response) {
          setStatus(response.data.initial)
          setScoringData(response.data.scoringDataGraph);
          setIsLoading(true);
        }
      } catch (err) {
        setIsLoading(false);
      } finally {
        
      }
    };
    getScore();
  }, []);
  const getData = async (id) => {
    if (id) {
      try {
        const response = await axiosInstance.current.get(
          `/extapp/patients/scoringLists/${akToken}/${id}`
        );
        setDatas(response.data.data);
        setLoad(true);
      } catch (error) {
        setLoad(false);
      }
    } else {
      setLoad(false);
    }
  };
  const handleClickHome = () => {
    navigate("/my-journey")
  }
  
  return (
    <>


      {!isLoading ? (
        <LoadingSpinner />
      ) : (
        <>

          <div className="">
            <div className="row mb-3">
              <div className="col-md-6 col-sm-6 ">
                <ul className={`${breadcrumbStyles.breadcrumb} mt-4`}>
                  <li className="c_pointer" onClick={() => handleClickHome()}>{translation.myJourney}</li>
                  <li className="c_pointer f_600" >{translation.Patient_scores}</li>
                </ul>
              </div>

              {/* <div className="col-md-6 col-sm-6">
            <div className="">
              <SmallProfile/>
            </div>
          </div> */}

            </div>
          </div>
          <div className={`patient_details mb_100`}>

            <div className="row justify-content-end">
              <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-4">
                <PatientProfile />
                <FormTimeline resID={getData} />
              </div>

              <div className={load ? `col-xxl-6 col-lg-5 col-sm-8` : `col-xxl-9 col-lg-8 col-md-8 col-sm-8`}>
                {!status && (
                  <div className="row">
                    {scoringData?.map((row) => (
                      <div className="col-md-12 col-sm-12 rp_100">
                        <ScoreChart
                          title={row.title}
                          options={options}
                          initialRight={
                            row.initialRight === -1 ? "" : row.initialRight
                          }
                          initialLeft={
                            row.initialLeft === -1 ? "" : row.initialLeft
                          }
                          recentRight={
                            row.recentRight === -1 ? "" : row.recentRight
                          }
                          recentLeft={row.recentLeft === -1 ? "" : row.recentLeft}
                          date1={UserDateFormat(
                            row.initialDate,
                            login.ing_date_formate,
                            login.ing_timezone
                          )}
                          date2={UserDateFormat(
                            row.recentDate,
                            login.ing_date_formate,
                            login.ing_timezone
                          )}
                          dates={row.dates}
                          inital={row.leftScores}
                          recent={row.rightScores}
                        />
                      </div>
                    ))}
                    {/* {scoringDataCircle.map((row) => (
                    <div className="col-md-6 col-sm-6 rp_100">
                      <ScoreCircleProgress
                        single={[]}
                        side={row.side}
                        title={row.title}
                        initialRight={
                          row.initialRight === -1 ? "" : row.initialRight
                        }
                        initialLeft={
                          row.initialLeft === -1 ? "" : row.initialLeft
                        }
                        recentRight={
                          row.recentRight === -1 ? "" : row.recentRight
                        }
                        recentLeft={row.recentLeft === -1 ? "" : row.recentLeft}
                      />
                    </div>
                  ))} */}
                  </div>
                )}

                {status && (
                  <div className="row">
                    {scoringData.map ((row) => (
                      <div className="col-sm-12">
                        <ScoreCircleProgress
                          single={[]}
                          side={row.side}
                          type={"initial"}
                          title={row.title}
                          initialRight={
                            row.initialRight === -1 ? "" : row.initialRight
                          }
                          initialLeft={
                            row.initialLeft === -1 ? "" : row.initialLeft
                          }
                          recentRight="0"
                          recentLeft="0"
                          min_score={row.min_score}
                          total_score={row.total_score}
                          reversed={row.reversed}
                        />
                      </div>
                    ))}
                    {/* {scoringData.map((row) => (
                  <div className="col-md-6 col-sm-6 rp_100">
                    <ScoreCircleProgress
                      single={[]}
                      side={row.side}
                      type={"initial"}
                      title={row.title}
                      initialRight={
                        row.initialRight === -1 ? "" : row.initialRight
                      }
                      initialLeft={
                        row.initialLeft === -1 ? "" : row.initialLeft
                      }
                      recentRight="0"
                      recentLeft="0"
                      min_score={row.min_score}
                      total_score={row.total_score}
                      reversed={row.reversed}
                    />
                  </div>
                ))} */}

                    {/* {scoringData.map((row) => {
                    if (!row.eq5d5l) {
                      return (
                        <div className="col-md-6 col-sm-6 rp_100">
                          <ScoreCircleProgress
                            single={Array.isArray(row.scores) ? row.scores : []}
                            side={row.side}
                            type={"initial"}
                            title={row.title}
                            initialRight={
                              row.initialRight === -1 ? "" : row.initialRight
                            }
                            initialLeft={
                              row.initialLeft === -1 ? "" : row.initialLeft
                            }
                            recentRight="0"
                            recentLeft="0"
                            min_score={row.min_score}
                            total_score={row.total_score}
                            reversed={row.reversed}
                          />
                        </div>
                      );
                    } else {
                       
                    }
                  })} */}
                  </div>
                )}
              </div>

              <div className={load ? `col-xxl-3 col-lg-3 col-sm-8 ` : "d-none"}>
                <div className="row justify-content-center">
                  {!load ? (
                    <p>Loading...</p>
                  ) : (
                    <>
                      {data?.filter((item)=>(item.title!=="Overall Health Perception")).map((row) => (
                        <>
                          <div className={""}>
                            <div className="box_d bg-white">
                              <ResultCircleProgress
                                total={row.total_score}
                                title={row.title}
                                arm={row.evaluted_value}
                                value={Number(row.score)}
                                text={Number(row.score)}
                                // value={row.score}
                                date={row.date}
                                color={row.path_color}
                                reverse={row.reversed}
                                min_score={row.min_score}
                                resource_id={row.resource_id}
                                type={row.type_id}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>

      )}
    </>
  );
};

export default ScoreWidgets;
