import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import actionStyles from "./TableActionItems.module.css";
import DeleteConfirmation from "../delete-confirmation/DeleteConfirmation";
import toggleIcon from "../../../images/dots-blue.svg";
import viewIcon from "../../../images/eye-icon.svg";
import editIcon from "../../../images/edit-icon.svg";
import checkIcon from "../../../images/check-icon.svg";
import deleteIcon from "../../../images/delete-icon.svg";
import navigateIcon from "../../../images/right-arrow-blue.svg";
import useTranslation from "../../customHooks/translations.js";


const TableActionItems = ({
  rowData,
  styleClass,
  onChange,
  value,
  viewAction,
  editAction,
  feedbackAction,
  deleteAction,
  arrowAction,
  rowName,
  rowId,
  rowType,
}) => {
  // Set up some additional local state
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const translation = useTranslation();
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  // Handle the displaying of the modal based on type and id
  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item based on type
  const submitDelete = () => {
    if (rowType === "patients") {
      // api code goes here
     
    }
    setDisplayConfirmationModal(false);
  };
  return (
    <>
      <div
        className={`action_large_dev custom_sort_dropdown ${actionStyles.action_large_dev}`}
      >
        <ul>
          {viewAction === "true" ? (
            <li>
              <Link to={`/${rowData.view_url}`}>
                <img className={`action_icons`} src={viewIcon} alt="Icon" />
              </Link>
            </li>
          ) : ""}
          {editAction === "true" ? (
            <li>
              <Link to={`/${rowData.edit_url}`}>
                <img className={`action_icons`} src={editIcon} alt="Icon" />
              </Link>
            </li>
          ) : ""}
          {feedbackAction === "true" ? (
            <li>
              <Link to={`/${rowData.mark_url}`}>
                <img className={`action_icons`} src={checkIcon} alt="Icon" />
              </Link>
            </li>
          ) : ""}
          {deleteAction === "true" ? (
            <li>
              <span onClick={() => showDeleteModal("vegetable", 1)}>
                <img className={`action_icons`} src={deleteIcon} alt="Icon" />
              </span>
            </li>
          ) : ""}
          {arrowAction === "true" ? (
            <li className={`${actionStyles.action_arrow}`}>
              <Link to={`/${rowData.view_url}`}>
                <img className={`action_icons`} src={navigateIcon} alt="Icon" />
              </Link>
            </li>
          ) : ""}
        </ul>
      </div>
      <div
        className={`action_small_dev text-end custom_sort_dropdown ${actionStyles.action_small_dev}`}
      >
        <Dropdown className={`custom_dropdown ${actionStyles.drop_down}`}>
          <Dropdown.Toggle
            className={actionStyles.dropdown_button}
            variant="default"
            id="dropdown-basic"
          >
            <span>
              <img
                className={`toggle_dots_img`}
                src={toggleIcon}
                alt="toggle"
              />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {viewAction === "true" ? (
              <Dropdown.Item>
                <Link to={`/${rowData.view_url}`}>
                  <img className={`action_icons`} src={viewIcon} alt="Icon" />
                  {translation.View}
                </Link>
              </Dropdown.Item>
            ) : ""}
            {editAction === "true" ? (
              <Dropdown.Item>
                <Link to={`/${rowData.edit_url}`}>
                  <img className={`action_icons`} src={editIcon} alt="Icon" />
                  {translation.Edit}
                </Link>
              </Dropdown.Item>
            ) : ""}
            {feedbackAction === "true" ? (
              <Dropdown.Item>
                <Link to="/">
                  <img className={`action_icons`} src={checkIcon} alt="Icon" />
                  {translation.Feedback}
                </Link>
              </Dropdown.Item>
            ) : ""}
            {deleteAction === "true" ? (
              <Dropdown.Item>
                <span onClick={() => showDeleteModal("vegetable", 1)}>
                  <img className={`action_icons`} src={deleteIcon} alt="Icon" />
                  {translation.Delete}
                </span>
              </Dropdown.Item>
            ) : ""}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        message={rowName}
        type={rowType}
        id={rowId}
      />
    </>
  );
};

TableActionItems.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  styleClass: PropTypes.string,
  onChange: PropTypes.func,
};

TableActionItems.defaultProps = {
  value: "",
  label: "",
  styleClass: "",
  placeholder: "",
  rowData: "",
  viewAction: "",
  editAction: "",
  feedbackAction: "",
  deleteAction: "",
  arrowAction: "",
  rowName: "",
  rowId: "",
  rowType: "",
};

export default TableActionItems;
