import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../custom/dropdown/Dropdown";
import Button from "../custom/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { Validators } from "../../utilities/Validator";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useTranslation from "../customHooks/translations";
import Message from "../custom/toster/Message";
import useAxios from "../../axiosinstance";
import plus from "../../images/user_demo.jpg";
import add from "../../images/adduser.svg";
import { loginData, loginVisitsData } from "../../redux/actions/index";
import "./DoctorMap.css";
import { s3UrlLink } from "../../utilities/S3Url";
import LoadingSpinnerText from "../custom/loader/LoadingSpinnerText";
import { fetchUserIdSuccess } from "../../redux/actions/index";
function DoctorMap() {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const login = useSelector((state) => state.userDetails.user);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [doctor, setDoctor] = useState("");
  const navigate = useNavigate();

  const options = login.Data.map((item) => {
    return {
      value: item.akdcid,
      label: item.name + " (" + item.akdcid + ")",
      image: item.ixmxg,
      name: item.sname,
    };
  });
  // console.log(options)
  // const handleDoctor = (e) => {
  //   setDoctor(e);
  // };

  const redirectToHome = () => {
    navigate("/", { replace: true });
    // window.location.reload(false);
  };

  // useEffect(() => {
  const handleConnectMe = async (patientId) => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getDetailsOfPatient?patient_id=${patientId}&use_for=patient`
      );
      if (result.status === 200) {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          dispatch(loginVisitsData(result.data));
          sessionStorage.setItem(
            "lang_",
            result.data.data[0].patient.user_language
          );
          sessionStorage.setItem(
            "isConsent",
            result.data.data[0].patientInfo.isConsent
          );
          localStorage.setItem("AkTOKENI", patientId);
          redirectToHome();
        }
      }
    } catch (err) {
      setMessage(err.message || "Unexpected Error!");
      // console.log(err.message, 'error');
      setMessageType("error");
      setTimeout(() => {
        setMessage(false);
      }, [3000]);
    } finally {
      console.log("api response finally");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (doctor.trim().length === 0) {
 
      return;
    }
    handleConnectMe();
  };

  const continueRedirect = () => {
    if (login.count >= 5) {
      setMessage(translation.error_max_profile_limit);
      setMessageType("error");
      setTimeout(() => {
        setMessage(false);
      }, 2000);
    } else {
      navigate("/patient-registration");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  let lang = sessionStorage.getItem("lang_");

  useEffect(() => {
    const getData = async () => {

      try {
        const response = await axiosInstance.current.post(
          "extapp/patients/pat_signin"
        );
        dispatch(fetchUserIdSuccess(response.data));
      } catch (error) {
        
        // console.log(error.message);
      }
    };
    getData();

      getData()
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingSpinnerText />
      ) : (
        // <div className="doctor_roles_map_screen">
        //   <div className="panel">
        //     {message && <Message message={message} type={messageType} />}

        //     <div className="container">

        //       <div className="row">
        //         <div className="col-md-12 col-12">
        //           <h2 className="f_600 f_24 mt_40 mb_60 text-center">
        //             {translation.List_of_my_profile}
        //           </h2>
        //         </div>
        //       </div>
        //       <div className="row mt_64 justify-content-center">
        //         <div className="col-md-2 col-2">
        //           <div className="profile_box">
        //             <a>
        //               <div className="profile-img">
        //                 <img className="img-fluid" src="https://img.freepik.com/free-psd/3d-rendering-bear-emoji-icon_23-2150339711.jpg?w=826&t=st=1682847149~exp=1682847749~hmac=f6a80f812d66eb0d6884222c5c22aff201cb379fd408c731638686998d9ac6db" alt=""></img>
        //               </div>
        //               <div className="profile_heading">
        //                 <p>P1</p>
        //               </div>
        //             </a>
        //           </div>
        //         </div>
        //         <div className="col-md-2 col-2">
        //           <div className="profile_box">
        //             <a>
        //               <div className="profile-img">
        //                 <img className="img-fluid" src="https://img.freepik.com/free-psd/3d-rendering-bear-emoji-icon_23-2150339711.jpg?w=826&t=st=1682847149~exp=1682847749~hmac=f6a80f812d66eb0d6884222c5c22aff201cb379fd408c731638686998d9ac6db" alt=""></img>
        //               </div>
        //               <div className="profile_heading">
        //                 <p>P1</p>
        //               </div>
        //             </a>
        //           </div>
        //         </div>
        //         <div className="col-md-2 col-2">
        //           <div className="profile_box">
        //             <a>
        //               <div className="profile-img">
        //                 <img className="img-fluid" src="https://img.freepik.com/free-psd/3d-rendering-bear-emoji-icon_23-2150339711.jpg?w=826&t=st=1682847149~exp=1682847749~hmac=f6a80f812d66eb0d6884222c5c22aff201cb379fd408c731638686998d9ac6db" alt=""></img>
        //               </div>
        //               <div className="profile_heading">
        //                 <p>{translation.Create_new_patient}</p>
        //               </div>
        //             </a>
        //           </div>
        //         </div>

        //       </div>
        //     </div>

        //     <form>
        //       {/* <div className="panel-body p_25">
        //         <h2 className="f_600 f_24 mt_40 mb_60 text-center">
        //           {translation.List_of_my_profile}
        //         </h2>

        //         <div className="row">
        //           <div className="col-sm-12 col-md-12">
        //             <div className="mt-5">
        //               <Dropdown
        //                 data={options}
        //                 label={translation.Select_Existing_Profile}
        //                 styleClass="mxw_100 br_10"
        //                 value={doctor}
        //                 placeholder={translation.Select}
        //                 onChange={handleDoctor}
        //                 validators={[
        //                   {
        //                     check: Validators.required,
        //                     message: translation.This_field_is_required,
        //                   },
        //                 ]}
        //               />
        //             </div>
        //           </div>
        //         </div>
        //       </div> */}
        //       {/* <div className="row mb_30 button_box ml_15 mr_15 p-0">
        //         <div className="col-md-6 col-sm-6">
        //           <Button
        //             onClick={handleSubmit}
        //             value={translation.Get_In}
        //             width="190px"
        //             height="50px"
        //             buttonStyle="btn_fill"
        //           />
        //         </div>
        //         <div className="col-md-6 col-sm-6 text-end">
        //           <Button
        //             onClick={continueRedirect}
        //             value={translation.Create_new_patient}
        //             width="190px"
        //             height="50px"
        //             buttonStyle="btn_fill"
        //           />
        //         </div>
        //       </div> */}
        //       {/* {error && (
        //         <div className="row mb_30  ml_15 mr_15">
        //           <div className="col-12">
        //             <div class="alert alert-danger ">
        //               limit exceeded
        //             </div>
        //           </div>
        //         </div>
        //       )} */}
        //     </form>
        //   </div>
        // </div>
        <>
          <div className="" dir={lang === "ara" ? "rtl" : "ltr"}>
            <div className="container">
              <div className="doctotmt">
              {message && <Message message={message} type={messageType} />}

              <h2
                className="f_600 f_24 mt_40 mb_60 text-center"
                style={{ marginBottom: "50px" }}
              >
                {translation.List_of_my_profile}
              </h2>

              <div className=" h-screen flex flex-col items-center justify-content-center d-flex ">
                <div className="flex flex-row flex-wrap gap-5 mt-8 d-flex justify-content-center">
                  {options.map((res) => (
                    <div
                      className="flex flex-col items-center group gap-2  patient_imgS c_pointer"
                      onClick={() => handleConnectMe(res.value)}
                    >
                      {res.image ? (
                        <img
                          className="rounded border-2 border-transparent group-hover:border-2 group-hover:border-gray-300 object-fit-scale patient_imgSS "
                          // style={{ "max-width": "100%", height: "170px" }}
                          width="150"
                          height="150"
                          src={s3UrlLink(res.image)}
                        />
                      ) : (
                        <img
                          className="rounded border-2 border-transparent group-hover:border-2 group-hover:border-gray-300 object-fit-scale patient_imgSS"
                          width="150"
                          height="150"
                          src={plus}
                        />
                      )}
                      <p
                        className="text-gray-500 group-hover:text-gray-300 text-center mt-3 "
                        style={{
                          width: "150px",
                          color: "#3f2783",
                          fontWeight: 500,
                        }}
                      >
                        {" "}
                        {res.label}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="row justify-content-center">
                {/* <div className="col-12 d-flex"> */}
                {/* {options.map((res) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                  <div
                    className="card-border"
                    onClick={() => handleConnectMe(res.value)}
                  >
                    {res.image ? (
                      <img
                        className="object-fit-contain"
                        style={{ "max-width": "100%", height: "184px" }}
                        src={s3UrlLink(res.image)}
                        alt=""
                      ></img>
                    ) : (
                      <div className="patient-map-name">
                        <p style={{ "font-size": "80px" }}>{res.name}</p>
                      </div>
                    )}
                  </div>
                  <span className="ms-1 ">{res.label}</span>
                </div>
              ))} */}

{login.count<5 &&(
                <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                  <div
                    className=" c_pointer patient_imgS"
                    onClick={() => continueRedirect()}
                  >
                    <img
                      className="patient_imgSS"
                      width="150"
                      height="150"
                      src={add}
                    />
                    <p
                      className="text-gray-500 group-hover:text-gray-300 mt-2"
                      style={{ color: "#3f2783", fontWeight: 500 }}
                    >
                      {" "}
                      {translation.Create_new_patient}
                    </p>
                  </div>
                  {/* <span>{translation.Create_new_patient}</span> */}
                </div>
)}
                {/* </div> */}
              </div>
              {/* <div class="add-btn row justify-content-center">
              <div className="col-sm-2 card-border">
                <img className="" src={plus} style={{ height: "100%" }} alt=""></img>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DoctorMap;
