export default{
  "patient_shoulder_pain":"Douleur",
  "patient_shoulder_pain_q1":"Ressentez-vous de la douleur dans votre épaule ? ",
  "patient_shoulder_pain_q1_a1":"Non",
  "patient_shoulder_pain_q1_a2":"Oui",
  "patient_shoulder_pain_q2":"Sur une échelle de 0 à 15, quelle est l'intensité de douleur maximale que vous avez ressenti dans votre épaule pendant des activités normales au cours des dernières 24 heures ? (0 signifie pas de douleur, 15 une douleur intolérable)",
  "patient_shoulder_pain_q2_a1":"Douleur intolérable",
  "patient_shoulder_pain_q2_a2":"Pas de douleur",
  "patient_shoulder_pain_q3":"Votre sommeil est-il perturbé par votre épaule ? ",
  "patient_shoulder_pain_q3_a1":"Sommeil non-perturbé",
  "patient_shoulder_pain_q3_a2":"Perturbations occasionnelles",
  "patient_shoulder_pain_q3_a3":"Perturbations toutes les nuits",
  "patient_shoulder_pain_q4":"Comment évaluez-vous votre douleur aujourd'hui ? ",
  "patient_shoulder_pain_q5":"Etes-vous actuellement fumeur ? ",
  "patient_shoulder_pain_q5_a1":"Non",
  "patient_shoulder_pain_q5_a2":"Oui",
  "patient_shoulder_pain_q5_a3":"Ancien fumeur",
  "patient_shoulder_pain_q5_a2_q1":"Combien de cigarettes fumez-vous par jour ? ",
  "patient_shoulder_pain_q5_a3_q1":"Quand avez-vous arrêté de fumer (en années) ? ",
  "patient_shoulder_pain_q5_a3_q2":"Pendant combien d'années avez-vous fumé ? ",
  "patient_shoulder_pain_q6":"Prenez-vous des analgésiques (ex paracétamol etc) ? ",
  "patient_shoulder_pain_q6_a1":"Non",
  "patient_shoulder_pain_q6_a2":"Oui",
  "patient_shoulder_pain_q6_a2_q1":"Prenez-vous des analgésiques forts (ex codéine, tramadol, morphine etc) ? ",
  "patient_shoulder_pain_q6_a2_q1_a1":"Non",
  "patient_shoulder_pain_q6_a2_q1_a2":"Oui",
  "patient_shoulder_pain_q6_a2_q2":"Combien de cachets prenez-vous par jour en moyenne ? ",
  "patient_shoulder_pain_q7":"Quelle affirmation décrit le mieux votre douleur ? ",
  "patient_shoulder_pain_q7_a1":"Aucune",
  "patient_shoulder_pain_q7_a2":"Occasionnelle et légère",
  "patient_shoulder_pain_q7_a3":"Présente pendant des activités intenses ou particulières, aspirine utilisé occasionnellement",
  "patient_shoulder_pain_q7_a4":"Présente pendant des activités légères, peu ou aucune au repos, aspirine utilisé fréquemment",
  "patient_shoulder_pain_q7_a5":"Toujours présente, tolérable, médication forte requise occasionnellement",
  "patient_shoulder_pain_q7_a6":"Toujours présente, intolérable, médication forte requise fréquemment",
  "patient_shoulder_pain_q8":"Ressentez-vous des fourmillements ou des picotements le long de votre bras ? ",
  "patient_shoulder_pain_q8_a1":"Non",
  "patient_shoulder_pain_q8_a2":"Oui",
  "patient_shoulder_pain_q9":"Indiquez où se situe la douleur ",
  "patient_shoulder_pain_q9_a1":"Avant",
  "patient_shoulder_pain_q9_a2":"Arrière",
  "patient_shoulder_function":"Signes fonctionnels",
  "patient_shoulder_function_q1":"Quelle affirmation décrit le mieux la fonction de votre épaule affectée ? ",
  "patient_shoulder_function_q1_a1":"Normale",
  "patient_shoulder_function_q1_a2":"Restrictions légères seulement, peut travailler au dessus du niveau de l'épaule",
  "patient_shoulder_function_q1_a3":"La plupart des tâches ménagères, faire les courses, conduire, se coiffer, s'habiller/se déshabiller",
  "patient_shoulder_function_q1_a4":"Peut faire des tâches ménagères légères et la plupart des activités quotidiennes",
  "patient_shoulder_function_q1_a5":"Seulement les activités légères sont possibles",
  "patient_shoulder_function_q1_a6":"Incapable d'utiliser le membre ",
  "patient_shoulder_function_instruction":"Sélectionnez le nombre qui indique votre capacité à effectuer les activités suivantes",
  "patient_shoulder_function_instruction_0":"Incapable",
  "patient_shoulder_function_instruction_1":"Très difficile",
  "patient_shoulder_function_instruction_2":"Quelque peu difficile",
  "patient_shoulder_function_instruction_3":"Pas difficile",
  "patient_shoulder_function_note":"Note : Veuillez répondre aux questions suivantes en relation avec la fonction de votre épaule. ",
  "patient_shoulder_function_q2":"Mettre un manteau / une chemise",
  "patient_shoulder_function_q2_a0":"Incapable",
  "patient_shoulder_function_q2_a1":"Très difficile",
  "patient_shoulder_function_q2_a2":"Quelque peu difficile",
  "patient_shoulder_function_q2_a3":"Pas difficile",
  "patient_shoulder_function_q3":"Dormir sur le côté douloureux ou affecté ",
  "patient_shoulder_function_q3_a0":"Incapable",
  "patient_shoulder_function_q3_a1":"Très difficile",
  "patient_shoulder_function_q3_a2":"Quelque peu difficile",
  "patient_shoulder_function_q3_a3":"Pas difficile",
  "patient_shoulder_function_q4":"Se laver le dos/attacher son soutien-gorge",
  "patient_shoulder_function_q4_a0":"Incapable",
  "patient_shoulder_function_q4_a1":"Très difficile",
  "patient_shoulder_function_q4_a2":"Quelque peu difficile",
  "patient_shoulder_function_q4_a3":"Pas difficile",
  "patient_shoulder_function_q5":"Gérer son hygiène personnelle/se laver ",
  "patient_shoulder_function_q5_a0":"Incapable",
  "patient_shoulder_function_q5_a1":"Très difficile",
  "patient_shoulder_function_q5_a2":"Quelque peu difficile",
  "patient_shoulder_function_q5_a3":"Pas difficile",
  "patient_shoulder_function_q6":"Se peigner ",
  "patient_shoulder_function_q6_a0":"Incapable",
  "patient_shoulder_function_q6_a1":"Très difficile",
  "patient_shoulder_function_q6_a2":"Quelque peu difficile",
  "patient_shoulder_function_q6_a3":"Pas difficile",
  "patient_shoulder_function_q7":"Se raser/se maquiller ",
  "patient_shoulder_function_q7_a0":"Incapable",
  "patient_shoulder_function_q7_a1":"Très difficile",
  "patient_shoulder_function_q7_a2":"Quelque peu difficile",
  "patient_shoulder_function_q7_a3":"Pas difficile",
  "patient_shoulder_function_q8":"Se laver les dents ",
  "patient_shoulder_function_q8_a0":"Incapable",
  "patient_shoulder_function_q8_a1":"Très difficile",
  "patient_shoulder_function_q8_a2":"Quelque peu difficile",
  "patient_shoulder_function_q8_a3":"Pas difficile",
  "patient_shoulder_function_q9":"Remplir un verre avec une bouteille pleine",
  "patient_shoulder_function_q9_a0":"Incapable",
  "patient_shoulder_function_q9_a1":"Très difficile",
  "patient_shoulder_function_q9_a2":"Quelque peu difficile",
  "patient_shoulder_function_q9_a3":"Pas difficile",
  "patient_shoulder_function_q10":"Boire (Porter un verre plein à la bouche)",
  "patient_shoulder_function_q10_a0":"Incapable",
  "patient_shoulder_function_q10_a1":"Très difficile",
  "patient_shoulder_function_q10_a2":"Quelque peu difficile",
  "patient_shoulder_function_q10_a3":"Pas difficile",
  "patient_shoulder_function_q11":"Manger de la soupe (Porter une cuillère à la bouche)",
  "patient_shoulder_function_q11_a0":"Incapable",
  "patient_shoulder_function_q11_a1":"Très difficile",
  "patient_shoulder_function_q11_a2":"Quelque peu difficile",
  "patient_shoulder_function_q11_a3":"Pas difficile",
  "patient_shoulder_function_q12":"Serrer la main de quelqu'un/ouvrir une porte ",
  "patient_shoulder_function_q12_a0":"Incapable",
  "patient_shoulder_function_q12_a1":"Très difficile",
  "patient_shoulder_function_q12_a2":"Quelque peu difficile",
  "patient_shoulder_function_q12_a3":"Pas difficile",
  "patient_shoulder_function_q13":"Utiliser un téléphone (à l'oreille)",
  "patient_shoulder_function_q13_a0":"Incapable",
  "patient_shoulder_function_q13_a1":"Très difficile",
  "patient_shoulder_function_q13_a2":"Quelque peu difficile",
  "patient_shoulder_function_q13_a3":"Pas difficile",
  "patient_shoulder_function_q14":"Ecrire une lettre/signer un papier/utiliser un clavier ",
  "patient_shoulder_function_q14_a0":"Incapable",
  "patient_shoulder_function_q14_a1":"Très difficile",
  "patient_shoulder_function_q14_a2":"Quelque peu difficile",
  "patient_shoulder_function_q14_a3":"Pas difficile",
  "patient_shoulder_function_q15":"Atteindre une étagère en hauteur (au-dessus de l'épaule)",
  "patient_shoulder_function_q15_a0":"Incapable",
  "patient_shoulder_function_q15_a1":"Très difficile",
  "patient_shoulder_function_q15_a2":"Quelque peu difficile",
  "patient_shoulder_function_q15_a3":"Pas difficile",
  "patient_shoulder_function_q16":"Porter 0.5 kg (1 lbs) au-dessus de l'épaule",
  "patient_shoulder_function_q16_a0":"Incapable",
  "patient_shoulder_function_q16_a1":"Très difficile",
  "patient_shoulder_function_q16_a2":"Quelque peu difficile",
  "patient_shoulder_function_q16_a3":"Pas difficile",
  "patient_shoulder_function_q17":"Porter 4.5 kg (10 lbs) au-dessus de l'épaule",
  "patient_shoulder_function_q17_a0":"Incapable",
  "patient_shoulder_function_q17_a1":"Très difficile",
  "patient_shoulder_function_q17_a2":"Quelque peu difficile",
  "patient_shoulder_function_q17_a3":"Pas difficile",
  "patient_shoulder_function_q18":"Pouvez-vous porter 10kg (22 lbs) du côté de votre épaule affectée",
  "patient_shoulder_function_q18_a0":"Incapable",
  "patient_shoulder_function_q18_a1":"Très difficile",
  "patient_shoulder_function_q18_a2":"Quelque peu difficile",
  "patient_shoulder_function_q18_a3":"Pas difficile",
  "patient_shoulder_function_q19":"Jeter une balle avec le bras maintenu en dessous de l'épaule (au moins à 3 mètres)",
  "patient_shoulder_function_q19_a0":"Incapable",
  "patient_shoulder_function_q19_a1":"Très difficile",
  "patient_shoulder_function_q19_a2":"Quelque peu difficile",
  "patient_shoulder_function_q19_a3":"Pas difficile",
  "patient_shoulder_function_q20":"Jeter une balle avec le bras au dessus de l'épaule (au moins à 6 mètres)",
  "patient_shoulder_function_q20_a0":"Incapable",
  "patient_shoulder_function_q20_a1":"Très difficile",
  "patient_shoulder_function_q20_a2":"Quelque peu difficile",
  "patient_shoulder_function_q20_a3":"Pas difficile",
  "patient_shoulder_function_q21":"Faire son travail habituel ",
  "patient_shoulder_function_q21_a0":"Incapable",
  "patient_shoulder_function_q21_a1":"Très difficile",
  "patient_shoulder_function_q21_a2":"Quelque peu difficile",
  "patient_shoulder_function_q21_a3":"Pas difficile",
  "patient_shoulder_function_q22":"Quel niveau de travail normal votre épaule vous permet-elle d'effectuer ? ",
  "patient_shoulder_function_q22_a0":"Aucun ",
  "patient_shoulder_function_q22_a1":"Tout",
  "patient_shoulder_function_q23":"Faire du sport/ses loisirs ",
  "patient_shoulder_function_q23_a0":"Incapable",
  "patient_shoulder_function_q23_a1":"Très difficile",
  "patient_shoulder_function_q23_a2":"Quelque peu difficile",
  "patient_shoulder_function_q23_a3":"Pas difficile",
  "patient_shoulder_function_q24":"Quel niveau de loisirs normaux votre épaule vous permet-elle d'effectuer ? ",
  "patient_shoulder_function_q24_a0":"Aucuns ",
  "patient_shoulder_function_q24_a1":"Tous ",
  "patient_shoulder_function_q25":"Veuillez lister vos activités professionnelles ",
  "patient_shoulder_function_q26":"Veuillez lister vos loisirs / activités sportives",
  "patient_shoulder_feeling_instability":"Sensation d'instabilité",
  "patient_shoulder_feeling_instability_q1":"Est-ce que votre épaule semble instable (comme si elle allait se déboîter)",
  "patient_shoulder_feeling_instability_q1_a1":"Non",
  "patient_shoulder_feeling_instability_q1_a2":"Oui",
  "patient_shoulder_feeling_instability_q1_a2_q1":"Veuillez indiquer à quel point votre épaule paraît instable ",
  "patient_shoulder_wosi":"Western Ontario Shoulder Instability Index",
  "patient_shoulder_wosi_instruction":"Instructions : Dans cette partie du questionnaire vous êtes demandé(e) d'indiquer les symptômes ressentis au cours de la dernière semaine dans votre épaule problématique. Glissez le curseur le long de la ligne jusqu'à ce que sa position reflète précisément vos symptômes. ",
  "patient_shoulder_wosi_note":"Note : Le plus à droite vous placez le curseur, le plus vous indiquez ressentir ce symptôme. Le plus à gauche vous placez le curseur, le moins vous ressentez ce symptôme. Si vous avez quelconques questions sur l'intention de certaines questions, veuillez demander. ",
  "patient_shoulder_wosi_sectionA":"Section A : Symptômes Physiques",
  "patient_shoulder_wosi_q1":"Comment évaluez-vous la douleur dans votre épaule en effectuant des tâches nécessitant d'avoir les bras en l'air (au-dessus de la tête) ?",
  "patient_shoulder_wosi_q1_a1":"Pas de douleur",
  "patient_shoulder_wosi_q1_a2":"Douleur extrême",
  "patient_shoulder_wosi_q2":"Comment évaluez-vous la douleur dans votre épaule ? ",
  "patient_shoulder_wosi_q2_a1":"Pas de douleur",
  "patient_shoulder_wosi_q2_a2":"Douleur extrême",
  "patient_shoulder_wosi_q3":"Quel niveau de faiblesse ou manque de force ressentez-vous dans votre épaule ? ",
  "patient_shoulder_wosi_q3_a1":"Pas de faiblesse",
  "patient_shoulder_wosi_q3_a2":"Faiblesse extrême",
  "patient_shoulder_wosi_q4":"Quel niveau de fatigue ou manque d'endurance ressentez-vous dans votre épaule ? ",
  "patient_shoulder_wosi_q4_a1":"Pas de fatigue",
  "patient_shoulder_wosi_q4_a2":"Fatigue extrême",
  "patient_shoulder_wosi_q5":"Quelle intensité des craquements ou claquements ressentez-vous dans votre épaule ? ",
  "patient_shoulder_wosi_q5_a1":"Pas de craquements",
  "patient_shoulder_wosi_q5_a2":"Beaucoup de craquements",
  "patient_shoulder_wosi_q6":"Quel degré de raideur ressentez-vous dans votre épaule ? ",
  "patient_shoulder_wosi_q6_a1":"Pas de raideur",
  "patient_shoulder_wosi_q6_a2":"Raideur extrême",
  "patient_shoulder_wosi_q7":"Quel degré d'inconfort ressentez-vous dans vos muscles du cou à cause de votre épaule ? ",
  "patient_shoulder_wosi_q7_a1":"Pas d'inconfort",
  "patient_shoulder_wosi_q7_a2":"Inconfort extrême",
  "patient_shoulder_wosi_q8":"Quel degré d'instabilité ressentez-vous dans votre épaule ? ",
  "patient_shoulder_wosi_q8_a1":"Pas d'instabilité",
  "patient_shoulder_wosi_q8_a2":"Instabilité extrême",
  "patient_shoulder_wosi_q9":"A quel point compensez-vous votre épaule avec d'autres muscles ? ",
  "patient_shoulder_wosi_q9_a1":"Pas du tout",
  "patient_shoulder_wosi_q9_a2":"Extrême",
  "patient_shoulder_wosi_q10":"Quelle limitation d'amplitude de mouvements ressentez-vous dans votre épaule ? ",
  "patient_shoulder_wosi_q10_a1":"Pas de perte",
  "patient_shoulder_wosi_q10_a2":"Perte extrême",
  "patient_shoulder_wosi_sectionB":"Section B : Sports / Loisirs / Travail",
  "patient_shoulder_wosi_q11":"A quel point votre épaule limite-t-elle vos activités sportives ou de loisirs ? ",
  "patient_shoulder_wosi_q11_a1":"Pas limitées",
  "patient_shoulder_wosi_q11_a2":"Extrêmement limitées",
  "patient_shoulder_wosi_q12":"A quelle intensité votre épaule affecte-t-elle votre capacité à réaliser les tâches spécifiques requises par votre activité sportive ou professionnelle ? ",
  "patient_shoulder_wosi_q12_note":"Si votre épaule affecte à la fois votre activité sportive et professionnelle, veuillez prendre en compte l'activité la plus touchée. ",
  "patient_shoulder_wosi_q12_a1":"Pas affectées",
  "patient_shoulder_wosi_q12_a2":"Extrêmement affectées",
  "patient_shoulder_wosi_q13":"A quel point ressentez-vous la necessité de protéger votre bras pendant vos activités ? ",
  "patient_shoulder_wosi_q13_a1":"Pas du tout",
  "patient_shoulder_wosi_q13_a2":"Extrême",
  "patient_shoulder_wosi_q14":"Quel degré de difficulté ressentez-vous pour lever une charge lourde en-dessous des épaules ? ",
  "patient_shoulder_wosi_q14_a1":"Pas de difficulté",
  "patient_shoulder_wosi_q14_a2":"Difficulté extrême",
  "patient_shoulder_wosi_sectionC":"Section C : Mode de vie",
  "patient_shoulder_wosi_q15":"Quelle est l'importance de votre peur de tomber sur votre épaule ?",
  "patient_shoulder_wosi_q15_a1":"Pas de peur",
  "patient_shoulder_wosi_q15_a2":"Peur extrême",
  "patient_shoulder_wosi_q16":"Quelle difficulté ressentez-vous pour maintenir votre niveau de forme physique souhaitée ? ",
  "patient_shoulder_wosi_q16_a1":"Pas de difficulté",
  "patient_shoulder_wosi_q16_a2":"Difficulté extrême",
  "patient_shoulder_wosi_q17":"Quelle difficulté avez-vous pour \"chahuter\" avec votre famille ou amis ? ",
  "patient_shoulder_wosi_q17_a1":"Pas de difficulté",
  "patient_shoulder_wosi_q17_a2":"Difficulté extrême",
  "patient_shoulder_wosi_q18":"Quelle difficulté avez-vous pour dormir à cause de votre épaule ?",
  "patient_shoulder_wosi_q18_a1":"Pas de difficulté",
  "patient_shoulder_wosi_q18_a2":"Difficulté extrême",
  "patient_shoulder_wosi_sectionD":"Section D : Emotions",
  "patient_shoulder_wosi_q19":"A quel point prenez-vous conscience de l'état de votre épaule ?",
  "patient_shoulder_wosi_q19_a1":"Pas conscient(e)",
  "patient_shoulder_wosi_q19_a2":"Extrêmement conscient(e)",
  "patient_shoulder_wosi_q20":"Quel niveau de préoccupation avez-vous au sujet de l'aggravation de l'état de votre épaule ? ",
  "patient_shoulder_wosi_q20_a1":"Pas d'inquiétude",
  "patient_shoulder_wosi_q20_a2":"Extrêmement inquiet(e)",
  "patient_shoulder_wosi_q21":"Quel degré de frustration ressentez-vous à cause de votre épaule ? ",
  "patient_shoulder_wosi_q21_a1":"Pas de frustration",
  "patient_shoulder_wosi_q21_a2":"Extrêmement frustré(e)",
  "patient_shoulder_ac_joint":"Articulation acromio-claviculaire",
  "patient_shoulder_ac_joint_q1":"Est-ce que votre articulation acromio-claviculaire est instable ?",
  "patient_shoulder_ac_joint_q1_note":"Note : L'articulation acromio-claviculaire est l'articulation dans la partie haute de votre épaule, qui connecte votre clavicule à la partie haute de votre omoplate",
  "patient_shoulder_ac_joint_q1_a1":"Non",
  "patient_shoulder_ac_joint_q1_a2":"Oui",
  "patient_shoulder_sacs":"Le Specific AC Score (SACS)",
  "patient_shoulder_sacs_explanation":"Explication : Pour chaque question, vous êtes demandé(e) d'évaluer soit la douleur ou la difficulté ressentie pour effectuer différentes tâches. L'échelle d'évaluation est conçue de façon à ce que 0 reflète pas de douleur ou de difficulté et 10 reflète la douleur ou difficulté la plus sévère. ",
  "patient_shoulder_sacs_note":"Note : Une note de 2 représenterait un niveau de douleur minimal. Si vous avez des questions sur l'intention de quelconques questions, veuillez demander.",
  "patient_shoulder_sacs_sectionA":"Section A : Douleur",
  "patient_shoulder_sacs_a1":"Evaluez l'intensité de douleur dans votre épaule quand elle est à son pire, la plus sévère. ",
  "patient_shoulder_sacs_a1_a1":"Pas de douleur",
  "patient_shoulder_sacs_a1_a2":"La douleur la plus sévère",
  "patient_shoulder_sacs_a2":"Evaluez l'intensité de douleur dans votre épaule quand elle est à son mieux.",
  "patient_shoulder_sacs_a2_a1":"Pas de douleur",
  "patient_shoulder_sacs_a2_a2":"La douleur la plus sévère",
  "patient_shoulder_sacs_a3":"Evaluez l'intensité de douleur quand vous toucher ou appliquer une pression sur le dessus de votre épaule.",
  "patient_shoulder_sacs_a3_note":"Ex: Bretelle de sac-à-main, sacoche d'ordinateur",
  "patient_shoulder_sacs_a3_a1":"Pas de douleur",
  "patient_shoulder_sacs_a3_a2":"La douleur la plus sévère",
  "patient_shoulder_sacs_a4":"Evaluez l'intensité de douleur, tension ou sensation de tiraillement dans votre cou et/ou région de l'omoplate affecté(e)s.",
  "patient_shoulder_sacs_a4_a1":"Pas de douleur",
  "patient_shoulder_sacs_a4_a2":"La douleur la plus sévère",
  "patient_shoulder_sacs_sectionB":"Section B : Fonction",
  "patient_shoulder_sacs_b1":"Evaluez le degré de craquements et/ou grincements et/ou cliquements que vous ressentez dans votre épaule.",
  "patient_shoulder_sacs_b1_a1":"Pas de craquements",
  "patient_shoulder_sacs_b1_a2":"Craquements extrêmes",
  "patient_shoulder_sacs_b2":"Indiquez si votre bras a besoin d'être supporté en marchant",
  "patient_shoulder_sacs_b2_note":"ex: mettre la main dans la poche ou utiliser une attelle",
  "patient_shoulder_sacs_b2_a1":"Pas besoin de support",
  "patient_shoulder_sacs_b2_a2":"Besoin extrême de support",
  "patient_shoulder_sacs_b3":"Évaluez le niveau de difficulté à porter du poids ou à vous pencher sur votre bras",
  "patient_shoulder_sacs_b3_note":"ex: Faire des pompes, du vélo, du yoga",
  "patient_shoulder_sacs_b3_a1":"Pas de difficulté",
  "patient_shoulder_sacs_b3_a2":"Difficulté extrême ou incapable d'effectuer",
  "patient_shoulder_sacs_b4":"Evaluez le niveau de difficulté ressenti pour porter de lourds objets avec le bras maintenu sur le côté pour plus de 5 minutes",
  "patient_shoulder_sacs_b4_note":"ex: Sac de courses",
  "patient_shoulder_sacs_b4_a1":"Pas de difficulté",
  "patient_shoulder_sacs_b4_a2":"Difficulté extrême ou incapable d'effectuer",
  "patient_shoulder_sacs_b5":"Evaluez le niveau de difficulté pour atteindre l'autre côté de votre corps avec votre bras affecté ",
  "patient_shoulder_sacs_b5_note":"ex: Toucher l'autre épaule",
  "patient_shoulder_sacs_b5_a1":"Pas de difficulté",
  "patient_shoulder_sacs_b5_a2":"Difficulté extrême ou incapable d'effectuer",
  "patient_shoulder_sacs_b6":"Evaluez le niveau de difficulté ressenti pour porter et presser de lourds objets ou des poids au dessus de la tête",
  "patient_shoulder_sacs_b6_note":"ex: Plus de 5kg",
  "patient_shoulder_sacs_b6_a1":"Pas de difficulté",
  "patient_shoulder_sacs_b6_a2":"Difficulté extrême ou incapable d'effectuer",
  "patient_shoulder_sacs_b7":"Evaluez le niveau de difficulté ressenti pour maintenir votre bras tendu devant vous pour une longue période",
  "patient_shoulder_sacs_b7_note":"ex: Tenir le volant en conduisant",
  "patient_shoulder_sacs_b7_a1":"Pas de difficulté",
  "patient_shoulder_sacs_b7_a2":"Difficulté extrême ou incapable d'effectuer",
  "patient_shoulder_sacs_b8":"Evaluez le niveau de difficulté ressenti pour effectuer votre activité professionnelle",
  "patient_shoulder_sacs_b8_a1":"Pas de difficulté",
  "patient_shoulder_sacs_b8_a2":"Difficulté extrême ou incapable d'effectuer",
  "patient_shoulder_sacs_b8_a3":"Non applicable/n'est pas applicable",
  "patient_shoulder_sacs_b9":"Evaluez à quel point vous avez dû changer/modifier votre activité pour compenser votre problème d'épaule ",
  "patient_shoulder_sacs_b9_a1":"Pas de difficulté",
  "patient_shoulder_sacs_b9_a2":"Difficulté extrême ou incapable d'effectuer",
  "patient_shoulder_sacs_b9_a3":"Non applicable/n'est pas applicable",
  "patient_shoulder_sacs_b10":"Evaluez le degré de difficulté ressenti pour effectuer votre principale activité sportive. ",
  "patient_shoulder_sacs_b10_a1":"Pas de difficulté",
  "patient_shoulder_sacs_b10_a2":"Difficulté extrême ou incapable d'effectuer",
  "patient_shoulder_sacs_b10_a3":"Non applicable/n'est pas applicable",
  "patient_shoulder_sacs_b11":"Evaluez à quel point vous avez dû changer/modifier votre principale activité sportive pour compenser votre problème d'épaule ",
  "patient_shoulder_sacs_b11_a1":"Pas de difficulté",
  "patient_shoulder_sacs_b11_a2":"Difficulté extrême ou incapable d'effectuer",
  "patient_shoulder_sacs_b11_a3":"Non applicable/n'est pas applicable",
  "patient_shoulder_sacs_sectionC":"Section C : Qualité de vie et satisfaction",
  "patient_shoulder_sacs_c1":"Evaluez la différence squelettique visible dans la partie haute de votre épaule en comparaison avec une épaule normale",
  "patient_shoulder_sacs_c1_note":"Présence d'une bosse incluse",
  "patient_shoulder_sacs_c1_a1":"Pas de différence",
  "patient_shoulder_sacs_c1_a2":"Extrêmement différente",
  "patient_shoulder_sacs_c2":"Evaluez le niveau général d'anxiété ou de stress que votre problème à l'épaule a généré. ",
  "patient_shoulder_sacs_c2_a1":"Pas d'anxiété / de stress",
  "patient_shoulder_sacs_c2_a2":"Niveau d'anxiété / de stress sévère",
  "patient_shoulder_sacs_c3":"A quelle fréquence êtes-vous conscient(e) de votre problème à l'épaule ?",
  "patient_shoulder_sacs_c3_a1":"Jamais",
  "patient_shoulder_sacs_c3_a2":"Constamment",
  "patient_shoulder_sacs_c4":"Avez-vous modifié votre mode de vie pour éviter une potentielle détérioration ou aggravation de votre problème d'épaule ?",
  "patient_shoulder_sacs_c4_a1":"Pas du tout",
  "patient_shoulder_sacs_c4_a2":"Totalement",
  "patient_shoulder_sacs_c5":"A quel point êtes-vous mécontent(e)/insatisfait(e) avec votre épaule ? ",
  "patient_shoulder_sacs_c5_a1":"Extrêmement content(e)",
  "patient_shoulder_sacs_c5_a2":"Extrêmement mécontent(e)",
  "patient_shoulder_sacs_c6":"Comment évalueriez-vous l'état de votre épaule comme pourcentage de vos attentes pour une épaule \"normale\" où 0% est médiocre et 100% est très bien ?",
  "patient_shoulder_satisfaction":"Satisfaction",
  "patient_shoulder_satisfaction_q1":"En ce qui concerne votre épaule affectée, êtes-vous actuellement",
  "patient_shoulder_satisfaction_q1_a1":"Satisfait(e) et/ou mieux",
  "patient_shoulder_satisfaction_q1_a2":"Pas satisfait(e) et/ou pire",
  "patient_shoulder_complete":"Fin du questionnaire",
  "patient_shoulder_complete_text1":"Merci d'avoir pris le temps de remplir ce formulaire. ",
  "patient_shoulder_complete_text2":"Veuillez cliquer sur Envoyer pour terminer et visualiser vos scores. ",
  "patient_shoulder_complete_text3":"Vos réponses seront traitées de façon confidentielle et tous rapports utiliseront seulement des informations anonymes. ",
  "patient_shoulder_results":"Résultats",
  "patient_shoulder_results_text1":"Vos scores de douleur et de résultats fonctionnels de l'épaule calculés à partir de votre questionnaire sont représentés ci-dessous. ",
  "patient_shoulder_results_pain":"Douleur",
  "patient_shoulder_results_function":"Signes fonctionnels",
  "patient_shoulder_results_instability":"Instabilité",
  "patient_shoulder_results_selfevaluation":"Score d'auto-évaluation de l'Epaule",
  "patient_shoulder_results_text2":"Votre praticien sera avec vous d'ici peu. ",
  "patient_hip_pain":"Douleur",
  "patient_hip_pain_q1":"DE FAÇON GÉNÉRALE, comment décririez-vous la douleur dans votre hanche AU COURS DU MOIS DERNIER ? ",
  "patient_hip_pain_q1_a1":"Aucune",
  "patient_hip_pain_q1_a2":"Très légère",
  "patient_hip_pain_q1_a3":"Légère",
  "patient_hip_pain_q1_a4":"Modérée",
  "patient_hip_pain_q1_a5":"Sévère",
  "patient_hip_pain_q2":"Comment évaluez-vous la douleur dans votre hanche EN CE MOMENT ? (où 0 signifie pas de douleur et 10 signifie la pire douleur imaginable)",
  "patient_hip_pain_q2_a1":"Pas de douleur",
  "patient_hip_pain_q2_a2":"La douleur la plus sévère",
  "patient_hip_pain_q3":"Quelle proposition décrit au mieux votre douleur ? ",
  "patient_hip_pain_q3_a1":"Aucune / ignore",
  "patient_hip_pain_q3_a2":"Légère, occasionnelle, pas de compromis sur les activités ",
  "patient_hip_pain_q3_a3":"Douleur légère, pas d'effet sur des activités moyennes, douleur après des activités habituelles, utilisation d'aspirine",
  "patient_hip_pain_q3_a4":"Modérée, tolérable, fait des des compromis, codéine occasionnelle",
  "patient_hip_pain_q3_a5":"Douleur marquée, limitations sévères",
  "patient_hip_pain_q3_a6":"Complètement handicapé(e)",
  "patient_hip_pain_q4":"À QUELLE FRÉQUENCE votre hanche est-elle douloureuse? ",
  "patient_hip_pain_q4_a1":"Jamais",
  "patient_hip_pain_q4_a2":"Tous les mois",
  "patient_hip_pain_q4_a3":"Toutes les semaines",
  "patient_hip_pain_q4_a4":"Tous les jours",
  "patient_hip_pain_q4_a5":"Toujours",
  "patient_hip_pain_q5":"A quel point la douleur dans votre hanche a-t-elle INTRAVÉE VOTRE TRAVAIL (activités ménagères inclues) ? ",
  "patient_hip_pain_q5_a1":"Pas du tout",
  "patient_hip_pain_q5_a2":"Un petit peu",
  "patient_hip_pain_q5_a3":"Modérément",
  "patient_hip_pain_q5_a4":"Beaucoup",
  "patient_hip_pain_q5_a5":"Totalement",
  "patient_hip_pain_instruction":"Quelle INTENSITÉ DE DOULEUR avez-vous ressentie dans votre hanche en effectuant les activités suivantes : ",
  "patient_hip_pain_instruction_0":"Aucune",
  "patient_hip_pain_instruction_1":"Légère",
  "patient_hip_pain_instruction_2":"Modérée",
  "patient_hip_pain_instruction_3":"Sévère",
  "patient_hip_pain_instruction_4":"Extrême",
  "patient_hip_pain_note":"Note : Veuillez répondre aux questions suivantes en relation avec les activités de votre hanche",
  "patient_hip_pain_q6":"Étendre complètement la hanche",
  "patient_hip_pain_q6_a1":"Aucune",
  "patient_hip_pain_q6_a2":"Légère",
  "patient_hip_pain_q6_a3":"Modérée",
  "patient_hip_pain_q6_a4":"Sévère",
  "patient_hip_pain_q6_a5":"Extrême",
  "patient_hip_pain_q7":"Plier complètement la hanche",
  "patient_hip_pain_q7_a1":"Aucune",
  "patient_hip_pain_q7_a2":"Légère",
  "patient_hip_pain_q7_a3":"Modérée",
  "patient_hip_pain_q7_a4":"Sévère",
  "patient_hip_pain_q7_a5":"Extrême",
  "patient_hip_pain_q8":"Marcher sur une surface plane ",
  "patient_hip_pain_q8_a1":"Aucune",
  "patient_hip_pain_q8_a2":"Légère",
  "patient_hip_pain_q8_a3":"Modérée",
  "patient_hip_pain_q8_a4":"Sévère",
  "patient_hip_pain_q8_a5":"Extrême",
  "patient_hip_pain_q9":"Combien de temps pouvez-vous marcher avant que la douleur dans votre hanche devienne intense ? (avec ou sans canne)",
  "patient_hip_pain_q9_a1":"Pas de douleur/plus de 30 minutes/illimité",
  "patient_hip_pain_q9_a2":"16-30 minutes/6 pâtés de maison",
  "patient_hip_pain_q9_a3":"5-15 minutes/2-3 pâtés de maison",
  "patient_hip_pain_q9_a4":"Autour de la maison/seulement à l'intérieur",
  "patient_hip_pain_q9_a5":"Ne peut pas marcher du tout/douleur sévère en marchant/lit et chaise seulement",
  "patient_hip_pain_q10":"Marcher sur une surface dure (bitume, béton etc) ",
  "patient_hip_pain_q10_a1":"Aucune",
  "patient_hip_pain_q10_a2":"Légère",
  "patient_hip_pain_q10_a3":"Modérée",
  "patient_hip_pain_q10_a4":"Sévère",
  "patient_hip_pain_q10_a5":"Extrême",
  "patient_hip_pain_q11":"Marcher sur une surface irrégulière ",
  "patient_hip_pain_q11_a1":"Aucune",
  "patient_hip_pain_q11_a2":"Légère",
  "patient_hip_pain_q11_a3":"Modérée",
  "patient_hip_pain_q11_a4":"Sévère",
  "patient_hip_pain_q11_a5":"Extrême",
  "patient_hip_pain_q12":"Monter ou descendre les escaliers ",
  "patient_hip_pain_q12_a1":"Aucune",
  "patient_hip_pain_q12_a2":"Légère",
  "patient_hip_pain_q12_a3":"Modérée",
  "patient_hip_pain_q12_a4":"Sévère",
  "patient_hip_pain_q12_a5":"Extrême",
  "patient_hip_pain_q13":"Au lit la nuit ",
  "patient_hip_pain_q13_a1":"Aucune",
  "patient_hip_pain_q13_a2":"Légère",
  "patient_hip_pain_q13_a3":"Modérée",
  "patient_hip_pain_q13_a4":"Sévère",
  "patient_hip_pain_q13_a5":"Extrême",
  "patient_hip_pain_q14":"A quelle fréquence souffrez-vous de votre hanche au lit la nuit ? ",
  "patient_hip_pain_q14_a1":"Pas de nuits",
  "patient_hip_pain_q14_a2":"Seulement 1 ou 2 nuits",
  "patient_hip_pain_q14_a3":"Quelques nuits",
  "patient_hip_pain_q14_a4":"La plupart des nuits",
  "patient_hip_pain_q14_a5":"Toute les nuits",
  "patient_hip_pain_q15":"Assis ou debout ",
  "patient_hip_pain_q15_a1":"Aucune",
  "patient_hip_pain_q15_a2":"Légère",
  "patient_hip_pain_q15_a3":"Modérée",
  "patient_hip_pain_q15_a4":"Sévère",
  "patient_hip_pain_q15_a5":"Extrême",
  "patient_hip_pain_q16":"En se tenant droit debout ",
  "patient_hip_pain_q16_a1":"Aucune",
  "patient_hip_pain_q16_a2":"Légère",
  "patient_hip_pain_q16_a3":"Modérée",
  "patient_hip_pain_q16_a4":"Sévère",
  "patient_hip_pain_q16_a5":"Extrême",
  "patient_hip_pain_q17":"En se levant d'une chaise",
  "patient_hip_pain_q17_a1":"Aucune",
  "patient_hip_pain_q17_a2":"Légère",
  "patient_hip_pain_q17_a3":"Modérée",
  "patient_hip_pain_q17_a4":"Sévère",
  "patient_hip_pain_q17_a5":"Extrême",
  "patient_hip_pain_q18":"Après une activité ",
  "patient_hip_pain_q18_a1":"Aucune",
  "patient_hip_pain_q18_a2":"Légère",
  "patient_hip_pain_q18_a3":"Modérée",
  "patient_hip_pain_q18_a4":"Sévère",
  "patient_hip_pain_q18_a5":"Extrême",
  "patient_hip_other_symptoms":"Autres symptômes",
  "patient_hip_other_symptoms_instruction1":"Veuillez choisir la réponse qui correspond le mieux à vos symptômes de hanche. ",
  "patient_hip_other_symptoms_instruction2":"Si vous n'êtes pas sûr(e), veuillez répondre du mieux que vous pouvez. ",
  "patient_hip_other_symptoms_instruction3":"Avez-vous les propositions suivantes : ",
  "patient_hip_other_symptoms_instruction_0":"Aucun(e)(s)",
  "patient_hip_other_symptoms_instruction_1":"Léger(e)(s)",
  "patient_hip_other_symptoms_instruction_2":"Modéré(e)(s)",
  "patient_hip_other_symptoms_instruction_3":"Sévère(s)",
  "patient_hip_other_symptoms_instruction_4":"Extrême(s)",
  "patient_hip_other_symptoms_q1":"Grincements, craquements ou tout autres bruits quand vous bougez votre hanche ",
  "patient_hip_other_symptoms_q1_a1":"Jamais",
  "patient_hip_other_symptoms_q1_a2":"Rarement",
  "patient_hip_other_symptoms_q1_a3":"Parfois",
  "patient_hip_other_symptoms_q1_a4":"Souvent",
  "patient_hip_other_symptoms_q1_a5":"Toujours",
  "patient_hip_other_symptoms_q2":"Sensations d'accrochage ou de blocage ",
  "patient_hip_other_symptoms_q2_a1":"Aucunes",
  "patient_hip_other_symptoms_q2_a2":"Légères",
  "patient_hip_other_symptoms_q2_a3":"Modérées",
  "patient_hip_other_symptoms_q2_a4":"Sévères",
  "patient_hip_other_symptoms_q2_a5":"Extrêmes",
  "patient_hip_other_symptoms_q3":"Sensation de raideur en se réveillant le matin",
  "patient_hip_other_symptoms_q3_a1":"Aucune",
  "patient_hip_other_symptoms_q3_a2":"Légère",
  "patient_hip_other_symptoms_q3_a3":"Modérée",
  "patient_hip_other_symptoms_q3_a4":"Sévère",
  "patient_hip_other_symptoms_q3_a5":"Extrême",
  "patient_hip_other_symptoms_q4":"Sensation de raideur après s'être assis(e), allongé(e), ou reposé(e) plus tard dans la journée ? ",
  "patient_hip_other_symptoms_q4_a1":"Aucune",
  "patient_hip_other_symptoms_q4_a2":"Légère",
  "patient_hip_other_symptoms_q4_a3":"Modérée",
  "patient_hip_other_symptoms_q4_a4":"Sévère",
  "patient_hip_other_symptoms_q4_a5":"Extrême",
  "patient_hip_other_symptoms_q5":"Boiterie ",
  "patient_hip_other_symptoms_q5_a1":"Aucune, rarement/jamais",
  "patient_hip_other_symptoms_q5_a2":"Légère, parfois ou seulement au début",
  "patient_hip_other_symptoms_q5_a3":"Modérée, souvent, pas seulement au début",
  "patient_hip_other_symptoms_q5_a4":"Sévère, la plupart du temps",
  "patient_hip_other_symptoms_q5_a5":"Tout le temps ou incapable de marcher",
  "patient_hip_other_symptoms_q6":"Soudaine, douleur sévère - \"tiraillement\", \"coup de couteau\", ou \"spasmes\" dans la hanche",
  "patient_hip_other_symptoms_q6_a1":"Pas de jour",
  "patient_hip_other_symptoms_q6_a2":"Seulement 1 ou 2 jour(s)",
  "patient_hip_other_symptoms_q6_a3":"Certains jours",
  "patient_hip_other_symptoms_q6_a4":"La plupart des jours",
  "patient_hip_other_symptoms_q6_a5":"Tous les jours",
  "patient_hip_other_symptoms_q7":"Dérobement de la hanche",
  "patient_hip_other_symptoms_q7_a1":"Aucun",
  "patient_hip_other_symptoms_q7_a2":"Léger",
  "patient_hip_other_symptoms_q7_a3":"Modéré",
  "patient_hip_other_symptoms_q7_a4":"Sévère",
  "patient_hip_other_symptoms_q7_a5":"Extrême",
  "patient_hip_other_symptoms_q8":"Mouvements réduits ",
  "patient_hip_other_symptoms_q8_a1":"Aucuns",
  "patient_hip_other_symptoms_q8_a2":"Légers",
  "patient_hip_other_symptoms_q8_a3":"Modérés",
  "patient_hip_other_symptoms_q8_a4":"Sévères",
  "patient_hip_other_symptoms_q8_a5":"Extrêmes",
  "patient_hip_other_symptoms_q9":"Utilisez-vous un support de marche ? ",
  "patient_hip_other_symptoms_q9_a1":"Aucun",
  "patient_hip_other_symptoms_q9_a2":"Utilisation d'un canne/béquille pour les longues marches",
  "patient_hip_other_symptoms_q9_a3":"Canne/béquille la plupart du temps",
  "patient_hip_other_symptoms_q9_a4":"Une béquille",
  "patient_hip_other_symptoms_q9_a5":"Deux cannes/béquilles",
  "patient_hip_other_symptoms_q9_a6":"Deux béquilles, incapable de marcher",
  "patient_hip_function":"Signes fonctionnels",
  "patient_hip_function_instruction":"Indiquez le NIVEAU DE DIFFICULTÉ rencontré pour effectuer les activités suivantes à cause de votre hanche",
  "patient_hip_function_instruction_0":"Aucune",
  "patient_hip_function_instruction_1":"Légère",
  "patient_hip_function_instruction_2":"Modérée",
  "patient_hip_function_instruction_3":"Sévère",
  "patient_hip_function_instruction_4":"Extrême/incapable d'effectuer",
  "patient_hip_function_note":"Note : Si vous ne faîtes pas une certaine activité, imaginez ce que vous ressentiriez dans votre hanche si vous deviez essayer de faire cette activité",
  "patient_hip_function_q1":"Descendre des marches",
  "patient_hip_function_q1_a1":"Aucune",
  "patient_hip_function_q1_a2":"Légère",
  "patient_hip_function_q1_a3":"Modérée",
  "patient_hip_function_q1_a4":"Sévère",
  "patient_hip_function_q1_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q2":"Monter des marches ",
  "patient_hip_function_q2_a1":"Aucune",
  "patient_hip_function_q2_a2":"Légère",
  "patient_hip_function_q2_a3":"Modérée",
  "patient_hip_function_q2_a4":"Sévère",
  "patient_hip_function_q2_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q3":"S'asseoir ",
  "patient_hip_function_q3_a1":"Aucune",
  "patient_hip_function_q3_a2":"Légère",
  "patient_hip_function_q3_a3":"Modérée",
  "patient_hip_function_q3_a4":"Sévère",
  "patient_hip_function_q3_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q4":"Se lever d'une position assise ",
  "patient_hip_function_q4_a1":"Aucune",
  "patient_hip_function_q4_a2":"Légère",
  "patient_hip_function_q4_a3":"Modérée",
  "patient_hip_function_q4_a4":"Sévère",
  "patient_hip_function_q4_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q5":"Se relever ou se baisser au sol ",
  "patient_hip_function_q5_a1":"Aucune",
  "patient_hip_function_q5_a2":"Légère",
  "patient_hip_function_q5_a3":"Modérée",
  "patient_hip_function_q5_a4":"Sévère",
  "patient_hip_function_q5_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q6":"Se tenir debout",
  "patient_hip_function_q6_a1":"Aucune",
  "patient_hip_function_q6_a2":"Légère",
  "patient_hip_function_q6_a3":"Modérée",
  "patient_hip_function_q6_a4":"Sévère",
  "patient_hip_function_q6_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q7":"Se tenir debout pendant 15 minutes",
  "patient_hip_function_q7_a1":"Aucune",
  "patient_hip_function_q7_a2":"Légère",
  "patient_hip_function_q7_a3":"Modérée",
  "patient_hip_function_q7_a4":"Sévère",
  "patient_hip_function_q7_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q8":"Ecarter les james ",
  "patient_hip_function_q8_a1":"Aucune",
  "patient_hip_function_q8_a2":"Légère",
  "patient_hip_function_q8_a3":"Modérée",
  "patient_hip_function_q8_a4":"Sévère",
  "patient_hip_function_q8_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q9":"Marcher à grands pas ",
  "patient_hip_function_q9_a1":"Aucune",
  "patient_hip_function_q9_a2":"Légère",
  "patient_hip_function_q9_a3":"Modérée",
  "patient_hip_function_q9_a4":"Sévère",
  "patient_hip_function_q9_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q10":"Se baisser au sol/ramasser un objet ",
  "patient_hip_function_q10_a1":"Aucune",
  "patient_hip_function_q10_a2":"Légère",
  "patient_hip_function_q10_a3":"Modérée",
  "patient_hip_function_q10_a4":"Sévère",
  "patient_hip_function_q10_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q11":"Marcher sur une surface plane ",
  "patient_hip_function_q11_a1":"Aucune",
  "patient_hip_function_q11_a2":"Légère",
  "patient_hip_function_q11_a3":"Modérée",
  "patient_hip_function_q11_a4":"Sévère",
  "patient_hip_function_q11_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q12":"Marcher sur une surface irrégulière ",
  "patient_hip_function_q12_a1":"Aucune",
  "patient_hip_function_q12_a2":"Légère",
  "patient_hip_function_q12_a3":"Modérée",
  "patient_hip_function_q12_a4":"Sévère",
  "patient_hip_function_q12_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q13":"Marcher de longues distances ",
  "patient_hip_function_q13_a1":"Aucune",
  "patient_hip_function_q13_a2":"Légère",
  "patient_hip_function_q13_a3":"Modérée",
  "patient_hip_function_q13_a4":"Sévère",
  "patient_hip_function_q13_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q14":"Marcher comme activité physique ",
  "patient_hip_function_q14_a1":"Aucune",
  "patient_hip_function_q14_a2":"Légère",
  "patient_hip_function_q14_a3":"Modérée",
  "patient_hip_function_q14_a4":"Sévère",
  "patient_hip_function_q14_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q15":"Monter une côte raide",
  "patient_hip_function_q15_a1":"Aucune",
  "patient_hip_function_q15_a2":"Légère",
  "patient_hip_function_q15_a3":"Modérée",
  "patient_hip_function_q15_a4":"Sévère",
  "patient_hip_function_q15_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q16":"Descendre d'une côte raide",
  "patient_hip_function_q16_a1":"Aucune",
  "patient_hip_function_q16_a2":"Légère",
  "patient_hip_function_q16_a3":"Modérée",
  "patient_hip_function_q16_a4":"Sévère",
  "patient_hip_function_q16_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q17":"Initialiser la marche ",
  "patient_hip_function_q17_a1":"Aucune",
  "patient_hip_function_q17_a2":"Légère",
  "patient_hip_function_q17_a3":"Modérée",
  "patient_hip_function_q17_a4":"Sévère",
  "patient_hip_function_q17_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q18":"Marcher environ 10 minutes",
  "patient_hip_function_q18_a1":"Aucune",
  "patient_hip_function_q18_a2":"Légère",
  "patient_hip_function_q18_a3":"Modérée",
  "patient_hip_function_q18_a4":"Sévère",
  "patient_hip_function_q18_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q19":"Marcher 15 minutes ou plus",
  "patient_hip_function_q19_a1":"Aucune",
  "patient_hip_function_q19_a2":"Légère",
  "patient_hip_function_q19_a3":"Modérée",
  "patient_hip_function_q19_a4":"Sévère",
  "patient_hip_function_q19_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q20":"Monter et descendre d'un trottoir ",
  "patient_hip_function_q20_a1":"Aucune",
  "patient_hip_function_q20_a2":"Légère",
  "patient_hip_function_q20_a3":"Modérée",
  "patient_hip_function_q20_a4":"Sévère",
  "patient_hip_function_q20_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q21":"Monter/descendre d'une voiture ou des transports communs (bus), ce que vous utilisez le plus souvent",
  "patient_hip_function_q21_a1":"Aucune",
  "patient_hip_function_q21_a2":"Légère",
  "patient_hip_function_q21_a3":"Modérée",
  "patient_hip_function_q21_a4":"Sévère",
  "patient_hip_function_q21_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q22":"Faire les courses ",
  "patient_hip_function_q22_a1":"Aucune",
  "patient_hip_function_q22_a2":"Légère",
  "patient_hip_function_q22_a3":"Modérée",
  "patient_hip_function_q22_a4":"Sévère",
  "patient_hip_function_q22_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q23":"Mettre des chaussettes/bas",
  "patient_hip_function_q23_a1":"Aucune",
  "patient_hip_function_q23_a2":"Légère",
  "patient_hip_function_q23_a3":"Modérée",
  "patient_hip_function_q23_a4":"Sévère",
  "patient_hip_function_q23_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q24":"Retirer des chaussettes/bas",
  "patient_hip_function_q24_a1":"Aucune",
  "patient_hip_function_q24_a2":"Légère",
  "patient_hip_function_q24_a3":"Modérée",
  "patient_hip_function_q24_a4":"Sévère",
  "patient_hip_function_q24_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q25":"Allongé(e) au lit (se retouner, maintenir la position de la hanche) ",
  "patient_hip_function_q25_a1":"Aucune",
  "patient_hip_function_q25_a2":"Légère",
  "patient_hip_function_q25_a3":"Modérée",
  "patient_hip_function_q25_a4":"Sévère",
  "patient_hip_function_q25_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q26":"Se retourner dans le lit ",
  "patient_hip_function_q26_a1":"Aucune",
  "patient_hip_function_q26_a2":"Légère",
  "patient_hip_function_q26_a3":"Modérée",
  "patient_hip_function_q26_a4":"Sévère",
  "patient_hip_function_q26_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q27":"Se lever du lit ",
  "patient_hip_function_q27_a1":"Aucune",
  "patient_hip_function_q27_a2":"Légère",
  "patient_hip_function_q27_a3":"Modérée",
  "patient_hip_function_q27_a4":"Sévère",
  "patient_hip_function_q27_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q28":"Entrer ou sortir d'une baignoire",
  "patient_hip_function_q28_a1":"Aucune",
  "patient_hip_function_q28_a2":"Légère",
  "patient_hip_function_q28_a3":"Modérée",
  "patient_hip_function_q28_a4":"Sévère",
  "patient_hip_function_q28_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q29":"S'asseoir ou se relever des toilettes ",
  "patient_hip_function_q29_a1":"Aucune",
  "patient_hip_function_q29_a2":"Légère",
  "patient_hip_function_q29_a3":"Modérée",
  "patient_hip_function_q29_a4":"Sévère",
  "patient_hip_function_q29_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q30":"Se laver et se sécher (partout) ",
  "patient_hip_function_q30_a1":"Aucune",
  "patient_hip_function_q30_a2":"Légère",
  "patient_hip_function_q30_a3":"Modérée",
  "patient_hip_function_q30_a4":"Sévère",
  "patient_hip_function_q30_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q31":"Travail léger à modéré (se tenir debout, marcher) ",
  "patient_hip_function_q31_a1":"Aucune",
  "patient_hip_function_q31_a2":"Légère",
  "patient_hip_function_q31_a3":"Modérée",
  "patient_hip_function_q31_a4":"Sévère",
  "patient_hip_function_q31_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q32":"Activités ménagères intenses (porter de lourds objets, nettoyer le sol etc) ",
  "patient_hip_function_q32_a1":"Aucune",
  "patient_hip_function_q32_a2":"Légère",
  "patient_hip_function_q32_a3":"Modérée",
  "patient_hip_function_q32_a4":"Sévère",
  "patient_hip_function_q32_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q33":"Activités ménagères douces (faire la cuisine, les poussières etc)",
  "patient_hip_function_q33_a1":"Aucune",
  "patient_hip_function_q33_a2":"Légère",
  "patient_hip_function_q33_a3":"Modérée",
  "patient_hip_function_q33_a4":"Sévère",
  "patient_hip_function_q33_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q34":"Porter des enfants ",
  "patient_hip_function_q34_a1":"Aucune",
  "patient_hip_function_q34_a2":"Légère",
  "patient_hip_function_q34_a3":"Modérée",
  "patient_hip_function_q34_a4":"Sévère",
  "patient_hip_function_q34_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q35":"S'accroupir",
  "patient_hip_function_q35_a1":"Aucune",
  "patient_hip_function_q35_a2":"Légère",
  "patient_hip_function_q35_a3":"Modérée",
  "patient_hip_function_q35_a4":"Sévère",
  "patient_hip_function_q35_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q36":"Faire de la course à pieds ",
  "patient_hip_function_q36_a1":"Aucune",
  "patient_hip_function_q36_a2":"Légère",
  "patient_hip_function_q36_a3":"Modérée",
  "patient_hip_function_q36_a4":"Sévère",
  "patient_hip_function_q36_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q37":"Courir ",
  "patient_hip_function_q37_a1":"Aucune",
  "patient_hip_function_q37_a2":"Légère",
  "patient_hip_function_q37_a3":"Modérée",
  "patient_hip_function_q37_a4":"Sévère",
  "patient_hip_function_q37_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q38":"Exercer une torsion/pivoter sur la jambe d'appui ",
  "patient_hip_function_q38_a1":"Aucune",
  "patient_hip_function_q38_a2":"Légère",
  "patient_hip_function_q38_a3":"Modérée",
  "patient_hip_function_q38_a4":"Sévère",
  "patient_hip_function_q38_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q39":"Activités sexuelles ",
  "patient_hip_function_q39_a1":"Aucune",
  "patient_hip_function_q39_a2":"Légère",
  "patient_hip_function_q39_a3":"Modérée",
  "patient_hip_function_q39_a4":"Sévère",
  "patient_hip_function_q39_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q39_not_relevant":"Je ne suis pas concerné(e) par ceci",
  "patient_hip_function_q40":"Activités physiques rigoureuses consistant à courir ou changer de directions rapidement (ex football, basketball, tennis, aerobic)",
  "patient_hip_function_q40_a1":"Aucune",
  "patient_hip_function_q40_a2":"Légère",
  "patient_hip_function_q40_a3":"Modérée",
  "patient_hip_function_q40_a4":"Sévère",
  "patient_hip_function_q40_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q41":"Activités sportives douces (ex golf, pétanque) ",
  "patient_hip_function_q41_a1":"Aucune",
  "patient_hip_function_q41_a2":"Légère",
  "patient_hip_function_q41_a3":"Modérée",
  "patient_hip_function_q41_a4":"Sévère",
  "patient_hip_function_q41_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q42":"Loisirs",
  "patient_hip_function_q42_a1":"Aucune",
  "patient_hip_function_q42_a2":"Légère",
  "patient_hip_function_q42_a3":"Modérée",
  "patient_hip_function_q42_a4":"Sévère",
  "patient_hip_function_q42_a5":"Extrême/incapable d'effectuer",
  "patient_hip_function_q43":"Comment évaluez-vous votre hanche aujourd'hui sur un ratio de normalité (de 0% à 100% avec 100% étant normal)",
  "patient_hip_function_q44":"Veuillez renseigner le niveau maximal d'activité que vous pouvez effectuer",
  "patient_hip_function_q44_q1":"Avant votre blessure/condition de la hanche",
  "patient_hip_function_q44_q1_a1":"Compétition",
  "patient_hip_function_q44_q1_a2":"Loisir, activité physique",
  "patient_hip_function_q44_q1_a3":"Pas de sport, seulement le travail",
  "patient_hip_function_q44_q1_a4":"Seulement les activités quotidiennes",
  "patient_hip_function_q44_q1_a5":"Arrêt maladie ou pension d'invalidité à cause des problèmes de santé",
  "patient_hip_function_q44_q1_a1_o1":"Sports de compétition qui se pratiquent à un niveau Olympique ou professionnel",
  "patient_hip_function_q44_q1_a1_o2":"Sports de compétition comme l'athlétisme, les sports de raquette, la gymnastique, l'aviron, le ski ou les arts martiaux joués à un niveau national ou international ",
  "patient_hip_function_q44_q1_a1_o3":"Sports de compétition comme les sports aquatiques, le cyclisme, le hockey, le curling, ou l'équitation/rodéo pratiqués à un niveau national ou international",
  "patient_hip_function_q44_q1_a2_o1":"Sports de loisirs comme la course à pieds, les sports de raquette, la musculation, le curling, l'aviron, le golf, le yoga, ou la gymnastique au moins 5 fois par semaine",
  "patient_hip_function_q44_q1_a2_o2":"Sports de loisirs comme la natation, les sports aquatiques, le ski, le hockey, les rollers, le cyclisme, l'équitation, ou l'escalade au moins 5 fois par semaine",
  "patient_hip_function_q44_q1_a2_o3":"Sports de loisirs au moins 2 fois par semaine",
  "patient_hip_function_q44_q1_a2_o4":"Sports de loisirs au moins une fois par semaine ou moins",
  "patient_hip_function_q44_q1_a2_o5":"Sports de loisir moins d'une fois par mois",
  "patient_hip_function_q44_q1_a3_o1":"Travail intense comme dans le bâtiment",
  "patient_hip_function_q44_q1_a3_o2":"Travail modérément intense comme camioneur",
  "patient_hip_function_q44_q1_a3_o3":"Travail léger comme infirmier(ère)",
  "patient_hip_function_q44_q1_a3_o4":"Travail sédentaire comme secrétariat ou travail sur ordinateur",
  "patient_hip_function_q44_q1_a4_o1":"Activités quotidiennes comme le jardinage, monter plusieurs étages à pieds, porter des charges, pousser/tirer une charge, possibilité de courir en cas de retard",
  "patient_hip_function_q44_q1_a4_o2":"Activités quotidiennes comme faire le ménage, monter 1 ou 2 étages, ou marcher en portant une charge légère",
  "patient_hip_function_q44_q1_a4_o3":"Activités quotidiennes limitées (ex ne prends pas les escaliers, incapable de porter des charges)",
  "patient_hip_function_q44_q2":"Statut actuel",
  "patient_hip_function_q44_q2_a1":"Compétition",
  "patient_hip_function_q44_q2_a2":"Loisir, activité physique",
  "patient_hip_function_q44_q2_a3":"Pas de sport, seulement travail",
  "patient_hip_function_q44_q2_a4":"Seulement les activités quotidiennes",
  "patient_hip_function_q44_q2_a5":"Arrêt maladie ou pension d'invalidité à cause des problèmes de santé",
  "patient_hip_function_q44_q2_a1_o1":"Sports de compétition qui se pratiquent à un niveau international, Olympique ou professionnel",
  "patient_hip_function_q44_q2_a1_o2":"Sports de compétition comme l'athlétisme, les sports de raquette, la gymnastique, l'aviron, le ski ou les arts martiaux joués à un niveau national ou international ",
  "patient_hip_function_q44_q2_a1_o3":"Sports de compétition comme les sports aquatiques, le cyclisme, le hockey, le curling, ou l'équitation/rodéo pratiqués à un niveau national ou international",
  "patient_hip_function_q44_q2_a2_o1":"Sports de loisirs comme la course à pieds, les sports de raquette, la musculation, le curling, l'aviron, le golf, le yoga, ou la gymnastique au moins 5 fois par semaine",
  "patient_hip_function_q44_q2_a2_o2":"Sports de loisirs comme la natation, les sports aquatiques, le ski, le hockey, les rollers, le cyclisme, l'équitation, ou l'escalade au moins 5 fois par semaine",
  "patient_hip_function_q44_q2_a2_o3":"Sports de loisirs au moins 2 fois par semaine",
  "patient_hip_function_q44_q2_a2_o4":"Sports de loisirs au moins une fois par semaine ou moins",
  "patient_hip_function_q44_q2_a2_o5":"Sports de loisirs moins d'une fois par mois",
  "patient_hip_function_q44_q2_a3_o1":"Travail intense comme dans le bâtiment",
  "patient_hip_function_q44_q2_a3_o2":"Travail modérément intense comme camioneur",
  "patient_hip_function_q44_q2_a3_o3":"Travail léger comme infirmier(ère)",
  "patient_hip_function_q44_q2_a3_o4":"Travail sédentaire comme secrétariat ou travail sur ordinateur",
  "patient_hip_function_q44_q2_a4_o1":"Activités quotidiennes comme le jardinage, monter plusieurs étages à pieds, porter des charges, pousser/tirer une charge, possibilité de courir en cas de retard",
  "patient_hip_function_q44_q2_a4_o2":"Activités quotidiennes comme le ménage, monter 1 ou 2 étages, ou marcher en portant une charge légère",
  "patient_hip_function_q44_q2_a4_o3":"Activités quotidiennes limitées (ex ne prends pas les escaliers, incapable de porter des charges)",
  "patient_hip_quality_of_life":"Qualité de vie",
  "patient_hip_quality_of_life_instruction":"Les questions suivantes concernent les inquiétudes sociales, émotionnelles et de mode de vie que vous pouvez ressentir en rapport avec votre problème de hanche. Veuillez penser à ce que vous avez ressenti la plupart du temps en relation avec votre problème de hanche. ",
  "patient_hip_quality_of_life_q1":"A quelle fréquence êtes-vous conscient(e) de votre problème de hanche ? ",
  "patient_hip_quality_of_life_q1_a1":"Jamais",
  "patient_hip_quality_of_life_q1_a2":"Tous les mois",
  "patient_hip_quality_of_life_q1_a3":"Toutes les semaines",
  "patient_hip_quality_of_life_q1_a4":"Tous les jours",
  "patient_hip_quality_of_life_q1_a5":"Constamment",
  "patient_hip_quality_of_life_q2":"Avez-vous modifié votre quotidien pour éviter des activités qui pourraient potentiallement détériorer votre hanche ? ",
  "patient_hip_quality_of_life_q2_a1":"Pas du tout",
  "patient_hip_quality_of_life_q2_a2":"Légèrement",
  "patient_hip_quality_of_life_q2_a3":"Modérément",
  "patient_hip_quality_of_life_q2_a4":"Fortement",
  "patient_hip_quality_of_life_q2_a5":"Totalement",
  "patient_hip_quality_of_life_q3":"A quel point êtes-vous inquiet(e) du maintien de votre condition physique désirée ? ",
  "patient_hip_quality_of_life_q3_a1":"Pas du tout inquiet(e)",
  "patient_hip_quality_of_life_q3_a2":"Extrêmement inquiet(e)",
  "patient_hip_quality_of_life_q4":"A quel point êtes-vous préoccupé(e) par votre manque de confiance en votre hanche ? ",
  "patient_hip_quality_of_life_q4_a1":"Pas du tout",
  "patient_hip_quality_of_life_q4_a2":"Légèrement",
  "patient_hip_quality_of_life_q4_a3":"Modérément",
  "patient_hip_quality_of_life_q4_a4":"Fortement",
  "patient_hip_quality_of_life_q4_a5":"Extrêmement",
  "patient_hip_quality_of_life_q5":"Quel niveau de distraction attribuez-vous à votre problème de hanche ? ",
  "patient_hip_quality_of_life_q5_a1":"Pas du tout de distraction",
  "patient_hip_quality_of_life_q5_a2":"Distraction extrême",
  "patient_hip_quality_of_life_q6":"De façon générale, quel niveau de difficulté avez-vous avec votre hanche ? ",
  "patient_hip_quality_of_life_q6_a1":"Aucune",
  "patient_hip_quality_of_life_q6_a2":"Légère",
  "patient_hip_quality_of_life_q6_a3":"Modérément",
  "patient_hip_quality_of_life_q6_a4":"Sévère",
  "patient_hip_quality_of_life_q6_a5":"Extrême",
  "patient_hip_quality_of_life_q7":"Comment évaluez-vous votre niveau d'anxiété/dépression aujourd'hui ? ",
  "patient_hip_quality_of_life_q7_a1":"Pas du tout",
  "patient_hip_quality_of_life_q7_a2":"Légèrement ",
  "patient_hip_quality_of_life_q7_a3":"Modérément",
  "patient_hip_quality_of_life_q7_a4":"Fortement",
  "patient_hip_quality_of_life_q7_a5":"Extrêmement",
  "patient_hip_quality_of_life_q8":"Comment évaluez-vous votre santé aujourd'hui ? (100 signifie meilleure santé et 0 signifie la pire santé que vous puissiez imaginer)",
  "patient_hip_quality_of_life_q8_a1":"Pire santé ",
  "patient_hip_quality_of_life_q8_a2":"Meilleure santé ",
  "patient_hip_satisfaction":"Satisfaction",
  "patient_hip_satisfaction_instructions":"Evaluez votre niveau de satisfaction avec vos résultats à la suite de votre chirurgie/traitement de la hanche ? ",
  "patient_hip_satisfaction_instructions_1":"Très insatisfait(e)",
  "patient_hip_satisfaction_instructions_2":"Légèrement insatisfait(e)",
  "patient_hip_satisfaction_instructions_3":"Légèrement satisfait(e)",
  "patient_hip_satisfaction_instructions_4":"Très satisfait(e)",
  "patient_hip_satisfaction_q1":"De façon générale ",
  "patient_hip_satisfaction_q1_not_applicable":"Non applicable",
  "patient_hip_satisfaction_q1_a1":"Très insatisfait(e)",
  "patient_hip_satisfaction_q1_a2":"Légèrement insatisfait(e)",
  "patient_hip_satisfaction_q1_a3":"Légèrement satisfait(e)",
  "patient_hip_satisfaction_q1_a4":"Très satisfait(e)",
  "patient_hip_satisfaction_q2":"Pour atténuer la douleur ? ",
  "patient_hip_satisfaction_q2_not_applicable":"Non applicable",
  "patient_hip_satisfaction_q2_a1":"Très insatisfait(e)",
  "patient_hip_satisfaction_q2_a2":"Légèrement insatisfait(e)",
  "patient_hip_satisfaction_q2_a3":"Légèrement satisfait(e)",
  "patient_hip_satisfaction_q2_a4":"Très satisfait(e)",
  "patient_hip_satisfaction_q3":"Pour améliorer votre capacité à effectuer des tâches ménagères ou du jardinage ? ",
  "patient_hip_satisfaction_q3_not_applicable":"Non applicable",
  "patient_hip_satisfaction_q3_a1":"Très insatisfait(e)",
  "patient_hip_satisfaction_q3_a2":"Légèrement insatisfait(e)",
  "patient_hip_satisfaction_q3_a3":"Légèrement satisfait(e)",
  "patient_hip_satisfaction_q3_a4":"Très satisfait(e)",
  "patient_hip_satisfaction_q4":"Pour améliorer votre capacité à effectuer des activités de loisir ? ",
  "patient_hip_satisfaction_q4_not_applicable":"Non applicable",
  "patient_hip_satisfaction_q4_a1":"Très insatisfait(e)",
  "patient_hip_satisfaction_q4_a2":"Légèrement insatisfait(e)",
  "patient_hip_satisfaction_q4_a3":"Légèrement satisfait(e)",
  "patient_hip_satisfaction_q4_a4":"Très satisfait(e)",
  "patient_hip_complete":"Fin du Questionnaire",
  "patient_hip_complete_text1":"Merci d'avoir pris le temps de remplir ce formulaire. ",
  "patient_hip_complete_text2":"Veuillez cliquer sur Envoyer pour terminer et visualiser vos scores. ",
  "patient_hip_complete_text3":"Vos réponses seront traitées de façon confidentielle et tous rapports utiliseront seulement des informations anonymes. ",
  "patient_hip_results":"Résultats",
  "patient_hip_results_text1":"Vos scores de douleur et de résultats fonctionnels de la hanche calculés à partir de votre questionnaire sont représentés ci-dessous. ",
  "patient_hip_results_pain":"Douleur",
  "patient_hip_results_function":"Signes fonctionnels",
  "patient_hip_results_health_perception":"Perception de santé générale",
  "patient_hip_results_self_evaluation":"Score d'auto-évaluation de la Hanche",
  "patient_hip_results_text2":"Votre praticien sera avec vous d'ici peu. ",
  "":"",
  "patient_adult_knee_pain":"Douleur",
  "patient_adult_knee_pain_q1":"Quel est le niveau maximal d'activité que vous pouvez effectuer sans douleur importante dans le genou ? ",
  "patient_adult_knee_pain_q1_a1":"Activités très intenses comme sauter ou pivoter comme au basketball ou au football",
  "patient_adult_knee_pain_q1_a2":"Activités intenses comme du travail physique intense, skier, ou faire du tennis",
  "patient_adult_knee_pain_q1_a3":"Activités modérées comme du travail physique modéré, courir ou faire du jogging",
  "patient_adult_knee_pain_q1_a4":"Activités légères comme de la marche à pieds, des activités ménagères ou du jardinage",
  "patient_adult_knee_pain_q1_a5":"Incapable d'effectuer les activités ci-dessus à cause de la douleur dans le genou",
  "patient_adult_knee_pain_q2":"A quelle fréquence se produit la douleur ? ",
  "patient_adult_knee_pain_q2_a1":"Jamais",
  "patient_adult_knee_pain_q2_a2":"Tous les mois",
  "patient_adult_knee_pain_q2_a3":"Toutes les semaines",
  "patient_adult_knee_pain_q2_a4":"Tous les jours",
  "patient_adult_knee_pain_q2_a5":"Constamment",
  "patient_adult_knee_pain_q3":"Quelle affirmation décrit le mieux votre douleur ? ",
  "patient_adult_knee_pain_q3_a1":"Pas de douleur, genou normal, fonctionne à 100%",
  "patient_adult_knee_pain_q3_a2":"Douleur occasionnelle avec sport et travail intense, genou pas entièrement normal, quelques limitations mais mineures et tolérables",
  "patient_adult_knee_pain_q3_a3":"Douleur, généralement comme conséquence d'une activité physique, activités de loisir légères ou travail modéré. Se produit de façon occasionnelle en marchant plus de 2km, en se tenant debout ou travail léger",
  "patient_adult_knee_pain_q3_a4":"Douleur occasionnelle avec du sport de loisir léger ou des activités professionnelles modérées, courir ou activités professionnelles intenses, sports ardus",
  "patient_adult_knee_pain_q3_a5":"La douleur est un problème important avec des activités simples comme marcher <2km, soulagée par du repos, incapable d'effectuer une activité sportive",
  "patient_adult_knee_pain_q3_a6":"Constamment",
  "patient_adult_knee_pain_q4":"Si vous ressentez de la douleur, comment l'évaluez-vous ? ",
  "patient_adult_knee_pain_q4_a1":"Aucune",
  "patient_adult_knee_pain_q4_a2":"Très légère",
  "patient_adult_knee_pain_q4_a3":"Légère",
  "patient_adult_knee_pain_q4_a4":"Modérée",
  "patient_adult_knee_pain_q4_a5":"Sévère",
  "patient_adult_knee_pain_q5":"A quel point la douleur dans votre genou a-t-elle interférée sur votre travail (activités de la vie quotidienne inclues) ? ",
  "patient_adult_knee_pain_q5_a1":"Pas du tout",
  "patient_adult_knee_pain_q5_a2":"Un petit peu",
  "patient_adult_knee_pain_q5_a3":"Modérément",
  "patient_adult_knee_pain_q5_a4":"Beaucoup",
  "patient_adult_knee_pain_q5_a5":"Totalement",
  "patient_adult_knee_pain_instruction":"Comment évaluez-vous votre douleur dans le genou lors des activités suivantes : ",
  "patient_adult_knee_pain_instruction_0":"Aucune",
  "patient_adult_knee_pain_instruction_1":"Légère",
  "patient_adult_knee_pain_instruction_2":"Modérée",
  "patient_adult_knee_pain_instruction_3":"Sévère",
  "patient_adult_knee_pain_instruction_4":"Extrême",
  "patient_adult_knee_pain_q6":"Torsion du genou/Tourner le genou ",
  "patient_adult_knee_pain_q6_a1":"Aucune",
  "patient_adult_knee_pain_q6_a2":"Légère",
  "patient_adult_knee_pain_q6_a3":"Modérée",
  "patient_adult_knee_pain_q6_a4":"Sévère",
  "patient_adult_knee_pain_q6_a5":"Extrême",
  "patient_adult_knee_pain_q7":"Etendre complètement le genou",
  "patient_adult_knee_pain_q7_a1":"Aucune",
  "patient_adult_knee_pain_q7_a2":"Légère",
  "patient_adult_knee_pain_q7_a3":"Modérée",
  "patient_adult_knee_pain_q7_a4":"Sévère",
  "patient_adult_knee_pain_q7_a5":"Extrême",
  "patient_adult_knee_pain_q8":"Plier complètement le genou",
  "patient_adult_knee_pain_q8_a1":"Aucune",
  "patient_adult_knee_pain_q8_a2":"Légère",
  "patient_adult_knee_pain_q8_a3":"Modérée",
  "patient_adult_knee_pain_q8_a4":"Sévère",
  "patient_adult_knee_pain_q8_a5":"Extrême",
  "patient_adult_knee_pain_q9":"Marcher sur une surface plane",
  "patient_adult_knee_pain_q9_a1":"Aucune",
  "patient_adult_knee_pain_q9_a2":"Légère",
  "patient_adult_knee_pain_q9_a3":"Modérée",
  "patient_adult_knee_pain_q9_a4":"Sévère",
  "patient_adult_knee_pain_q9_a5":"Extrême",
  "patient_adult_knee_pain_q10":"Combien de temps pouvez-vous marcher avant que la douleur dans votre genou devienne intense ? (avec ou sans canne) ",
  "patient_adult_knee_pain_q10_a1":"Plus de 30 minutes",
  "patient_adult_knee_pain_q10_a2":"16-30 minutes",
  "patient_adult_knee_pain_q10_a3":"5-15 minutes",
  "patient_adult_knee_pain_q10_a4":"A la maison seulement",
  "patient_adult_knee_pain_q10_a5":"Ne peut pas marcher du tout/douleur sévère en marchant",
  "patient_adult_knee_pain_q11":"Monter ou descendre les escaliers ",
  "patient_adult_knee_pain_q11_a1":"Aucune",
  "patient_adult_knee_pain_q11_a2":"Légère",
  "patient_adult_knee_pain_q11_a3":"Modérée",
  "patient_adult_knee_pain_q11_a4":"Sévère",
  "patient_adult_knee_pain_q11_a5":"Extrême",
  "patient_adult_knee_pain_q12":"Au lit la nuit ",
  "patient_adult_knee_pain_q12_a1":"Aucune",
  "patient_adult_knee_pain_q12_a2":"Légère",
  "patient_adult_knee_pain_q12_a3":"Modérée",
  "patient_adult_knee_pain_q12_a4":"Sévère",
  "patient_adult_knee_pain_q12_a5":"Extrême",
  "patient_adult_knee_pain_q13":"A quelle fréquence souffrez-vous de votre genou au lit la nuit ? ",
  "patient_adult_knee_pain_q13_a1":"Pas de nuits",
  "patient_adult_knee_pain_q13_a2":"Seulement 1 ou 2 nuits",
  "patient_adult_knee_pain_q13_a3":"Quelques nuits",
  "patient_adult_knee_pain_q13_a4":"La plupart des nuits",
  "patient_adult_knee_pain_q13_a5":"Toutes les nuits",
  "patient_adult_knee_pain_q14":"Assis(e) ou allongé(e)",
  "patient_adult_knee_pain_q14_a1":"Aucune",
  "patient_adult_knee_pain_q14_a2":"Légère",
  "patient_adult_knee_pain_q14_a3":"Modérée",
  "patient_adult_knee_pain_q14_a4":"Sévère",
  "patient_adult_knee_pain_q14_a5":"Extrême",
  "patient_adult_knee_pain_q15":"En se tenant droit debout ",
  "patient_adult_knee_pain_q15_a1":"Aucune",
  "patient_adult_knee_pain_q15_a2":"Légère",
  "patient_adult_knee_pain_q15_a3":"Modérée",
  "patient_adult_knee_pain_q15_a4":"Sévère",
  "patient_adult_knee_pain_q15_a5":"Extrême",
  "patient_adult_knee_pain_q16":"En se levant d'une chaise",
  "patient_adult_knee_pain_q16_a1":"Aucune",
  "patient_adult_knee_pain_q16_a2":"Légère",
  "patient_adult_knee_pain_q16_a3":"Modérée",
  "patient_adult_knee_pain_q16_a4":"Sévère",
  "patient_adult_knee_pain_q16_a5":"Extrême",
  "patient_adult_knee_other_symptoms":"Autres symptômes",
  "patient_adult_knee_other_symptoms_instruction1":"Veuillez choisir la réponse qui correspond le mieux aux symptômes dans votre genou. ",
  "patient_adult_knee_other_symptoms_instruction2":"Si vous n'êtes pas sûr(e) de votre réponse, veuillez répondre du mieux que vous pouvez. ",
  "patient_adult_knee_other_symptoms_instruction3":"Avez-vous les propositions suivantes : ",
  "patient_adult_knee_other_symptoms_instruction_0":"Aucun(e)(s)",
  "patient_adult_knee_other_symptoms_instruction_1":"Léger(e)(s)",
  "patient_adult_knee_other_symptoms_instruction_2":"Modéré(e)(s)",
  "patient_adult_knee_other_symptoms_instruction_3":"Sévère(s)",
  "patient_adult_knee_other_symptoms_instruction_4":"Extrême(s)",
  "patient_adult_knee_other_symptoms_q1":"Gonflement ",
  "patient_adult_knee_other_symptoms_q1_a1":"Jamais",
  "patient_adult_knee_other_symptoms_q1_a2":"Rarement",
  "patient_adult_knee_other_symptoms_q1_a3":"Parfois",
  "patient_adult_knee_other_symptoms_q1_a4":"Souvent",
  "patient_adult_knee_other_symptoms_q1_a5":"Toujours",
  "patient_adult_knee_other_symptoms_q2":"Craquements ou tout autres bruits quand vous bougez votre genou ",
  "patient_adult_knee_other_symptoms_q2_a1":"Jamais",
  "patient_adult_knee_other_symptoms_q2_a2":"Rarement",
  "patient_adult_knee_other_symptoms_q2_a3":"Parfois",
  "patient_adult_knee_other_symptoms_q2_a4":"Souvent",
  "patient_adult_knee_other_symptoms_q2_a5":"Toujours",
  "patient_adult_knee_other_symptoms_q3":"Sensations d'accrochage ou de blocage ",
  "patient_adult_knee_other_symptoms_q3_a1":"Jamais",
  "patient_adult_knee_other_symptoms_q3_a2":"Rarement",
  "patient_adult_knee_other_symptoms_q3_a3":"Parfois",
  "patient_adult_knee_other_symptoms_q3_a4":"Souvent",
  "patient_adult_knee_other_symptoms_q3_a5":"Toujours",
  "patient_adult_knee_other_symptoms_q4":"Boiterie ",
  "patient_adult_knee_other_symptoms_q4_a1":"Rarement/jamais",
  "patient_adult_knee_other_symptoms_q4_a2":"Parfois, ou seulement au début",
  "patient_adult_knee_other_symptoms_q4_a3":"Souvent, pas seulement au début",
  "patient_adult_knee_other_symptoms_q4_a4":"La plupart du temps",
  "patient_adult_knee_other_symptoms_q4_a5":"Tout le temps",
  "patient_adult_knee_other_symptoms_q5":"Au cours des 4 dernières semaines ou depuis votre blessure au genou, à quel point votre genou a-t-il été raide ou gonflé ?",
  "patient_adult_knee_other_symptoms_q5_a1":"Pas du tout",
  "patient_adult_knee_other_symptoms_q5_a2":"Légèrement",
  "patient_adult_knee_other_symptoms_q5_a3":"Modérément",
  "patient_adult_knee_other_symptoms_q5_a4":"Très",
  "patient_adult_knee_other_symptoms_q5_a5":"Extrêmement",
  "patient_adult_knee_other_symptoms_q6":"Quel est le niveau maximal d'activité que vous pouvez effectuer sans GONFLEMENT important de votre genou ? ",
  "patient_adult_knee_other_symptoms_q6_a1":"Activités très intenses comme sauter ou pivoter comme au basketball ou au football",
  "patient_adult_knee_other_symptoms_q6_a2":"Activités intenses comme du travail physique intense, skier, ou faire du tennis",
  "patient_adult_knee_other_symptoms_q6_a3":"Activités modérées comme du travail physique modéré, courir ou faire du jogging",
  "patient_adult_knee_other_symptoms_q6_a4":"Activités légères comme de la marche à pieds, des activités ménagères ou du jardinage",
  "patient_adult_knee_other_symptoms_q6_a5":"Incapable d'accomplir les activités ci-dessus à cause du gonflement du genou",
  "patient_adult_knee_other_symptoms_q7":"Quelle est la sévérité de la RAIDEUR de votre articulation : ",
  "patient_adult_knee_other_symptoms_q7_0":"Aucune",
  "patient_adult_knee_other_symptoms_q7_1":"Légère",
  "patient_adult_knee_other_symptoms_q7_2":"Modérée",
  "patient_adult_knee_other_symptoms_q7_3":"Sévère",
  "patient_adult_knee_other_symptoms_q7_4":"Extrême",
  "patient_adult_knee_other_symptoms_q7_q1":"En se réveillant le matin ? ",
  "patient_adult_knee_other_symptoms_q7_q1_a1":"Aucune",
  "patient_adult_knee_other_symptoms_q7_q1_a2":"Légère",
  "patient_adult_knee_other_symptoms_q7_q1_a3":"Modérée",
  "patient_adult_knee_other_symptoms_q7_q1_a4":"Sévère",
  "patient_adult_knee_other_symptoms_q7_q1_a5":"Extrême",
  "patient_adult_knee_other_symptoms_q7_q2":"Après s'être assis(e), allongé(e), ou reposé(e) plus tard dans la journée ? ",
  "patient_adult_knee_other_symptoms_q7_q2_a1":"Aucune ",
  "patient_adult_knee_other_symptoms_q7_q2_a2":"Légère",
  "patient_adult_knee_other_symptoms_q7_q2_a3":"Modérée",
  "patient_adult_knee_other_symptoms_q7_q2_a4":"Sévère",
  "patient_adult_knee_other_symptoms_q7_q2_a5":"Extrême",
  "patient_adult_knee_other_symptoms_q8":"Quel niveau maximal d'activité pouvez-vous effectuer sans ressentir un important dérobement de votre genou ? ",
  "patient_adult_knee_other_symptoms_q8_a1":"Pas de dérobement",
  "patient_adult_knee_other_symptoms_q8_a2":"Dérobement occasionnel avec des activités physiques ou professionnelles intenses, peut participer à n'importe quel sport avec prudence et quelques limitations. Rarement pendant des activités athlétiques ou autre effort extrême",
  "patient_adult_knee_other_symptoms_q8_a3":"Fréquemment pendant des activités athlétiques ou autre effort extrême. Dérobement occasionnel avec des activités physiques légères ou du travail modéré. Peut compenser mais avec des limitations sur les activités vigoureuses, le sport, ou du travail intense, pas capable de pivoter ou changer de direction rapidement ",
  "patient_adult_knee_other_symptoms_q8_a4":"Dérobement limite les activités physiques ou travail modéré, ne se produit pas fréquemment en marchant ou avec du travail léger (eviron 3 fois par an). Occasionnellement pendant les activités quotidiennes",
  "patient_adult_knee_other_symptoms_q8_a5":"Dérobement avec de la marche simple et du travail léger. Se produit une fois par mois, nécessite de la prudence. Souvent pendant les activités quotidiennes.",
  "patient_adult_knee_other_symptoms_q8_a6":"Problèmes sévères avec de la marche simple, dérobement à chaque pas, ne peut pas pivoter ou changer de direction en marchant sans dérobement",
  "patient_adult_knee_other_symptoms_q9":"Utilisez-vous un SUPPORT de marche ? ",
  "patient_adult_knee_other_symptoms_q9_a1":"Aucun",
  "patient_adult_knee_other_symptoms_q9_a2":"Canne ou béquille",
  "patient_adult_knee_other_symptoms_q9_a3":"Impossible de supporter son propre poids",
  "patient_adult_knee_function":"Signes fonctionnels",
  "patient_adult_knee_function_instruction":"Indiquez le NIVEAU DE DIFFICULTÉ rencontré pour effectuer les activités suivantes au cours de la dernière semaine à cause de votre genou",
  "patient_adult_knee_function_instruction_0":"Pas de difficulté",
  "patient_adult_knee_function_instruction_1":"Peu de difficulté",
  "patient_adult_knee_function_instruction_2":"Difficulté modérée",
  "patient_adult_knee_function_instruction_3":"Difficulté extrême",
  "patient_adult_knee_function_instruction_4":"Incapacité",
  "patient_adult_knee_function_note":"Note : Si vous ne faites pas une certaine activité, imaginez ce que vous ressentiriez dans votre genou si vous deviez essayer",
  "patient_adult_knee_function_q1":"Monter des marches",
  "patient_adult_knee_function_q1_a1":"Pas de difficulté",
  "patient_adult_knee_function_q1_a2":"Peu de difficulté",
  "patient_adult_knee_function_q1_a3":"Difficulté modérée",
  "patient_adult_knee_function_q1_a4":"Difficulté extrême",
  "patient_adult_knee_function_q1_a5":"Incapacité",
  "patient_adult_knee_function_q2":"Descendre des marches",
  "patient_adult_knee_function_q2_a1":"Pas de difficulté",
  "patient_adult_knee_function_q2_a2":"Peu de difficulté",
  "patient_adult_knee_function_q2_a3":"Difficulté modérée",
  "patient_adult_knee_function_q2_a4":"Difficulté extrême",
  "patient_adult_knee_function_q2_a5":"Incapacité",
  "patient_adult_knee_function_q3":"S'agenouiller sur le devant de votre genou",
  "patient_adult_knee_function_q3_a1":"Pas de difficulté",
  "patient_adult_knee_function_q3_a2":"Peu de difficulté",
  "patient_adult_knee_function_q3_a3":"Difficulté modérée",
  "patient_adult_knee_function_q3_a4":"Difficulté extrême",
  "patient_adult_knee_function_q3_a5":"Incapacité",
  "patient_adult_knee_function_q4":"Etendre votre genou",
  "patient_adult_knee_function_q4_a1":"Pas de difficulté",
  "patient_adult_knee_function_q4_a2":"Peu de difficulté",
  "patient_adult_knee_function_q4_a3":"Difficulté modérée",
  "patient_adult_knee_function_q4_a4":"Difficulté extrême",
  "patient_adult_knee_function_q4_a5":"Incapacité",
  "patient_adult_knee_function_q5":"Plier votre genou",
  "patient_adult_knee_function_q5_a1":"Pas de difficulté",
  "patient_adult_knee_function_q5_a2":"Peu de difficulté",
  "patient_adult_knee_function_q5_a3":"Difficulté modérée",
  "patient_adult_knee_function_q5_a4":"Difficulté extrême",
  "patient_adult_knee_function_q5_a5":"Incapacité",
  "patient_adult_knee_function_q6":"S'accroupir",
  "patient_adult_knee_function_q6_a1":"Pas de difficulté",
  "patient_adult_knee_function_q6_a2":"Peu de difficulté",
  "patient_adult_knee_function_q6_a3":"Difficulté modérée",
  "patient_adult_knee_function_q6_a4":"Difficulté extrême",
  "patient_adult_knee_function_q6_a5":"Incapacité",
  "patient_adult_knee_function_q7":"S'asseoir avec votre genou plié ",
  "patient_adult_knee_function_q7_a1":"Pas de difficulté",
  "patient_adult_knee_function_q7_a2":"Peu de difficulté",
  "patient_adult_knee_function_q7_a3":"Difficulté modérée",
  "patient_adult_knee_function_q7_a4":"Difficulté extrême",
  "patient_adult_knee_function_q7_a5":"Incapacité",
  "patient_adult_knee_function_q8":"Se lever d'une chaise",
  "patient_adult_knee_function_q8_a1":"Pas de difficulté",
  "patient_adult_knee_function_q8_a2":"Peu de difficulté",
  "patient_adult_knee_function_q8_a3":"Difficulté modérée",
  "patient_adult_knee_function_q8_a4":"Difficulté extrême",
  "patient_adult_knee_function_q8_a5":"Incapacité",
  "patient_adult_knee_function_q9":"Se lever du lit ",
  "patient_adult_knee_function_q9_a1":"Pas de difficulté",
  "patient_adult_knee_function_q9_a2":"Peu de difficulté",
  "patient_adult_knee_function_q9_a3":"Difficulté modérée",
  "patient_adult_knee_function_q9_a4":"Difficulté extrême",
  "patient_adult_knee_function_q9_a5":"Incapacité",
  "patient_adult_knee_function_q10":"Allongé(e) au lit (se retourner en maintenant la position du genou)",
  "patient_adult_knee_function_q10_a1":"Pas de difficulté",
  "patient_adult_knee_function_q10_a2":"Peu de difficulté",
  "patient_adult_knee_function_q10_a3":"Difficulté modérée",
  "patient_adult_knee_function_q10_a4":"Difficulté extrême",
  "patient_adult_knee_function_q10_a5":"Incapacité",
  "patient_adult_knee_function_q11":"Se baisser au sol/ramasser un objet",
  "patient_adult_knee_function_q11_a1":"Pas de difficulté",
  "patient_adult_knee_function_q11_a2":"Peu de difficulté",
  "patient_adult_knee_function_q11_a3":"Difficulté modérée",
  "patient_adult_knee_function_q11_a4":"Difficulté extrême",
  "patient_adult_knee_function_q11_a5":"Incapacité",
  "patient_adult_knee_function_q12":"Mettre des chaussettes/bas",
  "patient_adult_knee_function_q12_a1":"Pas de difficulté",
  "patient_adult_knee_function_q12_a2":"Peu de difficulté",
  "patient_adult_knee_function_q12_a3":"Difficulté modérée",
  "patient_adult_knee_function_q12_a4":"Difficulté extrême",
  "patient_adult_knee_function_q12_a5":"Incapacité",
  "patient_adult_knee_function_q13":"Retirer des chaussettes/bas",
  "patient_adult_knee_function_q13_a1":"Pas de difficulté",
  "patient_adult_knee_function_q13_a2":"Peu de difficulté",
  "patient_adult_knee_function_q13_a3":"Difficulté modérée",
  "patient_adult_knee_function_q13_a4":"Difficulté extrême",
  "patient_adult_knee_function_q13_a5":"Incapacité",
  "patient_adult_knee_function_q14":"Se tenir debout",
  "patient_adult_knee_function_q14_a1":"Pas de difficulté",
  "patient_adult_knee_function_q14_a2":"Peu de difficulté",
  "patient_adult_knee_function_q14_a3":"Difficulté modérée",
  "patient_adult_knee_function_q14_a4":"Difficulté extrême",
  "patient_adult_knee_function_q14_a5":"Incapacité",
  "patient_adult_knee_function_q15":"Marcher",
  "patient_adult_knee_function_q15_a1":"Pas de difficulté",
  "patient_adult_knee_function_q15_a2":"Peu de difficulté",
  "patient_adult_knee_function_q15_a3":"Difficulté modérée",
  "patient_adult_knee_function_q15_a4":"Difficulté extrême",
  "patient_adult_knee_function_q15_a5":"Incapacité",
  "patient_adult_knee_function_q16":"Courir droit devant",
  "patient_adult_knee_function_q16_a1":"Pas de difficulté",
  "patient_adult_knee_function_q16_a2":"Peu de difficulté",
  "patient_adult_knee_function_q16_a3":"Difficulté modérée",
  "patient_adult_knee_function_q16_a4":"Difficulté extrême",
  "patient_adult_knee_function_q16_a5":"Incapacité",
  "patient_adult_knee_function_q17":"Sauter et atterrir sur votre jambe impliquée ",
  "patient_adult_knee_function_q17_a1":"Pas de difficulté",
  "patient_adult_knee_function_q17_a2":"Peu de difficulté",
  "patient_adult_knee_function_q17_a3":"Difficulté modérée",
  "patient_adult_knee_function_q17_a4":"Difficulté extrême",
  "patient_adult_knee_function_q17_a5":"Incapacité",
  "patient_adult_knee_function_q18":"S'arrêter et démarrer rapidement",
  "patient_adult_knee_function_q18_a1":"Pas de difficulté",
  "patient_adult_knee_function_q18_a2":"Peu de difficulté",
  "patient_adult_knee_function_q18_a3":"Difficulté modérée",
  "patient_adult_knee_function_q18_a4":"Difficulté extrême",
  "patient_adult_knee_function_q18_a5":"Incapacité",
  "patient_adult_knee_function_q19":"Exercer une torsion/tourner sur votre genou blessé",
  "patient_adult_knee_function_q19_a1":"Pas de difficulté",
  "patient_adult_knee_function_q19_a2":"Peu de difficulté",
  "patient_adult_knee_function_q19_a3":"Difficulté modérée",
  "patient_adult_knee_function_q19_a4":"Difficulté extrême",
  "patient_adult_knee_function_q19_a5":"Incapacité",
  "patient_adult_knee_function_q20":"Monter ou descendre de voiture ou utiliser les transports en commun",
  "patient_adult_knee_function_q20_a1":"Pas de difficulté",
  "patient_adult_knee_function_q20_a2":"Peu de difficulté",
  "patient_adult_knee_function_q20_a3":"Difficulté modérée",
  "patient_adult_knee_function_q20_a4":"Difficulté extrême",
  "patient_adult_knee_function_q20_a5":"Incapacité",
  "patient_adult_knee_function_q21":"Entrer ou sortir d'une baignoire",
  "patient_adult_knee_function_q21_a1":"Pas de difficulté",
  "patient_adult_knee_function_q21_a2":"Peu de difficulté",
  "patient_adult_knee_function_q21_a3":"Difficulté modérée",
  "patient_adult_knee_function_q21_a4":"Difficulté extrême",
  "patient_adult_knee_function_q21_a5":"Incapacité",
  "patient_adult_knee_function_q22":"S'asseoir/se relever des toilettes",
  "patient_adult_knee_function_q22_a1":"Pas de difficulté",
  "patient_adult_knee_function_q22_a2":"Peu de difficulté",
  "patient_adult_knee_function_q22_a3":"Difficulté modérée",
  "patient_adult_knee_function_q22_a4":"Difficulté extrême",
  "patient_adult_knee_function_q22_a5":"Incapacité",
  "patient_adult_knee_function_q23":"Se laver ou se sécher",
  "patient_adult_knee_function_q23_a1":"Pas de difficulté",
  "patient_adult_knee_function_q23_a2":"Peu de difficulté",
  "patient_adult_knee_function_q23_a3":"Difficulté modérée",
  "patient_adult_knee_function_q23_a4":"Difficulté extrême",
  "patient_adult_knee_function_q23_a5":"Incapacité",
  "patient_adult_knee_function_q24":"Faire les courses",
  "patient_adult_knee_function_q24_a1":"Pas de difficulté",
  "patient_adult_knee_function_q24_a2":"Peu de difficulté",
  "patient_adult_knee_function_q24_a3":"Difficulté modérée",
  "patient_adult_knee_function_q24_a4":"Difficulté extrême",
  "patient_adult_knee_function_q24_a5":"Incapacité",
  "patient_adult_knee_function_q25":"Activités ménagères intenses (déplacer des cartons lourds, nettoyer le sol etc) ",
  "patient_adult_knee_function_q25_a1":"Pas de difficulté",
  "patient_adult_knee_function_q25_a2":"Peu de difficulté",
  "patient_adult_knee_function_q25_a3":"Difficulté modérée",
  "patient_adult_knee_function_q25_a4":"Difficulté extrême",
  "patient_adult_knee_function_q25_a5":"Incapacité",
  "patient_adult_knee_function_q26":"Activités ménagères douces (faire la cuisine, les poussières, etc)",
  "patient_adult_knee_function_q26_a1":"Pas de difficulté",
  "patient_adult_knee_function_q26_a2":"Peu de difficulté",
  "patient_adult_knee_function_q26_a3":"Difficulté modérée",
  "patient_adult_knee_function_q26_a4":"Difficulté extrême",
  "patient_adult_knee_function_q26_a5":"Incapacité",
  "patient_adult_knee_function_q27":"Sur une échelle de 0 à 10, comment évaluez-vous la fonction ACTUELLE de votre genou ? ",
  "patient_adult_knee_function_q28":"10 signifiant fonction normale et 0 signifiant incapacité à effectuer vos activités habituelles de la vie quotidienne ce qui peut comprendre les activités sportives",
  "patient_adult_knee_function_q28_a1":"Pire",
  "patient_adult_knee_function_q28_a2":"Meilleure",
  "patient_adult_knee_function_q29":"Quel est le plus haut niveau d'activité auquel vous pouvez participer de façon régulière ?",
  "patient_adult_knee_function_q29_a1":"Activités très intenses comme sauter ou pivoter comme au basketball ou au football",
  "patient_adult_knee_function_q29_a2":"Activités intenses comme du travail physique intense, skier, ou faire du tennis",
  "patient_adult_knee_function_q29_a3":"Activités modérées comme du travail physique modéré, courir ou faire du jogging",
  "patient_adult_knee_function_q29_a4":"Activités légères comme de la marche à pieds, des activités ménagères ou du jardinage",
  "patient_adult_knee_function_q29_a5":"Incapable d'effectuer les activités ci-dessus à cause du genou",
  "patient_adult_knee_function_q30":"Veuillez renseigner le niveau maximal d'activité que vous pouvez effectuer",
  "patient_adult_knee_function_q30_q1":"Avant la blessure/le problème au genou",
  "patient_adult_knee_function_q30_q1_a1":"Compétition",
  "patient_adult_knee_function_q30_q1_a2":"Loisir, activité physique",
  "patient_adult_knee_function_q30_q1_a3":"Pas de sport, seulement travail",
  "patient_adult_knee_function_q30_q1_a4":"Seulement les activités quotidiennes",
  "patient_adult_knee_function_q30_q1_a5":"Arrêt maladie ou pension d'invalidité à cause des problèmes de santé",
  "patient_adult_knee_function_q30_q1_a1_o1":"Sports de compétition qui se pratiquent à un niveau international, Olympique ou professionnel",
  "patient_adult_knee_function_q30_q1_a1_o2":"Sports de compétition comme l'athlétisme, les sports de raquette, la gymnastique, l'aviron, le ski ou les arts martiaux pratiqués à un niveau national ou international ",
  "patient_adult_knee_function_q30_q1_a1_o3":"Sports de compétition comme les sports aquatiques, le cyclisme, le hockey, le curling, ou l'équitation/rodéo pratiqués à un niveau national ou international",
  "patient_adult_knee_function_q30_q1_a2_o1":"Sports de loisirs comme la course à pieds, les sports de raquette, la musculation, le curling, l'aviron, le golf, le yoga, ou la gymnastique au moins 5 fois par semaine",
  "patient_adult_knee_function_q30_q1_a2_o2":"Sports de loisirs comme la natation, les sports aquatiques, le ski, le hockey, les rollers, le cyclisme, l'équitation, ou l'escalade au moins 5 fois par semaine",
  "patient_adult_knee_function_q30_q1_a2_o3":"Sports de loisirs au moins deux fois par semaine",
  "patient_adult_knee_function_q30_q1_a2_o4":"Sports de loisirs au moins une fois par semaine ou moins",
  "patient_adult_knee_function_q30_q1_a2_o5":"Sports de loisirs moins d'une fois par mois",
  "patient_adult_knee_function_q30_q1_a3_o1":"Travail intense comme dans le bâtiment",
  "patient_adult_knee_function_q30_q1_a3_o2":"Travail modérément intense comme camioneur",
  "patient_adult_knee_function_q30_q1_a3_o3":"Travail léger comme infirmier(ère)",
  "patient_adult_knee_function_q30_q1_a3_o4":"Travail sédentaire comme secrétariat ou travail sur ordinateur",
  "patient_adult_knee_function_q30_q1_a4_o1":"Activités quotidiennes comme le jardinage, monter plusieurs étages à pieds, porter des charges, pousser/tirer une charge, possibilité de courir en cas de retard",
  "patient_adult_knee_function_q30_q1_a4_o2":"Activités quotidiennes comme faire le ménage, monter 1 ou 2 étages, ou marcher en portant une charge légère",
  "patient_adult_knee_function_q30_q1_a4_o3":"Activités quotidiennes limitées (ex ne prends pas les escaliers, incapable de porter des charges)",
  "patient_adult_knee_function_q30_q2":"Statut actuel",
  "patient_adult_knee_function_q30_q2_a1":"Compétition",
  "patient_adult_knee_function_q30_q2_a2":"Loisir, activité physique",
  "patient_adult_knee_function_q30_q2_a3":"Pas de sport, seulement travail",
  "patient_adult_knee_function_q30_q2_a4":"Seulement les activités quotidiennes",
  "patient_adult_knee_function_q30_q2_a5":"Arrêt maladie ou pension d'invalidité à cause des problèmes de santé",
  "patient_adult_knee_function_q30_q2_a1_o1":"Sports de compétition qui se pratiquent à un niveau international, Olympique ou professionnel",
  "patient_adult_knee_function_q30_q2_a1_o2":"Sports de compétition comme l'athlétisme, les sports de raquette, la gymnastique, l'aviron, le ski ou les arts martiaux pratiqués à un niveau national ou international ",
  "patient_adult_knee_function_q30_q2_a1_o3":"Sports de compétition comme les sports aquatiques, le cyclisme, le hockey, le curling, ou l'équitation/rodéo pratiqués à un niveau national ou international",
  "patient_adult_knee_function_q30_q2_a2_o1":"Sports de loisirs comme la course à pieds, les sports de raquette, la musculation, le curling, l'aviron, le golf, le yoga, ou la gymnastique au moins 5 fois par semaine",
  "patient_adult_knee_function_q30_q2_a2_o2":"Sports de loisirs comme la natation, les sports aquatiques, le ski, le hockey, les rollers, le cyclisme, l'équitation, ou l'escalade au moins 5 fois par semaine",
  "patient_adult_knee_function_q30_q2_a2_o3":"Sports de loisirs au moins deux fois par semaine",
  "patient_adult_knee_function_q30_q2_a2_o4":"Sports de loisirs au moins une fois par semaine ou moins",
  "patient_adult_knee_function_q30_q2_a2_o5":"Sports de loisir moins d'une fois par mois",
  "patient_adult_knee_function_q30_q2_a3_o1":"Travail intense comme dans le bâtiment",
  "patient_adult_knee_function_q30_q2_a3_o2":"Travail modérément intense comme camioneur",
  "patient_adult_knee_function_q30_q2_a3_o3":"Travail léger comme infirmier(ère)",
  "patient_adult_knee_function_q30_q2_a3_o4":"Travail sédentaire comme secrétariat ou travail sur ordinateur",
  "patient_adult_knee_function_q30_q2_a4_o1":"Activités quotidiennes comme le jardinage, monter plusieurs étages à pieds, porter des charges, pousser/tirer une charge, possibilité de courir en cas de retard",
  "patient_adult_knee_function_q30_q2_a4_o2":"Activités quotidiennes comme le ménage, monter 1 ou 2 étages, ou marcher en portant une charge légère",
  "patient_adult_knee_function_q30_q2_a4_o3":"Activités quotidiennes limitées (ex ne prends pas les escaliers, incapable de porter des charges)",
  "patient_adult_knee_function_q31":"Est-ce que votre rotule se déplace ou avez-vous la sensation que votre rotule se déplace ? ",
  "patient_adult_knee_function_q31_a1":"Non",
  "patient_adult_knee_function_q31_a2":"Oui",
  "patient_adult_knee_patellofemoral_instability":"Instabilité fémoro-patellaire",
  "patient_adult_knee_patellofemoral_instability_instruction":"Veuillez choisir ce qui décrit le mieux À QUELLE FRÉQUENCE VOUS AVEZ L'IMPRESSION QUE VOTRE ROTULE VA SE DEBOÎTER OU SEMBLE INSTABLE quand vous effectuez les activités suivantes ",
  "patient_adult_knee_patellofemoral_instability_instruction_0":"Jamais",
  "patient_adult_knee_patellofemoral_instability_instruction_1":"Rarement",
  "patient_adult_knee_patellofemoral_instability_instruction_2":"Parfois",
  "patient_adult_knee_patellofemoral_instability_instruction_3":"Souvent",
  "patient_adult_knee_patellofemoral_instability_instruction_4":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q1":"Pivoter/Changer de direction pendant une activité sportive ",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q1_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q1_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q1_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q1_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q1_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q2":"Changer de direction en courant",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q2_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q2_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q2_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q2_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q2_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q3":"Courir en ligne droite sur des surfaces IRRÉGULIÈRES",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q3_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q3_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q3_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q3_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q3_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q4":"Marcher sur des surfaces glissantes, mouillées ou gelées",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q4_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q4_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q4_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q4_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q4_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q5":"Courir de côté",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q5_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q5_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q5_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q5_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q5_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q6":"Sauter à cloche-pied",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q6_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q6_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q6_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q6_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q6_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q7":"Sauter",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q7_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q7_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q7_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q7_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q7_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q8":"Courir en ligne droite sur des surfaces PLANES",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q8_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q8_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q8_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q8_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q8_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q9":"Descendre des marches",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q9_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q9_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q9_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q9_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q9_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q10":"S'accroupir",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q10_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q10_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q10_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q10_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q10_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q11":"S'agenouiller",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q11_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q11_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q11_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q11_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q11_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q12":"Marcher en ligne droite sur des surfaces IRRÉGULIÈRES",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q12_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q12_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q12_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q12_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q12_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q13":"Monter des marches",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q13_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q13_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q13_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q13_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q13_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q14":"Enjamber un obstacle",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q14_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q14_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q14_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q14_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q14_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q15":"Croiser les james en étant assis(e)",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q15_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q15_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q15_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q15_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q15_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q16":"Marcher en ligne droite sur des surfaces PLANES",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q16_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q16_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q16_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q16_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q16_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q17":"Monter ou descendre de voiture",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q17_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q17_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q17_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q17_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q17_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q18":"Tourner un caddie lourd dans une allée de supermarché ",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q18_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q18_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q18_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q18_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q18_a5":"Toujours",
  "patient_adult_knee_patellofemoral_instability_q19":"Se tourner pour regarder au dessus de votre épaule",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do":"Ne fait pas",
  "patient_adult_knee_patellofemoral_instability_q19_a1":"Jamais",
  "patient_adult_knee_patellofemoral_instability_q19_a2":"Rarement",
  "patient_adult_knee_patellofemoral_instability_q19_a3":"Parfois",
  "patient_adult_knee_patellofemoral_instability_q19_a4":"Souvent",
  "patient_adult_knee_patellofemoral_instability_q19_a5":"Toujours",
  "patient_adult_knee_quality_of_life":"Qualité de vie",
  "patient_adult_knee_quality_of_life_instruction":"Les questions suivantes concernent les inquiétudes sociales, émotionnelles et de mode de vie que vous pouvez ressentir en rapport avec votre problème de genou. Veuillez penser à ce que vous avez ressenti la plupart du temps en relation avec votre problème de genou. ",
  "patient_adult_knee_quality_of_life_q1":"A quelle fréquence êtes-vous conscient(e) de votre problème de genou ? ",
  "patient_adult_knee_quality_of_life_q1_a1":"Jamais",
  "patient_adult_knee_quality_of_life_q1_a2":"Tous les mois",
  "patient_adult_knee_quality_of_life_q1_a3":"Toutes les semaines",
  "patient_adult_knee_quality_of_life_q1_a4":"Tous les jours",
  "patient_adult_knee_quality_of_life_q1_a5":"Constamment",
  "patient_adult_knee_quality_of_life_q2":"Avez-vous modifier votre quotidien pour éviter des activités qui pourraient potentiellement détériorer votre genou ? ",
  "patient_adult_knee_quality_of_life_q2_a1":"Pas du tout",
  "patient_adult_knee_quality_of_life_q2_a2":"Légèrement",
  "patient_adult_knee_quality_of_life_q2_a3":"Modérément",
  "patient_adult_knee_quality_of_life_q2_a4":"Fortement",
  "patient_adult_knee_quality_of_life_q2_a5":"Totalement",
  "patient_adult_knee_quality_of_life_q3":"A quel point êtes-vous préoccupé(e) par votre manque de confiance en votre genou ? ",
  "patient_adult_knee_quality_of_life_q3_a1":"Pas du tout",
  "patient_adult_knee_quality_of_life_q3_a2":"Légèrement",
  "patient_adult_knee_quality_of_life_q3_a3":"Modérément",
  "patient_adult_knee_quality_of_life_q3_a4":"Fortement",
  "patient_adult_knee_quality_of_life_q3_a5":"Totalement",
  "patient_adult_knee_quality_of_life_q4":"De façon générale, quel niveau de difficulté avez-vous avec votre genou ? ",
  "patient_adult_knee_quality_of_life_q4_a1":"Aucune",
  "patient_adult_knee_quality_of_life_q4_a2":"Légère",
  "patient_adult_knee_quality_of_life_q4_a3":"Modérée",
  "patient_adult_knee_quality_of_life_q4_a4":"Sévère",
  "patient_adult_knee_quality_of_life_q4_a5":"Extrême",
  "patient_adult_knee_quality_of_life_q5":"Comment évaluez-vous votre niveau d'anxiété/dépression aujourd'hui ? ",
  "patient_adult_knee_quality_of_life_q5_a1":"Pas anxieux(se)/déprimé(e)",
  "patient_adult_knee_quality_of_life_q5_a2":"Légèrement ",
  "patient_adult_knee_quality_of_life_q5_a3":"Modérément",
  "patient_adult_knee_quality_of_life_q5_a4":"Fortement",
  "patient_adult_knee_quality_of_life_q5_a5":"Extrêmement",
  "patient_adult_knee_quality_of_life_q6":"Comment évaluez-vous votre santé aujourd'hui ?",
  "patient_adult_knee_quality_of_life_q6_instruction":"100 signifie meilleure santé et 0 signifie la pire santé que vous pouvez imaginer",
  "patient_adult_knee_quality_of_life_q6_instruction_a1":"Pire",
  "patient_adult_knee_quality_of_life_q6_instruction_a2":"Meilleure",
  "patient_adult_knee_satisfaction":"Satisfaction",
  "patient_adult_knee_satisfaction_instructions":"Quel est votre niveau de satisfaction avec les résultats de votre chirurgie/traitement du genou ? ",
  "patient_adult_knee_satisfaction_instructions_1":"Très insatisfait(e)",
  "patient_adult_knee_satisfaction_instructions_2":"Légèrement insatisfait(e)",
  "patient_adult_knee_satisfaction_instructions_3":"Légèrement satisfait(e)",
  "patient_adult_knee_satisfaction_instructions_4":"Très satisfait(e)",
  "patient_adult_knee_satisfaction_q1":"De façon générale ",
  "patient_adult_knee_satisfaction_q1_not_applicable":"Non applicable",
  "patient_adult_knee_satisfaction_q1_a1":"Très insatisfait(e)",
  "patient_adult_knee_satisfaction_q1_a2":"Légèrement insatisfait(e)",
  "patient_adult_knee_satisfaction_q1_a3":"Légèrement satisfait(e)",
  "patient_adult_knee_satisfaction_q1_a4":"Très satisfait(e)",
  "patient_adult_knee_satisfaction_q2":"Pour atténuer la douleur ? ",
  "patient_adult_knee_satisfaction_q2_not_applicable":"Non applicable",
  "patient_adult_knee_satisfaction_q2_a1":"Très insatisfait(e)",
  "patient_adult_knee_satisfaction_q2_a2":"Légèrement insatisfait(e)",
  "patient_adult_knee_satisfaction_q2_a3":"Légèrement satisfait(e)",
  "patient_adult_knee_satisfaction_q2_a4":"Très satisfait(e)",
  "patient_adult_knee_satisfaction_q3":"Pour améliorer votre capacité à effectuer des tâches ménagères ou du jardinage ? ",
  "patient_adult_knee_satisfaction_q3_not_applicable":"Non applicable",
  "patient_adult_knee_satisfaction_q3_a1":"Très insatisfait(e)",
  "patient_adult_knee_satisfaction_q3_a2":"Légèrement insatisfait(e)",
  "patient_adult_knee_satisfaction_q3_a3":"Légèrement satisfait(e)",
  "patient_adult_knee_satisfaction_q3_a4":"Très satisfait(e)",
  "patient_adult_knee_satisfaction_q4":"Pour améliorer votre capacité à effectuer des activités de loisir ? ",
  "patient_adult_knee_satisfaction_q4_not_applicable":"Non applicable",
  "patient_adult_knee_satisfaction_q4_a1":"Très insatisfait(e)",
  "patient_adult_knee_satisfaction_q4_a2":"Légèrement insatisfait(e)",
  "patient_adult_knee_satisfaction_q4_a3":"Légèrement satisfait(e)",
  "patient_adult_knee_satisfaction_q4_a4":"Très satisfait(e)",
  "patient_adult_knee_complete":"Fin du questionnaire",
  "patient_adult_knee_complete_text1":"Merci d'avoir pris le temps de remplir ce formulaire. ",
  "patient_adult_knee_complete_text2":"Veuillez cliquer Envoyer pour terminer et visualiser vos scores. ",
  "patient_adult_knee_complete_text3":"Vos réponses seront traitées de façon confidentielle et tous rapports utiliseront seulement des informations anonymes. ",
  "patient_adult_knee_results":"Résultats",
  "patient_adult_knee_results_text1":"Vos scores de douleur et de résultats fonctionnels du genou calculés à partir de votre questionnaire sont représentés ci-dessous. ",
  "patient_adult_knee_results_pain":"Douleur",
  "patient_adult_knee_results_function":"Signes fonctionnels",
  "patient_adult_knee_results_health_perception":"Perception de santé générale",
  "patient_adult_knee_results_self_evaluation":"Score d'auto-évaluation du Genou",
  "patient_adult_knee_results_text2":"Votre praticien sera avec vous d'ici peu. ",
  "patient_adolescent_knee_pain":"Douleur",
  "patient_adolescent_knee_function_q23":"Faire les courses ",
  "patient_adolescent_knee_function_q23_a1":"Pas de difficulté",
  "patient_adolescent_knee_function_q23_a2":"Peu de difficulté",
  "patient_adolescent_knee_function_q23_a3":"Difficulté modérée",
  "patient_adolescent_knee_function_q23_a4":"Difficulté extrême ",
  "patient_adolescent_knee_function_q23_a5":"Incapacité",
  "patient_pedia_knee_pain":"Douleur",
  "patient_pedia_knee_pain_q1":"Ces 4 dernières semaines ou depuis ta blessure du genou, combien de fois ton genou blessé était-il douloureux ?",
  "patient_pedia_knee_pain_q1_a1":"Jamais de douleur",
  "patient_pedia_knee_pain_q1_a2":"Rarement",
  "patient_pedia_knee_pain_q1_a3":"Parfois",
  "patient_pedia_knee_pain_q1_a4":"Souvent",
  "patient_pedia_knee_pain_q1_a5":"Constamment",
  "patient_pedia_knee_pain_q2":"Comment évalues-tu la douleur dans ton genou blessé aujourd'hui ? ",
  "patient_pedia_knee_pain_q2_a1":"Ne fais pas mal du tout ",
  "patient_pedia_knee_pain_q2_a2":"Ca fait tellement mal que je ne peux pas le tolérer",
  "patient_pedia_knee_pain_instructions":"Quelle intensité de douleur as-tu ressentie au cours des 7 derniers jours en effectuant les activités suivantes ?",
  "patient_pedia_knee_pain_instructions_0":"Pas de douleur",
  "patient_pedia_knee_pain_instructions_1":"Un petit peu de douleur ",
  "patient_pedia_knee_pain_instructions_2":"Un peu de douleur",
  "patient_pedia_knee_pain_instructions_3":"Beaucoup de douleur",
  "patient_pedia_knee_pain_instructions_4":"Douleur extrême",
  "patient_pedia_knee_pain_note":"Note : Réponds aux questions suivantes en relation avec tes activités du genou. ",
  "patient_pedia_knee_pain_q3":"Exercer une torsion ou pivoter en marchant/se tenant debout/courant ",
  "patient_pedia_knee_pain_q3_a1":"Pas de douleur",
  "patient_pedia_knee_pain_q3_a2":"Un petit peu de douleur ",
  "patient_pedia_knee_pain_q3_a3":"Un peu de douleur",
  "patient_pedia_knee_pain_q3_a4":"Beaucoup de douleur",
  "patient_pedia_knee_pain_q3_a5":"Douleur extrême",
  "patient_pedia_knee_pain_q4":"Etendre complètement ton genou",
  "patient_pedia_knee_pain_q4_a1":"Pas de douleur",
  "patient_pedia_knee_pain_q4_a2":"Un petit peu de douleur ",
  "patient_pedia_knee_pain_q4_a3":"Un peu de douleur",
  "patient_pedia_knee_pain_q4_a4":"Beaucoup de douleur",
  "patient_pedia_knee_pain_q4_a5":"Douleur extrême",
  "patient_pedia_knee_pain_q5":"Plier complètement ton genou",
  "patient_pedia_knee_pain_q5_a1":"Pas de douleur",
  "patient_pedia_knee_pain_q5_a2":"Un petit peu de douleur ",
  "patient_pedia_knee_pain_q5_a3":"Un peu de douleur",
  "patient_pedia_knee_pain_q5_a4":"Beaucoup de douleur",
  "patient_pedia_knee_pain_q5_a5":"Douleur extrême",
  "patient_pedia_knee_pain_q6":"Monter des marches",
  "patient_pedia_knee_pain_q6_a1":"Pas de douleur",
  "patient_pedia_knee_pain_q6_a2":"Un petit peu de douleur ",
  "patient_pedia_knee_pain_q6_a3":"Un peu de douleur",
  "patient_pedia_knee_pain_q6_a4":"Beaucoup de douleur",
  "patient_pedia_knee_pain_q6_a5":"Douleur extrême",
  "patient_pedia_knee_pain_q7":"Descendre des marches",
  "patient_pedia_knee_pain_q7_a1":"Pas de douleur",
  "patient_pedia_knee_pain_q7_a2":"Un petit peu de douleur ",
  "patient_pedia_knee_pain_q7_a3":"Un peu de douleur",
  "patient_pedia_knee_pain_q7_a4":"Beaucoup de douleur",
  "patient_pedia_knee_pain_q7_a5":"Douleur extrême",
  "patient_pedia_knee_pain_q8":"S'asseoir avec ton genou plié ",
  "patient_pedia_knee_pain_q8_a1":"Pas de douleur",
  "patient_pedia_knee_pain_q8_a2":"Un petit peu de douleur ",
  "patient_pedia_knee_pain_q8_a3":"Un peu de douleur",
  "patient_pedia_knee_pain_q8_a4":"Beaucoup de douleur",
  "patient_pedia_knee_pain_q8_a5":"Douleur extrême",
  "patient_pedia_knee_pain_q9":"Se tenir debout avec les deux jambes tendues pour n'importe quelle durée",
  "patient_pedia_knee_pain_q9_a1":"Pas de douleur",
  "patient_pedia_knee_pain_q9_a2":"Un petit peu de douleur ",
  "patient_pedia_knee_pain_q9_a3":"Un peu de douleur",
  "patient_pedia_knee_pain_q9_a4":"Beaucoup de douleur",
  "patient_pedia_knee_pain_q9_a5":"Douleur extrême",
  "patient_pedia_knee_other_symptoms":"Autres symptômes",
  "patient_pedia_knee_other_symptoms_instruction1":"A quelle fréquence ressens-tu les propositions suivantes : ",
  "patient_pedia_knee_other_symptoms_instruction1_0":"Jamais",
  "patient_pedia_knee_other_symptoms_instruction1_1":"Rarement",
  "patient_pedia_knee_other_symptoms_instruction1_2":"Parfois",
  "patient_pedia_knee_other_symptoms_instruction1_3":"Souvent",
  "patient_pedia_knee_other_symptoms_instruction1_4":"Toujours",
  "patient_pedia_knee_other_symptoms_q1":"Le genou fait du bruit/des sons",
  "patient_pedia_knee_other_symptoms_q1_a1":"Jamais",
  "patient_pedia_knee_other_symptoms_q1_a2":"Rarement",
  "patient_pedia_knee_other_symptoms_q1_a3":"Parfois",
  "patient_pedia_knee_other_symptoms_q1_a4":"Souvent",
  "patient_pedia_knee_other_symptoms_q1_a5":"Toujours",
  "patient_pedia_knee_other_symptoms_q2":"Le genou se coince ou se bloque ",
  "patient_pedia_knee_other_symptoms_q2_a1":"Jamais",
  "patient_pedia_knee_other_symptoms_q2_a2":"Rarement",
  "patient_pedia_knee_other_symptoms_q2_a3":"Parfois",
  "patient_pedia_knee_other_symptoms_q2_a4":"Souvent",
  "patient_pedia_knee_other_symptoms_q2_a5":"Toujours",
  "patient_pedia_knee_other_symptoms_q3":"Avoir l'impression que le genou se bloque mais que tu peux quand même le bouger",
  "patient_pedia_knee_other_symptoms_q3_a1":"Jamais",
  "patient_pedia_knee_other_symptoms_q3_a2":"Rarement",
  "patient_pedia_knee_other_symptoms_q3_a3":"Parfois",
  "patient_pedia_knee_other_symptoms_q3_a4":"Souvent",
  "patient_pedia_knee_other_symptoms_q3_a5":"Toujours",
  "patient_pedia_knee_other_symptoms_q4":"Genou gonflé/enflé ",
  "patient_pedia_knee_other_symptoms_q4_a1":"Jamais",
  "patient_pedia_knee_other_symptoms_q4_a2":"Rarement",
  "patient_pedia_knee_other_symptoms_q4_a3":"Parfois",
  "patient_pedia_knee_other_symptoms_q4_a4":"Souvent",
  "patient_pedia_knee_other_symptoms_q4_a5":"Toujours",
  "patient_pedia_knee_other_symptoms_q5":"Décris à quel point ton genou était BOURSOUFLÉ (OU GONFLÉ) ? ",
  "patient_pedia_knee_other_symptoms_q5_a1":"Pas du tout boursouflé",
  "patient_pedia_knee_other_symptoms_q5_a2":"Un petit peu boursouflé",
  "patient_pedia_knee_other_symptoms_q5_a3":"Un peu boursouflé",
  "patient_pedia_knee_other_symptoms_q5_a4":"Très boursouflé",
  "patient_pedia_knee_other_symptoms_q5_a5":"Extrêmement boursouflé",
  "patient_pedia_knee_other_symptoms_instruction2":"Si on te demandait de faire les activités suivantes, quel est le maximum que tu pourrais faire AUJOURD'HUI ...",
  "patient_pedia_knee_other_symptoms_instruction2_0":"Activités très difficiles comme sauter or tourner rapidement comme au basketball ou au football",
  "patient_pedia_knee_other_symptoms_instruction2_1":"Activités difficiles comme soulever des poids, skier ou faire du tennis",
  "patient_pedia_knee_other_symptoms_instruction2_2":"Activités un peu difficiles comme marcher vite ou courir",
  "patient_pedia_knee_other_symptoms_instruction2_3":"Activités faciles comme marcher à une vitesse normale",
  "patient_pedia_knee_other_symptoms_instruction2_4":"Je ne peux faire aucune des activités citées ci-dessus",
  "patient_pedia_knee_other_symptoms_q6":"Sans que ton genou FASSE TRÈS MAL ",
  "patient_pedia_knee_other_symptoms_q6_a1":"Activités très difficiles",
  "patient_pedia_knee_other_symptoms_q6_a2":"Activités difficiles",
  "patient_pedia_knee_other_symptoms_q6_a3":"Activités un peu difficiles",
  "patient_pedia_knee_other_symptoms_q6_a4":"Activités faciles",
  "patient_pedia_knee_other_symptoms_q6_a5":"Je ne peux faire aucune des activités ci-dessus",
  "patient_pedia_knee_other_symptoms_q7":"Sans rendre ton genou BOURSOUFLÉ (OU GONFLÉ) ",
  "patient_pedia_knee_other_symptoms_q7_a1":"Activités très difficiles",
  "patient_pedia_knee_other_symptoms_q7_a2":"Activités difficiles",
  "patient_pedia_knee_other_symptoms_q7_a3":"Activités un peu difficiles",
  "patient_pedia_knee_other_symptoms_q7_a4":"Activités faciles",
  "patient_pedia_knee_other_symptoms_q7_a5":"Je ne peux faire aucune des activités ci-dessus",
  "patient_pedia_knee_other_symptoms_q8":"Sans avoir l'impression que ton genou NE PEUT PLUS TENIR",
  "patient_pedia_knee_other_symptoms_q8_a1":"Activités très difficiles",
  "patient_pedia_knee_other_symptoms_q8_a2":"Activités difficiles",
  "patient_pedia_knee_other_symptoms_q8_a3":"Activités un peu difficiles",
  "patient_pedia_knee_other_symptoms_q8_a5":"Je ne peux faire aucune des activités ci-dessus",
  "patient_pedia_knee_other_symptoms_q9":"La plupart du temps",
  "patient_pedia_knee_other_symptoms_q9_a1":"Activités très difficiles",
  "patient_pedia_knee_other_symptoms_q9_a2":"Activités difficiles",
  "patient_pedia_knee_other_symptoms_q9_a3":"Activités un peu difficiles",
  "patient_pedia_knee_other_symptoms_q9_a4":"Activités faciles",
  "patient_pedia_knee_other_symptoms_q9_a5":"Je ne peux faire aucune des activités ci-dessus",
  "patient_pedia_knee_function":"Signes fonctionnels",
  "patient_pedia_knee_function_instruction":"Quel niveau de difficulté as-tu rencontré pour faire les activités suivantes à cause de ton genou : ",
  "patient_pedia_knee_function_instruction_0":"Pas de difficulté",
  "patient_pedia_knee_function_instruction_1":"Un petit peu",
  "patient_pedia_knee_function_instruction_2":"Un peu",
  "patient_pedia_knee_function_instruction_3":"Beaucoup",
  "patient_pedia_knee_function_instruction_4":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_note":"Note: Si tu ne fais pas une certaine activité, imagine ce que tu ressentirais dans ton genou si tu devais essayer",
  "patient_pedia_knee_function_q1":"Monter des marches",
  "patient_pedia_knee_function_q1_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q1_a2":"Un petit peu",
  "patient_pedia_knee_function_q1_a3":"Un peu",
  "patient_pedia_knee_function_q1_a4":"Beaucoup",
  "patient_pedia_knee_function_q1_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q2":"Descendre des marches",
  "patient_pedia_knee_function_q2_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q2_a2":"Un petit peu",
  "patient_pedia_knee_function_q2_a3":"Un peu",
  "patient_pedia_knee_function_q2_a4":"Beaucoup",
  "patient_pedia_knee_function_q2_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q3":"S'agenouiller sur le devant de ton genou",
  "patient_pedia_knee_function_q3_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q3_a2":"Un petit peu",
  "patient_pedia_knee_function_q3_a3":"Un peu",
  "patient_pedia_knee_function_q3_a4":"Beaucoup",
  "patient_pedia_knee_function_q3_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q4":"Etendre complètement le genou",
  "patient_pedia_knee_function_q4_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q4_a2":"Un petit peu",
  "patient_pedia_knee_function_q4_a3":"Un peu",
  "patient_pedia_knee_function_q4_a4":"Beaucoup",
  "patient_pedia_knee_function_q4_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q5":"Plier complètement le genou",
  "patient_pedia_knee_function_q5_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q5_a2":"Un petit peu",
  "patient_pedia_knee_function_q5_a3":"Un peu",
  "patient_pedia_knee_function_q5_a4":"Beaucoup",
  "patient_pedia_knee_function_q5_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q6":"Bouger le genou juste après s'être réveiller le matin ",
  "patient_pedia_knee_function_q6_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q6_a2":"Un petit peu",
  "patient_pedia_knee_function_q6_a3":"Un peu",
  "patient_pedia_knee_function_q6_a4":"Beaucoup",
  "patient_pedia_knee_function_q6_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q7":"Bouger ton genou plus tard dans la journée après avoir été sédentaire ",
  "patient_pedia_knee_function_q7_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q7_a2":"Un petit peu",
  "patient_pedia_knee_function_q7_a3":"Un peu",
  "patient_pedia_knee_function_q7_a4":"Beaucoup",
  "patient_pedia_knee_function_q7_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q8":"S'accroupir comme un joueur de baseball ",
  "patient_pedia_knee_function_q8_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q8_a2":"Un petit peu",
  "patient_pedia_knee_function_q8_a3":"Un peu",
  "patient_pedia_knee_function_q8_a4":"Beaucoup",
  "patient_pedia_knee_function_q8_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q9":"S'asseoir sur une chaise avec ton genou plié et les pieds à plat au sol ",
  "patient_pedia_knee_function_q9_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q9_a2":"Un petit peu",
  "patient_pedia_knee_function_q9_a3":"Un peu",
  "patient_pedia_knee_function_q9_a4":"Beaucoup",
  "patient_pedia_knee_function_q9_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q10":"Se lever d'une chaise ",
  "patient_pedia_knee_function_q10_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q10_a2":"Un petit peu",
  "patient_pedia_knee_function_q10_a3":"Un peu",
  "patient_pedia_knee_function_q10_a4":"Beaucoup",
  "patient_pedia_knee_function_q10_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q11":"Se baisser et ramasser un objet au sol ",
  "patient_pedia_knee_function_q11_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q11_a2":"Un petit peu",
  "patient_pedia_knee_function_q11_a3":"Un peu",
  "patient_pedia_knee_function_q11_a4":"Beaucoup",
  "patient_pedia_knee_function_q11_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q12":"Courir en jouant ou pendant des activités sportives",
  "patient_pedia_knee_function_q12_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q12_a2":"Un petit peu",
  "patient_pedia_knee_function_q12_a3":"Un peu",
  "patient_pedia_knee_function_q12_a4":"Beaucoup",
  "patient_pedia_knee_function_q12_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q13":"Sauter en jouant ou pendant des activités sportives",
  "patient_pedia_knee_function_q13_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q13_a2":"Un petit peu",
  "patient_pedia_knee_function_q13_a3":"Un peu",
  "patient_pedia_knee_function_q13_a4":"Beaucoup",
  "patient_pedia_knee_function_q13_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q14":"Démarrer ou s'arrêter brusquement ",
  "patient_pedia_knee_function_q14_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q14_a2":"Un petit peu",
  "patient_pedia_knee_function_q14_a3":"Un peu",
  "patient_pedia_knee_function_q14_a4":"Beaucoup",
  "patient_pedia_knee_function_q14_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q15":"Exercer une torsion/pivoter en jouant ou pendant des activités sportives ",
  "patient_pedia_knee_function_q15_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q15_a2":"Un petit peu",
  "patient_pedia_knee_function_q15_a3":"Un peu",
  "patient_pedia_knee_function_q15_a4":"Beaucoup",
  "patient_pedia_knee_function_q15_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q16":"Maintenir ton équilibre en marchant/courant sur des surfaces irrégulières ",
  "patient_pedia_knee_function_q16_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q16_a2":"Un petit peu",
  "patient_pedia_knee_function_q16_a3":"Un peu",
  "patient_pedia_knee_function_q16_a4":"Beaucoup",
  "patient_pedia_knee_function_q16_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q17":"Faire du sport ",
  "patient_pedia_knee_function_q17_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q17_a2":"Un petit peu",
  "patient_pedia_knee_function_q17_a3":"Un peu",
  "patient_pedia_knee_function_q17_a4":"Beaucoup",
  "patient_pedia_knee_function_q17_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q18":"Monter/descendre de voiture ",
  "patient_pedia_knee_function_q18_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q18_a2":"Un petit peu",
  "patient_pedia_knee_function_q18_a3":"Un peu",
  "patient_pedia_knee_function_q18_a4":"Beaucoup",
  "patient_pedia_knee_function_q18_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q19":"Sortir du lit ",
  "patient_pedia_knee_function_q19_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q19_a2":"Un petit peu",
  "patient_pedia_knee_function_q19_a3":"Un peu",
  "patient_pedia_knee_function_q19_a4":"Beaucoup",
  "patient_pedia_knee_function_q19_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q20":"Changer la position du genou en étant allongé(e)au lit ",
  "patient_pedia_knee_function_q20_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q20_a2":"Un petit peu",
  "patient_pedia_knee_function_q20_a3":"Un peu",
  "patient_pedia_knee_function_q20_a4":"Beaucoup",
  "patient_pedia_knee_function_q20_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q21":"Entrer/sortir de la baignoire/douche ",
  "patient_pedia_knee_function_q21_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q21_a2":"Un petit peu",
  "patient_pedia_knee_function_q21_a3":"Un peu",
  "patient_pedia_knee_function_q21_a4":"Beaucoup",
  "patient_pedia_knee_function_q21_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q22":"Porter des sacs lourds/sac-à-dos etc ",
  "patient_pedia_knee_function_q22_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q22_a2":"Un petit peu",
  "patient_pedia_knee_function_q22_a3":"Un peu",
  "patient_pedia_knee_function_q22_a4":"Beaucoup",
  "patient_pedia_knee_function_q22_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q23":"Faire des activités ménagères légères comme ranger ta chambre, remplir/vider le lave-vaisselle, faire ton lit, etc",
  "patient_pedia_knee_function_q23_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q23_a2":"Un petit peu",
  "patient_pedia_knee_function_q23_a3":"Un peu",
  "patient_pedia_knee_function_q23_a4":"Beaucoup",
  "patient_pedia_knee_function_q23_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q24":"Aller à l'école ou marcher dans l'école (monter des marches, ouvrir des portes, à la récré)",
  "patient_pedia_knee_function_q24_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q24_a2":"Un petit peu",
  "patient_pedia_knee_function_q24_a3":"Un peu",
  "patient_pedia_knee_function_q24_a4":"Beaucoup",
  "patient_pedia_knee_function_q24_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q25":"Sortir avec des amis",
  "patient_pedia_knee_function_q25_a1":"Pas de difficulté",
  "patient_pedia_knee_function_q25_a2":"Un petit peu",
  "patient_pedia_knee_function_q25_a3":"Un peu",
  "patient_pedia_knee_function_q25_a4":"Beaucoup",
  "patient_pedia_knee_function_q25_a5":"Difficulté extrême / ne peut pas faire",
  "patient_pedia_knee_function_q26":"Comment fonctionnait ton genou AVANT QUE TU TE BLESSES ?",
  "patient_pedia_knee_function_q26_instruction":"10 signifie normal et 0 signifie incapacité à effectuer tes activités de la vie quotidienne habituelles, activités sportives inclues",
  "patient_pedia_knee_function_q26_instruction_a1":"Je ne pouvais rien faire du tout",
  "patient_pedia_knee_function_q26_instruction_a2":"Je pouvais faire tout ce que je voulais",
  "patient_pedia_knee_function_q27":"Comment fonctionne ton genou MAINTENANT ? ",
  "patient_pedia_knee_function_q27_instruction":"10 signifie normal et 0 signifie incapacité à effectuer tes activités de la vie quotidienne habituelles, activités sportives inclues",
  "patient_pedia_knee_function_q27_instruction_a1":"Je ne peux rien faire du tout",
  "patient_pedia_knee_function_q27_instruction_a2":"Je peux faire tout ce que je veux",
  "patient_pedia_knee_function_q28":"Est-ce que ta rotule se déplace ou as-tu la sensation que ta rotule se déplace ? ",
  "patient_pedia_knee_function_q28_a1":"Non",
  "patient_pedia_knee_function_q28_a2":"Oui",
  "patient_pedia_knee_instability":"Instabilité fémoro-patellaire",
  "patient_pedia_knee_instability_instruction":"Choisis ce qui décrit le mieux À QUELLE FRÉQUENCE TU AS L'IMPRESSION QUE TA ROTULE VA SE DEBOÎTER OU SEMBLE INSTABLE quand tu effectues les activités suivantes ",
  "patient_pedia_knee_instability_instruction_0":"Jamais",
  "patient_pedia_knee_instability_instruction_1":"Rarement",
  "patient_pedia_knee_instability_instruction_2":"Parfois",
  "patient_pedia_knee_instability_instruction_3":"Souvent",
  "patient_pedia_knee_instability_instruction_4":"Toujours",
  "patient_pedia_knee_instability_note":"Note : Réponds aux questions suivantes en relation avec tes activités du genou",
  "patient_pedia_knee_instability_q1":"Pivoter/Changer de direction pendant une activité sportive ",
  "patient_pedia_knee_instability_q1_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q1_a1":"Jamais",
  "patient_pedia_knee_instability_q1_a2":"Rarement",
  "patient_pedia_knee_instability_q1_a3":"Parfois",
  "patient_pedia_knee_instability_q1_a4":"Souvent",
  "patient_pedia_knee_instability_q1_a5":"Toujours",
  "patient_pedia_knee_instability_q2":"Changer de direction en courant",
  "patient_pedia_knee_instability_q2_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q2_a1":"Jamais",
  "patient_pedia_knee_instability_q2_a2":"Rarement",
  "patient_pedia_knee_instability_q2_a3":"Parfois",
  "patient_pedia_knee_instability_q2_a4":"Souvent",
  "patient_pedia_knee_instability_q2_a5":"Toujours",
  "patient_pedia_knee_instability_q3":"Courir en ligne droite sur des surfaces IRREGULIÈRES ",
  "patient_pedia_knee_instability_q3_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q3_a1":"Jamais",
  "patient_pedia_knee_instability_q3_a2":"Rarement",
  "patient_pedia_knee_instability_q3_a3":"Parfois",
  "patient_pedia_knee_instability_q3_a4":"Souvent",
  "patient_pedia_knee_instability_q3_a5":"Toujours",
  "patient_pedia_knee_instability_q4":"Marcher sur des surfaces glissantes, mouillées ou gelées",
  "patient_pedia_knee_instability_q4_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q4_a1":"Jamais",
  "patient_pedia_knee_instability_q4_a2":"Rarement",
  "patient_pedia_knee_instability_q4_a3":"Parfois",
  "patient_pedia_knee_instability_q4_a4":"Souvent",
  "patient_pedia_knee_instability_q4_a5":"Toujours",
  "patient_pedia_knee_instability_q5":"Courir de côté",
  "patient_pedia_knee_instability_q5_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q5_a1":"Jamais",
  "patient_pedia_knee_instability_q5_a2":"Rarement",
  "patient_pedia_knee_instability_q5_a3":"Parfois",
  "patient_pedia_knee_instability_q5_a4":"Souvent",
  "patient_pedia_knee_instability_q5_a5":"Toujours",
  "patient_pedia_knee_instability_q6":"Sauter à cloche-pied",
  "patient_pedia_knee_instability_q6_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q6_a1":"Jamais",
  "patient_pedia_knee_instability_q6_a2":"Rarement",
  "patient_pedia_knee_instability_q6_a3":"Parfois",
  "patient_pedia_knee_instability_q6_a4":"Souvent",
  "patient_pedia_knee_instability_q6_a5":"Toujours",
  "patient_pedia_knee_instability_q7":"Sauter",
  "patient_pedia_knee_instability_q7_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q7_a1":"Jamais",
  "patient_pedia_knee_instability_q7_a2":"Rarement",
  "patient_pedia_knee_instability_q7_a3":"Parfois",
  "patient_pedia_knee_instability_q7_a4":"Souvent",
  "patient_pedia_knee_instability_q7_a5":"Toujours",
  "patient_pedia_knee_instability_q8":"Courir en ligne droite sur des surfaces PLANES",
  "patient_pedia_knee_instability_q8_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q8_a1":"Jamais",
  "patient_pedia_knee_instability_q8_a2":"Rarement",
  "patient_pedia_knee_instability_q8_a3":"Parfois",
  "patient_pedia_knee_instability_q8_a4":"Souvent",
  "patient_pedia_knee_instability_q8_a5":"Toujours",
  "patient_pedia_knee_instability_q9":"Descendre des marches",
  "patient_pedia_knee_instability_q9_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q9_a1":"Jamais",
  "patient_pedia_knee_instability_q9_a2":"Rarement",
  "patient_pedia_knee_instability_q9_a3":"Parfois",
  "patient_pedia_knee_instability_q9_a4":"Souvent",
  "patient_pedia_knee_instability_q9_a5":"Toujours",
  "patient_pedia_knee_instability_q10":"S'accroupir",
  "patient_pedia_knee_instability_q10_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q10_a1":"Jamais",
  "patient_pedia_knee_instability_q10_a2":"Rarement",
  "patient_pedia_knee_instability_q10_a3":"Parfois",
  "patient_pedia_knee_instability_q10_a4":"Souvent",
  "patient_pedia_knee_instability_q10_a5":"Toujours",
  "patient_pedia_knee_instability_q11":"S'agenouiller",
  "patient_pedia_knee_instability_q11_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q11_a1":"Jamais",
  "patient_pedia_knee_instability_q11_a2":"Rarement",
  "patient_pedia_knee_instability_q11_a3":"Parfois",
  "patient_pedia_knee_instability_q11_a4":"Souvent",
  "patient_pedia_knee_instability_q11_a5":"Toujours",
  "patient_pedia_knee_instability_q12":"Marcher en ligne droite sur des surfaces IRRÉGULIÈRES",
  "patient_pedia_knee_instability_q12_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q12_a1":"Jamais",
  "patient_pedia_knee_instability_q12_a2":"Rarement",
  "patient_pedia_knee_instability_q12_a3":"Parfois",
  "patient_pedia_knee_instability_q12_a4":"Souvent",
  "patient_pedia_knee_instability_q12_a5":"Toujours",
  "patient_pedia_knee_instability_q13":"Monter des marches",
  "patient_pedia_knee_instability_q13_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q13_a1":"Jamais",
  "patient_pedia_knee_instability_q13_a2":"Rarement",
  "patient_pedia_knee_instability_q13_a3":"Parfois",
  "patient_pedia_knee_instability_q13_a4":"Souvent",
  "patient_pedia_knee_instability_q13_a5":"Toujours",
  "patient_pedia_knee_instability_q14":"Enjamber un obstacle",
  "patient_pedia_knee_instability_q14_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q14_a1":"Jamais",
  "patient_pedia_knee_instability_q14_a2":"Rarement",
  "patient_pedia_knee_instability_q14_a3":"Parfois",
  "patient_pedia_knee_instability_q14_a4":"Souvent",
  "patient_pedia_knee_instability_q14_a5":"Toujours",
  "patient_pedia_knee_instability_q15":"Croiser les james en étant assis",
  "patient_pedia_knee_instability_q15_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q15_a1":"Jamais",
  "patient_pedia_knee_instability_q15_a2":"Rarement",
  "patient_pedia_knee_instability_q15_a3":"Parfois",
  "patient_pedia_knee_instability_q15_a4":"Souvent",
  "patient_pedia_knee_instability_q15_a5":"Toujours",
  "patient_pedia_knee_instability_q16":"Marcher en ligne droite sur des surfaces PLANES",
  "patient_pedia_knee_instability_q16_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q16_a1":"Jamais",
  "patient_pedia_knee_instability_q16_a2":"Rarement",
  "patient_pedia_knee_instability_q16_a3":"Parfois",
  "patient_pedia_knee_instability_q16_a4":"Souvent",
  "patient_pedia_knee_instability_q16_a5":"Toujours",
  "patient_pedia_knee_instability_q17":"Monter ou descendre de voiture",
  "patient_pedia_knee_instability_q17_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q17_a1":"Jamais",
  "patient_pedia_knee_instability_q17_a2":"Rarement",
  "patient_pedia_knee_instability_q17_a3":"Parfois",
  "patient_pedia_knee_instability_q17_a4":"Souvent",
  "patient_pedia_knee_instability_q17_a5":"Toujours",
  "patient_pedia_knee_instability_q18":"Tourner un caddie lourd dans une allée de supermarché ",
  "patient_pedia_knee_instability_q18_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q18_a1":"Jamais",
  "patient_pedia_knee_instability_q18_a2":"Rarement",
  "patient_pedia_knee_instability_q18_a3":"Parfois",
  "patient_pedia_knee_instability_q18_a4":"Souvent",
  "patient_pedia_knee_instability_q18_a5":"Toujours",
  "patient_pedia_knee_instability_q19":"Se tourner pour regarder au dessus de ton épaule",
  "patient_pedia_knee_instability_q19_do_not_do":"Ne fait pas",
  "patient_pedia_knee_instability_q19_a1":"Jamais",
  "patient_pedia_knee_instability_q19_a2":"Rarement",
  "patient_pedia_knee_instability_q19_a3":"Parfois",
  "patient_pedia_knee_instability_q19_a4":"Souvent",
  "patient_pedia_knee_instability_q19_a5":"Toujours",
  "patient_pedia_knee_quality_of_life":"Qualité de vie",
  "patient_pedia_knee_quality_of_life_instruction":"Comment ta blessure a-t-elle impacté ta vie ? ",
  "patient_pedia_knee_quality_of_life_q1":"A quelle fréquence penses-tu à ton problème de genou ?",
  "patient_pedia_knee_quality_of_life_q1_a1":"Jamais",
  "patient_pedia_knee_quality_of_life_q1_a2":"Rarement",
  "patient_pedia_knee_quality_of_life_q1_a3":"Parfois",
  "patient_pedia_knee_quality_of_life_q1_a4":"Souvent",
  "patient_pedia_knee_quality_of_life_q1_a5":"Tout le temps",
  "patient_pedia_knee_problem_q2":"À quel point as-tu modifié ton quotidien à cause de ton genou blessé ? ",
  "patient_pedia_knee_problem_q2_a1":"Pas du tout",
  "patient_pedia_knee_problem_q2_a2":"Un petit peu",
  "patient_pedia_knee_problem_q2_a3":"Un peu",
  "patient_pedia_knee_problem_q2_a4":"Beaucoup",
  "patient_pedia_knee_problem_q2_a5":"De façon importante",
  "patient_pedia_knee_problem_q3":"As-tu confiance en ton genou blessé ? ",
  "patient_pedia_knee_problem_q3_a1":"Complètement",
  "patient_pedia_knee_problem_q3_a2":"Beaucoup",
  "patient_pedia_knee_problem_q3_a3":"Un peu",
  "patient_pedia_knee_problem_q3_a4":"Un petit peu",
  "patient_pedia_knee_problem_q3_a5":"Pas du tout",
  "patient_pedia_knee_problem_q4":"De façon générale, quel niveau de difficulté as-tu avec ton genou blessé ? ",
  "patient_pedia_knee_problem_q4_a1":"Pas de difficulté",
  "patient_pedia_knee_problem_q4_a2":"Un petit peu",
  "patient_pedia_knee_problem_q4_a3":"Un peu",
  "patient_pedia_knee_problem_q4_a4":"Beaucoup",
  "patient_pedia_knee_problem_q4_a5":"Difficulté extrême",
  "patient_pedia_knee_satisfaction":"Satisfaction",
  "patient_pedia_knee_satisfaction_instruction":"Quel est ton niveau de satisfaction avec les résultats de ta chirurgie/ton traitement du genou ? ",
  "patient_pedia_knee_satisfaction_instruction_a1":"Très insatisfait(e)",
  "patient_pedia_knee_satisfaction_instruction_a2":"Légèrement insatisfait(e)",
  "patient_pedia_knee_satisfaction_instruction_a3":"Légèrement satisfait(e)",
  "patient_pedia_knee_satisfaction_instruction_a4":"Très satisfait(e)",
  "patient_pedia_knee_satisfaction_q1":"De façon générale ",
  "patient_pedia_knee_satisfaction_q1_NA":"Non applicable",
  "patient_pedia_knee_satisfaction_q1_a1":"Très insatisfait(e)",
  "patient_pedia_knee_satisfaction_q1_a2":"Légèrement insatisfait(e)",
  "patient_pedia_knee_satisfaction_q1_a3":"Légèrement satisfait(e)",
  "patient_pedia_knee_satisfaction_q1_a4":"Très satisfait(e)",
  "patient_pedia_knee_satisfaction_q2":"Pour atténuer la douleur ? ",
  "patient_pedia_knee_satisfaction_q2_NA":"Non applicable",
  "patient_pedia_knee_satisfaction_q2_a1":"Très insatisfait(e)",
  "patient_pedia_knee_satisfaction_q2_a2":"Légèrement insatisfait(e)",
  "patient_pedia_knee_satisfaction_q2_a3":"Légèrement satisfait(e)",
  "patient_pedia_knee_satisfaction_q2_a4":"Très satisfait(e)",
  "patient_pedia_knee_satisfaction_q3":"Pour améliorer ton/ta capacité à effectuer des tâches ménagères ou du jardinage ? ",
  "patient_pedia_knee_satisfaction_q3_NA":"Non applicable",
  "patient_pedia_knee_satisfaction_q3_a1":"Très insatisfait(e)",
  "patient_pedia_knee_satisfaction_q3_a2":"Légèrement insatisfait(e)",
  "patient_pedia_knee_satisfaction_q3_a3":"Légèrement satisfait(e)",
  "patient_pedia_knee_satisfaction_q3_a4":"Très satisfait(e)",
  "patient_pedia_knee_satisfaction_q4":"Pour améliorer ton/ta capacité à effectuer tes loisirs ? ",
  "patient_pedia_knee_satisfaction_q4_NA":"Non applicable",
  "patient_pedia_knee_satisfaction_q4_a1":"Très insatisfait(e)",
  "patient_pedia_knee_satisfaction_q4_a2":"Légèrement insatisfait(e)",
  "patient_pedia_knee_satisfaction_q4_a3":"Légèrement satisfait(e)",
  "patient_pedia_knee_satisfaction_q4_a4":"Très satisfait(e)",
  "patient_pedia_knee_complete":"Fin du Questionnaire",
  "patient_pedia_knee_complete_text1":"Merci d'avoir pris le temps de remplir ce formulaire. ",
  "patient_pedia_knee_complete_text2":"Clique Envoyer pour terminer et visualiser tes scores.",
  "patient_pedia_knee_complete_text3":"Tes réponses seront traitées de façon confidentielle et tous rapports utiliseront seulement des informations anonymes. ",
  "patient_pedia_knee_results":"Résultats",
  "patient_pedia_knee_results_text1":"Tes scores de douleur et de résultats fonctionnels du genou calculés à partir de ton questionnaire sont représentés ci-dessous. ",
  "patient_pedia_knee_results_pain":"Douleur",
  "patient_pedia_knee_results_function":"Signes fonctionnels",
  "patient_pedia_knee_results_self_evaluation":"Score d'auto-évalution du Genou",
  "patient_pedia_knee_results_text2":"Ton praticien sera avec toi d'ici peu. ",
  "patient_foot_ankle_pain":"Douleur",
  "patient_foot_ankle_pain_q1":"Quel est le NIVEAU ACTUEL DE DOULEUR dans votre pied/cheville ? ",
  "patient_foot_ankle_pain_q1_0":"Aucune",
  "patient_foot_ankle_pain_q1_1":"Pire douleur imaginable",
  "patient_foot_ankle_pain_q2":"Quelle est la meilleure description de la DOULEUR dans votre pied/cheville ? ",
  "patient_foot_ankle_pain_q2_a1":"Aucune",
  "patient_foot_ankle_pain_q2_a2":"Légère, occasionnelle",
  "patient_foot_ankle_pain_q2_a3":"Modérée, quotidienne",
  "patient_foot_ankle_pain_q2_a4":"Sévère, presque toujours présente",
  "patient_foot_ankle_pain_q3":"A quelle FRÉQUENCE votre pied/cheville est-il/elle douloureux/se ? ",
  "patient_foot_ankle_pain_q3_a1":"Jamais",
  "patient_foot_ankle_pain_q3_a2":"Tous les mois",
  "patient_foot_ankle_pain_q3_a3":"Toutes les semaines",
  "patient_foot_ankle_pain_q3_a4":"Tous les jours",
  "patient_foot_ankle_pain_q3_a5":"Toujours",
  "patient_foot_ankle_pain_instruction":"Quel niveau de douleur avez-vous ressenti dans votre PIED/CHEVILLE pendant les activités suivantes : ",
  "patient_foot_ankle_pain_instruction_0":"Aucune",
  "patient_foot_ankle_pain_instruction_1":"Légère",
  "patient_foot_ankle_pain_instruction_2":"Modérée",
  "patient_foot_ankle_pain_instruction_3":"Sévère",
  "patient_foot_ankle_pain_instruction_4":"Extrême",
  "patient_foot_ankle_pain_q4":"Niveau de douleur général ",
  "patient_foot_ankle_pain_q4_a1":"Aucune",
  "patient_foot_ankle_pain_q4_a2":"Légère",
  "patient_foot_ankle_pain_q4_a3":"Modérée",
  "patient_foot_ankle_pain_q4_a4":"Sévère",
  "patient_foot_ankle_pain_q4_a5":"Extrême",
  "patient_foot_ankle_pain_q5":"Au repos",
  "patient_foot_ankle_pain_q5_a1":"Aucune",
  "patient_foot_ankle_pain_q5_a2":"Légère",
  "patient_foot_ankle_pain_q5_a3":"Modérée",
  "patient_foot_ankle_pain_q5_a4":"Sévère",
  "patient_foot_ankle_pain_q5_a5":"Extrême",
  "patient_foot_ankle_pain_q6":"Pendant des activités normales",
  "patient_foot_ankle_pain_q6_a1":"Aucune",
  "patient_foot_ankle_pain_q6_a2":"Légère",
  "patient_foot_ankle_pain_q6_a3":"Modérée",
  "patient_foot_ankle_pain_q6_a4":"Sévère",
  "patient_foot_ankle_pain_q6_a5":"Extrême",
  "patient_foot_ankle_pain_q7":"Au réveil le matin",
  "patient_foot_ankle_pain_q7_a1":"Aucune",
  "patient_foot_ankle_pain_q7_a2":"Légère",
  "patient_foot_ankle_pain_q7_a3":"Modérée",
  "patient_foot_ankle_pain_q7_a4":"Sévère",
  "patient_foot_ankle_pain_q7_a5":"Extrême",
  "patient_foot_ankle_pain_q8":"Exercer une torsion/pivoter sur votre pied/cheville",
  "patient_foot_ankle_pain_q8_a1":"Aucune",
  "patient_foot_ankle_pain_q8_a2":"Légère",
  "patient_foot_ankle_pain_q8_a3":"Modérée",
  "patient_foot_ankle_pain_q8_a4":"Sévère",
  "patient_foot_ankle_pain_q8_a5":"Extrême",
  "patient_foot_ankle_pain_q9":"Etendre complètement le pied/la cheville",
  "patient_foot_ankle_pain_q9_a1":"Aucune",
  "patient_foot_ankle_pain_q9_a2":"Légère",
  "patient_foot_ankle_pain_q9_a3":"Modérée",
  "patient_foot_ankle_pain_q9_a4":"Sévère",
  "patient_foot_ankle_pain_q9_a5":"Extrême",
  "patient_foot_ankle_pain_q10":"Plier complètement le pied/la cheville",
  "patient_foot_ankle_pain_q10_a1":"Aucune",
  "patient_foot_ankle_pain_q10_a2":"Légère",
  "patient_foot_ankle_pain_q10_a3":"Modérée",
  "patient_foot_ankle_pain_q10_a4":"Sévère",
  "patient_foot_ankle_pain_q10_a5":"Extrême",
  "patient_foot_ankle_pain_q11":"Marcher sur une surface plane",
  "patient_foot_ankle_pain_q11_a1":"Aucune",
  "patient_foot_ankle_pain_q11_a2":"Légère",
  "patient_foot_ankle_pain_q11_a3":"Modérée",
  "patient_foot_ankle_pain_q11_a4":"Sévère",
  "patient_foot_ankle_pain_q11_a5":"Extrême",
  "patient_foot_ankle_pain_q12":"Monter ou descendre les escaliers ",
  "patient_foot_ankle_pain_q12_a1":"Aucune",
  "patient_foot_ankle_pain_q12_a2":"Légère",
  "patient_foot_ankle_pain_q12_a3":"Modérée",
  "patient_foot_ankle_pain_q12_a4":"Sévère",
  "patient_foot_ankle_pain_q12_a5":"Extrême",
  "patient_foot_ankle_pain_q13":"Au lit la nuit ",
  "patient_foot_ankle_pain_q13_a1":"Aucune",
  "patient_foot_ankle_pain_q13_a2":"Légère",
  "patient_foot_ankle_pain_q13_a3":"Modérée",
  "patient_foot_ankle_pain_q13_a4":"Sévère",
  "patient_foot_ankle_pain_q13_a5":"Extrême",
  "patient_foot_ankle_pain_q14":"Assis(e) ou allongé(e)",
  "patient_foot_ankle_pain_q14_a1":"Aucune",
  "patient_foot_ankle_pain_q14_a2":"Légère",
  "patient_foot_ankle_pain_q14_a3":"Modérée",
  "patient_foot_ankle_pain_q14_a4":"Sévère",
  "patient_foot_ankle_pain_q14_a5":"Extrême",
  "patient_foot_ankle_pain_q15":"En se tenant droit debout ",
  "patient_foot_ankle_pain_q15_a1":"Aucune",
  "patient_foot_ankle_pain_q15_a2":"Légère",
  "patient_foot_ankle_pain_q15_a3":"Modérée",
  "patient_foot_ankle_pain_q15_a4":"Sévère",
  "patient_foot_ankle_pain_q15_a5":"Extrême",
  "patient_foot_ankle_instability":"Instabilité",
  "patient_foot_ankle_instability_q1":"Faites-vous des entorses à répétition, est-ce que votre cheville se dérobe ou avez-vous l'impression que votre cheville va se dérober ? ",
  "patient_foot_ankle_instability_q1_a1":"Non",
  "patient_foot_ankle_instability_q1_a2":"Oui",
  "patient_foot_ankle_instability_q2":"Avez-vous déjà été diagnostiqué(e)/traité(e) pour une instabilité de cheville/entorses à répétition ? ",
  "patient_foot_ankle_instability_q2_a1":"Non",
  "patient_foot_ankle_instability_q2_a2":"Oui",
  "patient_foot_ankle_instability_q3":"Est-ce que votre cheville non affectée semble complètement stable ? ",
  "patient_foot_ankle_instability_q3_a1":"Non",
  "patient_foot_ankle_instability_q3_a2":"Oui",
  "patient_foot_ankle_cumberland":"OUTIL D'INSTABILITE DE LA CHEVILLE DE CUMBERLAND",
  "patient_foot_ankle_cumberland_instruction":"Veuillez choisir une proposition pour chaque question qui décrit le mieux votre cheville. ",
  "patient_foot_ankle_cumberland_q1":"J'ai mal à la cheville ",
  "patient_foot_ankle_cumberland_q1_a1":"Jamais",
  "patient_foot_ankle_cumberland_q1_a2":"Pendant le sport",
  "patient_foot_ankle_cumberland_q1_a3":"En courant sur des surfaces irrégulières",
  "patient_foot_ankle_cumberland_q1_a4":"En courant sur des surfaces planes",
  "patient_foot_ankle_cumberland_q1_a5":"En marchant sur des surfaces irrégulières",
  "patient_foot_ankle_cumberland_q1_a6":"En marchant sur des surfaces planes",
  "patient_foot_ankle_cumberland_q2":"Ma cheville semble INSTABLE",
  "patient_foot_ankle_cumberland_q2_a1":"Jamais",
  "patient_foot_ankle_cumberland_q2_a2":"Parfois pendant le sport (pas à chaque fois)",
  "patient_foot_ankle_cumberland_q2_a3":"Fréquemment pendant le sport (à chaque fois)",
  "patient_foot_ankle_cumberland_q2_a4":"Parfois pendant les activités quotidiennes",
  "patient_foot_ankle_cumberland_q2_a5":"Fréquemment pendant les activités quotidiennes",
  "patient_foot_ankle_cumberland_q3":"Quand je tourne BRUSQUEMENT ma cheville semble INSTABLE",
  "patient_foot_ankle_cumberland_q3_a1":"Jamais",
  "patient_foot_ankle_cumberland_q3_a2":"Parfois en courant",
  "patient_foot_ankle_cumberland_q3_a3":"Souvent en courant",
  "patient_foot_ankle_cumberland_q3_a4":"En marchant",
  "patient_foot_ankle_cumberland_q4":"Quand je descends des escaliers, ma cheville semble INSTABLE",
  "patient_foot_ankle_cumberland_q4_a1":"Jamais",
  "patient_foot_ankle_cumberland_q4_a2":"Si je vais vite",
  "patient_foot_ankle_cumberland_q4_a3":"Occasionnellement",
  "patient_foot_ankle_cumberland_q4_a4":"Toujours",
  "patient_foot_ankle_cumberland_q5":"Ma cheville semble INSTABLE quand je me tiens sur UNE jambe",
  "patient_foot_ankle_cumberland_q5_a1":"Jamais",
  "patient_foot_ankle_cumberland_q5_a2":"Sur la plante du pied",
  "patient_foot_ankle_cumberland_q5_a3":"Avec mon pied à plat",
  "patient_foot_ankle_cumberland_q6":"Ma cheville paraît INSTABLE quand ",
  "patient_foot_ankle_cumberland_q6_a1":"Jamais",
  "patient_foot_ankle_cumberland_q6_a2":"Je fais du cloche-pied d'un pied à l'autre",
  "patient_foot_ankle_cumberland_q6_a3":"Je fais du cloche-pied sur place",
  "patient_foot_ankle_cumberland_q6_a4":"Je saute",
  "patient_foot_ankle_cumberland_q7":"Ma cheville semble INSTABLE quand ",
  "patient_foot_ankle_cumberland_q7_a1":"Jamais",
  "patient_foot_ankle_cumberland_q7_a2":"Je cours sur des surfaces irrégulières",
  "patient_foot_ankle_cumberland_q7_a3":"Je fais de la course à pieds sur des surfaces irrégulières",
  "patient_foot_ankle_cumberland_q7_a4":"Je marche sur des surfaces irrégulières",
  "patient_foot_ankle_cumberland_q7_a5":"Je marche sur des surfaces planes",
  "patient_foot_ankle_cumberland_q8":"De façon générale, quand ma cheville commence à rouler (ou \"se tordre\") je peux l'arrêter ",
  "patient_foot_ankle_cumberland_q8_a1":"Immédiatement",
  "patient_foot_ankle_cumberland_q8_a2":"Souvent",
  "patient_foot_ankle_cumberland_q8_a3":"Parfois",
  "patient_foot_ankle_cumberland_q8_a4":"Jamais",
  "patient_foot_ankle_cumberland_q8_a5":"Je ne me suis jamais tordu(e) la cheville",
  "patient_foot_ankle_cumberland_q9":"Après un incident CARACTÉRISTIQUE où je tords ma cheville, ma cheville retourne à son état \"normal\"",
  "patient_foot_ankle_cumberland_q9_a1":"Presque immédiatement",
  "patient_foot_ankle_cumberland_q9_a2":"En moins d'une journée",
  "patient_foot_ankle_cumberland_q9_a3":"En 1-2 jours",
  "patient_foot_ankle_cumberland_q9_a4":"Après plus de 2 jours",
  "patient_foot_ankle_cumberland_q9_a5":"Je ne me suis jamais tordu(e) la cheville",
  "patient_foot_ankle_other_symptoms":"Autres symptômes",
  "patient_foot_ankle_other_symptoms_instruction1":"Veuillez choisir la réponse qui correspond le mieux à vos symptômes du pied/de la cheville. ",
  "patient_foot_ankle_other_symptoms_instruction2":"Si vous n'êtes pas sûr(e) comment répondre à une question, veuillez répondre du mieux que vous pouvez. ",
  "patient_foot_ankle_other_symptoms_note":"Note : Veuillez répondre à ces questions en pensant à vos symptômes dans votre pied/cheville au cours de la dernière semaine. ",
  "patient_foot_ankle_other_symptoms_instruction3":"Avez-vous les propositions suivantes : ",
  "patient_foot_ankle_other_symptoms_instruction3_0":"Jamais",
  "patient_foot_ankle_other_symptoms_instruction3_1":"Rarement",
  "patient_foot_ankle_other_symptoms_instruction3_2":"Parfois",
  "patient_foot_ankle_other_symptoms_instruction3_3":"Souvent",
  "patient_foot_ankle_other_symptoms_instruction3_4":"Toujours",
  "patient_foot_ankle_other_symptoms_q1":"Gonflement dans votre pied/cheville ",
  "patient_foot_ankle_other_symptoms_q1_a1":"Jamais",
  "patient_foot_ankle_other_symptoms_q1_a2":"Rarement",
  "patient_foot_ankle_other_symptoms_q1_a3":"Parfois",
  "patient_foot_ankle_other_symptoms_q1_a4":"Souvent",
  "patient_foot_ankle_other_symptoms_q1_a5":"Toujours",
  "patient_foot_ankle_other_symptoms_q2":"Grincements, craquements, ou tout autre bruit quand votre pied/cheville bouge",
  "patient_foot_ankle_other_symptoms_q2_a1":"Jamais",
  "patient_foot_ankle_other_symptoms_q2_a2":"Rarement",
  "patient_foot_ankle_other_symptoms_q2_a3":"Parfois",
  "patient_foot_ankle_other_symptoms_q2_a4":"Souvent",
  "patient_foot_ankle_other_symptoms_q2_a5":"Toujours",
  "patient_foot_ankle_other_symptoms_q3":"Sensations d'accrochage ou de blocage en bougeant",
  "patient_foot_ankle_other_symptoms_q3_a1":"Jamais",
  "patient_foot_ankle_other_symptoms_q3_a2":"Rarement",
  "patient_foot_ankle_other_symptoms_q3_a3":"Parfois",
  "patient_foot_ankle_other_symptoms_q3_a4":"Souvent",
  "patient_foot_ankle_other_symptoms_q3_a5":"Toujours",
  "patient_foot_ankle_other_symptoms_instruction4":"Comment évaluez-vous la sévérité de la RAIDEUR dans votre pied/cheville : ",
  "patient_foot_ankle_other_symptoms_instruction4_0":"Aucune",
  "patient_foot_ankle_other_symptoms_instruction4_1":"Légère ",
  "patient_foot_ankle_other_symptoms_instruction4_2":"Modérée",
  "patient_foot_ankle_other_symptoms_instruction4_3":"Sévère",
  "patient_foot_ankle_other_symptoms_instruction4_4":"Extrême",
  "patient_foot_ankle_other_symptoms_q4":"En se réveillant le matin ",
  "patient_foot_ankle_other_symptoms_q4_a1":"Aucune",
  "patient_foot_ankle_other_symptoms_q4_a2":"Légère ",
  "patient_foot_ankle_other_symptoms_q4_a3":"Modérée",
  "patient_foot_ankle_other_symptoms_q4_a4":"Sévère",
  "patient_foot_ankle_other_symptoms_q4_a5":"Extrême",
  "patient_foot_ankle_other_symptoms_q5":"Après s'être assis(e), allongé(e), ou reposé(e) plus tard dans la journée ",
  "patient_foot_ankle_other_symptoms_q5_a1":"Aucune",
  "patient_foot_ankle_other_symptoms_q5_a2":"Légère ",
  "patient_foot_ankle_other_symptoms_q5_a3":"Modérée",
  "patient_foot_ankle_other_symptoms_q5_a4":"Sévère",
  "patient_foot_ankle_other_symptoms_q5_a5":"Extrême",
  "patient_foot_ankle_function":"Signes fonctionnels",
  "patient_foot_ankle_function_q1":"Quelles limitations avez-vous dans vos activités quotidiennes à cause de votre pied/cheville ? ",
  "patient_foot_ankle_function_q1_a1":"Pas de limitations, pas de support",
  "patient_foot_ankle_function_q1_a2":"Pas de limitations d'activités quotidiennes, limitation des loisirs, pas de support",
  "patient_foot_ankle_function_q1_a3":"Activités quotidiennes et de loisirs limitées, utilisation d'une canne",
  "patient_foot_ankle_function_q1_a4":"Limitations importantes d'activités quotidiennes et de loisirs, utilisation d'un déambulateur, de béquilles, d'un fauteuil roulant, d'une attelle",
  "patient_foot_ankle_function_q2":"Quel type de chaussures portez-vous habituellement ? ",
  "patient_foot_ankle_function_q2_a1":"Chaussures de mode, conventionnelles, pas de semelles orthopédiques requises",
  "patient_foot_ankle_function_q2_a2":"Chaussures confortables, semelles orthopédiques",
  "patient_foot_ankle_function_q2_a3":"Chaussures spécialisées ou attelle",
  "patient_foot_ankle_function_q3":"Sur quelle type de surface vous est-il difficile de marcher ? ",
  "patient_foot_ankle_function_q3_a1":"Pas de difficulté sur quelconque surface",
  "patient_foot_ankle_function_q3_a2":"Quelques difficultés sur terrain irrégulier, escaliers, pentes, échelles",
  "patient_foot_ankle_function_q3_a3":"Difficultés importantes sur terrain irrégulier, escaliers, pentes, échelles",
  "patient_foot_ankle_function_instruction":"Indiquez le NIVEAU DE DIFFICULTÉ que vous avez ressenti À CAUSE DE VOTRE PIED/CHEVILLE",
  "patient_foot_ankle_function_instruction_0":"Pas de difficulté",
  "patient_foot_ankle_function_instruction_1":"Difficulté légère",
  "patient_foot_ankle_function_instruction_2":"Difficulté modérée",
  "patient_foot_ankle_function_instruction_3":"Difficulté extrême",
  "patient_foot_ankle_function_instruction_4":"Incapacité",
  "patient_foot_ankle_function_note":"Note : Si l'activité en question est limitée par quelques choses autres que votre pied ou cheville, veuillez sélectionner \"Non Applicable\" pour les questions où c'est une option. Sinon, veuillez répondre à toutes les questions avec une réponse qui décrit le plus précisément votre état au cours de la dernière semaine. ",
  "patient_foot_ankle_function_q4":"Etendre complètement le pied/la cheville ",
  "patient_foot_ankle_function_q4_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q4_a2":"Difficulté légère",
  "patient_foot_ankle_function_q4_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q4_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q4_a5":"Incapacité",
  "patient_foot_ankle_function_q5":"Plier complètement le pied/la cheville ",
  "patient_foot_ankle_function_q5_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q5_a2":"Difficulté légère",
  "patient_foot_ankle_function_q5_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q5_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q5_a5":"Incapacité",
  "patient_foot_ankle_function_q6":"Descendre des marches",
  "patient_foot_ankle_function_q6_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q6_a2":"Difficulté légère",
  "patient_foot_ankle_function_q6_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q6_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q6_a5":"Incapacité",
  "patient_foot_ankle_function_q7":"Monter des marches ",
  "patient_foot_ankle_function_q7_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q7_a2":"Difficulté légère",
  "patient_foot_ankle_function_q7_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q7_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q7_a5":"Incapacité",
  "patient_foot_ankle_function_q8":"Se lever d'une position assise ",
  "patient_foot_ankle_function_q8_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q8_a2":"Difficulté légère",
  "patient_foot_ankle_function_q8_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q8_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q8_a5":"Incapacité",
  "patient_foot_ankle_function_q9":"Se tenir debout",
  "patient_foot_ankle_function_q9_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q9_a2":"Difficulté légère",
  "patient_foot_ankle_function_q9_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q9_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q9_a5":"Incapacité",
  "patient_foot_ankle_function_q10":"Se baisser au sol/ramasser un objet",
  "patient_foot_ankle_function_q10_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q10_a2":"Difficulté légère",
  "patient_foot_ankle_function_q10_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q10_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q10_a5":"Incapacité",
  "patient_foot_ankle_function_q11":"Marcher sur une surface plane",
  "patient_foot_ankle_function_q11_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q11_a2":"Difficulté légère",
  "patient_foot_ankle_function_q11_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q11_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q11_a5":"Incapacité",
  "patient_foot_ankle_function_q12":"Quelle distance maximale pouvez-vous marcher ? ",
  "patient_foot_ankle_function_q12_a1":"Plus de 6 pâtés de maison",
  "patient_foot_ankle_function_q12_a2":"4-6 pâtés de maison",
  "patient_foot_ankle_function_q12_a3":"1-3 pâtés de maison",
  "patient_foot_ankle_function_q12_a4":"Moins d'1 pâté de maison",
  "patient_foot_ankle_function_q13":"Marcher sur une surface plane sans chaussures",
  "patient_foot_ankle_function_q13_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q13_a2":"Difficulté légère",
  "patient_foot_ankle_function_q13_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q13_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q13_a5":"Incapacité",
  "patient_foot_ankle_function_q14":"Monter une côte",
  "patient_foot_ankle_function_q14_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q14_a2":"Difficulté légère",
  "patient_foot_ankle_function_q14_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q14_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q14_a5":"Incapacité",
  "patient_foot_ankle_function_q15":"Descendre une côte",
  "patient_foot_ankle_function_q15_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q15_a2":"Difficulté légère",
  "patient_foot_ankle_function_q15_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q15_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q15_a5":"Incapacité",
  "patient_foot_ankle_function_q16":"Marcher sur une surface irrégulière ",
  "patient_foot_ankle_function_q16_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q16_a2":"Difficulté légère",
  "patient_foot_ankle_function_q16_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q16_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q16_a5":"Incapacité",
  "patient_foot_ankle_function_q17":"Monter et descendre d'un trottoir ",
  "patient_foot_ankle_function_q17_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q17_a2":"Difficulté légère",
  "patient_foot_ankle_function_q17_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q17_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q17_a5":"Incapacité",
  "patient_foot_ankle_function_q18":"Se mettre sur la pointe des pieds ",
  "patient_foot_ankle_function_q18_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q18_a2":"Difficulté légère",
  "patient_foot_ankle_function_q18_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q18_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q18_a5":"Incapacité",
  "patient_foot_ankle_function_q19":"Initialiser la marche ",
  "patient_foot_ankle_function_q19_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q19_a2":"Difficulté légère",
  "patient_foot_ankle_function_q19_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q19_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q19_a5":"Incapacité",
  "patient_foot_ankle_function_q20":"Marcher 5 minutes ou moins",
  "patient_foot_ankle_function_q20_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q20_a2":"Difficulté légère",
  "patient_foot_ankle_function_q20_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q20_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q20_a5":"Incapacité",
  "patient_foot_ankle_function_q21":"Marcher environ 10 minutes",
  "patient_foot_ankle_function_q21_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q21_a2":"Difficulté légère",
  "patient_foot_ankle_function_q21_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q21_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q21_a5":"Incapacité",
  "patient_foot_ankle_function_q22":"Marcher 15 minutes ou plus",
  "patient_foot_ankle_function_q22_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q22_a2":"Difficulté légère",
  "patient_foot_ankle_function_q22_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q22_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q22_a5":"Incapacité",
  "patient_foot_ankle_function_q23":"Monter/descendre d'une voiture",
  "patient_foot_ankle_function_q23_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q23_a2":"Difficulté légère",
  "patient_foot_ankle_function_q23_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q23_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q23_a5":"Incapacité",
  "patient_foot_ankle_function_q24":"Faire les courses ",
  "patient_foot_ankle_function_q24_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q24_a2":"Difficulté légère",
  "patient_foot_ankle_function_q24_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q24_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q24_a5":"Incapacité",
  "patient_foot_ankle_function_q25":"Mettre des chaussettes/bas",
  "patient_foot_ankle_function_q25_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q25_a2":"Difficulté légère",
  "patient_foot_ankle_function_q25_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q25_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q25_a5":"Incapacité",
  "patient_foot_ankle_function_q26":"Retirer des chaussettes/bas",
  "patient_foot_ankle_function_q26_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q26_a2":"Difficulté légère",
  "patient_foot_ankle_function_q26_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q26_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q26_a5":"Incapacité",
  "patient_foot_ankle_function_q27":"Se lever du lit ",
  "patient_foot_ankle_function_q27_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q27_a2":"Difficulté légère",
  "patient_foot_ankle_function_q27_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q27_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q27_a5":"Incapacité",
  "patient_foot_ankle_function_q28":"Allongé(e) au lit (se retourner en maintenant la position du pied/de la cheville) ",
  "patient_foot_ankle_function_q28_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q28_a2":"Difficulté légère",
  "patient_foot_ankle_function_q28_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q28_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q28_a5":"Incapacité",
  "patient_foot_ankle_function_q29":"Dormir",
  "patient_foot_ankle_function_q29_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q29_a2":"Difficulté légère",
  "patient_foot_ankle_function_q29_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q29_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q29_a5":"Incapacité",
  "patient_foot_ankle_function_q30":"Entrer ou sortir de la baignoire ",
  "patient_foot_ankle_function_q30_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q30_a2":"Difficulté légère",
  "patient_foot_ankle_function_q30_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q30_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q30_a5":"Incapacité",
  "patient_foot_ankle_function_q31":"S'asseoir ",
  "patient_foot_ankle_function_q31_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q31_a2":"Difficulté légère",
  "patient_foot_ankle_function_q31_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q31_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q31_a5":"Incapacité",
  "patient_foot_ankle_function_q32":"S'asseoir ou se relever des toilettes ",
  "patient_foot_ankle_function_q32_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q32_a2":"Difficulté légère",
  "patient_foot_ankle_function_q32_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q32_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q32_a5":"Incapacité",
  "patient_foot_ankle_function_q33":"Responsabilités domestiques ",
  "patient_foot_ankle_function_q33_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q33_a2":"Difficulté légère",
  "patient_foot_ankle_function_q33_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q33_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q33_a5":"Incapacité",
  "patient_foot_ankle_function_q34":"Activités quotidiennes ",
  "patient_foot_ankle_function_q34_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q34_a2":"Difficulté légère",
  "patient_foot_ankle_function_q34_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q34_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q34_a5":"Incapacité",
  "patient_foot_ankle_function_q35":"Hygiène personnelle (y compris se laver et s'habiller)",
  "patient_foot_ankle_function_q35_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q35_a2":"Difficulté légère",
  "patient_foot_ankle_function_q35_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q35_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q35_a5":"Incapacité",
  "patient_foot_ankle_function_q36":"Travaux intenses (pousser/tirer, monter, déplacer de lourds cartons, nettoyer le sol etc) ",
  "patient_foot_ankle_function_q36_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q36_a2":"Difficulté légère",
  "patient_foot_ankle_function_q36_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q36_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q36_a5":"Incapacité",
  "patient_foot_ankle_function_q37":"Activités ménagères légères (faire la cuisine, les poussières, etc)",
  "patient_foot_ankle_function_q37_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q37_a2":"Difficulté légère",
  "patient_foot_ankle_function_q37_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q37_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q37_a5":"Incapacité",
  "patient_foot_ankle_function_q38":"Travail léger à modéré (se tenir debout, marcher) ",
  "patient_foot_ankle_function_q38_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q38_a2":"Difficulté légère",
  "patient_foot_ankle_function_q38_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q38_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q38_a5":"Incapacité",
  "patient_foot_ankle_function_q39":"Loisirs",
  "patient_foot_ankle_function_q39_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q39_a2":"Difficulté légère",
  "patient_foot_ankle_function_q39_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q39_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q39_a5":"Incapacité",
  "patient_foot_ankle_function_q40":"S'accroupir",
  "patient_foot_ankle_function_q40_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q40_a2":"Difficulté légère",
  "patient_foot_ankle_function_q40_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q40_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q40_a5":"Incapacité",
  "patient_foot_ankle_function_q41":"Exercer une torsion/pivoter sur votre pied/cheville ",
  "patient_foot_ankle_function_q41_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q41_a2":"Difficulté légère",
  "patient_foot_ankle_function_q41_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q41_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q41_a5":"Incapacité",
  "patient_foot_ankle_function_q42":"S'agenouiller",
  "patient_foot_ankle_function_q42_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q42_a2":"Difficulté légère",
  "patient_foot_ankle_function_q42_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q42_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q42_a5":"Incapacité",
  "patient_foot_ankle_function_q43":"Courir ",
  "patient_foot_ankle_function_q43_not_applicable":"Non applicable",
  "patient_foot_ankle_function_q43_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q43_a2":"Difficulté légère",
  "patient_foot_ankle_function_q43_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q43_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q43_a5":"Incapacité",
  "patient_foot_ankle_function_q44":"Sauter",
  "patient_foot_ankle_function_q44_not_applicable":"Non applicable",
  "patient_foot_ankle_function_q44_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q44_a2":"Difficulté légère",
  "patient_foot_ankle_function_q44_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q44_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q44_a5":"Incapacité",
  "patient_foot_ankle_function_q45":"Atterrir ",
  "patient_foot_ankle_function_q45_not_applicable":"Non applicable",
  "patient_foot_ankle_function_q45_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q45_a2":"Difficulté légère",
  "patient_foot_ankle_function_q45_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q45_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q45_a5":"Incapacité",
  "patient_foot_ankle_function_q46":"Démarrer et s'arrêter brusquement ",
  "patient_foot_ankle_function_q46_not_applicable":"Non applicable",
  "patient_foot_ankle_function_q46_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q46_a2":"Difficulté légère",
  "patient_foot_ankle_function_q46_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q46_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q46_a5":"Incapacité",
  "patient_foot_ankle_function_q47":"Changer rapidement de direction / mouvements latéraux abrupts ",
  "patient_foot_ankle_function_q47_not_applicable":"Non applicable",
  "patient_foot_ankle_function_q47_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q47_a2":"Difficulté légère",
  "patient_foot_ankle_function_q47_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q47_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q47_a5":"Incapacité",
  "patient_foot_ankle_function_q48":"Activités à faible impact ",
  "patient_foot_ankle_function_q48_not_applicable":"Non applicable",
  "patient_foot_ankle_function_q48_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q48_a2":"Difficulté légère",
  "patient_foot_ankle_function_q48_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q48_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q48_a5":"Incapacité",
  "patient_foot_ankle_function_q49":"Capacité à effectuer une activité avec votre technique normale ",
  "patient_foot_ankle_function_q49_not_applicable":"Non applicable",
  "patient_foot_ankle_function_q49_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q49_a2":"Difficulté légère",
  "patient_foot_ankle_function_q49_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q49_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q49_a5":"Incapacité",
  "patient_foot_ankle_function_q50":"Capacité à effectuer une activité sportive aussi longtemps que vous le désirez ",
  "patient_foot_ankle_function_q50_not_applicable":"Non applicable",
  "patient_foot_ankle_function_q50_a1":"Pas de difficulté",
  "patient_foot_ankle_function_q50_a2":"Difficulté légère",
  "patient_foot_ankle_function_q50_a3":"Difficulté modérée",
  "patient_foot_ankle_function_q50_a4":"Difficulté extrême",
  "patient_foot_ankle_function_q50_a5":"Incapacité",
  "patient_foot_ankle_function_q51":"Comment évalueriez-vous votre PIED/CHEVILLE aujourd'hui sur un ratio de normalité (de 0 à 100%, avec 100% étant normal)",
  "patient_foot_ankle_function_q51_a1":"Normal",
  "patient_foot_ankle_function_q52":"Quel est le niveau maximal d'activité auquel vous pouvez participer de façon régulière ? ",
  "patient_foot_ankle_function_q52_q1":"Avant votre blessure/problème de pied ou de cheville",
  "patient_foot_ankle_function_q52_q1_a1":"À haut niveau",
  "patient_foot_ankle_function_q52_q1_a2":"Niveaux de compétition plus bas",
  "patient_foot_ankle_function_q52_q1_a3":"Loisir",
  "patient_foot_ankle_function_q52_q1_a4":"Je ne fais pas de sport",
  "patient_foot_ankle_function_q52_q1_a5":"Pas de sport, pas de travail",
  "patient_foot_ankle_function_q52_q1_a4_o7":"Incapable de marcher, handicapé à cause des problèmes de cheville",
  "patient_foot_ankle_function_q52_q1_a1_title":"À haut niveau (international, professionnel, équipe nationale, ou première division)",
  "patient_foot_ankle_function_q52_q1_a2_title":"Niveaux de compétition plus bas",
  "patient_foot_ankle_function_q52_q1_a3_title":"Loisir",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1":"Gymnastique aérobique, fitness",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2":"Ski alpin et snowboard",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3":"Football américain",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4":"Badminton",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5":"Baseball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6":"Basketball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7":"Bowling/curling",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08":"Boxe",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9":"Cross",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10":"Cyclisme",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11":"Danse",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12":"Plongée",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13":"Equitation",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14":"Escrime",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15":"Floorball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16":"Snowboard freestyle",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17":"Golf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18":"Gymnastique",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19":"Handball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20":"Hockey ",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21":"Hockey sur glace",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22":"Korfball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23":"Arts martiaux",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24":"Pentathlon moderne",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25":"Sports motorisés, sports techniques",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26":"Randonnée en montagne",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27":"VTT/BMX",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28":"Ski nordique",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29":"Course d'orientation",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30":"Parachute",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31":"Haltérophilie",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32":"Gymnastique rythmique ",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33":"Aviron, kayak",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34":"Rugby",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35":"Voile",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36":"Plongée sous-marine",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37":"Tir, tir à l'arc",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38":"Patins à roulettes, rollers",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39":"Football",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40":"Softball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41":"Squash",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42":"Surf, planche à voile",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43":"Tennis de table",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44":"Tennis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45":"Athlétisme : autres (ex: lancers de poids, de javelot etc)",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46":"Athlétisme : sports de course",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47":"Triathlon",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48":"Volleyball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49":"Waterpolo et natation",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50":"Ski nautique",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51":"Haltérophilie, musculation",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52":"Lutte",
  "patient_foot_ankle_function_q52_q1_a4_o1":"Travail physique intense",
  "patient_foot_ankle_function_q52_q1_a4_o2":"Travail physique",
  "patient_foot_ankle_function_q52_q1_a4_o3":"Professions spéciales : danseur de ballet, militaire, secouriste spécialisé, cascadeur",
  "patient_foot_ankle_function_q52_q1_a4_o4":"Peut marcher sur n'importe quel terrain irrégulier",
  "patient_foot_ankle_function_q52_q1_a4_o5":"Activités quotidiennes non limitées ",
  "patient_foot_ankle_function_q52_q1_a4_o6":"Peut marcher sur terrain plat, mais les activités quotidiennes sont limitées ",
  "patient_foot_ankle_function_q52_q2":"Statut actuel",
  "patient_foot_ankle_function_q52_q2_a1":"À haut niveau",
  "patient_foot_ankle_function_q52_q2_a2":"Niveaux de compétition plus bas",
  "patient_foot_ankle_function_q52_q2_a3":"Loisir",
  "patient_foot_ankle_function_q52_q2_a4":"Je ne fais pas de sport mais je travaille",
  "patient_foot_ankle_function_q52_q2_a5":"Pas de sport, pas de travail",
  "patient_foot_ankle_function_q52_q2_a4_o7":"Incapable de marcher, handicapé(e) à cause des problèmes de cheville",
  "patient_foot_ankle_function_q52_q2_a1_title":"Compétition de haut niveau (international, professionnel, équipe nationale, ou première division)",
  "patient_foot_ankle_function_q52_q2_a2_title":"Miveaux de compétition plus bas",
  "patient_foot_ankle_function_q52_q2_a3_title":"Loisir",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1":"Gymnastique aérobique, fitness",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2":"Ski alpin et snowboard",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3":"Football américain",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4":"Badminton",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5":"Baseball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6":"Basketball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7":"Bowling/curling",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8":"Boxe",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9":"Cross",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10":"Cyclisme",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11":"Danse",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12":"Plongée",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13":"Equitation",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14":"Escrime",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15":"Floorball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16":"Snowboard freestyle",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17":"Golf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18":"Gymnastique",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19":"Handball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20":"Hockey ",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21":"Hockey sur glace",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22":"Korfball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23":"Arts martiaux",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24":"Pentathlon moderne",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25":"Sports motorisés, sports techniques",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26":"Randonnée en montagne",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27":"VTT/BMX",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28":"Ski nordique",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29":"Course d'orientation",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30":"Parachute",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31":"Haltérophilie",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32":"Gymnastique rythmique ",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33":"Aviron, kayak",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34":"Rugby",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35":"Voile",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36":"Plongée sous-marine",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37":"Tir, tir à l'arc",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38":"Patins à roulettes, rollers",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39":"Football",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40":"Softball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41":"Squash",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42":"Surf, planche à voile",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43":"Tennis de table",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44":"Tennis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45":"Athlétisme : autres (ex: lancers de poids, de javelot etc)",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46":"Athlétisme : sports de course",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47":"Triathlon",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48":"Volleyball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49":"Waterpolo et natation",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50":"Ski nautique",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51":"Haltérophilie, musculation",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52":"Lutte",
  "patient_foot_ankle_function_q52_q2_a4_o1":"Travail physique intense",
  "patient_foot_ankle_function_q52_q2_a4_o2":"Travail physique",
  "patient_foot_ankle_function_q52_q2_a4_o3":"Professions spéciales : danseur de ballet, militaire, secouriste spécialisé, cascadeur",
  "patient_foot_ankle_function_q52_q2_a4_o4":"Peut marcher sur n'importe quel terrain irrégulier",
  "patient_foot_ankle_function_q52_q2_a4_o5":"Activités quotidiennes non limitées ",
  "patient_foot_ankle_function_q52_q2_a4_o6":"Peut marcher sur terrain plat, toutes les activités sont limitées ",
  "patient_foot_ankle_quality_of_life":"Qualité de vie",
  "patient_foot_ankle_quality_of_life_instructions":"Les questions suivantes concernent les inquiétudes sociales, émotionnelles et de mode de vie que vous pouvez ressentir en rapport avec votre problème de pied/cheville. Veuillez penser à ce que vous avez ressenti la plupart du temps en relation avec votre problème de pied/cheville.",
  "patient_foot_ankle_quality_of_life_q1":"A quelle fréquence êtes-vous conscient(e) de votre problème de pied/cheville ?",
  "patient_foot_ankle_quality_of_life_q1_a1":"Jamais",
  "patient_foot_ankle_quality_of_life_q1_a2":"Tous les mois",
  "patient_foot_ankle_quality_of_life_q1_a3":"Toutes les semaines",
  "patient_foot_ankle_quality_of_life_q1_a4":"Tous les jours",
  "patient_foot_ankle_quality_of_life_q1_a5":"Constamment",
  "patient_foot_ankle_quality_of_life_q2":"Avez-vous modifié votre quotidien pour éviter des activités qui pourraient potentiellement détériorer votre pied/cheville ? ",
  "patient_foot_ankle_quality_of_life_q2_a1":"Pas du tout",
  "patient_foot_ankle_quality_of_life_q2_a2":"Légèrement",
  "patient_foot_ankle_quality_of_life_q2_a3":"Modérément",
  "patient_foot_ankle_quality_of_life_q2_a4":"Fortement",
  "patient_foot_ankle_quality_of_life_q2_a5":"Totalement",
  "patient_foot_ankle_quality_of_life_q3":"A quel point êtes-vous préoccupé(e) par votre manque de confiance en votre pied/cheville ? ",
  "patient_foot_ankle_quality_of_life_q3_a1":"Pas du tout",
  "patient_foot_ankle_quality_of_life_q3_a2":"Légèrement",
  "patient_foot_ankle_quality_of_life_q3_a3":"Modérément",
  "patient_foot_ankle_quality_of_life_q3_a4":"Fortement",
  "patient_foot_ankle_quality_of_life_q3_a5":"Extrêmement",
  "patient_foot_ankle_quality_of_life_q4":"De façon générale, quel niveau de difficulté avez-vous avec votre pied/cheville ? ",
  "patient_foot_ankle_quality_of_life_q4_a1":"Aucune",
  "patient_foot_ankle_quality_of_life_q4_a2":"Légère",
  "patient_foot_ankle_quality_of_life_q4_a3":"Modérée",
  "patient_foot_ankle_quality_of_life_q4_a4":"Sévère",
  "patient_foot_ankle_quality_of_life_q4_a5":"Extrême",
  "patient_foot_ankle_quality_of_life_q5":"Comment évaluez-vous votre niveau d'anxiété/de dépression aujourd'hui ? ",
  "patient_foot_ankle_quality_of_life_q5_a1":"Pas anxieux(se)/déprimé(e)",
  "patient_foot_ankle_quality_of_life_q5_a2":"Légèrement ",
  "patient_foot_ankle_quality_of_life_q5_a3":"Modérément",
  "patient_foot_ankle_quality_of_life_q5_a4":"Fortement",
  "patient_foot_ankle_quality_of_life_q5_a5":"Extrêmement",
  "patient_foot_ankle_quality_of_life_q6":"Comment évaluez-vous votre SANTÉ aujourd'hui ? (100 signifie meilleure santé et 0 signifie la pire santé que vous puissiez imaginer)",
  "patient_foot_ankle_quality_of_life_q6_a1":"Pire santé ",
  "patient_foot_ankle_quality_of_life_q6_a2":"Meilleure santé ",
  "patient_foot_ankle_satisfaction":"Satisfaction",
  "patient_foot_ankle_satisfaction_instruction":"Quel est votre niveau de satisfaction avec les résultats de votre chirurgie ou traitement de votre pied/cheville ? ",
  "patient_foot_ankle_satisfaction_instruction_a1":"Très insatisfait(e)",
  "patient_foot_ankle_satisfaction_instruction_a2":"Légèrement insatisfait(e)",
  "patient_foot_ankle_satisfaction_instruction_a3":"Légèrement satisfait(e)",
  "patient_foot_ankle_satisfaction_instruction_a4":"Très satisfait(e)",
  "patient_foot_ankle_satisfaction_q1":"Pour soulager vos symptômes ?",
  "patient_foot_ankle_satisfaction_q1_not_applicable":"Non applicable",
  "patient_foot_ankle_satisfaction_q1_a1":"Très insatisfait(e)",
  "patient_foot_ankle_satisfaction_q1_a2":"Légèrement insatisfait(e)",
  "patient_foot_ankle_satisfaction_q1_a3":"Légèrement satisfait(e)",
  "patient_foot_ankle_satisfaction_q1_a4":"Très satisfait(e)",
  "patient_foot_ankle_satisfaction_q2":"Pour améliorer votre capacité à effectuer vos activités quotidiennes ? ",
  "patient_foot_ankle_satisfaction_q2_not_applicable":"Non applicable",
  "patient_foot_ankle_satisfaction_q2_a1":"Très insatisfait(e)",
  "patient_foot_ankle_satisfaction_q2_a2":"Légèrement insatisfait(e)",
  "patient_foot_ankle_satisfaction_q2_a3":"Légèrement satisfait(e)",
  "patient_foot_ankle_satisfaction_q2_a4":"Très satisfait(e)",
  "patient_foot_ankle_satisfaction_q3":"Pour \"oublier\" votre problème de pied/cheville ? (conscience du problème de pied/cheville)",
  "patient_foot_ankle_satisfaction_q3_not_applicable":"Non applicable",
  "patient_foot_ankle_satisfaction_q3_a1":"Très insatisfait(e)",
  "patient_foot_ankle_satisfaction_q3_a2":"Légèrement insatisfait(e)",
  "patient_foot_ankle_satisfaction_q3_a3":"Légèrement satisfait(e)",
  "patient_foot_ankle_satisfaction_q3_a4":"Très satisfait(e)",
  "patient_foot_ankle_complete":"Fin du questionnaire",
  "patient_foot_ankle_complete_text1":"Merci d'avoir pris le temps de remplir ce formulaire. ",
  "patient_foot_ankle_complete_text2":"Veuillez cliquer Envoyer pour terminer et visualiser vos scores. ",
  "patient_foot_ankle_complete_text3":"Vos réponses seront traitées de façon confidentielle et tous rapports utiliseront seulement des informations anonymes. ",
  "patient_foot_ankle_results":"Résultats",
  "patient_foot_ankle_results_text1":"Vos scores de douleur et de résultats fonctionnels du pied/de la cheville calculés à partir de votre questionnaire sont représentés ci-dessous. ",
  "patient_foot_ankle_results_pain":"Douleur",
  "patient_foot_ankle_results_function":"Signes fonctionnels",
  "patient_foot_ankle_results_overall_health_perception":"Perception de santé générale",
  "patient_foot_ankle_results_feeling_of_instability":"Sensation d'instabilité",
  "patient_foot_ankle_results_self_evaluation":"Score d'auto-évaluation du pied/de la cheville",
  "patient_foot_ankle_results_text2":"Votre praticien sera avec vous d'ici peu.",
  "patient_elbow_pain":"Douleur",
  "patient_elbow_pain_q1":"Ressentez-vous de la douleur dans votre coude ? ",
  "patient_elbow_pain_q1_a1":"Non",
  "patient_elbow_pain_q1_a2":"Oui",
  "patient_elbow_pain_q2":"Quelle est la meilleure description de votre douleur dans le coude ? ",
  "patient_elbow_pain_q2_a1":"Aucun(e) ou ignoré(e)",
  "patient_elbow_pain_q2_a2":"Légère avec des activités, n'utilise jamais ou occasionnellement des médicaments/analgésiques",
  "patient_elbow_pain_q2_a3":"Modérée avec ou sans activités, utilise des analgésiques tous les jours",
  "patient_elbow_pain_q2_a4":"Modérée au repos ou la nuit",
  "patient_elbow_pain_q2_a5":"Sévère au repos, constante médication, handicapante",
  "patient_elbow_pain_instruction":"Evaluez votre DOULEUR dans votre coude",
  "patient_elbow_pain_q3":"En ce moment",
  "patient_elbow_pain_q3_a1":"Pas de douleur",
  "patient_elbow_pain_q3_a2":"Pire douleur, intolérable",
  "patient_elbow_pain_q4":"Douleur habituelle au cours des 4 dernières semaines",
  "patient_elbow_pain_q4_a1":"Pas de douleur",
  "patient_elbow_pain_q4_a2":"Pire douleur, intolérable",
  "patient_elbow_pain_q5":"Quand elle est à son pire au cours des 4 dernières semaines",
  "patient_elbow_pain_q5_a1":"Pas de douleur",
  "patient_elbow_pain_q5_a2":"Pire douleur, intolérable",
  "patient_elbow_pain_q6":"Au repos",
  "patient_elbow_pain_q6_a1":"Pas de douleur",
  "patient_elbow_pain_q6_a2":"Pire douleur, intolérable",
  "patient_elbow_pain_q7":"Soulever un objet lourd ",
  "patient_elbow_pain_q7_a1":"Pas de douleur",
  "patient_elbow_pain_q7_a2":"Pire douleur, intolérable",
  "patient_elbow_pain_q8":"En effectuant une tâche nécessitant des mouvements répétés du coude ",
  "patient_elbow_pain_q8_a1":"Pas de douleur",
  "patient_elbow_pain_q8_a2":"Pire douleur, intolérable",
  "patient_elbow_pain_q9":"La nuit ",
  "patient_elbow_pain_q9_a1":"Pas de douleur",
  "patient_elbow_pain_q9_a2":"Pire douleur, intolérable",
  "patient_elbow_pain_q10":"A quelle fréquence la douleur dans votre coude vous dérange-t-elle au lit la nuit ? ",
  "patient_elbow_pain_q10_a1":"Pas du tout",
  "patient_elbow_pain_q10_a2":"1-2 nuits",
  "patient_elbow_pain_q10_a3":"Quelques nuits",
  "patient_elbow_pain_q10_a4":"La plupart des nuits",
  "patient_elbow_pain_q10_a5":"Toutes les nuits",
  "patient_elbow_pain_q11":"A quelle fréquence la douleur dans votre coude a-t-elle impacté votre sommeil ? ",
  "patient_elbow_pain_q11_a1":"Pas du tout",
  "patient_elbow_pain_q11_a2":"Occasionnellement",
  "patient_elbow_pain_q11_a3":"Parfois",
  "patient_elbow_pain_q11_a4":"La plupart du temps",
  "patient_elbow_pain_q11_a5":"Tout le temps",
  "patient_elbow_function":"Signes fonctionnels",
  "patient_elbow_function_q1":"Quelle est la meilleure description de la FONCTION de votre coude ? ",
  "patient_elbow_function_q1_a1":"Pas de limitations",
  "patient_elbow_function_q1_a2":"Légères limitations, pas de restrictions des activités quotidiennes ",
  "patient_elbow_function_q1_a3":"Incapable de soulever des objets pesant >4.5kg",
  "patient_elbow_function_q1_a4":"Restrictions modérées des activités quotidiennes ",
  "patient_elbow_function_q1_a5":"Incapable de se peigner ou de se toucher la tête",
  "patient_elbow_function_q1_a6":"Incapable de se nourrir ",
  "patient_elbow_function_q2":"Quelle est la meilleure description de l'ACTIVITÉ que vous pouvez réaliser ? ",
  "patient_elbow_function_q2_a1":"Utilisation du coude sans limite",
  "patient_elbow_function_q2_a2":"Utilisation limitée pour les loisirs ",
  "patient_elbow_function_q2_a3":"Limité(e) aux tâches ménagères et professionnelles ",
  "patient_elbow_function_q2_a4":"Capable de prendre soin de soi",
  "patient_elbow_function_q2_a5":"Invalide",
  "patient_elbow_function_q3":"Pendant combien de temps pouvez-vous utiliser votre coude ? ",
  "patient_elbow_function_q3_a1":"Peut effectuer des activités pendant 30 minutes",
  "patient_elbow_function_q3_a2":"Peut effectuer des activités pendant 15 minutes",
  "patient_elbow_function_q3_a3":"Peut effectuer des activités pendant 5 minutes",
  "patient_elbow_function_q3_a4":"Ne peut pas utiliser le coude",
  "patient_elbow_function_instruction":"Indiquez le NIVEAU DE DIFFICULTÉ ressenti pour effectuer les activités suivantes au cours de la dernière semaine à cause de votre problème de coude",
  "patient_elbow_function_instruction_0":"Pas de difficulté",
  "patient_elbow_function_instruction_1":"Légère",
  "patient_elbow_function_instruction_2":"Modérée",
  "patient_elbow_function_instruction_3":"Sévère",
  "patient_elbow_function_instruction_4":"Incapable",
  "patient_elbow_function_q4":"Ouvrir un couvercle bien serré ou un pot neuf",
  "patient_elbow_function_q4_a1":"Pas de difficulté",
  "patient_elbow_function_q4_a2":"Légère",
  "patient_elbow_function_q4_a3":"Modérée",
  "patient_elbow_function_q4_a4":"Sévère",
  "patient_elbow_function_q4_a5":"Incapable",
  "patient_elbow_function_q5":"Porter un sac de courses ou un attaché-case ",
  "patient_elbow_function_q5_a1":"Pas de difficulté",
  "patient_elbow_function_q5_a2":"Légère",
  "patient_elbow_function_q5_a3":"Modérée",
  "patient_elbow_function_q5_a4":"Sévère",
  "patient_elbow_function_q5_a5":"Incapable",
  "patient_elbow_function_q6":"Soulever des choses à la maison, comme sortir les poubelles ",
  "patient_elbow_function_q6_a1":"Pas de difficulté",
  "patient_elbow_function_q6_a2":"Légère",
  "patient_elbow_function_q6_a3":"Modérée",
  "patient_elbow_function_q6_a4":"Sévère",
  "patient_elbow_function_q6_a5":"Incapable",
  "patient_elbow_function_q7":"Se laver le dos ",
  "patient_elbow_function_q7_a1":"Pas de difficulté",
  "patient_elbow_function_q7_a2":"Légère",
  "patient_elbow_function_q7_a3":"Modérée",
  "patient_elbow_function_q7_a4":"Sévère",
  "patient_elbow_function_q7_a5":"Incapable",
  "patient_elbow_function_q8":"Gérer sa toilette",
  "patient_elbow_function_q8_a1":"Pas de difficulté",
  "patient_elbow_function_q8_a2":"Légère",
  "patient_elbow_function_q8_a3":"Modérée",
  "patient_elbow_function_q8_a4":"Sévère",
  "patient_elbow_function_q8_a5":"Incapable",
  "patient_elbow_function_q9":"Se laver partout ",
  "patient_elbow_function_q9_a1":"Pas de difficulté",
  "patient_elbow_function_q9_a2":"Légère",
  "patient_elbow_function_q9_a3":"Modérée",
  "patient_elbow_function_q9_a4":"Sévère",
  "patient_elbow_function_q9_a5":"Incapable",
  "patient_elbow_function_q10":"Gérer son hygiène personnelle ",
  "patient_elbow_function_q10_a1":"Pas de difficulté",
  "patient_elbow_function_q10_a2":"Légère",
  "patient_elbow_function_q10_a3":"Modérée",
  "patient_elbow_function_q10_a4":"Sévère",
  "patient_elbow_function_q10_a5":"Incapable",
  "patient_elbow_function_q11":"Utiliser un couteau pour couper de la nourriture ",
  "patient_elbow_function_q11_a1":"Pas de difficulté",
  "patient_elbow_function_q11_a2":"Légère",
  "patient_elbow_function_q11_a3":"Modérée",
  "patient_elbow_function_q11_a4":"Sévère",
  "patient_elbow_function_q11_a5":"Incapable",
  "patient_elbow_function_q12":"Manger avec des couverts ",
  "patient_elbow_function_q12_a1":"Pas de difficulté",
  "patient_elbow_function_q12_a2":"Légère",
  "patient_elbow_function_q12_a3":"Modérée",
  "patient_elbow_function_q12_a4":"Sévère",
  "patient_elbow_function_q12_a5":"Incapable",
  "patient_elbow_function_q13":"Loisirs nécessitant une certaine force ou avec des chocs au niveau du bras, de l'épaule ou de la main (ex golf, bricolage avec un marteau, tennis etc) ",
  "patient_elbow_function_q13_a1":"Pas de difficulté",
  "patient_elbow_function_q13_a2":"Légère",
  "patient_elbow_function_q13_a3":"Modérée",
  "patient_elbow_function_q13_a4":"Sévère",
  "patient_elbow_function_q13_a5":"Incapable",
  "patient_elbow_function_q14":"Dormir à cause de la douleur dans votre bras, épaule ou main ",
  "patient_elbow_function_q14_a1":"Pas de difficulté",
  "patient_elbow_function_q14_a2":"Légère",
  "patient_elbow_function_q14_a3":"Modérée",
  "patient_elbow_function_q14_a4":"Sévère",
  "patient_elbow_function_q14_a5":"Incapable",
  "patient_elbow_function_q15":"Mettre une chemise ",
  "patient_elbow_function_q15_a1":"Pas de difficulté",
  "patient_elbow_function_q15_a2":"Légère",
  "patient_elbow_function_q15_a3":"Modérée",
  "patient_elbow_function_q15_a4":"Sévère",
  "patient_elbow_function_q15_a5":"Incapable",
  "patient_elbow_function_q16":"Boutonner le dernier bouton (au niveau du col) d'une chemise ",
  "patient_elbow_function_q16_a1":"Pas de difficulté",
  "patient_elbow_function_q16_a2":"Légère",
  "patient_elbow_function_q16_a3":"Modérée",
  "patient_elbow_function_q16_a4":"Sévère",
  "patient_elbow_function_q16_a5":"Incapable",
  "patient_elbow_function_q17":"S'habiller ",
  "patient_elbow_function_q17_a1":"Pas de difficulté",
  "patient_elbow_function_q17_a2":"Légère",
  "patient_elbow_function_q17_a3":"Modérée",
  "patient_elbow_function_q17_a4":"Sévère",
  "patient_elbow_function_q17_a5":"Incapable",
  "patient_elbow_function_q18":"Se peigner ",
  "patient_elbow_function_q18_a1":"Pas de difficulté",
  "patient_elbow_function_q18_a2":"Légère",
  "patient_elbow_function_q18_a3":"Modérée",
  "patient_elbow_function_q18_a4":"Sévère",
  "patient_elbow_function_q18_a5":"Incapable",
  "patient_elbow_function_q19":"Mettre des chaussures ",
  "patient_elbow_function_q19_a1":"Pas de difficulté",
  "patient_elbow_function_q19_a2":"Légère",
  "patient_elbow_function_q19_a3":"Modérée",
  "patient_elbow_function_q19_a4":"Sévère",
  "patient_elbow_function_q19_a5":"Incapable",
  "patient_elbow_function_q20":"Faire ses lacets ",
  "patient_elbow_function_q20_a1":"Pas de difficulté",
  "patient_elbow_function_q20_a2":"Légère",
  "patient_elbow_function_q20_a3":"Modérée",
  "patient_elbow_function_q20_a4":"Sévère",
  "patient_elbow_function_q20_a5":"Incapable",
  "patient_elbow_function_q21":"Porter un objet lourd ",
  "patient_elbow_function_q21_a1":"Pas de difficulté",
  "patient_elbow_function_q21_a2":"Légère",
  "patient_elbow_function_q21_a3":"Modérée",
  "patient_elbow_function_q21_a4":"Sévère",
  "patient_elbow_function_q21_a5":"Incapable",
  "patient_elbow_function_q22":"Se lever d'une chaise en poussant avec le bras ",
  "patient_elbow_function_q22_a1":"Pas de difficulté",
  "patient_elbow_function_q22_a2":"Légère",
  "patient_elbow_function_q22_a3":"Modérée",
  "patient_elbow_function_q22_a4":"Sévère",
  "patient_elbow_function_q22_a5":"Incapable",
  "patient_elbow_function_q23":"Effectuer des tâches ménagères intenses (ex lessiver des murs, le sol)",
  "patient_elbow_function_q23_a1":"Pas de difficulté",
  "patient_elbow_function_q23_a2":"Légère",
  "patient_elbow_function_q23_a3":"Modérée",
  "patient_elbow_function_q23_a4":"Sévère",
  "patient_elbow_function_q23_a5":"Incapable",
  "patient_elbow_function_q24":"Tourner une clé ",
  "patient_elbow_function_q24_a1":"Pas de difficulté",
  "patient_elbow_function_q24_a2":"Légère",
  "patient_elbow_function_q24_a3":"Modérée",
  "patient_elbow_function_q24_a4":"Sévère",
  "patient_elbow_function_q24_a5":"Incapable",
  "patient_elbow_function_q25":"Jeter un ballon ",
  "patient_elbow_function_q25_a1":"Pas de difficulté",
  "patient_elbow_function_q25_a2":"Légère",
  "patient_elbow_function_q25_a3":"Modérée",
  "patient_elbow_function_q25_a4":"Sévère",
  "patient_elbow_function_q25_a5":"Incapable",
  "patient_elbow_function_q26":"Effectuer vos activités professionnelles habituelles à cause de la douleur dans votre bras, épaule ou main (profession comprend au foyer si cela est votre activité principale) ",
  "patient_elbow_function_q26_a1":"Pas de difficulté",
  "patient_elbow_function_q26_a2":"Légère",
  "patient_elbow_function_q26_a3":"Modérée",
  "patient_elbow_function_q26_a4":"Sévère",
  "patient_elbow_function_q26_a5":"Incapable",
  "patient_elbow_function_q27":"Indiquez votre activité professionnelle",
  "patient_elbow_function_q27_placeholder":"Précisez le travail",
  "patient_elbow_function_i_donot_work":"Je ne travaille pas ",
  "patient_elbow_function_q28":"Utiliser votre technique habituelle au travail ",
  "patient_elbow_function_q28_a1":"Pas de difficulté",
  "patient_elbow_function_q28_a2":"Légère",
  "patient_elbow_function_q28_a3":"Modérée",
  "patient_elbow_function_q28_a4":"Sévère",
  "patient_elbow_function_q28_a5":"Incapable",
  "patient_elbow_function_q29":"Faire votre travail aussi bien que vous le désireriez ",
  "patient_elbow_function_q29_a1":"Pas de difficulté",
  "patient_elbow_function_q29_a2":"Légère",
  "patient_elbow_function_q29_a3":"Modérée",
  "patient_elbow_function_q29_a4":"Sévère",
  "patient_elbow_function_q29_a5":"Incapable",
  "patient_elbow_function_q30":"Passer autant de temps que d'habitude pour faire votre travail",
  "patient_elbow_function_q30_a1":"Pas de difficulté",
  "patient_elbow_function_q30_a2":"Légère",
  "patient_elbow_function_q30_a3":"Modérée",
  "patient_elbow_function_q30_a4":"Sévère",
  "patient_elbow_function_q30_a5":"Incapable",
  "patient_elbow_function_q31":"Jouer de votre instrument de musique ou faire du sport à cause de la douleur dans votre bras, épaule ou main",
  "patient_elbow_function_q31_a1":"Pas de difficulté",
  "patient_elbow_function_q31_a2":"Légère",
  "patient_elbow_function_q31_a3":"Modérée",
  "patient_elbow_function_q31_a4":"Sévère",
  "patient_elbow_function_q31_a5":"Incapable",
  "patient_elbow_function_q32":"Indiquez le sport pratiqué ou l'instrument de musique joué",
  "patient_elbow_function_q32_placeholder":"Indiquez le sport/instrument de musique ",
  "patient_elbow_function_i_donot_play":"Je ne fais pas de sport ni ne joue d'un instrument de musique ",
  "patient_elbow_function_q33":"Utiliser votre technique habituelle jouer de votre instrument de musique ou pour faire du sport",
  "patient_elbow_function_q33_a1":"Pas de difficulté",
  "patient_elbow_function_q33_a2":"Légère",
  "patient_elbow_function_q33_a3":"Modérée",
  "patient_elbow_function_q33_a4":"Sévère",
  "patient_elbow_function_q33_a5":"Incapable",
  "patient_elbow_function_q34":"Jouer de votre instrument de musique ou faire du sport aussi bien que vous le désireriez ",
  "patient_elbow_function_q34_a1":"Pas de difficulté",
  "patient_elbow_function_q34_a2":"Légère",
  "patient_elbow_function_q34_a3":"Modérée",
  "patient_elbow_function_q34_a4":"Sévère",
  "patient_elbow_function_q34_a5":"Incapable",
  "patient_elbow_function_q35":"Passer autant de temps que d'habitude pour jouer de votre instrument de musique ou pratiquer votre sport",
  "patient_elbow_function_q35_a1":"Pas de difficulté",
  "patient_elbow_function_q35_a2":"Légère",
  "patient_elbow_function_q35_a3":"Modérée",
  "patient_elbow_function_q35_a4":"Sévère",
  "patient_elbow_function_q35_a5":"Incapable",
  "patient_elbow_function_q36":"Au cours de la dernière semaine, à quel point votre problème de bras, épaule ou main a-t-il impacté vos ACTIVITÉS SOCIALES/DE LOISIRS ORDINAIRES avec votre famille, amis ou voisins, ou groupes ? ",
  "patient_elbow_function_q36_a1":"Pas du tout",
  "patient_elbow_function_q36_a2":"Légèrement ",
  "patient_elbow_function_q36_a3":"Modérément",
  "patient_elbow_function_q36_a4":"Un peu",
  "patient_elbow_function_q36_a5":"Extrêmement",
  "patient_elbow_function_q37":"Au cours de la dernière semaine, à quel point avez-vous été limité(e) dans votre TRAVAIL OU AUTRES ACTIVITÉS QUOTIDIENNES HABITUELLES à cause de votre problème de bras, épaule ou main ? ",
  "patient_elbow_function_q37_a1":"Pas du tout",
  "patient_elbow_function_q37_a2":"Légèrement ",
  "patient_elbow_function_q37_a3":"Modérément",
  "patient_elbow_function_q37_a4":"Un peu",
  "patient_elbow_function_q37_a5":"Extrêmement",
  "patient_elbow_symptoms":"Autres symptômes",
  "patient_elbow_symptoms_instruction":"Veuillez indiquer l'intensité des symptômes suivants au cours de la dernière semaine : ",
  "patient_elbow_symptoms_instruction_0":"Aucuns",
  "patient_elbow_symptoms_instruction_1":"Légers",
  "patient_elbow_symptoms_instruction_2":"Modérés",
  "patient_elbow_symptoms_instruction_3":"Sévères",
  "patient_elbow_symptoms_instruction_4":"Extrêmes",
  "patient_elbow_symptoms_q1":"Douleur dans le bras, l'épaule, ou la main",
  "patient_elbow_symptoms_q1_a1":"Aucune",
  "patient_elbow_symptoms_q1_a2":"Légère",
  "patient_elbow_symptoms_q1_a3":"Modérée",
  "patient_elbow_symptoms_q1_a4":"Sévère",
  "patient_elbow_symptoms_q1_a5":"Extrême",
  "patient_elbow_symptoms_q2":"Fourmillement dans votre bras, épaule, ou main",
  "patient_elbow_symptoms_q2_a1":"Aucuns",
  "patient_elbow_symptoms_q2_a2":"Légers",
  "patient_elbow_symptoms_q2_a3":"Modérés",
  "patient_elbow_symptoms_q2_a4":"Sévères",
  "patient_elbow_symptoms_q2_a5":"Extrêmes",
  "patient_elbow_symptoms_q3":"Comment évaluez-vous votre coude aujourd'hui sur un ratio de normalité ? (de 0% à 100% avec 100% étant normal)",
  "patient_elbow_quality_of_life":"Qualité de vie",
  "patient_elbow_quality_of_life_instruction":"Les questions suivantes concernent les inquiétudes sociales, émotionnelles et de mode de vie que vous pouvez ressentir en rapport avec votre problème de coude. Veuillez penser à ce que vous avez ressenti la plupart du temps en relation avec votre problème de coude.",
  "patient_elbow_quality_of_life_q1":"Au cours des 4 dernières semaines, avez-vous eu l'impression que votre problème de coude a \"pris le contrôle\" de votre vie ?",
  "patient_elbow_quality_of_life_q1_a1":"Pas du tout",
  "patient_elbow_quality_of_life_q1_a2":"Occasionnellement",
  "patient_elbow_quality_of_life_q1_a3":"Certains jours",
  "patient_elbow_quality_of_life_q1_a4":"La plupart des jours",
  "patient_elbow_quality_of_life_q1_a5":"Tous les jours",
  "patient_elbow_quality_of_life_q2":"Au cours des 4 dernières semaines, à quel point aviez-vous votre coude à l'esprit ?",
  "patient_elbow_quality_of_life_q2_a1":"Pas du tout",
  "patient_elbow_quality_of_life_q2_a2":"Peu souvent",
  "patient_elbow_quality_of_life_q2_a3":"Parfois",
  "patient_elbow_quality_of_life_q2_a4":"La plupart du temps",
  "patient_elbow_quality_of_life_q2_a5":"Tout le temps",
  "patient_elbow_quality_of_life_instruction1":"Veuillez évaluer les propositions suivantes pour décrire au mieux votre niveau de SANTÉ AUJOURD'HUI :  ",
  "patient_elbow_quality_of_life_instruction1_0":"Pas de problème",
  "patient_elbow_quality_of_life_instruction1_1":"Léger",
  "patient_elbow_quality_of_life_instruction1_2":"Modéré",
  "patient_elbow_quality_of_life_instruction1_3":"Sévère",
  "patient_elbow_quality_of_life_instruction1_4":"Extrême/Incapable",
  "patient_elbow_quality_of_life_q3":"Problème pour se promener ",
  "patient_elbow_quality_of_life_q3_a1":"Pas de problème",
  "patient_elbow_quality_of_life_q3_a2":"Léger",
  "patient_elbow_quality_of_life_q3_a3":"Modéré",
  "patient_elbow_quality_of_life_q3_a4":"Sévère",
  "patient_elbow_quality_of_life_q3_a5":"Incapable",
  "patient_elbow_quality_of_life_q4":"Problème pour se laver ou s'habiller",
  "patient_elbow_quality_of_life_q4_a1":"Pas de problème",
  "patient_elbow_quality_of_life_q4_a2":"Léger",
  "patient_elbow_quality_of_life_q4_a3":"Modéré",
  "patient_elbow_quality_of_life_q4_a4":"Sévère",
  "patient_elbow_quality_of_life_q4_a5":"Incapable",
  "patient_elbow_quality_of_life_q5":"Problème pour réaliser des activités habituelles (ex travailler, étudier, tâches ménagères, famille, loisirs) ",
  "patient_elbow_quality_of_life_q5_a1":"Pas de problème",
  "patient_elbow_quality_of_life_q5_a2":"Léger",
  "patient_elbow_quality_of_life_q5_a3":"Modéré",
  "patient_elbow_quality_of_life_q5_a4":"Sévère",
  "patient_elbow_quality_of_life_q5_a5":"Incapable",
  "patient_elbow_quality_of_life_q6":"Douleur ou inconfort ",
  "patient_elbow_quality_of_life_q6_a1":"Aucun(e)",
  "patient_elbow_quality_of_life_q6_a2":"Léger(e)",
  "patient_elbow_quality_of_life_q6_a3":"Modéré(e)",
  "patient_elbow_quality_of_life_q6_a4":"Sévère",
  "patient_elbow_quality_of_life_q6_a5":"Extrême",
  "patient_elbow_quality_of_life_q7":"Anxiété/dépression",
  "patient_elbow_quality_of_life_q7_a1":"Pas anxieux(se)/déprimé(e)",
  "patient_elbow_quality_of_life_q7_a2":"Un peu anxieux(se)/déprimé(e)",
  "patient_elbow_quality_of_life_q7_a3":"Modérément anxieux(se)/déprimé(e)",
  "patient_elbow_quality_of_life_q7_a4":"Fortement anxieux(se)/déprimé(e)",
  "patient_elbow_quality_of_life_q7_a5":"Extrêmement anxieux(se)/déprimé(e)",
  "patient_elbow_quality_of_life_q8":"Comment évaluez-vous votre santé AUJOURD'HUI ? ",
  "patient_elbow_quality_of_life_q8_instruction":"100 signifie meilleure santé, 0 signifie la pire santé que vous puissiez imaginer",
  "patient_elbow_quality_of_life_q8_a1":"Pire",
  "patient_elbow_quality_of_life_q8_a2":"Meilleure",
  "patient_elbow_satisfaction":"Satisfaction",
  "patient_elbow_satisfaction_q1":"Etes-vous satisfait(e) avec le traitement pour votre coude ? ",
  "patient_elbow_satisfaction_na":"Non applicable",
  "patient_elbow_satisfaction_q1_a1":"Pas du tout satisfait(e)",
  "patient_elbow_satisfaction_q1_a2":"Très satisfait(e)",
  "patient_elbow_complete":"Fin du questionnaire",
  "patient_elbow_complete_text1":"Merci d'avoir pris le temps de remplir ce formulaire. ",
  "patient_elbow_complete_text2":"Veuillez cliquer sur Envoyer pour terminer et visualiser vos scores. ",
  "patient_elbow_complete_text3":"Vos réponses seront traitées de façon confidentielle et tous rapports utiliseront seulement des informations anonymes. ",
  "patient_elbow_results":"Résultats",
  "patient_elbow_results_text1":"Vos scores de douleur et de résultats fonctionnels du coude calculés à partir de votre questionnaire sont représentés ci-dessous. ",
  "patient_elbow_results_function":"Signes fonctionnels",
  "patient_elbow_results_pain":"Douleur",
  "patient_elbow_results_health_perception":"Perception de santé générale",
  "patient_elbow_results_self_evaluation":"Score d'auto-évaluation du Coude",
  "patient_elbow_results_text4":"Votre Praticien sera avec vous d'ici peu. ",
  "patient_hand_pain":"Douleur",
  "patient_hand_pain_q1":"Veuillez décrire la douleur dans votre main/poignet AU COURS DE LA DERNIÈRE SEMAINE",
  "patient_hand_pain_q1_a1":"Aucune à très légère",
  "patient_hand_pain_q1_a2":"Légère",
  "patient_hand_pain_q1_a3":"Modérée",
  "patient_hand_pain_q1_a4":"Sévère",
  "patient_hand_pain_q1_a5":"Très sévère/extrême",
  "patient_hand_pain_q2":"Quelle est l'intensité de la douleur dans votre main/poignet EN CE MOMENT ? ",
  "patient_hand_pain_q2_a1":"Pas de douleur",
  "patient_hand_pain_q2_a2":"La pire douleur ",
  "patient_hand_pain_q3":"A quelle fréquence la douleur dans votre main/poignet a-t-elle impacté vos activités quotidiennes (comme manger ou se laver) au cours de la dernière semaine ?",
  "patient_hand_pain_q3_a1":"Jamais",
  "patient_hand_pain_q3_a2":"Rarement",
  "patient_hand_pain_q3_a3":"Parfois",
  "patient_hand_pain_q3_a4":"Souvent",
  "patient_hand_pain_q3_a5":"Toujours",
  "patient_hand_symptoms":"Autres symptômes",
  "patient_hand_symptoms_q1":"Quelle intensité de fourmillements dans votre bras, épaule ou main avez-vous ressentis au cours de la dernière semaine ? ",
  "patient_hand_symptoms_q1_a1":"Aucuns",
  "patient_hand_symptoms_q1_a2":"Légers",
  "patient_hand_symptoms_q1_a3":"Modérés",
  "patient_hand_symptoms_q1_a4":"Sévères",
  "patient_hand_symptoms_q1_a5":"Extrêmes",
  "patient_hand_symptoms_q2":"Comment décririez-vous les sensations dans votre main au cours de la dernière semaine ? ",
  "patient_hand_symptoms_q2_a1":"Très bonnes",
  "patient_hand_symptoms_q2_a2":"Bonnes",
  "patient_hand_symptoms_q2_a3":"Passables",
  "patient_hand_symptoms_q2_a4":"Médiocres",
  "patient_hand_symptoms_q2_a5":"Très médiocres",
  "patient_hand_symptoms_q3":"L'aspect de ma main impacte mes activités quotidiennes habituelles",
  "patient_hand_symptoms_q3_a1":"Pas du tout d'accord",
  "patient_hand_symptoms_q3_a2":"Pas d'accord",
  "patient_hand_symptoms_q3_a3":"Ni en accord ni en désaccord",
  "patient_hand_symptoms_q3_a4":"D'accord",
  "patient_hand_symptoms_q3_a5":"Très en accord",
  "patient_hand_symptoms_q3_a6":"Comment évaluez-vous votre main/poignet aujourd'hui sur un ratio de normalité ? (de 0% à 100% avec 100% étant normal)",
  "patient_hand_symptoms_q3_a6_normal":"Normal(e)",
  "patient_hand_function":"Signes fonctionnels",
  "patient_hand_function_instruction1":"Si vous ne faites pas une certaine activité, veuillez estimer la difficulté que vous ressentiriez pour la réaliser. ",
  "patient_hand_function_instruction2":"Si vous n'êtes pas sûr(e) comment répondre, veuillez répondre du mieux que vous pouvez. ",
  "patient_hand_function_q1":"De façon générale, comment fonctionnait votre main au cours de la dernière semaine ? ",
  "patient_hand_function_q1_a1":"Très bien",
  "patient_hand_function_q1_a2":"Bien",
  "patient_hand_function_q1_a3":"Passable",
  "patient_hand_function_q1_a4":"Médiocre",
  "patient_hand_function_q1_a5":"Très médiocre",
  "patient_hand_function_q2":"A quelle fréquence n'avez-vous PAS été CAPABLE DE TRAVAILLER à cause de vos problèmes de main/poignet ? ",
  "patient_hand_function_q2_a1":"Jamais",
  "patient_hand_function_q2_a2":"Rarement",
  "patient_hand_function_q2_a3":"Parfois",
  "patient_hand_function_q2_a4":"Souvent",
  "patient_hand_function_q2_a5":"Toujours",
  "patient_hand_function_q3":"A quelle fréquence vous-a-t-il PRIS PLUS DE TEMPS QUE LA NORMALE POUR EFFECTUER DES TÂCHES requises par votre activité professionnelle à cause de vos problèmes main/poignet ? ",
  "patient_hand_function_q3_a1":"Jamais",
  "patient_hand_function_q3_a2":"Rarement",
  "patient_hand_function_q3_a3":"Parfois",
  "patient_hand_function_q3_a4":"Souvent",
  "patient_hand_function_q3_a5":"Toujours",
  "patient_hand_function_instruction":"Indiquez le NIVEAU DE DIFFICULTÉ pour effectuer les activités suivantes au cours de la dernière semaine à cause des problèmes dans votre main/poignet",
  "patient_hand_function_instruction_0":"Pas de difficulté",
  "patient_hand_function_instruction_1":"Difficulté légère",
  "patient_hand_function_instruction_2":"Difficulté modérée",
  "patient_hand_function_instruction_3":"Forte difficulté",
  "patient_hand_function_instruction_4":"Difficulté extrême/incapable",
  "patient_hand_function_q4":"Ouvrir un couvercle bien serré ou un pot neuf",
  "patient_hand_function_q4_a1":"Pas de difficulté",
  "patient_hand_function_q4_a2":"Difficulté légère",
  "patient_hand_function_q4_a3":"Difficulté modérée",
  "patient_hand_function_q4_a4":"Forte difficulté",
  "patient_hand_function_q4_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q5":"Tenir une poêle",
  "patient_hand_function_q5_a1":"Pas de difficulté",
  "patient_hand_function_q5_a2":"Difficulté légère",
  "patient_hand_function_q5_a3":"Difficulté modérée",
  "patient_hand_function_q5_a4":"Forte difficulté",
  "patient_hand_function_q5_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q6":"Boutonner une chemise ou une blouse ",
  "patient_hand_function_q6_a1":"Pas de difficulté",
  "patient_hand_function_q6_a2":"Difficulté légère",
  "patient_hand_function_q6_a3":"Difficulté modérée",
  "patient_hand_function_q6_a4":"Forte difficulté",
  "patient_hand_function_q6_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q7":"Effectuer des tâches ménagères intenses (ex lessiver des murs, le sol)",
  "patient_hand_function_q7_a1":"Pas de difficulté",
  "patient_hand_function_q7_a2":"Difficulté légère",
  "patient_hand_function_q7_a3":"Difficulté modérée",
  "patient_hand_function_q7_a4":"Forte difficulté",
  "patient_hand_function_q7_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q8":"Porter un sac de courses ou un attaché-case ",
  "patient_hand_function_q8_a1":"Pas de difficulté",
  "patient_hand_function_q8_a2":"Difficulté légère",
  "patient_hand_function_q8_a3":"Difficulté modérée",
  "patient_hand_function_q8_a4":"Forte difficulté",
  "patient_hand_function_q8_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q9":"Se laver le dos ",
  "patient_hand_function_q9_a1":"Pas de difficulté",
  "patient_hand_function_q9_a2":"Difficulté légère",
  "patient_hand_function_q9_a3":"Difficulté modérée",
  "patient_hand_function_q9_a4":"Forte difficulté",
  "patient_hand_function_q9_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q10":"Utiliser un couteau pour couper de la nourriture ",
  "patient_hand_function_q10_a1":"Pas de difficulté",
  "patient_hand_function_q10_a2":"Difficulté légère",
  "patient_hand_function_q10_a3":"Difficulté modérée",
  "patient_hand_function_q10_a4":"Forte difficulté",
  "patient_hand_function_q10_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q11":"Loisirs nécessitant une certaine force ou avec des chocs au niveau du bras, de l'épaule ou de la main (ex golf, bricolage avec un marteau, tennis etc) ",
  "patient_hand_function_q11_a1":"Pas de difficulté",
  "patient_hand_function_q11_a2":"Difficulté légère",
  "patient_hand_function_q11_a3":"Difficulté modérée",
  "patient_hand_function_q11_a4":"Forte difficulté",
  "patient_hand_function_q11_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q12":"Dormir à cause de la douleur dans votre bras, épaule ou main ",
  "patient_hand_function_q12_a1":"Pas de difficulté",
  "patient_hand_function_q12_a2":"Difficulté légère",
  "patient_hand_function_q12_a3":"Difficulté modérée",
  "patient_hand_function_q12_a4":"Forte difficulté",
  "patient_hand_function_q12_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q13":"Effectuer votre travail habituel à cause de la douleur dans votre bras, épaule ou main (profession comprend au foyer si cela est votre activité principale) ",
  "patient_hand_function_q13_a1":"Pas de difficulté",
  "patient_hand_function_q13_a2":"Difficulté légère",
  "patient_hand_function_q13_a3":"Difficulté modérée",
  "patient_hand_function_q13_a4":"Forte difficulté",
  "patient_hand_function_q13_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q14":"Indiquez votre travail habituel",
  "patient_hand_function_q14_placeholder":"Indiquez le travail",
  "patient_hand_function_do_dot_work":"Je ne travaille pas ",
  "patient_hand_function_q15":"Utiliser votre technique habituelle pour travailler",
  "patient_hand_function_q15_a1":"Pas de difficulté",
  "patient_hand_function_q15_a2":"Difficulté légère",
  "patient_hand_function_q15_a3":"Difficulté modérée",
  "patient_hand_function_q15_a4":"Forte difficulté",
  "patient_hand_function_q15_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q16":"Faire votre travail aussi bien que vous le désireriez ",
  "patient_hand_function_q16_a1":"Pas de difficulté",
  "patient_hand_function_q16_a2":"Difficulté légère",
  "patient_hand_function_q16_a3":"Difficulté modérée",
  "patient_hand_function_q16_a4":"Forte difficulté ",
  "patient_hand_function_q16_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q17":"Passer autant de temps que d'habitude pour faire votre travail",
  "patient_hand_function_q17_a1":"Pas de difficulté",
  "patient_hand_function_q17_a2":"Difficulté légère",
  "patient_hand_function_q17_a3":"Difficulté modérée",
  "patient_hand_function_q17_a4":"Forte difficulté",
  "patient_hand_function_q17_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q18":"Jouer de votre instrument de musique ou faire du sport à cause de la douleur dans votre bras, épaule ou main",
  "patient_hand_function_q18_a1":"Pas de difficulté",
  "patient_hand_function_q18_a2":"Difficulté légère",
  "patient_hand_function_q18_a3":"Difficulté modérée",
  "patient_hand_function_q18_a4":"Forte difficulté",
  "patient_hand_function_q18_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q19":"Indiquez le sport pratiqué ou l'instrument de musique joué",
  "patient_hand_function_q19_placeholder":"Indiquez le sport/instrument de musique",
  "patient_hand_function_donot_play":"Je ne fais pas de sport ni ne joue d'un instrument de musique ",
  "patient_hand_function_20":"Utiliser votre technique habituelle pour jouer de votre instrument de musique ou faire du sport",
  "patient_hand_function_q20_a1":"Pas de difficulté",
  "patient_hand_function_q20_a2":"Difficulté légère",
  "patient_hand_function_q20_a3":"Difficulté modérée",
  "patient_hand_function_q20_a4":"Forte difficulté",
  "patient_hand_function_q20_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q21":"Jouer de votre instrument de musique ou faire du sport aussi bien que vous le désireriez ",
  "patient_hand_function_q21_a1":"Pas de difficulté",
  "patient_hand_function_q21_a2":"Difficulté légère",
  "patient_hand_function_q21_a3":"Difficulté modérée",
  "patient_hand_function_q21_a4":"Forte difficulté",
  "patient_hand_function_q21_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q22":"Passer autant de temps que d'habitude pour pratiquer votre sport ou jouer de votre instrument de musique ",
  "patient_hand_function_q22_a1":"Pas de difficulté",
  "patient_hand_function_q22_a2":"Difficulté légère",
  "patient_hand_function_q22_a3":"Difficulté modérée",
  "patient_hand_function_q22_a4":"Forte difficulté",
  "patient_hand_function_q22_a5":"Difficulté extrême/incapable",
  "patient_hand_function_q23":"Au cours de la dernière semaine, à quel point votre problème de bras, épaule ou main a-t-il impacté vos ACTIVITÉS SOCIALES/DE LOISIR ORDINAIRES avec votre famille, amis, voisins, ou groupes ? ",
  "patient_hand_function_q23_a1":"Pas du tout",
  "patient_hand_function_q23_a2":"Légèrement ",
  "patient_hand_function_q23_a3":"Modérément",
  "patient_hand_function_q23_a4":"Un peu",
  "patient_hand_function_q23_a5":"Extrêmement",
  "patient_hand_function_q24":"Au cours de la dernière semaine, à quel point avez-vous été limité(e) dans vos ACTIVITÉS PROFESSIONNELLES OU AUTRES ACTIVITÉS QUOTIDIENNES HABITUELLES à cause de votre problème de bras, épaule ou main ? ",
  "patient_hand_function_q24_a1":"Pas du tout",
  "patient_hand_function_q24_a2":"Légèrement ",
  "patient_hand_function_q24_a3":"Modérément",
  "patient_hand_function_q24_a4":"Un peu",
  "patient_hand_function_q24_a5":"Extrêmement",
  "patient_hand_questionnaire":"Questionnaire de Boston du Syndrome du Canal Carpien",
  "patient_hand_questionnaire_symptom":"Symptômes",
  "patient_hand_questionnaire_symptom_instruction":"Veuillez choisir les réponses qui correspondent le mieux à vos symptômes. ",
  "patient_hand_questionnaire_symptom_instruction_0":"Normaux",
  "patient_hand_questionnaire_symptom_instruction_1":"Légers",
  "patient_hand_questionnaire_symptom_instruction_2":"Moyens",
  "patient_hand_questionnaire_symptom_instruction_3":"Sévères",
  "patient_hand_questionnaire_symptom_instruction_4":"Très forts",
  "patient_hand_questionnaire_symptom_q1":"Quelle est l'intensité de la douleur dans votre main ou poignet la nuit ? ",
  "patient_hand_questionnaire_symptom_q1_a1":"Normale",
  "patient_hand_questionnaire_symptom_q1_a2":"Légère",
  "patient_hand_questionnaire_symptom_q1_a3":"Moyenne",
  "patient_hand_questionnaire_symptom_q1_a4":"Sévère",
  "patient_hand_questionnaire_symptom_q1_a5":"Très forte",
  "patient_hand_questionnaire_symptom_q2":"A quelle fréquence la douleur dans votre main ou poignet vous a-t-elle réveillé dans la nuit au cours des 2 dernières semaines ?",
  "patient_hand_questionnaire_symptom_q2_a1":"Normale",
  "patient_hand_questionnaire_symptom_q2_a2":"Une fois",
  "patient_hand_questionnaire_symptom_q2_a3":"2-3 fois",
  "patient_hand_questionnaire_symptom_q2_a4":"4-5 fois",
  "patient_hand_questionnaire_symptom_q2_a5":"Plus de 5 fois",
  "patient_hand_questionnaire_symptom_q3":"De façon générale, ressentez-vous de la douleur votre main ou poignet dans la journée ? ",
  "patient_hand_questionnaire_symptom_q3_a1":"Pas de douleur",
  "patient_hand_questionnaire_symptom_q3_a2":"Légère",
  "patient_hand_questionnaire_symptom_q3_a3":"Moyenne",
  "patient_hand_questionnaire_symptom_q3_a4":"Sévère",
  "patient_hand_questionnaire_symptom_q3_a5":"Très forte",
  "patient_hand_questionnaire_symptom_q4":"A quelle fréquence ressentez-vous de la douleur dans votre main ou poignet dans la journée ? ",
  "patient_hand_questionnaire_symptom_q4_a1":"Normale",
  "patient_hand_questionnaire_symptom_q4_a2":"1-2 fois par jour",
  "patient_hand_questionnaire_symptom_q4_a3":"3-5 fois par jour",
  "patient_hand_questionnaire_symptom_q4_a4":"Plus de 5 fois par jour",
  "patient_hand_questionnaire_symptom_q4_a5":"En continu",
  "patient_hand_questionnaire_symptom_q5":"Pendant combien de temps dure un épisode douloureux dans la journée ? ",
  "patient_hand_questionnaire_symptom_q5_a1":"Normal",
  "patient_hand_questionnaire_symptom_q5_a2":"< 10 minutes",
  "patient_hand_questionnaire_symptom_q5_a3":"10-60 minutes en continu",
  "patient_hand_questionnaire_symptom_q5_a4":"> 60 minutes",
  "patient_hand_questionnaire_symptom_q5_a5":"En continu",
  "patient_hand_questionnaire_symptom_q6":"Ressentez-vous des engourdissements (perte de sensation) dans votre main ? ",
  "patient_hand_questionnaire_symptom_q6_a1":"Normaux",
  "patient_hand_questionnaire_symptom_q6_a2":"Légers",
  "patient_hand_questionnaire_symptom_q6_a3":"Moyens",
  "patient_hand_questionnaire_symptom_q6_a4":"Sévères",
  "patient_hand_questionnaire_symptom_q6_a5":"Très forts",
  "patient_hand_questionnaire_symptom_q7":"Ressentez-vous de la faiblesse dans votre main ou poignet ? ",
  "patient_hand_questionnaire_symptom_q7_a1":"Normale",
  "patient_hand_questionnaire_symptom_q7_a2":"Légère",
  "patient_hand_questionnaire_symptom_q7_a3":"Moyenne",
  "patient_hand_questionnaire_symptom_q7_a4":"Sévère",
  "patient_hand_questionnaire_symptom_q7_a5":"Très forte",
  "patient_hand_questionnaire_symptom_q8":"Ressentez-vous des picotements dans votre main ? ",
  "patient_hand_questionnaire_symptom_q8_a1":"Normaux",
  "patient_hand_questionnaire_symptom_q8_a2":"Légers",
  "patient_hand_questionnaire_symptom_q8_a3":"Moyens",
  "patient_hand_questionnaire_symptom_q8_a4":"Sévères",
  "patient_hand_questionnaire_symptom_q8_a5":"Très forts",
  "patient_hand_questionnaire_symptom_q9":"Quelle est l'intensité des engourdissements (perte de sensation) ou picotements la nuit ? ",
  "patient_hand_questionnaire_symptom_q9_a1":"Normaux",
  "patient_hand_questionnaire_symptom_q9_a2":"Légers",
  "patient_hand_questionnaire_symptom_q9_a3":"Moyens",
  "patient_hand_questionnaire_symptom_q9_a4":"Sévères",
  "patient_hand_questionnaire_symptom_q9_a5":"Très forts",
  "patient_hand_questionnaire_symptom_q10":"A quelle fréquence les engourdissements et picotements dans votre main vous ont-ils réveillé dans une nuit ordinaire au cours des dernières 2 semaines ? ",
  "patient_hand_questionnaire_symptom_q10_a1":"Normal",
  "patient_hand_questionnaire_symptom_q10_a2":"Une fois",
  "patient_hand_questionnaire_symptom_q10_a3":"2-3 fois",
  "patient_hand_questionnaire_symptom_q10_a4":"4-5 fois",
  "patient_hand_questionnaire_symptom_q10_a5":"Plus de 5 fois",
  "patient_hand_questionnaire_function":"Signes fonctionnels",
  "patient_hand_questionnaire_function_instructions":"Evaluez le niveau de difficulté ressenti pour effectuer les activités suivantes à cause de vos symptômes : ",
  "patient_hand_questionnaire_function_instructions_0":"Pas de difficulté",
  "patient_hand_questionnaire_function_instructions_1":"Un peu de difficulté",
  "patient_hand_questionnaire_function_instructions_2":"Difficulté modérée",
  "patient_hand_questionnaire_function_instructions_3":"Difficulté intense",
  "patient_hand_questionnaire_function_instructions_4":"Ne peut pas effectuer du tout",
  "patient_hand_questionnaire_function_q1":"Difficulté pour attraper et utiliser de petits objets comme des clés ou stylos ",
  "patient_hand_questionnaire_function_q1_a1":"Pas de difficulté",
  "patient_hand_questionnaire_function_q1_a2":"Un peu de difficulté",
  "patient_hand_questionnaire_function_q1_a3":"Difficulté modérée",
  "patient_hand_questionnaire_function_q1_a4":"Difficulté intense",
  "patient_hand_questionnaire_function_q1_a5":"Ne peut pas effectuer du tout",
  "patient_hand_questionnaire_function_q2":"Ecrire",
  "patient_hand_questionnaire_function_q2_a1":"Pas de difficulté",
  "patient_hand_questionnaire_function_q2_a2":"Un peu de difficulté",
  "patient_hand_questionnaire_function_q2_a3":"Difficulté modérée",
  "patient_hand_questionnaire_function_q2_a4":"Difficulté intense",
  "patient_hand_questionnaire_function_q2_a5":"Ne peut pas effectuer du tout",
  "patient_hand_questionnaire_function_q3":"Boutonner des vêtements ",
  "patient_hand_questionnaire_function_q3_a1":"Pas de difficulté",
  "patient_hand_questionnaire_function_q3_a2":"Un peu de difficulté",
  "patient_hand_questionnaire_function_q3_a3":"Difficulté modérée",
  "patient_hand_questionnaire_function_q3_a4":"Difficulté intense",
  "patient_hand_questionnaire_function_q3_a5":"Ne peut pas effectuer du tout",
  "patient_hand_questionnaire_function_q4":"Tenir un livre en lisant",
  "patient_hand_questionnaire_function_q4_a1":"Pas de difficulté",
  "patient_hand_questionnaire_function_q4_a2":"Un peu de difficulté",
  "patient_hand_questionnaire_function_q4_a3":"Difficulté modérée",
  "patient_hand_questionnaire_function_q4_a4":"Difficulté intense",
  "patient_hand_questionnaire_function_q4_a5":"Ne peut pas effectuer du tout",
  "patient_hand_questionnaire_function_q5":"Tenir la anse d'une téléphone",
  "patient_hand_questionnaire_function_q5_a1":"Pas de difficulté",
  "patient_hand_questionnaire_function_q5_a2":"Un peu de difficulté",
  "patient_hand_questionnaire_function_q5_a3":"Difficulté modérée",
  "patient_hand_questionnaire_function_q5_a4":"Difficulté intense",
  "patient_hand_questionnaire_function_q5_a5":"Ne peut pas effectuer du tout",
  "patient_hand_questionnaire_function_q6":"Ouvrir des couvercles ",
  "patient_hand_questionnaire_function_q6_a1":"Pas de difficulté",
  "patient_hand_questionnaire_function_q6_a2":"Un peu de difficulté",
  "patient_hand_questionnaire_function_q6_a3":"Difficulté modérée",
  "patient_hand_questionnaire_function_q6_a4":"Difficulté intense",
  "patient_hand_questionnaire_function_q6_a5":"Ne peut pas effectuer du tout",
  "patient_hand_questionnaire_function_q7":"Tâches ménagères",
  "patient_hand_questionnaire_function_q7_a1":"Pas de difficulté",
  "patient_hand_questionnaire_function_q7_a2":"Un peu de difficulté",
  "patient_hand_questionnaire_function_q7_a3":"Difficulté modérée",
  "patient_hand_questionnaire_function_q7_a4":"Difficulté intense",
  "patient_hand_questionnaire_function_q7_a5":"Ne peut pas effectuer du tout",
  "patient_hand_questionnaire_function_q8":"Porter un panier de courses ",
  "patient_hand_questionnaire_function_q8_a1":"Pas de difficulté",
  "patient_hand_questionnaire_function_q8_a2":"Un peu de difficulté",
  "patient_hand_questionnaire_function_q8_a3":"Difficulté modérée",
  "patient_hand_questionnaire_function_q8_a4":"Difficulté intense",
  "patient_hand_questionnaire_function_q8_a5":"Ne peut pas effectuer du tout",
  "patient_hand_questionnaire_function_q9":"Se laver et s'habiller ",
  "patient_hand_questionnaire_function_q9_a1":"Pas de difficulté",
  "patient_hand_questionnaire_function_q9_a2":"Un peu de difficulté",
  "patient_hand_questionnaire_function_q9_a3":"Difficulté modérée",
  "patient_hand_questionnaire_function_q9_a4":"Difficulté intense",
  "patient_hand_questionnaire_function_q9_a5":"Ne peut pas effectuer du tout",
  "patient_hand_uram":"Echelle URAM Main et Poignet",
  "patient_hand_uram_canyou":"Pouvez-vous...",
  "patient_hand_uram_q1":"Vous laver avec un gant de toilette, en gardant la main à plat ",
  "patient_hand_uram_q1_a1":"Sans difficulté",
  "patient_hand_uram_q1_a2":"Avec très peu de difficultés",
  "patient_hand_uram_q1_a3":"Avec quelques difficultés",
  "patient_hand_uram_q1_a4":"Avec beaucoup de difficultés",
  "patient_hand_uram_q1_a5":"Presque impossible",
  "patient_hand_uram_q1_a6":"Impossible",
  "patient_hand_uram_q2":"Vous laver le visage ",
  "patient_hand_uram_q2_a1":"Sans difficulté",
  "patient_hand_uram_q2_a2":"Avec très peu de difficultés",
  "patient_hand_uram_q2_a3":"Avec quelques difficultés",
  "patient_hand_uram_q2_a4":"Avec beaucoup de difficultés",
  "patient_hand_uram_q2_a5":"Presque impossible",
  "patient_hand_uram_q2_a6":"Impossible",
  "patient_hand_uram_q3":"Tenir une bouteille dans une seule main ",
  "patient_hand_uram_q3_a1":"Sans difficulté",
  "patient_hand_uram_q3_a2":"Avec très peu de difficultés",
  "patient_hand_uram_q3_a3":"Avec quelques difficultés",
  "patient_hand_uram_q3_a4":"Avec beaucoup de difficultés",
  "patient_hand_uram_q3_a5":"Presque impossible",
  "patient_hand_uram_q3_a6":"Impossible",
  "patient_hand_uram_q4":"Serrer la main de quelqu'un ",
  "patient_hand_uram_q4_a1":"Sans difficulté",
  "patient_hand_uram_q4_a2":"Avec très peu de difficultés",
  "patient_hand_uram_q4_a3":"Avec quelques difficultés",
  "patient_hand_uram_q4_a4":"Avec beaucoup de difficultés",
  "patient_hand_uram_q4_a5":"Presque impossible",
  "patient_hand_uram_q4_a6":"Impossible",
  "patient_hand_uram_q5":"Caresser quelques choses ou quelqu'un ",
  "patient_hand_uram_q5_a1":"Sans difficulté",
  "patient_hand_uram_q5_a2":"Avec très peu de difficultés",
  "patient_hand_uram_q5_a3":"Avec quelques difficultés",
  "patient_hand_uram_q5_a4":"Avec beaucoup de difficultés",
  "patient_hand_uram_q5_a5":"Presque impossible",
  "patient_hand_uram_q5_a6":"Impossible",
  "patient_hand_uram_q6":"Applaudir ",
  "patient_hand_uram_q6_a1":"Sans difficulté",
  "patient_hand_uram_q6_a2":"Avec très peu de difficultés",
  "patient_hand_uram_q6_a3":"Avec quelques difficultés",
  "patient_hand_uram_q6_a4":"Avec beaucoup de difficultés",
  "patient_hand_uram_q6_a5":"Presque impossible",
  "patient_hand_uram_q6_a6":"Impossible",
  "patient_hand_uram_q7":"Ecarter vos doigts",
  "patient_hand_uram_q7_a1":"Sans difficulté",
  "patient_hand_uram_q7_a2":"Avec très peu de difficultés",
  "patient_hand_uram_q7_a3":"Avec quelques difficultés",
  "patient_hand_uram_q7_a4":"Avec beaucoup de difficultés",
  "patient_hand_uram_q7_a5":"Presque impossible",
  "patient_hand_uram_q7_a6":"Impossible",
  "patient_hand_uram_q8":"S'appuyer sur votre main ",
  "patient_hand_uram_q8_a1":"Sans difficulté",
  "patient_hand_uram_q8_a2":"Avec très peu de difficultés",
  "patient_hand_uram_q8_a3":"Avec quelques difficultés",
  "patient_hand_uram_q8_a4":"Avec beaucoup de difficultés",
  "patient_hand_uram_q8_a5":"Presque impossible",
  "patient_hand_uram_q8_a6":"Impossible",
  "patient_hand_uram_q9":"Attraper de petits objets avec votre pouce et index ",
  "patient_hand_uram_q9_a1":"Sans difficulté",
  "patient_hand_uram_q9_a2":"Avec très peu de difficultés",
  "patient_hand_uram_q9_a3":"Avec quelques difficultés",
  "patient_hand_uram_q9_a4":"Avec beaucoup de difficultés",
  "patient_hand_uram_q9_a5":"Presque impossible",
  "patient_hand_uram_q9_a6":"Impossible",
  "patient_hand_quality_of_life":"Qualité de vie",
  "patient_hand_quality_of_life_instruction":"Veuillez évaluer les propositions suivantes pour décrire au mieux votre niveau de SANTÉ AUJOURD'HUI :  ",
  "patient_hand_quality_of_life_instruction_0":"Pas de problème",
  "patient_hand_quality_of_life_instruction_1":"Léger",
  "patient_hand_quality_of_life_instruction_2":"Modéré",
  "patient_hand_quality_of_life_instruction_3":"Sévère",
  "patient_hand_quality_of_life_instruction_4":"Extrême/incapable",
  "patient_hand_quality_of_life_q1":"Problème pour se promener ",
  "patient_hand_quality_of_life_q1_a1":"Pas de problème",
  "patient_hand_quality_of_life_q1_a2":"Léger",
  "patient_hand_quality_of_life_q1_a3":"Modéré",
  "patient_hand_quality_of_life_q1_a4":"Sévère",
  "patient_hand_quality_of_life_q1_a5":"Incapable",
  "patient_hand_quality_of_life_q2":"Problème pour se laver ou s'habiller",
  "patient_hand_quality_of_life_q2_a1":"Pas de problème",
  "patient_hand_quality_of_life_q2_a2":"Léger",
  "patient_hand_quality_of_life_q2_a3":"Modéré",
  "patient_hand_quality_of_life_q2_a4":"Sévère",
  "patient_hand_quality_of_life_q2_a5":"Incapable",
  "patient_hand_quality_of_life_q3":"Problème pour réaliser des activités classiques (ex travailler, étudier, tâches ménagères, ou loisirs) ",
  "patient_hand_quality_of_life_q3_a1":"Pas de problème",
  "patient_hand_quality_of_life_q3_a2":"Léger",
  "patient_hand_quality_of_life_q3_a3":"Modéré",
  "patient_hand_quality_of_life_q3_a4":"Sévère",
  "patient_hand_quality_of_life_q3_a5":"Incapable",
  "patient_hand_quality_of_life_q4":"Douleur ou inconfort ",
  "patient_hand_quality_of_life_q4_a1":"Aucun(e)",
  "patient_hand_quality_of_life_q4_a2":"Léger(e)",
  "patient_hand_quality_of_life_q4_a3":"Modéré(e)",
  "patient_hand_quality_of_life_q4_a4":"Sévère",
  "patient_hand_quality_of_life_q4_a5":"Extrême",
  "patient_hand_quality_of_life_q5":"Anxiété/dépression",
  "patient_hand_quality_of_life_q5_a1":"Pas anxieux(se)/déprimé(e)",
  "patient_hand_quality_of_life_q5_a2":"Légère",
  "patient_hand_quality_of_life_q5_a3":"Modérée",
  "patient_hand_quality_of_life_q5_a4":"Très anxieux(se)/déprimé(e)",
  "patient_hand_quality_of_life_q5_a5":"Extrêmement anxieux(se)/déprimé(e)",
  "patient_hand_quality_of_life_q6":"Evaluez votre niveau de santé AUJOURD'HUI ? (100 signifie meilleure santé, 0 signifie pire santé que vous puissiez imaginer) ",
  "patient_hand_quality_of_life_q6_a1":"Pire",
  "patient_hand_quality_of_life_q6_a2":"Meilleure",
  "patient_hand_satisfaction":"Satisfaction",
  "patient_hand_satisfaction_instrucion":"Veuillez indiquer votre niveau de satisfaction avec les propositions suivantes",
  "patient_hand_satisfaction_instrucion_0":"Très insatisfait(e)",
  "patient_hand_satisfaction_instrucion_1":"Insatisfait(e)",
  "patient_hand_satisfaction_instrucion_2":"Ni satisfait(e) ni insatisfait(e)",
  "patient_hand_satisfaction_instrucion_3":"Légèrement satisfait(e)",
  "patient_hand_satisfaction_instrucion_4":"Très satisfait(e)",
  "patient_hand_satisfaction_q1":"Mouvement de vos DOIGTS",
  "patient_hand_satisfaction_q1_na":"Non applicable",
  "patient_hand_satisfaction_q1_a1":"Très insatisfait(e)",
  "patient_hand_satisfaction_q1_a2":"Insatisfait(e)",
  "patient_hand_satisfaction_q1_a3":"Ni satisfait(e) ni insatisfait(e)",
  "patient_hand_satisfaction_q1_a4":"Légèrement satisfait(e)",
  "patient_hand_satisfaction_q1_a5":"Très satisfait(e)",
  "patient_hand_satisfaction_q2":"Mouvement de votre POIGNET",
  "patient_hand_satisfaction_q2_na":"Non applicable",
  "patient_hand_satisfaction_q2_a1":"Très insatisfait(e)",
  "patient_hand_satisfaction_q2_a2":"Insatisfait(e)",
  "patient_hand_satisfaction_q2_a3":"Ni satisfait(e) ni insatisfait(e)",
  "patient_hand_satisfaction_q2_a4":"Légèrement satisfait(e)",
  "patient_hand_satisfaction_q2_a5":"Très satisfait(e)",
  "patient_hand_satisfaction_q3":"L'aspect de votre MAIN",
  "patient_hand_satisfaction_q3_na":"Non applicable",
  "patient_hand_satisfaction_q3_a1":"Très insatisfait(e)",
  "patient_hand_satisfaction_q3_a2":"Insatisfait(e)",
  "patient_hand_satisfaction_q3_a3":"Ni satisfait(e) ni insatisfait(e)",
  "patient_hand_satisfaction_q3_a4":"Légèrement satisfait(e)",
  "patient_hand_satisfaction_q3_a5":"Très satisfait(e)",
  "patient_hand_satisfaction_q4":"Le traitement de votre main/poignet ",
  "patient_hand_satisfaction_q4_na":"Non applicable",
  "patient_hand_satisfaction_q4_a1":"Très insatisfait(e)",
  "patient_hand_satisfaction_q4_a2":"Insatisfait(e)",
  "patient_hand_satisfaction_q4_a3":"Ni satisfait(e) ni insatisfait(e)",
  "patient_hand_satisfaction_q4_a4":"Légèrement satisfait(e)",
  "patient_hand_satisfaction_q4_a5":"Très satisfait(e)",
  "patient_hand_complete":"Fin du Questionnaire",
  "patient_hand_complete_text1":"Merci d'avoir pris le temps de remplir ce formulaire. ",
  "patient_hand_complete_text2":"Veuillez cliquer sur Envoyer pour terminer et visualiser vos scores. ",
  "patient_hand_complete_text3":"Vos réponses seront traitées de façon confidentielle et tous rapports utiliseront seulement des informations anonymes. ",
  "patient_hand_result":"Résultats",
  "patient_hand_result_text1":"Vos scores de douleur et de résultats fonctionnels de la main et du poignet calculés à partir de votre questionnaire sont représentés ci-dessous. ",
  "patient_hand_result_function":"Signes fonctionnels",
  "patient_hand_result_pain":"Douleur",
  "patient_hand_result_health_perception":"Perception de santé générale",
  "patient_hand_result_self_evaluation":"Score d'auto-évaluation de la Main et du Poignet",
  "patient_hand_result_text2":"Votre Praticien sera avec vous d'ici peu. ",
  "patient_general_history":"HISTORIQUE DE L'AFFECTION/BLESSURE/MALADIE ACTUELLE",
  "patient_general_history_q1":"Affection principale (expliquez votre affection avec vos propres mots) ",
  "patient_general_history_q2":"Date à laquelle le problème a commencé",
  "patient_general_history_q3":"Avez-vous fait quelconque imagerie ? ",
  "patient_general_history_q3_a1":"Aucune",
  "patient_general_history_q3_a2":"Radiographie",
  "patient_general_history_q3_a3":"Echographie",
  "patient_general_history_q3_a4":"IRM",
  "patient_general_history_q3_a5":"Arthrographie IRM (IRM avec du produit de contraste dans l'articulation)",
  "patient_general_history_q3_a6":"Scanner CT",
  "patient_general_history_q3_a7":"Arthrographie CT (CT avec du produit de contrast dans l'articulation)",
  "patient_general_history_q4":"Avez-vous fait des tests supplémentaires en relation avec votre pathologie, qui ne sont pas listés ci-dessus ? ",
  "patient_general_history_q4_placeholder":"Autres examens",
  "patient_general_past_history":"ANTECEDENTS MEDICAUX / PROBLEMES MEDICAUX ACTUELS",
  "patient_general_past_history_q1":"Avez-vous des allergies ? (médicamenteuses ou alimentaires)",
  "patient_general_past_history_q1_a1":"Non",
  "patient_general_past_history_q1_a2":"Oui",
  "patient_general_past_history_placeholder":"Indiquez l'allergie et le type de réaction",
  "patient_general_past_history_q2":"Etes-vous allergique au latex ? ",
  "patient_general_past_history_q2_a1":"Non",
  "patient_general_past_history_q2_a2":"Oui",
  "patient_general_past_history_q3":"Avez-vous déjà été traité(e) ou êtes-vous actuellement traité(e) pour une tension artérielle élevée ? ",
  "patient_general_past_history_q3_a1":"Non",
  "patient_general_past_history_q3_a2":"Oui",
  "patient_general_past_history_q3_a2_placeholder":"Indiquez les médicaments pris pour la tension artérielle élevée",
  "patient_general_past_history_q4":"Avez-vous déjà été traité(e) ou êtes-vous actuellement traité(e) pour une maladie/pathologie cardiaque ? ",
  "patient_general_past_history_q4_a1":"Non",
  "patient_general_past_history_q4_a2":"Oui",
  "patient_general_past_history_q4_a2_o1":"Sélectionnez la pathologie",
  "patient_general_past_history_q4_a2_o2":"Angine de poitrine (douleur de poitrine)",
  "patient_general_past_history_q4_a2_o3":"Crise cardiaque",
  "patient_general_past_history_q4_a2_o4":"Insuffisance cardiaque (fluide dans les poumons)",
  "patient_general_past_history_q4_a2_o5":"Murmure ou problème de valve cardiaque",
  "patient_general_past_history_q4_a2_o6":"Palpitations/battements cardiaques irréguliers",
  "patient_general_past_history_q4_a2_o7":"Dispositif implanté (défébrillateur, pacemaker)",
  "patient_general_past_history_q4_a2_o8":"Chirurgie cardiaque",
  "patient_general_past_history_q4_a2_o9":"Chirurgie sur la carotide",
  "patient_general_past_history_q4_a2_o10":"Autre pathologie cardiaque",
  "patient_general_past_history_q4_a2_o8_q1_placeholder":"Précisez la chirurgie cardiaque",
  "patient_general_past_history_q4_a2_o10_q1_placeholder":"Précisez autres pathologies cardiaques",
  "patient_general_past_history_q5":"Etes-vous suivi(e) par un cardiologue ? ",
  "patient_general_past_history_q5_a1":"Non",
  "patient_general_past_history_q5_a2":"Oui",
  "patient_general_past_history_q5_a2_placeholder":"Nom et cabinet",
  "patient_general_past_history_q6":"Avez-vous des problèmes de circulation dans les jambes (maladie vasculaire périphérique) ? ",
  "patient_general_past_history_q6_a1":"Non",
  "patient_general_past_history_q6_a2":"Oui",
  "patient_general_past_history_q6_a2_placeholder":"Veuillez décrire",
  "patient_general_past_history_q7":"Avez-vous pris des anticoagulants au cours du mois dernier ? ",
  "patient_general_past_history_q7_a1":"Non",
  "patient_general_past_history_q7_a2":"Oui",
  "patient_general_past_history_q7_a2_q1":"Quel type ? ",
  "patient_general_past_history_q7_a2_q1_a1":"Asprine",
  "patient_general_past_history_q7_a2_q1_a2":"Clopidogrel/Plavix",
  "patient_general_past_history_q7_a2_q1_a3":"Rivaroxaban",
  "patient_general_past_history_q7_a2_q1_a4":"Eliquis",
  "patient_general_past_history_q7_a2_q1_a5":"Autres",
  "patient_general_past_history_q7_a2_q1_a5_placeholder":"Précisez les anticoagulants",
  "patient_general_past_history_q8":"Etes-vous sujet à problèmes avec vos poumons ou thorax ? ",
  "patient_general_past_history_q8_a1":"Non",
  "patient_general_past_history_q8_a2":"Oui",
  "patient_general_past_history_q8_a2_o1":"Toux avec des expectorations ? ",
  "patient_general_past_history_q8_a2_o2":"Avez-vous des difficultés pour respirer ? ",
  "patient_general_past_history_q8_a2_o3":"Autres ",
  "patient_general_past_history_q8_a2_o2_o1":"Asthme",
  "patient_general_past_history_q8_a2_o2_o2":"Tension artérielle élevée dans les poumons (hypertension pulmonaire) ",
  "patient_general_past_history_q8_a2_o2_o3":"Fibrose pulmonaire",
  "patient_general_past_history_q8_a2_o2_o4":"Sarcoïdose ",
  "patient_general_past_history_q8_a2_o2_o5":"BPCO ou emphysème ",
  "patient_general_past_history_q8_a2_o2_o6":"Fibrose kystique",
  "patient_general_past_history_q8_a2_o2_o7":"Tuberculose",
  "patient_general_past_history_q8_a2_o2_o8":"Autres",
  "patient_general_past_history_q8_a2_o3_placehold":"Précisez",
  "patient_general_past_history_q8_a2_o2_o8_placeholder":"Précisez",
  "patient_general_past_history_q9":"Avez-vous pris des stéroïdes (prednisone ou cortisone) au cours de l'année dernière ? ",
  "patient_general_past_history_q9_a1":"Non",
  "patient_general_past_history_q9_a2":"Oui",
  "patient_general_past_history_q10":"Utilisez-vous de l'oxygène à domicile ? ",
  "patient_general_past_history_q10_a1":"Non",
  "patient_general_past_history_q10_a2":"Oui",
  "patient_general_past_history_q11":"Etes-vous suivi par un spécialiste respiratoire ? ",
  "patient_general_past_history_q11_a1":"Non",
  "patient_general_past_history_q11_a2":"Oui",
  "patient_general_past_history_q11_a2_placeholder":"Nom et cabinet",
  "patient_general_past_history_q12":"Ronflez-vous de façon excessive ou faites-vous de l'apnée du sommeil ? ",
  "patient_general_past_history_q12_a1":"Non",
  "patient_general_past_history_q12_a2":"Oui",
  "patient_general_past_history_q12_a2_q1":"Votre apnée du sommeil est-elle traitée avec ventilation CPAP ou BiPaP ? ",
  "patient_general_past_history_q12_a2_q1_a1":"Non",
  "patient_general_past_history_q12_a2_q1_a2":"Oui",
  "patient_general_past_history_q13":"Avez-vous déjà eu des problèmes avec votre foie ? ",
  "patient_general_past_history_q13_a1":"Non",
  "patient_general_past_history_q13_a2":"Oui",
  "patient_general_past_history_q13_a2_o1":"Selectionné",
  "patient_general_past_history_q13_a2_o2":"Cirrhose",
  "patient_general_past_history_q13_a2_o3":"Hépatite A, B ou C",
  "patient_general_past_history_q13_a2_o4":"Jaunice",
  "patient_general_past_history_q13_a2_o5":"Autres",
  "patient_general_past_history_q13_a2_o5_placeholder":"Précisez",
  "patient_general_past_history_q14":"Avez-vous fréquemment des brûlures d'estomac, ulcers, ou hernie hiatale ? ",
  "patient_general_past_history_q14_a1":"Non",
  "patient_general_past_history_q14_a2":"Oui",
  "patient_general_past_history_q15":"Avez-vous déjà eu une maladie rénale ? ",
  "patient_general_past_history_q15_a1":"Non",
  "patient_general_past_history_q15_a2":"Oui",
  "patient_general_past_history_q15_a2_q1":"Etes-vous sous dialyse ? Péritonéale ? Hémodialyse ?",
  "patient_general_past_history_q15_a2_q1_a1":"Non",
  "patient_general_past_history_q15_a2_q1_a2":"Oui",
  "patient_general_past_history_q15_a2_placeholder":"Indiquez la maladie rénale",
  "patient_general_past_history_q15_a2_q1_a2_placeholder":"Indiquez le type et le programme de dialyse",
  "patient_general_past_history_q16":"Avez-vous du diabète ? ",
  "patient_general_past_history_q16_a1":"Non",
  "patient_general_past_history_q16_a2":"Oui",
  "patient_general_past_history_q16_a2_q1":"Comment gérer votre diabète ? Régime/médicamentation orale ou insuline ? ",
  "patient_general_past_history_q16_a2_q1_placeholder":"Précisez",
  "patient_general_past_history_q17":"Avez-vous des problèmes de thyroïde ? ",
  "patient_general_past_history_q17_a1":"Non",
  "patient_general_past_history_q17_a2":"Oui",
  "patient_general_past_history_q18":"Avez-vous déjà fait de l'épilepsie, eu des convulsions ? ",
  "patient_general_past_history_q18_a1":"Non",
  "patient_general_past_history_q18_a2":"Oui",
  "patient_general_past_history_q18_a2_q1":"Quand était votre dernier épisode de convulsions ? ",
  "patient_general_past_history_q18_a2_q1_placeholder":"Précisez",
  "patient_general_past_history_q19":"Avez-vous des problèmes nerveux ou musculaires ? ",
  "patient_general_past_history_q19_a1":"Non",
  "patient_general_past_history_q19_a2":"Oui",
  "patient_general_past_history_q19_a2_o1":"Convulsions ",
  "patient_general_past_history_q19_a2_o1_placeholder":"Quand ? ",
  "patient_general_past_history_q19_a2_o2":"AIT (Accident Ischémique Transitoire/mini AVC) ou AVC ",
  "patient_general_past_history_q19_a2_o2_placeholder":"Quand ?",
  "patient_general_past_history_q19_a2_o3":"Faiblesses dans le visage, jambe ou bras",
  "patient_general_past_history_q19_a2_o4":"Maladie neurologique (exemples : Sclérose en plaque, maladie de Charcot, maladie d'Alzheimer) ",
  "patient_general_past_history_q19_a2_o4_placeholder":"Précisez",
  "patient_general_past_history_q19_a2_o5":"Maladie musculaire (exemples : myasthénie, dystrophie musculaire) ",
  "patient_general_past_history_q19_a2_o5_placeholder":"Précisez",
  "patient_general_past_history_q19_a2_o6":"Problèmes d'audition, de vision ou de mémoire ",
  "patient_general_past_history_q19_a2_o7":"Douleurs chroniques",
  "patient_general_past_history_q19_a2_o8":"Autres",
  "patient_general_past_history_q19_a2_o8_placeholder":"Précisez",
  "patient_general_past_history_q20":"Avez-vous de l'arthrose ? ",
  "patient_general_past_history_q20_a1":"Non",
  "patient_general_past_history_q20_a2":"Oui",
  "patient_general_past_history_q20_a2_o1":"Ostéoarthrite ",
  "patient_general_past_history_q20_a2_o2":"Arthrite rhumatoïde",
  "patient_general_past_history_q20_a2_o3":"Autres",
  "patient_general_past_history_q20_a2_o3_placeholder":"Précisez",
  "patient_general_past_history_q21":"Avez-vous déjà été traité(e) pour une maladie psychiatrique ? ",
  "patient_general_past_history_q21_a1":"Non",
  "patient_general_past_history_q21_a2":"Oui",
  "patient_general_past_history_q21_a2_o1":"Anxiété sévère",
  "patient_general_past_history_q21_a2_o2":"Dépression",
  "patient_general_past_history_q21_a2_o3":"Autres",
  "patient_general_past_history_q21_a2_o3_placeholder":"Précisez",
  "patient_general_past_history_q22":"Avez-vous déjà été diagnostiqué(e) avec des troubles hémorragiques ? ",
  "patient_general_past_history_q22_a1":"Non",
  "patient_general_past_history_q22_a2":"Oui",
  "patient_general_past_history_q22_a2_o1":"Leucémie ou lymphome",
  "patient_general_past_history_q22_a2_o2":"Hémophilie",
  "patient_general_past_history_q22_a2_o3":"Caillots sanguins",
  "patient_general_past_history_q22_a2_o4":"Anémie",
  "patient_general_past_history_q22_a2_o5":"Drépanocytose",
  "patient_general_past_history_q22_a2_o6":"Autres",
  "patient_general_past_history_q22_a2_o6_placeholder":"Précisez",
  "patient_general_past_history_q23":"Etes-vous anémique ? ",
  "patient_general_past_history_q23_a1":"Non",
  "patient_general_past_history_q23_a2":"Oui",
  "patient_general_past_history_q24":"Avez-vous déjà eu un caillot sanguin dans vos jambes ou poumons ? ",
  "patient_general_past_history_q24_a1":"Non",
  "patient_general_past_history_q24_a2":"Oui",
  "patient_general_past_history_q25":"Avez-vous ou quelqu'un dans votre famille eu des problèmes sérieux avec : ",
  "patient_general_past_history_q25_a1":"Saignements de nez",
  "patient_general_past_history_q25_a1_o1":"Non",
  "patient_general_past_history_q25_a1_o2":"Oui",
  "patient_general_past_history_q25_a2":"Saignements avec des extractions dentaires",
  "patient_general_past_history_q25_a2_o1":"Non",
  "patient_general_past_history_q25_a2_o2":"Oui",
  "patient_general_past_history_q25_a3":"Saignements après chirurgie",
  "patient_general_past_history_q25_a3_o1":"Non",
  "patient_general_past_history_q25_a3_o2":"Oui",
  "patient_general_past_history_q26":"Avez-vous déjà été diagnostiqué d'un cancer ? ",
  "patient_general_past_history_q26_a1":"Non",
  "patient_general_past_history_q26_a2":"Oui",
  "patient_general_past_history_q26_a2_placeholder1":"Indiquez le type de cancer ",
  "patient_general_past_history_q26_a2_placeholder2":"Quand ? ",
  "patient_general_past_history_q27":"Avez-vous déjà reçu traitement par chiomiothérapie ou radiothérapie ? ",
  "patient_general_past_history_q27_a1":"Non",
  "patient_general_past_history_q27_a2":"Oui",
  "patient_general_past_history_q27_a2_placeholder1":"Nom du traitement",
  "patient_general_past_history_q27_a2_placeholder2":"Date du dernier traitement",
  "patient_general_past_history_q28":"Avez-vous ou quelqu'un dans votre famille eu des problèmes avec l'anesthésie ?",
  "patient_general_past_history_q28_a1":"Non",
  "patient_general_past_history_q28_a2":"Oui",
  "patient_general_past_history_q28_a2_q1":"Quels problèmes ? ",
  "patient_general_past_history_q28_a2_q1_placeholder":"Séléctionnez",
  "patient_general_past_history_q28_a2_q1_o1":"Nausées sévères ou vomissement après chirurgie",
  "patient_general_past_history_q28_a2_q1_o2":"Hyperthermie maligne (dans la famille de sang ou vous-même)",
  "patient_general_past_history_q28_a2_q1_o3":"Problèmes de respiration ou difficultés avec l'insertion du tube de respiration pour l'anesthésie",
  "patient_general_past_history_q28_a2_q1_o4":"Problèmes avec le placement de tube pour respirer",
  "patient_general_past_history_q28_a2_q1_o5":"Autres",
  "patient_general_past_history_q28_a2_q1_o5_placeholder":"Précisez",
  "patient_general_past_history_q29":"Avez-vous les propositions suivantes ?",
  "patient_general_past_history_q29_q1":"Dents fêlées ou instables, dentier, prothèses dentaires ",
  "patient_general_past_history_q29_q1_a1":"Non",
  "patient_general_past_history_q29_q1_a2":"Oui",
  "patient_general_past_history_q29_q2":"Problèmes pour bouger votre cou ",
  "patient_general_past_history_q29_q2_a1":"Non",
  "patient_general_past_history_q29_q2_a2":"Oui",
  "patient_general_past_history_q29_q3":"Problèmes pour ouvrir votre bouche",
  "patient_general_past_history_q29_q3_a1":"Non",
  "patient_general_past_history_q29_q3_a2":"Oui",
  "patient_general_past_history_q30":"Est-il possible que vous soyez enceinte ?",
  "patient_general_past_history_q30_a1":"Non",
  "patient_general_past_history_q30_a2":"Oui",
  "patient_general_past_history_q30_not_applicable":"Non applicable",
  "patient_general_past_history_q30_a2_q1":"Dernières règles ",
  "patient_general_past_history_q30_a2_q1_placeholder":"Dernières règles ",
  "patient_general_past_history_q31":"Antécédents médicaux : Veuillez lister tout autre(s) problème(s) médical(aux) qui n'a(ont) pas été indiqué(s) ci-dessus",
  "patient_general_past_history_q31_q1":"Listez tous les médicaments (médicaments en vente libre, inhalateurs, patchs, gouttes, vitamines, suppléments, herbes inclus)",
  "patient_general_past_history_q31_q2":"Indiquez le nom du médicament, la dose, la fréquence, et la raison pour la prise",
  "patient_general_past_history_q31_q2_placeholder":"Nom du médicament, dose, fréquence, raison pour la prise",
  "patient_general_past_history_q31_q3":"Dose",
  "patient_general_past_history_q31_q4":"Souvent",
  "patient_general_past_history_q31_q5":"Raison pour la prise ",
  "patient_general_past_history_q31_q6":"Antécédents chirurgicaux",
  "patient_general_past_history_q31_q6_placeholder":"Précisez la procédure et la date de procédure ",
  "patient_general_social_history":"HISTORIQUE SOCIAL",
  "patient_general_social_history_q1":"Vivez-vous seul(e) ?",
  "patient_general_social_history_q1_a1":"Non",
  "patient_general_social_history_q1_a2":"Oui",
  "patient_general_social_history_q1_a1_q1":"Avec qui vivez-vous ? ",
  "patient_general_social_history_q1_a1_q1_placeholder":"Avec qui vivez-vous ? ",
  "patient_general_social_history_q2":"Fumez-vous ?",
  "patient_general_social_history_q2_a1":"Non",
  "patient_general_social_history_q2_a2":"Oui",
  "patient_general_social_history_q2_a2_q1":"Combien de cigarettes par jour ? ",
  "patient_general_social_history_q2_a2_q1_placeholder":"Nombre de cigarettes ",
  "patient_general_social_history_q2_a2_q2":"Nombre d'années ? ",
  "patient_general_social_history_q2_a2_q2_placeholder":"Années",
  "patient_general_social_history_q3":"Avez-vous déjà fumé dans le passé ? ",
  "patient_general_social_history_q3_a1":"Non",
  "patient_general_social_history_q3_a2":"Oui",
  "patient_general_social_history_q3_a2_q1":"Quand avez-vous arrêté de fumer ?",
  "patient_general_social_history_q3_a2_q1_placeholder":"Quand avez-vous arrêté de fumer ?",
  "patient_general_social_history_q3_a2_q2_placeholder":"Années",
  "patient_general_social_history_q4":"Buvez-vous de l'alcool ?",
  "patient_general_social_history_q4_a1":"Non",
  "patient_general_social_history_q4_a2":"Oui",
  "patient_general_social_history_q4_a2_q1_placeholder":"Nombre de boissons alcoolisées standards par semaine",
  "patient_general_social_history_q5":"Consommez-vous ou avez-vous déjà consommé des drogues récréatives ou illicites, de la marijuana ou des opioïdes qui ne vous ont pas été prescrits ?",
  "patient_general_social_history_q5_a1":"Non",
  "patient_general_social_history_q5_a2":"Oui",
  "patient_general_social_history_q5_a2_placeholder":"Indiquez les drogues ",
  "patient_general_complete":"Fin du questionnaire ",
  "patient_general_complete_text1":"Merci d'avoir pris le temps de remplir ce fromulaire. ",
  "patient_general_complete_text2":"Veuillez cliquer sur Envoyer pour terminer et visualiser vos scores. ",
  "patient_general_complete_text3":"Vos réponses seront traitées de façon confidentielle et tous rapports utiliseront seulement des informations anonymes. ",
  "AppVersion":"Version de l'application",
  "Complete_personal_info":"Renseignez vos informations personnelles",
  "To_complete_the_sign_up":"Pour terminer l'inscription veuillez renseignez vos informations ci-dessous. ",
  "Select_Existing_Profile":"Sélectionnez un profil existant",
  "Get_In":"Saisir",
  "Create_new_patient":"Créer un nouveau patient",
  "myProfile":"Mon Profil",
  "First_Name":"Prénom*",
  "Last_Name":"Nom*",
  "DOB":"Date de naissance*",
  "Sex":"Sexe*",
  "Address":"Adresse*",
  "Postcode":"Code Postal*",
  "Suburb":"Ville*",
  "Country_Code":"Indicatif téléphonique",
  "Mobile_Phone":"Téléphone portable",
  "Home_Phone":"Téléphone fixe",
  "Email":"Email*",
  "Emergency_Contact":"Personne à contacter en cas d'urgence*",
  "Phone":"Téléphone*",
  "Relationship":"Relation ou lien de parenté*",
  "Regular_GP_Name":"Médecin Traitant*",
  "Practice_Name":"Cabinet*",
  "Medicare_Card_Number":"Numéro de Sécurité sociale ",
  "Ref":"Référence",
  "Message":"Messages",
  "Expiry":"Expiration",
  "Private_Health_Fund":"Mutuelle/Complémentaire santé",
  "Membership_Number":"Numéro d'adhésion/de membre",
  "Claim_Type":"Type de demande",
  "Work_Cover":"Assurance Maladie pour Accident du Travail/Maladie Professionnelle",
  "Employer":"Employeur",
  "Third_Party_Claim_Number":"Numéro de demande de tierce partie",
  "Third_Party_Name":"Nom de la tierce partie",
  "DVA_Concession_Card":"Carte de réduction d'Ancien Combattant",
  "Number":"Numéro",
  "Defence_Personnel":"Personnel de l'Armée",
  "I_agree":"J'accepte le traitement de mes données personnelles et ai lu la ",
  "Privacy_Policy":"Politique de Confidentialité",
  "visitClinic":"Prendre rendez-vous",
  "selectdocOrg":"Sélectionnez un Praticien & un Etablissement de santé*",
  "doctorOrganization":"Etablissement de santé*",
  "Select_Treatment_Category":"Sélectionnez une spécialité de traitement*",
  "Do_you_want_to_submit_patient_form_or_not":"Souhaitez-vous envoyer votre formulaire patient ? ",
  "areyousurewanttocontinueyourform":"Votre formulaire précédent est incomplet. Souhaitez-vous continuer ? ",
  "myVisit":"Anciennes visites",
  "View_score":"Voir le score",
  "upcoming_visits":"Prochainement",
  "No_data_found":"Aucune donnée trouvée",
  "Doctor_Name":"Nom du praticien",
  "Appointment_Date_Time":"Date et heure du rendez-vous",
  "Clinic_Name":"Nom du cabinet",
  "Clinic_Address":"Adresse du cabinet",
  "akmed":"Akunah Med",
  "Type":"Ecrire",
  "Date_Time":"Date & heure",
  "Action":"Action",
  "View":"Voir",
  "No_Messages":"Pas de message",
  "settings":"Paramètres",
  "Language":"Langue",
  "language":"Langue & région",
  "timezone":"Fuseau horaire",
  "Timeformat":"Format de l'heure",
  "List_of_my_profile":"Mes profils",
  "next_button":"Suivant",
  "back_button":"Retour",
  "Continue":"Continuer",
  "Close":"Fermer",
  "submit":"Envoyer",
  "Sign_out":"Retour au profil ",
  "Results":"Résultats",
  "update":"Formulaire mis à jour avec succès",
  "Personal_Data":"Données personnelles",
  "Date_of_Birth":"Date de naissance",
  "Hip_arthritis":"Arthrose de la hanche ?*",
  "Area_of_foot_ankle":"Partie du pied/de la cheville affecté(e) ?* ",
  "Diagnosis":"Diagnostic ?*",
  "Evaluated_Shoulder":"Epaule évaluée",
  "Dominant_Hand":"Préférence manuelle*",
  "Age":"Age",
  "No":"Non",
  "Yes":"Oui",
  "visitNo":"Pas de visite",
  "visitYes":"Visite",
  "shoulderPainR":"Douleur épaule droite",
  "Is_it_initial_visit":"Est-ce une consultation initiale avec",
  "for_the_problem":"pour ce problème ?*",
  "Diagnosis_Procedure":"Diagnostic/Procédure",
  "Procedure":"Procédure",
  "Evaluated_sdfs":"Côté Evalué*",
  "Please_Select_previous_visits":"Veuillez sélectionner les visites précédentes*",
  "Follow_Up":"Rendez-vous de suivi",
  "Assessment_Date":"Date de consultation",
  "Do_you_have_Dupuytren_disease":"Avez-vous la maladie de Dupuytren ?",
  "Do_you_have_carpal_tunnel_syndrome":"Avez-vous le syndrome du canal carpien ?",
  "Is_your_unaffected_shoulder_pain":"Est-ce que votre épaule non-affectée est sans douleur et vous permet une fonction normale ? ",
  "Is_your_unaffected_hip_pain":"Est-ce que votre hanche non-affectée est sans douleur et vous permet une fonction normale ? ",
  "Is_your_unaffected_knee_pain":"Est-ce que votre genou non-affecté est sans douleur et vous permet une fonction normale ? ",
  "Is_your_unaffected_elbow_pain":"Est-ce que votre coude non-affecté est sans douleur et vous permet une fonction normale ? ",
  "Is_your_unaffected_foot_and_ankle_pain":"Est-ce que votre pied et cheville non-affectés sont sans douleur et vous permettent une fonction normale ? ",
  "Is_your_unaffected_hand_pain":"Est-ce que votre main et poignet non-affectés sont sans douleur et vous permettent une fonction normale ? ",
  "Patient_Consent":"Formulaire d'Information et de Consentement Libre Préalable du Patient d'Akunah",
  "Patient_Last_Name":"Nom du patient",
  "Patient_First_Name":"Prénom du patient",
  "Patient_Date_of_Birth":"Date de naissance du patient",
  "information":"Informations",
  "I_am_informedDDD":"A des fins d’évaluation, de planification, et de l’étude des résultats de votre traitement orthopédique, votre médecin,",
  "Clinician":"(ci-après le \"Praticien\") utilise une évaluation, de la collecte de données en relation avec vos résultats, et une analyse, et/ou des services de plannification chirugicale et logiciels fournis par Akunah Medical Technology Pty Ltd et ses filiales (\"Akunah\"). ",
  "For_that":"A ces fins, le Praticien transférera vos données personnelles à Akunah, y compris les scans de tomodensitométrie (CT scan) de votre corps et autres données médicales.",
  "Akunah_softawre":"Le Logiciel et services d'Akunah peuvent impliquer l'étude d'images, l'évaluation de données médicales, la création de modèles 3D personnalisés de votre anatomie avec des mesures anatomiques clés, de la planification chirurgicale, et de l'assistance pour la chirurgie, qui seront partagés avec le Praticien.",
  "the_clinic":"Le Praticien utilisera l'évaluation et la planification chirurgicale pour votre traitement orthopédique. ",
  "oportunnity":"Vous avez aussi l’opportunité de fournir des données personnelles au travers du portail patient d’Akunah et d’accéder aux modèles et planifications d’Akunah.",
  "akunah_approch":"L'approche d’Akunah en matière de confidentialité et de sécurité",
  "akunah_approch1":"La confidentialité et la sécurité de vos données qu’Akunah collectera pour aider votre Praticien nous tient à cœur.",
  "akunah_approch2":"Akunah se conforme de façon volontaire avec les Lois Européennes Applicables en matière de Protection des Données, ce qui comprend le Règlement Général de l'UE sur la Protection des Données EU/2016/679, car ces lois sont les lois sur la protection de la vie privée les plus strictes adoptées dans le monde. Ces lois dépassent les lois sur la confidentialité promulguées par l'Australie, les États-Unis et d'autres pays.",
  "akunah_approch3":"Le « Code de Confidentialité pour le Traitement d’Informations Personnelles de Clients Particuliers » d’Akunah décrit comment Akunah se conforme avec les Lois Européennes Applicables en matière de Protection des Données (le",
  "akunah_approch4":"Code de Confidentialité",
  "akunah_approch5":"L’ « Accord de Traitement des Données » d’Akunah décrit les dispositions qu’Akunah a pris avec ses Praticiens pour protéger et sécuriser vos informations personnelles (l’",
  "akunah_approch6":"Accord de Traitement de Données",
  "akunah_approch7":"Ces politiques sont disponibles sur le site internet d’Akunah à",
  "terms_":"https://akunah.com/termsofservices.",
  "akunah_approch8":"En cas d'incohérence entre le Code de Confidentialité, l'Accord de Traitement des Données et ce document, les termes du premier document mentionné s'appliqueront à l'étendue de toute incohérence avec ce document.",
  "Data_Collection_and_Processing":"Collection et traitement des données",
  "I_am_informed":"Akunah peut collecter vos données, y compris les informations pour vous contacter, les informations d’authentification, d'identification, de profession, et d'anamnèse. Akunah peut aussi collecter des données médicales en rapport avec votre santé comme",
  "Assessment_and_evaluation":"• L'étude et l'évaluation de données à caractéristiques physiques et toutes autres informations en relation avec les traitements proposés et leurs résultats ; ",
  "Previous_surgery":"• Antécédents chirurgicaux, diagnostics, radiologies, scans, modèles anatomiques 3D précédents, précédentes mesures anatomiques ; et",
  "Health_and_medical_history":"• Antécédents médicaux et de santé, y compris vos symptômes, médicaments et diagnostics et traitements précédents qui vous ont été attribué(e)s.",
  "I_have_been_assured":"Akunah collecte toujours des informations d'une façon juste et légale que ce soit directement auprès du patient, partagés par un Praticien, ou au travers des produits Akunah. Akunah traitera ces données en accord avec votre consentement exprimé ci-après pour permettre la prestation d'évaluation et de service de planification chirurgicale au cas échéant.",
  "My_data":"De plus, Akunah peut utiliser vos données personnelles pour se conformer à toutes les législations et régulations applicables.",
  "your_data":"Vos données peuvent également être divulguées à des tierces parties si requis pour effectuer les services proposés, ou en vertu de la loi.",
  "Data_Access_and_Storage":"Accès, Stockage des Données et Sécurité",
  "Personal_data_provided":"Les données fournies par vous-même et votre Praticien seront seulement accessibles au personnel d’Akunah et aux tierces parties qui contribuent à votre traitement ou convalescence (ex anesthésiste, kinésithérapeute) et doivent avoir accès à vos données.",
  "data1":"Les données entrées dans le Logiciel d'Akunah sont hébergées en lieu sûr dans le Commonwealth d'Australie par un hébergeur approuvé. Ceci permet de fournir un niveau de confidentialité et de sécurité adapté à la nature des données traitées par le Logiciel d’Akunah.",
  "data2":"Si vous êtes situés en dehors du Commonwealth d'Australie et/ou que les données entrées dans le logiciel d’Akunah sont accédées par les filiales d'Akunah situées en dehors du Commonwealth d'Australie, y compris dans l'Union Européenne et aux États Unis, différentes normes de gestion de la vie privée peuvent être appliquées sur la façon dont vos données sont utilisées et protégées dans ces pays. Dans le cas échéant, vos données personnelles seront stockées dans ce pays.",
  "data3":"Akunah a des dispositifs de protection appropriés en place en accord avec les obligations légales pour s'assurer que les données sont maintenues confidentielles et protégées avec un niveau de protection adéquat avec toutes tierces parties. Nous exigeons quiconque ayant accès d'adopter les normes pour assurer le maintien d'un niveau de protection des données équivalent à celui adopté par Akunah.",
  "data4":"Votre Praticien partage la responsabilité de collection, d’accès, d’analyse et de partage de vos données en accord avec les exigences légales.",
  "p_data1":"Vos données personnelles seront conservées par Akunah pour une période de temps variable. La période de rétention est déterminée par les critères suivants: (i) aux fins pour lesquelles Akunah ou le Praticien utilise vos données personnelles (ils ne devront seulement conserver les données que le temps nécessaire à cette fin) ; et (ii) en application avec les lois et régulations qui peuvent fixer une période minimale pendant laquelle Akunah se doit de conserver vos données personnelles.",
  "Deidentified_Data":"Données Anonymes",
  "d_txt1":"Akunah peut anonymiser vos données, et ensuite utiliser ces données anonymes – généralement regroupées avec des données anonymes provenant d’autres patients - à titre documentaire, pour des activités de recherche et de développement, d'analyse de données, pour le développement et l'amélioration des logiciels/dispositifs pour les produits et services d'Akunah. Ceci permet l’amélioration continue des produits et services d’Akunah au bénéfice du patient comme vous-même.",
  "Patient_Rights":"Vos Droits",
  "I_understand_that":"Vous êtes libre de refuser de donner votre consentement pour fournir vos données personnelles à Akunah. Au cas échéant, veuillez en discuter avec votre Praticien, comme il se peut que votre Praticien puisse ne pas être en mesure de vous fournir des services ou des traitements médicaux sans utiliser les logiciels et les services d'Akunah.",
  "I_understand_that_1txt":"Vous avez le droit de révoquer votre consentement n'importe quand et par quelconque moyen, et, au cas échéant, vous avez le droit : (i) d’obtenir des informations en relation avec le traitement de vos données personnelles et d’accéder ces informations personnelles ; (ii) de demander leur rectification quand elles sont incorrectes ; (iii) de demander la restriction du traitement de vos données personnelles, y compris de vous opposer à certains types de traitements de vos données personnelles et de demander le retrait du consentement que vous avez fourni ; (iv) de demander l’effacement de vos données personnelles ; (v) de définir des consignes concernant le traitement de vos données après votre décès ; et (vi) d’obtenir une copie de vos données personnelles traitées dans les conditions susmentionnées dans un format structuré, couramment utilisé et lisible par machine. Vous pouvez exercer ces droits en adressant votre demande directement à votre Praticien. ",
  "I_understand_that_2txt":"Vos droits sont sujets aux exemptions et restrictions applicables par la loi.",
  "I_understand_that_3txt":"A la suite d’un retrait, il se peut que votre Praticien puisse ne pas être en mesure de vous fournir des services ou des traitements médicaux, si vous exercez ces droits avant l’intervention proposée.",
  "I_understand_that_4txt":"Vous êtes aussi libre de porter plainte contre le traitement de vos données personnelles auprès des autorités compétentes dans votre pays de résidence.",
  "I_understand_that_5txt":"Quant aux données anonymes, les données anonymes ne tombent pas sous les mêmes restrictions légales que les données personnelles. Veuillez noter que vous ne pouvez pas révoquer votre consentement pour l'accès et l'utilisation de vos données anonymes ou pour demander l’effacement de ces données, car Akunah ne sera pas capable de retrouver vos données. Après anonymisation, Akunah ne peut pas retracer les données jusqu'à vous.",
  "Contact":"Consentement",
  "I_am_able_to_contact":"J’ai lu et ai compris la section Informations ci-dessus, ou que quelqu’un me l’a lue dans une langue que je comprends.",
  "or_by_post":"J’ai eu l’opportunité de poser des questions et je suis satisfait(e) des réponses que j’ai obtenues. Je comprends que mes informations resteront confidentielles.",
  "or_by_post2":"Je donne la permission à mon médecin",
  "or_by_post2_1":"(le “Praticien”) de partager mes données personnelles avec Akunah.",
  "or_by_post3":"Je donne permission pour qu'Akunah collecte, stocke, utilise, et partage mes données personnelles et anonymes comme décrit dans la section Informations ci-dessus.",
  "or_by_post4":"Je comprends que je suis libre de révoquer mon consentement à n’importe quel moment.",
  "or_by_post5":"Je comprends que je vais recevoir une copie signée de ce document à conserver.",
  "or_by_post6":"Je peux contacter Akunah en relation avec le traitement de mes données par e-mail à",
  "info_akunah":"info@akunah.com",
  "or_by_post7":"ou par courrier postal à Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australia.",
  "Clear":"Effacer",
  "Date":"Date",
  "Signed_by_Mouse":"Signé avec la souris*",
  "Name_if_consent_on_behalf_of_patient":"Nom (si consentement au nom du patient)",
  "Relationship_to_patient_if_applicable":"Relation avec le patient au cas échéant",
  "Choose_Sign_Type":"Choisir le type de signature*",
  "Draw":"Dessiner",
  "Signed_by_Keyboard":"Signé avec le clavier*",
  "Agreement":"Accord",
  "agree_that_I_have_read":"Je reconnais avoir lu et compris the formulaire de consentement du patient et je consens au traitement de façon explicite au traitement de mes données personnelles pour les fins décrites ci-dessus. ",
  "NOTE":"NOTE",
  "If_the_patient":"Si le patient a moins de 18 ans, ceci doit être signé par leur parent ou responsable légal.",
  "If_the_patient_is_deceased":"Si le patient est décédé ceci doit être signé par leur plus proche parent.",
  "patient_signature":"Signature du patient",
  "profile_updated":"Profil mis à jour",
  "profile_not_updated":"Profil non mis à jour",
  "video":"Vidéo",
  "radiology_form":"Formulaire de radiologie",
  "doc_document":"Document",
  "pathology_form":"Formulaire de résultats des analyses de laboratoire",
  "text":"Message",
  "Document_Name":"Nom du document",
  "email_sent_successfully":"Demande de suppression envoyée avec succès",
  "messages":"Messages",
  "adult_knee":"Genou Adulte",
  "paediatric_knee":"Genou Pédiatrique",
  "document":"Mes Documents",
  "Check_in":"Enregistrement",
  "info_icon":"Remplissez le formulaire patient",
  "reminder":"Notes",
  "Search_with_minimum_three_characters":"Rechercher avec minimum trois caractères",
  "Select":"Séléctionnez",
  "delete_account":"Supprimer le compte",
  "DD_MM_YYYY":"JJ/MM/AAAA",
  "Female":"Féminin",
  "Male":"Masculin",
  "want_to_disclose":"Ne veut pas divulguer",
  "enter_address":"Entrez l'adresse",
  "logout":"Se déconnecter",
  "full_name":"Nom et prénom",
  "mmyy":"MM / AA",
  "health_found":"Assurance santé privée",
  "Not_Applicable":"Non applicable",
  "not_available":"Pas disponible",
  "available":"Disponible",
  "shared":"Partagés",
  "private":"Privés",
  "add_document":"Ajouter un document",
  "drap_and_drop":"Glisser et déposer des fichiers ici",
  "or_browser_files":"ou parcourir les fichiers dans votre ordinateur",
  "browse_files":"Parcourir les fichiers",
  "download":"Télécharger",
  "Oops_error":"Oups ! Ce que vous avez demandé n'a pas été trouvé",
  "go_to_home":"Retour à la page d'accueil",
  "This_field_is_required":"Ce champ est obligatoire",
  "PendingForm":"Formulaire en attente",
  "NoFollowUpRecords":"Pas de données de suivi",
  "Right":"Droite",
  "Both":"Droite et Gauche",
  "Left":"Gauche",
  "shoulder":"Epaule",
  "hip_form":"Hanche",
  "Foot_And_Ankle":"Pied et Cheville",
  "Hand_And_Wrist":"Main et Poignet",
  "General":"Général",
  "adolescent_knee":"Genou Adolescent",
  "Elbow":"Coude",
  "title":"Titre",
  "description":"Description",
  "enter_your_description_here":"Saisissez la description ici",
  "enter_your_title_here":"Saisissez le titre ici",
  "progress":"En attente",
  "done":"Terminé",
  "reset":"Rafraîchir",
  "send":"Envoyer",
  "Edit":"Modifier",
  "delete":"Supprimer",
  "Are_you_sure_want_to_done_the_remainder":"Etes-vous sûr(e) de vouloir terminer cette note ? ",
  "Confirm":"Confirmer ",
  "Update":"Mettre à jour",
  "success":"Succès",
  "error":"Erreur",
  "Are_you_sure_you_want_to_delete":"Etes-vous sûr(e) de vouloir supprimer ? ",
  "cancel":"Annuler",
  "Hour_Time":"Heure au format 24 heures",
  "Knee":"Genou",
  "no":"Non",
  "yes":"Oui",
  "Year":"0 an, 0 mois, 0 jour",
  "Bilateral":"Ambidextre",
  "Left_Arm":"Épaule Gauche",
  "Right_Arm":"Épaule Droite",
  "out_of":"sur",
  "Right_Elbow":"Coude Droit",
  "Left_Elbow":"Coude Gauche",
  "right_foot_ankle":"Pied/Cheville Droit(e)",
  "left_foot_ankle":"Pied/Cheville Gauche",
  "Right_hand_and_wrist":"Main et Poignet Droit",
  "Left_hand_and_wrist":"Main et Poignet Gauche",
  "Hip_Function_R":"Hanche Droite",
  "Hip_Function_L":"Hanche Gauche",
  "Right_Knee":"Genou Droit",
  "Left_Knee":"Genou Gauche",
  "Right_Pedi_Knee":"Genou Droit",
  "Left_Pedi_Knee":"Genou Gauche",
  "Scoring_Self_Evaluation":"Score d'auto-évaluation",
  "presciption_form":"Ordonnance",
  "insurance_form":"Assurance",
  "letter_form":"Lettre",
  "refferal_form":"Recommendation",
  "medical_form":"Médical",
  "are_you_sure_delete_account":"Etes-vous sûr(e) de vouloir supprimer votre compte ? ",
  "Adult_Knee_Pain":"Douleur du Genou Adulte",
  "Knee_pain_13_18_year":"Douleur du Genou",
  "day_pdata":"Jour",
  "days_pdata":"Jours ",
  "month_pdata":"Mois",
  "months_pdata":"Mois",
  "year_pdata":"An",
  "years_pdata":"Ans",
  "Name":"Nom",
  "akunah_id":"Akunah ID",
  "mobile":"Téléphone",
  "email":"Email",
  "upload_a_profile":"Charger une Image",
  "No_special_characters_are_allowed":"Les caractères spéciaux ne sont pas permis",
  "numbers_are_not_allowed":"Les chiffres ne sont pas permis",
  "maximum_50_character_are_allowed":"50 caractères maximum permis.",
  "invalid_character":"Caractère invalide",
  "fields_only_contain_10_character":"Le champs contient seulement 10 caractères",
  "maximum_10_Numbers_are_allowed":"10 chiffres maximum sont permis",
  "Only_I":"Seulement les chiffres sont permis",
  "invalid_home_phone_number":"Numéro de téléphone fixe invalide",
  "special_characters_are_not_allowed":"Les caractères spéciaux ne sont pas permis",
  "maximum_digit_are_allowed":"1 chiffre maximum est permis",
  "invalid_date":"Date invalide",
  "maximum_10_digits_are_allowed":"10 chiffres maximum sont permis",
  "switch_profile":"Changer de profil",
  "loading":"Chargement",
  "error_max_profile_limit":"Vous avez atteint le nombre maximum de comptes autorisés avec une seule adresse email",
  "error_upcoming_appointments":"Pas de rendez-vous à venir trouvés",
  "error_past_visits":"Pas de visites précédentes trouvées",
  "error_documents":"Pas de documents trouvés",
  "country_hawaii":"(UTC-10:00) Heure d'Hawaii",
  "country_tahiti":"(UTC-10:00) Tahiti",
  "country_pitcairn":"(UTC-08:00) Pitcairn",
  "country_niue":"(UTC-11:00) Niue",
  "country_mountain":"(UTC-07:00) Heure Normale des Rocheuses",
  "country_arizona":"(UTC-07:00) Heure Normale des Rocheuses - Arizona ",
  "country_whitehorse":"(UTC-08:00) Heure Normale du Pacifique - Whitehorse",
  "country_chihuahua":"(UTC-07:00) Heure Normale des Rocheuses - Chihuahua, Mazatlan ",
  "country_edmonton":"(UTC-07:00) Heure Normale des Rocheuses - Edmonton",
  "country_gambier":"(UTC-09:00) Gambier",
  "country_yellowknife":"(UTC-07:00) Heure Normale des Rocheuses - Yellowknife",
  "country_dawson":"(UTC-07:00) Heure Normale des Rocheuses - Dawson Creek",
  "country_belize":"(UTC-06:00) Belize",
  "country_hermosillo":"(UTC-07:00) Heure Normale des Rocheuses  - Hermosillo",
  "country_mexico":"(UTC-06:00) Heure Normale du Centre - Mexico City",
  "country_regina":"(UTC-06:00) Heure Normale du Centree - Regina",
  "country_tegucigalpa":"(UTC-06:00) Heure Normale du Centre - Tegucigalpa",
  "country_centraltime":"(UTC-06:00) Heure Normale du Centre",
  "country_rarotonga":"(UTC-10:00) Rarotonga",
  "country_pago":"(UTC-11:00) Pago Pago",
  "country_pacific":"(UTC-08:00) Heure du Pacifique",
  "country_alaska":"(UTC-09:00) Heure de l'Alaska",
  "country_vancouver":"(UTC-08:00) Heure du Pacifique - Vancouver",
  "country_tijuana":"(UTC-08:00) Heure du Pacifique - Tijuana",
  "country_salvador":"(UTC-06:00) Le Salvador",
  "country_costa":"(UTC-06:00) Costa Rica",
  "country_winnipeg":"(UTC-06:00) Heure Normale du Centre - Winnipeg",
  "country_galapagos":"(UTC-06:00) Iles Galapagos",
  "country_managua":"(UTC-06:00) Managua",
  "country_america_cancun":"(UTC-05:00) America Cancun",
  "country_guatemala":"(UTC-06:00) Guatemala",
  "country_bogota":"(UTC-05:00) Bogota",
  "country_easter_island":"(UTC-05:00) Ile de Pâques",
  "country_iqaluit":"(UTC-05:00) Heure Normale de l'Est - Iqaluit",
  "country_toronto":"(UTC-05:00) Heure Normale de l'Est - Toronto",
  "country_eastern_time":"(UTC-05:00) Heure Normale de l'Est",
  "country_havana":"(UTC-05:00) La Havane",
  "country_lima":"(UTC-05:00) Lima",
  "country_jamaica":"(UTC-05:00) Jamaique",
  "country_panama":"(UTC-05:00) Panama",
  "country_port_au_prince":"(UTC-05:00) Port-au-Prince",
  "country_nassau":"(UTC-05:00) Nassau",
  "country_rio_branco":"(UTC-05:00) Rio Branco",
  "country_barbados":"(UTC-04:00) La Barbade",
  "country_halifax":"(UTC-04:00) Heure Normale de l'Atlantique - Halifax",
  "country_bermuda":"(UTC-04:00) Les Bermudes",
  "country_boa_vista":"(UTC-04:00) Boa Vista",
  "country_curacao":"(UTC-04:00) Curacao",
  "country_marquesas":"(UTC-09:30) Marquesas",
  "country_caracas":"(UTC-04:00) Caracas",
  "country_grand_turk":"(UTC-04:00) Grand Turk",
  "country_la_paz":"(UTC-04:00) La Paz",
  "timezone_guyana":"(UTC-04:00) Guyane",
  "timezone_port_of_spain":"(UTC-04:00) Port d'Espagne",
  "timezone_manaus":"(UTC-04:00) Manaus",
  "timezone_martinique":"(UTC-04:00) Martinique",
  "timezone_porto_velho":"(UTC-04:00) Porto Velho",
  "timezone_santo_domingo":"(UTC-04:00) Saint Domingue",
  "timezone_puerto_rico":"(UTC-04:00) Porto Rico",
  "timezone_araguaina":"(UTC-03:00) Araguaina",
  "timezone_belem":"(UTC-03:00) Belem",
  "timezone_buenos_aires":"(UTC-03:00) Buenos Aires",
  "timezone_newfound_time":"(UTC-03:30) Heure Normale De Terre-Neuve - St. Johns",
  "timezone_asuncion":"(UTC-03:00) Asuncion",
  "timezone_campo":"(UTC-03:00) Campo Grande",
  "timezone_cayenne":"(UTC-03:00) Cayenne",
  "timezone_cuiaba":"(UTC-03:00) Cuiaba",
  "timezone_maceio":"(UTC-03:00) Maceio",
  "timezone_godthab":"(UTC-03:00) Godthab",
  "timezone_fortaleza":"(UTC-03:00) Fortaleza",
  "timezone_miquelon":"(UTC-03:00) Miquelon",
  "timezone_palmer":"(UTC-03:00) Palmer",
  "timezone_montevideo":"(UTC-03:00) Montevideo",
  "timezone_recife":"(UTC-03:00) Recife",
  "timezone_rothera":"(UTC-03:00) Rothera",
  "timezone_paramaribo":"(UTC-03:00) Paramaribo",
  "timezone_punta_arenas":"(UTC-03:00) Punta Arenas",
  "timezone_santig":"(UTC-03:00) Santiag",
  "timezone_salvador":"(UTC-03:00) Salvador",
  "timezone_stanley":"(UTC-03:00) Stanley",
  "timezone_south_georgia":"(UTC-02:00) South Georgia",
  "timezone_noronha":"(UTC-02:00) Noronha",
  "timezone_sao_pulo":"(UTC-02:00) Sao Paulo",
  "timezone_azores":"(UTC-01:00) Azores",
  "timezone_abidjan":"(UTC+00:00) Abidjan",
  "timezone_cape_verde":"(UTC-01:00) Cap-Vert",
  "timezone_scoresbysund":"(UTC-01:00) Scoresbysund",
  "timezone_bissau":"(UTC+00:00) Bissau",
  "timezone_accra":"(UTC+00:00) Accra",
  "timezone_danmarkshavn":"(UTC+00:00) Danmarkshavn",
  "timezone_casablanca":"(UTC+00:00) Casablanca",
  "timezone_dublin":"(UTC+00:00) Dublin",
  "timezone_canary_islands":"(UTC+00:00) les Iles Canary",
  "timezone_el_aaiun":"(UTC+00:00) El Aaiun",
  "timezone_thule":"(UTC-04:00) Thule",
  "timezone_gmt":"(UTC+00:00) Heure Moyenne de Greenwich (pas de changement horaire)",
  "timezone_lisbon":"(UTC+00:00) Lisbonne",
  "timezone_faeroe":"(UTC+00:00) Faeroe",
  "timezone_reykjavik":"(UTC+00:00) Reykjavik",
  "timezone_monrovia":"(UTC+00:00) Monrovia",
  "timezone_algiers":"(UTC+01:00) Alger",
  "timezone_london":"(UTC+00:00) Londres",
  "timezone_amsterdam":"(UTC+01:00) Amsterdam",
  "timezone_andorra":"(UTC+01:00) Andorre",
  "timezone_berlin":"(UTC+01:00) Berlin",
  "timezone_budapest":"(UTC+01:00) Budapest",
  "timezone_ceuta":"(UTC+01:00) Ceuta",
  "timezone_brussels":"(UTC+01:00) Bruxelles",
  "timezone_copenhagen":"(UTC+01:00) Copenhague",
  "timezone_gibrltar":"(UTC+01:00) Gibraltar",
  "timezone_prague":"(UTC+01:00) Heure Normal d'Europe Centrale - Prague",
  "timezone_lagos":"(UTC+01:00) Lagos",
  "timezone_luxembourg":"(UTC+01:00) Luxembourg",
  "timezone_madrid":"(UTC+01:00) Madrid",
  "timezone_malta":"(UTC+01:00) Malte",
  "timezone_ndjamena":"(UTC+01:00) Ndjamena",
  "timezone_paris":"(UTC+01:00) Paris",
  "timezone_oslo":"(UTC+01:00) Oslo",
  "timezone_monaco":"(UTC+01:00) Monaco",
  "timezone_tirane":"(UTC+01:00) Tirane",
  "timezone_rome":"(UTC+01:00) Rome",
  "timezone_vienna":"(UTC+01:00) Vienne",
  "timezone_tunis":"(UTC+01:00) Tunis",
  "timezone_warsaw":"(UTC+01:00) Varsovie",
  "timezone_amman":"(UTC+02:00) Amman",
  "timezone_zurich":"(UTC+01:00) Zurich",
  "timezone_athens":"(UTC+02:00) Athènes",
  "timezone_burcharest":"(UTC+02:00) Bucharest",
  "timezone_chisinau":"(UTC+02:00) Chisinau",
  "timezone_stockholm":"(UTC+01:00) Stockholm",
  "timezone_cairo":"(UTC+02:00) Le Caire",
  "timezone_gaza":"(UTC+02:00) Gaza",
  "timezone_jerusalem":"(UTC+02:00) Jerusalem",
  "timezone_johannesburg":"(UTC+02:00) Johannesburg",
  "timezone_helsinki":"(UTC+02:00) Helsinki",
  "timezone_khartoum":"(UTC+02:00) Khartoum",
  "timezone_belgrade":"(UTC+01:00) Heure Normal d'Europe Centrale - Belgrade",
  "timezone_damascus":"(UTC+02:00) Damascus",
  "timezone_maputo":"(UTC+02:00) Maputo",
  "timezone_kaliningrad":"(UTC+02:00) Moscou-01 - Kaliningrad",
  "timezone_riga":"(UTC+02:00) Riga",
  "timezone_kiev":"(UTC+02:00) Kiev",
  "timezone_nicosia":"(UTC+02:00) Nicosia",
  "timezone_tallinn":"(UTC+02:00) Tallinn",
  "timezone_tripoli":"(UTC+02:00) Tripoli",
  "timezone_sofia":"(UTC+02:00) Sofia",
  "timezone_vilnius":"(UTC+02:00) Vilnius",
  "timezone_istanbul":"(UTC+03:00) Istanbul",
  "timezone_baghdad":"(UTC+03:00) Baghdad",
  "timezone_minsk":"(UTC+03:00) Minsk",
  "timezone_windhoek":"(UTC+02:00) Windhoek",
  "timezone_nairobi":"(UTC+03:00) Nairobi",
  "timezone_moscow":"(UTC+03:00) Moscou+00 - Moscou",
  "timezone_qatar":"(UTC+03:00) Qatar",
  "timezone_beirut":"(UTC+02:00) Beyrouth",
  "timezone_riyadh":"(UTC+03:00) Riyadh",
  "timezone_syowa":"(UTC+03:00) Syowa",
  "timezone_tehran":"(UTC+03:30) Téhéran",
  "timezone_baku":"(UTC+04:00) Baku",
  "timezone_dubai":"(UTC+04:00) Dubai",
  "timezone_mahe":"(UTC+04:00) Mahe",
  "timezone_mauritius":"(UTC+04:00) l'Ile Maurice",
  "timezone_tbilisi":"(UTC+04:00) Tbilisi",
  "timezone_samara":"(UTC+04:00) Moscou+01 - Samara",
  "timezone_reunion":"(UTC+04:00) La Réunion",
  "timezone_yerevan":"(UTC+04:00) Yerevan",
  "timezone_kabul":"(UTC+04:30) Kaboul",
  "timezone_aqtau":"(UTC+05:00) Aqtau",
  "timezone_aqtobe":"(UTC+05:00) Aqtobe",
  "timezone_ashgabat":"(UTC+05:00) Ashgabat",
  "timezone_dushanbe":"(UTC+05:00) Dushanbe",
  "timezone_maldives":"(UTC+05:00) Les Maldives",
  "timezone_yekaterinburg":"(UTC+05:00) Moscou+02 - Yekaterinburg",
  "timezone_kerguelen":"(UTC+05:00) Kerguelen",
  "timezone_mawson":"(UTC+05:00) Mawson",
  "timezone_tashkent":"(UTC+05:00) Tashkent",
  "timezone_colombo":"(UTC+05:30) Colombo",
  "timezone_kathmandu":"(UTC+05:45) Kathmandu",
  "timezone_omsk":"(UTC+06:00) Moscou+03 - Omsk",
  "timezone_india_standard_time":"(UTC+05:30) Heure Normale de l'Inde",
  "timezone_chagos":"(UTC+06:00) Chagos",
  "timezone_dhaka":"(UTC+06:00) Dhaka",
  "timezone_bishkek":"(UTC+06:00) Bishkek",
  "timezone_cocos":"(UTC+06:30) Cocos",
  "timezone_thimphu":"(UTC+06:00) Thimphu",
  "timezone_almaty":"(UTC+06:00) Almaty",
  "timezone_vostok":"(UTC+06:00) Vostok",
  "timezone_rangoon":"(UTC+06:30) Rangoon",
  "timezone_christmas":"(UTC+07:00) Christmas",
  "timezone_davis":"(UTC+07:00) Davis",
  "timezone_karachi":"(UTC+05:00) Karachi",
  "timezone_hanoi":"(UTC+07:00) Hanoï",
  "timezone_beijing":"(UTC+08:00) heure de la Chine - Beijing (Pékin)",
  "timezone_hongkong":"(UTC+08:00) Hong Kong",
  "timezone_hovd":"(UTC+07:00) Hovd",
  "timezone_jakarta":"(UTC+07:00) Jakarta",
  "timezone_kuala_lumpur":"(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok":"(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk":"(UTC+07:00) Moscou+04 - Krasnoyarsk",
  "timezone_brunei":"(UTC+08:00) Brunei",
  "timezone_macau":"(UTC+08:00) Macao",
  "timezone_makassar":"(UTC+08:00) Makassar",
  "timezone_choibalsan":"(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar":"(UTC+08:00) Ulaanbaatar",
  "timezone_manila":"(UTC+08:00) Manille",
  "timezone_pyongyang":"(UTC+08:30) Pyongyang",
  "timezone_singapore":"(UTC+08:00) Singapour",
  "timezone_irkutsk":"(UTC+08:00) Moscou+05 - Irkutsk",
  "timezone_taipei":"(UTC+08:00) Taipei",
  "timezone_jayapura":"(UTC+09:00) Jayapura",
  "timezone_dili":"(UTC+09:00) Dili",
  "timezone_perth":"(UTC+11:00) Heure Normale de l'Australie Occidentale - Perth",
  "timezone_yakutsk":"(UTC+09:00) Moscou+06 - Yakutsk",
  "timezone_palau":"(UTC+09:00) Palau",
  "timezone_seoul":"(UTC+09:00) Séoul",
  "timezone_tokyo":"(UTC+09:00) Tokyo",
  "timezone_darwin":"(UTC+11:00) Heure Normale de l'Australie Centrale - Darwin",
  "timezone_dumont":"(UTC+10:00) Dumont D'Urville",
  "timezone_brisbane":"(UTC+11:00) Heure Normale de l'Australie Orientale - Brisbane",
  "timezone_guam":"(UTC+10:00) Guam",
  "timezone_vladivostok":"(UTC+10:00) Moscou+07 - Vladivostok",
  "timezone_port_moresby":"(UTC+10:00) Port Moresby",
  "timezone_truk":"(UTC+10:00) Truk",
  "timezone_adelaide":"(UTC+10:30) Heure Normale de l'Australie Centrale - Adelaide",
  "timezone_casey":"(UTC+11:00) Heure Normale de l'Australie Orientale - Hobart",
  "timezone_melbourne_sydney":"(UTC+11:00) Heure Normale de l'Australie Orientale - Melbourne, Sydney",
  "timezone_kosrae":"(UTC+11:00) Kosrae",
  "timezone_efate":"(UTC+11:00) Efate",
  "timezone_guadalcanal":"(UTC+11:00) Guadalcanal",
  "timezone_ponape":"(UTC+11:00) Ponape",
  "timezone_norfolk":"(UTC+11:00) Norfolk",
  "timezone_magadan":"(UTC+11:00) Moscou+08 - Magadan",
  "timezone_noumea":"(UTC+11:00) Nouméa",
  "timezone_majuro":"(UTC+12:00) Majuro",
  "timezone_funafuti":"(UTC+12:00) Funafuti",
  "timezone_nauru":"(UTC+12:00) Nauru",
  "timezone_tarawa":"(UTC+12:00) Tarawa",
  "timezone_kwajalein":"(UTC+12:00) Kwajalein",
  "timezone_enderbury":"(UTC+13:00) Enderbury",
  "timezone_wake":"(UTC+12:00) Wake",
  "timezone_fakaofo":"(UTC+13:00) Fakaofo",
  "timezone_wallis":"(UTC+12:00) Wallis",
  "timezone_fiji":"(UTC+13:00) Fiji",
  "timezone_apia":"(UTC+14:00) Apia",
  "timezone_kiritimati":"(UTC+14:00) Kiritimati",
  "timezone_tongatapu":"(UTC+13:00) Tongatapu",
  "timezone_auckland":"(UTC+13:00) Auckland",
  "dateFormet":"Format de la date",
  "Org_success":"Votre demande pour rejoindre l'Etablissement a été envoyée avec succès",
  "Org_err":"Vous avez déjà rejoint cet Etablissement",
  "Request_Organization":"Demander un Etablissement",
  "organization_requested":"Etablissement demandé",
  "join":"Rejoindre",
  "your_email":"email@adresse.com",
  "medical_summary":"Vous n'avez pas encore rempli votre résumé médical ",
  "medical_summary_msg":"Mon résumé médical",
  "medical_success":"Résumé médical envoyé avec succès",
  "patient_form_popup":"Souhaitez-vous continuer à remplir le questionnaire sur lequel vous travailliez il y a quelque peu ? ",
  "Please_confirm_that_your_details_are_correct":"Veuillez confirmer que vos coordonnées sont correctes",
  "Welcome_to_Akunah_your_Health_Passport":"Bienvenue à Akunah, votre Passeport Santé",
  "Your_helth_is_in_your_hands":"Votre santé est entre vos mains. ",
  "Lets_begin_your_journey":"Commençons votre parcours santé. ",
  "Please_complete_your_profile":"Veuillez compléter votre profil. ",
  "Profile_complete":"Profil complété ! ",
  "Would_you_like_to_add_another_profile_for_a_family_member":"Souhaitez-vous ajouter un autre profile pour un membre de votre famille ?",
  "Alert_detected":"Attention : Plusieurs logins détectés",
  "To_safeguard_your_data":"Pour protéger la confidentialité et la sécurité de vos données, la page web a expiré. ",
  "Kindly_close_all_other_Akunah":"Veuillez fermer tous les autres onglets Akunah et cliquer sur \"Retour à la page d'accueil\" pour continuer. ",
  "Akunah_Team_is_dedicated":"L'Équipe Akunah est dévouée à protéger vos données. ",
  "Last_Created":"Dernier créé",
  "First_Created":"Premier créé",
  "Alphabetically":"Par ordre alphabétique (A-Z) ",
  "Alphabetically1":"Par order alphabétique (Z-A) ",
  "Your_form_submission_was_successful_Clinician":"Votre formulaire a été envoyé avec succès. Nous allons maintenant automatiquement vous rediriger vers l'application Praticien. ",
  "Your_form_submission_was_successful_Patient":"Votre formulaire a été envoyé avec succès. Nous allons maintenant automatiquement vous rediriger vers l'application Patient. ",
  "If_this_doesnt_happen_within":"Si ceci ne se produit pas dans les 5 prochaines secondes, veuillez",
  "click_here":"cliquer ici ",
  "Save":"Sauvegarder",
  "Proceed_to_my_dashboard":"Continuer vers mon Tableau de Bord",
  "myJourney":"Mon Progrès ",
  "Home":"Accueil",
  "No_journey":"Pas de progrès trouvé",
  "Share_results_with_your_doctor":"Partager les résultats avec votre Praticien",
  "If_you_would_like_to_share_your_results_with":"Si vous souhaitez partager vos résultats avec votre doctor, cliquez sur Partagez MAINTENANT ci-dessous",
  "Link_PROMs":"Rattacher PROMs",
  "Welcome_to_akunah":"Bienvenue sur Akunah ",
  "Share_to_your_Doctor_using_one_of_the_3_options_below":"Partagez avec votre Praticien en utilisant l'une des 3 options ci-dessous",
  "Doctor_Code":"Code Praticien ",
  "Enter_the_code_that_has_been_provided_to_you_by_your":"Saisissez le code qui vous a été fourni par votre Praticien par email ou au cabinet. Si le code n'est pas disponible, veuillez vérifier vos visites récentes ou chercher votre Praticien",
  "Recent_Visits":"Visites récentes",
  "Select_your_recent_doctor_or":"Sélectionnez votre Praticien récent ou ",
  "organisation_here":"établissement ici",
  "Search":"Rechercher",
  "Search_for_your_doctor_or":"Rechercher votre Praticien ou ",
  "Please_confirm_that_the_following_details":"Veuillez confirmer que les informations suivantes sont correctes",
  "Organisation":"Etablissement",
  "PROMs_Specialty_Date":"Spécialités PROMs & Date",
  "Get_started_by_completing_your":"Commencez par remplir vos questionnaires PROMs ",
  "and_exploring_your_progress":"et visualisez votre progrès ",
  "Fill_up_your_PROMs_questionnaire":"Remplissez votre questionnaire PROMs",
  "Hip":"Hanche",
  "My_Progress":"Mon Progrès",
  "Completed":"Terminés",
  "Expired":"Expirés",
  "Incomplete":"Incomplets",
  "Specialty":"Spécialité",
  "Doctor":"Praticien",
  "Status":"Statut",
  "View_form":"Voir the formulaire",
  "Share_to_another_doctor":"Partager avec un autre Praticien",
  "Edit_form":"Mettre à jour le formulaire",
  "Answer_the_form":"Répondre au formulaire",
  "Complete_the_form":"Teminer le formulaire",
  "Delete_the_form":"Supprimer le formulaire",
  "Share_to_doctor":"Partager avec un Praticien",
  "Your_account_has_been_created":"Votre compte a été créé",
  "Please_click_below_to_proceed_to_your_dashboard":"Veuillez cliquer ci-dessous pour continuer vers votre Tableau de Bord",
  "If_you_would_like_to_add_another_profile_for_a_family":"Si vous souhaitez ajouter un autre profile pour un membre de votre famille veuillez",
  "Share":"Partager",
  "Hip_Scoring_Self_Evaluation_Questionnaire":"Questionnaire d'auto-évaluation de la Hanche",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire":"Questionnaire d'auto-évaluation de la Main & du Poignet",
  "Right_Hand_nd_Wrist":"Main & Poignet Droits",
  "Left_Hand_nd_Wrist":"Main & Poignet Gauches",
  "DOB_1":"Date de naissance",
  "Sex_1":"Sexe",
  "Address_1":"Adresse",
  "Please_Choose_PROMs_to_link":"Veuillez choisir PROMs à rattacher",
  "Please_select_atleast_one_form_or_close":"Veuillez sélectionner au moins un PROMs ou fermer ",
  "cant_find_appropriate_PROMs_to_link":"Vous ne trouvez pas les PROMs appropriés à rattacher ? ",
  "Request_New_PROMs":"Demander de Nouveaux PROMs",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit":"Attention : Questionnaire PROMs incomplet d'une visite récente détecté. ",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted":"Si vous sélectionnez \"Non\", un nouveau questionnaire sera créé et le formulaire incomplet sera supprimé. ",
  "Would_you_like_to_continue_this_form":"Souhaitez-vous continuer ? ",
  "Note":"Note : ",
  "Expiring_in":"Expiration dans ",
  "Date_Created":"Date de création",
  "Days":"Jours",
  "Shared":"Partagé",
  "Pending":"En attente",
  "Initial":"Initial ",
  "Recent":"Récent",
  "Pain":"Douleur",
  "Function":"Signes Fonctionnels",
  "Share_Now":"Partager Maintenant",
  "Share_Later":"Partager Plus Tard",
  "Updated":"Mis à jour",
  "Display_More":"Afficher plus ",
  "Address_Now":"Répondre Maintenant",
  "Address_Later":"Répondre Plus Tard",
  "You_have_pending_questionnaires":"Vous avez des questionnaires en attente",
  "Notifications":"Notification",
  "Form_shared_successfully":"Formulaire partagé avec succès",
  "You_have_already_shared_with_same_doctor_and_organization":"Vous avez déjà partagé avec le même Clinicien et Etablissement",
  "No_recent_notifications":"Pas de notifications récentes",
  "Have_a_good_day":"Bonne journée",
  "Patient_scores":"Scores patient",
  "Display less":"Afficher moins",
  "myJourney_Shoulder":"Epaule",
  "myJourney_Shoulder_txt":"Sélectionnez Epaule pour visualiser votre parcours de l'épaule",
  "myJourney_Elbow":"Coude",
  "myJourney_Elbow_txt":"Sélectionnez Coude pour visualiser votre parcours du coude",
  "myJourney_Hand_Wrist":"Main & Poignet",
  "myJourney_Hand_Wrist_txt":"Sélectionnez Main & Poignet pour visualiser votre parcours de la main & du poignet",
  "myJourney_Hip":"Hanche",
  "myJourney_Hip_txt":"Sélectionnez Hanche pour visualiser votre parcours de la hanche",
  "myJourney_knee":"Genou",
  "myJourney_knee_txt":"Sélectionnez Genou pour visualiser votre parcours du genou",
  "myJourney_Foot_Ankle":"Pied & Cheville",
  "myJourney_Foot_Ankle_txt":"Sélectionnez Pied & Cheville pour visualiser votre parcours du pied & de la cheville",
  "data_sort":"Trier",
  "Visit_Clinic":"Visiter un Cabinet",
  "My_Visits":"Mes Visites ",
  "Patient_Scoring_Forms":"Formulaires de Scores Patient",
  "Other_findings":"Autres observations",
  "Organization":"Etablissement",
  "more":"Plus",
  "mediacal_summary":"Antécédents Médicaux",
  "my_consent":"Mes Consentements",
  "Are_you_sure_you_want_to_reject":"Etes-vous sûr(e) de vouloir bloquer cette recommandation médicale ? ",
  "reject":"Rejetter",
  "i_accept_consent":"Je consens à ceci",
  "grant":"Donner permission",
  "no_conscent_found":"Pas de consentement trouvé",
  "rejected":"Rejeté",
  "No_Time_Lines":"Pas d'échéances",
  "Hide_Score":"Cacher le score",
  "Show_Score":"Montrer le score",
  "patient_sex":"Sexe",
  "Dominant_hand_1":"Préférence manuelle",
  "patient_Initial_Assessment":"Examination initiale",
  "patient_Treatment_Category":"Type de traitement",
  "View_all":"Voir tout",
  "Display_Less":"Afficher moins",
  "patient_general_past_Medical_history":"Antécédents médicaux",
  "patient_general_past_Medication_name":"Liste de médicaments ",
  "Evaluated_Knee":"Genou évalué",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function":"Est-ce que votre Main & Poignet non-affecté(e) est sans douleur et vous permet une fonction normale ?",
  "Evaluated_Hip":"Hanche évaluée",
  "Evaluated_Foot_Ankle":"Pied Cheville évalué(e)",
  "patient_Evaluated_Elbow":"Coude évalué",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function":"Est-ce que votre Pied Cheville non-affecté(e) est sans douleur et vous permet une fonction normale ?",
  "Evaluated_Hand_Wrist":"Main Poignet évalué(e)",
  "patient_shoulder_feeling_instability_q2":"Veuillez indiquer à quel point votre épaule paraît instable ",
  "patient_shoulder_feeling_instability_q3":"Veuillez indiquer à quel point votre épaule paraît instable ",
  "patient_Hand_BCTQ":"Main et Poignet BCTQ",
  "Paediatric_Knee_Satisfaction":"Satisfaction - Genou Pédiatrique",
  "patient_foot_ankle_symptoms":"Autres symptômes Pied & Cheville",
  "Right_Foot_and_Ankle":"Pied & Cheville Droit(e)",
  "Left_Foot_and_Ankle":"Pied & Cheville Gauche",
  "summary":"Synthèse",
  "Shoulder_Treatment_Form":"Formulaire de Traitement de l'Epaule",
  "Adolescent_knee_Treatment_Form":"Formulaire de Traitement du Genou Adolescent",
  "Adult_Knee_Treatment_Form":"Formulaire de Traitement du Genou Adulte",
  "Paediatric_Knee_Treatment_Form":"Formulaire de Traitement du Genou Pédiatrique",
  "Hip_Treatment_Form":"Formulaire de Traitement de la Hanche",
  "Foot_Ankle_Treatment_Form":"Formaulaire de Traitement du Pied et de la Cheville",
  "Elbow_Treatment_Form":"Formulaire de Traitement du Coude",
  "Hand_Wrist_Treatment_Form":"Formulaire de Traitment de la Main & du Poignet",
  "Upload_complete":"Chargment terminé",
  "Select_Date_Format":"Sélectionner un format de date",
  "timezone_casey1":"(UTC+11:00) Casey",
  "timezone_Kamchatskiy":"(UTC+12:00) Moscou+09 - Petropavlovsk-Kamchatskiy",
  "timezone_Guayaquil":"(UTC-05:00) Guayaquil",
  "requested":"a demandé un",
  "Display_name":"Afficher le nom",
  "Are_you_sure_you_want_to_delete_Consent":"Etes-vous certain de vouloir refuser ce consentement ? ",
  "form":"formulaire",
  "Requested_by":"Demandé par",
  "requested_hip_form":"a demandé un formulaire de la Hanche",
  "requested_shoulder_form":"a demandé un formulaire de l'Epaule",
  "requested_knee_form":"a demandé un formulaire du Genou",
  "requested_foot_form":"a demandé un formulaire du Pied et de la Cheville",
  "requested_hand_form":"a demandé un formulaire de la Main et du Poignet",
  "requested_elbow_form":"a demandé un formulaire du Coude",
  "requested_General_form":"a demandé un formulaire Général",
  "requested_Paediatric_form":"a demandé un formulaire du Genou Pédiatrique",
  "requested_Adolescent_form":"a demandé un formulaire du Genou Adolescent",
  "Expiring_in_Tomorrow":"Arrive à expiration demain",
  "PROMs_questionnaire":"Dr [nom du Docteur qui fait la demande] vous demande de remplir un formulaire PROMs du/de la [spécialité] ",
  "new_message":"Vous avez un nouveau message de [nom de l'envoyeur]",
  "new_appointment":"Vous avez un nouveau rendez-vous avec Dr [nom du Docteur] ",
  "upcoming_appointment":"Rappel: Rendez-vous imminent avec Dr [nom du Docteur] le [date]",
  "pending_message":"Rappel: Vous avez un formulaire PROMs du/de la [spécialité] en attente",
  "document_message":"Dr [nom du Docteur] a partagé un document avec vous ",
  "requested_message":"Dr [nom du Docteur] a demandé un consentement",
  "patient_added_message":"Vous avez été ajouté(e) comme patient(e) du Dr [nom du Docteur] à [établissement] ",
  "cancelled_appointment_message":"Votre rendez-vous avec Dr [nom] le [date du rendez-vous] a été annulé. ",
  "Mark_all_read":"Marquer comme lu",
  "Delete_All":"Tout supprimer",
  "No_Notification":"Pas de notification",
  "You_have_not":"Vous n'avez pas encore commencé votre parcours santé. Veuillez remplir un formulaire PROMs pour commencer votre parcours santé. ",
  "pass_verification1":"Le mot de passe doit contenir au moins 8 caractères*",
  "pass_verification2":"Le mot de passe doit contenir au moins une lettre majuscule*",
  "pass_verification3":"Le mot de passe doit contenir au moins une lettre minuscule*",
  "pass_verification4":"The mot de passe doit contenir au moins un chiffre*",
  "loginTitle":"Akunah:Authentication:: ",
  "Create_patient_mobile":"Create new profile",
  "Select_Profile_mobile":"Select your profile",
  "Profile_Created_mobile":"Profile Created",
  "Your_account_has_been_created_mobile":"Your account has been created.",
  "Please_tap_below_to_sign_in_to_your_workspace_mobile":"Please tap below to sign in to your workspace.",
  "Start_Journey_mobile":"Start Journey",
  "If_add_another_profile_mobile":"If you would like to add another profile for a family member, please",
  "Create_Profile_mobile":"Create Profile",
  "change_Profile_Pic_mobile":"Change profile photo",
  "MobileImp_mobile":"Mobile*",
  "Gender_mobile":"Gender",
  "Country_1_mobile":"Country",
  "Med_1_mobile":"Med",
  "Appoinments_mobile":"Appointments",
  "View_Profile_mobile":"View Profile",
  "Sign_out_mobile":"Sign out",
  "My_Appointments_mobile":"My Appointments",
  "Appoinments_Past_mobile":"Past",
  "No_appointments_mobile":"No appointments",
  "When_appointment_available_mobile":"When your next appointment is available, it will be displayed here",
  "Available_soon_mobile":"Available soon!",
  "please_akunah_desktop_mobile":"To access it now, please login tome.akunah.comon your desktop.",
  "All_Notes_mobile":"All Notes",
  "AddButton_mobile":"AddButton",
  "Start_your_note_mobile":"Start your note...",
  "Done_mobile":"Done",
  "Note_Title_mobile":"Note Title",
  "New_Consents_mobile":"New Consents",
  "Signed_Consents_mobile":"Signed Consents",
  "Consent_mobile":"Consent",
  "Signed_Type_mobile":"Signed by Type",
  "Submit_Consent_mobile":"Submit Consent",
  "Reject_Consent_mobile":"Reject Consent",
  "Type_name_mobile":"Type your name",
  "Signature_mobile":"Signature",
  "No_consents_mobile":"No consents",
  "Any_display_ready_mobile":"Any new or signed consents will display here when ready",
  "Clinician_Documents_mobile":"Clinician Documents",
  "Uploaded_Documents_mobile":"Uploaded Documents",
  "Upload_Document_mobile":"Upload Document",
  "Upload_file_mobile":"Upload file",
  "Upload_photo_mobile":"Upload photo",
  "Take_photo_mobile":"Take photo",
  "Uploading_1_mobile":"Uploading",
  "New_1_mobile":"New",
  "Share_document_mobile":"Share this document",
  "Enter_email_mobile":"Enter email",
  "Add_message_like_mobile":"Add a message, if you’d like.",
  "Are_delete_account_mobile":"Are you sure you want to delete your account?",
  "No_display_mobile":"No documents to display",
  "All_clinician_documents_mobile":"All clinician documents will display here",
  "New_Messages_mobile":"New Messages",
  "Read_Messages_mobile":"Read Messages",
  "Any_new_unread_messages_ready_mobile":"Any new or unread messages will be displayed here when ready",
  "Shoulder_Journey_mobile":"Shoulder Journey",
  "Hip_Journey_mobile":"Hip Journey",
  "Foot_Ankle_Journey_mobile":"Foot and Ankle Journey",
  "Elbow_Journey_mobile":"Elbow Journey",
  "Knee_Journey_mobile":"Knee Journey",
  "Hand_Wrists_Journey_mobile":"Hand and Wrist Journey",
  "New_Form_mobile":"New Form",
  "Start_new_form_mobile":"Start new form",
  "Requested_Forms_mobile":"Requested Forms",
  "Incomplete_Forms_mobile":"Incomplete Forms",
  "Completed_Forms_mobile":"Completed Forms",
  "No_Available_mobile":"No Forms Available",
  "Start_new_journey_mobile":"Start a new form to begin your journey.",
  "View_Results_mobile":"View Results",
  "Complete_Form_mobile":"Complete Form",
  "Delete_Form_mobile":"Delete Form",
  "Edit_Details_mobile":"Edit Details",
  "Personal_Details_mobile":"Personal Details",
  "DOB_1_mobile":"DOB",
  "Unknown_Gender_mobile":"Unknown Gender",
  "Mobile_mobile":"Mobile",
  "Next_kin’s_name_mobile":"Next of kin’s name",
  "Next_kin’s_relationship_mobile":"Next of kin’s relationship",
  "GP_Details_mobile":"GP Details",
  "GP_Name_mobile":"GP Name",
  "Practice_mobile":"Practice",
  "Edit_Profile_mobile":"Edit Profile",
  "Full_Name_mobile":"Full Name",
  "Country_Code_mobile":"Country Code",
  "GP_Full_Name_mobile":"GP Full Name",
  "Doctor’s_full_name_mobile":"Doctor’s full name",
  "All_mobile":"All",
  "No_notifications_mobile":"No recent notifications",
  "Any_new_available_mobile":"Any new or unread notifications will be displayed here when available",
  "Account_mobile":"Account",
  "Manage_My_Account_mobile":"Manage My Account",
  "Change_Password_mobile":"Change Password",
  "Privacy_mobile":"Privacy",
  "Time_mobile":"Time",
  "Date_Format_mobile":"Date & Format",
  "Support_mobile":"Support",
  "All_Notifications_mobile":"All Notifications",
  "Receive_notifications_mobile":"Receive all notifications",
  "Journey_Updates_mobile":"Journey Updates",
  "Receive_appointment_notifications_mobile":"Receive all appointment notifications",
  "Start_Form_mobile":"Start Form",
  "Your_journey_starts_here_mobile":"Your journey starts here",
  "To_recovery_questions_mobile":"To get started with your journey to recovery, we just ask you to please best answer the following questions.",
  "Please_estimate_questions_mobile":"Please estimate 12-15 minutes to answer these questions.",
  "Shoulder_Form_mobile":"Shoulder Form",
  "Save_and_Continue_mobile":"Save & Continue",
  "How_years_smoke_mobile":"How many years did you smoke?",
  "patient_shoulder_pain_inner_value_mobile":"(0 means no pain, 15 means intolerable pain)",
  "Scoring_Key_mobile":"Scoring Key",
  "patient_shoulder_wosi_1_mobile":"Western Ontario Shoulder Instability Index (WOSI)",
  "patient_shoulder_wosi_sectionA_phy_mobile":"PHYSICAL SYMPTOMS",
  "patient_shoulder_wosi_sectionB_sp_mobile":"SPORTS/RECREATION/WORK",
  "patient_shoulder_wosi_sectionC_life_mobile":"LIFESTYLE",
  "patient_shoulder_wosi_sectionD_emo_mobile":"EMOTIONS",
  "Specific_AC_Score_mobile":"Specific AC Score (SACS)",
  "patient_shoulder_sacs_sectionA_pain_mobile":"Pain",
  "patient_shoulder_sacs_sectionB_function_mobile":"FUNCTION",
  "patient_shoulder_sacs_sectionC_Qua_mobile":"Quality of life and satisfaction",
  "Finish_mobile":"Finish",
  "Share_Results_mobile":"Share Results",
  "Share_Doctor_mobile":"Share to your Doctor",
  "Choose_share_results_doctor_mobile":"Choose one of the 3 options below to share your results with your doctor",
  "Share_your_Clinician_mobile":"Share with your Clinician",
  "Select_recent_doctor_organisation_mobile":"Select your recent doctor or organisation here",
  "Clinician_Search_mobile":"Clinician Search",
  "Search_doctor_organisation_mobile":"Search for your doctor or organisation here",
  "Clinician_Code_mobile":"Clinician Code",
  "Enter_doctor_code_search_mobile":"Enter the code that has been provided to you by your doctor in your email or in the clinic. If the code is not available, please check you recent visits or use the search.",
  "Skip_sharing_now_mobile":"Skip sharing for now",
  "Code_please_try_again_mobile":"Code incorrect, please try again.",
  "Confirm_details_mobile":"Confirm details",
  "PROMs_Speciality_Date_mobile":"PROMs Speciality & Date",
  "Results_Shared_mobile":"Results Shared!",
  "Your_successfully_doctor_mobile":"Your results has been successfully shared with your doctor.",
  "Please_results_mobile":"Please tap below to view your results.",
  "If_another_doctor_mobile":"If you would like to share with another doctor",
  "please_mobile":"please",
  "click_here_mobile":"click here",
  "Pain_Score_mobile":"Pain Score",
  "Recent_Scores_mobile":"Recent Scores",
  "Right_Arm_mobile":"Right Arm",
  "SACS_Score_mobile":"SACS",
  "complete_mobile":"Complete",
  "Add_Note_mobile":"Add Note",
  "Pending_Forms_mobile":"Pending Forms",
  "Your_pending__will_display_mobile":"Your pending forms will be displayed here",
  "Your_Incomplete_display_mobile":"Your Incomplete Forms will be displayed here",
  "Your_complete_display_mobile":"Your complete forms will be displayed here",
  "PROMs_Shoulder_request_mobile":"PROMs Shoulder request",
  "New_message_mobile":"New message",
  "New_appointment_mobile":"New appointment",
  "New_Consent_request_mobile":"New Consent request",
  "New_shared_document_mobile":"New shared document",
  "New_Organisation_Clinician_mobile":"New Organisation/Clinician",
  "This_field_cannot_empty_mobile":"This field cannot be empty.",
  "List_work_activities_mobile":"List work activities ...",
  "List_hobby_activities_mobile":"List hobby and sport activities ...",
  "patient_shoulder_wosi_sectionA_mobile":"SECTION A:",
  "patient_shoulder_wosi_sectionB_mobile":"SECTION B:",
  "patient_shoulder_wosi_sectionC_mobile":"Section C:",
  "patient_shoulder_wosi_sectionD_mobile":"Section D:",
  "patient_shoulder_sacs_sectionA_mobile":"Section A:",
  "patient_shoulder_sacs_sectionB_mobile":"Section B:",
  "patient_shoulder_sacs_sectionC_mobile":"Section C:",
  "sac_explaination":"Explanation",
  "patient_shoulder_sacs_explanation_mobile":"For each question, you will be asked to rate either your level of pain or difficulty performing different tasks. The rating scale is designed so that 0 reflects no pain or difficulty while 10 reflects the most severe amount of pain or difficulty.",
  "patient_shoulder_sacs_a3_note_mobile":"(e.g. Laptop or hand bag strap).",
  "patient_shoulder_sacs_b2_note_mobile":"(e.g. Resting your arm in a pocket or using a sling).",
  "patient_shoulder_sacs_b3_note_mobile":"(e.g. Push up, cycling, yoga).",
  "patient_shoulder_sacs_b4_note_mobile":"(e.g. Bag of shopping).",
  "patient_shoulder_sacs_b5_note_mobile":"(e.g. To reach across and touch the unaffected shoulder).",
  "patient_shoulder_sacs_b6_note_mobile":"(e.g. Greater than five kilograms).",
  "patient_shoulder_sacs_b7_note_mobile":"(e.g. Holding the steering wheel whilst driving).",
  "patient_shoulder_sacs_c1_note_mobile":"(Including the presence of a lump).",
  "Function_Score_mobile":"Function Score",
  "Instability_Score_mobile":"Instability Score",
  "patient_shoulder_pain_q2_mobile":"On a scale of 0-15, what is the highest level of pain that you have experienced in your shoulder during ordinary activities within the last 24 hours?",
  "patient_shoulder_pain_q6_mobile":"Do you take pain medication",
  "patient_shoulder_pain_q6_mobile_sub":"(eg paracetamol etc)?",
  "patient_shoulder_pain_q6_a2_q1_mobile":"Do you take strong pain medication",
  "patient_shoulder_pain_q6_a2_q1_mobile_sub":"(eg codeine, tramadol, morphine etc)?",
  "patient_shoulder_feeling_instability_q1_b1_mobile_sub":"(as if it is going to dislocate)?",
  "patient_shoulder_feeling_instability_q1_mobile":"Does your shoulder feel unstable",
  "patient_shoulder_wosi_instruction_mobile":"INSTRUCTIONS",
  "Next_of_kins_number_mobile":"Next of kin’s number",
  "Next_of_Kin_mobile":"Next of Kin",
  "Doctor_Name_mobile":"Doctor's name",
  "Doctors_practice_mobile":"Doctor’s practice",
  "Doctors_practice_address_mobile":"Doctor’s practice address",
  "Language_mobile":"Languages",
  "No_Internet_Connection_mobile":"No Internet Connection",
  "Please_type_your_name_mobile":"Please type your name",
  "Choose_Sign_Type_mobile":"Choose Sign Type",
  "Signed_by_Draw_mobile":"Signed by Draw",
  "select_image_mobile":"select image",
  "Consent_rejected_mobile":"Consent rejected",
  "Consent_submitted_mobile":"Consent submitted"
}