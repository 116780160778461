export default{
  "patient_shoulder_pain":"疼痛",
  "patient_shoulder_pain_q1":"您的肩膀痛嗎？",
  "patient_shoulder_pain_q1_a1":"否",
  "patient_shoulder_pain_q1_a2":"是",
  "patient_shoulder_pain_q2":"以 0-15 分表示，您在過去 24 小時內進行普通活動時肩部的最高疼痛程度是多少？ (0 表示無疼痛，15 表示無法忍受的疼痛）",
  "patient_shoulder_pain_q2_a1":"無法忍受的疼痛",
  "patient_shoulder_pain_q2_a2":"無疼痛",
  "patient_shoulder_pain_q3":"您的睡眠是否受到肩膀的干擾？",
  "patient_shoulder_pain_q3_a1":"不受干擾的睡眠",
  "patient_shoulder_pain_q3_a2":"偶爾干擾",
  "patient_shoulder_pain_q3_a3":"每晚干擾",
  "patient_shoulder_pain_q4":"您今天有多痛？",
  "patient_shoulder_pain_q5":"您目前是吸煙者嗎？",
  "patient_shoulder_pain_q5_a1":"否",
  "patient_shoulder_pain_q5_a2":"是",
  "patient_shoulder_pain_q5_a3":"前吸煙者",
  "patient_shoulder_pain_q5_a2_q1":"您每天吸多少支煙？",
  "patient_shoulder_pain_q5_a3_q1":"您戒菸多久了（幾年）？",
  "patient_shoulder_pain_q5_a3_q2":"您抽煙抽了多少年？",
  "patient_shoulder_pain_q6":"您服用止痛藥嗎？",
  "patient_shoulder_pain_q6_a1":"否",
  "patient_shoulder_pain_q6_a2":"是",
  "patient_shoulder_pain_q6_a2_q1":"您服用強效止痛藥嗎 (可待因，曲馬多， 嗎啡）？",
  "patient_shoulder_pain_q6_a2_q1_a1":"否",
  "patient_shoulder_pain_q6_a2_q1_a2":"是",
  "patient_shoulder_pain_q6_a2_q2":"您平均每天服用多少顆？",
  "patient_shoulder_pain_q7":"以下哪一項陳述最能說明您的疼痛？",
  "patient_shoulder_pain_q7_a1":"無",
  "patient_shoulder_pain_q7_a2":"偶爾和輕微",
  "patient_shoulder_pain_q7_a3":"在劇烈或特定活動時出現，偶爾需要服用阿司匹林",
  "patient_shoulder_pain_q7_a4":"在輕度活動或休息時出現，經常需要服用阿司匹林",
  "patient_shoulder_pain_q7_a5":"始終存在，但可以忍受，偶爾服用強效藥物",
  "patient_shoulder_pain_q7_a6":"始終存在， 不能忍受，經常服用強效藥物",
  "patient_shoulder_pain_q8":"您的手臂是否有針刺或麻木的感覺？",
  "patient_shoulder_pain_q8_a1":"否",
  "patient_shoulder_pain_q8_a2":"是",
  "patient_shoulder_pain_q9":"請標明哪裡疼痛",
  "patient_shoulder_pain_q9_a1":"前面",
  "patient_shoulder_pain_q9_a2":"後面",
  "patient_shoulder_function":"功能",
  "patient_shoulder_function_q1":"以下哪一項陳述最能說明疼痛那邊肩膀的活動能力？",
  "patient_shoulder_function_q1_a1":"普通， 沒有限制",
  "patient_shoulder_function_q1_a2":"輕微限制，能在肩膀以上工作",
  "patient_shoulder_function_q1_a3":"大多數家務、購物、駕駛、整理頭髮、穿脫衣服",
  "patient_shoulder_function_q1_a4":"執行輕微家務和大多日常生活活動",
  "patient_shoulder_function_q1_a5":"只可執行輕微活動",
  "patient_shoulder_function_q1_a6":"無法運用手臂",
  "patient_shoulder_function_instruction":"請選擇數字來表示您進行活動的能力",
  "patient_shoulder_function_instruction_0":"做不到",
  "patient_shoulder_function_instruction_1":"艱難",
  "patient_shoulder_function_instruction_2":"有些難",
  "patient_shoulder_function_instruction_3":"不難",
  "patient_shoulder_function_note":"筆記：請回答以下與您的肩部功能有關的問題",
  "patient_shoulder_function_q2":"穿外套/襯衫",
  "patient_shoulder_function_q2_a0":"做不到",
  "patient_shoulder_function_q2_a1":"艱難",
  "patient_shoulder_function_q2_a2":"有些難",
  "patient_shoulder_function_q2_a3":"不難",
  "patient_shoulder_function_q3":"側躺靠在您疼痛的肩部睡覺",
  "patient_shoulder_function_q3_a0":"做不到",
  "patient_shoulder_function_q3_a1":"艱難",
  "patient_shoulder_function_q3_a2":"有些難",
  "patient_shoulder_function_q3_a3":"不難",
  "patient_shoulder_function_q4":"洗自己背/扣自己胸罩",
  "patient_shoulder_function_q4_a0":"做不到",
  "patient_shoulder_function_q4_a1":"艱難",
  "patient_shoulder_function_q4_a2":"有些難",
  "patient_shoulder_function_q4_a3":"不難",
  "patient_shoulder_function_q5":"上廁所擦屁股",
  "patient_shoulder_function_q5_a0":"做不到",
  "patient_shoulder_function_q5_a1":"艱難",
  "patient_shoulder_function_q5_a2":"有些難",
  "patient_shoulder_function_q5_a3":"不難",
  "patient_shoulder_function_q6":"梳頭髮",
  "patient_shoulder_function_q6_a0":"做不到",
  "patient_shoulder_function_q6_a1":"艱難",
  "patient_shoulder_function_q6_a2":"有些難",
  "patient_shoulder_function_q6_a3":"不難",
  "patient_shoulder_function_q7":"刮鬍子/化妝",
  "patient_shoulder_function_q7_a0":"做不到",
  "patient_shoulder_function_q7_a1":"艱難",
  "patient_shoulder_function_q7_a2":"有些難",
  "patient_shoulder_function_q7_a3":"不難",
  "patient_shoulder_function_q8":"刷牙",
  "patient_shoulder_function_q8_a0":"做不到",
  "patient_shoulder_function_q8_a1":"艱難",
  "patient_shoulder_function_q8_a2":"有些難",
  "patient_shoulder_function_q8_a3":"不難",
  "patient_shoulder_function_q9":"用一整瓶水把玻璃杯裝滿",
  "patient_shoulder_function_q9_a0":"做不到",
  "patient_shoulder_function_q9_a1":"艱難",
  "patient_shoulder_function_q9_a2":"有些難",
  "patient_shoulder_function_q9_a3":"不難",
  "patient_shoulder_function_q10":"喝水 （把裝滿的杯子舉到嘴前）",
  "patient_shoulder_function_q10_a0":"做不到",
  "patient_shoulder_function_q10_a1":"艱難",
  "patient_shoulder_function_q10_a2":"有些難",
  "patient_shoulder_function_q10_a3":"不難",
  "patient_shoulder_function_q11":"喝湯 （把滿滿的湯匙放到嘴裡）",
  "patient_shoulder_function_q11_a0":"做不到",
  "patient_shoulder_function_q11_a1":"艱難",
  "patient_shoulder_function_q11_a2":"有些難",
  "patient_shoulder_function_q11_a3":"不難",
  "patient_shoulder_function_q12":"握手 / 開門",
  "patient_shoulder_function_q12_a0":"做不到",
  "patient_shoulder_function_q12_a1":"艱難",
  "patient_shoulder_function_q12_a2":"有些難",
  "patient_shoulder_function_q12_a3":"不難",
  "patient_shoulder_function_q13":"用電話 （拿到耳朵的高度）",
  "patient_shoulder_function_q13_a0":"做不到",
  "patient_shoulder_function_q13_a1":"艱難",
  "patient_shoulder_function_q13_a2":"有些難",
  "patient_shoulder_function_q13_a3":"不難",
  "patient_shoulder_function_q14":"手寫信 / 文件上簽名 / 使用鍵盤",
  "patient_shoulder_function_q14_a0":"做不到",
  "patient_shoulder_function_q14_a1":"艱難",
  "patient_shoulder_function_q14_a2":"有些難",
  "patient_shoulder_function_q14_a3":"不難",
  "patient_shoulder_function_q15":"用手拿起或觸及高架上的物品",
  "patient_shoulder_function_q15_a0":"做不到",
  "patient_shoulder_function_q15_a1":"艱難",
  "patient_shoulder_function_q15_a2":"有些難",
  "patient_shoulder_function_q15_a3":"不難",
  "patient_shoulder_function_q16":"將 0.5 公斤（1英磅） 的物品舉起高過肩膀",
  "patient_shoulder_function_q16_a0":"做不到",
  "patient_shoulder_function_q16_a1":"艱難",
  "patient_shoulder_function_q16_a2":"有些難",
  "patient_shoulder_function_q16_a3":"不難",
  "patient_shoulder_function_q17":"將 4.5 公斤（10英磅）的物品舉起高過肩膀",
  "patient_shoulder_function_q17_a0":"做不到",
  "patient_shoulder_function_q17_a1":"艱難",
  "patient_shoulder_function_q17_a2":"有些難",
  "patient_shoulder_function_q17_a3":"不難",
  "patient_shoulder_function_q18":"利用疼痛肩部一側的手臂提 10 公斤 （22英磅）的物品",
  "patient_shoulder_function_q18_a0":"做不到",
  "patient_shoulder_function_q18_a1":"艱難",
  "patient_shoulder_function_q18_a2":"有些難",
  "patient_shoulder_function_q18_a3":"不難",
  "patient_shoulder_function_q19":"低臂投球 （至少 3 米）",
  "patient_shoulder_function_q19_a0":"做不到",
  "patient_shoulder_function_q19_a1":"艱難",
  "patient_shoulder_function_q19_a2":"有些難",
  "patient_shoulder_function_q19_a3":"不難",
  "patient_shoulder_function_q20":"高臂投球 （至少 6 米）",
  "patient_shoulder_function_q20_a0":"做不到",
  "patient_shoulder_function_q20_a1":"艱難",
  "patient_shoulder_function_q20_a2":"有些難",
  "patient_shoulder_function_q20_a3":"不難",
  "patient_shoulder_function_q21":"完整進行平日的工作",
  "patient_shoulder_function_q21_a0":"做不到",
  "patient_shoulder_function_q21_a1":"艱難",
  "patient_shoulder_function_q21_a2":"有些難",
  "patient_shoulder_function_q21_a3":"不難",
  "patient_shoulder_function_q22":"您的肩膀能夠勝任多少正常工作？",
  "patient_shoulder_function_q22_a0":"無",
  "patient_shoulder_function_q22_a1":"全部",
  "patient_shoulder_function_q23":"完整進行平日的愛好/運動",
  "patient_shoulder_function_q23_a0":"做不到",
  "patient_shoulder_function_q23_a1":"艱難",
  "patient_shoulder_function_q23_a2":"有些難",
  "patient_shoulder_function_q23_a3":"不難",
  "patient_shoulder_function_q24":"您的肩膀能夠勝任多少正常的娛樂活動？",
  "patient_shoulder_function_q24_a0":"無",
  "patient_shoulder_function_q24_a1":"全部",
  "patient_shoulder_function_q25":"請列出工作活動",
  "patient_shoulder_function_q26":"請列出愛好/運動",
  "patient_shoulder_feeling_instability":"肩關節不穩定",
  "patient_shoulder_feeling_instability_q1":"您的肩是否感覺不穩定 （好像快脫臼）？",
  "patient_shoulder_feeling_instability_q1_a1":"否",
  "patient_shoulder_feeling_instability_q1_a2":"是",
  "patient_shoulder_feeling_instability_q1_a2_q1":"請標明您的肩關節有多不穩定",
  "patient_shoulder_wosi":"西安大略肩關節不穩定指數",
  "patient_shoulder_wosi_instruction":"指示：請您在這個問卷調查中表明您疼痛的肩膀在過去的一週裡經歷過多少症狀。請在線條上移動滑塊，來標明症狀的嚴重程度。",
  "patient_shoulder_wosi_note":"注意：將滑塊向右移得越遠，表示您遇到該症狀的程度就越深。將滑塊向左移得越遠，表明您遇到的這種症狀就越少。如果您對任何問題的意圖有疑問，請進行詢問。",
  "patient_shoulder_wosi_sectionA":"A部分 ：身體症狀",
  "patient_shoulder_wosi_q1":"您的肩膀在進行高於頭部的活動時，有多少疼痛？",
  "patient_shoulder_wosi_q1_a1":"不痛",
  "patient_shoulder_wosi_q1_a2":"極度疼痛",
  "patient_shoulder_wosi_q2":"您的肩膀歷經多少抽痛？",
  "patient_shoulder_wosi_q2_a1":"沒有抽痛",
  "patient_shoulder_wosi_q2_a2":"極端抽痛",
  "patient_shoulder_wosi_q3":"您的肩膀有缺乏力量或軟弱嗎？",
  "patient_shoulder_wosi_q3_a1":"沒有缺乏力量",
  "patient_shoulder_wosi_q3_a2":"極端缺乏力量",
  "patient_shoulder_wosi_q4":"您的肩膀感覺疲勞或缺乏耐力嗎？",
  "patient_shoulder_wosi_q4_a1":"沒有疲勞",
  "patient_shoulder_wosi_q4_a2":"極端疲勞",
  "patient_shoulder_wosi_q5":"您的肩膀發出多少咔嗒聲，爆裂聲， 劈啪聲？",
  "patient_shoulder_wosi_q5_a1":"沒有咔嗒聲",
  "patient_shoulder_wosi_q5_a2":"極端咔嗒聲",
  "patient_shoulder_wosi_q6":"您的肩膀僵硬嗎？",
  "patient_shoulder_wosi_q6_a1":"沒有僵硬",
  "patient_shoulder_wosi_q6_a2":"極端僵硬",
  "patient_shoulder_wosi_q7":"因為您的肩膀疼痛，導致頸部肌肉有多少不舒服？",
  "patient_shoulder_wosi_q7_a1":"沒有不舒服",
  "patient_shoulder_wosi_q7_a2":"極端不舒服",
  "patient_shoulder_wosi_q8":"您的肩關節有多不穩定？",
  "patient_shoulder_wosi_q8_a1":"沒有不穩定",
  "patient_shoulder_wosi_q8_a2":"極端不穩定",
  "patient_shoulder_wosi_q9":"因為肩膀的疼痛，您經常運用其他的身體肌肉進行替代動作嗎？",
  "patient_shoulder_wosi_q9_a1":"一點也不",
  "patient_shoulder_wosi_q9_a2":"極嚴重",
  "patient_shoulder_wosi_q10":"您的肩膀因疼痛減少了多少活動範圍？",
  "patient_shoulder_wosi_q10_a1":"沒有減少",
  "patient_shoulder_wosi_q10_a2":"極端減少",
  "patient_shoulder_wosi_sectionB":"B部分 ：運動 / 休閒 / 工作",
  "patient_shoulder_wosi_q11":"您的肩膀多大程度上限制您參加運動或休閒活動？",
  "patient_shoulder_wosi_q11_a1":"沒有限制",
  "patient_shoulder_wosi_q11_a2":"極端限制",
  "patient_shoulder_wosi_q12":"您的肩膀限制了多少您工作或運動所需的特定技能？",
  "patient_shoulder_wosi_q12_note":"如工作和運動都因肩膀而受限制，請考慮影響比較嚴重的一方",
  "patient_shoulder_wosi_q12_a1":"沒有影響",
  "patient_shoulder_wosi_q12_a2":"極端影響",
  "patient_shoulder_wosi_q13":"在進行活動時，您有需要保護肩臂的感覺嗎？",
  "patient_shoulder_wosi_q13_a1":"一點也不",
  "patient_shoulder_wosi_q13_a2":"極嚴重",
  "patient_shoulder_wosi_q14":"您在低於肩部的高度舉起重物的時候有歷經任何困難嗎？",
  "patient_shoulder_wosi_q14_a1":"沒有困難",
  "patient_shoulder_wosi_q14_a2":"極度困難",
  "patient_shoulder_wosi_sectionC":"C部分：生活方式",
  "patient_shoulder_wosi_q15":"您有多害怕摔倒撞到肩膀？",
  "patient_shoulder_wosi_q15_a1":"沒有害怕",
  "patient_shoulder_wosi_q15_a2":"極端害怕",
  "patient_shoulder_wosi_q16":"您在維持想要的體能水準方面遇到多少困難？",
  "patient_shoulder_wosi_q16_a1":"沒有困難",
  "patient_shoulder_wosi_q16_a2":"極度困難",
  "patient_shoulder_wosi_q17":"您和家人與朋友們嬉笑打鬧時有困難嗎？",
  "patient_shoulder_wosi_q17_a1":"沒有困難",
  "patient_shoulder_wosi_q17_a2":"極度困難",
  "patient_shoulder_wosi_q18":"您因肩膀不舒服造成入睡有困難嗎？",
  "patient_shoulder_wosi_q18_a1":"沒有困難",
  "patient_shoulder_wosi_q18_a2":"極度困難",
  "patient_shoulder_wosi_sectionD":"D部分：情緒",
  "patient_shoulder_wosi_q19":"您是否時時意識自己的肩膀？",
  "patient_shoulder_wosi_q19_a1":"沒意識到",
  "patient_shoulder_wosi_q19_a2":"極端意識到",
  "patient_shoulder_wosi_q20":"您是否擔心您的肩膀狀況變得更糟？",
  "patient_shoulder_wosi_q20_a1":"沒有擔心",
  "patient_shoulder_wosi_q20_a2":"極端擔心",
  "patient_shoulder_wosi_q21":"您是否因您的肩膀感到挫折？",
  "patient_shoulder_wosi_q21_a1":"沒有挫折",
  "patient_shoulder_wosi_q21_a2":"極端挫折",
  "patient_shoulder_ac_joint":"AC 關節",
  "patient_shoulder_ac_joint_q1":"您的AC關節有不穩定嗎？",
  "patient_shoulder_ac_joint_q1_note":"註：AC 關節就是肩膀頂部連接鎖骨和肩胛骨的關節。",
  "patient_shoulder_ac_joint_q1_a1":"否",
  "patient_shoulder_ac_joint_q1_a2":"是",
  "patient_shoulder_sacs":"特定的AC指數 （SACS）",
  "patient_shoulder_sacs_explanation":"說明：每個問題都會要求您對執行某種任務時的疼痛或困難程度進行評分。評分的標準是 0 代表沒有疼痛或困難，而 10 代表最嚴重的疼痛或困難。",
  "patient_shoulder_sacs_note":"註：2 代表程度最低的疼痛。如果您對任何特定問題的意圖有疑問，請詢問。",
  "patient_shoulder_sacs_sectionA":"A部分：疼痛",
  "patient_shoulder_sacs_a1":"請評估您肩膀疼痛最嚴重時，疼痛的等級",
  "patient_shoulder_sacs_a1_a1":"不痛",
  "patient_shoulder_sacs_a1_a2":"最疼痛",
  "patient_shoulder_sacs_a2":"請評估您肩膀感覺最好時，疼痛的等級",
  "patient_shoulder_sacs_a2_a1":"不痛",
  "patient_shoulder_sacs_a2_a2":"最疼痛",
  "patient_shoulder_sacs_a3":"請評估您肩膀被觸碰或受壓的時候，疼痛的等級",
  "patient_shoulder_sacs_a3_note":"如：筆記型電腦包或手提包的背帶",
  "patient_shoulder_sacs_a3_a1":"不痛",
  "patient_shoulder_sacs_a3_a2":"極端疼痛",
  "patient_shoulder_sacs_a4":"請評估您頸部或受影響肩胛骨部位",
  "patient_shoulder_sacs_a4_a1":"不痛",
  "patient_shoulder_sacs_a4_a2":"極端疼痛",
  "patient_shoulder_sacs_sectionB":"B部分：功能",
  "patient_shoulder_sacs_b1":"請評估您在肩膀上感受到的咔噠聲和/或磨擦聲的數量",
  "patient_shoulder_sacs_b1_a1":"沒有咔嗒聲",
  "patient_shoulder_sacs_b1_a2":"極端咔嗒聲",
  "patient_shoulder_sacs_b2":"請註明您的手臂在行走時是否感覺需要被支撐",
  "patient_shoulder_sacs_b2_note":"像是把手放在口袋裡面休息，或使用吊帶",
  "patient_shoulder_sacs_b2_a1":"不需要支撐",
  "patient_shoulder_sacs_b2_a2":"極端需要支撐",
  "patient_shoulder_sacs_b3":"請評估您舉起重物或通過手臂斜撐身體的困難程度",
  "patient_shoulder_sacs_b3_note":"動作像是伏地挺身、騎自行車、做瑜伽",
  "patient_shoulder_sacs_b3_a1":"沒有困難",
  "patient_shoulder_sacs_b3_a2":"極端困難 / 做不到",
  "patient_shoulder_sacs_b4":"請評估您在身邊拿著重物超過五分鐘時間的困難程度",
  "patient_shoulder_sacs_b4_note":"例如購物袋",
  "patient_shoulder_sacs_b4_a1":"沒有困難",
  "patient_shoulder_sacs_b4_a2":"極端困難 / 做不到",
  "patient_shoulder_sacs_b5":"請評估您運用受影響的手臂接觸身體各處的困難程度",
  "patient_shoulder_sacs_b5_note":"例如將手伸到另一邊去碰沒有受影響的肩膀",
  "patient_shoulder_sacs_b5_a1":"沒有困難",
  "patient_shoulder_sacs_b5_a2":"極端困難 / 做不到",
  "patient_shoulder_sacs_b6":"請評估您推重物或舉重物過頭的困難程度",
  "patient_shoulder_sacs_b6_note":"例如超過 5 公斤的重物",
  "patient_shoulder_sacs_b6_a1":"沒有困難",
  "patient_shoulder_sacs_b6_a2":"極端困難 / 做不到",
  "patient_shoulder_sacs_b7":"請評估受影響手臂長時間撐在前面的困難程度",
  "patient_shoulder_sacs_b7_note":"例如駕車時手握方向盤",
  "patient_shoulder_sacs_b7_a1":"沒有困難",
  "patient_shoulder_sacs_b7_a2":"極端困難 / 做不到",
  "patient_shoulder_sacs_b8":"請評估您進行日常工作的困難程度",
  "patient_shoulder_sacs_b8_a1":"沒有困難",
  "patient_shoulder_sacs_b8_a2":"極端困難 / 做不到",
  "patient_shoulder_sacs_b8_a3":"不適用",
  "patient_shoulder_sacs_b9":"為了不讓受影響的肩膀疼痛，您需要對日常工作活動進行調整多少？",
  "patient_shoulder_sacs_b9_a1":"沒有困難",
  "patient_shoulder_sacs_b9_a2":"極端困難 / 做不到",
  "patient_shoulder_sacs_b9_a3":"不適用",
  "patient_shoulder_sacs_b10":"請評估您進行主要體育活動時的困難",
  "patient_shoulder_sacs_b10_a1":"沒有困難",
  "patient_shoulder_sacs_b10_a2":"極端困難 / 做不到",
  "patient_shoulder_sacs_b10_a3":"不適用",
  "patient_shoulder_sacs_b11":"請評估您為了不讓受影響肩膀疼痛，調整了多少您的主要體育活動",
  "patient_shoulder_sacs_b11_a1":"沒有困難",
  "patient_shoulder_sacs_b11_a2":"極端困難 / 做不到",
  "patient_shoulder_sacs_b11_a3":"不適用",
  "patient_shoulder_sacs_sectionC":"C部分：生活品質和滿意度",
  "patient_shoulder_sacs_c1":"請評估您肩膀上方骨骼與普通的肩膀外觀有多少差距",
  "patient_shoulder_sacs_c1_note":"包括腫塊的存在",
  "patient_shoulder_sacs_c1_a1":"沒有差別",
  "patient_shoulder_sacs_c1_a2":"極端差別",
  "patient_shoulder_sacs_c2":"請評估因肩膀問題產生的內心壓力或焦慮程度",
  "patient_shoulder_sacs_c2_a1":"沒有壓力/焦慮",
  "patient_shoulder_sacs_c2_a2":"嚴重壓力/焦慮",
  "patient_shoulder_sacs_c3":"您多常意識到自己的肩部問題？",
  "patient_shoulder_sacs_c3_a1":"從未",
  "patient_shoulder_sacs_c3_a2":"持續不斷",
  "patient_shoulder_sacs_c4":"您有刻意改變可能傷害肩部或加劇肩部問題的生活型態嗎？",
  "patient_shoulder_sacs_c4_a1":"一點也不",
  "patient_shoulder_sacs_c4_a2":"極度",
  "patient_shoulder_sacs_c5":"您對自己的肩膀有多不高興或不滿意？",
  "patient_shoulder_sacs_c5_a1":"極端滿意",
  "patient_shoulder_sacs_c5_a2":"極端不滿意",
  "patient_shoulder_sacs_c6":"與您對「正常」肩部的預期相比，整體上您如何評價您的肩部？（0% 表示較差，100% 表示非常好）",
  "patient_shoulder_satisfaction":"滿意度",
  "patient_shoulder_satisfaction_q1":"對於您受影響的肩膀，現今您",
  "patient_shoulder_satisfaction_q1_a1":"滿意/很滿意",
  "patient_shoulder_satisfaction_q1_a2":"不滿意 / 更糟",
  "patient_shoulder_complete":"問卷結束",
  "patient_shoulder_complete_text1":"感謝您抽出寶貴時間填寫此問卷。",
  "patient_shoulder_complete_text2":"請點擊提交完成，並查看您的分數。",
  "patient_shoulder_complete_text3":"您的回答將被保密，任何報告都只會使用去除身份識別的資訊",
  "patient_shoulder_results":"結果",
  "patient_shoulder_results_text1":"以下是您的疼痛和肩部功能評分，這些評分是根據您的調查問卷計算得出的。",
  "patient_shoulder_results_pain":"疼痛",
  "patient_shoulder_results_function":"功能",
  "patient_shoulder_results_instability":"不穩定性",
  "patient_shoulder_results_selfevaluation":"肩膀自我評分",
  "patient_shoulder_results_text2":"您的醫生很快就會和您見面",
  "patient_hip_pain":"疼痛",
  "patient_hip_pain_q1":"總的來說，您會如何描述最近一個月您的臀部經常出現的疼痛？",
  "patient_hip_pain_q1_a1":"無",
  "patient_hip_pain_q1_a2":"很輕微",
  "patient_hip_pain_q1_a3":"輕微",
  "patient_hip_pain_q1_a4":"中度",
  "patient_hip_pain_q1_a5":"嚴重",
  "patient_hip_pain_q2":"此刻您的臀部疼痛有多嚴重？ （0 表示沒有疼痛，10 表示您能想像到的最嚴重的疼痛）",
  "patient_hip_pain_q2_a1":"不痛",
  "patient_hip_pain_q2_a2":"最嚴重的疼痛",
  "patient_hip_pain_q3":"以下哪一項最能描述您的痛苦?",
  "patient_hip_pain_q3_a1":"無 / 忽略",
  "patient_hip_pain_q3_a2":"一點點，偶爾，活動不受影響",
  "patient_hip_pain_q3_a3":"輕微疼痛，平均活動不受影響，普通活動後感到疼痛，服用阿司匹林",
  "patient_hip_pain_q3_a4":"中度疼痛，可以忍受，有時被迫調整做法，偶爾服用可待因",
  "patient_hip_pain_q3_a5":"極端疼痛，嚴重限制",
  "patient_hip_pain_q3_a6":"完全殘疾",
  "patient_hip_pain_q4":"您的臀部多常疼痛？",
  "patient_hip_pain_q4_a1":"從未",
  "patient_hip_pain_q4_a2":"每月",
  "patient_hip_pain_q4_a3":"每週",
  "patient_hip_pain_q4_a4":"每天",
  "patient_hip_pain_q4_a5":"總是如此",
  "patient_hip_pain_q5":"您臀部的疼痛干擾了多少您的工作 （包括家務）？",
  "patient_hip_pain_q5_a1":"一點也不",
  "patient_hip_pain_q5_a2":"稍微干擾",
  "patient_hip_pain_q5_a3":"中度",
  "patient_hip_pain_q5_a4":"嚴重",
  "patient_hip_pain_q5_a5":"極度",
  "patient_hip_pain_instruction":"在進行以下的活動時，您體驗到多少臀部疼痛？",
  "patient_hip_pain_instruction_0":"無",
  "patient_hip_pain_instruction_1":"輕微",
  "patient_hip_pain_instruction_2":"中度",
  "patient_hip_pain_instruction_3":"嚴重",
  "patient_hip_pain_instruction_4":"極嚴重",
  "patient_hip_pain_note":"注：請回答關於您的臀部活動的的問題。",
  "patient_hip_pain_q6":"完全伸直臀部",
  "patient_hip_pain_q6_a1":"無",
  "patient_hip_pain_q6_a2":"輕微",
  "patient_hip_pain_q6_a3":"中度",
  "patient_hip_pain_q6_a4":"嚴重",
  "patient_hip_pain_q6_a5":"極嚴重",
  "patient_hip_pain_q7":"完全彎下臀部",
  "patient_hip_pain_q7_a1":"無",
  "patient_hip_pain_q7_a2":"輕微",
  "patient_hip_pain_q7_a3":"中度",
  "patient_hip_pain_q7_a4":"嚴重",
  "patient_hip_pain_q7_a5":"極嚴重",
  "patient_hip_pain_q8":"在平坦的表面步行",
  "patient_hip_pain_q8_a1":"無",
  "patient_hip_pain_q8_a2":"輕微",
  "patient_hip_pain_q8_a3":"中度",
  "patient_hip_pain_q8_a4":"嚴重",
  "patient_hip_pain_q8_a5":"極嚴重",
  "patient_hip_pain_q9":"您步行了多久才感覺臀部疼痛變嚴重？ (獨立或拄著拐杖）",
  "patient_hip_pain_q9_a1":"沒有痛 / 超過30分鐘 / 可持續行走",
  "patient_hip_pain_q9_a2":"16-30 分鐘 / 6 個城市街區距離",
  "patient_hip_pain_q9_a3":"5-15 分鐘 / 2-3 個城市街區距離",
  "patient_hip_pain_q9_a4":"在家裡範圍 / 室內",
  "patient_hip_pain_q9_a5":"做不到步行 / 步行時嚴重疼痛 / 只能待在床或椅子上",
  "patient_hip_pain_q10":"在堅硬的表面上步行 （ 瀝青, 混凝土）",
  "patient_hip_pain_q10_a1":"無",
  "patient_hip_pain_q10_a2":"輕微",
  "patient_hip_pain_q10_a3":"中度",
  "patient_hip_pain_q10_a4":"嚴重",
  "patient_hip_pain_q10_a5":"極嚴重",
  "patient_hip_pain_q11":"在不均勻的表面上步行",
  "patient_hip_pain_q11_a1":"無",
  "patient_hip_pain_q11_a2":"輕微",
  "patient_hip_pain_q11_a3":"中度",
  "patient_hip_pain_q11_a4":"嚴重",
  "patient_hip_pain_q11_a5":"極嚴重",
  "patient_hip_pain_q12":"上下樓梯",
  "patient_hip_pain_q12_a1":"無",
  "patient_hip_pain_q12_a2":"輕微",
  "patient_hip_pain_q12_a3":"中度",
  "patient_hip_pain_q12_a4":"嚴重",
  "patient_hip_pain_q12_a5":"極嚴重",
  "patient_hip_pain_q13":"晚間在床上",
  "patient_hip_pain_q13_a1":"無",
  "patient_hip_pain_q13_a2":"輕微",
  "patient_hip_pain_q13_a3":"中度",
  "patient_hip_pain_q13_a4":"嚴重",
  "patient_hip_pain_q13_a5":"極嚴重",
  "patient_hip_pain_q14":"您多常在晚上躺在床上睡覺時被臀部疼痛所困擾？",
  "patient_hip_pain_q14_a1":"否",
  "patient_hip_pain_q14_a2":"一或兩個晚上",
  "patient_hip_pain_q14_a3":"有幾晚",
  "patient_hip_pain_q14_a4":"大多晚上",
  "patient_hip_pain_q14_a5":"每天晚上",
  "patient_hip_pain_q15":"坐下或躺臥",
  "patient_hip_pain_q15_a1":"無",
  "patient_hip_pain_q15_a2":"輕微",
  "patient_hip_pain_q15_a3":"中度",
  "patient_hip_pain_q15_a4":"嚴重",
  "patient_hip_pain_q15_a5":"極嚴重",
  "patient_hip_pain_q16":"站立",
  "patient_hip_pain_q16_a1":"無",
  "patient_hip_pain_q16_a2":"輕微",
  "patient_hip_pain_q16_a3":"中度",
  "patient_hip_pain_q16_a4":"嚴重",
  "patient_hip_pain_q16_a5":"極嚴重",
  "patient_hip_pain_q17":"從坐的椅子起身站立",
  "patient_hip_pain_q17_a1":"無",
  "patient_hip_pain_q17_a2":"輕微",
  "patient_hip_pain_q17_a3":"中度",
  "patient_hip_pain_q17_a4":"嚴重",
  "patient_hip_pain_q17_a5":"極嚴重",
  "patient_hip_pain_q18":"活動後",
  "patient_hip_pain_q18_a1":"無",
  "patient_hip_pain_q18_a2":"輕微",
  "patient_hip_pain_q18_a3":"中度",
  "patient_hip_pain_q18_a4":"嚴重",
  "patient_hip_pain_q18_a5":"極嚴重",
  "patient_hip_other_symptoms":"其他症狀",
  "patient_hip_other_symptoms_instruction1":"請選擇最符合您臀部症狀的答案。",
  "patient_hip_other_symptoms_instruction2":"如果您不確定如何回答問題，請盡可能給出最佳答案",
  "patient_hip_other_symptoms_instruction3":"您是否經驗到以下情況：",
  "patient_hip_other_symptoms_instruction_0":"無",
  "patient_hip_other_symptoms_instruction_1":"輕微",
  "patient_hip_other_symptoms_instruction_2":"中度",
  "patient_hip_other_symptoms_instruction_3":"嚴重",
  "patient_hip_other_symptoms_instruction_4":"極嚴重",
  "patient_hip_other_symptoms_q1":"臀部發出摩擦聲、咔嗒聲或任何其他類型的噪音",
  "patient_hip_other_symptoms_q1_a1":"從未",
  "patient_hip_other_symptoms_q1_a2":"很少",
  "patient_hip_other_symptoms_q1_a3":"有時",
  "patient_hip_other_symptoms_q1_a4":"經常",
  "patient_hip_other_symptoms_q1_a5":"總是如此",
  "patient_hip_other_symptoms_q2":"髖關節感覺卡住或動不了",
  "patient_hip_other_symptoms_q2_a1":"無",
  "patient_hip_other_symptoms_q2_a2":"輕微",
  "patient_hip_other_symptoms_q2_a3":"中度",
  "patient_hip_other_symptoms_q2_a4":"嚴重",
  "patient_hip_other_symptoms_q2_a5":"極嚴重",
  "patient_hip_other_symptoms_q3":"早上起床後感覺僵硬",
  "patient_hip_other_symptoms_q3_a1":"無",
  "patient_hip_other_symptoms_q3_a2":"輕微",
  "patient_hip_other_symptoms_q3_a3":"中度",
  "patient_hip_other_symptoms_q3_a4":"嚴重",
  "patient_hip_other_symptoms_q3_a5":"極嚴重",
  "patient_hip_other_symptoms_q4":"坐下，躺下來，或晚上休息",
  "patient_hip_other_symptoms_q4_a1":"無",
  "patient_hip_other_symptoms_q4_a2":"輕微",
  "patient_hip_other_symptoms_q4_a3":"中度",
  "patient_hip_other_symptoms_q4_a4":"嚴重",
  "patient_hip_other_symptoms_q4_a5":"極嚴重",
  "patient_hip_other_symptoms_q5":"跛行",
  "patient_hip_other_symptoms_q5_a1":"否， 偶爾/絕不",
  "patient_hip_other_symptoms_q5_a2":"一點點，有時或一開始而已",
  "patient_hip_other_symptoms_q5_a3":"中度，經常，不止一開始",
  "patient_hip_other_symptoms_q5_a4":"嚴重，大多數時候",
  "patient_hip_other_symptoms_q5_a5":"一直不舒服或無法步行",
  "patient_hip_other_symptoms_q6":"突然的劇烈疼痛、刺痛、痙攣",
  "patient_hip_other_symptoms_q6_a1":"沒有",
  "patient_hip_other_symptoms_q6_a2":"一或兩天",
  "patient_hip_other_symptoms_q6_a3":"有些日子",
  "patient_hip_other_symptoms_q6_a4":"大多數日子",
  "patient_hip_other_symptoms_q6_a5":"每天",
  "patient_hip_other_symptoms_q7":"臀部撐不住",
  "patient_hip_other_symptoms_q7_a1":"無",
  "patient_hip_other_symptoms_q7_a2":"輕微",
  "patient_hip_other_symptoms_q7_a3":"中度",
  "patient_hip_other_symptoms_q7_a4":"嚴重",
  "patient_hip_other_symptoms_q7_a5":"極嚴重",
  "patient_hip_other_symptoms_q8":"減少移動",
  "patient_hip_other_symptoms_q8_a1":"無",
  "patient_hip_other_symptoms_q8_a2":"輕微",
  "patient_hip_other_symptoms_q8_a3":"中度",
  "patient_hip_other_symptoms_q8_a4":"嚴重",
  "patient_hip_other_symptoms_q8_a5":"極嚴重",
  "patient_hip_other_symptoms_q9":"您步行時使用任何支撐物嗎？",
  "patient_hip_other_symptoms_q9_a1":"無",
  "patient_hip_other_symptoms_q9_a2":"長途步行時用手杖/棍子",
  "patient_hip_other_symptoms_q9_a3":"大多數時候用手杖/棍子",
  "patient_hip_other_symptoms_q9_a4":"一根拐杖",
  "patient_hip_other_symptoms_q9_a5":"兩根拐杖/手杖",
  "patient_hip_other_symptoms_q9_a6":"兩根拐杖 / 無法步行",
  "patient_hip_function":"功能",
  "patient_hip_function_instruction":"請標明您上週因髖關節問題而經歷的以下活動的困難程度",
  "patient_hip_function_instruction_0":"無",
  "patient_hip_function_instruction_1":"輕微",
  "patient_hip_function_instruction_2":"中度",
  "patient_hip_function_instruction_3":"嚴重",
  "patient_hip_function_instruction_4":"極嚴重 / 做不到",
  "patient_hip_function_note":"註：如果您不做某項活動，請想像一下如果您必須嘗試，您的臀部會有什麼感覺",
  "patient_hip_function_q1":"下樓梯",
  "patient_hip_function_q1_a1":"無",
  "patient_hip_function_q1_a2":"輕微",
  "patient_hip_function_q1_a3":"中度",
  "patient_hip_function_q1_a4":"嚴重",
  "patient_hip_function_q1_a5":"極嚴重 / 做不到",
  "patient_hip_function_q2":"上樓梯",
  "patient_hip_function_q2_a1":"無",
  "patient_hip_function_q2_a2":"輕微",
  "patient_hip_function_q2_a3":"中度",
  "patient_hip_function_q2_a4":"嚴重",
  "patient_hip_function_q2_a5":"極嚴重 / 做不到",
  "patient_hip_function_q3":"坐著",
  "patient_hip_function_q3_a1":"無",
  "patient_hip_function_q3_a2":"輕微",
  "patient_hip_function_q3_a3":"中度",
  "patient_hip_function_q3_a4":"嚴重",
  "patient_hip_function_q3_a5":"極嚴重 / 做不到",
  "patient_hip_function_q4":"從椅子起身",
  "patient_hip_function_q4_a1":"無",
  "patient_hip_function_q4_a2":"輕微",
  "patient_hip_function_q4_a3":"中度",
  "patient_hip_function_q4_a4":"嚴重",
  "patient_hip_function_q4_a5":"極嚴重 / 做不到",
  "patient_hip_function_q5":"從地板上起身",
  "patient_hip_function_q5_a1":"無",
  "patient_hip_function_q5_a2":"輕微",
  "patient_hip_function_q5_a3":"中度",
  "patient_hip_function_q5_a4":"嚴重",
  "patient_hip_function_q5_a5":"極嚴重 / 做不到",
  "patient_hip_function_q6":"站著",
  "patient_hip_function_q6_a1":"無",
  "patient_hip_function_q6_a2":"輕微",
  "patient_hip_function_q6_a3":"中度",
  "patient_hip_function_q6_a4":"嚴重",
  "patient_hip_function_q6_a5":"極嚴重 / 做不到",
  "patient_hip_function_q7":"站立十五分鐘",
  "patient_hip_function_q7_a1":"無",
  "patient_hip_function_q7_a2":"輕微",
  "patient_hip_function_q7_a3":"中度",
  "patient_hip_function_q7_a4":"嚴重",
  "patient_hip_function_q7_a5":"極嚴重 / 做不到",
  "patient_hip_function_q8":"張開雙腿",
  "patient_hip_function_q8_a1":"無",
  "patient_hip_function_q8_a2":"輕微",
  "patient_hip_function_q8_a3":"中度",
  "patient_hip_function_q8_a4":"嚴重",
  "patient_hip_function_q8_a5":"極嚴重 / 做不到",
  "patient_hip_function_q9":"走路時跨出大步",
  "patient_hip_function_q9_a1":"無",
  "patient_hip_function_q9_a2":"輕微",
  "patient_hip_function_q9_a3":"中度",
  "patient_hip_function_q9_a4":"嚴重",
  "patient_hip_function_q9_a5":"極嚴重 / 做不到",
  "patient_hip_function_q10":"彎腰去摸地板/撿東西",
  "patient_hip_function_q10_a1":"無",
  "patient_hip_function_q10_a2":"輕微",
  "patient_hip_function_q10_a3":"中度",
  "patient_hip_function_q10_a4":"嚴重",
  "patient_hip_function_q10_a5":"極嚴重 / 做不到",
  "patient_hip_function_q11":"在平坦的表面上步行",
  "patient_hip_function_q11_a1":"無",
  "patient_hip_function_q11_a2":"輕微",
  "patient_hip_function_q11_a3":"中度",
  "patient_hip_function_q11_a4":"嚴重",
  "patient_hip_function_q11_a5":"極嚴重 / 做不到",
  "patient_hip_function_q12":"在不平坦的表面上步行",
  "patient_hip_function_q12_a1":"無",
  "patient_hip_function_q12_a2":"輕微",
  "patient_hip_function_q12_a3":"中度",
  "patient_hip_function_q12_a4":"嚴重",
  "patient_hip_function_q12_a5":"極嚴重 / 做不到",
  "patient_hip_function_q13":"長途步行",
  "patient_hip_function_q13_a1":"無",
  "patient_hip_function_q13_a2":"輕微",
  "patient_hip_function_q13_a3":"中度",
  "patient_hip_function_q13_a4":"嚴重",
  "patient_hip_function_q13_a5":"極嚴重 / 做不到",
  "patient_hip_function_q14":"運動步行",
  "patient_hip_function_q14_a1":"無",
  "patient_hip_function_q14_a2":"輕微",
  "patient_hip_function_q14_a3":"中度",
  "patient_hip_function_q14_a4":"嚴重",
  "patient_hip_function_q14_a5":"極嚴重 / 做不到",
  "patient_hip_function_q15":"走上陡峭的山坡",
  "patient_hip_function_q15_a1":"無",
  "patient_hip_function_q15_a2":"輕微",
  "patient_hip_function_q15_a3":"中度",
  "patient_hip_function_q15_a4":"嚴重",
  "patient_hip_function_q15_a5":"極嚴重 / 做不到",
  "patient_hip_function_q16":"走下陡峭的山坡",
  "patient_hip_function_q16_a1":"無",
  "patient_hip_function_q16_a2":"輕微",
  "patient_hip_function_q16_a3":"中度",
  "patient_hip_function_q16_a4":"嚴重",
  "patient_hip_function_q16_a5":"極嚴重 / 做不到",
  "patient_hip_function_q17":"開始步行",
  "patient_hip_function_q17_a1":"無",
  "patient_hip_function_q17_a2":"輕微",
  "patient_hip_function_q17_a3":"中度",
  "patient_hip_function_q17_a4":"嚴重",
  "patient_hip_function_q17_a5":"極嚴重 / 做不到",
  "patient_hip_function_q18":"步行大約十分鐘",
  "patient_hip_function_q18_a1":"無",
  "patient_hip_function_q18_a2":"輕微",
  "patient_hip_function_q18_a3":"中度",
  "patient_hip_function_q18_a4":"嚴重",
  "patient_hip_function_q18_a5":"極嚴重 / 做不到",
  "patient_hip_function_q19":"步行超過十五分鐘",
  "patient_hip_function_q19_a1":"無",
  "patient_hip_function_q19_a2":"輕微",
  "patient_hip_function_q19_a3":"中度",
  "patient_hip_function_q19_a4":"嚴重",
  "patient_hip_function_q19_a5":"極嚴重 / 做不到",
  "patient_hip_function_q20":"走上走下人行道",
  "patient_hip_function_q20_a1":"無",
  "patient_hip_function_q20_a2":"輕微",
  "patient_hip_function_q20_a3":"中度",
  "patient_hip_function_q20_a4":"嚴重",
  "patient_hip_function_q20_a5":"極嚴重 / 做不到",
  "patient_hip_function_q21":"上下車或交通工具",
  "patient_hip_function_q21_a1":"無",
  "patient_hip_function_q21_a2":"輕微",
  "patient_hip_function_q21_a3":"中度",
  "patient_hip_function_q21_a4":"嚴重",
  "patient_hip_function_q21_a5":"極嚴重 / 做不到",
  "patient_hip_function_q22":"去購物",
  "patient_hip_function_q22_a1":"無",
  "patient_hip_function_q22_a2":"輕微",
  "patient_hip_function_q22_a3":"中度",
  "patient_hip_function_q22_a4":"嚴重",
  "patient_hip_function_q22_a5":"極嚴重 / 做不到",
  "patient_hip_function_q23":"穿上襪子/絲襪",
  "patient_hip_function_q23_a1":"無",
  "patient_hip_function_q23_a2":"輕微",
  "patient_hip_function_q23_a3":"中度",
  "patient_hip_function_q23_a4":"嚴重",
  "patient_hip_function_q23_a5":"極嚴重 / 做不到",
  "patient_hip_function_q24":"脫下襪子/絲襪",
  "patient_hip_function_q24_a1":"無",
  "patient_hip_function_q24_a2":"輕微",
  "patient_hip_function_q24_a3":"中度",
  "patient_hip_function_q24_a4":"嚴重",
  "patient_hip_function_q24_a5":"極嚴重 / 做不到",
  "patient_hip_function_q25":"躺在床上 （翻身，保持臀部姿勢）",
  "patient_hip_function_q25_a1":"無",
  "patient_hip_function_q25_a2":"輕微",
  "patient_hip_function_q25_a3":"中度",
  "patient_hip_function_q25_a4":"嚴重",
  "patient_hip_function_q25_a5":"極嚴重 / 做不到",
  "patient_hip_function_q26":"床上翻身",
  "patient_hip_function_q26_a1":"無",
  "patient_hip_function_q26_a2":"輕微",
  "patient_hip_function_q26_a3":"中度",
  "patient_hip_function_q26_a4":"嚴重",
  "patient_hip_function_q26_a5":"極嚴重 / 做不到",
  "patient_hip_function_q27":"起床",
  "patient_hip_function_q27_a1":"無",
  "patient_hip_function_q27_a2":"輕微",
  "patient_hip_function_q27_a3":"中度",
  "patient_hip_function_q27_a4":"嚴重",
  "patient_hip_function_q27_a5":"極嚴重 / 做不到",
  "patient_hip_function_q28":"進出浴缸",
  "patient_hip_function_q28_a1":"無",
  "patient_hip_function_q28_a2":"輕微",
  "patient_hip_function_q28_a3":"中度",
  "patient_hip_function_q28_a4":"嚴重",
  "patient_hip_function_q28_a5":"極嚴重 / 做不到",
  "patient_hip_function_q29":"上下馬桶",
  "patient_hip_function_q29_a1":"無",
  "patient_hip_function_q29_a2":"輕微",
  "patient_hip_function_q29_a3":"中度",
  "patient_hip_function_q29_a4":"嚴重",
  "patient_hip_function_q29_a5":"極嚴重 / 做不到",
  "patient_hip_function_q30":"清洗擦乾身體（全身）",
  "patient_hip_function_q30_a1":"無",
  "patient_hip_function_q30_a2":"輕微",
  "patient_hip_function_q30_a3":"中度",
  "patient_hip_function_q30_a4":"嚴重",
  "patient_hip_function_q30_a5":"極嚴重 / 做不到",
  "patient_hip_function_q31":"輕到中度的工作 (站立，步行）",
  "patient_hip_function_q31_a1":"無",
  "patient_hip_function_q31_a2":"輕微",
  "patient_hip_function_q31_a3":"中度",
  "patient_hip_function_q31_a4":"嚴重",
  "patient_hip_function_q31_a5":"極嚴重 / 做不到",
  "patient_hip_function_q32":"繁重家務 （搬運重箱子，擦地板等等）",
  "patient_hip_function_q32_a1":"無",
  "patient_hip_function_q32_a2":"輕微",
  "patient_hip_function_q32_a3":"中度",
  "patient_hip_function_q32_a4":"嚴重",
  "patient_hip_function_q32_a5":"極嚴重 / 做不到",
  "patient_hip_function_q33":"輕微家務 （煮飯，打掃除塵等等）",
  "patient_hip_function_q33_a1":"無",
  "patient_hip_function_q33_a2":"輕微",
  "patient_hip_function_q33_a3":"中度",
  "patient_hip_function_q33_a4":"嚴重",
  "patient_hip_function_q33_a5":"極嚴重 / 做不到",
  "patient_hip_function_q34":"抱起或搬運小孩",
  "patient_hip_function_q34_a1":"無",
  "patient_hip_function_q34_a2":"輕微",
  "patient_hip_function_q34_a3":"中度",
  "patient_hip_function_q34_a4":"嚴重",
  "patient_hip_function_q34_a5":"極嚴重 / 做不到",
  "patient_hip_function_q35":"蹲著",
  "patient_hip_function_q35_a1":"無",
  "patient_hip_function_q35_a2":"輕微",
  "patient_hip_function_q35_a3":"中度",
  "patient_hip_function_q35_a4":"嚴重",
  "patient_hip_function_q35_a5":"極嚴重 / 做不到",
  "patient_hip_function_q36":"運動慢跑",
  "patient_hip_function_q36_a1":"無",
  "patient_hip_function_q36_a2":"輕微",
  "patient_hip_function_q36_a3":"中度",
  "patient_hip_function_q36_a4":"嚴重",
  "patient_hip_function_q36_a5":"極嚴重 / 做不到",
  "patient_hip_function_q37":"跑步",
  "patient_hip_function_q37_a1":"無",
  "patient_hip_function_q37_a2":"輕微",
  "patient_hip_function_q37_a3":"中度",
  "patient_hip_function_q37_a4":"嚴重",
  "patient_hip_function_q37_a5":"極嚴重 / 做不到",
  "patient_hip_function_q38":"在負重的腿上進行扭轉或旋轉",
  "patient_hip_function_q38_a1":"無",
  "patient_hip_function_q38_a2":"輕微",
  "patient_hip_function_q38_a3":"中度",
  "patient_hip_function_q38_a4":"嚴重",
  "patient_hip_function_q38_a5":"極嚴重 / 做不到",
  "patient_hip_function_q39":"性活動",
  "patient_hip_function_q39_a1":"無",
  "patient_hip_function_q39_a2":"輕微",
  "patient_hip_function_q39_a3":"中度",
  "patient_hip_function_q39_a4":"嚴重",
  "patient_hip_function_q39_a5":"極嚴重 / 做不到",
  "patient_hip_function_q39_not_relevant":"與我無關",
  "patient_hip_function_q40":"涉及追逐或切入動作的高要求運動 （足球，籃球，網球，有氧運動）",
  "patient_hip_function_q40_a1":"無",
  "patient_hip_function_q40_a2":"輕微",
  "patient_hip_function_q40_a3":"中度",
  "patient_hip_function_q40_a4":"嚴重",
  "patient_hip_function_q40_a5":"極嚴重 / 做不到",
  "patient_hip_function_q41":"低門檻運動 （高爾夫球，保齡球）",
  "patient_hip_function_q41_a1":"無",
  "patient_hip_function_q41_a2":"輕微",
  "patient_hip_function_q41_a3":"中度",
  "patient_hip_function_q41_a4":"嚴重",
  "patient_hip_function_q41_a5":"極嚴重 / 做不到",
  "patient_hip_function_q42":"休閒活動",
  "patient_hip_function_q42_a1":"無",
  "patient_hip_function_q42_a2":"輕微",
  "patient_hip_function_q42_a3":"中度",
  "patient_hip_function_q42_a4":"嚴重",
  "patient_hip_function_q42_a5":"極嚴重 / 做不到",
  "patient_hip_function_q43":"您今天的臀部與有多正常？ （0% 到 100%，100% 為正常）",
  "patient_hip_function_q44":"請標明您可以參與的最高程度的活動",
  "patient_hip_function_q44_q1":"臀部出問題之前",
  "patient_hip_function_q44_q1_a1":"競技的",
  "patient_hip_function_q44_q1_a2":"休閒活動/體適能",
  "patient_hip_function_q44_q1_a3":"沒有運動，只有工作",
  "patient_hip_function_q44_q1_a4":"只做日常生活活動",
  "patient_hip_function_q44_q1_a5":"因健康問題請病假或得到傷殘年金",
  "patient_hip_function_q44_q1_a1_o1":"世界級、奧林匹克級或職業競技水準",
  "patient_hip_function_q44_q1_a1_o2":"國家或國際級別的競技運動，例如田徑、球拍/球類運動、體操、賽艇、滑雪或武術",
  "patient_hip_function_q44_q1_a1_o3":"國內或國際級別的競技運動，如水上運動、自行車、曲棍球、冰壺或騎馬/競技表演",
  "patient_hip_function_q44_q1_a2_o1":"每周至少 5 次的休閒運動，例如跑步、球/球拍運動、舉重訓練、冰壺、划船、高爾夫、瑜伽或體操",
  "patient_hip_function_q44_q1_a2_o2":"每周至少 5 次的休閒運動，例如游泳、水上運動、滑雪、曲棍球、滑旱冰、騎自行車、騎馬或登山",
  "patient_hip_function_q44_q1_a2_o3":"每周至少進行兩次休閒運動",
  "patient_hip_function_q44_q1_a2_o4":"每週一次以下的休閒運動",
  "patient_hip_function_q44_q1_a2_o5":"每月少於一次的休閒運動",
  "patient_hip_function_q44_q1_a3_o1":"建築等重體力勞動",
  "patient_hip_function_q44_q1_a3_o2":"中等重體力勞動，例如卡車駕駛",
  "patient_hip_function_q44_q1_a3_o3":"輕體力勞動比如護理等",
  "patient_hip_function_q44_q1_a3_o4":"久坐的工作，例如秘書或基於電腦的工作",
  "patient_hip_function_q44_q1_a4_o1":"日常活動，例如園藝、爬多段樓梯、搬運貨物、推/拉貨物、遲到時跑步的能力",
  "patient_hip_function_q44_q1_a4_o2":"日常活動，如打掃房屋、爬1或2層樓梯，或攜帶輕物品行走",
  "patient_hip_function_q44_q1_a4_o3":"日常活動受限（例如不爬樓梯、無法搬運物品）",
  "patient_hip_function_q44_q2":"當前狀態",
  "patient_hip_function_q44_q2_a1":"競技的",
  "patient_hip_function_q44_q2_a2":"休閒活動/體適能",
  "patient_hip_function_q44_q2_a3":"沒有運動，只有工作",
  "patient_hip_function_q44_q2_a4":"只做日常生活活動",
  "patient_hip_function_q44_q2_a5":"因健康問題請病假或得到傷殘年金",
  "patient_hip_function_q44_q2_a1_o1":"世界級、奧林匹克級或職業競技運動",
  "patient_hip_function_q44_q2_a1_o2":"國家或國際級別的競技運動，例如田徑、球拍/球類運動、體操、賽艇、滑雪或武術",
  "patient_hip_function_q44_q2_a1_o3":"國內或國際級別的競技運動，如水上運動、自行車、曲棍球、冰壺或騎馬/競技表演",
  "patient_hip_function_q44_q2_a2_o1":"每周至少 5 次的休閒運動，例如跑步、球/球拍運動、舉重訓練、冰壺、划船、高爾夫、瑜伽或體操",
  "patient_hip_function_q44_q2_a2_o2":"每周至少 5 次的休閒運動，例如游泳、水上運動、滑雪、曲棍球、滑旱冰、騎自行車、騎馬或登山",
  "patient_hip_function_q44_q2_a2_o3":"每周至少進行兩次休閒運動",
  "patient_hip_function_q44_q2_a2_o4":"每週一次以下的休閒運動",
  "patient_hip_function_q44_q2_a2_o5":"每月少於一次的休閒運動",
  "patient_hip_function_q44_q2_a3_o1":"建築等重體力勞動",
  "patient_hip_function_q44_q2_a3_o2":"中等重體力勞動，例如卡車駕駛",
  "patient_hip_function_q44_q2_a3_o3":"輕體力勞動比如護理等",
  "patient_hip_function_q44_q2_a3_o4":"久坐的工作，例如秘書或基於電腦的工作",
  "patient_hip_function_q44_q2_a4_o1":"日常活動，例如園藝、爬多段樓梯、搬運貨物、推/拉貨物、遲到時跑步的能力",
  "patient_hip_function_q44_q2_a4_o2":"日常活動，如打掃房屋、爬1或2層樓梯，或攜帶輕物品行走",
  "patient_hip_function_q44_q2_a4_o3":"日常活動受限（例如不爬樓梯、無法搬運物品）",
  "patient_hip_quality_of_life":"生活品質",
  "patient_hip_quality_of_life_instruction":"以下問題會詢問您可能對髖關節問題感到的社交、情感和生活方式方面的擔憂。請想一想您大部分時間對臀部的感覺如何。",
  "patient_hip_quality_of_life_q1":"您多常意識到自己的臀部問題？",
  "patient_hip_quality_of_life_q1_a1":"從未",
  "patient_hip_quality_of_life_q1_a2":"每月",
  "patient_hip_quality_of_life_q1_a3":"每週",
  "patient_hip_quality_of_life_q1_a4":"每天",
  "patient_hip_quality_of_life_q1_a5":"持續不斷",
  "patient_hip_quality_of_life_q2":"您有刻意改變可能傷害臀部的生活型態嗎？",
  "patient_hip_quality_of_life_q2_a1":"一點也不",
  "patient_hip_quality_of_life_q2_a2":"有一點",
  "patient_hip_quality_of_life_q2_a3":"中度",
  "patient_hip_quality_of_life_q2_a4":"嚴重",
  "patient_hip_quality_of_life_q2_a5":"極度",
  "patient_hip_quality_of_life_q3":"您對自己維持理想體能水準的能力有多關心？",
  "patient_hip_quality_of_life_q3_a1":"不關心",
  "patient_hip_quality_of_life_q3_a2":"極度關心",
  "patient_hip_quality_of_life_q4":"您對自己的臀部缺乏信心造成了多少困擾？",
  "patient_hip_quality_of_life_q4_a1":"一點也不",
  "patient_hip_quality_of_life_q4_a2":"有一點",
  "patient_hip_quality_of_life_q4_a3":"中度",
  "patient_hip_quality_of_life_q4_a4":"嚴重",
  "patient_hip_quality_of_life_q4_a5":"極度",
  "patient_hip_quality_of_life_q5":"您的臀部問題對您造成多大的干擾？",
  "patient_hip_quality_of_life_q5_a1":"沒有干擾",
  "patient_hip_quality_of_life_q5_a2":"極度干擾",
  "patient_hip_quality_of_life_q6":"一般來說，您的臀部承受了多少困難？",
  "patient_hip_quality_of_life_q6_a1":"無",
  "patient_hip_quality_of_life_q6_a2":"輕微",
  "patient_hip_quality_of_life_q6_a3":"中度",
  "patient_hip_quality_of_life_q6_a4":"嚴重",
  "patient_hip_quality_of_life_q6_a5":"極嚴重",
  "patient_hip_quality_of_life_q7":"您今天有多焦慮/沮喪？",
  "patient_hip_quality_of_life_q7_a1":"一點也不",
  "patient_hip_quality_of_life_q7_a2":"輕微",
  "patient_hip_quality_of_life_q7_a3":"中度",
  "patient_hip_quality_of_life_q7_a4":"嚴重",
  "patient_hip_quality_of_life_q7_a5":"極度",
  "patient_hip_quality_of_life_q8":"您今天的健康狀況如何？ （100 表示最佳健康狀況，0 表示您可以想像的最差健康狀況）",
  "patient_hip_quality_of_life_q8_a1":"最差健康",
  "patient_hip_quality_of_life_q8_a2":"最佳健康",
  "patient_hip_satisfaction":"滿意度",
  "patient_hip_satisfaction_instructions":"您對髖關節手術/治療的結果滿意嗎？",
  "patient_hip_satisfaction_instructions_1":"非常不滿意",
  "patient_hip_satisfaction_instructions_2":"有些不滿意",
  "patient_hip_satisfaction_instructions_3":"有些滿意",
  "patient_hip_satisfaction_instructions_4":"很滿意",
  "patient_hip_satisfaction_q1":"整體來說",
  "patient_hip_satisfaction_q1_not_applicable":"不適用",
  "patient_hip_satisfaction_q1_a1":"非常不滿意",
  "patient_hip_satisfaction_q1_a2":"有些不滿意",
  "patient_hip_satisfaction_q1_a3":"有些滿意",
  "patient_hip_satisfaction_q1_a4":"很滿意",
  "patient_hip_satisfaction_q2":"在改善您的疼痛方面？",
  "patient_hip_satisfaction_q2_not_applicable":"不適用",
  "patient_hip_satisfaction_q2_a1":"非常不滿意",
  "patient_hip_satisfaction_q2_a2":"有些不滿意",
  "patient_hip_satisfaction_q2_a3":"有些滿意",
  "patient_hip_satisfaction_q2_a4":"很滿意",
  "patient_hip_satisfaction_q3":"在提高您做家務或庭院工作的能力方面？",
  "patient_hip_satisfaction_q3_not_applicable":"不適用",
  "patient_hip_satisfaction_q3_a1":"非常不滿意",
  "patient_hip_satisfaction_q3_a2":"有些不滿意",
  "patient_hip_satisfaction_q3_a3":"有些滿意",
  "patient_hip_satisfaction_q3_a4":"很滿意",
  "patient_hip_satisfaction_q4":"在提高您進行娛樂/休閒活動的能力方面？",
  "patient_hip_satisfaction_q4_not_applicable":"不適用",
  "patient_hip_satisfaction_q4_a1":"非常不滿意",
  "patient_hip_satisfaction_q4_a2":"有些不滿意",
  "patient_hip_satisfaction_q4_a3":"有些滿意",
  "patient_hip_satisfaction_q4_a4":"很滿意",
  "patient_hip_complete":"問卷結束",
  "patient_hip_complete_text1":"感謝您抽出寶貴時間填寫此問卷。",
  "patient_hip_complete_text2":"請點擊提交完成，並查看您的分數。",
  "patient_hip_complete_text3":"您的回答將被保密，任何報告都只會使用去除身份識別的資訊",
  "patient_hip_results":"結果",
  "patient_hip_results_text1":"以下是您的疼痛和髖關節功能評分，這些評分是根據您的調查問卷計算得出的。",
  "patient_hip_results_pain":"疼痛",
  "patient_hip_results_function":"功能",
  "patient_hip_results_health_perception":"整體健康認知",
  "patient_hip_results_self_evaluation":"髖關節評分自我評估",
  "patient_hip_results_text2":"您的醫生很快就會與您聯繫。",
  "":"",
  "patient_adult_knee_pain":"疼痛",
  "patient_adult_knee_pain_q1":"在沒有明顯膝蓋疼痛的情況下，您可以進行的最高活動水平如何？",
  "patient_adult_knee_pain_q1_a1":"非常劇烈的活動，例如籃球或足球中的跳躍或旋轉",
  "patient_adult_knee_pain_q1_a2":"劇烈活動，如重體力勞動、滑雪或打網球",
  "patient_adult_knee_pain_q1_a3":"中度的活動，例如中度的體力勞動、跑步或慢跑",
  "patient_adult_knee_pain_q1_a4":"輕度活動，如散步、做家務或庭院工作",
  "patient_adult_knee_pain_q1_a5":"由於膝蓋疼痛而無法進行上述任何活動",
  "patient_adult_knee_pain_q2":"您多常感覺疼痛？",
  "patient_adult_knee_pain_q2_a1":"從未",
  "patient_adult_knee_pain_q2_a2":"每月",
  "patient_adult_knee_pain_q2_a3":"每週",
  "patient_adult_knee_pain_q2_a4":"每天",
  "patient_adult_knee_pain_q2_a5":"持續",
  "patient_adult_knee_pain_q3":"以下哪項陳述最能描述您的痛苦？",
  "patient_adult_knee_pain_q3_a1":"無疼痛，膝關節正常，表現 100%",
  "patient_adult_knee_pain_q3_a2":"劇烈運動和繁重工作時偶爾疼痛，膝蓋不完全正常，有一些限制，但輕微且可以忍受",
  "patient_adult_knee_pain_q3_a3":"疼痛通常由運動、輕度娛樂活動或中等強度的工作引起。步行超過2公里、站立或輕體力勞動時偶爾會發生",
  "patient_adult_knee_pain_q3_a4":"輕度休閒運動或中度工作活動、跑步或重體力勞動、劇烈運動時偶爾疼痛",
  "patient_adult_knee_pain_q3_a5":"簡單活動（例如步行 <2 公里）引起嚴重疼痛的問題，可通過休息緩解。無法進行運動。",
  "patient_adult_knee_pain_q3_a6":"持續",
  "patient_adult_knee_pain_q4":"如果您感到疼痛，疼痛有多嚴重？",
  "patient_adult_knee_pain_q4_a1":"無",
  "patient_adult_knee_pain_q4_a2":"很輕微",
  "patient_adult_knee_pain_q4_a3":"輕微",
  "patient_adult_knee_pain_q4_a4":"中度",
  "patient_adult_knee_pain_q4_a5":"嚴重",
  "patient_adult_knee_pain_q5":"您的膝蓋疼痛對您的日常工作（包括家務）有多大影響？",
  "patient_adult_knee_pain_q5_a1":"一點也不",
  "patient_adult_knee_pain_q5_a2":"一點點",
  "patient_adult_knee_pain_q5_a3":"中度",
  "patient_adult_knee_pain_q5_a4":"嚴重",
  "patient_adult_knee_pain_q5_a5":"極度",
  "patient_adult_knee_pain_instruction":"您在以下活動中經歷過多少程度的膝蓋疼痛：",
  "patient_adult_knee_pain_instruction_0":"無",
  "patient_adult_knee_pain_instruction_1":"輕微",
  "patient_adult_knee_pain_instruction_2":"中度",
  "patient_adult_knee_pain_instruction_3":"嚴重",
  "patient_adult_knee_pain_instruction_4":"極度",
  "patient_adult_knee_pain_q6":"扭轉/旋轉膝蓋",
  "patient_adult_knee_pain_q6_a1":"無",
  "patient_adult_knee_pain_q6_a2":"輕微",
  "patient_adult_knee_pain_q6_a3":"中度",
  "patient_adult_knee_pain_q6_a4":"嚴重",
  "patient_adult_knee_pain_q6_a5":"極度",
  "patient_adult_knee_pain_q7":"膝蓋完全伸直",
  "patient_adult_knee_pain_q7_a1":"無",
  "patient_adult_knee_pain_q7_a2":"輕微",
  "patient_adult_knee_pain_q7_a3":"中度",
  "patient_adult_knee_pain_q7_a4":"嚴重",
  "patient_adult_knee_pain_q7_a5":"極度",
  "patient_adult_knee_pain_q8":"屈膝",
  "patient_adult_knee_pain_q8_a1":"無",
  "patient_adult_knee_pain_q8_a2":"輕微",
  "patient_adult_knee_pain_q8_a3":"中度",
  "patient_adult_knee_pain_q8_a4":"嚴重",
  "patient_adult_knee_pain_q8_a5":"極度",
  "patient_adult_knee_pain_q9":"在平坦的表面步行",
  "patient_adult_knee_pain_q9_a1":"無",
  "patient_adult_knee_pain_q9_a2":"輕微",
  "patient_adult_knee_pain_q9_a3":"中度",
  "patient_adult_knee_pain_q9_a4":"嚴重",
  "patient_adult_knee_pain_q9_a5":"極度",
  "patient_adult_knee_pain_q10":"在膝蓋疼痛變得嚴重之前，您可以行走多長時間？ （無論有沒有手杖）",
  "patient_adult_knee_pain_q10_a1":"超過三十分鐘",
  "patient_adult_knee_pain_q10_a2":"16-30 分鐘",
  "patient_adult_knee_pain_q10_a3":"5-15 分鐘",
  "patient_adult_knee_pain_q10_a4":"家內而已",
  "patient_adult_knee_pain_q10_a5":"無法步行/步行的時候嚴重疼痛",
  "patient_adult_knee_pain_q11":"上下樓梯",
  "patient_adult_knee_pain_q11_a1":"無",
  "patient_adult_knee_pain_q11_a2":"輕微",
  "patient_adult_knee_pain_q11_a3":"中度",
  "patient_adult_knee_pain_q11_a4":"嚴重",
  "patient_adult_knee_pain_q11_a5":"極度",
  "patient_adult_knee_pain_q12":"晚間在床上",
  "patient_adult_knee_pain_q12_a1":"無",
  "patient_adult_knee_pain_q12_a2":"輕微",
  "patient_adult_knee_pain_q12_a3":"中度",
  "patient_adult_knee_pain_q12_a4":"嚴重",
  "patient_adult_knee_pain_q12_a5":"極度",
  "patient_adult_knee_pain_q13":"您多常在夜間床上受膝蓋疼痛所困擾？",
  "patient_adult_knee_pain_q13_a1":"否",
  "patient_adult_knee_pain_q13_a2":"一個或兩個晚上",
  "patient_adult_knee_pain_q13_a3":"有幾晚",
  "patient_adult_knee_pain_q13_a4":"大多晚上",
  "patient_adult_knee_pain_q13_a5":"每天晚上",
  "patient_adult_knee_pain_q14":"坐下或躺臥",
  "patient_adult_knee_pain_q14_a1":"無",
  "patient_adult_knee_pain_q14_a2":"輕微",
  "patient_adult_knee_pain_q14_a3":"中度",
  "patient_adult_knee_pain_q14_a4":"嚴重",
  "patient_adult_knee_pain_q14_a5":"極度",
  "patient_adult_knee_pain_q15":"站立",
  "patient_adult_knee_pain_q15_a1":"無",
  "patient_adult_knee_pain_q15_a2":"輕微",
  "patient_adult_knee_pain_q15_a3":"中度",
  "patient_adult_knee_pain_q15_a4":"嚴重",
  "patient_adult_knee_pain_q15_a5":"極度",
  "patient_adult_knee_pain_q16":"從坐的椅子起身站立",
  "patient_adult_knee_pain_q16_a1":"無",
  "patient_adult_knee_pain_q16_a2":"輕微",
  "patient_adult_knee_pain_q16_a3":"中度",
  "patient_adult_knee_pain_q16_a4":"嚴重",
  "patient_adult_knee_pain_q16_a5":"極度",
  "patient_adult_knee_other_symptoms":"其他症狀",
  "patient_adult_knee_other_symptoms_instruction1":"請選擇最符合您膝蓋症狀的答案。",
  "patient_adult_knee_other_symptoms_instruction2":"如果您不確定如何回答問題，請盡可能給出最佳答案。",
  "patient_adult_knee_other_symptoms_instruction3":"您是否經驗到以下情況：",
  "patient_adult_knee_other_symptoms_instruction_0":"無",
  "patient_adult_knee_other_symptoms_instruction_1":"輕微",
  "patient_adult_knee_other_symptoms_instruction_2":"中度",
  "patient_adult_knee_other_symptoms_instruction_3":"嚴重",
  "patient_adult_knee_other_symptoms_instruction_4":"極度",
  "patient_adult_knee_other_symptoms_q1":"腫脹",
  "patient_adult_knee_other_symptoms_q1_a1":"從未",
  "patient_adult_knee_other_symptoms_q1_a2":"偶爾",
  "patient_adult_knee_other_symptoms_q1_a3":"有時候",
  "patient_adult_knee_other_symptoms_q1_a4":"時常",
  "patient_adult_knee_other_symptoms_q1_a5":"持續",
  "patient_adult_knee_other_symptoms_q2":"膝蓋發出摩擦聲、咔嗒聲或任何其他類型的噪音",
  "patient_adult_knee_other_symptoms_q2_a1":"從未",
  "patient_adult_knee_other_symptoms_q2_a2":"偶爾",
  "patient_adult_knee_other_symptoms_q2_a3":"有時候",
  "patient_adult_knee_other_symptoms_q2_a4":"時常",
  "patient_adult_knee_other_symptoms_q2_a5":"持續",
  "patient_adult_knee_other_symptoms_q3":"膝關節感覺卡住或動不了",
  "patient_adult_knee_other_symptoms_q3_a1":"從未",
  "patient_adult_knee_other_symptoms_q3_a2":"偶爾",
  "patient_adult_knee_other_symptoms_q3_a3":"有時候",
  "patient_adult_knee_other_symptoms_q3_a4":"時常",
  "patient_adult_knee_other_symptoms_q3_a5":"持續",
  "patient_adult_knee_other_symptoms_q4":"跛行",
  "patient_adult_knee_other_symptoms_q4_a1":"偶爾/不曾",
  "patient_adult_knee_other_symptoms_q4_a2":"有時候，或一剛開始的時候",
  "patient_adult_knee_other_symptoms_q4_a3":"時常，不知剛開始",
  "patient_adult_knee_other_symptoms_q4_a4":"大多的時候",
  "patient_adult_knee_other_symptoms_q4_a5":"一直",
  "patient_adult_knee_other_symptoms_q5":"在過去 4 週內或自膝蓋受傷以來，您的膝蓋僵硬或腫脹程度如何？",
  "patient_adult_knee_other_symptoms_q5_a1":"一點也不",
  "patient_adult_knee_other_symptoms_q5_a2":"輕微",
  "patient_adult_knee_other_symptoms_q5_a3":"中度",
  "patient_adult_knee_other_symptoms_q5_a4":"很",
  "patient_adult_knee_other_symptoms_q5_a5":"極度",
  "patient_adult_knee_other_symptoms_q6":"在膝蓋不出現明顯腫脹的情況下，您可以進行的最高活動水平是什麼？",
  "patient_adult_knee_other_symptoms_q6_a1":"非常劇烈的活動，例如籃球或足球中的跳躍或旋轉",
  "patient_adult_knee_other_symptoms_q6_a2":"劇烈活動，如重體力勞動、滑雪或打網球",
  "patient_adult_knee_other_symptoms_q6_a3":"中度的活動，例如中度的體力勞動、跑步或慢跑",
  "patient_adult_knee_other_symptoms_q6_a4":"輕度活動，如散步、做家務或庭院工作",
  "patient_adult_knee_other_symptoms_q6_a5":"由於膝蓋腫脹而無法進行上述任何活動",
  "patient_adult_knee_other_symptoms_q7":"您的關節僵硬有多嚴重：",
  "patient_adult_knee_other_symptoms_q7_0":"無",
  "patient_adult_knee_other_symptoms_q7_1":"輕微",
  "patient_adult_knee_other_symptoms_q7_2":"中度",
  "patient_adult_knee_other_symptoms_q7_3":"嚴重",
  "patient_adult_knee_other_symptoms_q7_4":"極度",
  "patient_adult_knee_other_symptoms_q7_q1":"早上第一次醒來後？",
  "patient_adult_knee_other_symptoms_q7_q1_a1":"無",
  "patient_adult_knee_other_symptoms_q7_q1_a2":"輕微",
  "patient_adult_knee_other_symptoms_q7_q1_a3":"中度",
  "patient_adult_knee_other_symptoms_q7_q1_a4":"嚴重",
  "patient_adult_knee_other_symptoms_q7_q1_a5":"極度",
  "patient_adult_knee_other_symptoms_q7_q2":"當天晚些時候坐著、躺著或休息後？",
  "patient_adult_knee_other_symptoms_q7_q2_a1":"無",
  "patient_adult_knee_other_symptoms_q7_q2_a2":"輕微",
  "patient_adult_knee_other_symptoms_q7_q2_a3":"中度",
  "patient_adult_knee_other_symptoms_q7_q2_a4":"嚴重",
  "patient_adult_knee_other_symptoms_q7_q2_a5":"極度",
  "patient_adult_knee_other_symptoms_q8":"在膝蓋沒有明顯撐不住的限度下，您可以進行的最高活動水平是什麼？",
  "patient_adult_knee_other_symptoms_q8_a1":"沒有撐不住",
  "patient_adult_knee_other_symptoms_q8_a2":"偶爾因劇烈運動或繁重工作而撐不住，可以參加所有運動，但需要一些防護或限制。偶爾在運動或其他劇烈運動期間撐不住。",
  "patient_adult_knee_other_symptoms_q8_a3":"經常在運動或其他劇烈運動期間發生。偶爾進行輕度運動或中度的工作。能夠用其他身體部位做出替代動作，但劇烈活動、運動或重體力勞動受限，不能突然進行切入動作或扭轉",
  "patient_adult_knee_other_symptoms_q8_a4":"膝蓋撐不住限制了運動或中度的工作，在步行或輕度工作中很少發生（每年約 3 次）。日常活動中偶爾會發生",
  "patient_adult_knee_other_symptoms_q8_a5":"簡單的步行活動和輕鬆的工作，膝蓋都會撐不住。每月發生一次，需要保護。經常在日常活動中會發生",
  "patient_adult_knee_other_symptoms_q8_a6":"簡單的行走活動導致嚴重問題，每一步膝蓋都撐不住，行走時無法轉身或扭轉",
  "patient_adult_knee_other_symptoms_q9":"您走路時使用任何支撐嗎？",
  "patient_adult_knee_other_symptoms_q9_a1":"無",
  "patient_adult_knee_other_symptoms_q9_a2":"棍子/拐杖",
  "patient_adult_knee_other_symptoms_q9_a3":"無法支撐體重",
  "patient_adult_knee_function":"功能",
  "patient_adult_knee_function_instruction":"請標明您在上週進行以下活動時因膝蓋問題而遇到的困難程度",
  "patient_adult_knee_function_instruction_0":"沒有困難",
  "patient_adult_knee_function_instruction_1":"稍微困難",
  "patient_adult_knee_function_instruction_2":"中度困難",
  "patient_adult_knee_function_instruction_3":"極度困難",
  "patient_adult_knee_function_instruction_4":"無法辦到",
  "patient_adult_knee_function_note":"註：如果您不做某項活動，請想像一下如果您必須嘗試，您的膝蓋會有什麼感覺。",
  "patient_adult_knee_function_q1":"上樓梯",
  "patient_adult_knee_function_q1_a1":"沒有困難",
  "patient_adult_knee_function_q1_a2":"稍微困難",
  "patient_adult_knee_function_q1_a3":"中度困難",
  "patient_adult_knee_function_q1_a4":"極度困難",
  "patient_adult_knee_function_q1_a5":"無法辦到",
  "patient_adult_knee_function_q2":"下樓梯",
  "patient_adult_knee_function_q2_a1":"沒有困難",
  "patient_adult_knee_function_q2_a2":"稍微困難",
  "patient_adult_knee_function_q2_a3":"中度困難",
  "patient_adult_knee_function_q2_a4":"極度困難",
  "patient_adult_knee_function_q2_a5":"無法辦到",
  "patient_adult_knee_function_q3":"用膝蓋前面跪著",
  "patient_adult_knee_function_q3_a1":"沒有困難",
  "patient_adult_knee_function_q3_a2":"稍微困難",
  "patient_adult_knee_function_q3_a3":"中度困難",
  "patient_adult_knee_function_q3_a4":"極度困難",
  "patient_adult_knee_function_q3_a5":"無法辦到",
  "patient_adult_knee_function_q4":"把膝蓋伸直",
  "patient_adult_knee_function_q4_a1":"沒有困難",
  "patient_adult_knee_function_q4_a2":"稍微困難",
  "patient_adult_knee_function_q4_a3":"中度困難",
  "patient_adult_knee_function_q4_a4":"極度困難",
  "patient_adult_knee_function_q4_a5":"無法辦到",
  "patient_adult_knee_function_q5":"屈膝",
  "patient_adult_knee_function_q5_a1":"沒有困難",
  "patient_adult_knee_function_q5_a2":"稍微困難",
  "patient_adult_knee_function_q5_a3":"中度困難",
  "patient_adult_knee_function_q5_a4":"極度困難",
  "patient_adult_knee_function_q5_a5":"無法辦到",
  "patient_adult_knee_function_q6":"蹲下",
  "patient_adult_knee_function_q6_a1":"沒有困難",
  "patient_adult_knee_function_q6_a2":"稍微困難",
  "patient_adult_knee_function_q6_a3":"中度困難",
  "patient_adult_knee_function_q6_a4":"極度困難",
  "patient_adult_knee_function_q6_a5":"無法辦到",
  "patient_adult_knee_function_q7":"坐著的時候屈膝",
  "patient_adult_knee_function_q7_a1":"沒有困難",
  "patient_adult_knee_function_q7_a2":"稍微困難",
  "patient_adult_knee_function_q7_a3":"中度困難",
  "patient_adult_knee_function_q7_a4":"極度困難",
  "patient_adult_knee_function_q7_a5":"無法辦到",
  "patient_adult_knee_function_q8":"從坐著的椅子上起來",
  "patient_adult_knee_function_q8_a1":"沒有困難",
  "patient_adult_knee_function_q8_a2":"稍微困難",
  "patient_adult_knee_function_q8_a3":"中度困難",
  "patient_adult_knee_function_q8_a4":"極度困難",
  "patient_adult_knee_function_q8_a5":"無法辦到",
  "patient_adult_knee_function_q9":"從躺著的床上起來",
  "patient_adult_knee_function_q9_a1":"沒有困難",
  "patient_adult_knee_function_q9_a2":"稍微困難",
  "patient_adult_knee_function_q9_a3":"中度困難",
  "patient_adult_knee_function_q9_a4":"極度困難",
  "patient_adult_knee_function_q9_a5":"無法辦到",
  "patient_adult_knee_function_q10":"躺在床上 （翻身，保持膝蓋姿勢）",
  "patient_adult_knee_function_q10_a1":"沒有困難",
  "patient_adult_knee_function_q10_a2":"稍微困難",
  "patient_adult_knee_function_q10_a3":"中度困難",
  "patient_adult_knee_function_q10_a4":"極度困難",
  "patient_adult_knee_function_q10_a5":"無法辦到",
  "patient_adult_knee_function_q11":"彎腰去摸地板/撿東西",
  "patient_adult_knee_function_q11_a1":"沒有困難",
  "patient_adult_knee_function_q11_a2":"稍微困難",
  "patient_adult_knee_function_q11_a3":"中度困難",
  "patient_adult_knee_function_q11_a4":"極度困難",
  "patient_adult_knee_function_q11_a5":"無法辦到",
  "patient_adult_knee_function_q12":"穿上襪子/絲襪",
  "patient_adult_knee_function_q12_a1":"沒有困難",
  "patient_adult_knee_function_q12_a2":"稍微困難",
  "patient_adult_knee_function_q12_a3":"中度困難",
  "patient_adult_knee_function_q12_a4":"極度困難",
  "patient_adult_knee_function_q12_a5":"無法辦到",
  "patient_adult_knee_function_q13":"脫下襪子/絲襪",
  "patient_adult_knee_function_q13_a1":"沒有困難",
  "patient_adult_knee_function_q13_a2":"稍微困難",
  "patient_adult_knee_function_q13_a3":"中度困難",
  "patient_adult_knee_function_q13_a4":"極度困難",
  "patient_adult_knee_function_q13_a5":"無法辦到",
  "patient_adult_knee_function_q14":"站立",
  "patient_adult_knee_function_q14_a1":"沒有困難",
  "patient_adult_knee_function_q14_a2":"稍微困難",
  "patient_adult_knee_function_q14_a3":"中度困難",
  "patient_adult_knee_function_q14_a4":"極度困難",
  "patient_adult_knee_function_q14_a5":"無法辦到",
  "patient_adult_knee_function_q15":"行走",
  "patient_adult_knee_function_q15_a1":"沒有困難",
  "patient_adult_knee_function_q15_a2":"稍微困難",
  "patient_adult_knee_function_q15_a3":"中度困難",
  "patient_adult_knee_function_q15_a4":"極度困難",
  "patient_adult_knee_function_q15_a5":"無法辦到",
  "patient_adult_knee_function_q16":"向前跑",
  "patient_adult_knee_function_q16_a1":"沒有困難",
  "patient_adult_knee_function_q16_a2":"稍微困難",
  "patient_adult_knee_function_q16_a3":"中度困難",
  "patient_adult_knee_function_q16_a4":"極度困難",
  "patient_adult_knee_function_q16_a5":"無法辦到",
  "patient_adult_knee_function_q17":"用受影響的腿跳躍並落地",
  "patient_adult_knee_function_q17_a1":"沒有困難",
  "patient_adult_knee_function_q17_a2":"稍微困難",
  "patient_adult_knee_function_q17_a3":"中度困難",
  "patient_adult_knee_function_q17_a4":"極度困難",
  "patient_adult_knee_function_q17_a5":"無法辦到",
  "patient_adult_knee_function_q18":"快速停止和啟動",
  "patient_adult_knee_function_q18_a1":"沒有困難",
  "patient_adult_knee_function_q18_a2":"稍微困難",
  "patient_adult_knee_function_q18_a3":"中度困難",
  "patient_adult_knee_function_q18_a4":"極度困難",
  "patient_adult_knee_function_q18_a5":"無法辦到",
  "patient_adult_knee_function_q19":"扭轉/旋轉受傷的膝蓋",
  "patient_adult_knee_function_q19_a1":"沒有困難",
  "patient_adult_knee_function_q19_a2":"稍微困難",
  "patient_adult_knee_function_q19_a3":"中度困難",
  "patient_adult_knee_function_q19_a4":"極度困難",
  "patient_adult_knee_function_q19_a5":"無法辦到",
  "patient_adult_knee_function_q20":"上下車或使用公共交通工具",
  "patient_adult_knee_function_q20_a1":"沒有困難",
  "patient_adult_knee_function_q20_a2":"稍微困難",
  "patient_adult_knee_function_q20_a3":"中度困難",
  "patient_adult_knee_function_q20_a4":"極度困難",
  "patient_adult_knee_function_q20_a5":"無法辦到",
  "patient_adult_knee_function_q21":"進出浴缸",
  "patient_adult_knee_function_q21_a1":"沒有困難",
  "patient_adult_knee_function_q21_a2":"稍微困難",
  "patient_adult_knee_function_q21_a3":"中度困難",
  "patient_adult_knee_function_q21_a4":"極度困難",
  "patient_adult_knee_function_q21_a5":"無法辦到",
  "patient_adult_knee_function_q22":"上下馬桶",
  "patient_adult_knee_function_q22_a1":"沒有困難",
  "patient_adult_knee_function_q22_a2":"稍微困難",
  "patient_adult_knee_function_q22_a3":"中度困難",
  "patient_adult_knee_function_q22_a4":"極度困難",
  "patient_adult_knee_function_q22_a5":"無法辦到",
  "patient_adult_knee_function_q23":"清洗擦乾身體",
  "patient_adult_knee_function_q23_a1":"沒有困難",
  "patient_adult_knee_function_q23_a2":"稍微困難",
  "patient_adult_knee_function_q23_a3":"中度困難",
  "patient_adult_knee_function_q23_a4":"極度困難",
  "patient_adult_knee_function_q23_a5":"無法辦到",
  "patient_adult_knee_function_q24":"日常購物",
  "patient_adult_knee_function_q24_a1":"沒有困難",
  "patient_adult_knee_function_q24_a2":"稍微困難",
  "patient_adult_knee_function_q24_a3":"中度困難",
  "patient_adult_knee_function_q24_a4":"極度困難",
  "patient_adult_knee_function_q24_a5":"無法辦到",
  "patient_adult_knee_function_q25":"繁重的家務勞動（搬運重箱子、擦地板等）",
  "patient_adult_knee_function_q25_a1":"沒有困難",
  "patient_adult_knee_function_q25_a2":"稍微困難",
  "patient_adult_knee_function_q25_a3":"中度困難",
  "patient_adult_knee_function_q25_a4":"極度困難",
  "patient_adult_knee_function_q25_a5":"無法辦到",
  "patient_adult_knee_function_q26":"輕微的家務勞動（做飯、除塵等）",
  "patient_adult_knee_function_q26_a1":"沒有困難",
  "patient_adult_knee_function_q26_a2":"稍微困難",
  "patient_adult_knee_function_q26_a3":"中度困難",
  "patient_adult_knee_function_q26_a4":"極度困難",
  "patient_adult_knee_function_q26_a5":"無法辦到",
  "patient_adult_knee_function_q27":"您如何評價您膝關節當前的功能（0-10 分）？",
  "patient_adult_knee_function_q28":"10 分表示正常，0 分表示無法進行任何日常活動（可能包括運動）",
  "patient_adult_knee_function_q28_a1":"最差",
  "patient_adult_knee_function_q28_a2":"最好",
  "patient_adult_knee_function_q29":"您可以定期參加的最高級別的活動是什麼？",
  "patient_adult_knee_function_q29_a1":"非常劇烈的活動，例如籃球或足球中的跳躍或旋轉",
  "patient_adult_knee_function_q29_a2":"劇烈活動，如重體力勞動、滑雪或打網球",
  "patient_adult_knee_function_q29_a3":"中度的活動，例如中度的體力勞動、跑步或慢跑",
  "patient_adult_knee_function_q29_a4":"輕度活動，如散步、做家務或庭院工作",
  "patient_adult_knee_function_q29_a5":"由於膝蓋而無法進行上述任何活動",
  "patient_adult_knee_function_q30":"請標明您可以參加的最高級別的活動",
  "patient_adult_knee_function_q30_q1":"膝蓋受傷/膝蓋出問題之前",
  "patient_adult_knee_function_q30_q1_a1":"競技",
  "patient_adult_knee_function_q30_q1_a2":"休閒活動/體適能",
  "patient_adult_knee_function_q30_q1_a3":"沒有運動，工作而已",
  "patient_adult_knee_function_q30_q1_a4":"日常生活活動",
  "patient_adult_knee_function_q30_q1_a5":"因健康問題請病假或得到傷殘年金",
  "patient_adult_knee_function_q30_q1_a1_o1":"世界級、奧林匹克級或職業競技運動",
  "patient_adult_knee_function_q30_q1_a1_o2":"國家或國際級別的競技運動，例如田徑、球拍/球類運動、體操、賽艇、滑雪或武術",
  "patient_adult_knee_function_q30_q1_a1_o3":"國內或國際級別的競技運動，如水上運動、自行車、曲棍球、冰壺或騎馬/競技表演",
  "patient_adult_knee_function_q30_q1_a2_o1":"每周至少 5 次的休閒運動，例如跑步、球/球拍運動、舉重訓練、冰壺、划船、高爾夫、瑜伽或體操",
  "patient_adult_knee_function_q30_q1_a2_o2":"每周至少 5 次的休閒運動，例如游泳、水上運動、滑雪、曲棍球、滑旱冰、騎自行車、騎馬或登山",
  "patient_adult_knee_function_q30_q1_a2_o3":"每周至少進行兩次休閒運動",
  "patient_adult_knee_function_q30_q1_a2_o4":"每週一次以下的休閒運動",
  "patient_adult_knee_function_q30_q1_a2_o5":"每月少於一次的休閒運動",
  "patient_adult_knee_function_q30_q1_a3_o1":"建築等重體力勞動",
  "patient_adult_knee_function_q30_q1_a3_o2":"中等重體力勞動，例如卡車駕駛",
  "patient_adult_knee_function_q30_q1_a3_o3":"輕體力勞動比如護理等",
  "patient_adult_knee_function_q30_q1_a3_o4":"久坐的工作，例如秘書或基於電腦的工作",
  "patient_adult_knee_function_q30_q1_a4_o1":"日常活動，例如園藝、爬多段樓梯、搬運貨物、推/拉貨物、遲到時跑步的能力",
  "patient_adult_knee_function_q30_q1_a4_o2":"日常活動，如打掃房屋、爬1或2層樓梯，或攜帶輕物品行走",
  "patient_adult_knee_function_q30_q1_a4_o3":"日常活動受限（例如不爬樓梯、無法搬運物品）",
  "patient_adult_knee_function_q30_q2":"當前狀態",
  "patient_adult_knee_function_q30_q2_a1":"競技",
  "patient_adult_knee_function_q30_q2_a2":"休閒活動/體適能",
  "patient_adult_knee_function_q30_q2_a3":"沒有運動，工作而已",
  "patient_adult_knee_function_q30_q2_a4":"日常生活活動",
  "patient_adult_knee_function_q30_q2_a5":"因健康問題請病假或得到傷殘年金",
  "patient_adult_knee_function_q30_q2_a1_o1":"世界級、奧林匹克級或職業競技運動",
  "patient_adult_knee_function_q30_q2_a1_o2":"國家或國際級別的競技運動，例如田徑、球拍/球類運動、體操、賽艇、滑雪或武術",
  "patient_adult_knee_function_q30_q2_a1_o3":"國內或國際級別的競技運動，如水上運動、自行車、曲棍球、冰壺或騎馬/競技表演",
  "patient_adult_knee_function_q30_q2_a2_o1":"每周至少 5 次的休閒運動，例如跑步、球/球拍運動、舉重訓練、冰壺、划船、高爾夫、瑜伽或體操",
  "patient_adult_knee_function_q30_q2_a2_o2":"每周至少 5 次的休閒運動，例如游泳、水上運動、滑雪、曲棍球、滑旱冰、騎自行車、騎馬或登山",
  "patient_adult_knee_function_q30_q2_a2_o3":"每周至少進行兩次休閒運動",
  "patient_adult_knee_function_q30_q2_a2_o4":"每週一次以下的休閒運動",
  "patient_adult_knee_function_q30_q2_a2_o5":"每月少於一次的休閒運動",
  "patient_adult_knee_function_q30_q2_a3_o1":"建築等重體力勞動",
  "patient_adult_knee_function_q30_q2_a3_o2":"中等重體力勞動，例如卡車駕駛",
  "patient_adult_knee_function_q30_q2_a3_o3":"輕體力勞動比如護理等",
  "patient_adult_knee_function_q30_q2_a3_o4":"久坐的工作，例如秘書或基於電腦的工作",
  "patient_adult_knee_function_q30_q2_a4_o1":"日常活動，例如園藝、爬多段樓梯、搬運貨物、推/拉貨物、遲到時跑步的能力",
  "patient_adult_knee_function_q30_q2_a4_o2":"日常活動，如打掃房屋、爬1或2層樓梯，或攜帶輕物品行走",
  "patient_adult_knee_function_q30_q2_a4_o3":"日常活動受限（例如不爬樓梯、無法搬運物品）",
  "patient_adult_knee_function_q31":"您的膝蓋骨是否脫臼，或感覺好像脫臼？",
  "patient_adult_knee_function_q31_a1":"否",
  "patient_adult_knee_function_q31_a2":"是",
  "patient_adult_knee_patellofemoral_instability":"髕股不穩",
  "patient_adult_knee_patellofemoral_instability_instruction":"請選擇最能描述您進行以下各項活動時膝蓋骨從關節“彈出”或感覺不穩定的頻率",
  "patient_adult_knee_patellofemoral_instability_instruction_0":"從未",
  "patient_adult_knee_patellofemoral_instability_instruction_1":"偶爾",
  "patient_adult_knee_patellofemoral_instability_instruction_2":"有時候",
  "patient_adult_knee_patellofemoral_instability_instruction_3":"經常",
  "patient_adult_knee_patellofemoral_instability_instruction_4":"持續",
  "patient_adult_knee_patellofemoral_instability_q1":"運動/比賽期間扭轉/改變方向",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q1_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q1_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q1_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q1_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q1_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q2":"跑步時改變方向",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q2_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q2_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q2_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q2_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q2_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q3":"在不平坦的表面上直線奔跑",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q3_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q3_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q3_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q3_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q3_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q4":"在滑溜、潮濕或結冰的表面行走",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q4_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q4_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q4_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q4_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q4_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q5":"往側面跑",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q5_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q5_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q5_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q5_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q5_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q6":"躍遷",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q6_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q6_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q6_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q6_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q6_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q7":"跳躍",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q7_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q7_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q7_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q7_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q7_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q8":"在平坦的表面上直線奔跑",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q8_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q8_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q8_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q8_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q8_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q9":"下樓梯",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q9_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q9_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q9_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q9_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q9_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q10":"蹲下",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q10_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q10_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q10_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q10_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q10_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q11":"跪下",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q11_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q11_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q11_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q11_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q11_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q12":"在不平坦的表面上直線步行",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q12_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q12_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q12_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q12_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q12_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q13":"上樓梯",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q13_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q13_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q13_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q13_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q13_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q14":"踏上或跨過高台階",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q14_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q14_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q14_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q14_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q14_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q15":"坐著時交叉雙腿",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q15_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q15_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q15_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q15_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q15_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q16":"在平坦的表面上直線步行",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q16_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q16_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q16_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q16_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q16_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q17":"進出車子",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q17_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q17_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q17_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q17_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q17_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q18":"在超市過道上讓沈重的手推車轉向",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q18_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q18_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q18_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q18_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q18_a5":"持續",
  "patient_adult_knee_patellofemoral_instability_q19":"轉頭越過肩膀上方看東西",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do":"不做這些活動",
  "patient_adult_knee_patellofemoral_instability_q19_a1":"從未",
  "patient_adult_knee_patellofemoral_instability_q19_a2":"偶爾",
  "patient_adult_knee_patellofemoral_instability_q19_a3":"有時候",
  "patient_adult_knee_patellofemoral_instability_q19_a4":"經常",
  "patient_adult_knee_patellofemoral_instability_q19_a5":"持續",
  "patient_adult_knee_quality_of_life":"生活品質",
  "patient_adult_knee_quality_of_life_instruction":"以下問題詢問您對膝蓋問題感到的社交、情感和生活方式方面的擔憂。請想一想您大部分時間對膝蓋問題的感受如何。",
  "patient_adult_knee_quality_of_life_q1":"您多常意識到自己的膝蓋問題？",
  "patient_adult_knee_quality_of_life_q1_a1":"從未",
  "patient_adult_knee_quality_of_life_q1_a2":"偶爾",
  "patient_adult_knee_quality_of_life_q1_a3":"有時候",
  "patient_adult_knee_quality_of_life_q1_a4":"經常",
  "patient_adult_knee_quality_of_life_q1_a5":"持續",
  "patient_adult_knee_quality_of_life_q2":"您有刻意改變可能傷害膝蓋的生活型態嗎？",
  "patient_adult_knee_quality_of_life_q2_a1":"一點也不",
  "patient_adult_knee_quality_of_life_q2_a2":"稍微",
  "patient_adult_knee_quality_of_life_q2_a3":"中度",
  "patient_adult_knee_quality_of_life_q2_a4":"嚴重",
  "patient_adult_knee_quality_of_life_q2_a5":"極度",
  "patient_adult_knee_quality_of_life_q3":"您對自己的膝蓋缺乏信心造成了多少困擾？",
  "patient_adult_knee_quality_of_life_q3_a1":"一點也不",
  "patient_adult_knee_quality_of_life_q3_a2":"稍微",
  "patient_adult_knee_quality_of_life_q3_a3":"中度",
  "patient_adult_knee_quality_of_life_q3_a4":"嚴重",
  "patient_adult_knee_quality_of_life_q3_a5":"極度",
  "patient_adult_knee_quality_of_life_q4":"一般來說，您的膝蓋承受了多少困難？",
  "patient_adult_knee_quality_of_life_q4_a1":"無",
  "patient_adult_knee_quality_of_life_q4_a2":"輕微",
  "patient_adult_knee_quality_of_life_q4_a3":"中度",
  "patient_adult_knee_quality_of_life_q4_a4":"嚴重",
  "patient_adult_knee_quality_of_life_q4_a5":"極度",
  "patient_adult_knee_quality_of_life_q5":"您今天有多焦慮/抑鬱？",
  "patient_adult_knee_quality_of_life_q5_a1":"不焦慮/抑鬱",
  "patient_adult_knee_quality_of_life_q5_a2":"一點點",
  "patient_adult_knee_quality_of_life_q5_a3":"中度",
  "patient_adult_knee_quality_of_life_q5_a4":"嚴重",
  "patient_adult_knee_quality_of_life_q5_a5":"極度",
  "patient_adult_knee_quality_of_life_q6":"您今天的健康狀況如何？",
  "patient_adult_knee_quality_of_life_q6_instruction":"100 表示最佳健康狀況，0 表示您可以想像的最差健康狀況",
  "patient_adult_knee_quality_of_life_q6_instruction_a1":"最差",
  "patient_adult_knee_quality_of_life_q6_instruction_a2":"最好",
  "patient_adult_knee_satisfaction":"滿意度",
  "patient_adult_knee_satisfaction_instructions":"您對膝蓋手術/治療的結果滿意嗎？",
  "patient_adult_knee_satisfaction_instructions_1":"很不滿意",
  "patient_adult_knee_satisfaction_instructions_2":"有些不滿意",
  "patient_adult_knee_satisfaction_instructions_3":"有些滿意",
  "patient_adult_knee_satisfaction_instructions_4":"很滿意",
  "patient_adult_knee_satisfaction_q1":"總體",
  "patient_adult_knee_satisfaction_q1_not_applicable":"不適用",
  "patient_adult_knee_satisfaction_q1_a1":"很不滿意",
  "patient_adult_knee_satisfaction_q1_a2":"有些不滿意",
  "patient_adult_knee_satisfaction_q1_a3":"有些滿意",
  "patient_adult_knee_satisfaction_q1_a4":"很滿意",
  "patient_adult_knee_satisfaction_q2":"在改善您的疼痛方面？",
  "patient_adult_knee_satisfaction_q2_not_applicable":"不適用",
  "patient_adult_knee_satisfaction_q2_a1":"很不滿意",
  "patient_adult_knee_satisfaction_q2_a2":"有些不滿意",
  "patient_adult_knee_satisfaction_q2_a3":"有些滿意",
  "patient_adult_knee_satisfaction_q2_a4":"很滿意",
  "patient_adult_knee_satisfaction_q3":"在提高您做家務或庭院工作的能力方面？",
  "patient_adult_knee_satisfaction_q3_not_applicable":"不適用",
  "patient_adult_knee_satisfaction_q3_a1":"很不滿意",
  "patient_adult_knee_satisfaction_q3_a2":"有些不滿意",
  "patient_adult_knee_satisfaction_q3_a3":"有些滿意",
  "patient_adult_knee_satisfaction_q3_a4":"很滿意",
  "patient_adult_knee_satisfaction_q4":"在提高您進行娛樂/休閒活動的能力方面？",
  "patient_adult_knee_satisfaction_q4_not_applicable":"不適用",
  "patient_adult_knee_satisfaction_q4_a1":"很不滿意",
  "patient_adult_knee_satisfaction_q4_a2":"有些不滿意",
  "patient_adult_knee_satisfaction_q4_a3":"有些滿意",
  "patient_adult_knee_satisfaction_q4_a4":"很滿意",
  "patient_adult_knee_complete":"問卷結束",
  "patient_adult_knee_complete_text1":"感謝您抽出寶貴時間填寫此問卷。",
  "patient_adult_knee_complete_text2":"請點擊提交完成，並查看您的分數。",
  "patient_adult_knee_complete_text3":"您的回答將被保密，任何報告都只會使用去除身份識別的資訊",
  "patient_adult_knee_results":"結果",
  "patient_adult_knee_results_text1":"以下是您的疼痛和膝關節功能評分，這些評分是根據您的調查問卷計算得出的。",
  "patient_adult_knee_results_pain":"疼痛",
  "patient_adult_knee_results_function":"功能",
  "patient_adult_knee_results_health_perception":"整體健康認知",
  "patient_adult_knee_results_self_evaluation":"膝蓋評分自我評估",
  "patient_adult_knee_results_text2":"您的醫生很快就會與您聯繫",
  "patient_adolescent_knee_pain":"疼痛",
  "patient_adolescent_knee_function_q23":"去購物",
  "patient_adolescent_knee_function_q23_a1":"沒有困難",
  "patient_adolescent_knee_function_q23_a2":"一點點困難",
  "patient_adolescent_knee_function_q23_a3":"中度困難",
  "patient_adolescent_knee_function_q23_a4":"極度困難",
  "patient_adolescent_knee_function_q23_a5":"無法辦到",
  "patient_pedia_knee_pain":"疼痛",
  "patient_pedia_knee_pain_q1":"在過去 4 週內或自您受傷以來，您受傷的膝蓋有多少時間感到疼痛？",
  "patient_pedia_knee_pain_q1_a1":"不曾痛",
  "patient_pedia_knee_pain_q1_a2":"偶爾",
  "patient_pedia_knee_pain_q1_a3":"有時",
  "patient_pedia_knee_pain_q1_a4":"經常",
  "patient_pedia_knee_pain_q1_a5":"持續",
  "patient_pedia_knee_pain_q2":"今天您受傷的膝蓋疼得有多厲害？",
  "patient_pedia_knee_pain_q2_a1":"完全不痛",
  "patient_pedia_knee_pain_q2_a2":"痛到我無法忍受",
  "patient_pedia_knee_pain_instructions":"在過去 7 天內，您在進行以下活動時膝蓋疼痛程度如何？",
  "patient_pedia_knee_pain_instructions_0":"沒有痛",
  "patient_pedia_knee_pain_instructions_1":"一點點痛",
  "patient_pedia_knee_pain_instructions_2":"有些痛",
  "patient_pedia_knee_pain_instructions_3":"很痛",
  "patient_pedia_knee_pain_instructions_4":"極度疼痛",
  "patient_pedia_knee_pain_note":"注意：請回答以下與您的膝蓋活動相關的問題。",
  "patient_pedia_knee_pain_q3":"行走/站立/跑步時扭轉/旋轉膝蓋",
  "patient_pedia_knee_pain_q3_a1":"沒有痛",
  "patient_pedia_knee_pain_q3_a2":"一點點痛",
  "patient_pedia_knee_pain_q3_a3":"有些痛",
  "patient_pedia_knee_pain_q3_a4":"很痛",
  "patient_pedia_knee_pain_q3_a5":"極度疼痛",
  "patient_pedia_knee_pain_q4":"膝蓋伸直",
  "patient_pedia_knee_pain_q4_a1":"沒有痛",
  "patient_pedia_knee_pain_q4_a2":"一點點痛",
  "patient_pedia_knee_pain_q4_a3":"有些痛",
  "patient_pedia_knee_pain_q4_a4":"很痛",
  "patient_pedia_knee_pain_q4_a5":"極度疼痛",
  "patient_pedia_knee_pain_q5":"屈膝",
  "patient_pedia_knee_pain_q5_a1":"沒有痛",
  "patient_pedia_knee_pain_q5_a2":"一點點痛",
  "patient_pedia_knee_pain_q5_a3":"有些痛",
  "patient_pedia_knee_pain_q5_a4":"很痛",
  "patient_pedia_knee_pain_q5_a5":"極度疼痛",
  "patient_pedia_knee_pain_q6":"上樓梯",
  "patient_pedia_knee_pain_q6_a1":"沒有痛",
  "patient_pedia_knee_pain_q6_a2":"一點點痛",
  "patient_pedia_knee_pain_q6_a3":"有些痛",
  "patient_pedia_knee_pain_q6_a4":"很痛",
  "patient_pedia_knee_pain_q6_a5":"極度疼痛",
  "patient_pedia_knee_pain_q7":"下樓梯",
  "patient_pedia_knee_pain_q7_a1":"沒有痛",
  "patient_pedia_knee_pain_q7_a2":"一點點痛",
  "patient_pedia_knee_pain_q7_a3":"有些痛",
  "patient_pedia_knee_pain_q7_a4":"很痛",
  "patient_pedia_knee_pain_q7_a5":"極度疼痛",
  "patient_pedia_knee_pain_q8":"坐著屈膝",
  "patient_pedia_knee_pain_q8_a1":"沒有痛",
  "patient_pedia_knee_pain_q8_a2":"一點點痛",
  "patient_pedia_knee_pain_q8_a3":"有些痛",
  "patient_pedia_knee_pain_q8_a4":"很痛",
  "patient_pedia_knee_pain_q8_a5":"極度疼痛",
  "patient_pedia_knee_pain_q9":"雙腿筆直站立任意時間",
  "patient_pedia_knee_pain_q9_a1":"沒有痛",
  "patient_pedia_knee_pain_q9_a2":"一點點痛",
  "patient_pedia_knee_pain_q9_a3":"有些痛",
  "patient_pedia_knee_pain_q9_a4":"很痛",
  "patient_pedia_knee_pain_q9_a5":"極度疼痛",
  "patient_pedia_knee_other_symptoms":"其他症狀",
  "patient_pedia_knee_other_symptoms_instruction1":"您多常經驗以下狀況：",
  "patient_pedia_knee_other_symptoms_instruction1_0":"從未",
  "patient_pedia_knee_other_symptoms_instruction1_1":"偶爾",
  "patient_pedia_knee_other_symptoms_instruction1_2":"有時侯",
  "patient_pedia_knee_other_symptoms_instruction1_3":"經常",
  "patient_pedia_knee_other_symptoms_instruction1_4":"持續",
  "patient_pedia_knee_other_symptoms_q1":"膝蓋發出任何噪音/聲音",
  "patient_pedia_knee_other_symptoms_q1_a1":"從未",
  "patient_pedia_knee_other_symptoms_q1_a2":"偶爾",
  "patient_pedia_knee_other_symptoms_q1_a3":"有時侯",
  "patient_pedia_knee_other_symptoms_q1_a4":"經常",
  "patient_pedia_knee_other_symptoms_q1_a5":"持續",
  "patient_pedia_knee_other_symptoms_q2":"膝蓋被卡住或鎖定在某些位置",
  "patient_pedia_knee_other_symptoms_q2_a1":"從未",
  "patient_pedia_knee_other_symptoms_q2_a2":"偶爾",
  "patient_pedia_knee_other_symptoms_q2_a3":"有時侯",
  "patient_pedia_knee_other_symptoms_q2_a4":"經常",
  "patient_pedia_knee_other_symptoms_q2_a5":"持續",
  "patient_pedia_knee_other_symptoms_q3":"感覺膝蓋被鎖定（卡住），但您仍然可以移動它",
  "patient_pedia_knee_other_symptoms_q3_a1":"從未",
  "patient_pedia_knee_other_symptoms_q3_a2":"偶爾",
  "patient_pedia_knee_other_symptoms_q3_a3":"有時侯",
  "patient_pedia_knee_other_symptoms_q3_a4":"經常",
  "patient_pedia_knee_other_symptoms_q3_a5":"持續",
  "patient_pedia_knee_other_symptoms_q4":"膝蓋腫脹/浮腫",
  "patient_pedia_knee_other_symptoms_q4_a1":"從未",
  "patient_pedia_knee_other_symptoms_q4_a2":"偶爾",
  "patient_pedia_knee_other_symptoms_q4_a3":"有時侯",
  "patient_pedia_knee_other_symptoms_q4_a4":"經常",
  "patient_pedia_knee_other_symptoms_q4_a5":"持續",
  "patient_pedia_knee_other_symptoms_q5":"您的膝蓋有多浮腫（或腫脹）？",
  "patient_pedia_knee_other_symptoms_q5_a1":"不浮腫",
  "patient_pedia_knee_other_symptoms_q5_a2":"一點點浮腫",
  "patient_pedia_knee_other_symptoms_q5_a3":"有些浮腫",
  "patient_pedia_knee_other_symptoms_q5_a4":"很浮腫",
  "patient_pedia_knee_other_symptoms_q5_a5":"極度浮腫",
  "patient_pedia_knee_other_symptoms_instruction2":"如果您被要求進行以下活動，您今天最多可以做到什麼......",
  "patient_pedia_knee_other_symptoms_instruction2_0":"非常困難的活動，例如玩籃球或足球時跳躍或快速轉身以改變方向",
  "patient_pedia_knee_other_symptoms_instruction2_1":"困難的活動，如舉重、滑雪或網球",
  "patient_pedia_knee_other_symptoms_instruction2_2":"有一些困難的活動，例如快走或慢跑",
  "patient_pedia_knee_other_symptoms_instruction2_3":"輕度活動，例如以正常速度行走",
  "patient_pedia_knee_other_symptoms_instruction2_4":"我無法進行上面列出的任何活動",
  "patient_pedia_knee_other_symptoms_q6":"在不讓膝蓋非常疼痛的狀況下",
  "patient_pedia_knee_other_symptoms_q6_a1":"非常困難的活動",
  "patient_pedia_knee_other_symptoms_q6_a2":"困難的活動",
  "patient_pedia_knee_other_symptoms_q6_a3":"有些困難的活動",
  "patient_pedia_knee_other_symptoms_q6_a4":"輕度活動",
  "patient_pedia_knee_other_symptoms_q6_a5":"我無法進行上面列出的任何活動",
  "patient_pedia_knee_other_symptoms_q7":"在不讓膝蓋浮腫（或腫脹）的狀況下",
  "patient_pedia_knee_other_symptoms_q7_a1":"非常困難的活動",
  "patient_pedia_knee_other_symptoms_q7_a2":"困難的活動",
  "patient_pedia_knee_other_symptoms_q7_a3":"有些困難的活動",
  "patient_pedia_knee_other_symptoms_q7_a4":"輕度活動",
  "patient_pedia_knee_other_symptoms_q7_a5":"我無法進行上面列出的任何活動",
  "patient_pedia_knee_other_symptoms_q8":"在不讓您感覺膝蓋無法支撐您的體重的狀況下",
  "patient_pedia_knee_other_symptoms_q8_a1":"非常困難的活動",
  "patient_pedia_knee_other_symptoms_q8_a2":"困難的活動",
  "patient_pedia_knee_other_symptoms_q8_a3":"有些困難的活動",
  "patient_pedia_knee_other_symptoms_q8_a5":"我無法進行上面列出的任何活動",
  "patient_pedia_knee_other_symptoms_q9":"大多的時候",
  "patient_pedia_knee_other_symptoms_q9_a1":"非常困難的活動",
  "patient_pedia_knee_other_symptoms_q9_a2":"困難的活動",
  "patient_pedia_knee_other_symptoms_q9_a3":"有些困難的活動",
  "patient_pedia_knee_other_symptoms_q9_a4":"輕度活動",
  "patient_pedia_knee_other_symptoms_q9_a5":"我無法進行上面列出的任何活動",
  "patient_pedia_knee_function":"功能",
  "patient_pedia_knee_function_instruction":"由於膝蓋的原因，您在執行以下操作時遇到了多少困難：",
  "patient_pedia_knee_function_instruction_0":"沒有困難",
  "patient_pedia_knee_function_instruction_1":"一點點",
  "patient_pedia_knee_function_instruction_2":"一些",
  "patient_pedia_knee_function_instruction_3":"很多",
  "patient_pedia_knee_function_instruction_4":"極度困難/無法辦到",
  "patient_pedia_knee_function_note":"註：如果您不做某項活動，請想像一下如果您必須嘗試，您的膝蓋會有什麼感覺。",
  "patient_pedia_knee_function_q1":"上樓梯",
  "patient_pedia_knee_function_q1_a1":"沒有困難",
  "patient_pedia_knee_function_q1_a2":"一點點",
  "patient_pedia_knee_function_q1_a3":"一些",
  "patient_pedia_knee_function_q1_a4":"很多",
  "patient_pedia_knee_function_q1_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q2":"下樓梯",
  "patient_pedia_knee_function_q2_a1":"沒有困難",
  "patient_pedia_knee_function_q2_a2":"一點點",
  "patient_pedia_knee_function_q2_a3":"一些",
  "patient_pedia_knee_function_q2_a4":"很多",
  "patient_pedia_knee_function_q2_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q3":"用膝蓋前面跪著",
  "patient_pedia_knee_function_q3_a1":"沒有困難",
  "patient_pedia_knee_function_q3_a2":"一點點",
  "patient_pedia_knee_function_q3_a3":"一些",
  "patient_pedia_knee_function_q3_a4":"很多",
  "patient_pedia_knee_function_q3_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q4":"完全把膝蓋伸直",
  "patient_pedia_knee_function_q4_a1":"沒有困難",
  "patient_pedia_knee_function_q4_a2":"一點點",
  "patient_pedia_knee_function_q4_a3":"一些",
  "patient_pedia_knee_function_q4_a4":"很多",
  "patient_pedia_knee_function_q4_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q5":"完全屈膝",
  "patient_pedia_knee_function_q5_a1":"沒有困難",
  "patient_pedia_knee_function_q5_a2":"一點點",
  "patient_pedia_knee_function_q5_a3":"一些",
  "patient_pedia_knee_function_q5_a4":"很多",
  "patient_pedia_knee_function_q5_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q6":"早晨一醒過來就活動膝蓋",
  "patient_pedia_knee_function_q6_a1":"沒有困難",
  "patient_pedia_knee_function_q6_a2":"一點點",
  "patient_pedia_knee_function_q6_a3":"一些",
  "patient_pedia_knee_function_q6_a4":"很多",
  "patient_pedia_knee_function_q6_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q7":"一天中稍晚的時分在久坐後活動膝蓋",
  "patient_pedia_knee_function_q7_a1":"沒有困難",
  "patient_pedia_knee_function_q7_a2":"一點點",
  "patient_pedia_knee_function_q7_a3":"一些",
  "patient_pedia_knee_function_q7_a4":"很多",
  "patient_pedia_knee_function_q7_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q8":"像棒球捕手一樣蹲下",
  "patient_pedia_knee_function_q8_a1":"沒有困難",
  "patient_pedia_knee_function_q8_a2":"一點點",
  "patient_pedia_knee_function_q8_a3":"一些",
  "patient_pedia_knee_function_q8_a4":"很多",
  "patient_pedia_knee_function_q8_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q9":"坐在椅子上屈膝，雙腳平放在地板上",
  "patient_pedia_knee_function_q9_a1":"沒有困難",
  "patient_pedia_knee_function_q9_a2":"一點點",
  "patient_pedia_knee_function_q9_a3":"一些",
  "patient_pedia_knee_function_q9_a4":"很多",
  "patient_pedia_knee_function_q9_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q10":"從椅子站起來",
  "patient_pedia_knee_function_q10_a1":"沒有困難",
  "patient_pedia_knee_function_q10_a2":"一點點",
  "patient_pedia_knee_function_q10_a3":"一些",
  "patient_pedia_knee_function_q10_a4":"很多",
  "patient_pedia_knee_function_q10_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q11":"彎腰並從地板上撿起一個物體",
  "patient_pedia_knee_function_q11_a1":"沒有困難",
  "patient_pedia_knee_function_q11_a2":"一點點",
  "patient_pedia_knee_function_q11_a3":"一些",
  "patient_pedia_knee_function_q11_a4":"很多",
  "patient_pedia_knee_function_q11_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q12":"在玩耍或體育活動時跑動",
  "patient_pedia_knee_function_q12_a1":"沒有困難",
  "patient_pedia_knee_function_q12_a2":"一點點",
  "patient_pedia_knee_function_q12_a3":"一些",
  "patient_pedia_knee_function_q12_a4":"很多",
  "patient_pedia_knee_function_q12_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q13":"在玩耍或體育活動時跳躍",
  "patient_pedia_knee_function_q13_a1":"沒有困難",
  "patient_pedia_knee_function_q13_a2":"一點點",
  "patient_pedia_knee_function_q13_a3":"一些",
  "patient_pedia_knee_function_q13_a4":"很多",
  "patient_pedia_knee_function_q13_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q14":"快速開始和停止移動",
  "patient_pedia_knee_function_q14_a1":"沒有困難",
  "patient_pedia_knee_function_q14_a2":"一點點",
  "patient_pedia_knee_function_q14_a3":"一些",
  "patient_pedia_knee_function_q14_a4":"很多",
  "patient_pedia_knee_function_q14_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q15":"在玩耍或體育活動期間扭動/旋轉",
  "patient_pedia_knee_function_q15_a1":"沒有困難",
  "patient_pedia_knee_function_q15_a2":"一點點",
  "patient_pedia_knee_function_q15_a3":"一些",
  "patient_pedia_knee_function_q15_a4":"很多",
  "patient_pedia_knee_function_q15_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q16":"在不平坦的地面上行走/跑步時保持平衡",
  "patient_pedia_knee_function_q16_a1":"沒有困難",
  "patient_pedia_knee_function_q16_a2":"一點點",
  "patient_pedia_knee_function_q16_a3":"一些",
  "patient_pedia_knee_function_q16_a4":"很多",
  "patient_pedia_knee_function_q16_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q17":"參加運動",
  "patient_pedia_knee_function_q17_a1":"沒有困難",
  "patient_pedia_knee_function_q17_a2":"一點點",
  "patient_pedia_knee_function_q17_a3":"一些",
  "patient_pedia_knee_function_q17_a4":"很多",
  "patient_pedia_knee_function_q17_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q18":"進出車子",
  "patient_pedia_knee_function_q18_a1":"沒有困難",
  "patient_pedia_knee_function_q18_a2":"一點點",
  "patient_pedia_knee_function_q18_a3":"一些",
  "patient_pedia_knee_function_q18_a4":"很多",
  "patient_pedia_knee_function_q18_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q19":"起床",
  "patient_pedia_knee_function_q19_a1":"沒有困難",
  "patient_pedia_knee_function_q19_a2":"一點點",
  "patient_pedia_knee_function_q19_a3":"一些",
  "patient_pedia_knee_function_q19_a4":"很多",
  "patient_pedia_knee_function_q19_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q20":"在床上躺著時改變膝蓋姿勢",
  "patient_pedia_knee_function_q20_a1":"沒有困難",
  "patient_pedia_knee_function_q20_a2":"一點點",
  "patient_pedia_knee_function_q20_a3":"一些",
  "patient_pedia_knee_function_q20_a4":"很多",
  "patient_pedia_knee_function_q20_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q21":"進出浴缸/淋浴間",
  "patient_pedia_knee_function_q21_a1":"沒有困難",
  "patient_pedia_knee_function_q21_a2":"一點點",
  "patient_pedia_knee_function_q21_a3":"一些",
  "patient_pedia_knee_function_q21_a4":"很多",
  "patient_pedia_knee_function_q21_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q22":"攜帶重的袋子/背包等",
  "patient_pedia_knee_function_q22_a1":"沒有困難",
  "patient_pedia_knee_function_q22_a2":"一點點",
  "patient_pedia_knee_function_q22_a3":"一些",
  "patient_pedia_knee_function_q22_a4":"很多",
  "patient_pedia_knee_function_q22_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q23":"做一些簡單的家務，例如打掃房間、裝滿/清空洗碗機、整理床舖等",
  "patient_pedia_knee_function_q23_a1":"沒有困難",
  "patient_pedia_knee_function_q23_a2":"一點點",
  "patient_pedia_knee_function_q23_a3":"一些",
  "patient_pedia_knee_function_q23_a4":"很多",
  "patient_pedia_knee_function_q23_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q24":"學上校或在學校裡走動（爬樓梯、開門、搬運書本、課間休息時參與活動）",
  "patient_pedia_knee_function_q24_a1":"沒有困難",
  "patient_pedia_knee_function_q24_a2":"一點點",
  "patient_pedia_knee_function_q24_a3":"一些",
  "patient_pedia_knee_function_q24_a4":"很多",
  "patient_pedia_knee_function_q24_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q25":"和朋友一起做事",
  "patient_pedia_knee_function_q25_a1":"沒有困難",
  "patient_pedia_knee_function_q25_a2":"一點點",
  "patient_pedia_knee_function_q25_a3":"一些",
  "patient_pedia_knee_function_q25_a4":"很多",
  "patient_pedia_knee_function_q25_a5":"極度困難/無法辦到",
  "patient_pedia_knee_function_q26":"在受傷之前，您的膝蓋功能情況如何？",
  "patient_pedia_knee_function_q26_instruction":"10 表示正常，0 表示無法進行任何日常活動，包括運動",
  "patient_pedia_knee_function_q26_instruction_a1":"我什麼也做不到",
  "patient_pedia_knee_function_q26_instruction_a2":"我可以做任何我想做的事",
  "patient_pedia_knee_function_q27":"您的膝蓋現在狀況如何？",
  "patient_pedia_knee_function_q27_instruction":"10 表示正常，0 表示無法進行任何日常活動，包括運動",
  "patient_pedia_knee_function_q27_instruction_a1":"我什麼也做不到",
  "patient_pedia_knee_function_q27_instruction_a2":"我可以做任何我想做的事",
  "patient_pedia_knee_function_q28":"您的膝蓋骨是否脫臼，或感覺好像脫臼？",
  "patient_pedia_knee_function_q28_a1":"否",
  "patient_pedia_knee_function_q28_a2":"是",
  "patient_pedia_knee_instability":"髕股不穩",
  "patient_pedia_knee_instability_instruction":"請選擇最能描述您進行以下各項活動時膝蓋骨從關節“彈出”或感覺不穩定的頻率",
  "patient_pedia_knee_instability_instruction_0":"從未",
  "patient_pedia_knee_instability_instruction_1":"偶爾",
  "patient_pedia_knee_instability_instruction_2":"有時候",
  "patient_pedia_knee_instability_instruction_3":"經常",
  "patient_pedia_knee_instability_instruction_4":"持續",
  "patient_pedia_knee_instability_note":"注意：請回答以下與您的膝蓋活動相關的問題",
  "patient_pedia_knee_instability_q1":"運動/比賽期間扭轉/改變方向",
  "patient_pedia_knee_instability_q1_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q1_a1":"從未",
  "patient_pedia_knee_instability_q1_a2":"偶爾",
  "patient_pedia_knee_instability_q1_a3":"有時候",
  "patient_pedia_knee_instability_q1_a4":"經常",
  "patient_pedia_knee_instability_q1_a5":"持續",
  "patient_pedia_knee_instability_q2":"跑步時改變方向",
  "patient_pedia_knee_instability_q2_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q2_a1":"從未",
  "patient_pedia_knee_instability_q2_a2":"偶爾",
  "patient_pedia_knee_instability_q2_a3":"有時候",
  "patient_pedia_knee_instability_q2_a4":"經常",
  "patient_pedia_knee_instability_q2_a5":"持續",
  "patient_pedia_knee_instability_q3":"在不平坦的表面上直線奔跑",
  "patient_pedia_knee_instability_q3_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q3_a1":"從未",
  "patient_pedia_knee_instability_q3_a2":"偶爾",
  "patient_pedia_knee_instability_q3_a3":"有時候",
  "patient_pedia_knee_instability_q3_a4":"經常",
  "patient_pedia_knee_instability_q3_a5":"持續",
  "patient_pedia_knee_instability_q4":"在滑溜、潮濕或結冰的表面行走",
  "patient_pedia_knee_instability_q4_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q4_a1":"從未",
  "patient_pedia_knee_instability_q4_a2":"偶爾",
  "patient_pedia_knee_instability_q4_a3":"有時候",
  "patient_pedia_knee_instability_q4_a4":"經常",
  "patient_pedia_knee_instability_q4_a5":"持續",
  "patient_pedia_knee_instability_q5":"往側面跑",
  "patient_pedia_knee_instability_q5_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q5_a1":"從未",
  "patient_pedia_knee_instability_q5_a2":"偶爾",
  "patient_pedia_knee_instability_q5_a3":"有時候",
  "patient_pedia_knee_instability_q5_a4":"經常",
  "patient_pedia_knee_instability_q5_a5":"持續",
  "patient_pedia_knee_instability_q6":"單腳跳",
  "patient_pedia_knee_instability_q6_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q6_a1":"從未",
  "patient_pedia_knee_instability_q6_a2":"偶爾",
  "patient_pedia_knee_instability_q6_a3":"有時候",
  "patient_pedia_knee_instability_q6_a4":"經常",
  "patient_pedia_knee_instability_q6_a5":"持續",
  "patient_pedia_knee_instability_q7":"跳躍",
  "patient_pedia_knee_instability_q7_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q7_a1":"從未",
  "patient_pedia_knee_instability_q7_a2":"偶爾",
  "patient_pedia_knee_instability_q7_a3":"有時候",
  "patient_pedia_knee_instability_q7_a4":"經常",
  "patient_pedia_knee_instability_q7_a5":"持續",
  "patient_pedia_knee_instability_q8":"在平坦的表面上直線奔跑",
  "patient_pedia_knee_instability_q8_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q8_a1":"從未",
  "patient_pedia_knee_instability_q8_a2":"偶爾",
  "patient_pedia_knee_instability_q8_a3":"有時候",
  "patient_pedia_knee_instability_q8_a4":"經常",
  "patient_pedia_knee_instability_q8_a5":"持續",
  "patient_pedia_knee_instability_q9":"下樓梯",
  "patient_pedia_knee_instability_q9_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q9_a1":"從未",
  "patient_pedia_knee_instability_q9_a2":"偶爾",
  "patient_pedia_knee_instability_q9_a3":"有時候",
  "patient_pedia_knee_instability_q9_a4":"經常",
  "patient_pedia_knee_instability_q9_a5":"持續",
  "patient_pedia_knee_instability_q10":"蹲下",
  "patient_pedia_knee_instability_q10_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q10_a1":"從未",
  "patient_pedia_knee_instability_q10_a2":"偶爾",
  "patient_pedia_knee_instability_q10_a3":"有時候",
  "patient_pedia_knee_instability_q10_a4":"經常",
  "patient_pedia_knee_instability_q10_a5":"持續",
  "patient_pedia_knee_instability_q11":"跪下",
  "patient_pedia_knee_instability_q11_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q11_a1":"從未",
  "patient_pedia_knee_instability_q11_a2":"偶爾",
  "patient_pedia_knee_instability_q11_a3":"有時候",
  "patient_pedia_knee_instability_q11_a4":"經常",
  "patient_pedia_knee_instability_q11_a5":"持續",
  "patient_pedia_knee_instability_q12":"在不平坦的表面上直線步行",
  "patient_pedia_knee_instability_q12_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q12_a1":"從未",
  "patient_pedia_knee_instability_q12_a2":"偶爾",
  "patient_pedia_knee_instability_q12_a3":"有時候",
  "patient_pedia_knee_instability_q12_a4":"經常",
  "patient_pedia_knee_instability_q12_a5":"持續",
  "patient_pedia_knee_instability_q13":"上樓梯",
  "patient_pedia_knee_instability_q13_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q13_a1":"從未",
  "patient_pedia_knee_instability_q13_a2":"偶爾",
  "patient_pedia_knee_instability_q13_a3":"有時候",
  "patient_pedia_knee_instability_q13_a4":"經常",
  "patient_pedia_knee_instability_q13_a5":"持續",
  "patient_pedia_knee_instability_q14":"踏上或跨過高台階",
  "patient_pedia_knee_instability_q14_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q14_a1":"從未",
  "patient_pedia_knee_instability_q14_a2":"偶爾",
  "patient_pedia_knee_instability_q14_a3":"有時候",
  "patient_pedia_knee_instability_q14_a4":"經常",
  "patient_pedia_knee_instability_q14_a5":"持續",
  "patient_pedia_knee_instability_q15":"坐著時交叉雙腿",
  "patient_pedia_knee_instability_q15_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q15_a1":"從未",
  "patient_pedia_knee_instability_q15_a2":"偶爾",
  "patient_pedia_knee_instability_q15_a3":"有時候",
  "patient_pedia_knee_instability_q15_a4":"經常",
  "patient_pedia_knee_instability_q15_a5":"持續",
  "patient_pedia_knee_instability_q16":"在平坦的表面上直線步行",
  "patient_pedia_knee_instability_q16_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q16_a1":"從未",
  "patient_pedia_knee_instability_q16_a2":"偶爾",
  "patient_pedia_knee_instability_q16_a3":"有時候",
  "patient_pedia_knee_instability_q16_a4":"經常",
  "patient_pedia_knee_instability_q16_a5":"持續",
  "patient_pedia_knee_instability_q17":"進出車子",
  "patient_pedia_knee_instability_q17_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q17_a1":"從未",
  "patient_pedia_knee_instability_q17_a2":"偶爾",
  "patient_pedia_knee_instability_q17_a3":"有時候",
  "patient_pedia_knee_instability_q17_a4":"經常",
  "patient_pedia_knee_instability_q17_a5":"持續",
  "patient_pedia_knee_instability_q18":"在超市過道上轉動重型手推車",
  "patient_pedia_knee_instability_q18_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q18_a1":"從未",
  "patient_pedia_knee_instability_q18_a2":"偶爾",
  "patient_pedia_knee_instability_q18_a3":"有時候",
  "patient_pedia_knee_instability_q18_a4":"經常",
  "patient_pedia_knee_instability_q18_a5":"持續",
  "patient_pedia_knee_instability_q19":"轉頭越過肩膀上方看東西",
  "patient_pedia_knee_instability_q19_do_not_do":"不做這些活動",
  "patient_pedia_knee_instability_q19_a1":"從未",
  "patient_pedia_knee_instability_q19_a2":"偶爾",
  "patient_pedia_knee_instability_q19_a3":"有時候",
  "patient_pedia_knee_instability_q19_a4":"經常",
  "patient_pedia_knee_instability_q19_a5":"持續",
  "patient_pedia_knee_quality_of_life":"生活品質",
  "patient_pedia_knee_quality_of_life_instruction":"您的受傷對您的生活有何影響？",
  "patient_pedia_knee_quality_of_life_q1":"您多常想起您的膝蓋問題？",
  "patient_pedia_knee_quality_of_life_q1_a1":"從未",
  "patient_pedia_knee_quality_of_life_q1_a2":"偶爾",
  "patient_pedia_knee_quality_of_life_q1_a3":"有時候",
  "patient_pedia_knee_quality_of_life_q1_a4":"經常",
  "patient_pedia_knee_quality_of_life_q1_a5":"不間斷",
  "patient_pedia_knee_problem_q2":"由於膝蓋受傷，您的生活方式改變了多少？",
  "patient_pedia_knee_problem_q2_a1":"一點也沒有",
  "patient_pedia_knee_problem_q2_a2":"一點點",
  "patient_pedia_knee_problem_q2_a3":"一些",
  "patient_pedia_knee_problem_q2_a4":"很多",
  "patient_pedia_knee_problem_q2_a5":"非常多",
  "patient_pedia_knee_problem_q3":"您對受傷的膝蓋有多信任？",
  "patient_pedia_knee_problem_q3_a1":"完全",
  "patient_pedia_knee_problem_q3_a2":"很多",
  "patient_pedia_knee_problem_q3_a3":"一些",
  "patient_pedia_knee_problem_q3_a4":"一點點",
  "patient_pedia_knee_problem_q3_a5":"一點也不",
  "patient_pedia_knee_problem_q4":"總的來說，您受傷的膝蓋讓您承受了多少困難？",
  "patient_pedia_knee_problem_q4_a1":"沒有困難",
  "patient_pedia_knee_problem_q4_a2":"一點點",
  "patient_pedia_knee_problem_q4_a3":"一些",
  "patient_pedia_knee_problem_q4_a4":"很多",
  "patient_pedia_knee_problem_q4_a5":"極度困難",
  "patient_pedia_knee_satisfaction":"滿意度",
  "patient_pedia_knee_satisfaction_instruction":"您對膝蓋手術/治療的結果滿意嗎？",
  "patient_pedia_knee_satisfaction_instruction_a1":"很不滿意",
  "patient_pedia_knee_satisfaction_instruction_a2":"有些不滿意",
  "patient_pedia_knee_satisfaction_instruction_a3":"有些滿意",
  "patient_pedia_knee_satisfaction_instruction_a4":"很滿意",
  "patient_pedia_knee_satisfaction_q1":"總體",
  "patient_pedia_knee_satisfaction_q1_NA":"不適用",
  "patient_pedia_knee_satisfaction_q1_a1":"很不滿意",
  "patient_pedia_knee_satisfaction_q1_a2":"有些不滿意",
  "patient_pedia_knee_satisfaction_q1_a3":"有些滿意",
  "patient_pedia_knee_satisfaction_q1_a4":"很滿意",
  "patient_pedia_knee_satisfaction_q2":"在改善您的疼痛方面？",
  "patient_pedia_knee_satisfaction_q2_NA":"不適用",
  "patient_pedia_knee_satisfaction_q2_a1":"很不滿意",
  "patient_pedia_knee_satisfaction_q2_a2":"有些不滿意",
  "patient_pedia_knee_satisfaction_q2_a3":"有些滿意",
  "patient_pedia_knee_satisfaction_q2_a4":"很滿意",
  "patient_pedia_knee_satisfaction_q3":"在提高您做家務或庭院工作的能力方面？",
  "patient_pedia_knee_satisfaction_q3_NA":"不適用",
  "patient_pedia_knee_satisfaction_q3_a1":"很不滿意",
  "patient_pedia_knee_satisfaction_q3_a2":"有些不滿意",
  "patient_pedia_knee_satisfaction_q3_a3":"有些滿意",
  "patient_pedia_knee_satisfaction_q3_a4":"很滿意",
  "patient_pedia_knee_satisfaction_q4":"在提高您進行休閒活動的能力方面？",
  "patient_pedia_knee_satisfaction_q4_NA":"不適用",
  "patient_pedia_knee_satisfaction_q4_a1":"很不滿意",
  "patient_pedia_knee_satisfaction_q4_a2":"有些不滿意",
  "patient_pedia_knee_satisfaction_q4_a3":"有些滿意",
  "patient_pedia_knee_satisfaction_q4_a4":"很滿意",
  "patient_pedia_knee_complete":"問卷結束",
  "patient_pedia_knee_complete_text1":"感謝您抽出寶貴時間填寫此問卷。",
  "patient_pedia_knee_complete_text2":"請點擊提交完成，並查看您的分數。",
  "patient_pedia_knee_complete_text3":"您的回答將被保密，任何報告都只會使用去除身份識別的資訊",
  "patient_pedia_knee_results":"結果",
  "patient_pedia_knee_results_text1":"以下是您的疼痛和膝關節功能評分，這些評分是根據您的調查問卷計算得出的。",
  "patient_pedia_knee_results_pain":"疼痛",
  "patient_pedia_knee_results_function":"功能",
  "patient_pedia_knee_results_self_evaluation":"膝蓋評分自我評估",
  "patient_pedia_knee_results_text2":"您的醫生很快就會與您聯繫",
  "patient_foot_ankle_pain":"疼痛",
  "patient_foot_ankle_pain_q1":"您的腳/腳踝目前的疼痛程度如何？",
  "patient_foot_ankle_pain_q1_0":"無",
  "patient_foot_ankle_pain_q1_1":"可以想像到的最嚴重的疼痛",
  "patient_foot_ankle_pain_q2":"對您的腳/腳踝疼痛的最佳描述是什麼？",
  "patient_foot_ankle_pain_q2_a1":"無",
  "patient_foot_ankle_pain_q2_a2":"輕微，偶爾",
  "patient_foot_ankle_pain_q2_a3":"中度，天天",
  "patient_foot_ankle_pain_q2_a4":"嚴重，大多時都有",
  "patient_foot_ankle_pain_q3":"您多常感到腳/腳踝疼痛？",
  "patient_foot_ankle_pain_q3_a1":"從未",
  "patient_foot_ankle_pain_q3_a2":"每月",
  "patient_foot_ankle_pain_q3_a3":"每週",
  "patient_foot_ankle_pain_q3_a4":"每天",
  "patient_foot_ankle_pain_q3_a5":"持續",
  "patient_foot_ankle_pain_instruction":"您在以下活動中經歷過多少程度的腳部/踝部疼痛：",
  "patient_foot_ankle_pain_instruction_0":"無",
  "patient_foot_ankle_pain_instruction_1":"輕微",
  "patient_foot_ankle_pain_instruction_2":"中度",
  "patient_foot_ankle_pain_instruction_3":"嚴重",
  "patient_foot_ankle_pain_instruction_4":"極度",
  "patient_foot_ankle_pain_q4":"一般疼痛程度",
  "patient_foot_ankle_pain_q4_a1":"無",
  "patient_foot_ankle_pain_q4_a2":"輕微",
  "patient_foot_ankle_pain_q4_a3":"中度",
  "patient_foot_ankle_pain_q4_a4":"嚴重",
  "patient_foot_ankle_pain_q4_a5":"極度",
  "patient_foot_ankle_pain_q5":"休息時",
  "patient_foot_ankle_pain_q5_a1":"無",
  "patient_foot_ankle_pain_q5_a2":"輕微",
  "patient_foot_ankle_pain_q5_a3":"中度",
  "patient_foot_ankle_pain_q5_a4":"嚴重",
  "patient_foot_ankle_pain_q5_a5":"極度",
  "patient_foot_ankle_pain_q6":"正常活動",
  "patient_foot_ankle_pain_q6_a1":"無",
  "patient_foot_ankle_pain_q6_a2":"輕微",
  "patient_foot_ankle_pain_q6_a3":"中度",
  "patient_foot_ankle_pain_q6_a4":"嚴重",
  "patient_foot_ankle_pain_q6_a5":"極度",
  "patient_foot_ankle_pain_q7":"早晨第一件事",
  "patient_foot_ankle_pain_q7_a1":"無",
  "patient_foot_ankle_pain_q7_a2":"輕微",
  "patient_foot_ankle_pain_q7_a3":"中度",
  "patient_foot_ankle_pain_q7_a4":"嚴重",
  "patient_foot_ankle_pain_q7_a5":"極度",
  "patient_foot_ankle_pain_q8":"扭轉/旋轉腳/腳踝",
  "patient_foot_ankle_pain_q8_a1":"無",
  "patient_foot_ankle_pain_q8_a2":"輕微",
  "patient_foot_ankle_pain_q8_a3":"中度",
  "patient_foot_ankle_pain_q8_a4":"嚴重",
  "patient_foot_ankle_pain_q8_a5":"極度",
  "patient_foot_ankle_pain_q9":"把腳/腳踝伸直",
  "patient_foot_ankle_pain_q9_a1":"無",
  "patient_foot_ankle_pain_q9_a2":"輕微",
  "patient_foot_ankle_pain_q9_a3":"中度",
  "patient_foot_ankle_pain_q9_a4":"嚴重",
  "patient_foot_ankle_pain_q9_a5":"極度",
  "patient_foot_ankle_pain_q10":"腳/腳踝完全彎曲",
  "patient_foot_ankle_pain_q10_a1":"無",
  "patient_foot_ankle_pain_q10_a2":"輕微",
  "patient_foot_ankle_pain_q10_a3":"中度",
  "patient_foot_ankle_pain_q10_a4":"嚴重",
  "patient_foot_ankle_pain_q10_a5":"極度",
  "patient_foot_ankle_pain_q11":"在平坦的表面步行",
  "patient_foot_ankle_pain_q11_a1":"無",
  "patient_foot_ankle_pain_q11_a2":"輕微",
  "patient_foot_ankle_pain_q11_a3":"中度",
  "patient_foot_ankle_pain_q11_a4":"嚴重",
  "patient_foot_ankle_pain_q11_a5":"極度",
  "patient_foot_ankle_pain_q12":"上下樓梯",
  "patient_foot_ankle_pain_q12_a1":"無",
  "patient_foot_ankle_pain_q12_a2":"輕微",
  "patient_foot_ankle_pain_q12_a3":"中度",
  "patient_foot_ankle_pain_q12_a4":"嚴重",
  "patient_foot_ankle_pain_q12_a5":"極度",
  "patient_foot_ankle_pain_q13":"晚間在床上",
  "patient_foot_ankle_pain_q13_a1":"無",
  "patient_foot_ankle_pain_q13_a2":"輕微",
  "patient_foot_ankle_pain_q13_a3":"中度",
  "patient_foot_ankle_pain_q13_a4":"嚴重",
  "patient_foot_ankle_pain_q13_a5":"極度",
  "patient_foot_ankle_pain_q14":"坐下或躺著",
  "patient_foot_ankle_pain_q14_a1":"無",
  "patient_foot_ankle_pain_q14_a2":"輕微",
  "patient_foot_ankle_pain_q14_a3":"中度",
  "patient_foot_ankle_pain_q14_a4":"嚴重",
  "patient_foot_ankle_pain_q14_a5":"極度",
  "patient_foot_ankle_pain_q15":"站立",
  "patient_foot_ankle_pain_q15_a1":"無",
  "patient_foot_ankle_pain_q15_a2":"輕微",
  "patient_foot_ankle_pain_q15_a3":"中度",
  "patient_foot_ankle_pain_q15_a4":"嚴重",
  "patient_foot_ankle_pain_q15_a5":"極度",
  "patient_foot_ankle_instability":"不穩定",
  "patient_foot_ankle_instability_q1":"您是否經歷過反覆扭傷、腳踝撐不住，或感覺腳踝即將撐不住？",
  "patient_foot_ankle_instability_q1_a1":"否",
  "patient_foot_ankle_instability_q1_a2":"是",
  "patient_foot_ankle_instability_q2":"您是否曾被診斷為腳踝不穩/反覆扭傷/重複接受治療？",
  "patient_foot_ankle_instability_q2_a1":"否",
  "patient_foot_ankle_instability_q2_a2":"是",
  "patient_foot_ankle_instability_q3":"您未受影響的腳踝是否感覺完全穩定？",
  "patient_foot_ankle_instability_q3_a1":"否",
  "patient_foot_ankle_instability_q3_a2":"是",
  "patient_foot_ankle_cumberland":"CUMBERLAND 踝關節不穩定指數",
  "patient_foot_ankle_cumberland_instruction":"請在每個問題中選擇最能描述您腳踝的一項陳述。",
  "patient_foot_ankle_cumberland_q1":"我的腳踝有疼痛",
  "patient_foot_ankle_cumberland_q1_a1":"從未",
  "patient_foot_ankle_cumberland_q1_a2":"做運動時",
  "patient_foot_ankle_cumberland_q1_a3":"在不平坦的表面上跑",
  "patient_foot_ankle_cumberland_q1_a4":"在平坦的表面上跑",
  "patient_foot_ankle_cumberland_q1_a5":"在不平坦的表面上步行",
  "patient_foot_ankle_cumberland_q1_a6":"在平坦的表面上步行",
  "patient_foot_ankle_cumberland_q2":"我的腳踝感覺不穩定",
  "patient_foot_ankle_cumberland_q2_a1":"從未",
  "patient_foot_ankle_cumberland_q2_a2":"運動中有時候（不是每一次）",
  "patient_foot_ankle_cumberland_q2_a3":"運動中經常（每一次）",
  "patient_foot_ankle_cumberland_q2_a4":"日常活動中有時候",
  "patient_foot_ankle_cumberland_q2_a5":"日常活動中經常",
  "patient_foot_ankle_cumberland_q3":"當我急轉彎時，我的腳踝感覺不穩定",
  "patient_foot_ankle_cumberland_q3_a1":"從未",
  "patient_foot_ankle_cumberland_q3_a2":"跑步中有時候",
  "patient_foot_ankle_cumberland_q3_a3":"跑步中經常",
  "patient_foot_ankle_cumberland_q3_a4":"步行時",
  "patient_foot_ankle_cumberland_q4":"下樓梯時，我的腳踝感覺不穩定",
  "patient_foot_ankle_cumberland_q4_a1":"從未",
  "patient_foot_ankle_cumberland_q4_a2":"如果我走得快的話",
  "patient_foot_ankle_cumberland_q4_a3":"偶爾",
  "patient_foot_ankle_cumberland_q4_a4":"經常",
  "patient_foot_ankle_cumberland_q5":"我在單腿站立時，腳踝感覺不穩定",
  "patient_foot_ankle_cumberland_q5_a1":"從未",
  "patient_foot_ankle_cumberland_q5_a2":"壓在腳前掌上",
  "patient_foot_ankle_cumberland_q5_a3":"用放平的腳底",
  "patient_foot_ankle_cumberland_q6":"在以下的什麼時刻，我的腳踝感覺不穩定？",
  "patient_foot_ankle_cumberland_q6_a1":"從未",
  "patient_foot_ankle_cumberland_q6_a2":"往側邊跳",
  "patient_foot_ankle_cumberland_q6_a3":"在原地跳",
  "patient_foot_ankle_cumberland_q6_a4":"小跳躍",
  "patient_foot_ankle_cumberland_q7":"在以下的什麼時刻，我的腳踝感覺不穩定？",
  "patient_foot_ankle_cumberland_q7_a1":"從未",
  "patient_foot_ankle_cumberland_q7_a2":"在不平坦的表面上跑",
  "patient_foot_ankle_cumberland_q7_a3":"在不平坦的表面上慢跑",
  "patient_foot_ankle_cumberland_q7_a4":"在不平坦的表面上步行",
  "patient_foot_ankle_cumberland_q7_a5":"在平坦的表面步行",
  "patient_foot_ankle_cumberland_q8":"通常，當我的腳踝開始折到（或“扭到”）時，我可以阻止它",
  "patient_foot_ankle_cumberland_q8_a1":"立刻",
  "patient_foot_ankle_cumberland_q8_a2":"經常",
  "patient_foot_ankle_cumberland_q8_a3":"有時候",
  "patient_foot_ankle_cumberland_q8_a4":"從未",
  "patient_foot_ankle_cumberland_q8_a5":"我的腳踝不曾折到過",
  "patient_foot_ankle_cumberland_q9":"在我的腳踝發生一次典型的折到事件後，我的腳踝恢復了“正常”",
  "patient_foot_ankle_cumberland_q9_a1":"幾乎是立刻",
  "patient_foot_ankle_cumberland_q9_a2":"少過一天",
  "patient_foot_ankle_cumberland_q9_a3":"一至兩天",
  "patient_foot_ankle_cumberland_q9_a4":"超過兩天",
  "patient_foot_ankle_cumberland_q9_a5":"我的腳踝不曾折到過",
  "patient_foot_ankle_other_symptoms":"其他症狀",
  "patient_foot_ankle_other_symptoms_instruction1":"請選擇最符合您的腳/腳踝症狀的答案。",
  "patient_foot_ankle_other_symptoms_instruction2":"如果您不確定如何回答問題，請盡可能給出最佳答案。",
  "patient_foot_ankle_other_symptoms_note":"注意：回答這些問題時應考慮您上週的足部/踝部症狀。",
  "patient_foot_ankle_other_symptoms_instruction3":"您是否經驗到以下情況：",
  "patient_foot_ankle_other_symptoms_instruction3_0":"從未",
  "patient_foot_ankle_other_symptoms_instruction3_1":"偶爾",
  "patient_foot_ankle_other_symptoms_instruction3_2":"有時候",
  "patient_foot_ankle_other_symptoms_instruction3_3":"經常",
  "patient_foot_ankle_other_symptoms_instruction3_4":"持續",
  "patient_foot_ankle_other_symptoms_q1":"腳/腳踝腫脹",
  "patient_foot_ankle_other_symptoms_q1_a1":"從未",
  "patient_foot_ankle_other_symptoms_q1_a2":"偶爾",
  "patient_foot_ankle_other_symptoms_q1_a3":"有時候",
  "patient_foot_ankle_other_symptoms_q1_a4":"經常",
  "patient_foot_ankle_other_symptoms_q1_a5":"持續",
  "patient_foot_ankle_other_symptoms_q2":"腳/腳踝移動時發出摩擦聲、咔噠聲或聽到任何類型的噪音",
  "patient_foot_ankle_other_symptoms_q2_a1":"從未",
  "patient_foot_ankle_other_symptoms_q2_a2":"偶爾",
  "patient_foot_ankle_other_symptoms_q2_a3":"有時候",
  "patient_foot_ankle_other_symptoms_q2_a4":"經常",
  "patient_foot_ankle_other_symptoms_q2_a5":"持續",
  "patient_foot_ankle_other_symptoms_q3":"移動時卡住",
  "patient_foot_ankle_other_symptoms_q3_a1":"從未",
  "patient_foot_ankle_other_symptoms_q3_a2":"偶爾",
  "patient_foot_ankle_other_symptoms_q3_a3":"有時候",
  "patient_foot_ankle_other_symptoms_q3_a4":"經常",
  "patient_foot_ankle_other_symptoms_q3_a5":"持續",
  "patient_foot_ankle_other_symptoms_instruction4":"您的腳/腳踝僵硬程度有多嚴重：",
  "patient_foot_ankle_other_symptoms_instruction4_0":"無",
  "patient_foot_ankle_other_symptoms_instruction4_1":"輕微",
  "patient_foot_ankle_other_symptoms_instruction4_2":"中度",
  "patient_foot_ankle_other_symptoms_instruction4_3":"嚴重",
  "patient_foot_ankle_other_symptoms_instruction4_4":"極度",
  "patient_foot_ankle_other_symptoms_q4":"早上第一次醒來後",
  "patient_foot_ankle_other_symptoms_q4_a1":"無",
  "patient_foot_ankle_other_symptoms_q4_a2":"輕微",
  "patient_foot_ankle_other_symptoms_q4_a3":"中度",
  "patient_foot_ankle_other_symptoms_q4_a4":"嚴重",
  "patient_foot_ankle_other_symptoms_q4_a5":"極度",
  "patient_foot_ankle_other_symptoms_q5":"當天晚些時候坐著、躺著或休息後",
  "patient_foot_ankle_other_symptoms_q5_a1":"無",
  "patient_foot_ankle_other_symptoms_q5_a2":"輕微",
  "patient_foot_ankle_other_symptoms_q5_a3":"中度",
  "patient_foot_ankle_other_symptoms_q5_a4":"嚴重",
  "patient_foot_ankle_other_symptoms_q5_a5":"極度",
  "patient_foot_ankle_function":"功能",
  "patient_foot_ankle_function_q1":"由於您的腳/腳踝，您在日常生活中的活動有哪些限制？",
  "patient_foot_ankle_function_q1_a1":"沒有限制，不需要支撐",
  "patient_foot_ankle_function_q1_a2":"日常活動不受限，娛樂活動受限，不需要支撐",
  "patient_foot_ankle_function_q1_a3":"有限的日常和娛樂活動，手杖",
  "patient_foot_ankle_function_q1_a4":"日常和娛樂活動嚴重受限，需使用助行器、拐杖、輪椅、支架",
  "patient_foot_ankle_function_q2":"您通常穿什麼鞋？",
  "patient_foot_ankle_function_q2_a1":"時尚、傳統的鞋子，無需鞋墊",
  "patient_foot_ankle_function_q2_a2":"舒適鞋、鞋墊",
  "patient_foot_ankle_function_q2_a3":"改良的鞋子或護具",
  "patient_foot_ankle_function_q3":"什麼樣的步行表面會讓您行走困難？",
  "patient_foot_ankle_function_q3_a1":"各種表面都沒有問題",
  "patient_foot_ankle_function_q3_a2":"在不平坦的地形、樓梯、斜坡、梯子上有一些困難",
  "patient_foot_ankle_function_q3_a3":"在不平坦的地形、樓梯、斜坡、梯子上有嚴重困難",
  "patient_foot_ankle_function_instruction":"請標明您上週因腳/腳踝而經歷的困難程度",
  "patient_foot_ankle_function_instruction_0":"沒有困難",
  "patient_foot_ankle_function_instruction_1":"一些困難",
  "patient_foot_ankle_function_instruction_2":"中度困難",
  "patient_foot_ankle_function_instruction_3":"極度困難",
  "patient_foot_ankle_function_instruction_4":"無法辦到",
  "patient_foot_ankle_function_note":"注意：如果相關活動受到腳或腳踝以外的其他因素的限制，請在可以選擇的項目中標記“不適用”。否則，請用最能描述您在過去一周內的狀況的答案來回答每個問題。",
  "patient_foot_ankle_function_q4":"把腳/腳踝伸直",
  "patient_foot_ankle_function_q4_a1":"沒有困難",
  "patient_foot_ankle_function_q4_a2":"一些困難",
  "patient_foot_ankle_function_q4_a3":"中度困難",
  "patient_foot_ankle_function_q4_a4":"極度困難",
  "patient_foot_ankle_function_q4_a5":"無法辦到",
  "patient_foot_ankle_function_q5":"完全屈腳/腳踝",
  "patient_foot_ankle_function_q5_a1":"沒有困難",
  "patient_foot_ankle_function_q5_a2":"一些困難",
  "patient_foot_ankle_function_q5_a3":"中度困難",
  "patient_foot_ankle_function_q5_a4":"極度困難",
  "patient_foot_ankle_function_q5_a5":"無法辦到",
  "patient_foot_ankle_function_q6":"下樓梯",
  "patient_foot_ankle_function_q6_a1":"沒有困難",
  "patient_foot_ankle_function_q6_a2":"一些困難",
  "patient_foot_ankle_function_q6_a3":"中度困難",
  "patient_foot_ankle_function_q6_a4":"極度困難",
  "patient_foot_ankle_function_q6_a5":"無法辦到",
  "patient_foot_ankle_function_q7":"上樓梯",
  "patient_foot_ankle_function_q7_a1":"沒有困難",
  "patient_foot_ankle_function_q7_a2":"一些困難",
  "patient_foot_ankle_function_q7_a3":"中度困難",
  "patient_foot_ankle_function_q7_a4":"極度困難",
  "patient_foot_ankle_function_q7_a5":"無法辦到",
  "patient_foot_ankle_function_q8":"從坐著站起來",
  "patient_foot_ankle_function_q8_a1":"沒有困難",
  "patient_foot_ankle_function_q8_a2":"一些困難",
  "patient_foot_ankle_function_q8_a3":"中度困難",
  "patient_foot_ankle_function_q8_a4":"極度困難",
  "patient_foot_ankle_function_q8_a5":"無法辦到",
  "patient_foot_ankle_function_q9":"站立",
  "patient_foot_ankle_function_q9_a1":"沒有困難",
  "patient_foot_ankle_function_q9_a2":"一些困難",
  "patient_foot_ankle_function_q9_a3":"中度困難",
  "patient_foot_ankle_function_q9_a4":"極度困難",
  "patient_foot_ankle_function_q9_a5":"無法辦到",
  "patient_foot_ankle_function_q10":"彎腰去摸地板/撿東西",
  "patient_foot_ankle_function_q10_a1":"沒有困難",
  "patient_foot_ankle_function_q10_a2":"一些困難",
  "patient_foot_ankle_function_q10_a3":"中度困難",
  "patient_foot_ankle_function_q10_a4":"極度困難",
  "patient_foot_ankle_function_q10_a5":"無法辦到",
  "patient_foot_ankle_function_q11":"在平面步行",
  "patient_foot_ankle_function_q11_a1":"沒有困難",
  "patient_foot_ankle_function_q11_a2":"一些困難",
  "patient_foot_ankle_function_q11_a3":"中度困難",
  "patient_foot_ankle_function_q11_a4":"極度困難",
  "patient_foot_ankle_function_q11_a5":"無法辦到",
  "patient_foot_ankle_function_q12":"您可以步行的最大距離是多少？",
  "patient_foot_ankle_function_q12_a1":"大於六個城市街區距離",
  "patient_foot_ankle_function_q12_a2":"四至六個城市街區距離",
  "patient_foot_ankle_function_q12_a3":"一至三個城市街區距離",
  "patient_foot_ankle_function_q12_a4":"少過一個城市街區距離",
  "patient_foot_ankle_function_q13":"在不平坦的地面上步行 （不穿鞋）",
  "patient_foot_ankle_function_q13_a1":"沒有困難",
  "patient_foot_ankle_function_q13_a2":"一些困難",
  "patient_foot_ankle_function_q13_a3":"中度困難",
  "patient_foot_ankle_function_q13_a4":"極度困難",
  "patient_foot_ankle_function_q13_a5":"無法辦到",
  "patient_foot_ankle_function_q14":"走上山坡",
  "patient_foot_ankle_function_q14_a1":"沒有困難",
  "patient_foot_ankle_function_q14_a2":"一些困難",
  "patient_foot_ankle_function_q14_a3":"中度困難",
  "patient_foot_ankle_function_q14_a4":"極度困難",
  "patient_foot_ankle_function_q14_a5":"無法辦到",
  "patient_foot_ankle_function_q15":"走下山坡",
  "patient_foot_ankle_function_q15_a1":"沒有困難",
  "patient_foot_ankle_function_q15_a2":"一些困難",
  "patient_foot_ankle_function_q15_a3":"中度困難",
  "patient_foot_ankle_function_q15_a4":"極度困難",
  "patient_foot_ankle_function_q15_a5":"無法辦到",
  "patient_foot_ankle_function_q16":"在不平坦的地面上步行",
  "patient_foot_ankle_function_q16_a1":"沒有困難",
  "patient_foot_ankle_function_q16_a2":"一些困難",
  "patient_foot_ankle_function_q16_a3":"中度困難",
  "patient_foot_ankle_function_q16_a4":"極度困難",
  "patient_foot_ankle_function_q16_a5":"無法辦到",
  "patient_foot_ankle_function_q17":"走上走下人行道",
  "patient_foot_ankle_function_q17_a1":"沒有困難",
  "patient_foot_ankle_function_q17_a2":"一些困難",
  "patient_foot_ankle_function_q17_a3":"中度困難",
  "patient_foot_ankle_function_q17_a4":"極度困難",
  "patient_foot_ankle_function_q17_a5":"無法辦到",
  "patient_foot_ankle_function_q18":"踮起腳尖",
  "patient_foot_ankle_function_q18_a1":"沒有困難",
  "patient_foot_ankle_function_q18_a2":"一些困難",
  "patient_foot_ankle_function_q18_a3":"中度困難",
  "patient_foot_ankle_function_q18_a4":"極度困難",
  "patient_foot_ankle_function_q18_a5":"無法辦到",
  "patient_foot_ankle_function_q19":"開始步行",
  "patient_foot_ankle_function_q19_a1":"沒有困難",
  "patient_foot_ankle_function_q19_a2":"一些困難",
  "patient_foot_ankle_function_q19_a3":"中度困難",
  "patient_foot_ankle_function_q19_a4":"極度困難",
  "patient_foot_ankle_function_q19_a5":"無法辦到",
  "patient_foot_ankle_function_q20":"步行五分鐘以下",
  "patient_foot_ankle_function_q20_a1":"沒有困難",
  "patient_foot_ankle_function_q20_a2":"一些困難",
  "patient_foot_ankle_function_q20_a3":"中度困難",
  "patient_foot_ankle_function_q20_a4":"極度困難",
  "patient_foot_ankle_function_q20_a5":"無法辦到",
  "patient_foot_ankle_function_q21":"步行大約十分鐘",
  "patient_foot_ankle_function_q21_a1":"沒有困難",
  "patient_foot_ankle_function_q21_a2":"一些困難",
  "patient_foot_ankle_function_q21_a3":"中度困難",
  "patient_foot_ankle_function_q21_a4":"極度困難",
  "patient_foot_ankle_function_q21_a5":"無法辦到",
  "patient_foot_ankle_function_q22":"步行超過十五分鐘",
  "patient_foot_ankle_function_q22_a1":"沒有困難",
  "patient_foot_ankle_function_q22_a2":"一些困難",
  "patient_foot_ankle_function_q22_a3":"中度困難",
  "patient_foot_ankle_function_q22_a4":"極度困難",
  "patient_foot_ankle_function_q22_a5":"無法辦到",
  "patient_foot_ankle_function_q23":"進出車子",
  "patient_foot_ankle_function_q23_a1":"沒有困難",
  "patient_foot_ankle_function_q23_a2":"一些困難",
  "patient_foot_ankle_function_q23_a3":"中度困難",
  "patient_foot_ankle_function_q23_a4":"極度困難",
  "patient_foot_ankle_function_q23_a5":"無法辦到",
  "patient_foot_ankle_function_q24":"去購物",
  "patient_foot_ankle_function_q24_a1":"沒有困難",
  "patient_foot_ankle_function_q24_a2":"一些困難",
  "patient_foot_ankle_function_q24_a3":"中度困難",
  "patient_foot_ankle_function_q24_a4":"極度困難",
  "patient_foot_ankle_function_q24_a5":"無法辦到",
  "patient_foot_ankle_function_q25":"穿上襪子/絲襪",
  "patient_foot_ankle_function_q25_a1":"沒有困難",
  "patient_foot_ankle_function_q25_a2":"一些困難",
  "patient_foot_ankle_function_q25_a3":"中度困難",
  "patient_foot_ankle_function_q25_a4":"極度困難",
  "patient_foot_ankle_function_q25_a5":"無法辦到",
  "patient_foot_ankle_function_q26":"脫下襪子/絲襪",
  "patient_foot_ankle_function_q26_a1":"沒有困難",
  "patient_foot_ankle_function_q26_a2":"一些困難",
  "patient_foot_ankle_function_q26_a3":"中度困難",
  "patient_foot_ankle_function_q26_a4":"極度困難",
  "patient_foot_ankle_function_q26_a5":"無法辦到",
  "patient_foot_ankle_function_q27":"從床上起身",
  "patient_foot_ankle_function_q27_a1":"沒有困難",
  "patient_foot_ankle_function_q27_a2":"一些困難",
  "patient_foot_ankle_function_q27_a3":"中度困難",
  "patient_foot_ankle_function_q27_a4":"極度困難",
  "patient_foot_ankle_function_q27_a5":"無法辦到",
  "patient_foot_ankle_function_q28":"躺在床上 （翻身，保持腳/腳踝的姿勢）",
  "patient_foot_ankle_function_q28_a1":"沒有困難",
  "patient_foot_ankle_function_q28_a2":"一些困難",
  "patient_foot_ankle_function_q28_a3":"中度困難",
  "patient_foot_ankle_function_q28_a4":"極度困難",
  "patient_foot_ankle_function_q28_a5":"無法辦到",
  "patient_foot_ankle_function_q29":"睡覺",
  "patient_foot_ankle_function_q29_a1":"沒有困難",
  "patient_foot_ankle_function_q29_a2":"一些困難",
  "patient_foot_ankle_function_q29_a3":"中度困難",
  "patient_foot_ankle_function_q29_a4":"極度困難",
  "patient_foot_ankle_function_q29_a5":"無法辦到",
  "patient_foot_ankle_function_q30":"進出浴缸",
  "patient_foot_ankle_function_q30_a1":"沒有困難",
  "patient_foot_ankle_function_q30_a2":"一些困難",
  "patient_foot_ankle_function_q30_a3":"中度困難",
  "patient_foot_ankle_function_q30_a4":"極度困難",
  "patient_foot_ankle_function_q30_a5":"無法辦到",
  "patient_foot_ankle_function_q31":"坐下",
  "patient_foot_ankle_function_q31_a1":"沒有困難",
  "patient_foot_ankle_function_q31_a2":"一些困難",
  "patient_foot_ankle_function_q31_a3":"中度困難",
  "patient_foot_ankle_function_q31_a4":"極度困難",
  "patient_foot_ankle_function_q31_a5":"無法辦到",
  "patient_foot_ankle_function_q32":"上下馬桶",
  "patient_foot_ankle_function_q32_a1":"沒有困難",
  "patient_foot_ankle_function_q32_a2":"一些困難",
  "patient_foot_ankle_function_q32_a3":"中度困難",
  "patient_foot_ankle_function_q32_a4":"極度困難",
  "patient_foot_ankle_function_q32_a5":"無法辦到",
  "patient_foot_ankle_function_q33":"做家務",
  "patient_foot_ankle_function_q33_a1":"沒有困難",
  "patient_foot_ankle_function_q33_a2":"一些困難",
  "patient_foot_ankle_function_q33_a3":"中度困難",
  "patient_foot_ankle_function_q33_a4":"極度困難",
  "patient_foot_ankle_function_q33_a5":"無法辦到",
  "patient_foot_ankle_function_q34":"日常活動",
  "patient_foot_ankle_function_q34_a1":"沒有困難",
  "patient_foot_ankle_function_q34_a2":"一些困難",
  "patient_foot_ankle_function_q34_a3":"中度困難",
  "patient_foot_ankle_function_q34_a4":"極度困難",
  "patient_foot_ankle_function_q34_a5":"無法辦到",
  "patient_foot_ankle_function_q35":"個人護理（包括自我清洗或穿衣）",
  "patient_foot_ankle_function_q35_a1":"沒有困難",
  "patient_foot_ankle_function_q35_a2":"一些困難",
  "patient_foot_ankle_function_q35_a3":"中度困難",
  "patient_foot_ankle_function_q35_a4":"極度困難",
  "patient_foot_ankle_function_q35_a5":"無法辦到",
  "patient_foot_ankle_function_q36":"繁重的工作（推/拉、攀爬、搬運、移動重箱、擦地板等）",
  "patient_foot_ankle_function_q36_a1":"沒有困難",
  "patient_foot_ankle_function_q36_a2":"一些困難",
  "patient_foot_ankle_function_q36_a3":"中度困難",
  "patient_foot_ankle_function_q36_a4":"極度困難",
  "patient_foot_ankle_function_q36_a5":"無法辦到",
  "patient_foot_ankle_function_q37":"輕微的家務勞動（做飯、除塵等）",
  "patient_foot_ankle_function_q37_a1":"沒有困難",
  "patient_foot_ankle_function_q37_a2":"一些困難",
  "patient_foot_ankle_function_q37_a3":"中度困難",
  "patient_foot_ankle_function_q37_a4":"極度困難",
  "patient_foot_ankle_function_q37_a5":"無法辦到",
  "patient_foot_ankle_function_q38":"輕度至中度工作（站立、行走）",
  "patient_foot_ankle_function_q38_a1":"沒有困難",
  "patient_foot_ankle_function_q38_a2":"一些困難",
  "patient_foot_ankle_function_q38_a3":"中度困難",
  "patient_foot_ankle_function_q38_a4":"極度困難",
  "patient_foot_ankle_function_q38_a5":"無法辦到",
  "patient_foot_ankle_function_q39":"休閒活動",
  "patient_foot_ankle_function_q39_a1":"沒有困難",
  "patient_foot_ankle_function_q39_a2":"一些困難",
  "patient_foot_ankle_function_q39_a3":"中度困難",
  "patient_foot_ankle_function_q39_a4":"極度困難",
  "patient_foot_ankle_function_q39_a5":"無法辦到",
  "patient_foot_ankle_function_q40":"蹲下",
  "patient_foot_ankle_function_q40_a1":"沒有困難",
  "patient_foot_ankle_function_q40_a2":"一些困難",
  "patient_foot_ankle_function_q40_a3":"中度困難",
  "patient_foot_ankle_function_q40_a4":"極度困難",
  "patient_foot_ankle_function_q40_a5":"無法辦到",
  "patient_foot_ankle_function_q41":"扭轉/旋轉腳/腳踝",
  "patient_foot_ankle_function_q41_a1":"沒有困難",
  "patient_foot_ankle_function_q41_a2":"一些困難",
  "patient_foot_ankle_function_q41_a3":"中度困難",
  "patient_foot_ankle_function_q41_a4":"極度困難",
  "patient_foot_ankle_function_q41_a5":"無法辦到",
  "patient_foot_ankle_function_q42":"跪下",
  "patient_foot_ankle_function_q42_a1":"沒有困難",
  "patient_foot_ankle_function_q42_a2":"一些困難",
  "patient_foot_ankle_function_q42_a3":"中度困難",
  "patient_foot_ankle_function_q42_a4":"極度困難",
  "patient_foot_ankle_function_q42_a5":"無法辦到",
  "patient_foot_ankle_function_q43":"奔跑",
  "patient_foot_ankle_function_q43_not_applicable":"不適用",
  "patient_foot_ankle_function_q43_a1":"沒有困難",
  "patient_foot_ankle_function_q43_a2":"一些困難",
  "patient_foot_ankle_function_q43_a3":"中度困難",
  "patient_foot_ankle_function_q43_a4":"極度困難",
  "patient_foot_ankle_function_q43_a5":"無法辦到",
  "patient_foot_ankle_function_q44":"跳躍",
  "patient_foot_ankle_function_q44_not_applicable":"不適用",
  "patient_foot_ankle_function_q44_a1":"沒有困難",
  "patient_foot_ankle_function_q44_a2":"一些困難",
  "patient_foot_ankle_function_q44_a3":"中度困難",
  "patient_foot_ankle_function_q44_a4":"極度困難",
  "patient_foot_ankle_function_q44_a5":"無法辦到",
  "patient_foot_ankle_function_q45":"落地",
  "patient_foot_ankle_function_q45_not_applicable":"不適用",
  "patient_foot_ankle_function_q45_a1":"沒有困難",
  "patient_foot_ankle_function_q45_a2":"一些困難",
  "patient_foot_ankle_function_q45_a3":"中度困難",
  "patient_foot_ankle_function_q45_a4":"極度困難",
  "patient_foot_ankle_function_q45_a5":"無法辦到",
  "patient_foot_ankle_function_q46":"快速開始和停止動作",
  "patient_foot_ankle_function_q46_not_applicable":"不適用",
  "patient_foot_ankle_function_q46_a1":"沒有困難",
  "patient_foot_ankle_function_q46_a2":"一些困難",
  "patient_foot_ankle_function_q46_a3":"中度困難",
  "patient_foot_ankle_function_q46_a4":"極度困難",
  "patient_foot_ankle_function_q46_a5":"無法辦到",
  "patient_foot_ankle_function_q47":"切入/橫向運動",
  "patient_foot_ankle_function_q47_not_applicable":"不適用",
  "patient_foot_ankle_function_q47_a1":"沒有困難",
  "patient_foot_ankle_function_q47_a2":"一些困難",
  "patient_foot_ankle_function_q47_a3":"中度困難",
  "patient_foot_ankle_function_q47_a4":"極度困難",
  "patient_foot_ankle_function_q47_a5":"無法辦到",
  "patient_foot_ankle_function_q48":"低衝擊力活動",
  "patient_foot_ankle_function_q48_not_applicable":"不適用",
  "patient_foot_ankle_function_q48_a1":"沒有困難",
  "patient_foot_ankle_function_q48_a2":"一些困難",
  "patient_foot_ankle_function_q48_a3":"中度困難",
  "patient_foot_ankle_function_q48_a4":"極度困難",
  "patient_foot_ankle_function_q48_a5":"無法辦到",
  "patient_foot_ankle_function_q49":"能夠以正常技巧進行活動",
  "patient_foot_ankle_function_q49_not_applicable":"不適用",
  "patient_foot_ankle_function_q49_a1":"沒有困難",
  "patient_foot_ankle_function_q49_a2":"一些困難",
  "patient_foot_ankle_function_q49_a3":"中度困難",
  "patient_foot_ankle_function_q49_a4":"極度困難",
  "patient_foot_ankle_function_q49_a5":"無法辦到",
  "patient_foot_ankle_function_q50":"可以參加您喜歡的運動, 沒有時間限制",
  "patient_foot_ankle_function_q50_not_applicable":"不適用",
  "patient_foot_ankle_function_q50_a1":"沒有困難",
  "patient_foot_ankle_function_q50_a2":"一些困難",
  "patient_foot_ankle_function_q50_a3":"中度困難",
  "patient_foot_ankle_function_q50_a4":"極度困難",
  "patient_foot_ankle_function_q50_a5":"無法辦到",
  "patient_foot_ankle_function_q51":"您會給自己今天的腳踝/足部打幾分？ （0到100%，100%為正常）",
  "patient_foot_ankle_function_q51_a1":"正常",
  "patient_foot_ankle_function_q52":"您可以定期參加的最高水準的活動是什麼？",
  "patient_foot_ankle_function_q52_q1":"在腳或腳踝受傷/出現問題之前",
  "patient_foot_ankle_function_q52_q1_a1":"頂級運動",
  "patient_foot_ankle_function_q52_q1_a2":"普通水準的競爭性運動",
  "patient_foot_ankle_function_q52_q1_a3":"休閒運動",
  "patient_foot_ankle_function_q52_q1_a4":"無體育活動",
  "patient_foot_ankle_function_q52_q1_a5":"無運動，無工作",
  "patient_foot_ankle_function_q52_q1_a4_o7":"無法行走，因腳踝問題而殘疾",
  "patient_foot_ankle_function_q52_q1_a1_title":"頂級（國際精英、職業、國家隊或甲級）",
  "patient_foot_ankle_function_q52_q1_a2_title":"普通水準的競爭性運動",
  "patient_foot_ankle_function_q52_q1_a3_title":"休閒",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1":"有氧運動、健身",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2":"高山滑雪和單板滑雪",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3":"美式足球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4":"羽球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5":"棒球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6":"籃球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7":"保齡球/冰壺",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08":"拳擊",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9":"越野跑",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10":"騎自行車",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11":"跳舞",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12":"潛水",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13":"馬術",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14":"擊劍",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15":"軟式曲棍球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16":"自由式滑雪板",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17":"高爾夫球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18":"體操",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19":"手球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20":"曲棍球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21":"冰球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22":"合球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23":"武術",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24":"現代五項",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25":"賽車運動、技術運動",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26":"山地運動和爬山",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27":"山地自行車/小輪車",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28":"北歐滑雪",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29":"定向運動",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30":"跳傘",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31":"健力",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32":"韻律體操",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33":"划船、皮划艇",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34":"橄欖球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35":"帆船運動",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36":"水肺潛水",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37":"射擊、射箭",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38":"滑冰、直排輪滑",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39":"足球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40":"壘球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41":"壁球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42":"衝浪、風帆衝浪",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43":"乒乓球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44":"網球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45":"田徑：田項目",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46":"田徑：徑項目",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47":"鐵人三項",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48":"排球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49":"水球和游泳",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50":"滑水",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51":"舉重、健身",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52":"摔角",
  "patient_foot_ankle_function_q52_q1_a4_o1":"重體力勞動",
  "patient_foot_ankle_function_q52_q1_a4_o2":"體力勞動",
  "patient_foot_ankle_function_q52_q1_a4_o3":"特殊職業：芭蕾舞演員、職業軍人、特種救援人員、替身演員",
  "patient_foot_ankle_function_q52_q1_a4_o4":"能夠在任何不平坦的地面上行走",
  "patient_foot_ankle_function_q52_q1_a4_o5":"日常活動不受限制",
  "patient_foot_ankle_function_q52_q1_a4_o6":"能夠在平坦的地面上行走，但日常活動受到限制",
  "patient_foot_ankle_function_q52_q2":"當前狀態",
  "patient_foot_ankle_function_q52_q2_a1":"頂級",
  "patient_foot_ankle_function_q52_q2_a2":"普通水準的競爭性運動",
  "patient_foot_ankle_function_q52_q2_a3":"休閒",
  "patient_foot_ankle_function_q52_q2_a4":"沒有體育活動",
  "patient_foot_ankle_function_q52_q2_a5":"沒有運動，沒有工作",
  "patient_foot_ankle_function_q52_q2_a4_o7":"無法行走，因腳踝問題而殘疾",
  "patient_foot_ankle_function_q52_q2_a1_title":"頂級（國際精英、職業、國家隊或甲級）",
  "patient_foot_ankle_function_q52_q2_a2_title":"普通水準的競爭性運動",
  "patient_foot_ankle_function_q52_q2_a3_title":"休閒",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1":"有氧運動、健身",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2":"高山滑雪和單板滑雪",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3":"美式足球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4":"羽毛球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5":"棒球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6":"籃球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7":"保齡球/冰壺",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8":"拳擊",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9":"越野跑",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10":"騎自行車",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11":"跳舞",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12":"潛水",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13":"馬術",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14":"擊劍",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15":"軟式曲棍球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16":"自由式滑雪板",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17":"高爾夫球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18":"體操",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19":"手球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20":"曲棍球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21":"冰球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22":"合球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23":"武術",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24":"現代五項",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25":"賽車運動、技術運動",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26":"山地和爬山",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27":"山地自行車/小輪車",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28":"北歐滑雪",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29":"定向運動",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30":"跳傘",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31":"力量舉重",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32":"藝術體操",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33":"划船、皮划艇",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34":"橄欖球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35":"帆船運動",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36":"水肺潛水",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37":"射擊、射箭",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38":"滑冰、直排輪滑",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39":"足球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40":"壘球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41":"壁球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42":"衝浪、風帆衝浪",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43":"乒乓球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44":"網球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45":"田徑：田徑項目",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46":"田徑：田徑項目",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47":"鐵人三項",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48":"排球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49":"水球和游泳",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50":"滑水",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51":"舉重、健身",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52":"摔角",
  "patient_foot_ankle_function_q52_q2_a4_o1":"重體力勞動",
  "patient_foot_ankle_function_q52_q2_a4_o2":"體力勞動",
  "patient_foot_ankle_function_q52_q2_a4_o3":"特殊職業：芭蕾舞演員、職業軍人、特種救援人員、替身演員",
  "patient_foot_ankle_function_q52_q2_a4_o4":"能夠在任何不平坦的地面上行走",
  "patient_foot_ankle_function_q52_q2_a4_o5":"日常活動不受限制",
  "patient_foot_ankle_function_q52_q2_a4_o6":"能夠在平坦的地面上行走，各項活動受到限制",
  "patient_foot_ankle_quality_of_life":"生活品質",
  "patient_foot_ankle_quality_of_life_instructions":"以下問題詢問您可能對腳/腳踝問題感到的社交、情感和生活方式方面的擔憂。請想一想您大部分時間對腳/腳踝的感受如何。",
  "patient_foot_ankle_quality_of_life_q1":"您多常意識到自己的腳/腳踝問題？",
  "patient_foot_ankle_quality_of_life_q1_a1":"從未",
  "patient_foot_ankle_quality_of_life_q1_a2":"每月",
  "patient_foot_ankle_quality_of_life_q1_a3":"每週",
  "patient_foot_ankle_quality_of_life_q1_a4":"每天",
  "patient_foot_ankle_quality_of_life_q1_a5":"持續",
  "patient_foot_ankle_quality_of_life_q2":"您有刻意改變可能傷害腳/腳踝的生活型態嗎？",
  "patient_foot_ankle_quality_of_life_q2_a1":"一點也不",
  "patient_foot_ankle_quality_of_life_q2_a2":"稍微",
  "patient_foot_ankle_quality_of_life_q2_a3":"中度",
  "patient_foot_ankle_quality_of_life_q2_a4":"嚴重",
  "patient_foot_ankle_quality_of_life_q2_a5":"極度",
  "patient_foot_ankle_quality_of_life_q3":"您對自己的腳/腳踝缺乏信心造成了多少困擾？",
  "patient_foot_ankle_quality_of_life_q3_a1":"一點也不",
  "patient_foot_ankle_quality_of_life_q3_a2":"稍微",
  "patient_foot_ankle_quality_of_life_q3_a3":"中度",
  "patient_foot_ankle_quality_of_life_q3_a4":"嚴重",
  "patient_foot_ankle_quality_of_life_q3_a5":"極度",
  "patient_foot_ankle_quality_of_life_q4":"一般來說，您的腳/腳踝承受了多少困難？",
  "patient_foot_ankle_quality_of_life_q4_a1":"無",
  "patient_foot_ankle_quality_of_life_q4_a2":"稍微",
  "patient_foot_ankle_quality_of_life_q4_a3":"中度",
  "patient_foot_ankle_quality_of_life_q4_a4":"嚴重",
  "patient_foot_ankle_quality_of_life_q4_a5":"極度",
  "patient_foot_ankle_quality_of_life_q5":"您今天有多焦慮/抑鬱？",
  "patient_foot_ankle_quality_of_life_q5_a1":"不焦慮/抑鬱",
  "patient_foot_ankle_quality_of_life_q5_a2":"輕微地",
  "patient_foot_ankle_quality_of_life_q5_a3":"中度",
  "patient_foot_ankle_quality_of_life_q5_a4":"嚴重",
  "patient_foot_ankle_quality_of_life_q5_a5":"極度",
  "patient_foot_ankle_quality_of_life_q6":"您今天的健康狀況如何？ （100 表示最好的健康狀況，0 表示您可以想像的最差的健康狀況）",
  "patient_foot_ankle_quality_of_life_q6_a1":"最差健康",
  "patient_foot_ankle_quality_of_life_q6_a2":"最好健康",
  "patient_foot_ankle_satisfaction":"滿意度",
  "patient_foot_ankle_satisfaction_instruction":"您對足/踝部手術或治療的結果滿意嗎？",
  "patient_foot_ankle_satisfaction_instruction_a1":"很不滿意",
  "patient_foot_ankle_satisfaction_instruction_a2":"有些不滿意",
  "patient_foot_ankle_satisfaction_instruction_a3":"有些滿意",
  "patient_foot_ankle_satisfaction_instruction_a4":"很滿意",
  "patient_foot_ankle_satisfaction_q1":"在改善症狀方面？",
  "patient_foot_ankle_satisfaction_q1_not_applicable":"不適用",
  "patient_foot_ankle_satisfaction_q1_a1":"很不滿意",
  "patient_foot_ankle_satisfaction_q1_a2":"有些不滿意",
  "patient_foot_ankle_satisfaction_q1_a3":"有些滿意",
  "patient_foot_ankle_satisfaction_q1_a4":"很滿意",
  "patient_foot_ankle_satisfaction_q2":"在提高您進行日常活動的能力方面？",
  "patient_foot_ankle_satisfaction_q2_not_applicable":"不適用",
  "patient_foot_ankle_satisfaction_q2_a1":"很不滿意",
  "patient_foot_ankle_satisfaction_q2_a2":"有些不滿意",
  "patient_foot_ankle_satisfaction_q2_a3":"有些滿意",
  "patient_foot_ankle_satisfaction_q2_a4":"很滿意",
  "patient_foot_ankle_satisfaction_q3":"在讓您“忘記”自己的腳/腳踝問題方面？ （是否意識到腳/腳踝問題）",
  "patient_foot_ankle_satisfaction_q3_not_applicable":"不適用",
  "patient_foot_ankle_satisfaction_q3_a1":"很不滿意",
  "patient_foot_ankle_satisfaction_q3_a2":"有些不滿意",
  "patient_foot_ankle_satisfaction_q3_a3":"有些滿意",
  "patient_foot_ankle_satisfaction_q3_a4":"很滿意",
  "patient_foot_ankle_complete":"問卷結束",
  "patient_foot_ankle_complete_text1":"感謝您抽出寶貴時間填寫此問卷。",
  "patient_foot_ankle_complete_text2":"請點擊提交完成，並查看您的分數。",
  "patient_foot_ankle_complete_text3":"您的回答將被保密，任何報告都只會使用去除身份識別的資訊",
  "patient_foot_ankle_results":"結果",
  "patient_foot_ankle_results_text1":"以下是您的疼痛和足/踝功能評分，這些評分是根據您的調查問卷計算得出的。",
  "patient_foot_ankle_results_pain":"疼痛",
  "patient_foot_ankle_results_function":"功能",
  "patient_foot_ankle_results_overall_health_perception":"整體健康認知",
  "patient_foot_ankle_results_feeling_of_instability":"不穩定的感覺",
  "patient_foot_ankle_results_self_evaluation":"足/踝部評分自我評估",
  "patient_foot_ankle_results_text2":"您的醫生很快就會與您聯繫。",
  "patient_elbow_pain":"疼痛",
  "patient_elbow_pain_q1":"您的肘部是否感到疼痛？",
  "patient_elbow_pain_q1_a1":"否",
  "patient_elbow_pain_q1_a2":"是",
  "patient_elbow_pain_q2":"對您的肘部疼痛的最佳描述是什麼？",
  "patient_elbow_pain_q2_a1":"無或可以被忽略",
  "patient_elbow_pain_q2_a2":"輕度，有活動，不使用藥物/鎮痛藥或偶爾使用藥物/止痛藥",
  "patient_elbow_pain_q2_a3":"中度，有或沒有活動，每天使用鎮痛藥",
  "patient_elbow_pain_q2_a4":"休息時或夜間中度",
  "patient_elbow_pain_q2_a5":"嚴重休息、持續服藥、致殘",
  "patient_elbow_pain_instruction":"請評價您肘部的疼痛程度",
  "patient_elbow_pain_q3":"此時此刻",
  "patient_elbow_pain_q3_a1":"不痛",
  "patient_elbow_pain_q3_a2":"最嚴重的疼痛，難以忍受",
  "patient_elbow_pain_q4":"過去 4 週內經常疼痛",
  "patient_elbow_pain_q4_a1":"不痛",
  "patient_elbow_pain_q4_a2":"最嚴重的疼痛，難以忍受",
  "patient_elbow_pain_q5":"當過去 4 週內情況最糟糕時",
  "patient_elbow_pain_q5_a1":"不痛",
  "patient_elbow_pain_q5_a2":"最嚴重的疼痛，難以忍受",
  "patient_elbow_pain_q6":"休息時候",
  "patient_elbow_pain_q6_a1":"不痛",
  "patient_elbow_pain_q6_a2":"最嚴重的疼痛，難以忍受",
  "patient_elbow_pain_q7":"舉起重物",
  "patient_elbow_pain_q7_a1":"不痛",
  "patient_elbow_pain_q7_a2":"最嚴重的疼痛，難以忍受",
  "patient_elbow_pain_q8":"當執行重複肘部動作的任務時",
  "patient_elbow_pain_q8_a1":"不痛",
  "patient_elbow_pain_q8_a2":"最嚴重的疼痛，難以忍受",
  "patient_elbow_pain_q9":"晚上",
  "patient_elbow_pain_q9_a1":"不痛",
  "patient_elbow_pain_q9_a2":"最嚴重的疼痛，難以忍受",
  "patient_elbow_pain_q10":"您有多少次在夜間床上受膝蓋疼痛所困擾？",
  "patient_elbow_pain_q10_a1":"一點也不",
  "patient_elbow_pain_q10_a2":"一至兩晚",
  "patient_elbow_pain_q10_a3":"有幾晚",
  "patient_elbow_pain_q10_a4":"大多晚",
  "patient_elbow_pain_q10_a5":"每晚",
  "patient_elbow_pain_q11":"您的肘部疼痛多常會影響您的睡眠？",
  "patient_elbow_pain_q11_a1":"一點也不",
  "patient_elbow_pain_q11_a2":"偶爾",
  "patient_elbow_pain_q11_a3":"有些時候",
  "patient_elbow_pain_q11_a4":"大多時候",
  "patient_elbow_pain_q11_a5":"一直",
  "patient_elbow_function":"功能",
  "patient_elbow_function_q1":"對您的肘部功能的最佳描述是什麼？",
  "patient_elbow_function_q1_a1":"無限制",
  "patient_elbow_function_q1_a2":"輕微限制，日常生活活動不受限制",
  "patient_elbow_function_q1_a3":"無法舉起>4.5公斤的物體",
  "patient_elbow_function_q1_a4":"對日常生活的活動有中度的限制",
  "patient_elbow_function_q1_a5":"無法梳理頭髮或摸頭",
  "patient_elbow_function_q1_a6":"無法自己吃飯",
  "patient_elbow_function_q2":"對您可以進行的活動的最佳描述是什麼？",
  "patient_elbow_function_q2_a1":"使用肘部無限制",
  "patient_elbow_function_q2_a2":"休閒活動有限制",
  "patient_elbow_function_q2_a3":"僅限家庭和職業活動",
  "patient_elbow_function_q2_a4":"能夠照顧自己",
  "patient_elbow_function_q2_a5":"無法使用",
  "patient_elbow_function_q3":"您的肘部可以使用多長時間？",
  "patient_elbow_function_q3_a1":"可以進行30分鐘的活動",
  "patient_elbow_function_q3_a2":"可以進行15分鐘的活動",
  "patient_elbow_function_q3_a3":"可以進行5分鐘的活動",
  "patient_elbow_function_q3_a4":"不能使用肘部",
  "patient_elbow_function_instruction":"請標明在上週內用肘部進行以下活動的難度",
  "patient_elbow_function_instruction_0":"沒有難度",
  "patient_elbow_function_instruction_1":"稍微",
  "patient_elbow_function_instruction_2":"中度",
  "patient_elbow_function_instruction_3":"嚴重",
  "patient_elbow_function_instruction_4":"做不到",
  "patient_elbow_function_q4":"打開一個緊的或新的罐子",
  "patient_elbow_function_q4_a1":"沒有難度",
  "patient_elbow_function_q4_a2":"稍微",
  "patient_elbow_function_q4_a3":"中度",
  "patient_elbow_function_q4_a4":"嚴重",
  "patient_elbow_function_q4_a5":"做不到",
  "patient_elbow_function_q5":"攜帶購物袋或公文包",
  "patient_elbow_function_q5_a1":"沒有難度",
  "patient_elbow_function_q5_a2":"稍微",
  "patient_elbow_function_q5_a3":"中度",
  "patient_elbow_function_q5_a4":"嚴重",
  "patient_elbow_function_q5_a5":"做不到",
  "patient_elbow_function_q6":"提起家裡的東西，例如倒垃圾",
  "patient_elbow_function_q6_a1":"沒有難度",
  "patient_elbow_function_q6_a2":"稍微",
  "patient_elbow_function_q6_a3":"中度",
  "patient_elbow_function_q6_a4":"嚴重",
  "patient_elbow_function_q6_a5":"做不到",
  "patient_elbow_function_q7":"洗您的背",
  "patient_elbow_function_q7_a1":"沒有難度",
  "patient_elbow_function_q7_a2":"稍微",
  "patient_elbow_function_q7_a3":"中度",
  "patient_elbow_function_q7_a4":"嚴重",
  "patient_elbow_function_q7_a5":"做不到",
  "patient_elbow_function_q8":"上廁所擦屁股",
  "patient_elbow_function_q8_a1":"沒有難度",
  "patient_elbow_function_q8_a2":"稍微",
  "patient_elbow_function_q8_a3":"中度",
  "patient_elbow_function_q8_a4":"嚴重",
  "patient_elbow_function_q8_a5":"做不到",
  "patient_elbow_function_q9":"把自己全身洗一遍",
  "patient_elbow_function_q9_a1":"沒有難度",
  "patient_elbow_function_q9_a2":"稍微",
  "patient_elbow_function_q9_a3":"中度",
  "patient_elbow_function_q9_a4":"嚴重",
  "patient_elbow_function_q9_a5":"做不到",
  "patient_elbow_function_q10":"做好個人衛生",
  "patient_elbow_function_q10_a1":"沒有難度",
  "patient_elbow_function_q10_a2":"稍微",
  "patient_elbow_function_q10_a3":"中度",
  "patient_elbow_function_q10_a4":"嚴重",
  "patient_elbow_function_q10_a5":"做不到",
  "patient_elbow_function_q11":"用刀切食物",
  "patient_elbow_function_q11_a1":"沒有難度",
  "patient_elbow_function_q11_a2":"稍微",
  "patient_elbow_function_q11_a3":"中度",
  "patient_elbow_function_q11_a4":"嚴重",
  "patient_elbow_function_q11_a5":"做不到",
  "patient_elbow_function_q12":"用餐具吃飯",
  "patient_elbow_function_q12_a1":"沒有難度",
  "patient_elbow_function_q12_a2":"稍微",
  "patient_elbow_function_q12_a3":"中度",
  "patient_elbow_function_q12_a4":"嚴重",
  "patient_elbow_function_q12_a5":"做不到",
  "patient_elbow_function_q13":"需要通過手臂、肩膀或手承受一些力道或衝擊力的休閒活動（例如高爾夫、錘擊、網球等）",
  "patient_elbow_function_q13_a1":"沒有難度",
  "patient_elbow_function_q13_a2":"稍微",
  "patient_elbow_function_q13_a3":"中度",
  "patient_elbow_function_q13_a4":"嚴重",
  "patient_elbow_function_q13_a5":"做不到",
  "patient_elbow_function_q14":"由於手臂、肩膀或手部疼痛而睡覺",
  "patient_elbow_function_q14_a1":"沒有難度",
  "patient_elbow_function_q14_a2":"稍微",
  "patient_elbow_function_q14_a3":"中度",
  "patient_elbow_function_q14_a4":"嚴重",
  "patient_elbow_function_q14_a5":"做不到",
  "patient_elbow_function_q15":"穿上襯衫",
  "patient_elbow_function_q15_a1":"沒有難度",
  "patient_elbow_function_q15_a2":"稍微",
  "patient_elbow_function_q15_a3":"中度",
  "patient_elbow_function_q15_a4":"嚴重",
  "patient_elbow_function_q15_a5":"做不到",
  "patient_elbow_function_q16":"扣襯衫最上面的鈕扣",
  "patient_elbow_function_q16_a1":"沒有難度",
  "patient_elbow_function_q16_a2":"稍微",
  "patient_elbow_function_q16_a3":"中度",
  "patient_elbow_function_q16_a4":"嚴重",
  "patient_elbow_function_q16_a5":"做不到",
  "patient_elbow_function_q17":"自己穿衣服",
  "patient_elbow_function_q17_a1":"沒有難度",
  "patient_elbow_function_q17_a2":"稍微",
  "patient_elbow_function_q17_a3":"中度",
  "patient_elbow_function_q17_a4":"嚴重",
  "patient_elbow_function_q17_a5":"做不到",
  "patient_elbow_function_q18":"梳頭髮",
  "patient_elbow_function_q18_a1":"沒有難度",
  "patient_elbow_function_q18_a2":"稍微",
  "patient_elbow_function_q18_a3":"中度",
  "patient_elbow_function_q18_a4":"嚴重",
  "patient_elbow_function_q18_a5":"做不到",
  "patient_elbow_function_q19":"穿鞋子",
  "patient_elbow_function_q19_a1":"沒有難度",
  "patient_elbow_function_q19_a2":"稍微",
  "patient_elbow_function_q19_a3":"中度",
  "patient_elbow_function_q19_a4":"嚴重",
  "patient_elbow_function_q19_a5":"做不到",
  "patient_elbow_function_q20":"綁鞋帶",
  "patient_elbow_function_q20_a1":"沒有難度",
  "patient_elbow_function_q20_a2":"稍微",
  "patient_elbow_function_q20_a3":"中度",
  "patient_elbow_function_q20_a4":"嚴重",
  "patient_elbow_function_q20_a5":"做不到",
  "patient_elbow_function_q21":"攜帶重物",
  "patient_elbow_function_q21_a1":"沒有難度",
  "patient_elbow_function_q21_a2":"稍微",
  "patient_elbow_function_q21_a3":"中度",
  "patient_elbow_function_q21_a4":"嚴重",
  "patient_elbow_function_q21_a5":"做不到",
  "patient_elbow_function_q22":"從椅子上站起來，用手臂推動",
  "patient_elbow_function_q22_a1":"沒有難度",
  "patient_elbow_function_q22_a2":"稍微",
  "patient_elbow_function_q22_a3":"中度",
  "patient_elbow_function_q22_a4":"嚴重",
  "patient_elbow_function_q22_a5":"做不到",
  "patient_elbow_function_q23":"做繁重的家務（例如洗牆、洗地板）",
  "patient_elbow_function_q23_a1":"沒有難度",
  "patient_elbow_function_q23_a2":"稍微",
  "patient_elbow_function_q23_a3":"中度",
  "patient_elbow_function_q23_a4":"嚴重",
  "patient_elbow_function_q23_a5":"做不到",
  "patient_elbow_function_q24":"轉動鑰匙開鎖",
  "patient_elbow_function_q24_a1":"沒有難度",
  "patient_elbow_function_q24_a2":"稍微",
  "patient_elbow_function_q24_a3":"中度",
  "patient_elbow_function_q24_a4":"嚴重",
  "patient_elbow_function_q24_a5":"做不到",
  "patient_elbow_function_q25":"丟球",
  "patient_elbow_function_q25_a1":"沒有難度",
  "patient_elbow_function_q25_a2":"稍微",
  "patient_elbow_function_q25_a3":"中度",
  "patient_elbow_function_q25_a4":"嚴重",
  "patient_elbow_function_q25_a5":"做不到",
  "patient_elbow_function_q26":"由於手臂、肩膀或手部疼痛而從事日常工作（工作包括做家務，如果這是您的主要職責）",
  "patient_elbow_function_q26_a1":"沒有難度",
  "patient_elbow_function_q26_a2":"稍微",
  "patient_elbow_function_q26_a3":"中度",
  "patient_elbow_function_q26_a4":"嚴重",
  "patient_elbow_function_q26_a5":"做不到",
  "patient_elbow_function_q27":"請註明平時的工作",
  "patient_elbow_function_q27_placeholder":"請註明工作",
  "patient_elbow_function_i_donot_work":"我不工作",
  "patient_elbow_function_q28":"使用您常用的工作技巧",
  "patient_elbow_function_q28_a1":"沒有難度",
  "patient_elbow_function_q28_a2":"稍微",
  "patient_elbow_function_q28_a3":"中度",
  "patient_elbow_function_q28_a4":"嚴重",
  "patient_elbow_function_q28_a5":"做不到",
  "patient_elbow_function_q29":"按照您的意願完成您的工作",
  "patient_elbow_function_q29_a1":"沒有難度",
  "patient_elbow_function_q29_a2":"稍微",
  "patient_elbow_function_q29_a3":"中度",
  "patient_elbow_function_q29_a4":"嚴重",
  "patient_elbow_function_q29_a5":"做不到",
  "patient_elbow_function_q30":"花平常的時間長度做您的工作",
  "patient_elbow_function_q30_a1":"沒有難度",
  "patient_elbow_function_q30_a2":"稍微",
  "patient_elbow_function_q30_a3":"中度",
  "patient_elbow_function_q30_a4":"嚴重",
  "patient_elbow_function_q30_a5":"做不到",
  "patient_elbow_function_q31":"由於手臂、肩膀或手部疼痛，演奏樂器或進行運動有困難",
  "patient_elbow_function_q31_a1":"沒有難度",
  "patient_elbow_function_q31_a2":"稍微",
  "patient_elbow_function_q31_a3":"中度",
  "patient_elbow_function_q31_a4":"嚴重",
  "patient_elbow_function_q31_a5":"做不到",
  "patient_elbow_function_q32":"請註明所玩的運動或樂器",
  "patient_elbow_function_q32_placeholder":"請註明所玩的運動或樂器",
  "patient_elbow_function_i_donot_play":"我不玩任何運動或樂器",
  "patient_elbow_function_q33":"使用您常用的演奏樂器或運動的技巧",
  "patient_elbow_function_q33_a1":"沒有難度",
  "patient_elbow_function_q33_a2":"稍微",
  "patient_elbow_function_q33_a3":"中度",
  "patient_elbow_function_q33_a4":"嚴重",
  "patient_elbow_function_q33_a5":"做不到",
  "patient_elbow_function_q34":"盡情演奏您的樂器或進行您想要的運動",
  "patient_elbow_function_q34_a1":"沒有難度",
  "patient_elbow_function_q34_a2":"稍微",
  "patient_elbow_function_q34_a3":"中度",
  "patient_elbow_function_q34_a4":"嚴重",
  "patient_elbow_function_q34_a5":"做不到",
  "patient_elbow_function_q35":"花平常的時間長度演奏樂器或進行運動",
  "patient_elbow_function_q35_a1":"沒有難度",
  "patient_elbow_function_q35_a2":"稍微",
  "patient_elbow_function_q35_a3":"中度",
  "patient_elbow_function_q35_a4":"嚴重",
  "patient_elbow_function_q35_a5":"做不到",
  "patient_elbow_function_q36":"在過去的一周中，您的手臂、肩膀或手部問題在多大程度上干擾了您與家人、朋友、鄰居或所屬團體的正常社交/休閒活動？",
  "patient_elbow_function_q36_a1":"一點也不",
  "patient_elbow_function_q36_a2":"稍微",
  "patient_elbow_function_q36_a3":"中度",
  "patient_elbow_function_q36_a4":"相當多",
  "patient_elbow_function_q36_a5":"極度",
  "patient_elbow_function_q37":"在過去的一周中，您的工作或其他日常活動是否因手臂、肩膀或手部問題而受到限制？",
  "patient_elbow_function_q37_a1":"一點也不",
  "patient_elbow_function_q37_a2":"稍微",
  "patient_elbow_function_q37_a3":"中度",
  "patient_elbow_function_q37_a4":"相當多",
  "patient_elbow_function_q37_a5":"極度",
  "patient_elbow_symptoms":"其他症狀",
  "patient_elbow_symptoms_instruction":"請評價上週以下症狀的嚴重程度：",
  "patient_elbow_symptoms_instruction_0":"無",
  "patient_elbow_symptoms_instruction_1":"稍微",
  "patient_elbow_symptoms_instruction_2":"中度",
  "patient_elbow_symptoms_instruction_3":"嚴重",
  "patient_elbow_symptoms_instruction_4":"極度",
  "patient_elbow_symptoms_q1":"手臂、肩膀或手部疼痛",
  "patient_elbow_symptoms_q1_a1":"無",
  "patient_elbow_symptoms_q1_a2":"稍微",
  "patient_elbow_symptoms_q1_a3":"中度",
  "patient_elbow_symptoms_q1_a4":"嚴重",
  "patient_elbow_symptoms_q1_a5":"極度",
  "patient_elbow_symptoms_q2":"手臂、肩膀或手部的刺痛感（針刺感）",
  "patient_elbow_symptoms_q2_a1":"無",
  "patient_elbow_symptoms_q2_a2":"稍微",
  "patient_elbow_symptoms_q2_a3":"中度",
  "patient_elbow_symptoms_q2_a4":"嚴重",
  "patient_elbow_symptoms_q2_a5":"極度",
  "patient_elbow_symptoms_q3":"您會給自己今天的肘部打幾分？（0-100%，100%為正常）",
  "patient_elbow_quality_of_life":"生活素質",
  "patient_elbow_quality_of_life_instruction":"以下問題詢問您可能對肘部問題感到的社交、情感和生活方式方面的擔憂。請想一想您大部分時間對肘部的感受如何。",
  "patient_elbow_quality_of_life_q1":"在過去的4周里，您是否感覺肘部問題正在“控制您的生活”？",
  "patient_elbow_quality_of_life_q1_a1":"一點也不",
  "patient_elbow_quality_of_life_q1_a2":"偶爾",
  "patient_elbow_quality_of_life_q1_a3":"有些日子",
  "patient_elbow_quality_of_life_q1_a4":"大多數日子",
  "patient_elbow_quality_of_life_q1_a5":"每天",
  "patient_elbow_quality_of_life_q2":"在過去 4 週內，您的肘部問題有多常“出現在您的腦海中”？",
  "patient_elbow_quality_of_life_q2_a1":"一點也不",
  "patient_elbow_quality_of_life_q2_a2":"有一點時候",
  "patient_elbow_quality_of_life_q2_a3":"有些時候",
  "patient_elbow_quality_of_life_q2_a4":"大多時候",
  "patient_elbow_quality_of_life_q2_a5":"一直",
  "patient_elbow_quality_of_life_instruction1":"請對以下內容進行評估，以最準確地描述您當前的健康狀況：",
  "patient_elbow_quality_of_life_instruction1_0":"沒有問題",
  "patient_elbow_quality_of_life_instruction1_1":"稍微",
  "patient_elbow_quality_of_life_instruction1_2":"中度",
  "patient_elbow_quality_of_life_instruction1_3":"嚴重",
  "patient_elbow_quality_of_life_instruction1_4":"極嚴重/做不到",
  "patient_elbow_quality_of_life_q3":"走路問題",
  "patient_elbow_quality_of_life_q3_a1":"沒有問題",
  "patient_elbow_quality_of_life_q3_a2":"稍微",
  "patient_elbow_quality_of_life_q3_a3":"中度",
  "patient_elbow_quality_of_life_q3_a4":"嚴重",
  "patient_elbow_quality_of_life_q3_a5":"做不到",
  "patient_elbow_quality_of_life_q4":"自己洗澡或穿衣問題",
  "patient_elbow_quality_of_life_q4_a1":"沒有問題",
  "patient_elbow_quality_of_life_q4_a2":"稍微",
  "patient_elbow_quality_of_life_q4_a3":"中度",
  "patient_elbow_quality_of_life_q4_a4":"嚴重",
  "patient_elbow_quality_of_life_q4_a5":"做不到",
  "patient_elbow_quality_of_life_q5":"進行日常活動（例如工作、學習、家務、家庭或休閒活動）時出現問題",
  "patient_elbow_quality_of_life_q5_a1":"沒有問題",
  "patient_elbow_quality_of_life_q5_a2":"稍微",
  "patient_elbow_quality_of_life_q5_a3":"中度",
  "patient_elbow_quality_of_life_q5_a4":"嚴重",
  "patient_elbow_quality_of_life_q5_a5":"做不到",
  "patient_elbow_quality_of_life_q6":"疼痛或不舒服",
  "patient_elbow_quality_of_life_q6_a1":"無",
  "patient_elbow_quality_of_life_q6_a2":"稍微",
  "patient_elbow_quality_of_life_q6_a3":"中度",
  "patient_elbow_quality_of_life_q6_a4":"嚴重",
  "patient_elbow_quality_of_life_q6_a5":"極度",
  "patient_elbow_quality_of_life_q7":"焦慮/抑鬱",
  "patient_elbow_quality_of_life_q7_a1":"不焦慮/抑鬱",
  "patient_elbow_quality_of_life_q7_a2":"稍微焦慮/抑鬱",
  "patient_elbow_quality_of_life_q7_a3":"中度焦慮/抑鬱",
  "patient_elbow_quality_of_life_q7_a4":"嚴重焦慮/抑鬱",
  "patient_elbow_quality_of_life_q7_a5":"極度焦慮/抑鬱",
  "patient_elbow_quality_of_life_q8":"您今天的健康狀況如何？",
  "patient_elbow_quality_of_life_q8_instruction":"100 表示最佳健康狀況，0 表示您可以想像的最差健康狀況",
  "patient_elbow_quality_of_life_q8_a1":"最差",
  "patient_elbow_quality_of_life_q8_a2":"最好",
  "patient_elbow_satisfaction":"滿意度",
  "patient_elbow_satisfaction_q1":"您對肘部治療的效果滿意嗎？",
  "patient_elbow_satisfaction_na":"不適用",
  "patient_elbow_satisfaction_q1_a1":"一點都不滿意",
  "patient_elbow_satisfaction_q1_a2":"很滿意",
  "patient_elbow_complete":"問卷結束",
  "patient_elbow_complete_text1":"感謝您抽出寶貴時間填寫此問卷。",
  "patient_elbow_complete_text2":"請點擊提交完成，並查看您的分數。",
  "patient_elbow_complete_text3":"您的回答將被保密，任何報告都將僅使用去除身份識別的資訊",
  "patient_elbow_results":"結果",
  "patient_elbow_results_text1":"以下是您的疼痛和肘部功能評分，這些評分是根據您的調查問卷計算得出的。",
  "patient_elbow_results_function":"功能",
  "patient_elbow_results_pain":"疼痛",
  "patient_elbow_results_health_perception":"整體健康認知",
  "patient_elbow_results_self_evaluation":"肘部評分自我評估",
  "patient_elbow_results_text4":"您的醫生很快就會和您聯繫。",
  "patient_hand_pain":"疼痛",
  "patient_hand_pain_q1":"描述過去一周您的手/手腕的疼痛",
  "patient_hand_pain_q1_a1":"無至非常溫和",
  "patient_hand_pain_q1_a2":"稍微",
  "patient_hand_pain_q1_a3":"中度",
  "patient_hand_pain_q1_a4":"嚴重",
  "patient_hand_pain_q1_a5":"非常嚴重/極端",
  "patient_hand_pain_q2":"此時您的手/手腕有多痛？",
  "patient_hand_pain_q2_a1":"沒有痛",
  "patient_hand_pain_q2_a2":"最痛",
  "patient_hand_pain_q3":"過去一周，您的手/手腕疼痛有多少次干擾您的日常活動（例如吃飯或洗澡）？",
  "patient_hand_pain_q3_a1":"從未",
  "patient_hand_pain_q3_a2":"偶爾",
  "patient_hand_pain_q3_a3":"有時候",
  "patient_hand_pain_q3_a4":"經常",
  "patient_hand_pain_q3_a5":"持續",
  "patient_hand_symptoms":"別的症狀",
  "patient_hand_symptoms_q1":"過去一周，您的手臂、肩膀或手部的刺痛（針刺感）有多嚴重？",
  "patient_hand_symptoms_q1_a1":"無",
  "patient_hand_symptoms_q1_a2":"稍微",
  "patient_hand_symptoms_q1_a3":"中度",
  "patient_hand_symptoms_q1_a4":"嚴重",
  "patient_hand_symptoms_q1_a5":"極度",
  "patient_hand_symptoms_q2":"過去一周您手中的感覺如何？",
  "patient_hand_symptoms_q2_a1":"很好",
  "patient_hand_symptoms_q2_a2":"好",
  "patient_hand_symptoms_q2_a3":"還可以",
  "patient_hand_symptoms_q2_a4":"差",
  "patient_hand_symptoms_q2_a5":"很差",
  "patient_hand_symptoms_q3":"我的手的外觀干擾了我的正常日常活動",
  "patient_hand_symptoms_q3_a1":"強烈反對",
  "patient_hand_symptoms_q3_a2":"反對",
  "patient_hand_symptoms_q3_a3":"既不同意也不反對",
  "patient_hand_symptoms_q3_a4":"同意",
  "patient_hand_symptoms_q3_a5":"強烈同意",
  "patient_hand_symptoms_q3_a6":"您會給自己今天的手/手腕打幾分？ （0到100%，100%為正常）（0-100%，100%為正常）",
  "patient_hand_symptoms_q3_a6_normal":"正常",
  "patient_hand_function":"功能",
  "patient_hand_function_instruction1":"如果您不執行某項任務，請估計您執行該任務的難度。",
  "patient_hand_function_instruction2":"如果您不確定如何回答問題，請盡可能給出最佳答案。",
  "patient_hand_function_q1":"總體而言，過去一周您的手部表現如何？",
  "patient_hand_function_q1_a1":"很好",
  "patient_hand_function_q1_a2":"好",
  "patient_hand_function_q1_a3":"還可以",
  "patient_hand_function_q1_a4":"差",
  "patient_hand_function_q1_a5":"很差",
  "patient_hand_function_q2":"您有多少次因為手/手腕問題而無法完成工作？",
  "patient_hand_function_q2_a1":"從未",
  "patient_hand_function_q2_a2":"偶爾",
  "patient_hand_function_q2_a3":"有時候",
  "patient_hand_function_q2_a4":"經常",
  "patient_hand_function_q2_a5":"持續",
  "patient_hand_function_q3":"您在工作中是否經常因為手/手腕問題而花更長時間完成任務？",
  "patient_hand_function_q3_a1":"從未",
  "patient_hand_function_q3_a2":"偶爾",
  "patient_hand_function_q3_a3":"有時候",
  "patient_hand_function_q3_a4":"經常",
  "patient_hand_function_q3_a5":"持續",
  "patient_hand_function_instruction":"請註明上週由於您的手/手腕問題而進行以下活動的難度",
  "patient_hand_function_instruction_0":"沒有難度",
  "patient_hand_function_instruction_1":"稍有難度",
  "patient_hand_function_instruction_2":"中度難度",
  "patient_hand_function_instruction_3":"嚴重難度",
  "patient_hand_function_instruction_4":"極端困難/做不到",
  "patient_hand_function_q4":"打開一個緊的或新的罐子",
  "patient_hand_function_q4_a1":"沒有難度",
  "patient_hand_function_q4_a2":"稍有難度",
  "patient_hand_function_q4_a3":"中度難度",
  "patient_hand_function_q4_a4":"嚴重難度",
  "patient_hand_function_q4_a5":"極端困難/做不到",
  "patient_hand_function_q5":"握住煎鍋",
  "patient_hand_function_q5_a1":"沒有難度",
  "patient_hand_function_q5_a2":"稍有難度",
  "patient_hand_function_q5_a3":"中度難度",
  "patient_hand_function_q5_a4":"嚴重難度",
  "patient_hand_function_q5_a5":"極端困難/做不到",
  "patient_hand_function_q6":"扣襯衫鈕扣",
  "patient_hand_function_q6_a1":"沒有難度",
  "patient_hand_function_q6_a2":"稍有難度",
  "patient_hand_function_q6_a3":"中度難度",
  "patient_hand_function_q6_a4":"嚴重難度",
  "patient_hand_function_q6_a5":"極端困難/做不到",
  "patient_hand_function_q7":"做繁重的家務（例如洗牆、洗地板）",
  "patient_hand_function_q7_a1":"沒有難度",
  "patient_hand_function_q7_a2":"稍有難度",
  "patient_hand_function_q7_a3":"中度難度",
  "patient_hand_function_q7_a4":"嚴重難度",
  "patient_hand_function_q7_a5":"極端困難/做不到",
  "patient_hand_function_q8":"攜帶購物袋或公文包",
  "patient_hand_function_q8_a1":"沒有難度",
  "patient_hand_function_q8_a2":"稍有難度",
  "patient_hand_function_q8_a3":"中度難度",
  "patient_hand_function_q8_a4":"嚴重難度",
  "patient_hand_function_q8_a5":"極端困難/做不到",
  "patient_hand_function_q9":"洗您的背",
  "patient_hand_function_q9_a1":"沒有難度",
  "patient_hand_function_q9_a2":"稍有難度",
  "patient_hand_function_q9_a3":"中度難度",
  "patient_hand_function_q9_a4":"嚴重難度",
  "patient_hand_function_q9_a5":"極端困難/做不到",
  "patient_hand_function_q10":"用刀切食物",
  "patient_hand_function_q10_a1":"沒有難度",
  "patient_hand_function_q10_a2":"稍有難度",
  "patient_hand_function_q10_a3":"中度難度",
  "patient_hand_function_q10_a4":"嚴重難度",
  "patient_hand_function_q10_a5":"極端困難/做不到",
  "patient_hand_function_q11":"通過手臂、肩膀或手承受一些力或衝擊力的娛樂活動（例如高爾夫、錘擊、網球等）",
  "patient_hand_function_q11_a1":"沒有難度",
  "patient_hand_function_q11_a2":"稍有難度",
  "patient_hand_function_q11_a3":"中度難度",
  "patient_hand_function_q11_a4":"嚴重難度",
  "patient_hand_function_q11_a5":"極端困難/做不到",
  "patient_hand_function_q12":"由於手臂、肩膀或手部疼痛而睡覺",
  "patient_hand_function_q12_a1":"沒有難度",
  "patient_hand_function_q12_a2":"稍有難度",
  "patient_hand_function_q12_a3":"中度難度",
  "patient_hand_function_q12_a4":"嚴重難度",
  "patient_hand_function_q12_a5":"極端困難/做不到",
  "patient_hand_function_q13":"由於手臂、肩膀或手部疼痛而從事日常工作（工作包括做家務，如果這是您的主要職責）",
  "patient_hand_function_q13_a1":"沒有難度",
  "patient_hand_function_q13_a2":"稍有難度",
  "patient_hand_function_q13_a3":"中度難度",
  "patient_hand_function_q13_a4":"嚴重難度",
  "patient_hand_function_q13_a5":"極端困難/做不到",
  "patient_hand_function_q14":"請註明平時的工作",
  "patient_hand_function_q14_placeholder":"請註明工作",
  "patient_hand_function_do_dot_work":"我不上班",
  "patient_hand_function_q15":"使用您常用的工作技巧",
  "patient_hand_function_q15_a1":"沒有難度",
  "patient_hand_function_q15_a2":"稍有難度",
  "patient_hand_function_q15_a3":"中度難度",
  "patient_hand_function_q15_a4":"嚴重難度",
  "patient_hand_function_q15_a5":"極端困難/做不到",
  "patient_hand_function_q16":"按照您的意願完成您的工作",
  "patient_hand_function_q16_a1":"沒有難度",
  "patient_hand_function_q16_a2":"稍有難度",
  "patient_hand_function_q16_a3":"中度難度",
  "patient_hand_function_q16_a4":"嚴重難度",
  "patient_hand_function_q16_a5":"極端困難/做不到",
  "patient_hand_function_q17":"花平常的時間長度做您的工作",
  "patient_hand_function_q17_a1":"沒有難度",
  "patient_hand_function_q17_a2":"稍有難度",
  "patient_hand_function_q17_a3":"中度難度",
  "patient_hand_function_q17_a4":"嚴重難度",
  "patient_hand_function_q17_a5":"極端困難/做不到",
  "patient_hand_function_q18":"由於手臂、肩膀或手部疼痛而演奏樂器或進行運動",
  "patient_hand_function_q18_a1":"沒有難度",
  "patient_hand_function_q18_a2":"稍有難度",
  "patient_hand_function_q18_a3":"中度難度",
  "patient_hand_function_q18_a4":"嚴重難度",
  "patient_hand_function_q18_a5":"極端困難/做不到",
  "patient_hand_function_q19":"請註明所玩的運動或樂器",
  "patient_hand_function_q19_placeholder":"請註明運動/樂器",
  "patient_hand_function_donot_play":"我不從事任何運動或樂器",
  "patient_hand_function_20":"使用您常用的演奏樂器或運動的技巧",
  "patient_hand_function_q20_a1":"沒有難度",
  "patient_hand_function_q20_a2":"稍有難度",
  "patient_hand_function_q20_a3":"中度難度",
  "patient_hand_function_q20_a4":"嚴重難度",
  "patient_hand_function_q20_a5":"極端困難/做不到",
  "patient_hand_function_q21":"盡情演奏您的樂器或進行您想要的運動",
  "patient_hand_function_q21_a1":"沒有難度",
  "patient_hand_function_q21_a2":"稍有難度",
  "patient_hand_function_q21_a3":"中度難度",
  "patient_hand_function_q21_a4":"嚴重難度",
  "patient_hand_function_q21_a5":"極端困難/做不到",
  "patient_hand_function_q22":"花平常的時間長度演奏樂器或進行運動",
  "patient_hand_function_q22_a1":"沒有難度",
  "patient_hand_function_q22_a2":"稍有難度",
  "patient_hand_function_q22_a3":"中度難度",
  "patient_hand_function_q22_a4":"嚴重難度",
  "patient_hand_function_q22_a5":"極端困難/做不到",
  "patient_hand_function_q23":"在過去的一周中，您的手臂、肩膀或手部問題在多大程度上干擾了您與家人、朋友、鄰居或所屬團體的正常社交/休閒活動？",
  "patient_hand_function_q23_a1":"一點也不",
  "patient_hand_function_q23_a2":"有些",
  "patient_hand_function_q23_a3":"中度",
  "patient_hand_function_q23_a4":"相當多",
  "patient_hand_function_q23_a5":"極度",
  "patient_hand_function_q24":"在過去的一周中，您的工作或其他日常活動是否因手臂、肩膀或手部問題而受到限制？",
  "patient_hand_function_q24_a1":"一點也不",
  "patient_hand_function_q24_a2":"有些",
  "patient_hand_function_q24_a3":"中度",
  "patient_hand_function_q24_a4":"相當多",
  "patient_hand_function_q24_a5":"極度",
  "patient_hand_questionnaire":"波士頓腕隧道症候群問卷",
  "patient_hand_questionnaire_symptom":"症狀",
  "patient_hand_questionnaire_symptom_instruction":"請選擇最符合您症狀的答案。",
  "patient_hand_questionnaire_symptom_instruction_0":"正常",
  "patient_hand_questionnaire_symptom_instruction_1":"稍微",
  "patient_hand_questionnaire_symptom_instruction_2":"中等",
  "patient_hand_questionnaire_symptom_instruction_3":"嚴重",
  "patient_hand_questionnaire_symptom_instruction_4":"很嚴重",
  "patient_hand_questionnaire_symptom_q1":"晚上您的手或手腕的疼痛有多嚴重？",
  "patient_hand_questionnaire_symptom_q1_a1":"正常",
  "patient_hand_questionnaire_symptom_q1_a2":"稍微",
  "patient_hand_questionnaire_symptom_q1_a3":"中等",
  "patient_hand_questionnaire_symptom_q1_a4":"嚴重",
  "patient_hand_questionnaire_symptom_q1_a5":"很嚴重",
  "patient_hand_questionnaire_symptom_q2":"在過去兩週內，一個普通的夜晚中，您有多少次因手部或手腕疼痛而醒來？",
  "patient_hand_questionnaire_symptom_q2_a1":"正常",
  "patient_hand_questionnaire_symptom_q2_a2":"一次",
  "patient_hand_questionnaire_symptom_q2_a3":"兩至三次",
  "patient_hand_questionnaire_symptom_q2_a4":"四至五次",
  "patient_hand_questionnaire_symptom_q2_a5":"超過五次",
  "patient_hand_questionnaire_symptom_q3":"您白天的時候手或手腕是否經常感到疼痛？",
  "patient_hand_questionnaire_symptom_q3_a1":"沒有痛",
  "patient_hand_questionnaire_symptom_q3_a2":"稍微",
  "patient_hand_questionnaire_symptom_q3_a3":"中等",
  "patient_hand_questionnaire_symptom_q3_a4":"嚴重",
  "patient_hand_questionnaire_symptom_q3_a5":"很嚴重",
  "patient_hand_questionnaire_symptom_q4":"您白天手或手腕疼痛的頻率有多少？",
  "patient_hand_questionnaire_symptom_q4_a1":"正常",
  "patient_hand_questionnaire_symptom_q4_a2":"一天內有一至兩次",
  "patient_hand_questionnaire_symptom_q4_a3":"一天內有三至五次",
  "patient_hand_questionnaire_symptom_q4_a4":"一天內有超過五次",
  "patient_hand_questionnaire_symptom_q4_a5":"持續地痛",
  "patient_hand_questionnaire_symptom_q5":"白天疼痛的時候平均持續多長時間？",
  "patient_hand_questionnaire_symptom_q5_a1":"正常",
  "patient_hand_questionnaire_symptom_q5_a2":"少於十分鐘",
  "patient_hand_questionnaire_symptom_q5_a3":"十至六十分鐘",
  "patient_hand_questionnaire_symptom_q5_a4":"超過六十分鐘",
  "patient_hand_questionnaire_symptom_q5_a5":"持續不斷",
  "patient_hand_questionnaire_symptom_q6":"您的手是否麻木（失去知覺）",
  "patient_hand_questionnaire_symptom_q6_a1":"正常",
  "patient_hand_questionnaire_symptom_q6_a2":"稍微",
  "patient_hand_questionnaire_symptom_q6_a3":"中等",
  "patient_hand_questionnaire_symptom_q6_a4":"嚴重",
  "patient_hand_questionnaire_symptom_q6_a5":"很嚴重",
  "patient_hand_questionnaire_symptom_q7":"您的手或手腕有無力感嗎？",
  "patient_hand_questionnaire_symptom_q7_a1":"正常",
  "patient_hand_questionnaire_symptom_q7_a2":"稍微",
  "patient_hand_questionnaire_symptom_q7_a3":"中等",
  "patient_hand_questionnaire_symptom_q7_a4":"嚴重",
  "patient_hand_questionnaire_symptom_q7_a5":"很嚴重",
  "patient_hand_questionnaire_symptom_q8":"您的手有刺痛的感覺嗎？",
  "patient_hand_questionnaire_symptom_q8_a1":"正常",
  "patient_hand_questionnaire_symptom_q8_a2":"稍微",
  "patient_hand_questionnaire_symptom_q8_a3":"中等",
  "patient_hand_questionnaire_symptom_q8_a4":"嚴重",
  "patient_hand_questionnaire_symptom_q8_a5":"很嚴重",
  "patient_hand_questionnaire_symptom_q9":"夜間麻木（感覺喪失）或刺痛有多嚴重？",
  "patient_hand_questionnaire_symptom_q9_a1":"正常",
  "patient_hand_questionnaire_symptom_q9_a2":"稍微",
  "patient_hand_questionnaire_symptom_q9_a3":"中等",
  "patient_hand_questionnaire_symptom_q9_a4":"嚴重",
  "patient_hand_questionnaire_symptom_q9_a5":"很嚴重",
  "patient_hand_questionnaire_symptom_q10":"在過去兩週內的普通夜晚中，您有多少次因手部麻木或刺痛而醒來？",
  "patient_hand_questionnaire_symptom_q10_a1":"正常",
  "patient_hand_questionnaire_symptom_q10_a2":"一次",
  "patient_hand_questionnaire_symptom_q10_a3":"兩至三次",
  "patient_hand_questionnaire_symptom_q10_a4":"四至五次",
  "patient_hand_questionnaire_symptom_q10_a5":"超過五次",
  "patient_hand_questionnaire_function":"功能",
  "patient_hand_questionnaire_function_instructions":"根據您的症狀請評估進行以下活動的難度：",
  "patient_hand_questionnaire_function_instructions_0":"沒有難度",
  "patient_hand_questionnaire_function_instructions_1":"一點難度",
  "patient_hand_questionnaire_function_instructions_2":"中度難度",
  "patient_hand_questionnaire_function_instructions_3":"極大難度",
  "patient_hand_questionnaire_function_instructions_4":"無法辦到",
  "patient_hand_questionnaire_function_q1":"難以抓握和使用鑰匙或筆等小物體",
  "patient_hand_questionnaire_function_q1_a1":"沒有難度",
  "patient_hand_questionnaire_function_q1_a2":"一點難度",
  "patient_hand_questionnaire_function_q1_a3":"中度難度",
  "patient_hand_questionnaire_function_q1_a4":"極大難度",
  "patient_hand_questionnaire_function_q1_a5":"無法辦到",
  "patient_hand_questionnaire_function_q2":"手寫字",
  "patient_hand_questionnaire_function_q2_a1":"沒有難度",
  "patient_hand_questionnaire_function_q2_a2":"一點難度",
  "patient_hand_questionnaire_function_q2_a3":"中度難度",
  "patient_hand_questionnaire_function_q2_a4":"極大難度",
  "patient_hand_questionnaire_function_q2_a5":"無法辦到",
  "patient_hand_questionnaire_function_q3":"扣上衣服釦子",
  "patient_hand_questionnaire_function_q3_a1":"沒有難度",
  "patient_hand_questionnaire_function_q3_a2":"一點難度",
  "patient_hand_questionnaire_function_q3_a3":"中度難度",
  "patient_hand_questionnaire_function_q3_a4":"極大難度",
  "patient_hand_questionnaire_function_q3_a5":"無法辦到",
  "patient_hand_questionnaire_function_q4":"拿著一本書來看",
  "patient_hand_questionnaire_function_q4_a1":"沒有難度",
  "patient_hand_questionnaire_function_q4_a2":"一點難度",
  "patient_hand_questionnaire_function_q4_a3":"中度難度",
  "patient_hand_questionnaire_function_q4_a4":"極大難度",
  "patient_hand_questionnaire_function_q4_a5":"無法辦到",
  "patient_hand_questionnaire_function_q5":"握住電話手柄",
  "patient_hand_questionnaire_function_q5_a1":"沒有難度",
  "patient_hand_questionnaire_function_q5_a2":"一點難度",
  "patient_hand_questionnaire_function_q5_a3":"中度難度",
  "patient_hand_questionnaire_function_q5_a4":"極大難度",
  "patient_hand_questionnaire_function_q5_a5":"無法辦到",
  "patient_hand_questionnaire_function_q6":"打開罐子",
  "patient_hand_questionnaire_function_q6_a1":"沒有難度",
  "patient_hand_questionnaire_function_q6_a2":"一點難度",
  "patient_hand_questionnaire_function_q6_a3":"中度難度",
  "patient_hand_questionnaire_function_q6_a4":"極大難度",
  "patient_hand_questionnaire_function_q6_a5":"無法辦到",
  "patient_hand_questionnaire_function_q7":"日常家務",
  "patient_hand_questionnaire_function_q7_a1":"沒有難度",
  "patient_hand_questionnaire_function_q7_a2":"一點難度",
  "patient_hand_questionnaire_function_q7_a3":"中度難度",
  "patient_hand_questionnaire_function_q7_a4":"極大難度",
  "patient_hand_questionnaire_function_q7_a5":"無法辦到",
  "patient_hand_questionnaire_function_q8":"攜帶買菜籃",
  "patient_hand_questionnaire_function_q8_a1":"沒有難度",
  "patient_hand_questionnaire_function_q8_a2":"一點難度",
  "patient_hand_questionnaire_function_q8_a3":"中度難度",
  "patient_hand_questionnaire_function_q8_a4":"極大難度",
  "patient_hand_questionnaire_function_q8_a5":"無法辦到",
  "patient_hand_questionnaire_function_q9":"洗澡和穿衣",
  "patient_hand_questionnaire_function_q9_a1":"沒有難度",
  "patient_hand_questionnaire_function_q9_a2":"一點難度",
  "patient_hand_questionnaire_function_q9_a3":"中度難度",
  "patient_hand_questionnaire_function_q9_a4":"極大難度",
  "patient_hand_questionnaire_function_q9_a5":"無法辦到",
  "patient_hand_uram":"手部和手腕 URAM 量表",
  "patient_hand_uram_canyou":"您能...",
  "patient_hand_uram_q1":"用毛巾清洗身體，同時保持手部平直？",
  "patient_hand_uram_q1_a1":"毫無困難",
  "patient_hand_uram_q1_a2":"幾乎沒有什麼困難",
  "patient_hand_uram_q1_a3":"有一些困難",
  "patient_hand_uram_q1_a4":"困難重重",
  "patient_hand_uram_q1_a5":"幾乎不可能",
  "patient_hand_uram_q1_a6":"不可能",
  "patient_hand_uram_q2":"洗臉",
  "patient_hand_uram_q2_a1":"毫無困難",
  "patient_hand_uram_q2_a2":"幾乎沒有什麼困難",
  "patient_hand_uram_q2_a3":"有一些困難",
  "patient_hand_uram_q2_a4":"困難重重",
  "patient_hand_uram_q2_a5":"幾乎不可能",
  "patient_hand_uram_q2_a6":"不可能",
  "patient_hand_uram_q3":"用一隻手握住一個瓶子",
  "patient_hand_uram_q3_a1":"毫無困難",
  "patient_hand_uram_q3_a2":"幾乎沒有什麼困難",
  "patient_hand_uram_q3_a3":"有一些困難",
  "patient_hand_uram_q3_a4":"困難重重",
  "patient_hand_uram_q3_a5":"幾乎不可能",
  "patient_hand_uram_q3_a6":"不可能",
  "patient_hand_uram_q4":"和別人握手",
  "patient_hand_uram_q4_a1":"毫無困難",
  "patient_hand_uram_q4_a2":"幾乎沒有什麼困難",
  "patient_hand_uram_q4_a3":"有一些困難",
  "patient_hand_uram_q4_a4":"困難重重",
  "patient_hand_uram_q4_a5":"幾乎不可能",
  "patient_hand_uram_q4_a6":"不可能",
  "patient_hand_uram_q5":"撫摸某物或愛撫某人",
  "patient_hand_uram_q5_a1":"毫無困難",
  "patient_hand_uram_q5_a2":"幾乎沒有什麼困難",
  "patient_hand_uram_q5_a3":"有一些困難",
  "patient_hand_uram_q5_a4":"困難重重",
  "patient_hand_uram_q5_a5":"幾乎不可能",
  "patient_hand_uram_q5_a6":"不可能",
  "patient_hand_uram_q6":"拍手",
  "patient_hand_uram_q6_a1":"毫無困難",
  "patient_hand_uram_q6_a2":"幾乎沒有什麼困難",
  "patient_hand_uram_q6_a3":"有一些困難",
  "patient_hand_uram_q6_a4":"困難重重",
  "patient_hand_uram_q6_a5":"幾乎不可能",
  "patient_hand_uram_q6_a6":"不可能",
  "patient_hand_uram_q7":"張開手指",
  "patient_hand_uram_q7_a1":"毫無困難",
  "patient_hand_uram_q7_a2":"幾乎沒有什麼困難",
  "patient_hand_uram_q7_a3":"有一些困難",
  "patient_hand_uram_q7_a4":"困難重重",
  "patient_hand_uram_q7_a5":"幾乎不可能",
  "patient_hand_uram_q7_a6":"不可能",
  "patient_hand_uram_q8":"將頭靠在您的手上",
  "patient_hand_uram_q8_a1":"毫無困難",
  "patient_hand_uram_q8_a2":"幾乎沒有什麼困難",
  "patient_hand_uram_q8_a3":"有一些困難",
  "patient_hand_uram_q8_a4":"困難重重",
  "patient_hand_uram_q8_a5":"幾乎不可能",
  "patient_hand_uram_q8_a6":"不可能",
  "patient_hand_uram_q9":"用拇指和食指撿起小物體",
  "patient_hand_uram_q9_a1":"毫無困難",
  "patient_hand_uram_q9_a2":"幾乎沒有什麼困難",
  "patient_hand_uram_q9_a3":"有一些困難",
  "patient_hand_uram_q9_a4":"困難重重",
  "patient_hand_uram_q9_a5":"幾乎不可能",
  "patient_hand_uram_q9_a6":"不可能",
  "patient_hand_quality_of_life":"生活品質",
  "patient_hand_quality_of_life_instruction":"請對以下內容進行評分，以最準確地描述您當前的健康狀況：",
  "patient_hand_quality_of_life_instruction_0":"沒有問題",
  "patient_hand_quality_of_life_instruction_1":"稍微",
  "patient_hand_quality_of_life_instruction_2":"中度",
  "patient_hand_quality_of_life_instruction_3":"嚴重",
  "patient_hand_quality_of_life_instruction_4":"極嚴重/做不到",
  "patient_hand_quality_of_life_q1":"走路的問題",
  "patient_hand_quality_of_life_q1_a1":"沒有問題",
  "patient_hand_quality_of_life_q1_a2":"稍微",
  "patient_hand_quality_of_life_q1_a3":"中度",
  "patient_hand_quality_of_life_q1_a4":"嚴重",
  "patient_hand_quality_of_life_q1_a5":"做不到",
  "patient_hand_quality_of_life_q2":"自己洗澡或穿衣的問題",
  "patient_hand_quality_of_life_q2_a1":"沒有問題",
  "patient_hand_quality_of_life_q2_a2":"稍微",
  "patient_hand_quality_of_life_q2_a3":"中度",
  "patient_hand_quality_of_life_q2_a4":"嚴重",
  "patient_hand_quality_of_life_q2_a5":"做不到",
  "patient_hand_quality_of_life_q3":"進行日常活動（例如工作、學習、家務、家庭或休閒活動）時出現問題",
  "patient_hand_quality_of_life_q3_a1":"沒有問題",
  "patient_hand_quality_of_life_q3_a2":"稍微",
  "patient_hand_quality_of_life_q3_a3":"中度",
  "patient_hand_quality_of_life_q3_a4":"嚴重",
  "patient_hand_quality_of_life_q3_a5":"做不到",
  "patient_hand_quality_of_life_q4":"疼痛或不舒服",
  "patient_hand_quality_of_life_q4_a1":"無",
  "patient_hand_quality_of_life_q4_a2":"稍微",
  "patient_hand_quality_of_life_q4_a3":"中度",
  "patient_hand_quality_of_life_q4_a4":"嚴重",
  "patient_hand_quality_of_life_q4_a5":"極度",
  "patient_hand_quality_of_life_q5":"焦慮/抑鬱",
  "patient_hand_quality_of_life_q5_a1":"沒有焦慮/抑鬱",
  "patient_hand_quality_of_life_q5_a2":"有些焦慮/抑鬱",
  "patient_hand_quality_of_life_q5_a3":"中度焦慮/抑鬱",
  "patient_hand_quality_of_life_q5_a4":"嚴重焦慮/抑鬱",
  "patient_hand_quality_of_life_q5_a5":"極度焦慮/抑鬱",
  "patient_hand_quality_of_life_q6":"您今天的健康狀況如何？ （100 表示最佳健康狀況，0 表示您可以想像的最差健康狀況）",
  "patient_hand_quality_of_life_q6_a1":"最差",
  "patient_hand_quality_of_life_q6_a2":"最好",
  "patient_hand_satisfaction":"滿意度",
  "patient_hand_satisfaction_instrucion":"請說明您對以下方面的滿意度：",
  "patient_hand_satisfaction_instrucion_0":"很不滿意",
  "patient_hand_satisfaction_instrucion_1":"不滿意",
  "patient_hand_satisfaction_instrucion_2":"既沒有滿意，也沒有不滿意",
  "patient_hand_satisfaction_instrucion_3":"有點滿意",
  "patient_hand_satisfaction_instrucion_4":"很滿意",
  "patient_hand_satisfaction_q1":"您手指的動作",
  "patient_hand_satisfaction_q1_na":"不適用",
  "patient_hand_satisfaction_q1_a1":"很不滿意",
  "patient_hand_satisfaction_q1_a2":"不滿意",
  "patient_hand_satisfaction_q1_a3":"既沒有滿意，也沒有不滿意",
  "patient_hand_satisfaction_q1_a4":"有點滿意",
  "patient_hand_satisfaction_q1_a5":"很滿意",
  "patient_hand_satisfaction_q2":"您手腕的動作",
  "patient_hand_satisfaction_q2_na":"不適用",
  "patient_hand_satisfaction_q2_a1":"很不滿意",
  "patient_hand_satisfaction_q2_a2":"不滿意",
  "patient_hand_satisfaction_q2_a3":"既沒有滿意，也沒有不滿意",
  "patient_hand_satisfaction_q2_a4":"有點滿意",
  "patient_hand_satisfaction_q2_a5":"很滿意",
  "patient_hand_satisfaction_q3":"您手的外觀",
  "patient_hand_satisfaction_q3_na":"不適用",
  "patient_hand_satisfaction_q3_a1":"很不滿意",
  "patient_hand_satisfaction_q3_a2":"不滿意",
  "patient_hand_satisfaction_q3_a3":"既沒有滿意，也沒有不滿意",
  "patient_hand_satisfaction_q3_a4":"有點滿意",
  "patient_hand_satisfaction_q3_a5":"很滿意",
  "patient_hand_satisfaction_q4":"您手/手腕的治療",
  "patient_hand_satisfaction_q4_na":"不適用",
  "patient_hand_satisfaction_q4_a1":"很不滿意",
  "patient_hand_satisfaction_q4_a2":"不滿意",
  "patient_hand_satisfaction_q4_a3":"既沒有滿意，也沒有不滿意",
  "patient_hand_satisfaction_q4_a4":"有點滿意",
  "patient_hand_satisfaction_q4_a5":"很滿意",
  "patient_hand_complete":"問卷結束",
  "patient_hand_complete_text1":"感謝您抽出寶貴時間填寫此問卷。",
  "patient_hand_complete_text2":"請點擊提交完成，並查看您的分數。",
  "patient_hand_complete_text3":"您的回答將被保密，任何報告都將僅使用去除身份識別的資訊",
  "patient_hand_result":"結果",
  "patient_hand_result_text1":"以下是您的疼痛以及手部和腕部功能評分，這些評分是根據您的調查問卷計算得出的。",
  "patient_hand_result_function":"功能",
  "patient_hand_result_pain":"疼痛",
  "patient_hand_result_health_perception":"整體健康認知",
  "patient_hand_result_self_evaluation":"手和手腕評分自我評估",
  "patient_hand_result_text2":"您的醫生很快就會和您聯繫。",
  "patient_general_history":"目前的主訴症狀/受傷/疾病史",
  "patient_general_history_q1":"主訴症狀（用自己的話解釋您的主訴症狀）",
  "patient_general_history_q2":"問題開始日期",
  "patient_general_history_q3":"有做過醫學影像檢查嗎？",
  "patient_general_history_q3_a1":"無",
  "patient_general_history_q3_a2":"X光",
  "patient_general_history_q3_a3":"超音波",
  "patient_general_history_q3_a4":"核磁共振成像",
  "patient_general_history_q3_a5":"MRI 關節造影（關節內染色 MRI）",
  "patient_general_history_q3_a6":"CT",
  "patient_general_history_q3_a7":"CT 關節造影（關節內染色 CT）",
  "patient_general_history_q4":"您是否對您的病情進行了上面未列出的進一步調查？",
  "patient_general_history_q4_placeholder":"其他調查",
  "patient_general_past_history":"既往病史/當前的醫療問題",
  "patient_general_past_history_q1":"您有已知的過敏史嗎？ （藥物或食物）",
  "patient_general_past_history_q1_a1":"否",
  "patient_general_past_history_q1_a2":"是",
  "patient_general_past_history_placeholder":"請註明過敏和反應類型",
  "patient_general_past_history_q2":"您對乳膠過敏嗎？",
  "patient_general_past_history_q2_a1":"否",
  "patient_general_past_history_q2_a2":"是",
  "patient_general_past_history_q3":"您是否患有高血壓或正在接受高血壓治療？",
  "patient_general_past_history_q3_a1":"否",
  "patient_general_past_history_q3_a2":"是",
  "patient_general_past_history_q3_a2_placeholder":"請註明治療高血壓的藥物",
  "patient_general_past_history_q4":"您目前或曾經患有任何心臟病/相關疾病嗎？",
  "patient_general_past_history_q4_a1":"否",
  "patient_general_past_history_q4_a2":"是",
  "patient_general_past_history_q4_a2_o1":"選擇病況",
  "patient_general_past_history_q4_a2_o2":"心絞痛",
  "patient_general_past_history_q4_a2_o3":"心肌梗死",
  "patient_general_past_history_q4_a2_o4":"心臟衰竭（肺部積液）",
  "patient_general_past_history_q4_a2_o5":"雜音或心臟瓣膜問題",
  "patient_general_past_history_q4_a2_o6":"心悸/心跳不規則",
  "patient_general_past_history_q4_a2_o7":"植入裝置（ICD、起搏器）",
  "patient_general_past_history_q4_a2_o8":"心臟手術",
  "patient_general_past_history_q4_a2_o9":"頸動脈手術",
  "patient_general_past_history_q4_a2_o10":"其他心臟病",
  "patient_general_past_history_q4_a2_o8_q1_placeholder":"請註明心臟手術",
  "patient_general_past_history_q4_a2_o10_q1_placeholder":"請註明其他心臟狀況",
  "patient_general_past_history_q5":"您有心臟病專科醫生嗎？",
  "patient_general_past_history_q5_a1":"否",
  "patient_general_past_history_q5_a2":"是",
  "patient_general_past_history_q5_a2_placeholder":"名字和執業地址",
  "patient_general_past_history_q6":"您的腿部循環有問題（週邊血管疾病）嗎？",
  "patient_general_past_history_q6_a1":"否",
  "patient_general_past_history_q6_a2":"是",
  "patient_general_past_history_q6_a2_placeholder":"請形容",
  "patient_general_past_history_q7":"您上個月服用過血液稀釋劑嗎？",
  "patient_general_past_history_q7_a1":"否",
  "patient_general_past_history_q7_a2":"是",
  "patient_general_past_history_q7_a2_q1":"是什麼？",
  "patient_general_past_history_q7_a2_q1_a1":"阿斯匹靈",
  "patient_general_past_history_q7_a2_q1_a2":"氯吡格雷/保栓通",
  "patient_general_past_history_q7_a2_q1_a3":"利伐沙班",
  "patient_general_past_history_q7_a2_q1_a4":"埃利奎斯 (阿哌沙班)",
  "patient_general_past_history_q7_a2_q1_a5":"其他",
  "patient_general_past_history_q7_a2_q1_a5_placeholder":"請註明血液稀釋劑",
  "patient_general_past_history_q8":"您的肺部或胸部有任何問題嗎？",
  "patient_general_past_history_q8_a1":"否",
  "patient_general_past_history_q8_a2":"是",
  "patient_general_past_history_q8_a2_o1":"咳嗽有痰？",
  "patient_general_past_history_q8_a2_o2":"您的呼吸有什麼問題嗎？",
  "patient_general_past_history_q8_a2_o3":"其他",
  "patient_general_past_history_q8_a2_o2_o1":"哮喘",
  "patient_general_past_history_q8_a2_o2_o2":"肺部高血壓（肺動脈高壓）",
  "patient_general_past_history_q8_a2_o2_o3":"肺纖維化",
  "patient_general_past_history_q8_a2_o2_o4":"結節病",
  "patient_general_past_history_q8_a2_o2_o5":"慢性阻塞性肺病或肺氣腫",
  "patient_general_past_history_q8_a2_o2_o6":"囊性纖維化",
  "patient_general_past_history_q8_a2_o2_o7":"TB（結核病）",
  "patient_general_past_history_q8_a2_o2_o8":"其他",
  "patient_general_past_history_q8_a2_o3_placehold":"請註明",
  "patient_general_past_history_q8_a2_o2_o8_placeholder":"請註明",
  "patient_general_past_history_q9":"您去年是否服用過類固醇（普賴鬆或可的松）？",
  "patient_general_past_history_q9_a1":"否",
  "patient_general_past_history_q9_a2":"是",
  "patient_general_past_history_q10":"您使用家用氧氣嗎？",
  "patient_general_past_history_q10_a1":"否",
  "patient_general_past_history_q10_a2":"是",
  "patient_general_past_history_q11":"您有呼吸科醫生嗎？",
  "patient_general_past_history_q11_a1":"否",
  "patient_general_past_history_q11_a2":"是",
  "patient_general_past_history_q11_a2_placeholder":"名字和執業地址",
  "patient_general_past_history_q12":"您是否有過度打鼾或睡眠呼吸暫停的情況？",
  "patient_general_past_history_q12_a1":"否",
  "patient_general_past_history_q12_a2":"是",
  "patient_general_past_history_q12_a2_q1":"您的睡眠呼吸暫停是否使用 CPAP 或 BiPAP 治療？",
  "patient_general_past_history_q12_a2_q1_a1":"否",
  "patient_general_past_history_q12_a2_q1_a2":"是",
  "patient_general_past_history_q13":"您曾經有過肝臟問題嗎？",
  "patient_general_past_history_q13_a1":"否",
  "patient_general_past_history_q13_a2":"是",
  "patient_general_past_history_q13_a2_o1":"已選擇",
  "patient_general_past_history_q13_a2_o2":"肝硬化",
  "patient_general_past_history_q13_a2_o3":"甲型、乙型或丙型肝炎",
  "patient_general_past_history_q13_a2_o4":"黃疸",
  "patient_general_past_history_q13_a2_o5":"其他",
  "patient_general_past_history_q13_a2_o5_placeholder":"請註明",
  "patient_general_past_history_q14":"您是否經常出現胃灼熱、潰瘍或食管裂孔疝氣？",
  "patient_general_past_history_q14_a1":"否",
  "patient_general_past_history_q14_a2":"是",
  "patient_general_past_history_q15":"您曾經患過腎臟疾病嗎？",
  "patient_general_past_history_q15_a1":"否",
  "patient_general_past_history_q15_a2":"是",
  "patient_general_past_history_q15_a2_q1":"您是透析患者嗎？腹膜？血液透析",
  "patient_general_past_history_q15_a2_q1_a1":"否",
  "patient_general_past_history_q15_a2_q1_a2":"是",
  "patient_general_past_history_q15_a2_placeholder":"請註明腎臟疾病",
  "patient_general_past_history_q15_a2_q1_a2_placeholder":"請註明透析的類型和時間表",
  "patient_general_past_history_q16":"您有糖尿病嗎？",
  "patient_general_past_history_q16_a1":"否",
  "patient_general_past_history_q16_a2":"是",
  "patient_general_past_history_q16_a2_q1":"您的糖尿病是如何控制的？是靠飲食/口服藥物或胰島素？",
  "patient_general_past_history_q16_a2_q1_placeholder":"請註明",
  "patient_general_past_history_q17":"您有甲狀腺問題嗎？",
  "patient_general_past_history_q17_a1":"否",
  "patient_general_past_history_q17_a2":"是",
  "patient_general_past_history_q18":"您是否患有癲癇、驚厥或抽搐？",
  "patient_general_past_history_q18_a1":"否",
  "patient_general_past_history_q18_a2":"是",
  "patient_general_past_history_q18_a2_q1":"您最後一次癲癇發作是什麼時候？",
  "patient_general_past_history_q18_a2_q1_placeholder":"請註明",
  "patient_general_past_history_q19":"您的神經或肌肉有問題嗎？",
  "patient_general_past_history_q19_a1":"否",
  "patient_general_past_history_q19_a2":"是",
  "patient_general_past_history_q19_a2_o1":"癲癇發作",
  "patient_general_past_history_q19_a2_o1_placeholder":"什麼時候 ？",
  "patient_general_past_history_q19_a2_o2":"TIA（短暫性腦缺血發作/小中風）或中風",
  "patient_general_past_history_q19_a2_o2_placeholder":"什麼時候 ？",
  "patient_general_past_history_q19_a2_o3":"面部、腿部或手臂無力",
  "patient_general_past_history_q19_a2_o4":"神經系統疾病（例如：多發性硬化症、ALS、阿茲海默症）",
  "patient_general_past_history_q19_a2_o4_placeholder":"請註明",
  "patient_general_past_history_q19_a2_o5":"肌肉疾病（例如：重症肌無力、肌營養不良）",
  "patient_general_past_history_q19_a2_o5_placeholder":"請註明",
  "patient_general_past_history_q19_a2_o6":"聽力、視力或記憶力問題",
  "patient_general_past_history_q19_a2_o7":"慢性疼痛",
  "patient_general_past_history_q19_a2_o8":"其他",
  "patient_general_past_history_q19_a2_o8_placeholder":"請註明",
  "patient_general_past_history_q20":"您有關節炎嗎？",
  "patient_general_past_history_q20_a1":"否",
  "patient_general_past_history_q20_a2":"是",
  "patient_general_past_history_q20_a2_o1":"骨關節炎",
  "patient_general_past_history_q20_a2_o2":"類風濕關節炎",
  "patient_general_past_history_q20_a2_o3":"其他",
  "patient_general_past_history_q20_a2_o3_placeholder":"請註明",
  "patient_general_past_history_q21":"您曾經接受過精神疾病治療嗎？",
  "patient_general_past_history_q21_a1":"否",
  "patient_general_past_history_q21_a2":"是",
  "patient_general_past_history_q21_a2_o1":"嚴重焦慮",
  "patient_general_past_history_q21_a2_o2":"抑鬱",
  "patient_general_past_history_q21_a2_o3":"其他",
  "patient_general_past_history_q21_a2_o3_placeholder":"請註明",
  "patient_general_past_history_q22":"您是否曾被診斷出患有出血性疾病？",
  "patient_general_past_history_q22_a1":"否",
  "patient_general_past_history_q22_a2":"是",
  "patient_general_past_history_q22_a2_o1":"白血病或淋巴瘤",
  "patient_general_past_history_q22_a2_o2":"血友病",
  "patient_general_past_history_q22_a2_o3":"血塊",
  "patient_general_past_history_q22_a2_o4":"貧血",
  "patient_general_past_history_q22_a2_o5":"鐮狀細胞性貧血症",
  "patient_general_past_history_q22_a2_o6":"其他",
  "patient_general_past_history_q22_a2_o6_placeholder":"請註明",
  "patient_general_past_history_q23":"您貧血嗎？",
  "patient_general_past_history_q23_a1":"否",
  "patient_general_past_history_q23_a2":"是",
  "patient_general_past_history_q24":"您的腿部或肺部是否曾出現過血栓？",
  "patient_general_past_history_q24_a1":"否",
  "patient_general_past_history_q24_a2":"是",
  "patient_general_past_history_q25":"您或您家人中的任何人是否遇到過以下嚴重問題：",
  "patient_general_past_history_q25_a1":"鼻子流血",
  "patient_general_past_history_q25_a1_o1":"否",
  "patient_general_past_history_q25_a1_o2":"是",
  "patient_general_past_history_q25_a2":"拔牙時出血",
  "patient_general_past_history_q25_a2_o1":"否",
  "patient_general_past_history_q25_a2_o2":"是",
  "patient_general_past_history_q25_a3":"手術後出血",
  "patient_general_past_history_q25_a3_o1":"否",
  "patient_general_past_history_q25_a3_o2":"是",
  "patient_general_past_history_q26":"您曾經被診斷出患有癌症嗎？",
  "patient_general_past_history_q26_a1":"否",
  "patient_general_past_history_q26_a2":"是",
  "patient_general_past_history_q26_a2_placeholder1":"請註明癌症類型",
  "patient_general_past_history_q26_a2_placeholder2":"什麼時候？",
  "patient_general_past_history_q27":"您曾經接受過化療或電療嗎？",
  "patient_general_past_history_q27_a1":"否",
  "patient_general_past_history_q27_a2":"是",
  "patient_general_past_history_q27_a2_placeholder1":"治療名稱",
  "patient_general_past_history_q27_a2_placeholder2":"最後一次治療日期",
  "patient_general_past_history_q28":"您或您的親屬在麻醉方面有任何問題嗎？",
  "patient_general_past_history_q28_a1":"否",
  "patient_general_past_history_q28_a2":"是",
  "patient_general_past_history_q28_a2_q1":"哪些問題？",
  "patient_general_past_history_q28_a2_q1_placeholder":"選擇",
  "patient_general_past_history_q28_a2_q1_o1":"手術後嚴重噁心或嘔吐",
  "patient_general_past_history_q28_a2_q1_o2":"惡性高熱（有血緣關係的家人或您本人）",
  "patient_general_past_history_q28_a2_q1_o3":"呼吸問題或麻醉呼吸管插入困難？",
  "patient_general_past_history_q28_a2_q1_o4":"呼吸管放置問題",
  "patient_general_past_history_q28_a2_q1_o5":"其他",
  "patient_general_past_history_q28_a2_q1_o5_placeholder":"請註明",
  "patient_general_past_history_q29":"您有以下情況嗎？",
  "patient_general_past_history_q29_q1":"牙齒、假牙、局部假牙破損或鬆動",
  "patient_general_past_history_q29_q1_a1":"否",
  "patient_general_past_history_q29_q1_a2":"是",
  "patient_general_past_history_q29_q2":"頸部活動困難",
  "patient_general_past_history_q29_q2_a1":"否",
  "patient_general_past_history_q29_q2_a2":"是",
  "patient_general_past_history_q29_q3":"張嘴有問題",
  "patient_general_past_history_q29_q3_a1":"否",
  "patient_general_past_history_q29_q3_a2":"是",
  "patient_general_past_history_q30":"您有可能已經懷孕了嗎？",
  "patient_general_past_history_q30_a1":"否",
  "patient_general_past_history_q30_a2":"是",
  "patient_general_past_history_q30_not_applicable":"不適用",
  "patient_general_past_history_q30_a2_q1":"上次月經",
  "patient_general_past_history_q30_a2_q1_placeholder":"上次月經",
  "patient_general_past_history_q31":"病史：請列出您患有的上面未註明的任何其他疾病",
  "patient_general_past_history_q31_q1":"列出所有藥物（包括非處方藥、吸入器、貼劑、滴劑、維生素、礦物質、補充劑、草藥）",
  "patient_general_past_history_q31_q2":"註明藥物名稱、劑量、頻率和服用原因",
  "patient_general_past_history_q31_q2_placeholder":"藥物名稱、劑量、頻率、服用原因",
  "patient_general_past_history_q31_q3":"劑量",
  "patient_general_past_history_q31_q4":"經常",
  "patient_general_past_history_q31_q5":"服用理由",
  "patient_general_past_history_q31_q6":"手術史",
  "patient_general_past_history_q31_q6_placeholder":"請註明手術和手術日期",
  "patient_general_social_history":"社會史",
  "patient_general_social_history_q1":"您獨自生活嗎？",
  "patient_general_social_history_q1_a1":"否",
  "patient_general_social_history_q1_a2":"是",
  "patient_general_social_history_q1_a1_q1":"您和誰一起生活？",
  "patient_general_social_history_q1_a1_q1_placeholder":"您和誰一起生活？",
  "patient_general_social_history_q2":"您抽煙嗎？",
  "patient_general_social_history_q2_a1":"否",
  "patient_general_social_history_q2_a2":"是",
  "patient_general_social_history_q2_a2_q1":"每天吸多少支煙？",
  "patient_general_social_history_q2_a2_q1_placeholder":"香煙數量",
  "patient_general_social_history_q2_a2_q2":"年數？",
  "patient_general_social_history_q2_a2_q2_placeholder":"年",
  "patient_general_social_history_q3":"您過去吸煙嗎？",
  "patient_general_social_history_q3_a1":"否",
  "patient_general_social_history_q3_a2":"是",
  "patient_general_social_history_q3_a2_q1":"您什麼時候戒菸的？",
  "patient_general_social_history_q3_a2_q1_placeholder":"您什麼時候戒菸的？",
  "patient_general_social_history_q3_a2_q2_placeholder":"年",
  "patient_general_social_history_q4":"您喝酒嗎？",
  "patient_general_social_history_q4_a1":"否",
  "patient_general_social_history_q4_a2":"是",
  "patient_general_social_history_q4_a2_q1_placeholder":"每週標準飲酒量",
  "patient_general_social_history_q5":"您是否在使用或曾經使用過任何非處方藥或街頭（非法）毒品、大麻或阿片類藥物？",
  "patient_general_social_history_q5_a1":"否",
  "patient_general_social_history_q5_a2":"是",
  "patient_general_social_history_q5_a2_placeholder":"請註明藥品",
  "patient_general_complete":"問卷結束",
  "patient_general_complete_text1":"感謝您抽出寶貴時間填寫此問卷。",
  "patient_general_complete_text2":"請點擊提交完成，並查看您的分數。",
  "patient_general_complete_text3":"您的回答將被保密，任何報告都將僅使用去除身份識別的資訊",
  "AppVersion":"APP版本",
  "Complete_personal_info":"完整的個人資訊",
  "To_complete_the_sign_up":"要完成註冊，請在下面填寫您的資訊。",
  "Select_Existing_Profile":"選擇現有個人資訊",
  "Get_In":"輸入",
  "Create_new_patient":"創建一個新患者",
  "myProfile":"我的個人資訊",
  "First_Name":"名*",
  "Last_Name":"姓*",
  "DOB":"出生日期*",
  "Sex":"性別*",
  "Address":"地址*",
  "Postcode":"郵遞區號*",
  "Suburb":"區鄉鎮市*",
  "Country_Code":"國碼",
  "Mobile_Phone":"手機",
  "Home_Phone":"家庭電話",
  "Email":"電子郵件*",
  "Emergency_Contact":"緊急聯絡人*",
  "Phone":"電話*",
  "Relationship":"關係*",
  "Regular_GP_Name":"全科家庭醫生姓名*",
  "Practice_Name":"執業名稱*",
  "Medicare_Card_Number":"醫療保險卡號碼",
  "Ref":"參考號",
  "Message":"留言",
  "Expiry":"到期",
  "Private_Health_Fund":"私人醫療基金",
  "Membership_Number":"會員號碼",
  "Claim_Type":"索賠類型",
  "Work_Cover":"職業保險保障範圍",
  "Employer":"雇主",
  "Third_Party_Claim_Number":"第三方索賠編號",
  "Third_Party_Name":"第三方名稱",
  "DVA_Concession_Card":"DVA 優惠卡",
  "Number":"數字",
  "Defence_Personnel":"國防人員",
  "I_agree":"我同意讓我的個人數據接受處理，並已閱讀",
  "Privacy_Policy":"隱私政策。",
  "visitClinic":"預約",
  "selectdocOrg":"選擇醫生和組織*",
  "doctorOrganization":"醫生組織”",
  "Select_Treatment_Category":"選擇治療類別*",
  "Do_you_want_to_submit_patient_form_or_not":"您是否要提交患者表格？",
  "areyousurewanttocontinueyourform":"您之前的表格正在等待處理。您想繼續嗎？",
  "myVisit":"過去的訪問",
  "View_score":"查看分數",
  "upcoming_visits":"即將到來",
  "No_data_found":"沒有找到數據",
  "Doctor_Name":"醫生姓名",
  "Appointment_Date_Time":"預約日期和時間",
  "Clinic_Name":"診所名稱",
  "Clinic_Address":"診所地址",
  "akmed":"阿庫納醫療 （AKUNAH MED）",
  "Type":"打字",
  "Date_Time":"日期 & 時間",
  "Action":"動作",
  "View":"查看",
  "No_Messages":"沒有留言",
  "settings":"設定",
  "Language":"語言",
  "language":"語言及地區",
  "timezone":"時區",
  "Timeformat":"時間格式",
  "List_of_my_profile":"我的個人資料",
  "next_button":"下一步",
  "back_button":"退回",
  "Continue":"繼續",
  "Close":"關閉",
  "submit":"提交",
  "Sign_out":"返回個人資料",
  "Results":"結果",
  "update":"表格更新成功",
  "Personal_Data":"個人資料",
  "Date_of_Birth":"出生日期",
  "Hip_arthritis":"髖關節炎？ *",
  "Area_of_foot_ankle":"腳/腳踝受影響的區域？ *",
  "Diagnosis":"診斷 ？ *",
  "Evaluated_Shoulder":"肩部評估",
  "Dominant_Hand":"慣用手*",
  "Age":"年齡",
  "No":"否",
  "Yes":"是",
  "visitNo":"訪問否",
  "visitYes":"訪問是",
  "shoulderPainR":"肩痛",
  "Is_it_initial_visit":"這是您初次訪問嗎",
  "for_the_problem":"對於這個問題？ *",
  "Diagnosis_Procedure":"診斷/程序",
  "Procedure":"程式",
  "Evaluated_sdfs":"已評估*",
  "Please_Select_previous_visits":"請選擇之前的訪問*",
  "Follow_Up":"跟進",
  "Assessment_Date":"評估日期",
  "Do_you_have_Dupuytren_disease":"您患有掌腱膜攣縮症嗎？",
  "Do_you_have_carpal_tunnel_syndrome":"您患有腕隧道症候群嗎？",
  "Is_your_unaffected_shoulder_pain":"您未受影響的肩膀是否無疼痛且功能正常？",
  "Is_your_unaffected_hip_pain":"您未受影響的臀部是否無疼痛且功能正常？",
  "Is_your_unaffected_knee_pain":"您未受影響的膝蓋是否無疼痛且功能正常？",
  "Is_your_unaffected_elbow_pain":"您未受影響的肘部是否無疼痛且功能正常？",
  "Is_your_unaffected_foot_and_ankle_pain":"您未受影響的腳和腳踝是否無疼痛且功能正常？",
  "Is_your_unaffected_hand_pain":"您未受影響的手和手腕是否無疼痛且功能正常？",
  "Patient_Consent":"Akunah 患者資訊和免費事先同意書",
  "Patient_Last_Name":"患者姓氏",
  "Patient_First_Name":"患者名字",
  "Patient_Date_of_Birth":"患者出生日期",
  "information":"資訊",
  "I_am_informedDDD":"為了對您的骨科治療進行評估、計劃和結果評價，您的醫生，",
  "Clinician":"（以下簡稱“臨床醫生”）使用 Akunah Medical Technology Pty Ltd 及其子公司（“Akunah”）提供的評估、結果數據收集和分析和/或術前規劃服務和軟體。",
  "For_that":"為此，臨床醫生會將您的個人數據傳輸給 Akunah，包括您身體的電腦斷層掃描 (CT) 和其他醫療數據。",
  "Akunah_softawre":"Akunah 的軟體和服務可能涉及圖像評估、醫療數據評估、創建個性化解剖結構 3D 模型以及關鍵解剖測量、手術計劃和手術支援，這些資訊將與臨床醫生共享。",
  "the_clinic":"臨床醫生將使用評估和術前計劃來進行您的骨科治療。",
  "oportunnity":"您還有機會通過 Akunah 的患者門戶網站提供個人數據，並訪問 Akunah 的模型和計劃。",
  "akunah_approch":"Akunah 的隱私和安全方法",
  "akunah_approch1":"Akunah 將從您那裡收集數據以協助您的臨床醫生，這是我們最關心的問題。",
  "akunah_approch2":"Akunah 自願遵守適用的歐洲資料保護法，其中包括歐盟通用資料保護條例 EU/2016/679，因為這些法律是全球頒布的最強有力的隱私法。這些法律超出了澳大利亞、美國和其他國家頒布的隱私法。",
  "akunah_approch3":"Akunah 的“處理客戶個人資訊的隱私準則”描述了 Akunah 如何遵守適用的歐洲數據保護法（",
  "akunah_approch4":"隱私守則",
  "akunah_approch5":"Akunah 的“資料處理協議”描述了其與臨床醫生為保護和確保您的個人資訊安全而做出的安排（",
  "akunah_approch6":"資料處理協議",
  "akunah_approch7":"這些政策可在 Akunah 網站上找到：",
  "terms_":"https://akunah.com/termsofservices",
  "akunah_approch8":"如果隱私準則、資料處理協議和本文件之間存在任何不一致，則第一個提到的文件的條款將適用於與本文件的任何不一致之處。",
  "Data_Collection_and_Processing":"資料收集和處理",
  "I_am_informed":"Akunah 可能會收集您的資料，包括與您的聯絡資訊、身份驗證、身份、職業和人口統計相關的資訊。 Akunah 還可能收集與您的健康相關的醫療資料，例如",
  "Assessment_and_evaluation":"評估和評價資料、身體特徵以及與提議的治療和結果相關的其他資訊；",
  "Previous_surgery":"• 既往手術、診斷、放射掃描、3D 模型、解剖學、解剖測量； 和",
  "Health_and_medical_history":"• 健康和病史，包括您的症狀、藥物以及之前對您進行的任何診斷和治療。",
  "I_have_been_assured":"Akunah 始終以合法、公平的方式收集資訊，無論是直接從患者處、由臨床醫生共享還是通過 Akunah 產品收集。 Akunah 將根據您的同意內容（如下文所述）處理這些資料，以便在必要時提供評估和術前規劃服務。",
  "My_data":"此外，Akunah 可能會使用您的個人資料來遵守所有適用的法律和法規",
  "your_data":"如果提供擬議的服務要求或法律要求，您的資料也可能會披露給第三方。",
  "Data_Access_and_Storage":"資料訪問、存儲和安全",
  "Personal_data_provided":"您和您的臨床醫生提供的資料只能由 Akunah 和第三方的工作人員訪問，他們會為您的治療或康復做出貢獻（例如麻醉師、物理治療師）並且需要訪問您的資料。",
  "data1":"通過 Akunah 軟體輸入的資料會由經批准的託管商安全地託管在澳大利亞聯邦。這可確保適當級別的機密性和安全性，反映通過 Akunah 軟體處理的資料的性質。",
  "data2":"如果您位於澳大利亞聯邦境外，和/或通過Akunah 軟體輸入的資料可能會被位於澳大利亞聯邦境外（包括歐盟或美國）的Akunah 子公司訪問，在這些國家使用和保護您的資料處理方式可能不同，則可能適用不同的隱私標準。在這種情況下，您的個人資料將存儲在該國家/地區。",
  "data3":"Akunah 根據所有適用的法律要求採取適當的保障措施，以確保資料保密並受到所有第三方足夠水平的資料保護。我們要求所有有權訪問的人採用確保與 Akunah 採用的資料保護水平相同的標準。",
  "data4":"您的臨床醫生有責任根據法律的要求來收集、訪問、分析和共享您的資料。",
  "p_data1":"Akunah 保存您的個人資料的時間長度會有所不同。保留期限將根據以下標準確定：(i) Akunah 或臨床醫生使用您的個人資料的目的（他們只需要在該目的所需的時間內保留資料）； (ii) 可能規定Akunah 必須保留您的個人資料的最短期限的適用法律或法規。",
  "Deidentified_Data":"去身份識別資料",
  "d_txt1":"Akunah 可能會對您的資料進行去識別化處理，然後使用這些去識別化資料（通常與其他患者的去識別化資料匯總）用於記錄目的、研究和開發、資料分析、軟體/設備開發以及Akunah產品和服務的改進。這使得 Akunah 能夠不斷改進產品和服務，造福像您這樣的患者。",
  "Patient_Rights":"您的權利",
  "I_understand_that":"您可以拒絕同意向 Akunah 提供您的個人資料。在這種情況下，請諮詢您的臨床醫生，因為如果不使用 Akunah 的軟體和服務，您的臨床醫生可能無法為您提供醫療服務或治療。",
  "I_understand_that_1txt":"您有權隨時以任何方式撤回您的同意，並且在適用的情況下，您有權： (i) 獲取有關您個人資料處理的資訊，並訪問此類個人資料； (ii) 對不准確的地方要求其更正； (iii) 請求限制處理您的個人資料，包括反對對您的個人資料進行某些處理並要求撤回您提供的同意； (iv) 請求刪除您的個人資料； (v) 制定與您去世後處理您的資料相關的準則； (vi) 獲取在上述條件下以結構化、常用和機器可讀格式處理的您的個人資料的副本。您可以通過直接向臨床醫生提出您的請求來行使這些權利。",
  "I_understand_that_2txt":"您的權利受到適用法律的豁免和限制。",
  "I_understand_that_3txt":"退出後，如果您在建議的手術或治療之前行使這些權利，您的臨床醫生可能無法為您提供醫療服務或治療。",
  "I_understand_that_4txt":"您還可以自由地向您居住國的監管機構投訴您個人資料的處理情況。",
  "I_understand_that_5txt":"在去識別化資料方面，去識別化資料不受與個人資料相同的法律限制。請注意，您不能撤回對訪問和使用您的去識別化資料的同意，或請求刪除這些資料，因為 Akunah 將無法找到您的資料。去身份化後，Akuna 無法將資料追溯到您。",
  "Contact":"同意書",
  "I_am_able_to_contact":"我已閱讀並理解上面的資訊段落，或者有人用我理解的語言將其讀給我聽。",
  "or_by_post":"我有機會提出問題，我對收到的答案感到滿意。我了解我的資訊將保密。",
  "or_by_post2":"我同意我的醫生",
  "or_by_post2_1":"（“臨床醫生”）與 Akunah 分享我的個人資料。",
  "or_by_post3":"我同意 Akunah 收集、存儲、使用和共享我的個人資料和去識別化資料，如上面資訊部分所述。",
  "or_by_post4":"我了解我可以隨時撤回我的同意。",
  "or_by_post5":"我了解我將收到一份已簽名的本文件副本以供保存。",
  "or_by_post6":"我可以通過電子郵件聯繫 Akunah 處理我的資料",
  "info_akunah":"info@akunah.com",
  "or_by_post7":"或郵寄至 Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australia。",
  "Clear":"清除",
  "Date":"日期",
  "Signed_by_Mouse":"滑鼠簽名 *",
  "Name_if_consent_on_behalf_of_patient":"姓名（代表患者同意的情況）",
  "Relationship_to_patient_if_applicable":"與患者的關係（如果適用）",
  "Choose_Sign_Type":"選擇簽名方式*",
  "Draw":"畫",
  "Signed_by_Keyboard":"鍵盤簽名 *",
  "Agreement":"協定",
  "agree_that_I_have_read":"我同意我已閱讀並理解本患者同意書，並且我明確同意出於上述目的對我的個人資料進行處理。",
  "NOTE":"筆記",
  "If_the_patient":"如果患者未滿 18 歲，則必須由其父母或法定監護人簽署。",
  "If_the_patient_is_deceased":"如果患者已去世，則必須由其近親簽名。",
  "patient_signature":"患者簽名",
  "profile_updated":"個人資料已更新",
  "profile_not_updated":"個人資料未更新",
  "video":"影片",
  "radiology_form":"放射檢驗表格",
  "doc_document":"文件",
  "pathology_form":"病理表格",
  "text":"文字",
  "Document_Name":"文件名稱",
  "email_sent_successfully":"刪除請求發送成功。",
  "messages":"留言",
  "adult_knee":"成人膝關節",
  "paediatric_knee":"小兒膝關節",
  "document":"我的文件",
  "Check_in":"報到",
  "info_icon":"填寫患者調查問卷",
  "reminder":"筆記",
  "Search_with_minimum_three_characters":"請至少使用三個字符進行搜索",
  "Select":"選擇",
  "delete_account":"刪除帳戶",
  "DD_MM_YYYY":"日/月/年",
  "Female":"女性",
  "Male":"男性",
  "want_to_disclose":"不想透露",
  "enter_address":"輸入地址",
  "logout":"登出",
  "full_name":"全名",
  "mmyy":"月/年",
  "health_found":"健康基金",
  "Not_Applicable":"不適用",
  "not_available":"無法使用",
  "available":"可用的",
  "shared":"共享",
  "private":"私人的",
  "add_document":"添加文檔",
  "drap_and_drop":"將檔案拖放到此處",
  "or_browser_files":"或瀏覽電腦上的檔案",
  "browse_files":"瀏覽檔案",
  "download":"下載",
  "Oops_error":"哎呀！未找到您請求的資料。",
  "go_to_home":"返回首頁",
  "This_field_is_required":"此字段是必需的",
  "PendingForm":"待處理表格",
  "NoFollowUpRecords":"無後續記錄",
  "Right":"右",
  "Both":"雙",
  "Left":"左",
  "shoulder":"肩膀",
  "hip_form":"臀部",
  "Foot_And_Ankle":"腳和腳踝",
  "Hand_And_Wrist":"手和手腕",
  "General":"一般",
  "adolescent_knee":"青少年膝蓋",
  "Elbow":"肘部",
  "title":"標題",
  "description":"描述",
  "enter_your_description_here":"在此輸入描述",
  "enter_your_title_here":"在此輸入標題",
  "progress":"待辦的",
  "done":"完全的",
  "reset":"重置",
  "send":"發送",
  "Edit":"修改",
  "delete":"刪除",
  "Are_you_sure_want_to_done_the_remainder":"您確定要完成此筆記嗎？",
  "Confirm":"確認",
  "Update":"更新",
  "success":"成功",
  "error":"出錯",
  "Are_you_sure_you_want_to_delete":"您確定您要刪除？",
  "cancel":"取消",
  "Hour_Time":"24 小時",
  "Knee":"膝蓋",
  "no":"否",
  "yes":"是",
  "Year":"0年，0月，0日",
  "Bilateral":"雙手靈巧",
  "Left_Arm":"左肩",
  "Right_Arm":"右肩",
  "out_of":"/",
  "Right_Elbow":"右肘",
  "Left_Elbow":"左肘",
  "right_foot_ankle":"右腳/腳踝",
  "left_foot_ankle":"左腳/腳踝",
  "Right_hand_and_wrist":"右手和手腕",
  "Left_hand_and_wrist":"左手和手腕",
  "Hip_Function_R":"右臀",
  "Hip_Function_L":"左臀",
  "Right_Knee":"右膝",
  "Left_Knee":"左膝",
  "Right_Pedi_Knee":"右膝",
  "Left_Pedi_Knee":"左膝",
  "Scoring_Self_Evaluation":"自我評價評分",
  "presciption_form":"處方",
  "insurance_form":"保險",
  "letter_form":"信件",
  "refferal_form":"轉介",
  "medical_form":"醫療",
  "are_you_sure_delete_account":"您確定要刪除您的帳戶嗎？",
  "Adult_Knee_Pain":"成人膝蓋疼痛",
  "Knee_pain_13_18_year":"膝蓋疼痛",
  "day_pdata":"天",
  "days_pdata":"天",
  "month_pdata":"月",
  "months_pdata":"月",
  "year_pdata":"年",
  "years_pdata":"年",
  "Name":"姓名",
  "akunah_id":"Akunah ID",
  "mobile":"電話",
  "email":"電子郵件",
  "upload_a_profile":"上傳圖片",
  "No_special_characters_are_allowed":"不允許使用特殊字符",
  "numbers_are_not_allowed":"不允許使用數字",
  "maximum_50_character_are_allowed":"最多允許 50 個字符",
  "invalid_character":"無效字符",
  "fields_only_contain_10_character":"字段僅包含 10 個字符",
  "maximum_10_Numbers_are_allowed":"最多允許 10 個號碼",
  "Only_I":"只允許使用數字",
  "invalid_home_phone_number":"無效的家庭電話號碼",
  "special_characters_are_not_allowed":"不允許有特殊字符",
  "maximum_digit_are_allowed":"最多允許 1 位數字",
  "invalid_date":"失效日期",
  "maximum_10_digits_are_allowed":"最多允許 10 位數字",
  "switch_profile":"個人檔案切換",
  "loading":"載入中",
  "error_max_profile_limit":"您已達到使用一個電子郵件地址允許的最大帳戶數",
  "error_upcoming_appointments":"未找到即將到來的預約安排",
  "error_past_visits":"未找到過去的訪問記錄",
  "error_documents":"沒有找到文件",
  "country_hawaii":"(UTC-10:00) 夏威夷時間",
  "country_tahiti":"(UTC-10:00) 大溪地島",
  "country_pitcairn":"(UTC-08:00) 皮特凱恩",
  "country_niue":"(UTC-11:00) 紐埃",
  "country_mountain":"(UTC-07:00) 山地時間",
  "country_arizona":"(UTC-07:00) 山地時間 - 亞利桑那州",
  "country_whitehorse":"(UTC-08:00) 太平洋時間 - 懷特霍斯",
  "country_chihuahua":"(UTC-07:00) 山地時間 - 奇瓦瓦州、馬薩特蘭",
  "country_edmonton":"(UTC-07:00) 山地時間 - 埃德蒙頓",
  "country_gambier":"(UTC-09:00) 甘比爾",
  "country_yellowknife":"(UTC-07:00) 山地時間 - 黃刀鎮",
  "country_dawson":"(UTC-07:00) 山地時間 - 道森溪",
  "country_belize":"(UTC-06:00) 貝里斯",
  "country_hermosillo":"(UTC-07:00) 山地時間 - 埃莫西約",
  "country_mexico":"(UTC-06:00) 中部時間 - 墨西哥城",
  "country_regina":"(UTC-06:00) 中部時間 - 里賈納",
  "country_tegucigalpa":"(UTC-06:00) 中部時間 - 特古西加爾巴",
  "country_centraltime":"(UTC-06:00) 中部時間",
  "country_rarotonga":"(UTC-10:00) 拉羅湯加",
  "country_pago":"(UTC-11:00) 帕果帕果",
  "country_pacific":"(UTC-08:00) 太平洋時間",
  "country_alaska":"(UTC-09:00) 阿拉斯加時間",
  "country_vancouver":"(UTC-08:00) 太平洋時間 - 溫哥華",
  "country_tijuana":"(UTC-08:00) 太平洋時間 - 蒂華納",
  "country_salvador":"(UTC-06:00) 薩爾瓦多",
  "country_costa":"(UTC-06:00) 哥斯達黎加",
  "country_winnipeg":"(UTC-06:00) 中部時間 - 溫尼伯",
  "country_galapagos":"(UTC-06:00) 加拉帕戈斯群島",
  "country_managua":"(UTC-06:00) 馬那瓜",
  "country_america_cancun":"(UTC-05:00) 美洲坎昆",
  "country_guatemala":"(UTC-06:00) 瓜地馬拉",
  "country_bogota":"(UTC-05:00) 波哥大",
  "country_easter_island":"(UTC-05:00) 復活節島",
  "country_iqaluit":"(UTC-05:00) 東部時間 - 伊魁特",
  "country_toronto":"(UTC-05:00) 東部時間 - 多倫多",
  "country_eastern_time":"(UTC-05:00) 東部時間",
  "country_havana":"(UTC-05:00) 哈瓦那",
  "country_lima":"(UTC-05:00) 利馬",
  "country_jamaica":"(UTC-05:00) 牙買加",
  "country_panama":"(UTC-05:00) 巴拿馬",
  "country_port_au_prince":"(UTC-05:00) 太子港",
  "country_nassau":"(UTC-05:00) 拿騷",
  "country_rio_branco":"(UTC-05:00) 里約布蘭科",
  "country_barbados":"(UTC-04:00) 巴巴多斯",
  "country_halifax":"(UTC-04:00) 大西洋時間 - 哈利法克斯",
  "country_bermuda":"(UTC-04:00) 百慕達",
  "country_boa_vista":"(UTC-04:00) 博阿維斯塔",
  "country_curacao":"(UTC-04:00) 庫拉索島",
  "country_marquesas":"(UTC-09:30) 馬克薩斯",
  "country_caracas":"(UTC-04:00) 加拉加斯",
  "country_grand_turk":"(UTC-04:00) 大特克島",
  "country_la_paz":"(UTC-04:00) 拉巴斯",
  "timezone_guyana":"(UTC-04:00) 圭亞那",
  "timezone_port_of_spain":"(UTC-04:00) 西班牙港",
  "timezone_manaus":"(UTC-04:00) 馬瑙斯",
  "timezone_martinique":"(UTC-04:00) 馬提尼克島",
  "timezone_porto_velho":"(UTC-04:00) 韋柳港",
  "timezone_santo_domingo":"(UTC-04:00) 聖多明各",
  "timezone_puerto_rico":"(UTC-04:00) 波多黎各",
  "timezone_araguaina":"(UTC-03:00) 阿拉瓜伊納",
  "timezone_belem":"(UTC-03:00) 貝倫",
  "timezone_buenos_aires":"(UTC-03:00) 布宜諾斯艾利斯",
  "timezone_newfound_time":"(UTC-03:30) 紐芬蘭時間 - 聖約翰斯",
  "timezone_asuncion":"(UTC-03:00) 亞松森",
  "timezone_campo":"(UTC-03:00) 大坎普",
  "timezone_cayenne":"(UTC-03:00) 卡宴",
  "timezone_cuiaba":"(UTC-03:00) 庫亞巴",
  "timezone_maceio":"(UTC-03:00) 馬塞約",
  "timezone_godthab":"(UTC-03:00) 戈德哈布",
  "timezone_fortaleza":"(UTC-03:00) 福塔雷薩",
  "timezone_miquelon":"(UTC-03:00) 密克隆群島",
  "timezone_palmer":"(UTC-03:00) 帕爾默",
  "timezone_montevideo":"(UTC-03:00) 蒙得維的亞",
  "timezone_recife":"(UTC-03:00) 累西腓",
  "timezone_rothera":"(UTC-03:00) 羅瑟拉",
  "timezone_paramaribo":"(UTC-03:00) 帕拉馬里博",
  "timezone_punta_arenas":"(UTC-03:00) 蓬塔阿雷納斯",
  "timezone_santig":"(UTC-03:00) 聖地亞哥",
  "timezone_salvador":"(UTC-03:00) 薩爾瓦多",
  "timezone_stanley":"(UTC-03:00) 斯坦利",
  "timezone_south_georgia":"(UTC-02:00) 南喬治亞島",
  "timezone_noronha":"(UTC-02:00) 諾羅尼亞群島",
  "timezone_sao_pulo":"(UTC-02:00) 聖保羅",
  "timezone_azores":"(UTC-01:00) 亞速爾群島",
  "timezone_abidjan":"(UTC+00:00) 阿比讓",
  "timezone_cape_verde":"(UTC-01:00) 佛得角",
  "timezone_scoresbysund":"(UTC-01:00) 斯科斯比桑德",
  "timezone_bissau":"(UTC+00:00) 比紹",
  "timezone_accra":"(UTC+00:00) 阿克拉",
  "timezone_danmarkshavn":"(UTC+00:00) 丹麥港",
  "timezone_casablanca":"(UTC+00:00) 卡薩布蘭卡",
  "timezone_dublin":"(UTC+00:00) 都柏林",
  "timezone_canary_islands":"(UTC+00:00) 加那利群島",
  "timezone_el_aaiun":"(UTC+00:00) 阿尤恩",
  "timezone_thule":"(UTC-04:00) 圖勒",
  "timezone_gmt":"(UTC+00:00) GMT（無夏令時）",
  "timezone_lisbon":"(UTC+00:00) 里斯本",
  "timezone_faeroe":"(UTC+00:00) 法羅",
  "timezone_reykjavik":"(UTC+00:00) 雷克雅未克",
  "timezone_monrovia":"(UTC+00:00) 蒙羅維亞",
  "timezone_algiers":"(UTC+01:00) 阿爾及爾",
  "timezone_london":"(UTC+00:00) 倫敦",
  "timezone_amsterdam":"(UTC+01:00) 阿姆斯特丹",
  "timezone_andorra":"(UTC+01:00) 安道爾",
  "timezone_berlin":"(UTC+01:00) 柏林",
  "timezone_budapest":"(UTC+01:00) 布達佩斯",
  "timezone_ceuta":"(UTC+01:00) 休達",
  "timezone_brussels":"(UTC+01:00) 布魯塞爾",
  "timezone_copenhagen":"(UTC+01:00) 哥本哈根",
  "timezone_gibrltar":"(UTC+01:00) 直布羅陀",
  "timezone_prague":"(UTC+01:00) 中歐時間 - 布拉格",
  "timezone_lagos":"(UTC+01:00) 拉各斯",
  "timezone_luxembourg":"(UTC+01:00) 盧森堡",
  "timezone_madrid":"(UTC+01:00) 馬德里",
  "timezone_malta":"(UTC+01:00) 馬耳他",
  "timezone_ndjamena":"(UTC+01:00) 恩賈梅納",
  "timezone_paris":"(UTC+01:00) 巴黎",
  "timezone_oslo":"(UTC+01:00) 奧斯陸",
  "timezone_monaco":"(UTC+01:00) 摩納哥",
  "timezone_tirane":"(UTC+01:00) 地拉那",
  "timezone_rome":"(UTC+01:00) 羅馬",
  "timezone_vienna":"(UTC+01:00) 維也納",
  "timezone_tunis":"(UTC+01:00) 突尼斯",
  "timezone_warsaw":"(UTC+01:00) 華沙",
  "timezone_amman":"(UTC+02:00) 安曼",
  "timezone_zurich":"(UTC+01:00) 蘇黎世",
  "timezone_athens":"(UTC+02:00) 雅典",
  "timezone_burcharest":"(UTC+02:00) 布加勒斯特",
  "timezone_chisinau":"(UTC+02:00) 基希訥烏",
  "timezone_stockholm":"(UTC+01:00) 斯德哥爾摩",
  "timezone_cairo":"(UTC+02:00) 開羅",
  "timezone_gaza":"(UTC+02:00) 加薩",
  "timezone_jerusalem":"(UTC+02:00) 耶路撒冷",
  "timezone_johannesburg":"(UTC+02:00) 約翰尼斯堡",
  "timezone_helsinki":"(UTC+02:00) 赫爾辛基",
  "timezone_khartoum":"(UTC+02:00) 喀土穆",
  "timezone_belgrade":"(UTC+01:00) 歐洲中部時間 - 貝爾格萊德",
  "timezone_damascus":"(UTC+02:00) 大馬士革",
  "timezone_maputo":"(UTC+02:00) 馬普托",
  "timezone_kaliningrad":"(UTC+02:00) 莫斯科-01 - 加里寧格勒",
  "timezone_riga":"(UTC+02:00) 里加",
  "timezone_kiev":"(UTC+02:00) 基輔",
  "timezone_nicosia":"(UTC+02:00) 尼科西亞",
  "timezone_tallinn":"(UTC+02:00) 塔林",
  "timezone_tripoli":"(UTC+02:00) 的黎波里",
  "timezone_sofia":"(UTC+02:00) 索非亞",
  "timezone_vilnius":"(UTC+02:00) 維爾紐斯",
  "timezone_istanbul":"(UTC+03:00) 伊斯坦布爾",
  "timezone_baghdad":"(UTC+03:00) 巴格達",
  "timezone_minsk":"(UTC+03:00) 明斯克",
  "timezone_windhoek":"(UTC+02:00) 溫得和克",
  "timezone_nairobi":"(UTC+03:00) 內羅畢",
  "timezone_moscow":"(UTC+03:00) 莫斯科+00 - 莫斯科",
  "timezone_qatar":"(UTC+03:00) 卡達",
  "timezone_beirut":"(UTC+02:00) 貝魯特",
  "timezone_riyadh":"(UTC+03:00) 利雅得",
  "timezone_syowa":"(UTC+03:00) 昭和",
  "timezone_tehran":"(UTC+03:30) 德黑蘭",
  "timezone_baku":"(UTC+04:00) 巴庫",
  "timezone_dubai":"(UTC+04:00) 杜拜",
  "timezone_mahe":"(UTC+04:00) 馬埃島",
  "timezone_mauritius":"(UTC+04:00) 模里西斯",
  "timezone_tbilisi":"(UTC+04:00) 提比里斯",
  "timezone_samara":"(UTC+04:00) 莫斯科+01 - 薩馬拉",
  "timezone_reunion":"(UTC+04:00) 團聚",
  "timezone_yerevan":"(UTC+04:00) 埃里溫",
  "timezone_kabul":"(UTC+04:30) 喀布爾",
  "timezone_aqtau":"(UTC+05:00) 阿克套",
  "timezone_aqtobe":"(UTC+05:00) 阿克托比",
  "timezone_ashgabat":"(UTC+05:00) 阿什哈巴德",
  "timezone_dushanbe":"(UTC+05:00) 杜尚別",
  "timezone_maldives":"(UTC+05:00) 馬爾地夫",
  "timezone_yekaterinburg":"(UTC+05:00) 莫斯科+02 - 葉卡捷琳堡",
  "timezone_kerguelen":"(UTC+05:00) 凱爾蓋朗",
  "timezone_mawson":"(UTC+05:00) 莫森",
  "timezone_tashkent":"(UTC+05:00) 塔什幹",
  "timezone_colombo":"(UTC+05:30) 可倫坡",
  "timezone_kathmandu":"(UTC+05:45) 加德滿都",
  "timezone_omsk":"(UTC+06:00) 莫斯科+03 - 鄂木斯克",
  "timezone_india_standard_time":"(UTC+05:30) 印度標準時間",
  "timezone_chagos":"(UTC+06:00) 查哥斯",
  "timezone_dhaka":"(UTC+06:00) 達卡",
  "timezone_bishkek":"(UTC+06:00) 比什凱克",
  "timezone_cocos":"(UTC+06:30) 科科斯",
  "timezone_thimphu":"(UTC+06:00) 廷布",
  "timezone_almaty":"(UTC+06:00) 阿拉木圖",
  "timezone_vostok":"(UTC+06:00) 東方",
  "timezone_rangoon":"(UTC+06:30) 仰光",
  "timezone_christmas":"(UTC+07:00) 聖誕節",
  "timezone_davis":"(UTC+07:00) 戴維斯",
  "timezone_karachi":"(UTC+05:00) 克拉蚩",
  "timezone_hanoi":"(UTC+07:00) 河內",
  "timezone_beijing":"(UTC+08:00) 中國時間 - 北京",
  "timezone_hongkong":"(UTC+08:00) 香港",
  "timezone_hovd":"(UTC+07:00) 霍布德",
  "timezone_jakarta":"(UTC+07:00) 雅加達",
  "timezone_kuala_lumpur":"(UTC+08:00) 吉隆坡",
  "timezone_bangkok":"(UTC+07:00) 曼谷",
  "timezone_krasnoyarsk":"(UTC+07:00) 莫斯科+04 - 克拉斯諾亞爾斯克",
  "timezone_brunei":"(UTC+08:00) 汶萊",
  "timezone_macau":"(UTC+08:00) 澳門",
  "timezone_makassar":"(UTC+08:00) 望加錫",
  "timezone_choibalsan":"(UTC+08:00) 喬巴山",
  "timezone_ulaanbaatar":"(UTC+08:00) 烏蘭巴托",
  "timezone_manila":"(UTC+08:00) 馬尼拉",
  "timezone_pyongyang":"(UTC+08:30) 平壤",
  "timezone_singapore":"(UTC+08:00) 新加坡",
  "timezone_irkutsk":"(UTC+08:00) 莫斯科+05 - 伊爾庫茨克",
  "timezone_taipei":"(UTC+08:00) 台北",
  "timezone_jayapura":"(UTC+09:00) 查亞普拉",
  "timezone_dili":"(UTC+09:00) 帝力",
  "timezone_perth":"(UTC+08:00) 西部時間 - 珀斯",
  "timezone_yakutsk":"(UTC+09:00) 莫斯科+06 - 雅庫茨克",
  "timezone_palau":"(UTC+09:00) 帛琉",
  "timezone_seoul":"(UTC+09:00) 首爾",
  "timezone_tokyo":"(UTC+09:00) 東京",
  "timezone_darwin":"(UTC+09:30) 中部時間 - 達爾文",
  "timezone_dumont":"(UTC+10:00) 杜蒙·杜維爾",
  "timezone_brisbane":"(UTC+10:00) 東部時間 - 布里斯班",
  "timezone_guam":"(UTC+10:00) 關島",
  "timezone_vladivostok":"(UTC+10:00) 莫斯科+07 - 符拉迪沃斯托克",
  "timezone_port_moresby":"(UTC+10:00) 莫爾茲比港",
  "timezone_truk":"(UTC+10:00) 特魯克",
  "timezone_adelaide":"(UTC+10:30) 中部時間 - 阿德萊德",
  "timezone_casey":"(UTC+11:00) 東部時間 - 霍巴特",
  "timezone_melbourne_sydney":"(UTC+11:00) 東部時間 - 墨爾本、悉尼",
  "timezone_kosrae":"(UTC+11:00) 科斯雷",
  "timezone_efate":"(UTC+11:00) 埃法特",
  "timezone_guadalcanal":"(UTC+11:00) 瓜達爾卡納爾島",
  "timezone_ponape":"(UTC+11:00) 波納佩",
  "timezone_norfolk":"(UTC+11:00) 諾福克",
  "timezone_magadan":"(UTC+11:00) 莫斯科+08 - 馬加丹",
  "timezone_noumea":"(UTC+11:00) 努美阿",
  "timezone_majuro":"(UTC+12:00) 馬朱羅",
  "timezone_funafuti":"(UTC+12:00) 富納富提",
  "timezone_nauru":"(UTC+12:00) 瑙魯",
  "timezone_tarawa":"(UTC+12:00) 塔拉瓦",
  "timezone_kwajalein":"(UTC+12:00) 誇賈林環礁",
  "timezone_enderbury":"(UTC+13:00) 恩德伯里",
  "timezone_wake":"(UTC+12:00) 醒來",
  "timezone_fakaofo":"(UTC+13:00) 法考福",
  "timezone_wallis":"(UTC+12:00) 沃利斯",
  "timezone_fiji":"(UTC+13:00) 斐濟",
  "timezone_apia":"(UTC+14:00) 阿皮亞",
  "timezone_kiritimati":"(UTC+14:00) 基里蒂馬蒂",
  "timezone_tongatapu":"(UTC+13:00) 湯加塔布島",
  "timezone_auckland":"(UTC+13:00) 奧克蘭",
  "dateFormet":"日期格式",
  "Org_success":"加入組織的請求已成功發送",
  "Org_err":"您已經加入該組織",
  "Request_Organization":"請求組織",
  "organization_requested":"組織已請求",
  "join":"加入",
  "your_email":"email@address.com",
  "medical_summary":"您尚未填寫完醫療摘要。",
  "medical_summary_msg":"我的醫療摘要",
  "medical_success":"醫療摘要已成功提交",
  "patient_form_popup":"您想繼續填寫之前的問卷嗎？",
  "Please_confirm_that_your_details_are_correct":"請確認您的個人資料正確無誤",
  "Welcome_to_Akunah_your_Health_Passport":"歡迎來到 Akunah，您的健康護照。",
  "Your_helth_is_in_your_hands":"您的健康掌握在自己手中。",
  "Lets_begin_your_journey":"展開您的旅程吧。",
  "Please_complete_your_profile":"請填寫您的個人資料。",
  "Profile_complete":"個人資料完成！",
  "Would_you_like_to_add_another_profile_for_a_family_member":"您想為家庭成員添加另一份個人資料嗎？",
  "Alert_detected":"警報：偵測到多重登入",
  "To_safeguard_your_data":"為保護您的資料隱私和安全，網頁已定為逾時。",
  "Kindly_close_all_other_Akunah":"請關閉所有其他 Akunah 標籤，然後按 \"返回主頁 \"繼續。",
  "Akunah_Team_is_dedicated":"Akunah 團隊致力於保護您的資料。",
  "Last_Created":"最新創建",
  "First_Created":"首次創建",
  "Alphabetically":"按字母順序 (A-Z)",
  "Alphabetically1":"按字母順序 (Z-A)",
  "Your_form_submission_was_successful_Clinician":"您的表格提交成功。我們現在自動重定向到臨床醫生應用程式",
  "Your_form_submission_was_successful_Patient":"您的表格提交成功。我們現在自動重定向到病人應用程式",
  "If_this_doesnt_happen_within":"如果在 5 秒內沒有移動，",
  "click_here":"請點擊此處",
  "Save":"儲存",
  "Proceed_to_my_dashboard":"前往我的報表頁",
  "myJourney":"我的歷程",
  "Home":"首頁",
  "No_journey":"未找到歷程",
  "Share_results_with_your_doctor":"與醫師分享結果",
  "If_you_would_like_to_share_your_results_with":"如果您想和醫師分享結果，請選擇下方的「立即分享」",
  "Link_PROMs":"連結病人回報結果評估",
  "Welcome_to_akunah":"歡迎來到Akunah",
  "Share_to_your_Doctor_using_one_of_the_3_options_below":"用以下的三個選項和醫師分享",
  "Doctor_Code":"醫師碼",
  "Enter_the_code_that_has_been_provided_to_you_by_your":"請輸入醫生在電子郵件或診所提供給您的代碼。如果沒有代碼，請查看您最近的就診記錄，或搜尋您的醫生",
  "Recent_Visits":"最近的到訪",
  "Select_your_recent_doctor_or":"在此選擇您",
  "organisation_here":"近期的醫師或機構",
  "Search":"搜尋",
  "Search_for_your_doctor_or":"搜尋您的醫師或",
  "Please_confirm_that_the_following_details":"請確認以下細節正確",
  "Organisation":"機構",
  "PROMs_Specialty_Date":"病人回報結果評估專科及日期",
  "Get_started_by_completing_your":"請從完成病人回報結果評估問卷開始",
  "and_exploring_your_progress":"探索您的進展",
  "Fill_up_your_PROMs_questionnaire":"填寫病人回報結果評估問卷",
  "Hip":"臀部",
  "My_Progress":"我的進展",
  "Completed":"已完成",
  "Expired":"已過期",
  "Incomplete":"未完成",
  "Specialty":"專門領域",
  "Doctor":"醫師",
  "Status":"狀態",
  "View_form":"觀看表格",
  "Share_to_another_doctor":"和其他醫師分享",
  "Edit_form":"編輯表單",
  "Answer_the_form":"回答表單",
  "Complete_the_form":"完成表單",
  "Delete_the_form":"刪除表單",
  "Share_to_doctor":"與醫師分享",
  "Your_account_has_been_created":"您的帳號已創建",
  "Please_click_below_to_proceed_to_your_dashboard":"請點擊下方連結前往報表頁",
  "If_you_would_like_to_add_another_profile_for_a_family":"如果您想為家庭成員增加個人檔案，請",
  "Share":"分享",
  "Hip_Scoring_Self_Evaluation_Questionnaire":"臀部分數自我評量問卷",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire":"手和手腕分數自我評量問卷",
  "Right_Hand_nd_Wrist":"右手和手腕",
  "Left_Hand_nd_Wrist":"左手和手腕",
  "DOB_1":"出生日期",
  "Sex_1":"性別",
  "Address_1":"地址",
  "Please_Choose_PROMs_to_link":"請選擇要連結的病人回報結果評估",
  "Please_select_atleast_one_form_or_close":"請選擇至少一個病人回報結果評估，或關閉",
  "cant_find_appropriate_PROMs_to_link":"找不到適合的病人回報結果評估去連結嗎？",
  "Request_New_PROMs":"要求新的病人回報結果評估",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit":"提醒：您最近的到訪有未完成的問卷",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted":"如果選擇 \"否\"，則會建立新的問卷，並刪除未完成的問卷。",
  "Would_you_like_to_continue_this_form":"您想繼續下去嗎？",
  "Note":"備註：",
  "Expiring_in":"到期：",
  "Date_Created":"已創建日期",
  "Days":"日後",
  "Shared":"已分享",
  "Pending":"未完成",
  "Initial":"剛開始",
  "Recent":"近期",
  "Pain":"疼痛",
  "Function":"功能",
  "Share_Now":"立即分享",
  "Share_Later":"之後分享",
  "Updated":"已更新",
  "Display_More":"顯示更多",
  "Address_Now":"現在處理",
  "Address_Later":"之後處理",
  "You_have_pending_questionnaires":"你有未完成的問卷",
  "Notifications":"通知",
  "Form_shared_successfully":"表格分享成功",
  "You_have_already_shared_with_same_doctor_and_organization":"您已經與同一醫生或機構分享了這一點",
  "No_recent_notifications":"最近沒有新通知",
  "Have_a_good_day":"祝你有美好的一天",
  "Patient_scores":"患者分數",
  "Display less":"減少顯示",
  "myJourney_Shoulder":"肩膀",
  "myJourney_Shoulder_txt":"選擇肩膀以查看您的肩膀歷程",
  "myJourney_Elbow":"肘部",
  "myJourney_Elbow_txt":"選擇肘部以查看您的肘部歷程",
  "myJourney_Hand_Wrist":"手和手腕",
  "myJourney_Hand_Wrist_txt":"選擇手和手腕以查看您的手和手腕歷程",
  "myJourney_Hip":"臀部",
  "myJourney_Hip_txt":"選擇臀部以查看您的臀部歷程",
  "myJourney_knee":"膝蓋",
  "myJourney_knee_txt":"選擇膝蓋以查看您的膝蓋歷程",
  "myJourney_Foot_Ankle":"腳和腳踝",
  "myJourney_Foot_Ankle_txt":"選擇腳和腳踝以查看您的腳和腳踝歷程",
  "data_sort":"種類",
  "Visit_Clinic":"訪問診所",
  "My_Visits":"我的訪問",
  "Patient_Scoring_Forms":"患者評分表格",
  "Other_findings":"其他發現",
  "Organization":"機構",
  "more":"更多",
  "mediacal_summary":"醫學摘要",
  "my_consent":"我的同意",
  "Are_you_sure_you_want_to_reject":"您確定要封鎖此轉介嗎？",
  "reject":"拒絕",
  "i_accept_consent":"我對此同意",
  "grant":"授予",
  "no_conscent_found":"未找到同意",
  "rejected":"已拒絕",
  "No_Time_Lines":"沒有時間表",
  "Hide_Score":"隱藏分數",
  "Show_Score":"顯示分數",
  "patient_sex":"性別",
  "Dominant_hand_1":"慣用手",
  "patient_Initial_Assessment":"初始評估",
  "patient_Treatment_Category":"治療類別",
  "View_all":"查看全部",
  "Display_Less":"減少顯示",
  "patient_general_past_Medical_history":"病史",
  "patient_general_past_Medication_name":"藥物清單",
  "Evaluated_Knee":"已評估的膝蓋",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function":"您未受影響的手和手腕是否無疼痛且功能正常？",
  "Evaluated_Hip":"已評估的臀部",
  "Evaluated_Foot_Ankle":"已評估的腳和腳踝",
  "patient_Evaluated_Elbow":"已評估的肘部",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function":"您未受影響的腳和腳踝是否無疼痛且功能正常？",
  "Evaluated_Hand_Wrist":"已評估的手和腕",
  "patient_shoulder_feeling_instability_q2":"請標明您的肩關節有多不穩定",
  "patient_shoulder_feeling_instability_q3":"請標明您的肩關節有多不穩定",
  "patient_Hand_BCTQ":"手和手腕BCTQ",
  "Paediatric_Knee_Satisfaction":"小兒膝蓋滿意度",
  "patient_foot_ankle_symptoms":"腳和腳踝的其他症狀",
  "Right_Foot_and_Ankle":"右腳和右腳踝",
  "Left_Foot_and_Ankle":"左腳和左腳踝",
  "summary":"摘要",
  "Shoulder_Treatment_Form":"肩部治療表格",
  "Adolescent_knee_Treatment_Form":"青少年膝蓋治療表格",
  "Adult_Knee_Treatment_Form":"成人膝蓋治療表格",
  "Paediatric_Knee_Treatment_Form":"小兒膝關節治療表格",
  "Hip_Treatment_Form":"髖關節治療表格",
  "Foot_Ankle_Treatment_Form":"腳踝治療表格",
  "Elbow_Treatment_Form":"肘部治療表格",
  "Hand_Wrist_Treatment_Form":"手和手腕治療表格",
  "Upload_complete":"上傳完成",
  "Select_Date_Format":"選擇日期格式",
  "timezone_casey1":"(UTC+11:00) 凱西",
  "timezone_Kamchatskiy":"(UTC+12:00) 莫斯科+09 - 堪察加彼得巴甫洛夫斯克",
  "timezone_Guayaquil":"(UTC-05:00) Guayaquil",
  "requested":"請求",
  "Display_name":"顯示名字",
  "Are_you_sure_you_want_to_delete_Consent":"您確定要拒絕這項同意書嗎？",
  "form":"表格",
  "Requested_by":"請求者",
  "requested_hip_form":"請求臀部表格",
  "requested_shoulder_form":"請求肩部表格",
  "requested_knee_form":"請求膝蓋表格",
  "requested_foot_form":"請求腳和腳踝表格",
  "requested_hand_form":"請求手和手腕表格",
  "requested_elbow_form":"請求手肘表格",
  "requested_General_form":"請求一般表格",
  "requested_Paediatric_form":"requested a Paediatric Knee form",
  "requested_Adolescent_form":"requested a Adolescent Knee form",
  "Expiring_in_Tomorrow":"Expiring tomorrow",
  "PROMs_questionnaire":"Dr. [doctor’s full name who requested] has requested you to fill out [specialty] PROMs questionnaire",
  "new_message":"You have a new message from [full name of message sender]",
  "new_appointment":"You have a new appointment with Dr. [doctor’s full name]",
  "upcoming_appointment":"Reminder: upcoming appointment with Dr. [full name] on [date]",
  "pending_message":"Reminder: you have a pending [specialty] PROMs questionnaire",
  "document_message":"Dr. [full name of who shared] shared a document with you",
  "requested_message":"Dr. [full name of who requested] has requested for a consent",
  "patient_added_message":"You have been added as a patient of Dr [full name] in [organisation]",
  "cancelled_appointment_message":"Your appointment with Dr [full name] on [date of appointment] has been cancelled.",
  "Mark_all_read":"Mark all as read",
  "Delete_All":"Delete All",
  "No_Notification":"No notifications found",
  "You_have_not":"You have not started your journey yet. Please fill out a PROMs questionnaire to begin your journey.",
  "pass_verification1":"Password should be at least 8 characters long *",
  "pass_verification2":"Password must contain at least 1 upper case character*",
  "pass_verification3":"Password must contain at least 1 lower case character*",
  "pass_verification4":"Password must contain at least 1 digit *",
  "loginTitle":"Akunah:Sign In ::",
  "Create_patient_mobile":"Create new profile",
  "Select_Profile_mobile":"Select your profile",
  "Profile_Created_mobile":"Profile Created",
  "Your_account_has_been_created_mobile":"Your account has been created.",
  "Please_tap_below_to_sign_in_to_your_workspace_mobile":"Please tap below to sign in to your workspace.",
  "Start_Journey_mobile":"Start Journey",
  "If_add_another_profile_mobile":"If you would like to add another profile for a family member, please",
  "Create_Profile_mobile":"Create Profile",
  "change_Profile_Pic_mobile":"Change profile photo",
  "MobileImp_mobile":"Mobile*",
  "Gender_mobile":"Gender",
  "Country_1_mobile":"Country",
  "Med_1_mobile":"Med",
  "Appoinments_mobile":"Appointments",
  "View_Profile_mobile":"View Profile",
  "Sign_out_mobile":"Sign out",
  "My_Appointments_mobile":"My Appointments",
  "Appoinments_Past_mobile":"Past",
  "No_appointments_mobile":"No appointments",
  "When_appointment_available_mobile":"When your next appointment is available, it will be displayed here",
  "Available_soon_mobile":"Available soon!",
  "please_akunah_desktop_mobile":"To access it now, please login tome.akunah.comon your desktop.",
  "All_Notes_mobile":"All Notes",
  "AddButton_mobile":"AddButton",
  "Start_your_note_mobile":"Start your note...",
  "Done_mobile":"Done",
  "Note_Title_mobile":"Note Title",
  "New_Consents_mobile":"New Consents",
  "Signed_Consents_mobile":"Signed Consents",
  "Consent_mobile":"Consent",
  "Signed_Type_mobile":"Signed by Type",
  "Submit_Consent_mobile":"Submit Consent",
  "Reject_Consent_mobile":"Reject Consent",
  "Type_name_mobile":"Type your name",
  "Signature_mobile":"Signature",
  "No_consents_mobile":"No consents",
  "Any_display_ready_mobile":"Any new or signed consents will display here when ready",
  "Clinician_Documents_mobile":"Clinician Documents",
  "Uploaded_Documents_mobile":"Uploaded Documents",
  "Upload_Document_mobile":"Upload Document",
  "Upload_file_mobile":"Upload file",
  "Upload_photo_mobile":"Upload photo",
  "Take_photo_mobile":"Take photo",
  "Uploading_1_mobile":"Uploading",
  "New_1_mobile":"New",
  "Share_document_mobile":"Share this document",
  "Enter_email_mobile":"Enter email",
  "Add_message_like_mobile":"Add a message, if you’d like.",
  "Are_delete_account_mobile":"Are you sure you want to delete your account?",
  "No_display_mobile":"No documents to display",
  "All_clinician_documents_mobile":"All clinician documents will display here",
  "New_Messages_mobile":"New Messages",
  "Read_Messages_mobile":"Read Messages",
  "Any_new_unread_messages_ready_mobile":"Any new or unread messages will be displayed here when ready",
  "Shoulder_Journey_mobile":"Shoulder Journey",
  "Hip_Journey_mobile":"Hip Journey",
  "Foot_Ankle_Journey_mobile":"Foot and Ankle Journey",
  "Elbow_Journey_mobile":"Elbow Journey",
  "Knee_Journey_mobile":"Knee Journey",
  "Hand_Wrists_Journey_mobile":"Hand and Wrist Journey",
  "New_Form_mobile":"New Form",
  "Start_new_form_mobile":"Start new form",
  "Requested_Forms_mobile":"Requested Forms",
  "Incomplete_Forms_mobile":"Incomplete Forms",
  "Completed_Forms_mobile":"Completed Forms",
  "No_Available_mobile":"No Forms Available",
  "Start_new_journey_mobile":"Start a new form to begin your journey.",
  "View_Results_mobile":"View Results",
  "Complete_Form_mobile":"Complete Form",
  "Delete_Form_mobile":"Delete Form",
  "Edit_Details_mobile":"Edit Details",
  "Personal_Details_mobile":"Personal Details",
  "DOB_1_mobile":"DOB",
  "Unknown_Gender_mobile":"Unknown Gender",
  "Mobile_mobile":"Mobile",
  "Next_kin’s_name_mobile":"Next of kin’s name",
  "Next_kin’s_relationship_mobile":"Next of kin’s relationship",
  "GP_Details_mobile":"GP Details",
  "GP_Name_mobile":"GP Name",
  "Practice_mobile":"Practice",
  "Edit_Profile_mobile":"Edit Profile",
  "Full_Name_mobile":"Full Name",
  "Country_Code_mobile":"Country Code",
  "GP_Full_Name_mobile":"GP Full Name",
  "Doctor’s_full_name_mobile":"Doctor’s full name",
  "All_mobile":"All",
  "No_notifications_mobile":"No recent notifications",
  "Any_new_available_mobile":"Any new or unread notifications will be displayed here when available",
  "Account_mobile":"Account",
  "Manage_My_Account_mobile":"Manage My Account",
  "Change_Password_mobile":"Change Password",
  "Privacy_mobile":"Privacy",
  "Time_mobile":"Time",
  "Date_Format_mobile":"Date & Format",
  "Support_mobile":"Support",
  "All_Notifications_mobile":"All Notifications",
  "Receive_notifications_mobile":"Receive all notifications",
  "Journey_Updates_mobile":"Journey Updates",
  "Receive_appointment_notifications_mobile":"Receive all appointment notifications",
  "Start_Form_mobile":"Start Form",
  "Your_journey_starts_here_mobile":"Your journey starts here",
  "To_recovery_questions_mobile":"To get started with your journey to recovery, we just ask you to please best answer the following questions.",
  "Please_estimate_questions_mobile":"Please estimate 12-15 minutes to answer these questions.",
  "Shoulder_Form_mobile":"Shoulder Form",
  "Save_and_Continue_mobile":"Save & Continue",
  "How_years_smoke_mobile":"How many years did you smoke?",
  "patient_shoulder_pain_inner_value_mobile":"(0 means no pain, 15 means intolerable pain)",
  "Scoring_Key_mobile":"Scoring Key",
  "patient_shoulder_wosi_1_mobile":"Western Ontario Shoulder Instability Index (WOSI)",
  "patient_shoulder_wosi_sectionA_phy_mobile":"PHYSICAL SYMPTOMS",
  "patient_shoulder_wosi_sectionB_sp_mobile":"SPORTS/RECREATION/WORK",
  "patient_shoulder_wosi_sectionC_life_mobile":"LIFESTYLE",
  "patient_shoulder_wosi_sectionD_emo_mobile":"EMOTIONS",
  "Specific_AC_Score_mobile":"Specific AC Score (SACS)",
  "patient_shoulder_sacs_sectionA_pain_mobile":"Pain",
  "patient_shoulder_sacs_sectionB_function_mobile":"FUNCTION",
  "patient_shoulder_sacs_sectionC_Qua_mobile":"Quality of life and satisfaction",
  "Finish_mobile":"Finish",
  "Share_Results_mobile":"Share Results",
  "Share_Doctor_mobile":"Share to your Doctor",
  "Choose_share_results_doctor_mobile":"Choose one of the 3 options below to share your results with your doctor",
  "Share_your_Clinician_mobile":"Share with your Clinician",
  "Select_recent_doctor_organisation_mobile":"Select your recent doctor or organisation here",
  "Clinician_Search_mobile":"Clinician Search",
  "Search_doctor_organisation_mobile":"Search for your doctor or organisation here",
  "Clinician_Code_mobile":"Clinician Code",
  "Enter_doctor_code_search_mobile":"Enter the code that has been provided to you by your doctor in your email or in the clinic. If the code is not available, please check you recent visits or use the search.",
  "Skip_sharing_now_mobile":"Skip sharing for now",
  "Code_please_try_again_mobile":"Code incorrect, please try again.",
  "Confirm_details_mobile":"Confirm details",
  "PROMs_Speciality_Date_mobile":"PROMs Speciality & Date",
  "Results_Shared_mobile":"Results Shared!",
  "Your_successfully_doctor_mobile":"Your results has been successfully shared with your doctor.",
  "Please_results_mobile":"Please tap below to view your results.",
  "If_another_doctor_mobile":"If you would like to share with another doctor",
  "please_mobile":"please",
  "click_here_mobile":"click here",
  "Pain_Score_mobile":"Pain Score",
  "Recent_Scores_mobile":"Recent Scores",
  "Right_Arm_mobile":"Right Arm",
  "SACS_Score_mobile":"SACS",
  "complete_mobile":"Complete",
  "Add_Note_mobile":"Add Note",
  "Pending_Forms_mobile":"Pending Forms",
  "Your_pending__will_display_mobile":"Your pending forms will be displayed here",
  "Your_Incomplete_display_mobile":"Your Incomplete Forms will be displayed here",
  "Your_complete_display_mobile":"Your complete forms will be displayed here",
  "PROMs_Shoulder_request_mobile":"PROMs Shoulder request",
  "New_message_mobile":"New message",
  "New_appointment_mobile":"New appointment",
  "New_Consent_request_mobile":"New Consent request",
  "New_shared_document_mobile":"New shared document",
  "New_Organisation_Clinician_mobile":"New Organisation/Clinician",
  "This_field_cannot_empty_mobile":"This field cannot be empty.",
  "List_work_activities_mobile":"List work activities ...",
  "List_hobby_activities_mobile":"List hobby and sport activities ...",
  "patient_shoulder_wosi_sectionA_mobile":"SECTION A:",
  "patient_shoulder_wosi_sectionB_mobile":"SECTION B:",
  "patient_shoulder_wosi_sectionC_mobile":"Section C:",
  "patient_shoulder_wosi_sectionD_mobile":"Section D:",
  "patient_shoulder_sacs_sectionA_mobile":"Section A:",
  "patient_shoulder_sacs_sectionB_mobile":"Section B:",
  "patient_shoulder_sacs_sectionC_mobile":"Section C:",
  "sac_explaination":"Explanation",
  "patient_shoulder_sacs_explanation_mobile":"For each question, you will be asked to rate either your level of pain or difficulty performing different tasks. The rating scale is designed so that 0 reflects no pain or difficulty while 10 reflects the most severe amount of pain or difficulty.",
  "patient_shoulder_sacs_a3_note_mobile":"(e.g. Laptop or hand bag strap).",
  "patient_shoulder_sacs_b2_note_mobile":"(e.g. Resting your arm in a pocket or using a sling).",
  "patient_shoulder_sacs_b3_note_mobile":"(e.g. Push up, cycling, yoga).",
  "patient_shoulder_sacs_b4_note_mobile":"(e.g. Bag of shopping).",
  "patient_shoulder_sacs_b5_note_mobile":"(e.g. To reach across and touch the unaffected shoulder).",
  "patient_shoulder_sacs_b6_note_mobile":"(e.g. Greater than five kilograms).",
  "patient_shoulder_sacs_b7_note_mobile":"(e.g. Holding the steering wheel whilst driving).",
  "patient_shoulder_sacs_c1_note_mobile":"(Including the presence of a lump).",
  "Function_Score_mobile":"Function Score",
  "Instability_Score_mobile":"Instability Score",
  "patient_shoulder_pain_q2_mobile":"On a scale of 0-15, what is the highest level of pain that you have experienced in your shoulder during ordinary activities within the last 24 hours?",
  "patient_shoulder_pain_q6_mobile":"Do you take pain medication",
  "patient_shoulder_pain_q6_mobile_sub":"(eg paracetamol etc)?",
  "patient_shoulder_pain_q6_a2_q1_mobile":"Do you take strong pain medication",
  "patient_shoulder_pain_q6_a2_q1_mobile_sub":"(eg codeine, tramadol, morphine etc)?",
  "patient_shoulder_feeling_instability_q1_b1_mobile_sub":"(as if it is going to dislocate)?",
  "patient_shoulder_feeling_instability_q1_mobile":"Does your shoulder feel unstable",
  "patient_shoulder_wosi_instruction_mobile":"INSTRUCTIONS",
  "Next_of_kins_number_mobile":"Next of kin’s number",
  "Next_of_Kin_mobile":"Next of Kin",
  "Doctor_Name_mobile":"Doctor's name",
  "Doctors_practice_mobile":"Doctor’s practice",
  "Doctors_practice_address_mobile":"Doctor’s practice address",
  "Language_mobile":"Languages",
  "No_Internet_Connection_mobile":"No Internet Connection",
  "Please_type_your_name_mobile":"Please type your name",
  "Choose_Sign_Type_mobile":"Choose Sign Type",
  "Signed_by_Draw_mobile":"Signed by Draw",
  "select_image_mobile":"select image",
  "Consent_rejected_mobile":"Consent rejected",
  "Consent_submitted_mobile":"Consent submitted"
}