import React, { useState, useEffect } from "react";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import aku_icon from "../../images/aku.svg";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "../custom/button/Button";
import useAxios from "../../axiosinstance";
import useTranslation from "../customHooks/translations";
import { useDispatch } from "react-redux";
import { loginData, loginVisitsData } from "../../redux/actions/index";
import { fetchUserIdSuccess } from "../../redux/actions/index";
export default function PatientComplete() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const [doctor, setDoctor] = useState("");
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const dispatch = useDispatch();
  const [loginDatas, setLoginDatas] = useState([]);
  const [langData, setlangData] = useState([]);
  const { akPt } = useParams();
  const redirectToHome = () => {
    navigate("/patient-consent-form");
    // window.location.reload(false);
  };

  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getDetailsOfPatient?patient_id=${akPt}&use_for=patient`
      );
      if (result.status === 200) {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          dispatch(loginVisitsData(result.data));
          localStorage.setItem("AkTOKENI", akPt);
          sessionStorage.setItem(
            "lang_",
            result.data.data[0].patient.user_language
          );
          sessionStorage.setItem(
            "isConsent",
            result.data.data[0].patientInfo.isConsent
          );
          redirectToHome();
        }
      }
    } catch (err) {
      // setMessage(err.message || "Unexpected Error!");
      console.log(err.Messages);
      // setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };

  //   const getData = async () => {
  //     try {
  //       const response = await axiosInstance.current.post(
  //         "extapp/patients/pat_signin"
  //       );

  //       dispatch(fetchUserIdSuccess(response.data));
  //     } catch (error) { }
  //   };

  const getData = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getLanguages/${loginDatas.ak_id}`
      );
     
      if (result.status === 200) {
        setlangData(result.data.data);
      }
    } catch (err) {
      console.log(err.Messages);
    } finally {
      console.log("api response finally");
    }
  };
  // const getData = async () => {
  //   try {
  //     const response = await axiosInstance.current.post(
  //       "extapp/patients/pat_signin"
  //     );

  //     dispatch(fetchUserIdSuccess(response.data));
  //   } catch (error) { }
  // };

  useEffect(() => {
    if (loginDatas) {
      getData();
    }
  }, [loginDatas]);

  const handeleConfirm = () => {
    //  navigate("/patient-consent-form");
    getData();

    handleConnectMe();

    // setTimeout(() => {
    //   handleConnectMe()
    // }, 1000);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="doctor_reg_screen">
          <div className="tab-content pt-0">
            <div className="panel">
              <div className="panel-body " style={{ paddingTop: "5vh" }}>
                <div className="dc_reg_inner">
                  <img
                    src={aku_icon}
                    alt=""
                    style={{ margin: "0px auto 35px", display: "block" }}
                  />
                  <div className="reg_sub_title text-center mb-3">
                    <div className="text-bold black">
                      {translation.Profile_complete}!
                    </div>
                  </div>
                  <p
                    className="text-center"
                    style={{ margin: "0px auto 25px", display: "block" }}
                  >
                    {translation.Your_account_has_been_created}.<br></br>
                    {
                      translation.Please_click_below_to_proceed_to_your_dashboard
                    }
                    .
                  </p>

                  <p />
                  <div className="w_350">
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        {/* <button className="btn_fill w_100 br_10 f_600 mt_40">
                                                    Proceed to my dashboard
                                                </button> */}

                        <Button
                          // onClick={handleContinue}
                          onClick={() => handeleConfirm()}
                          // value={translation.Continue}
                          value={translation.Proceed_to_my_dashboard}
                          buttonStyle="btn_fill w_100 br_10 f_600 mt_40"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-center mt-3">
                    {/* If you would like to add another profile for a family <br></br> member please <span className="c_pointer f_600 text-decoration-underline" style={{color: '#3f2783'}} >click here</span> */}
                    {
                      translation.If_you_would_like_to_add_another_profile_for_a_family
                    }{" "}
                    <br></br>
                    {translation.member_please}{" "}
                    <Link
                      to="/patient-registration"
                      className="c_pointer f_600 text-decoration-underline"
                      style={{ color: "#3f2783" }}
                    >
                      {translation.click_here}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
