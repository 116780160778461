import React from "react"

export const DocType=(type , fileName,translation)=>{
    if(type==="mp4" || type === "mov" || type === "hevc"){
        return "Video"?translation.video:translation.video
    }else if(type==="png" || type==="jpg" || type==="jpeg" || type==="heif" || type==="heic"){
        return "Image"
    }else{
        return fileName==="radiology_form"?translation.radiology_form:fileName==="Document"?translation.doc_document:fileName==="pathology_form"?translation.pathology_form: fileName==="presciption"?translation.presciption_form: fileName === "insurance"?translation.insurance_form:fileName === "letter"? translation.letter_form:fileName==="refferal_form"?translation.refferal_form:fileName==="medical_form"?translation.medical_form :translation.doc_document
    }
}

export const ScoreColor = (value, type) => {

  if (type) {
    if (value >= 1 && value <= 10) {
      return ["#42E0CE", "#48DEFF"];
    } else if (value >= 11 && value <= 20) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 21 && value <= 30) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 31 && value <= 40) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 41 && value <= 50) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 51 && value <= 60) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 61 && value <= 70) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 71 && value <= 80) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 81 && value <= 90) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 91 && value <= 100) {
      return ["#FF0301", "#FF4601"];
    } else {
      return ["#42E0CE", "#48DEFF"];
    }
  } else {
    if (value >= 1 && value <= 10) {
      return ["#FF0301", "#FF4601"];
    } else if (value >= 11 && value <= 20) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 21 && value <= 30) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 31 && value <= 40) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 41 && value <= 50) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 51 && value <= 60) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 61 && value <= 70) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 71 && value <= 80) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 81 && value <= 90) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 91 && value <= 100) {
      return ["#42E0CE", "#48DEFF"];
    } else {
      return ["#FF0301", "#FF4601"];
    }
  }
};

export const vasColor = (value, type) => {
  if (type) {
    if (value >= 0 && value <= 1) {
      return ["#FF0301", "#FF4601"];
    } else if (value >= 1 && value <= 2) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 2 && value <= 3) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 3 && value <= 4) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 4 && value <= 5) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 5 && value <= 6) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 6 && value <= 7) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 7 && value <= 8) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 8 && value <= 9) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 9 && value <= 10) {
      return ["#42E0CE", "#48DEFF"];
    } else {
      return ["#FF0301", "#FF4601"];
    }
  } else {
    if (value >= 0 && value <= 1) {
      return ["#42E0CE", "#48DEFF"];
    } else if (value >= 1 && value <= 2) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 2 && value <= 3) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 3 && value <= 4) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 4 && value <= 5) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 5 && value <= 6) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 6 && value <= 7) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 7 && value <= 8) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 8 && value <= 9) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 9 && value <= 10) {
      return ["#FF0301", "#FF4601"];
    } else {
      return ["#42E0CE", "#48DEFF"];
    }
  }
};
export const FiveColor = (value, type) => {

  if (!type) {
    if (value >= 0 && value <= 1) {
      return ["#FF0301", "#FF4601"];
    } else if (value >= 1 && value <= 2) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 2 && value <= 3) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 3 && value <= 4) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 4 && value <= 5) {
      return ["#42E0CE", "#48DEFF"];
    } else {
      return ["#FF0301", "#FF4601"];
    }
  } else {
    if (value >= 0 && value <= 1) {
      return ["#42E0CE", "#48DEFF"];
    } else if (value >= 1 && value <= 2) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 2 && value <= 3) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 3 && value <= 4) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 4 && value <= 5) {
      return ["#FF0301", "#FF4601"];
    } else {
      return ["#42E0CE", "#48DEFF"];
    }
  }
};


export const CircleColor = (total, score, res, min_score) => {

  if (res) {
   
    return min_score + (total - score) ;
  } else {
 

    return score;
  }
};

export const WordCutter = (text, maxWords) => {
  const words = text.replace(/\n/g, " ").split(" ");
  const truncatedWords = words.slice(0, maxWords);
  const truncatedText = truncatedWords.join(" ");
  return (
    <span>
      {truncatedText}
      {words.length > 10 ? "..." : " "}
    </span>
  );
};

export const WordCutter2 = (text, maxWords, maxCharacters) => {
  const words = text.replace(/\n/g, " ").split(" ");
  
  // Check if there are no spaces between words
  if (words.length === 1) {
    const truncatedText = words[0].substring(0, maxCharacters);
    return (
      <span>
        {truncatedText.length < text.length ? truncatedText + "..." : truncatedText}
      </span>
    );
  }

  // Handle cases with spaces between words
  const truncatedWords = words.slice(0, maxWords);
  const truncatedText = truncatedWords.join(" ");
  return (
    <span>
      {truncatedText}
      {words.length > maxWords ? "..." : " "}
    </span>
  );
};


export const Circle5 = (total, score, res, min_score) => {
  

  if (res) {
    if (score === 5) {
      return 0;
    } else {
      return min_score + (total - score);
    }
  } else {
    return score;
  }
};
