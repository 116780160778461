import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "../custom/button/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nextWhiteIcon from "../../images/next-white.png";
import nextIcon from "../../images/next.png";
import InputField from "../custom/inputfield/InputField";
import { Validators } from "../../utilities/Validator";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { loginData } from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../custom/dropdown/Dropdown";
import useTranslation from "../customHooks/translations";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { dateChangeByTimezone } from "../../utilities/DateFormate";
import { spacialCharReplace } from "../../utilities/Validator";
import Message from "../custom/toster/Message";
import DeleteIcon from "../../images/delete-red.svg";
import heart_icon from "../../images/heart_out_blue.png";
import heart_icon1 from "../../images/heart.svg";
import heart_icon_fill from "../../images/heart1_blue.png";
// import editIcon from "../../images/edit-icon.svg";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import CreateOrganizationReq from "../joinorg/CreateOrganizationReq";
import enUS from "date-fns/locale/en-US"; // Import the desired locale
import fr from "date-fns/locale/fr";
import ar from "date-fns/locale/ar";
import de from "date-fns/locale/de";
import it from "date-fns/locale/it";
import es from "date-fns/locale/es";
import th from "date-fns/locale/th";

registerLocale("en-US", enUS); // Register the locale with date-fns
registerLocale("fr", fr);
registerLocale("ar", ar);
registerLocale("de", de);
registerLocale("it", it);
registerLocale("es", es);
registerLocale("th", th);

function MyProfile() {
  let lang = sessionStorage.getItem("lang_");
  const [selectedLang, setSelectedLang] = useState("en-US");
  const translation = useTranslation();
  let axiosInstance = useAxios();
  const inputRef = useRef();

  const dispatch = useDispatch();
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const [profile, setProfile] = useState({});
  // const [profileInfo, setProfileInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState(false);
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [postCode, setPostCode] = useState("");
  const [postCodeErr, setPostCodeErr] = useState(false);
  const [suburb, setSuburb] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [sex, setSex] = useState("");
  const [location, setLocation] = useState();
  const [addressError, setaddressError] = useState(false);
  const [country, setCountry] = useState([]);
  const [mobileErr, setmobileErr] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [modalOpenSmallDelete, setModalOpenSmallDelete] = useState(false);

  const closeModalSmallDelete = () => {
    setModalOpenSmallDelete(false);
  };
  const handleDelte = () => {
    axiosInstance.current
      .get(`extapp/forms/delete_account?ak_id=${profile.ak_id}`)
      .then((res) => {
        setMessage(translation.email_sent_successfully);
        setMessageType("success");
        // setEditProfileShow(false);
        setModalOpenSmallDelete(false);
        setTimeout(() => {
          setMessage(false);
        }, [3000]);
      });
  };
  // const [homePhoneErr, setHomePhoneErr] = useState(false);
  const navigate = useNavigate();
  const maxDate = new Date();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const postCodeRef = useRef();
  const suburbRef = useRef();
  const countryCodeRef = useRef();
  const mobileNumRef = useRef();
  const emailRef = useRef();
  const sexRef = useRef();
  useEffect(() => {
    if (login) {
      setProfile(login.patient);
      setEmail(login.patient.email);
      // setProfileInfo(login.patientInfo);
      setFirstName(login.patient.firstname);
      setLastName(login.patient.lastname);
      setAddress(login.patient.address);
      setPostCode(login.patient.postal_code);
      setSuburb(login.patientInfo.suburb);
      setCountryCode(login.patient.country_code);
      setMobileNum(login.patient.mobile);
      setHomePhone(login.patientInfo.home_phone);
      setSex(login.patient.gender);
      setStartDate(
        dateChangeByTimezone(login.patient.dob, login.patient.timezone)
      );
      setLocation(login.patient.address);
     
      setSelectedLang(login.patient.user_language);
    }
  }, [login]);

  const handleFirstName = (e, err) => {
    setFirstName(e);
    setFirstNameErr(err);
  };
  const handleLastName = (e, err) => {
    setLastName(e);
    setLastNameErr(err);
  };
  // const handleAddress = (e) => {
  //   setAddress(e);
  // };
  const handlePostCode = (e, err) => {
    setPostCode(e);
    setPostCodeErr(err);
  };
  const handleSuburb = (e) => {
    setSuburb(e);
  };
  const handleCountryCode = (e) => {
    setCountryCode(e);
  };

  const handleMobileNum = (e, err) => {
    setMobileNum(e);
    // setmobileErr(err);
  };

  const handleDeletePop = () => {
    setModalOpenSmallDelete(true);
    // setEditProfileShow(false);
  };

  const handleHomePhone = (e, err) => {
    const homePhonePattern = /^(\d{2})(\d{8})$/;
    const formattedPhone = e.replace(homePhonePattern, "($1)$2");
    setHomePhone(formattedPhone);
    // setHomePhoneErr(err);
  };
  const handleEmail = (e, err) => {
    setEmail(e);
    setEmailErr(err);
  };
  const handleSex = (e) => {
    setSex(e);
  };
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setAddress(place.formatted_address);
      setLocation(place.formatted_address);
      // console.log(place.formatted_address);
  

      place.address_components.map((ele) => {
        if (ele.types.includes("postal_code")) {
          // console.log(ele.long_name);
          setPostCode(ele.long_name);
        }
      });
      place.address_components.map((ele) => {
        if (ele.types.includes("locality")) {
          // console.log(ele.long_name);
          setSuburb(ele.long_name);
        }
      });
      // console.log(place.geometry.location.lng());

      // console.log(place, "placesss");
    }
  };
  const getData = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getDetailsOfPatient?patient_id=${profile.ak_id}&use_for=patient`
      );
      if (result.status === 200) {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          sessionStorage.setItem(
            "lang_",
            result.data.data[0].patient.user_language
          );
          sessionStorage.setItem(
            "isConsent",
            result.data.data[0].patientInfo.isConsent
          );
        }
      }
    } catch (err) {
      console.log(err.Messages);
    } finally {
      console.log("api response finally");
    }
  };
  const getCountryData = async () => {
    try {
      const result = await axiosInstance.current.get(`extapp/countrylist`);
      if (result.status === 200) {
        setCountry(result.data.data);
      }
    } catch (err) {
    } finally {
      console.log("api response finally");
    }
  };

  const continueRedirect = (e) => {
    e.preventDefault();
    if (!firstName) {
      firstNameRef.current.errUpdate();
    }
    if (!lastName) {
      lastNameRef.current.errUpdate();
    }
    if (!address) {
      setaddressError(true);
    }
    if (!location) {
      setaddressError(true);
    }
    if (!suburb) {
      suburbRef.current.errUpdate();
    }
    if (!postCode) {
      postCodeRef.current.errUpdate();
    }
    // if (!countryCode) {
    //   countryCodeRef.current.errUpdate();
    // }
    // if (!mobileNum) {
    //   mobileNumRef.current.errUpdate();
    // }
    if (!sex) {
      sexRef.current.errUpdate();
    }
    // if (!email) {
    //   emailRef.current.errUpdate();
    // }
    if (homeerr) {
      return;
    }

    if (
      !firstName ||
      !lastName ||
      !address ||
      !suburb ||
      !postCode ||
      // !countryCode ||
      // !mobileNum ||
      !sex ||
      // !email ||
      !location ||
      // emailErr ||
      mobileErr ||
      postCodeErr ||
      firstNameErr ||
      lastNameErr
    ) {
    } else {
      const json = JSON.stringify({
        firstname: firstName,
        lastname: lastName,
        dob: dateChangeByTimezone(startDate, login.patient.timezone), // dateChangeByTimezone(login.patient.dob, login.patient.timezone
        address: address,
        country_code: countryCode,
        postal_code: postCode,
        mobile: mobileNum,
        suburb: suburb,
        home_phone: homePhone,
        gender: sex,
        // email: email,
      });
      axiosInstance.current
        .post(
          `extapp/patients/create_Profile?ak_id=${profile.ak_id}&type=1`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          getData();
          navigate("/my-profile2");
        })
        .catch((err) => {
          setMessage(translation.error_max_profile_limit);
          setMessageType("error");
          setTimeout(() => {
            setMessage(false);
          }, 300000);
        });
    }
  };
  // console.log(address)
  // console.log(location)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    getCountryData();
  }, []);
  // useEffect(()=>{
  //   if (!address) {
  //     console.log("address")
  //     setaddressError(true);
  //     // addressRef.current.errUpdate();
  //   }
  //   if (!location) {
  //     setaddressError(true);
  //     console.log("location")

  //     // addressRef.current.errUpdate();
  //   }
  // },[address,location])
  let formattedDate;

  if (startDate) {
    let date = new Date(startDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let dayFormat = day < 10 ? `0${day}` : day;
    let monthFormat = month < 10 ? `0${month}` : month;
    let yearFormat = year < 10 ? `0${year}` : year;
    // Create the desired format (day/month/year)
    formattedDate = `${dayFormat}/${monthFormat}/${yearFormat}`;

    // setStartDate(formattedDate)
  }

  const [homeerr, setHomeerr] = useState(false);
  // let x = homePhone === undefined ? homePhone === undefined : homePhone.length === 0;
  let x =
    homePhone == undefined ? homePhone == undefined : homePhone.length === 0;
  const onlyNumbersAndParentheses = /^[\d()]{0,12}$/;
  useEffect(() => {
    if (onlyNumbersAndParentheses.test(homePhone) || x === true) {
      setHomeerr(false);
    } else {
      setHomeerr(true);
    }
  }, [homePhone]);

  const handleLocation = (e) => {
    setaddressError(false);
    setAddress(e);
    setLocation(e);
  };
  let akLang = sessionStorage.getItem("lang_");
  let akToken = localStorage.getItem("AkTOKENI");
  const [medical_status, setmedical_status] = useState("0");
  const save = async () => {
    await axiosInstance.current
      .get(
        `extapp/doctors/getMedicalSummaryList?ak_id=${akToken}&doctor_id=&is_patient=${1}`
      )
      .then((res) => {
        setmedical_status(res.data.data.status);
      })
      .catch((err) => {
        if (err.response !== undefined ? err.response.status === 401 : null) {
          navigate("/un-authorized-access");
        }
      });
  };

  useEffect(() => {
    save();
  }, []);

  const handleRedirect = () => {
    window.open(
      process.env.REACT_APP_FORM_URL +
        "medical-treatment/" +
        window.btoa(akToken) +
        "?doctor=",
      "_blank"
    );
    // navigate("/medical-history")
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="myPprofile_screen mb_100">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6 d-flex">
              <h4 className="ph_title">{translation.myProfile}</h4>
              {medical_status === "0" ? (
                <>
                  <img
                    className="heart_ico"
                    src={heart_icon1}
                    alt="Medical summary"
                    onClick={() => navigate("/medical-history")}
                    style={{ cursor: "pointer" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={translation.medical_summary}
                  />
                  <span
                    onClick={() => navigate("/medical-history")}
                    className="c_pointer text-danger mt-1 ms-2"
                  >
                    {translation.medical_summary}
                  </span>

                  {/* <span
                    onClick={() => navigate("/medical-history")}
                    className="c_pointer text-danger mt-1 ms-2"
                  >
                    {translation.medical_summary}
                  </span> */}
                </>
              ) : (
                <img
                  className="heart_ico"
                  src={heart_icon_fill}
                  alt="Medical summary"
                  onClick={() => handleRedirect()}
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={translation.medical_summary_msg}
                />
              )}
              {/* <img
                            src={editIcon}
                            onClick={() =>navigate('/medical-history')}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={translation.Edit}
                            className={`c_pointer ms-2 heart_ico`}
                          ></img> */}
            </div>

            {/* <CreateOrganizationReq /> */}
          </div>

          <div className="panel">
            {message && <Message message={message} type={messageType} />}

            {/* <form onSubmit={continueRedirect}> */}
            <div className="panel-body p_25">
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.First_Name}
                    value={firstName}
                    name="firstName"
                    type="text"
                    placeholder={translation.First_Name}
                    onChange={handleFirstName}
                    ref={firstNameRef}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                      {
                        check: Validators.spacialName,
                        message: translation.No_special_characters_are_allowed,
                      },
                      // {
                      //   check: Validators.textNum,
                      //   message: translation.numbers_are_not_allowed,
                      // },
                      {
                        check: Validators.maxfifty,
                        message: translation.maximum_50_character_are_allowed,
                      },
                    ]}
                    styleClass="br_10 "
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Last_Name}
                    value={lastName}
                    type="text"
                    placeholder={translation.Last_Name}
                    onChange={handleLastName}
                    styleClass="br_10 "
                    ref={lastNameRef}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                      {
                        check: Validators.spacialName,
                        message: translation.No_special_characters_are_allowed,
                      },
                      // {
                      //   check: Validators.textNum,
                      //   message: translation.numbers_are_not_allowed,
                      // },
                      {
                        check: Validators.maxfifty,
                        message: translation.maximum_50_character_are_allowed,
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <Form.Group className="mb-3" controlId="fromDate">
                    <Form.Label>
                      {translation.DOB}{" "}
                      <i style={{ "font-style": "italic" }}>
                        ({translation.DD_MM_YYYY})
                      </i>
                    </Form.Label>
                    <InputField
                      // label={translation.First_Name}
                      value={formattedDate}
                      name="firstName"
                      type="text"
                      placeholder={translation.First_Name}
                      onChange={(date) => setStartDate(date)}
                      disabled={true}
                      styleClass="br_10 "
                    />
                    {/* <DatePicker
                      dateFormat="dd/MM/yyyy"
                      maxDate={maxDate}
                      className={
                        akLang === "ara"
                          ? "form-controlleft mb_0 br_10 dob_disable"
                          : "form-controlright mb_0 br_10 dob_disable "
                      }
                      selected={new Date(startDate)}
                      onChange={(date) => setStartDate(date)}
                      showMonthDropdown
                      showYearDropdown
                      disabled
                      dropdownMode="select"
                      locale={
                        selectedLang === "en"
                          ? "en-US"
                          : selectedLang === "ara"
                          ? "ar"
                          : selectedLang === "ger"
                          ? "de"
                          : selectedLang === "ita"
                          ? "it"
                          : selectedLang === "spa"
                          ? "es"
                          : selectedLang === "tha"
                          ? "th"
                          : selectedLang === "fr"
                          ? "fr"
                          : "en-US"
                      } 
                      validators={[
                        {
                          check: Validators.required,
                          message: translation.This_field_is_required,
                        },

                        {
                          check: Validators.number,
                          message:
                            translation.No_special_characters_are_allowed,
                        },
                      ]}
                    /> */}
                  </Form.Group>
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <Dropdown
                    data={[
                      { value: 1, label: translation.Male },
                      { value: 2, label: translation.Female },
                      { value: 3, label: translation.want_to_disclose },
                    ]}
                    label={translation.Sex}
                    styleClass=" br_10"
                    value={sex}
                    ref={sexRef}
                    placeholder={translation.Select}
                    onChange={handleSex}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-10 col-md-10 mb_20">
                  <label>{translation.Address}</label>
                  <LoadScript
                    googleMapsApiKey={"AIzaSyAb4s2rhkg_dDms-SKZx16Ka5zDcU6aEIs"}
                    libraries={["places"]}
                  >
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef.current = ref)}
                      // searchBoxRef={searchBoxRef}
                      onPlacesChanged={handlePlaceChanged}
                      // placeholder="Search for a place..."
                    >
                      <input
                        type="text"
                        // styleClass={lang === "ara" ? "arab_text" : "br_10 "}
                        className={lang === "ara"? "arab_text form-control": "form-control  br_10"}
                        placeholder={translation.enter_address}
                        value={location}
                        // onChange={handleAddress}
                        onChange={(e) => {
                          handleLocation(e.target.value);
                        }}
                      />
                    </StandaloneSearchBox>
                  </LoadScript>
                  {addressError && (
                    <p className="text-danger">
                      {translation.This_field_is_required}
                    </p>
                  )}

                  {/* <InputField
                      label="Address*"
                      value={address}
                      type="text"
                      ref={addressRef}
                      placeholder="1234 john st"
                      onChange={handleAddress}
                      validators={[
                        {
                          check: Validators.required,
                          message: translation.This_field_is_required,
                        },
                      ]}
                      styleClass=" br_10"
                    /> */}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Postcode}
                    value={postCode}
                    ref={postCodeRef}
                    type="text"
                    placeholder="_ _ _ _ _ _ _ _ _ _"
                    onChange={handlePostCode}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                      {
                        check: Validators.spacial,
                        message: translation.invalid_character,
                      },
                      {
                        check: Validators.postcode,
                        message: translation.fields_only_contain_10_character,
                      },
                    ]}
                    styleClass="br_10 "
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Suburb}
                    value={suburb}
                    ref={suburbRef}
                    type="text"
                    placeholder={translation.Suburb}
                    onChange={handleSuburb}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                    styleClass="br_10 "
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  {/* <InputField
                      label="Country Code*"
                      value={countryCode}
                      ref={countryCodeRef}
                      type="text"
                      placeholder="_"
                      onChange={handleCountryCode}
                      validators={[
                        {
                          check: Validators.required,
                          message: translation.This_field_is_required,
                        },
                      ]}
                      formGroupClass="w-108 d-inline-block mr_20"
                      styleClass="br_10"
                    /> */}
                  <div className="row">
                    <div className="col-5">
                      <Dropdown
                        data={country}
                        label={translation.Country_Code}
                        styleClass=" br_10"
                        value={countryCode}
                        ref={countryCodeRef}
                        placeholder={translation.Select}
                        onChange={handleCountryCode}
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: translation.This_field_is_required,
                        //   },
                        // ]}
                      />
                    </div>
                    <div className="col-7">
                      <InputField
                        label={translation.Mobile_Phone}
                        value={mobileNum}
                        ref={mobileNumRef}
                        type="text"
                        placeholder="_ _ _ _ _ _ _ _ _ "
                        onChange={handleMobileNum}
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: translation.This_field_is_required,
                        //   },
                        //   {
                        //     check: Validators.maxmobile,
                        //     message: translation.maximum_10_Numbers_are_allowed,
                        //   },
                          // {
                          //   check: Validators.minmobile,
                          //   message: "Maximum 10 Numbers are allowed",
                          // },
                        //   {
                        //     check: Validators.number,
                        //     message: translation.Only_I,
                        //   },
                        // ]}
                        formGroupClass="d-inline-block"
                        styleClass="br_10"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Home_Phone}
                    value={homePhone}
                    // ref={homePhoneRef}
                    type="text"
                    placeholder="(_ _)_ _ _ _ _ _ _ _"
                    onChange={handleHomePhone}
                    formGroupClass=""
                    styleClass="br_10"
                  />
                  {homeerr && (
                    <span className="text-danger">
                      {translation.invalid_home_phone_number}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10 col-md-10 mb_20">
                  <InputField
                    label={translation.Email}
                    value={email}
                    ref={emailRef}
                    type="text"
                    placeholder={`${translation.your_email}`}
                    onChange={handleEmail}
                    styleClass={lang === "ara" ? "arab_text" : "br_10 "}
                    disabled
                    // validators={[
                    //   {
                    //     check: Validators.required,
                    //     message: translation.This_field_is_required,
                    //   },
                    //   {
                    //     check: Validators.email,
                    //     message:
                    //       translation.patient_elbow_function_q2_a5 +
                    //       " " +
                    //       translation.Email,
                    //   },
                    // ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10 col-md-10 mb_20">
                  <Button
                    value={translation.delete_account}
                    buttonStyle="btn_danger btn btn-danger w-auto mr_20"
                    onClick={() => handleDeletePop()}
                  />
                </div>
              </div>
            </div>
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6"></div>
              <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      )}
      <Modal
        show={modalOpenSmallDelete}
        onHide={closeModalSmallDelete}
        dialogClassName="modal-dialog modal-dialog-centered "
        className={`delete_modal_main`}
      >
        <Modal.Body className="">
          <div className="text-center">
            <img src={DeleteIcon} className="delete_pp_icon" alt="icon" />
            <h4 className="center_title">
              {translation.are_you_sure_delete_account}
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            onClick={closeModalSmallDelete}
            value={translation.cancel}
            buttonStyle="btn_outline mr_20"
            width="154px"
          />
          <Button
            value={translation.delete}
            buttonStyle="btn_danger btn btn-danger mr_20"
            width="154px"
            onClick={() => handleDelte()}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MyProfile;
