import React, {useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import MessageTable from "./MessageTable";
import useTranslation from "../customHooks/translations";
import { useSearchParams } from "react-router-dom";
function Messages() {
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [msgData, setmsgData] = useState([]);
  const [profile, setProfile] = useState("");
  const login = useSelector((state) => state.loginPatient.arr[0]);
  let akToken = localStorage.getItem("AkTOKENI");
  const [offset, setOffset] = useState(10);
  const [limit, setLimit] = useState(10);
  const [timezoneD, setTimeZone] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  let resourceId = searchParams.get("id");
  const getMsgData = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getMessagesForPatient/${akToken}?limit=${limit}&offset=${0}`
      );
      if (result) {
        setmsgData(result.data.data);
      }
    } catch (err) {
      console.log(err.message || "Unexpected Error!");
    } finally {
      console.log("api response finally");
    }
  };
  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/patients/getMessagesForPatient/${akToken}?limit=${limit}&offset=${offset}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setmsgData(msgData.concat(resdata));
    }, 500);
  };
  const fetchMoreData = () => {
    addMoreData();
  };

  useEffect(() => {
    getMsgData();
    if (login) {
      setProfile(login.patient);
      setTimeZone(login.patient.timezone);
      setTimeFormat(login.patient.timeformat)
    }
  }, [login]);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="my_visit_screen">
            <div className="row page_header">
              <div className="col-sm-6 col-md-6">
                <h4 className="ph_title">{translation.messages}</h4>

              </div>
            </div>
          </div>
          {msgData.length !== 0 ? (
            <>
              <div className="my_visit_screen mb_100">
                <MessageTable data={msgData} updatePagination={fetchMoreData} timezoneD={timezoneD}  timeFormat={timeFormat} resourceId={resourceId}/>
              </div>
            </>
          ) : (
           
            <div className="panel">
              <div className="panel-body p_25">
                <div className="no_data">{translation.No_Messages}</div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Messages;