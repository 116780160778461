import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserDateFormat } from "../../utilities/DateFormate";
import viewIcon from "../../images/icon-view.svg";
import VideoPlayer from "../custom/video-player/VideoPlayer";
import Modal from "react-bootstrap/Modal";
import useTranslation from "../customHooks/translations";
import { Hours_Minutes_Format } from "../../utilities/DateFormate";
import { s3UrlLinkFile } from "../../utilities/S3Url";
import { useSelector } from "react-redux";

// let akToken = localStorage.getItem("AkTOKENI");
function MessageTable({
  data,
  updatePagination,
  DataIdget,
  timezoneD,
  timeFormat,
  resourceId
}) {
  const translation = useTranslation();
  const [show, setShow] = useState(false);
  const [videoLink, setvideoLink] = useState("");
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const [profile, setProfile] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [fileName, setFileName] = useState("");

  const columns = React.useMemo(
    () => [
      {
        Header: translation.Organization_ID,
        accessor: "msg_type",
      },
      {
        Header: translation.Organization_Name,
        accessor: "message",
      },
    ],
    []
  );

  const { rows } = useTable({
    columns,
    data,
  });

  const WordCutter = (text, maxWords) => {
    const words = text.split(" ");
    const truncatedWords = words.slice(0, maxWords);
    const truncatedText = truncatedWords.join(" ");
    return (
      <span>
        {truncatedText}
        {words.length > 10 ? "..." : " "}
      </span>
    );
  };
  const handleView = (e, msg, link, file_name) => {
    
    setvideoLink(link);
    setMessage(msg);
    setType(e);
    setShow(true);
    setFileName(file_name)
  };
  const handleCloseShow = () => {
    setShow(false);
  };
  useEffect(() => {
    if (login) {
      setProfile(login.patient);
    }
  }, [login]);
  return (
    <>
      <div
        className="tableContainer bg-white "
        id="rrpScrollableDivs"
        style={{ height: "500px" }}
      >
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rrpScrollableDivs"
          loader={""}
        >
          <table role="table" className="custom-tbl mt-3" style={{ width: "95%" }}>
            <thead>
              <tr role="row">
                <th colSpan={1} role="columnheader" className="col-orgid">
                  {translation.Doctor_Name}
                </th>
                <th colSpan={1} role="columnheader" className="col-orgname">
                  {translation.Type}
                </th>
                <th colSpan={1} role="columnheader" className="col-status">
                  {translation.Date_Time}
                </th>
                <th colSpan={1} role="columnheader" className="col-status"   >
                  {translation.Message}
                </th>
                <th colSpan={1} role="columnheader" className="col-status" >
                  {translation.Action}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr role="row" style={{
                  backgroundColor:
                    row.resource_id == resourceId ? "#4027831e" : "inherit", 
                }}>
                  <td role="cell" className="col-orgid">
                    {row.doc_name}
                  </td>
                  <td role="cell" className="col-orgname" >
                    {row.msg_type === "text" ? translation.text : row.msg_type === "video" ? translation.video : ""}
                  </td>
                  <td className="col">
                    {/* {UserDateFormat(row.created_at , profile.dateFormat)}{" "} */}
                    {row.created_at}
                    {' '}
                    {Hours_Minutes_Format(
                      row.time,
                      timeFormat === "1" ? true : false,
                      timezoneD
                    )}
                  </td>
                  <td role="cell" className="col-orgname tooltip-container" data-bs-toggle="tooltip"
                    title={row.message}>
                    {row.message.length > 10 ? `${row.message.substring(0, 10)}...` : row.message}
                  </td>
                  <td role="cell" className="col-orgname ">
                    <img
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={translation.View}
                      className={`action_icons c_pointer`}
                      src={viewIcon}
                      alt="Icon"
                      onClick={() =>
                        handleView(
                          row.msg_type,
                          row.message,
                          row.link,
                          row.file_name
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      <Modal
        show={show}
        onHide={handleCloseShow}
        dialogClassName="modal-lg patient_notes_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{translation.View}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          {/* {videoLink} */}
          <div className="msg-box">
            <span className=""> {message.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}</span>
          </div>
          {type === "video" && <VideoPlayer url={s3UrlLinkFile(videoLink, fileName)} />}
        </Modal.Body>
        {/* <Modal.Footer style={{ justifyContent: "flex-start" }}>
                <Button
                  onClick={handleContinue}
                  value="Continue"
                  buttonStyle="btn_fill mr_20 "
                />
              </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default MessageTable;
