export default{
  "patient_shoulder_pain":"Dolore",
  "patient_shoulder_pain_q1":"Avverte dolore alla spalla?",
  "patient_shoulder_pain_q1_a1":"No ",
  "patient_shoulder_pain_q1_a2":"Si",
  "patient_shoulder_pain_q2":"Su una scala da 0 a 15, quale è il livello più alto di dolore sperimentato nella spalla durante le attività quotidiane nelle ultime 24 ore? (0 significa nessun dolore, 15 significa dolore insopportabile)",
  "patient_shoulder_pain_q2_a1":"Dolore insopportabile\n",
  "patient_shoulder_pain_q2_a2":"Nessun dolore",
  "patient_shoulder_pain_q3":"Il suo sonno è disturbato dalla sua spalla?",
  "patient_shoulder_pain_q3_a1":"Sonno indisturbato",
  "patient_shoulder_pain_q3_a2":"Disturbo occasionale",
  "patient_shoulder_pain_q3_a3":"Disturbo ogni notte",
  "patient_shoulder_pain_q4":"Quanto è intenso il suo dolore oggi?",
  "patient_shoulder_pain_q5":"È un fumatore attualmente?",
  "patient_shoulder_pain_q5_a1":"No ",
  "patient_shoulder_pain_q5_a2":"Si",
  "patient_shoulder_pain_q5_a3":"Ex-Fumatore",
  "patient_shoulder_pain_q5_a2_q1":"Quante sigarette fumi al giorno?",
  "patient_shoulder_pain_q5_a3_q1":"Da quanto tempo ha smesso di fumare (anni)?",
  "patient_shoulder_pain_q5_a3_q2":"Per quanti anni ha fumato?",
  "patient_shoulder_pain_q6":"Assume farmaci per il dolore (es. paracetamolo, ecc.)?",
  "patient_shoulder_pain_q6_a1":"No",
  "patient_shoulder_pain_q6_a2":"Sì",
  "patient_shoulder_pain_q6_a2_q1":"Assume farmaci antidolorifici forti (es. codeina, tramadolo, morfina)?",
  "patient_shoulder_pain_q6_a2_q1_a1":"No ",
  "patient_shoulder_pain_q6_a2_q1_a2":"Si",
  "patient_shoulder_pain_q6_a2_q2":"Quanti compresse assume ogni giorno in media?",
  "patient_shoulder_pain_q7":"Quale delle seguenti affermazioni descrive meglio il suo dolore?",
  "patient_shoulder_pain_q7_a1":"Nessun dolore",
  "patient_shoulder_pain_q7_a2":"Occasionale e lieve",
  "patient_shoulder_pain_q7_a3":"Presente durante attività intense o particolari, uso occasionale di aspirina",
  "patient_shoulder_pain_q7_a4":"Presente durante attività leggere, poco o nessun dolore a riposo, uso frequente di aspirina",
  "patient_shoulder_pain_q7_a5":"Sempre presente, sopportabile, richiede uso occasionale di farmaci forti",
  "patient_shoulder_pain_q7_a6":"Sempre presente, insopportabile, richiede uso frequente di farmaci forti",
  "patient_shoulder_pain_q8":"Avverte formicolio o pizzicore lungo il braccio?",
  "patient_shoulder_pain_q8_a1":"No ",
  "patient_shoulder_pain_q8_a2":"Si",
  "patient_shoulder_pain_q9":"Indichi dove si trova il dolore",
  "patient_shoulder_pain_q9_a1":"Fronte",
  "patient_shoulder_pain_q9_a2":"Retro",
  "patient_shoulder_function":"Funzionalità",
  "patient_shoulder_function_q1":"Quale delle seguenti affermazioni descrive meglio la funzionalità della spalla interessata?",
  "patient_shoulder_function_q1_a1":"Normale",
  "patient_shoulder_function_q1_a2":"Solo una lieve limitazione, capace di lavorare sopra il livello delle spalle",
  "patient_shoulder_function_q1_a3":"La maggior parte delle faccende domestiche, fare la spesa, guidare, pettinarsi, vestirsi, svestirsi",
  "patient_shoulder_function_q1_a4":"Capace di svolgere faccende domestiche leggere e la maggior parte delle attività quotidiane",
  "patient_shoulder_function_q1_a5":"Possibili solo attività leggere",
  "patient_shoulder_function_q1_a6":"Incapace di usare l'arto",
  "patient_shoulder_function_instruction":"Seleziona il numero che indica la sua capacità di svolgere le seguenti attività:",
  "patient_shoulder_function_instruction_0":"Incapace",
  "patient_shoulder_function_instruction_1":"Molto difficile",
  "patient_shoulder_function_instruction_2":"Un po difficile",
  "patient_shoulder_function_instruction_3":"Non difficile",
  "patient_shoulder_function_note":"Nota: La preghiamo di rispondere alle seguenti domande riguardanti la funzionalità della sua spalla.",
  "patient_shoulder_function_q2":"Indossare una giacca o una camicia",
  "patient_shoulder_function_q2_a0":"Incapace",
  "patient_shoulder_function_q2_a1":"Molto difficile",
  "patient_shoulder_function_q2_a2":"Un po difficile",
  "patient_shoulder_function_q2_a3":"Non difficile",
  "patient_shoulder_function_q3":"Dormire sul lato doloroso o interessato",
  "patient_shoulder_function_q3_a0":"Incapace",
  "patient_shoulder_function_q3_a1":"Molto difficile",
  "patient_shoulder_function_q3_a2":"Un po difficile",
  "patient_shoulder_function_q3_a3":"Non difficile",
  "patient_shoulder_function_q4":"Lavarsi la schiena/chiudi il reggiseno",
  "patient_shoulder_function_q4_a0":"Incapace",
  "patient_shoulder_function_q4_a1":"Molto difficile",
  "patient_shoulder_function_q4_a2":"Un po difficile",
  "patient_shoulder_function_q4_a3":"Non difficile",
  "patient_shoulder_function_q5":"Gestire l'igiene personale",
  "patient_shoulder_function_q5_a0":"Incapace",
  "patient_shoulder_function_q5_a1":"Molto difficile",
  "patient_shoulder_function_q5_a2":"Un po difficile",
  "patient_shoulder_function_q5_a3":"Non difficile",
  "patient_shoulder_function_q6":"Pettinarsi",
  "patient_shoulder_function_q6_a0":"Incapace",
  "patient_shoulder_function_q6_a1":"Molto difficile",
  "patient_shoulder_function_q6_a2":"Un po difficile",
  "patient_shoulder_function_q6_a3":"Non difficile",
  "patient_shoulder_function_q7":"Rasarsi o Truccarsi",
  "patient_shoulder_function_q7_a0":"Incapace",
  "patient_shoulder_function_q7_a1":"Molto difficile",
  "patient_shoulder_function_q7_a2":"Un po difficile",
  "patient_shoulder_function_q7_a3":"Non difficile",
  "patient_shoulder_function_q8":"Lavarsi i denti",
  "patient_shoulder_function_q8_a0":"Incapace",
  "patient_shoulder_function_q8_a1":"Molto difficile",
  "patient_shoulder_function_q8_a2":"Un po difficile",
  "patient_shoulder_function_q8_a3":"Non difficile",
  "patient_shoulder_function_q9":"Riempire un bicchiere con una bottiglia piena",
  "patient_shoulder_function_q9_a0":"Incapace",
  "patient_shoulder_function_q9_a1":"Molto difficile",
  "patient_shoulder_function_q9_a2":"Un po difficile",
  "patient_shoulder_function_q9_a3":"Non difficile",
  "patient_shoulder_function_q10":"Bere (portare un bicchiere pieno alla bocca)",
  "patient_shoulder_function_q10_a0":"Incapace",
  "patient_shoulder_function_q10_a1":"Molto difficile",
  "patient_shoulder_function_q10_a2":"Un po difficile",
  "patient_shoulder_function_q10_a3":"Non difficile",
  "patient_shoulder_function_q11":"Mangiare la zuppa (portare un cucchiaio pieno alla bocca)",
  "patient_shoulder_function_q11_a0":"Incapace",
  "patient_shoulder_function_q11_a1":"Molto difficile",
  "patient_shoulder_function_q11_a2":"Un po difficile",
  "patient_shoulder_function_q11_a3":"Non difficile",
  "patient_shoulder_function_q12":"Stringere la mano o aprire una porta",
  "patient_shoulder_function_q12_a0":"Incapace",
  "patient_shoulder_function_q12_a1":"Molto difficile",
  "patient_shoulder_function_q12_a2":"Un po difficile",
  "patient_shoulder_function_q12_a3":"Non difficile",
  "patient_shoulder_function_q13":"Usare un telefono (all'altezza dell'orecchio)",
  "patient_shoulder_function_q13_a0":"Incapace",
  "patient_shoulder_function_q13_a1":"Molto difficile",
  "patient_shoulder_function_q13_a2":"Un po difficile",
  "patient_shoulder_function_q13_a3":"Non difficile",
  "patient_shoulder_function_q14":"Scrivere una lettera, firmare su carta, usare una tastiera",
  "patient_shoulder_function_q14_a0":"Incapace",
  "patient_shoulder_function_q14_a1":"Molto difficile",
  "patient_shoulder_function_q14_a2":"Un po difficile",
  "patient_shoulder_function_q14_a3":"Non difficile",
  "patient_shoulder_function_q15":"Raggiungere uno scaffale alto (sopra la spalla)",
  "patient_shoulder_function_q15_a0":"Incapace",
  "patient_shoulder_function_q15_a1":"Molto difficile",
  "patient_shoulder_function_q15_a2":"Un po difficile",
  "patient_shoulder_function_q15_a3":"Non difficile",
  "patient_shoulder_function_q16":"Sollevare 0,5 kg (1 libbra) sopra la spalla",
  "patient_shoulder_function_q16_a0":"Incapace",
  "patient_shoulder_function_q16_a1":"Molto difficile",
  "patient_shoulder_function_q16_a2":"Un po difficile",
  "patient_shoulder_function_q16_a3":"Non difficile",
  "patient_shoulder_function_q17":"Sollevare 4,5 kg (10 libbre) sopra la spalla",
  "patient_shoulder_function_q17_a0":"Incapace",
  "patient_shoulder_function_q17_a1":"Molto difficile",
  "patient_shoulder_function_q17_a2":"Un po difficile",
  "patient_shoulder_function_q17_a3":"Non difficile",
  "patient_shoulder_function_q18":"Portare 10 kg (22 libbre) sulla spalla interessata",
  "patient_shoulder_function_q18_a0":"Incapace",
  "patient_shoulder_function_q18_a1":"Molto difficile",
  "patient_shoulder_function_q18_a2":"Un po difficile",
  "patient_shoulder_function_q18_a3":"Non difficile",
  "patient_shoulder_function_q19":"Lanciare una palla dal basso verso l'alto (almeno 3 metri)",
  "patient_shoulder_function_q19_a0":"Incapace",
  "patient_shoulder_function_q19_a1":"Molto difficile",
  "patient_shoulder_function_q19_a2":"Un po difficile",
  "patient_shoulder_function_q19_a3":"Non difficile",
  "patient_shoulder_function_q20":"Lanciare una palla alzando il braccio sopra la spalla (almeno 6 metri)",
  "patient_shoulder_function_q20_a0":"Incapace",
  "patient_shoulder_function_q20_a1":"Molto difficile",
  "patient_shoulder_function_q20_a2":"Un po difficile",
  "patient_shoulder_function_q20_a3":"Non difficile",
  "patient_shoulder_function_q21":"Svolgere al completo il lavoro abituale",
  "patient_shoulder_function_q21_a0":"Incapace",
  "patient_shoulder_function_q21_a1":"Molto difficile",
  "patient_shoulder_function_q21_a2":"Un po difficile",
  "patient_shoulder_function_q21_a3":"Non difficile",
  "patient_shoulder_function_q22":"Quanta della sua normale attività lavorativa le consente di svolgere la spalla?",
  "patient_shoulder_function_q22_a0":"Nessuna",
  "patient_shoulder_function_q22_a1":"Tutta",
  "patient_shoulder_function_q23":"Svolgere al completo gli hobby e/o sport abituali",
  "patient_shoulder_function_q23_a0":"Incapace",
  "patient_shoulder_function_q23_a1":"Molto difficile",
  "patient_shoulder_function_q23_a2":"Un po difficile",
  "patient_shoulder_function_q23_a3":"Non difficile",
  "patient_shoulder_function_q24":"Quanta della sua normale attività ricreativa le consente di svolgere la spalla?",
  "patient_shoulder_function_q24_a0":"Nessuna",
  "patient_shoulder_function_q24_a1":"Tutta",
  "patient_shoulder_function_q25":"Si prega di elencare le attività lavorative",
  "patient_shoulder_function_q26":"Si prega di elencare le attività di hobby e/o sport",
  "patient_shoulder_feeling_instability":"Sensazione di instabilità",
  "patient_shoulder_feeling_instability_q1":"Avverte instabilità nella spalla (come se potesse slogarsi)",
  "patient_shoulder_feeling_instability_q1_a1":"No ",
  "patient_shoulder_feeling_instability_q1_a2":"Si",
  "patient_shoulder_feeling_instability_q1_a2_q1":"Si prega di indicare quanto la sua spalla è instabile",
  "patient_shoulder_wosi":"Western Ontario Shoulder Instability Index",
  "patient_shoulder_wosi_instruction":"Istruzioni: Ti chiediamo di indicare in questa parte del questionario l'entità dei sintomi che ha sperimentato nella scorsa settimana relativamente alla sua spalla. Sposta semplicemente il cursore lungo la linea in modo che corrisponda con precisione ai suoi sintomi.",
  "patient_shoulder_wosi_note":"Nota: Più sposta il cursore verso destra, maggiormente indica che ha sperimentato quel sintomo. Più sposta il cursore verso sinistra, meno ha sperimentato quel sintomo. Se ha dubbi riguardo al significato di una particolare domanda, la preghiamo chiedere.",
  "patient_shoulder_wosi_sectionA":"Sezione A: Sintomi fisici",
  "patient_shoulder_wosi_q1":"Quanto dolore prova alla spalla durante le attività sopra la testa?",
  "patient_shoulder_wosi_q1_a1":"Nessun dolore",
  "patient_shoulder_wosi_q1_a2":"Dolore estremo",
  "patient_shoulder_wosi_q2":"Quanta sensazione di dolore costante o pulsazioni prova alla spalla?",
  "patient_shoulder_wosi_q2_a1":"Nessun dolore costante o pulsazioni",
  "patient_shoulder_wosi_q2_a2":"Estremo dolore costante o pulsazioni ",
  "patient_shoulder_wosi_q3":"Quanta debolezza o mancanza di forza prova alla spalla?",
  "patient_shoulder_wosi_q3_a1":"Nessuna debolezza",
  "patient_shoulder_wosi_q3_a2":"Debolezza estrema",
  "patient_shoulder_wosi_q4":"Quanta stanchezza o mancanza di resistenza prova alla spalla?",
  "patient_shoulder_wosi_q4_a1":"Nessuna stanchezza",
  "patient_shoulder_wosi_q4_a2":"Stanchezza estrema",
  "patient_shoulder_wosi_q5":"Quanti clic, scrocchi, o scatti avverte alla spalla?",
  "patient_shoulder_wosi_q5_a1":"Nessun clic",
  "patient_shoulder_wosi_q5_a2":"Clic molto intenso",
  "patient_shoulder_wosi_q6":"Quanta rigidità prova alla spalla?",
  "patient_shoulder_wosi_q6_a1":"Nessuna rigidità",
  "patient_shoulder_wosi_q6_a2":"Rigidità estrema",
  "patient_shoulder_wosi_q7":"Quanta fastidio sente nei muscoli del collo a causa della sua spalla?",
  "patient_shoulder_wosi_q7_a1":"Nessun fastidio",
  "patient_shoulder_wosi_q7_a2":"Fastidio estremo",
  "patient_shoulder_wosi_q8":"Quanta sensazione di instabilità o lassità sente alla spalla?",
  "patient_shoulder_wosi_q8_a1":"Nessuna instabilità",
  "patient_shoulder_wosi_q8_a2":"Instabilità estrema",
  "patient_shoulder_wosi_q9":"In che misura compensa la sua spalla con altri muscoli?",
  "patient_shoulder_wosi_q9_a1":"Per niente",
  "patient_shoulder_wosi_q9_a2":"In modo estremo",
  "patient_shoulder_wosi_q10":"Quanta perdita di mobilità ha nella spalla?",
  "patient_shoulder_wosi_q10_a1":"Nessuna perdita",
  "patient_shoulder_wosi_q10_a2":"Perdita estrema",
  "patient_shoulder_wosi_sectionB":"Sezione B: Sport / Tempo libero / Lavoro",
  "patient_shoulder_wosi_q11":"In che misura la sua spalla ha limitato la partecipazione ad attivtà sportive o ricreative?",
  "patient_shoulder_wosi_q11_a1":"Nessuna limitazione",
  "patient_shoulder_wosi_q11_a2":"Limitazione estrema",
  "patient_shoulder_wosi_q12":"In che misura la sua spalla ha influenzato la capacità di eseguire le abilità specifiche richieste per uno sport o per il lavoro?",
  "patient_shoulder_wosi_q12_note":"Se la sua spalla influenza sia lo sport che il lavoro, consideri l'area più colpita.",
  "patient_shoulder_wosi_q12_a1":"Nessun impatto",
  "patient_shoulder_wosi_q12_a2":"Impatto estremo",
  "patient_shoulder_wosi_q13":"In che misura sente il bisogno di proteggere il braccio durante le attività?",
  "patient_shoulder_wosi_q13_a1":"Per niente",
  "patient_shoulder_wosi_q13_a2":"Estremamente",
  "patient_shoulder_wosi_q14":"Quanta difficoltà prova nel sollevare oggetti pesanti al di sotto del livello della spalla?",
  "patient_shoulder_wosi_q14_a1":"Nessuna difficoltà",
  "patient_shoulder_wosi_q14_a2":"Difficoltà estrema",
  "patient_shoulder_wosi_sectionC":"Sezione C: Stile di vita",
  "patient_shoulder_wosi_q15":"Quanta paura ha di cadere sulla spalla?",
  "patient_shoulder_wosi_q15_a1":"Nessuna paura",
  "patient_shoulder_wosi_q15_a2":"Paura estrema",
  "patient_shoulder_wosi_q16":"Quanta difficoltà incontra nel mantenere il livello desiderato di fitness?",
  "patient_shoulder_wosi_q16_a1":"Nessuna difficoltà",
  "patient_shoulder_wosi_q16_a2":"Difficoltà estrema",
  "patient_shoulder_wosi_q17":"Quanta difficoltà incontra nel giocare \"in modo energico\" con la famiglia o gli amici?",
  "patient_shoulder_wosi_q17_a1":"Nessuna difficoltà",
  "patient_shoulder_wosi_q17_a2":"Difficoltà estrema",
  "patient_shoulder_wosi_q18":"Quanta difficoltà incontra nel dormire a causa della sua spalla?",
  "patient_shoulder_wosi_q18_a1":"Nessuna difficoltà",
  "patient_shoulder_wosi_q18_a2":"Difficoltà estrema",
  "patient_shoulder_wosi_sectionD":"Sezione D: Emozioni",
  "patient_shoulder_wosi_q19":"Quanto è consapevole della sua spalla?",
  "patient_shoulder_wosi_q19_a1":"Non ci penso",
  "patient_shoulder_wosi_q19_a2":"Estremamente consapevole",
  "patient_shoulder_wosi_q20":"Quanto è preoccupato che la sua spalla possa peggiorare?",
  "patient_shoulder_wosi_q20_a1":"Nessuna preoccupazione",
  "patient_shoulder_wosi_q20_a2":"Preoccupazione estrema",
  "patient_shoulder_wosi_q21":"Quanta frustrazione prova a causa della sua spalla?",
  "patient_shoulder_wosi_q21_a1":"Nessuna frustrazione",
  "patient_shoulder_wosi_q21_a2":"Frustrazione estrema",
  "patient_shoulder_ac_joint":"Articolazione Acromioclavicolare",
  "patient_shoulder_ac_joint_q1":"Ha instabilità nella sua articolazione acromioclavicolare?",
  "patient_shoulder_ac_joint_q1_note":"Nota: L'articolazione acromioclavicolare è l'articolazione nella parte superiore della spalla che collega la clavicola e l'estremità anteriore dell'omero.",
  "patient_shoulder_ac_joint_q1_a1":"No ",
  "patient_shoulder_ac_joint_q1_a2":"Si",
  "patient_shoulder_sacs":"Punteggio specifico per l'articolazione acromion-claveare (SACS)",
  "patient_shoulder_sacs_explanation":"Spiegazione: Per ogni domanda, le verrà chiesto di valutare il suo livello di dolore o difficoltà nell'eseguire diverse attività. La scala di valutazione è progettata in modo che 0 rifletta nessun dolore o difficoltà, mentre 10 riflette il livello più grave di dolore o difficoltà.",
  "patient_shoulder_sacs_note":"Nota: Una valutazione di 2 rappresenterebbe livelli di dolore minimi. Se ha dubbi riguardo al significato di una particolare domanda, la preghiamo di chiedere.",
  "patient_shoulder_sacs_sectionA":"Sezione A: Dolore",
  "patient_shoulder_sacs_a1":"Valuti il suo livello di dolore alla spalla quando è al massimo ",
  "patient_shoulder_sacs_a1_a1":"Nessun dolore",
  "patient_shoulder_sacs_a1_a2":"Dolore molto intenso",
  "patient_shoulder_sacs_a2":"Valuti il suo livello di dolore alla spalla quando è al minimo",
  "patient_shoulder_sacs_a2_a1":"Nessun dolore",
  "patient_shoulder_sacs_a2_a2":"Dolore molto intenso",
  "patient_shoulder_sacs_a3":"Valuti il livello di dolore quando preme, tocca o c'è pressione sulla parte superiore della sua spalla",
  "patient_shoulder_sacs_a3_note":"Esempio: tracolla del computer portatile o cinghia della borsa",
  "patient_shoulder_sacs_a3_a1":"Nessun dolore",
  "patient_shoulder_sacs_a3_a2":"Dolore molto intenso",
  "patient_shoulder_sacs_a4":"Valuti il livello di dolore, tensione o sensazione di trazione che prova nel collo o nella regione della scapola interessata",
  "patient_shoulder_sacs_a4_a1":"Nessun dolore",
  "patient_shoulder_sacs_a4_a2":"Dolore molto intenso",
  "patient_shoulder_sacs_sectionB":"Sezione B: Funzionalità",
  "patient_shoulder_sacs_b1":"Valuti la presenza di clic, scrocchi, o scatti anomali che prova nella sua spalla",
  "patient_shoulder_sacs_b1_a1":"Nessun clic",
  "patient_shoulder_sacs_b1_a2":"Clic molto intenso",
  "patient_shoulder_sacs_b2":"Indichi se il suo braccio ha bisogno di essere sostenuto durante il cammino",
  "patient_shoulder_sacs_b2_note":"Esempio: appoggiare il braccio in una tasca o utilizzare un tutore",
  "patient_shoulder_sacs_b2_a1":"Nessuna necessità di supporto",
  "patient_shoulder_sacs_b2_a2":"Estrema necessità di supporto",
  "patient_shoulder_sacs_b3":"Valuti il livello di difficoltà nel sostenere il peso o o nel piegarsi sul braccio",
  "patient_shoulder_sacs_b3_note":"Esempio: flessioni, ciclismo, yoga",
  "patient_shoulder_sacs_b3_a1":"Nessuna difficoltà",
  "patient_shoulder_sacs_b3_a2":"Molto difficile o impossibile da fare    ",
  "patient_shoulder_sacs_b4":"Valuti il suo livello di difficoltà nel trasportare oggetti pesanti per più di 5 minuti",
  "patient_shoulder_sacs_b4_note":"Esempio: sacchetti della spesa",
  "patient_shoulder_sacs_b4_a1":"Nessuna difficoltà",
  "patient_shoulder_sacs_b4_a2":"Molto difficile o impossibile da fare    ",
  "patient_shoulder_sacs_b5":"Valuti il livello di difficoltà nel raggiungere con il braccio interessato la parte opposta del suo corpo",
  "patient_shoulder_sacs_b5_note":"Esempio: raggiungere e toccare la spalla non interessata",
  "patient_shoulder_sacs_b5_a1":"Nessuna difficoltà",
  "patient_shoulder_sacs_b5_a2":"Molto difficile o impossibile da fare    ",
  "patient_shoulder_sacs_b6":"Valuti il livello di difficoltà nel sollevare oggetti pesanti o pesi sopra la testa",
  "patient_shoulder_sacs_b6_note":"Esempio: superiore a cinque chilogrammi.",
  "patient_shoulder_sacs_b6_a1":"Nessuna difficoltà",
  "patient_shoulder_sacs_b6_a2":"Molto difficile o impossibile da fare    ",
  "patient_shoulder_sacs_b7":"Valuti il livello di difficoltà nel tenere il braccio interessato davanti a lei per un lungo periodo",
  "patient_shoulder_sacs_b7_note":"Esempio: tenere il volante mentre guidi",
  "patient_shoulder_sacs_b7_a1":"Nessuna difficoltà",
  "patient_shoulder_sacs_b7_a2":"Molto difficile o impossibile da fare    ",
  "patient_shoulder_sacs_b8":"Valuti il livello di difficoltà che incontra nel svolgere la sua occupazione abituale",
  "patient_shoulder_sacs_b8_a1":"Nessuna difficoltà",
  "patient_shoulder_sacs_b8_a2":"Molto difficile o impossibile da fare    ",
  "patient_shoulder_sacs_b8_a3":"Non applicabile alla mia situazione",
  "patient_shoulder_sacs_b9":"Valuti quanto ha dovuto cambiare o modificare la sua occupazione abituale per compensare il problema alla spalla",
  "patient_shoulder_sacs_b9_a1":"Nessuna difficoltà",
  "patient_shoulder_sacs_b9_a2":"Molto difficile o impossibile da fare    ",
  "patient_shoulder_sacs_b9_a3":"Non applicabile alla mia situazione",
  "patient_shoulder_sacs_b10":"Valuti il livello di difficoltà che sperimenti nel svolgimento della sua attività sportiva principale",
  "patient_shoulder_sacs_b10_a1":"Nessuna difficoltà",
  "patient_shoulder_sacs_b10_a2":"Molto difficile o impossibile da fare    ",
  "patient_shoulder_sacs_b10_a3":"Non applicabile alla mia situazione",
  "patient_shoulder_sacs_b11":"Valuti quanto ha dovuto modificare la sua attività sportiva principale per compensare il problema alla spalla",
  "patient_shoulder_sacs_b11_a1":"Nessuna difficoltà",
  "patient_shoulder_sacs_b11_a2":"Molto difficile o impossibile da fare    ",
  "patient_shoulder_sacs_b11_a3":"Non applicabile alla mia situazione",
  "patient_shoulder_sacs_sectionC":"Sezione C: Qualità della vita e soddisfazione",
  "patient_shoulder_sacs_c1":"Valuti il livello di differenza nell'aspetto delle ossa sulla parte superiore della spalla rispetto a una spalla normale, includendo la presenza di un rigonfiamento",
  "patient_shoulder_sacs_c1_note":"Inclusa la presenza di un rigonfiamento",
  "patient_shoulder_sacs_c1_a1":"Nessuna differenza",
  "patient_shoulder_sacs_c1_a2":"Differenza estrema",
  "patient_shoulder_sacs_c2":"Valuti complessivamente il livello di ansia o stress causato dal problema alla spalla.",
  "patient_shoulder_sacs_c2_a1":"Nessuna ansia o nessun stress",
  "patient_shoulder_sacs_c2_a2":"Livello notevole di ansia o stress",
  "patient_shoulder_sacs_c3":"Con quale frequenza è consapevole del problema alla sua spalla?",
  "patient_shoulder_sacs_c3_a1":"Mai",
  "patient_shoulder_sacs_c3_a2":"Costantemente",
  "patient_shoulder_sacs_c4":"Ha modificato il suo stile di vita per evitare di danneggiare o aggravare il problema alla sua spalla?",
  "patient_shoulder_sacs_c4_a1":"Per niente",
  "patient_shoulder_sacs_c4_a2":"Totalmente",
  "patient_shoulder_sacs_c5":"Quanto è infelice o insoddisfatto del problema alla spalla?",
  "patient_shoulder_sacs_c5_a1":"Estremamente felice",
  "patient_shoulder_sacs_c5_a2":"Estremamente infelice",
  "patient_shoulder_sacs_c6":"Come valuterebbe complessivamente la sua spalla in percentuale rispetto alle sue aspettative di una spalla \"normale\", dove 0% indica pessimo e 100% indica molto buono?",
  "patient_shoulder_satisfaction":"Soddisfazione",
  "patient_shoulder_satisfaction_q1":"In riferimento alla sua spalla interessata, al momento è",
  "patient_shoulder_satisfaction_q1_a1":"Soddisfatto e/o migliorato",
  "patient_shoulder_satisfaction_q1_a2":"Insoddisfatto e/o peggiorato",
  "patient_shoulder_complete":"Fine del questionario",
  "patient_shoulder_complete_text1":"Grazie per aver dedicato del tempo per compilare questo modulo.",
  "patient_shoulder_complete_text2":"Per favore, clicchi su \"Invia\" per completare e visualizzare i suoi punteggi.",
  "patient_shoulder_complete_text3":"Le tue risposte saranno trattate in modo confidenziale e qualsiasi rapporto utilizzerà solo informazioni anonime.",
  "patient_shoulder_results":"Risultati",
  "patient_shoulder_results_text1":"Di seguito sono riportati i suoi punteggi per il dolore e la funzionalità della spalla, calcolati dal questionario.",
  "patient_shoulder_results_pain":"Dolore",
  "patient_shoulder_results_function":"Funzionalità",
  "patient_shoulder_results_instability":"Instabilità",
  "patient_shoulder_results_selfevaluation":"Punteggio dell'autovalutazione della spalla",
  "patient_shoulder_results_text2":" Il suo medico sarà con lei a breve.",
  "patient_hip_pain":"Dolore",
  "patient_hip_pain_q1":"IN GENERALE, come descriverebbe il dolore che solitamente prova dall'anca nell'ultimo mese?",
  "patient_hip_pain_q1_a1":"Nessun dolore",
  "patient_hip_pain_q1_a2":"Molto lieve",
  "patient_hip_pain_q1_a3":"Lieve",
  "patient_hip_pain_q1_a4":"Discreto",
  "patient_hip_pain_q1_a5":"Intenso",
  "patient_hip_pain_q2":"Quanto è intenso il dolore all'anca IN QUESTO MOMENTO? (0 significa nessun dolore e 10 significa il dolore più intenso che puoi immaginare)",
  "patient_hip_pain_q2_a1":"Nessun dolore",
  "patient_hip_pain_q2_a2":"Dolore più intenso possibile",
  "patient_hip_pain_q3":"Quale delle seguenti descrizioni corrisponde meglio al suo dolore?",
  "patient_hip_pain_q3_a1":"Nessun dolore",
  "patient_hip_pain_q3_a2":"Lieve, occasionale, nessun compromesso nelle atività quotidiane",
  "patient_hip_pain_q3_a3":"Moderato, nessun effetto sulle attività quotidiane, dolore dopo l'attività abituale, uso di aspirina",
  "patient_hip_pain_q3_a4":"Dolore discreto, tollerabile, comporta delle limitazioni, uso occasionale di codeina",
  "patient_hip_pain_q3_a5":"Dolore Intenso, limitazioni significative",
  "patient_hip_pain_q3_a6":"Totalmente disabile",
  "patient_hip_pain_q4":"Quanto SPESSO ha dolore all'anca?",
  "patient_hip_pain_q4_a1":"Mai",
  "patient_hip_pain_q4_a2":"Mensilmente",
  "patient_hip_pain_q4_a3":"Settimanalmente",
  "patient_hip_pain_q4_a4":"Quotidianamente",
  "patient_hip_pain_q4_a5":"Sempre",
  "patient_hip_pain_q5":"In che misura il dolore all'anca INTERFERISCE CON IL LAVORO ABITUALE (compreso lavori domestici)?",
  "patient_hip_pain_q5_a1":"Per niente",
  "patient_hip_pain_q5_a2":"Un po'",
  "patient_hip_pain_q5_a3":"Moderatamente",
  "patient_hip_pain_q5_a4":"Molto",
  "patient_hip_pain_q5_a5":"Totalmente",
  "patient_hip_pain_instruction":"Quale INTENSITÀ DI DOLORE ALL'ANCA ha sperimentato durante le seguenti attività",
  "patient_hip_pain_instruction_0":"Nessun dolore",
  "patient_hip_pain_instruction_1":"Lieve",
  "patient_hip_pain_instruction_2":"Moderato",
  "patient_hip_pain_instruction_3":"Intenso",
  "patient_hip_pain_instruction_4":"Dolore estremo",
  "patient_hip_pain_note":"Nota: La preghiamo di rispondere alle seguenti domande riguardanti le attività della sua anca.",
  "patient_hip_pain_q6":"Estendere completamente l'anca",
  "patient_hip_pain_q6_a1":"Nessun dolore",
  "patient_hip_pain_q6_a2":"Lieve",
  "patient_hip_pain_q6_a3":"Moderato",
  "patient_hip_pain_q6_a4":"Intenso",
  "patient_hip_pain_q6_a5":"Dolore estremo",
  "patient_hip_pain_q7":"Piegare completamente l'anca",
  "patient_hip_pain_q7_a1":"Nessun dolore",
  "patient_hip_pain_q7_a2":"Lieve",
  "patient_hip_pain_q7_a3":"Moderato",
  "patient_hip_pain_q7_a4":"Intenso",
  "patient_hip_pain_q7_a5":"Dolore estremo",
  "patient_hip_pain_q8":"Camminare su una superficie piana",
  "patient_hip_pain_q8_a1":"Nessun dolore",
  "patient_hip_pain_q8_a2":"Lieve",
  "patient_hip_pain_q8_a3":"Moderato",
  "patient_hip_pain_q8_a4":"Intenso",
  "patient_hip_pain_q8_a5":"Dolore estremo",
  "patient_hip_pain_q9":"Per quanto tempo puoi camminare prima che il dolore all'anca diventi intenso? (con o senza bastone)",
  "patient_hip_pain_q9_a1":"Nessun dolore, più di 30 minuti, tempo illimitato",
  "patient_hip_pain_q9_a2":"16-30 minuti, 6 isolati",
  "patient_hip_pain_q9_a3":"5-15 minuti, 2-3 isolati",
  "patient_hip_pain_q9_a4":"Solo intorno alla casa, esclusivamente al chiuso",
  "patient_hip_pain_q9_a5":"Non posso camminare affatto, il dolore è grave durante la camminata, solo a letto e sulla sedia",
  "patient_hip_pain_q10":"Camminare su una superficie dura (asfalto, cemento, ecc.) ",
  "patient_hip_pain_q10_a1":"Nessun dolore",
  "patient_hip_pain_q10_a2":"Lieve",
  "patient_hip_pain_q10_a3":"Moderato",
  "patient_hip_pain_q10_a4":"Intenso",
  "patient_hip_pain_q10_a5":"Dolore estremo",
  "patient_hip_pain_q11":"Camminare su una superficie irregolare",
  "patient_hip_pain_q11_a1":"Nessun dolore",
  "patient_hip_pain_q11_a2":"Lieve",
  "patient_hip_pain_q11_a3":"Moderato",
  "patient_hip_pain_q11_a4":"Intenso",
  "patient_hip_pain_q11_a5":"Dolore estremo",
  "patient_hip_pain_q12":"Salire o scendere le scale",
  "patient_hip_pain_q12_a1":"Nessun dolore",
  "patient_hip_pain_q12_a2":"Lieve",
  "patient_hip_pain_q12_a3":"Moderato",
  "patient_hip_pain_q12_a4":"Intenso",
  "patient_hip_pain_q12_a5":"Dolore estremo",
  "patient_hip_pain_q13":"Di notte a letto",
  "patient_hip_pain_q13_a1":"Nessun dolore",
  "patient_hip_pain_q13_a2":"Lieve",
  "patient_hip_pain_q13_a3":"Moderato",
  "patient_hip_pain_q13_a4":"Intenso",
  "patient_hip_pain_q13_a5":"Dolore estremo",
  "patient_hip_pain_q14":"Quanto spesso è stato disturbato dal dolore all'anca mentre era a letto durante la notte?",
  "patient_hip_pain_q14_a1":"Nessuna notte",
  "patient_hip_pain_q14_a2":"Solo 1 o 2 notti",
  "patient_hip_pain_q14_a3":"Alcune notti",
  "patient_hip_pain_q14_a4":"La maggior parte delle notti",
  "patient_hip_pain_q14_a5":"Ogni notte",
  "patient_hip_pain_q15":"Sedersi o sdraiarsi ",
  "patient_hip_pain_q15_a1":"Nessun dolore",
  "patient_hip_pain_q15_a2":"Lieve",
  "patient_hip_pain_q15_a3":"Moderato",
  "patient_hip_pain_q15_a4":"Intenso",
  "patient_hip_pain_q15_a5":"Dolore estremo",
  "patient_hip_pain_q16":"In posizione eretta",
  "patient_hip_pain_q16_a1":"Nessun dolore",
  "patient_hip_pain_q16_a2":"Lieve",
  "patient_hip_pain_q16_a3":"Moderato",
  "patient_hip_pain_q16_a4":"Intenso",
  "patient_hip_pain_q16_a5":"Dolore estremo",
  "patient_hip_pain_q17":"Alzarsi da una sedia ",
  "patient_hip_pain_q17_a1":"Nessun dolore",
  "patient_hip_pain_q17_a2":"Lieve",
  "patient_hip_pain_q17_a3":"Moderato",
  "patient_hip_pain_q17_a4":"Intenso",
  "patient_hip_pain_q17_a5":"Dolore estremo",
  "patient_hip_pain_q18":"Dopo un'attività ",
  "patient_hip_pain_q18_a1":"Nessun dolore",
  "patient_hip_pain_q18_a2":"Lieve",
  "patient_hip_pain_q18_a3":"Moderato",
  "patient_hip_pain_q18_a4":"Intenso",
  "patient_hip_pain_q18_a5":"Dolore estremo",
  "patient_hip_other_symptoms":"Altri sintomi ",
  "patient_hip_other_symptoms_instruction1":"Per favore, scelga la risposta che corrisponde meglio ai suoi sintomi all'anca.",
  "patient_hip_other_symptoms_instruction2":"Se non è sicuro su come rispondere a una domanda, la preghiamo di fornire la migliore risposta possibile.",
  "patient_hip_other_symptoms_instruction3":"Ha i seguenti sintomi:",
  "patient_hip_other_symptoms_instruction_0":"Nessuno",
  "patient_hip_other_symptoms_instruction_1":"Lieve",
  "patient_hip_other_symptoms_instruction_2":"Moderato",
  "patient_hip_other_symptoms_instruction_3":"Severo",
  "patient_hip_other_symptoms_instruction_4":"Estremo",
  "patient_hip_other_symptoms_q1":"Scrocchio, clic o qualsiasi altro tipo di rumore proveniente dall'anca",
  "patient_hip_other_symptoms_q1_a1":"Mai",
  "patient_hip_other_symptoms_q1_a2":"Raramente",
  "patient_hip_other_symptoms_q1_a3":"A volte",
  "patient_hip_other_symptoms_q1_a4":"Spesso",
  "patient_hip_other_symptoms_q1_a5":"Sempre",
  "patient_hip_other_symptoms_q2":"Sensazione di bloccaggio ",
  "patient_hip_other_symptoms_q2_a1":"Nessuna",
  "patient_hip_other_symptoms_q2_a2":"Lieve",
  "patient_hip_other_symptoms_q2_a3":"Moderata",
  "patient_hip_other_symptoms_q2_a4":"Notevole",
  "patient_hip_other_symptoms_q2_a5":"Estrema",
  "patient_hip_other_symptoms_q3":"Rigidità dopo il primo risveglio al mattino ",
  "patient_hip_other_symptoms_q3_a1":"Nessuna",
  "patient_hip_other_symptoms_q3_a2":"Lieve",
  "patient_hip_other_symptoms_q3_a3":"Moderata",
  "patient_hip_other_symptoms_q3_a4":"Notevole",
  "patient_hip_other_symptoms_q3_a5":"Estrema",
  "patient_hip_other_symptoms_q4":"Rigidità dopo essere rimasto seduto, sdraiato o aver riposato durante il giorno",
  "patient_hip_other_symptoms_q4_a1":"Nessuna",
  "patient_hip_other_symptoms_q4_a2":"Lieve",
  "patient_hip_other_symptoms_q4_a3":"Moderata",
  "patient_hip_other_symptoms_q4_a4":"Notevole",
  "patient_hip_other_symptoms_q4_a5":"Estrema",
  "patient_hip_other_symptoms_q5":"Andatura zoppicante",
  "patient_hip_other_symptoms_q5_a1":"Nessuna, raramente o mai",
  "patient_hip_other_symptoms_q5_a2":"Leggera, a volte o solo all'inizio",
  "patient_hip_other_symptoms_q5_a3":"Moderata, spesso, non solo all'inizio",
  "patient_hip_other_symptoms_q5_a4":"Notevole, la maggior parte del tempo",
  "patient_hip_other_symptoms_q5_a5":"Sempre o impossibilità di camminare",
  "patient_hip_other_symptoms_q6":"Dolore improvviso e grave - \"colpi\", \"pungenti\" o \"spasmi\" dall'anca",
  "patient_hip_other_symptoms_q6_a1":"Nessun giorno",
  "patient_hip_other_symptoms_q6_a2":"Solo 1 o 2 giorni",
  "patient_hip_other_symptoms_q6_a3":"Alcuni giorni",
  "patient_hip_other_symptoms_q6_a4":"La maggior parte dei giorni",
  "patient_hip_other_symptoms_q6_a5":"Ogni giorno",
  "patient_hip_other_symptoms_q7":"Cedimento dell'anca ",
  "patient_hip_other_symptoms_q7_a1":"Nessun",
  "patient_hip_other_symptoms_q7_a2":"Lieve",
  "patient_hip_other_symptoms_q7_a3":"Discreto",
  "patient_hip_other_symptoms_q7_a4":"Notevole",
  "patient_hip_other_symptoms_q7_a5":"Estremo",
  "patient_hip_other_symptoms_q8":"Movimento ridotto",
  "patient_hip_other_symptoms_q8_a1":"Nessun",
  "patient_hip_other_symptoms_q8_a2":"Lieve",
  "patient_hip_other_symptoms_q8_a3":"Discreto",
  "patient_hip_other_symptoms_q8_a4":"Notevole",
  "patient_hip_other_symptoms_q8_a5":"Estremo",
  "patient_hip_other_symptoms_q9":"Utilizza qualche supporto per camminare?",
  "patient_hip_other_symptoms_q9_a1":"Nessun supporto",
  "patient_hip_other_symptoms_q9_a2":"Bastone da passeggio per lunghe camminate",
  "patient_hip_other_symptoms_q9_a3":"Bastone da passeggio per la maggior parte del tempo",
  "patient_hip_other_symptoms_q9_a4":"Una stampella",
  "patient_hip_other_symptoms_q9_a5":"Due bastoni da passeggio",
  "patient_hip_other_symptoms_q9_a6":"Due stampelle o impossibilità di camminare",
  "patient_hip_function":"Funzionalità",
  "patient_hip_function_instruction":"Indichi il GRADO DI DIFFICOLTÀ che ha riscontrato nell'eseguire le seguenti attività nella scorsa settimana a causa del problema alla sua anca:",
  "patient_hip_function_instruction_0":"Nessuna",
  "patient_hip_function_instruction_1":"Lieve",
  "patient_hip_function_instruction_2":"Moderata",
  "patient_hip_function_instruction_3":"Notevole",
  "patient_hip_function_instruction_4":"Estrema, impossibile da fare",
  "patient_hip_function_note":"Nota: Se non ha svolto una determinata attività, immagina come si sentirebbe la sua anca se dovesse provarla.",
  "patient_hip_function_q1":"Scendere le scale",
  "patient_hip_function_q1_a1":"Nessuna",
  "patient_hip_function_q1_a2":"Lieve",
  "patient_hip_function_q1_a3":"Moderata",
  "patient_hip_function_q1_a4":"Notevole",
  "patient_hip_function_q1_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q2":"Salire le scale",
  "patient_hip_function_q2_a1":"Nessuna",
  "patient_hip_function_q2_a2":"Lieve",
  "patient_hip_function_q2_a3":"Moderata",
  "patient_hip_function_q2_a4":"Notevole",
  "patient_hip_function_q2_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q3":"Sedersi",
  "patient_hip_function_q3_a1":"Nessuna",
  "patient_hip_function_q3_a2":"Lieve",
  "patient_hip_function_q3_a3":"Moderata",
  "patient_hip_function_q3_a4":"Notevole",
  "patient_hip_function_q3_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q4":"Alzarsi dalla sedia",
  "patient_hip_function_q4_a1":"Nessuna",
  "patient_hip_function_q4_a2":"Lieve",
  "patient_hip_function_q4_a3":"Moderata",
  "patient_hip_function_q4_a4":"Notevole",
  "patient_hip_function_q4_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q5":"Alzarsi e abbassarsi da terra",
  "patient_hip_function_q5_a1":"Nessuna",
  "patient_hip_function_q5_a2":"Lieve",
  "patient_hip_function_q5_a3":"Moderata",
  "patient_hip_function_q5_a4":"Notevole",
  "patient_hip_function_q5_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q6":"Stare in piedi",
  "patient_hip_function_q6_a1":"Nessuna",
  "patient_hip_function_q6_a2":"Lieve",
  "patient_hip_function_q6_a3":"Moderata",
  "patient_hip_function_q6_a4":"Notevole",
  "patient_hip_function_q6_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q7":"Stare in piedi per 15 minuti",
  "patient_hip_function_q7_a1":"Nessuna",
  "patient_hip_function_q7_a2":"Lieve",
  "patient_hip_function_q7_a3":"Moderata",
  "patient_hip_function_q7_a4":"Notevole",
  "patient_hip_function_q7_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q8":"Divaricare le gambe molto ampiamente",
  "patient_hip_function_q8_a1":"Nessuna",
  "patient_hip_function_q8_a2":"Lieve",
  "patient_hip_function_q8_a3":"Moderata",
  "patient_hip_function_q8_a4":"Notevole",
  "patient_hip_function_q8_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q9":"Allungare il passo durante la camminata",
  "patient_hip_function_q9_a1":"Nessuna",
  "patient_hip_function_q9_a2":"Lieve",
  "patient_hip_function_q9_a3":"Moderata",
  "patient_hip_function_q9_a4":"Notevole",
  "patient_hip_function_q9_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q10":"Chinarsi verso il pavimento/raccogliere un oggetto",
  "patient_hip_function_q10_a1":"Nessuna",
  "patient_hip_function_q10_a2":"Lieve",
  "patient_hip_function_q10_a3":"Moderata",
  "patient_hip_function_q10_a4":"Notevole",
  "patient_hip_function_q10_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q11":"Camminare su una superficie piana",
  "patient_hip_function_q11_a1":"Nessuna",
  "patient_hip_function_q11_a2":"Lieve",
  "patient_hip_function_q11_a3":"Moderata",
  "patient_hip_function_q11_a4":"Notevole",
  "patient_hip_function_q11_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q12":"Camminare su superficie irregolare",
  "patient_hip_function_q12_a1":"Nessuna",
  "patient_hip_function_q12_a2":"Lieve",
  "patient_hip_function_q12_a3":"Moderata",
  "patient_hip_function_q12_a4":"Notevole",
  "patient_hip_function_q12_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q13":"Camminare per lunghe distanze",
  "patient_hip_function_q13_a1":"Nessuna",
  "patient_hip_function_q13_a2":"Lieve",
  "patient_hip_function_q13_a3":"Moderata",
  "patient_hip_function_q13_a4":"Notevole",
  "patient_hip_function_q13_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q14":"Camminare per esercizio fisico",
  "patient_hip_function_q14_a1":"Nessuna",
  "patient_hip_function_q14_a2":"Lieve",
  "patient_hip_function_q14_a3":"Moderata",
  "patient_hip_function_q14_a4":"Notevole",
  "patient_hip_function_q14_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q15":"Camminare su colline ripide in salita",
  "patient_hip_function_q15_a1":"Nessuna",
  "patient_hip_function_q15_a2":"Lieve",
  "patient_hip_function_q15_a3":"Moderata",
  "patient_hip_function_q15_a4":"Notevole",
  "patient_hip_function_q15_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q16":"Camminare su colline ripide in discesa",
  "patient_hip_function_q16_a1":"Nessuna",
  "patient_hip_function_q16_a2":"Lieve",
  "patient_hip_function_q16_a3":"Moderata",
  "patient_hip_function_q16_a4":"Notevole",
  "patient_hip_function_q16_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q17":"Camminare inizialmente",
  "patient_hip_function_q17_a1":"Nessuna",
  "patient_hip_function_q17_a2":"Lieve",
  "patient_hip_function_q17_a3":"Moderata",
  "patient_hip_function_q17_a4":"Notevole",
  "patient_hip_function_q17_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q18":"Camminare per circa 10 minuti",
  "patient_hip_function_q18_a1":"Nessuna",
  "patient_hip_function_q18_a2":"Lieve",
  "patient_hip_function_q18_a3":"Moderata",
  "patient_hip_function_q18_a4":"Notevole",
  "patient_hip_function_q18_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q19":"Camminare per 15 minuti o più a lungo",
  "patient_hip_function_q19_a1":"Nessuna",
  "patient_hip_function_q19_a2":"Lieve",
  "patient_hip_function_q19_a3":"Moderata",
  "patient_hip_function_q19_a4":"Notevole",
  "patient_hip_function_q19_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q20":"Salire e scendere dai marciapiedi",
  "patient_hip_function_q20_a1":"Nessuna",
  "patient_hip_function_q20_a2":"Lieve",
  "patient_hip_function_q20_a3":"Moderata",
  "patient_hip_function_q20_a4":"Notevole",
  "patient_hip_function_q20_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q21":"Salire e scendere dall'auto o dai mezzi pubblici (autobus) ",
  "patient_hip_function_q21_a1":"Nessuna",
  "patient_hip_function_q21_a2":"Lieve",
  "patient_hip_function_q21_a3":"Moderata",
  "patient_hip_function_q21_a4":"Notevole",
  "patient_hip_function_q21_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q22":"Fare la spesa",
  "patient_hip_function_q22_a1":"Nessuna",
  "patient_hip_function_q22_a2":"Lieve",
  "patient_hip_function_q22_a3":"Moderata",
  "patient_hip_function_q22_a4":"Notevole",
  "patient_hip_function_q22_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q23":"Indossare calze o calzini",
  "patient_hip_function_q23_a1":"Nessuna",
  "patient_hip_function_q23_a2":"Lieve",
  "patient_hip_function_q23_a3":"Moderata",
  "patient_hip_function_q23_a4":"Notevole",
  "patient_hip_function_q23_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q24":"Togliere calze o calzini",
  "patient_hip_function_q24_a1":"Nessuna",
  "patient_hip_function_q24_a2":"Lieve",
  "patient_hip_function_q24_a3":"Moderata",
  "patient_hip_function_q24_a4":"Notevole",
  "patient_hip_function_q24_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q25":"Stare sdraiato a letto (girarsi, mantenere la posizione dell'anca) ",
  "patient_hip_function_q25_a1":"Nessuna",
  "patient_hip_function_q25_a2":"Lieve",
  "patient_hip_function_q25_a3":"Moderata",
  "patient_hip_function_q25_a4":"Notevole",
  "patient_hip_function_q25_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q26":"Ruotare nel letto",
  "patient_hip_function_q26_a1":"Nessuna",
  "patient_hip_function_q26_a2":"Lieve",
  "patient_hip_function_q26_a3":"Moderata",
  "patient_hip_function_q26_a4":"Notevole",
  "patient_hip_function_q26_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q27":"Alzarsi dal letto",
  "patient_hip_function_q27_a1":"Nessuna",
  "patient_hip_function_q27_a2":"Lieve",
  "patient_hip_function_q27_a3":"Moderata",
  "patient_hip_function_q27_a4":"Notevole",
  "patient_hip_function_q27_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q28":"Entrare e uscire dalla vasca da bagno ",
  "patient_hip_function_q28_a1":"Nessuna",
  "patient_hip_function_q28_a2":"Lieve",
  "patient_hip_function_q28_a3":"Moderata",
  "patient_hip_function_q28_a4":"Notevole",
  "patient_hip_function_q28_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q29":"Sedersi e alzarsi dal water",
  "patient_hip_function_q29_a1":"Nessuna",
  "patient_hip_function_q29_a2":"Lieve",
  "patient_hip_function_q29_a3":"Moderata",
  "patient_hip_function_q29_a4":"Notevole",
  "patient_hip_function_q29_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q30":"Lavarsi e asciugarsi (interamente)",
  "patient_hip_function_q30_a1":"Nessuna",
  "patient_hip_function_q30_a2":"Lieve",
  "patient_hip_function_q30_a3":"Moderata",
  "patient_hip_function_q30_a4":"Notevole",
  "patient_hip_function_q30_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q31":"Lavori leggeri o moderati (stare in piedi, camminare)",
  "patient_hip_function_q31_a1":"Nessuna",
  "patient_hip_function_q31_a2":"Lieve",
  "patient_hip_function_q31_a3":"Moderata",
  "patient_hip_function_q31_a4":"Notevole",
  "patient_hip_function_q31_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q32":"Lavori domestici pesanti (spostare scatoloni pesanti, lavare I pavimenti, ecc)",
  "patient_hip_function_q32_a1":"Nessuna",
  "patient_hip_function_q32_a2":"Lieve",
  "patient_hip_function_q32_a3":"Moderata",
  "patient_hip_function_q32_a4":"Notevole",
  "patient_hip_function_q32_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q33":"Lavori domenistici leggeri (cucinare, spolverare, ecc)",
  "patient_hip_function_q33_a1":"Nessuna",
  "patient_hip_function_q33_a2":"Lieve",
  "patient_hip_function_q33_a3":"Moderata",
  "patient_hip_function_q33_a4":"Notevole",
  "patient_hip_function_q33_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q34":"Sollevare o portare bambini",
  "patient_hip_function_q34_a1":"Nessuna",
  "patient_hip_function_q34_a2":"Lieve",
  "patient_hip_function_q34_a3":"Moderata",
  "patient_hip_function_q34_a4":"Notevole",
  "patient_hip_function_q34_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q35":"Accovacciarsi",
  "patient_hip_function_q35_a1":"Nessuna",
  "patient_hip_function_q35_a2":"Lieve",
  "patient_hip_function_q35_a3":"Moderata",
  "patient_hip_function_q35_a4":"Notevole",
  "patient_hip_function_q35_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q36":"Correre ad andatura moderata per esercizio fisico",
  "patient_hip_function_q36_a1":"Nessuna",
  "patient_hip_function_q36_a2":"Lieve",
  "patient_hip_function_q36_a3":"Moderata",
  "patient_hip_function_q36_a4":"Notevole",
  "patient_hip_function_q36_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q37":"Correre",
  "patient_hip_function_q37_a1":"Nessuna",
  "patient_hip_function_q37_a2":"Lieve",
  "patient_hip_function_q37_a3":"Moderata",
  "patient_hip_function_q37_a4":"Notevole",
  "patient_hip_function_q37_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q38":"Ruotare o pivotare sulla gamba carica",
  "patient_hip_function_q38_a1":"Nessuna",
  "patient_hip_function_q38_a2":"Lieve",
  "patient_hip_function_q38_a3":"Moderata",
  "patient_hip_function_q38_a4":"Notevole",
  "patient_hip_function_q38_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q39":"Attività sessuale",
  "patient_hip_function_q39_a1":"Nessuna",
  "patient_hip_function_q39_a2":"Lieve",
  "patient_hip_function_q39_a3":"Moderata",
  "patient_hip_function_q39_a4":"Notevole",
  "patient_hip_function_q39_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q39_not_relevant":"Questo non mi riguarda.",
  "patient_hip_function_q40":"Sport ad alta intensità che richiedono scatti o cambi di direzione (es. calcio, basket, tennis, aerobica)",
  "patient_hip_function_q40_a1":"Nessuna",
  "patient_hip_function_q40_a2":"Lieve",
  "patient_hip_function_q40_a3":"Moderata",
  "patient_hip_function_q40_a4":"Notevole",
  "patient_hip_function_q40_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q41":"Sport a bassa intensità (es. golf, bowling) ",
  "patient_hip_function_q41_a1":"Nessuna",
  "patient_hip_function_q41_a2":"Lieve",
  "patient_hip_function_q41_a3":"Moderata",
  "patient_hip_function_q41_a4":"Notevole",
  "patient_hip_function_q41_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q42":"Attività ricreative",
  "patient_hip_function_q42_a1":"Nessuna",
  "patient_hip_function_q42_a2":"Lieve",
  "patient_hip_function_q42_a3":"Moderata",
  "patient_hip_function_q42_a4":"Notevole",
  "patient_hip_function_q42_a5":"Estrema, impossibile da fare",
  "patient_hip_function_q43":"Come valuterebbe le sue anche oggi come percentuale rispetto alla normalità? (da 0% al 100%, con il 100% che rappresenta la normalità)",
  "patient_hip_function_q44":"Si prega di specificare il più alto livello di attività a cui può partecipare",
  "patient_hip_function_q44_q1":"Prima dell'infortunio o della problematica all'anca",
  "patient_hip_function_q44_q1_a1":"Competitivo",
  "patient_hip_function_q44_q1_a2":"Ricreativo, fitness fisico",
  "patient_hip_function_q44_q1_a3":"Nessuno sport, solo lavoro",
  "patient_hip_function_q44_q1_a4":"Solo attività quotidiane",
  "patient_hip_function_q44_q1_a5":"Congedo per malattia o pensione di invalidità a causa di problemi di salute",
  "patient_hip_function_q44_q1_a1_o1":"Sport competitivi giocati a livello mondiale o olimpico o professionalmente",
  "patient_hip_function_q44_q1_a1_o2":"Sport competitivi come atletica leggera, sport con racchetta o palla, ginnastica, canottaggio, sci o arti marziali praticati a livello nazionale o internazionale",
  "patient_hip_function_q44_q1_a1_o3":"Sport competitivi come sport acquatici, ciclismo, hockey, curling o equitazione o rodeo praticati a livello nazionale o internazionale",
  "patient_hip_function_q44_q1_a2_o1":"Sport ricreativi come corsa, sport con palla o racchetta, allenamento con i pesi, curling, canottaggio, golf, yoga o ginnastica almeno 5 volte a settimana",
  "patient_hip_function_q44_q1_a2_o2":"Sport ricreativi come nuoto, sport acquatici, sci, hockey, pattinaggio, ciclismo, equitazione o arrampicata in montagna almeno 5 volte a settimana",
  "patient_hip_function_q44_q1_a2_o3":"Sport ricreativi almeno due volte a settimana",
  "patient_hip_function_q44_q1_a2_o4":"Sport ricreativi una volta alla settimana o meno",
  "patient_hip_function_q44_q1_a2_o5":"Sport ricreativi meno di una volta al mese",
  "patient_hip_function_q44_q1_a3_o1":"Lavoro pesante come l'edilizia",
  "patient_hip_function_q44_q1_a3_o2":"Lavoro moderatamente pesante come il camionista",
  "patient_hip_function_q44_q1_a3_o3":"Lavoro leggero come l'assistenza infermieristica",
  "patient_hip_function_q44_q1_a3_o4":"Lavoro sedentario come il lavoro segretariale o basato sul computer",
  "patient_hip_function_q44_q1_a4_o1":"Attività quotidiane come il giardinaggio, salire più rampe di scale, trasportare carichi, spingere o tirare un carico, capacità di correre se in ritardo",
  "patient_hip_function_q44_q1_a4_o2":"Attività quotidiane come pulire la casa, salire 1 o 2 rampe di scale o camminare portando un carico leggero",
  "patient_hip_function_q44_q1_a4_o3":"Attività quotidiane limitate (ad esempio, non salire le scale, impossibilità di trasportare carichi)",
  "patient_hip_function_q44_q2":"Stato attuale",
  "patient_hip_function_q44_q2_a1":"Competitivo",
  "patient_hip_function_q44_q2_a2":"Ricreativo, per il fitness fisico",
  "patient_hip_function_q44_q2_a3":"Nessuno sport, solo lavoro",
  "patient_hip_function_q44_q2_a4":"Solo attività della vita quotidiana",
  "patient_hip_function_q44_q2_a5":"Congedo per malattia o pensione di invalidità a causa di problemi di salute",
  "patient_hip_function_q44_q2_a1_o1":"Sport competitivi praticati a livello mondiale o olimpico o professionalmente",
  "patient_hip_function_q44_q2_a1_o2":"Sport competitivi come atletica leggera, sport con racchetta o palla, ginnastica, canottaggio, sci o arti marziali praticati a livello nazionale o internazionale",
  "patient_hip_function_q44_q2_a1_o3":"Sport competitivi come sport acquatici, ciclismo, hockey, curling o equitazione o rodeo praticati a livello nazionale o internazionale.",
  "patient_hip_function_q44_q2_a2_o1":"Sport ricreativi come corsa, sport con palla o racchetta, allenamento con pesi, curling, canottaggio, golf, yoga o ginnastica almeno 5 volte alla settimana",
  "patient_hip_function_q44_q2_a2_o2":"Sport ricreativi come nuoto, sport acquatici, sci, hockey, pattinaggio in linea, ciclismo, equitazione o alpinismo almeno 5 volte alla settimana",
  "patient_hip_function_q44_q2_a2_o3":"Sport ricreativi almeno due volte a settimana",
  "patient_hip_function_q44_q2_a2_o4":"Sport ricreativi una volta alla settimana o meno",
  "patient_hip_function_q44_q2_a2_o5":"Sport ricreativi meno di una volta al mese",
  "patient_hip_function_q44_q2_a3_o1":"Lavoro pesante come l'edilizia",
  "patient_hip_function_q44_q2_a3_o2":"Lavoro moderatamente pesante come il camionista",
  "patient_hip_function_q44_q2_a3_o3":"Lavoro leggero come l'assistenza infermieristica",
  "patient_hip_function_q44_q2_a3_o4":"Lavoro sedentario come il lavoro segretariale o basato sul computer",
  "patient_hip_function_q44_q2_a4_o1":"Attività quotidiane come il giardinaggio, salire più rampe di scale, trasportare carichi, spingere o tirare un carico, capacità di correre se in ritardo",
  "patient_hip_function_q44_q2_a4_o2":"Attività quotidiane come pulire la casa, salire 1 o 2 rampe di scale o camminare portando un carico leggero",
  "patient_hip_function_q44_q2_a4_o3":"Attività quotidiane limitate (ad esempio, non salire le scale, impossibilità di trasportare carichi)",
  "patient_hip_quality_of_life":"Qualità della vita",
  "patient_hip_quality_of_life_instruction":"Le seguenti domande riguardano le preoccupazioni che potrebbe avere riguardo al suo problema all'anca, sia dal punto di vista sociale che emotivo, e come questo possa influenzare il suo stile di vita. La preghiamo di pensare a come si è sentito nella maggior parte delle situazioni in relazione alla sua anca.",
  "patient_hip_quality_of_life_q1":"Con quale frequenza è consapevole del problema dell'anca?",
  "patient_hip_quality_of_life_q1_a1":"Mai",
  "patient_hip_quality_of_life_q1_a2":"Mensilmente",
  "patient_hip_quality_of_life_q1_a3":"Settimanalmente",
  "patient_hip_quality_of_life_q1_a4":"Quotidianamente",
  "patient_hip_quality_of_life_q1_a5":"Costantemente",
  "patient_hip_quality_of_life_q2":"Ha modificato il suo stile di vita per evitare attività che potrebbero danneggiare potenzialmente la sua anca?",
  "patient_hip_quality_of_life_q2_a1":"Per niente",
  "patient_hip_quality_of_life_q2_a2":"Leggermente",
  "patient_hip_quality_of_life_q2_a3":"Moderatamente",
  "patient_hip_quality_of_life_q2_a4":"Notevolmente",
  "patient_hip_quality_of_life_q2_a5":"Totalmente",
  "patient_hip_quality_of_life_q3":"Quanto è preoccupato riguardo alla sua capacità di mantenere il livello di forma fisica desiderato?",
  "patient_hip_quality_of_life_q3_a1":"Per niente preoccupato ",
  "patient_hip_quality_of_life_q3_a2":"Estremamente preoccupato ",
  "patient_hip_quality_of_life_q4":"Quanto è disturbato dalla mancanza di fiducia nella sua anca?",
  "patient_hip_quality_of_life_q4_a1":"Per niente",
  "patient_hip_quality_of_life_q4_a2":"Leggermente",
  "patient_hip_quality_of_life_q4_a3":"Moderatamente",
  "patient_hip_quality_of_life_q4_a4":"Notevolmente",
  "patient_hip_quality_of_life_q4_a5":"Estremamente",
  "patient_hip_quality_of_life_q5":"Quanto il problema all'anca è una distrazione per lei?",
  "patient_hip_quality_of_life_q5_a1":"Per niente distratto",
  "patient_hip_quality_of_life_q5_a2":"Estremamente distratto",
  "patient_hip_quality_of_life_q6":"In generale, quanto difficoltà ha con la sua anca?",
  "patient_hip_quality_of_life_q6_a1":"Nessuna",
  "patient_hip_quality_of_life_q6_a2":"Lieve",
  "patient_hip_quality_of_life_q6_a3":"Moderata",
  "patient_hip_quality_of_life_q6_a4":"Notevole",
  "patient_hip_quality_of_life_q6_a5":"Estrema",
  "patient_hip_quality_of_life_q7":"Quanto è ansioso o depresso oggi?",
  "patient_hip_quality_of_life_q7_a1":"Per niente",
  "patient_hip_quality_of_life_q7_a2":"Leggermente",
  "patient_hip_quality_of_life_q7_a3":"Moderatamente",
  "patient_hip_quality_of_life_q7_a4":"Notevolmente",
  "patient_hip_quality_of_life_q7_a5":"Estremamente",
  "patient_hip_quality_of_life_q8":"Quanto è buona o cattiva la sua salute oggi? (100 significa la miglior salute immaginabile, 0 significa la peggior salute immaginabile)",
  "patient_hip_quality_of_life_q8_a1":"Peggiore salute",
  "patient_hip_quality_of_life_q8_a2":"Migliore salute",
  "patient_hip_satisfaction":"Soddisfazione",
  "patient_hip_satisfaction_instructions":"Quanto è soddisfatto dei risultati dell'intervento o del trattamento all'anca?",
  "patient_hip_satisfaction_instructions_1":"Molto insoddisfatto",
  "patient_hip_satisfaction_instructions_2":"Abbastanza insoddisfatto ",
  "patient_hip_satisfaction_instructions_3":"Abbastanza soddisfatto",
  "patient_hip_satisfaction_instructions_4":"Molto soddisfatto",
  "patient_hip_satisfaction_q1":"Complessivamente",
  "patient_hip_satisfaction_q1_not_applicable":"Non applicabile",
  "patient_hip_satisfaction_q1_a1":"Molto insoddisfatto",
  "patient_hip_satisfaction_q1_a2":"Abbastanza insoddisfatto",
  "patient_hip_satisfaction_q1_a3":"Abbastanza soddisfatto",
  "patient_hip_satisfaction_q1_a4":"Molto soddisfatto",
  "patient_hip_satisfaction_q2":"Per migliorare il dolore? ",
  "patient_hip_satisfaction_q2_not_applicable":"Non applicabile",
  "patient_hip_satisfaction_q2_a1":"Molto insoddisfatto",
  "patient_hip_satisfaction_q2_a2":"Abbastanza insoddisfatto",
  "patient_hip_satisfaction_q2_a3":"Abbastanza soddisfatto",
  "patient_hip_satisfaction_q2_a4":"Molto soddisfatto",
  "patient_hip_satisfaction_q3":"Per il miglioramento della sua capacità di svolgere lavori domestici o in giardino?",
  "patient_hip_satisfaction_q3_not_applicable":"Non applicabile",
  "patient_hip_satisfaction_q3_a1":"Molto insoddisfatto",
  "patient_hip_satisfaction_q3_a2":"Abbastanza insoddisfatto",
  "patient_hip_satisfaction_q3_a3":"Abbastanza soddisfatto",
  "patient_hip_satisfaction_q3_a4":"Molto soddisfatto",
  "patient_hip_satisfaction_q4":"Per il miglioramento della sua capacità di svolgere attività ricreative?",
  "patient_hip_satisfaction_q4_not_applicable":"Non applicabile",
  "patient_hip_satisfaction_q4_a1":"Molto insoddisfatto",
  "patient_hip_satisfaction_q4_a2":"Abbastanza insoddisfatto",
  "patient_hip_satisfaction_q4_a3":"Abbastanza soddisfatto",
  "patient_hip_satisfaction_q4_a4":"Molto soddisfatto",
  "patient_hip_complete":"Fine del questionario",
  "patient_hip_complete_text1":"Grazie per aver dedicato del tempo per compilare questo modulo.",
  "patient_hip_complete_text2":"Per favore, clicchi su \"Invia\" per completare e visualizzare i suoi punteggi.",
  "patient_hip_complete_text3":"Le tue risposte saranno trattate in modo confidenziale e qualsiasi rapporto utilizzerà solo informazioni anonime.",
  "patient_hip_results":"Risultati",
  "patient_hip_results_text1":"Di seguito sono riportati i suoi punteggi per il dolore e la funzionalità dell'anca, calcolati dal questionario.",
  "patient_hip_results_pain":"Dolore",
  "patient_hip_results_function":"Funzionalità",
  "patient_hip_results_health_perception":"Percezione generale della salute",
  "patient_hip_results_self_evaluation":"Punteggio dell'autovalutazione della'anca",
  "patient_hip_results_text2":" Il suo medico sarà con lei a breve.",
  "":"",
  "patient_adult_knee_pain":"Dolore",
  "patient_adult_knee_pain_q1":"Quale è il livello più alto di attività che puoi svolgere senza provare un dolore significativo al ginocchio?",
  "patient_adult_knee_pain_q1_a1":"Attività molto intense come saltare o fare movimenti di inversione come nel basket o nel calcio",
  "patient_adult_knee_pain_q1_a2":"Attività impegnative come lavori fisici pesanti, sci o tennis",
  "patient_adult_knee_pain_q1_a3":"Attività moderate come lavori fisici moderati, corsa o jogging",
  "patient_adult_knee_pain_q1_a4":"Attività leggere come camminare, lavori domestici o lavori in giardino",
  "patient_adult_knee_pain_q1_a5":"Impossibilità di svolgere una qualsiasi delle attività sopra elencate a causa del dolore al ginocchio",
  "patient_adult_knee_pain_q2":"Con quale frequenza ha avuto dolore? ",
  "patient_adult_knee_pain_q2_a1":"Mai",
  "patient_adult_knee_pain_q2_a2":"Mensilmente",
  "patient_adult_knee_pain_q2_a3":"Settimanalmente",
  "patient_adult_knee_pain_q2_a4":"Quotidianamente",
  "patient_adult_knee_pain_q2_a5":"Costantemente ",
  "patient_adult_knee_pain_q3":"Quale delle seguenti affermazioni descrive meglio il suo dolore?",
  "patient_adult_knee_pain_q3_a1":"Nessun dolore, ginocchio normale, capacità del 100%",
  "patient_adult_knee_pain_q3_a2":"Dolore occasionale con sport intensi e lavori pesanti, ginocchio non del tutto normale, alcune limitazioni ma di lieve entità e tollerabili",
  "patient_adult_knee_pain_q3_a3":"Dolore, di solito scatenato dallo sport, attività ricreative leggere o lavori moderati. A volte si verifica durante la camminata di oltre 2 km, la stazione eretta o il lavoro leggero",
  "patient_adult_knee_pain_q3_a4":"Dolore occasionale con sport ricreativi leggeri o attività lavorative moderate, corsa o lavori pesante, sport intensi",
  "patient_adult_knee_pain_q3_a5":"Il dolore è un problema significativo con attività semplici come camminare <2 km, si allevia con il riposo, impossibilità di praticare sport",
  "patient_adult_knee_pain_q3_a6":"Costante",
  "patient_adult_knee_pain_q4":"Se ha dolore, quanto è intenso? ",
  "patient_adult_knee_pain_q4_a1":"Per niente",
  "patient_adult_knee_pain_q4_a2":"Molto lieve",
  "patient_adult_knee_pain_q4_a3":"Lieve",
  "patient_adult_knee_pain_q4_a4":"Moderato",
  "patient_adult_knee_pain_q4_a5":"Grave",
  "patient_adult_knee_pain_q5":"Quanto il dolore al ginocchio ha interferito con il lavoro abituale (comprese le mansioni domestiche)?",
  "patient_adult_knee_pain_q5_a1":"Nessun",
  "patient_adult_knee_pain_q5_a2":"Un po'",
  "patient_adult_knee_pain_q5_a3":"Moderatamente",
  "patient_adult_knee_pain_q5_a4":"Molto",
  "patient_adult_knee_pain_q5_a5":"Totalmente",
  "patient_adult_knee_pain_instruction":"Quanto dolore al ginocchio ha provato durante le seguenti attività:",
  "patient_adult_knee_pain_instruction_0":"Nessun dolore",
  "patient_adult_knee_pain_instruction_1":"Lieve",
  "patient_adult_knee_pain_instruction_2":"Moderato",
  "patient_adult_knee_pain_instruction_3":"Intenso",
  "patient_adult_knee_pain_instruction_4":"Estremo",
  "patient_adult_knee_pain_q6":"Torsione o rotazione del ginocchio",
  "patient_adult_knee_pain_q6_a1":"Nessun dolore",
  "patient_adult_knee_pain_q6_a2":"Lieve",
  "patient_adult_knee_pain_q6_a3":"Moderato",
  "patient_adult_knee_pain_q6_a4":"Intenso",
  "patient_adult_knee_pain_q6_a5":"Estremo",
  "patient_adult_knee_pain_q7":"Estendere completamente il ginocchio",
  "patient_adult_knee_pain_q7_a1":"Nessun dolore",
  "patient_adult_knee_pain_q7_a2":"Lieve",
  "patient_adult_knee_pain_q7_a3":"Moderato",
  "patient_adult_knee_pain_q7_a4":"Intenso",
  "patient_adult_knee_pain_q7_a5":"Estremo",
  "patient_adult_knee_pain_q8":"Flettere completamente il ginocchio",
  "patient_adult_knee_pain_q8_a1":"Nessun dolore",
  "patient_adult_knee_pain_q8_a2":"Lieve",
  "patient_adult_knee_pain_q8_a3":"Moderato",
  "patient_adult_knee_pain_q8_a4":"Intenso",
  "patient_adult_knee_pain_q8_a5":"Estremo",
  "patient_adult_knee_pain_q9":"Camminare su una superficie piana",
  "patient_adult_knee_pain_q9_a1":"Nessun dolore",
  "patient_adult_knee_pain_q9_a2":"Lieve",
  "patient_adult_knee_pain_q9_a3":"Intenso",
  "patient_adult_knee_pain_q9_a4":"Intenso",
  "patient_adult_knee_pain_q9_a5":"Estremo",
  "patient_adult_knee_pain_q10":"Per quanto tempo puoi camminare prima che il dolore al ginocchio diventi intenso? (con o senza bastone) ",
  "patient_adult_knee_pain_q10_a1":"Più di 30 minuti",
  "patient_adult_knee_pain_q10_a2":"16-30 minuti",
  "patient_adult_knee_pain_q10_a3":"5-15 minuti",
  "patient_adult_knee_pain_q10_a4":"Solo intorno casa",
  "patient_adult_knee_pain_q10_a5":"Non riesco a camminare affatto/il dolore è grave durante la camminata",
  "patient_adult_knee_pain_q11":"Salire o scendere le scale",
  "patient_adult_knee_pain_q11_a1":"Nessun dolore",
  "patient_adult_knee_pain_q11_a2":"Lieve",
  "patient_adult_knee_pain_q11_a3":"Moderato",
  "patient_adult_knee_pain_q11_a4":"Intenso",
  "patient_adult_knee_pain_q11_a5":"Estremo",
  "patient_adult_knee_pain_q12":"Di notte a letto",
  "patient_adult_knee_pain_q12_a1":"Nessun dolore",
  "patient_adult_knee_pain_q12_a2":"Lieve",
  "patient_adult_knee_pain_q12_a3":"Moderato",
  "patient_adult_knee_pain_q12_a4":"Intenso",
  "patient_adult_knee_pain_q12_a5":"Estremo",
  "patient_adult_knee_pain_q13":"Quante volte ha provato fastidio a causa del dolore al ginocchio mentre era a letto di notte?",
  "patient_adult_knee_pain_q13_a1":"Nessuna notte",
  "patient_adult_knee_pain_q13_a2":"1 o 2 notti",
  "patient_adult_knee_pain_q13_a3":"Alcune notti",
  "patient_adult_knee_pain_q13_a4":"La maggior parte delle notti",
  "patient_adult_knee_pain_q13_a5":"Ogni notti",
  "patient_adult_knee_pain_q14":"Sedersi o sdraiarsi",
  "patient_adult_knee_pain_q14_a1":"Nessun dolore",
  "patient_adult_knee_pain_q14_a2":"Lieve",
  "patient_adult_knee_pain_q14_a3":"Moderato",
  "patient_adult_knee_pain_q14_a4":"Intenso",
  "patient_adult_knee_pain_q14_a5":"Estremo",
  "patient_adult_knee_pain_q15":"In posizione eretta",
  "patient_adult_knee_pain_q15_a1":"Nessun dolore",
  "patient_adult_knee_pain_q15_a2":"Lieve",
  "patient_adult_knee_pain_q15_a3":"Moderato",
  "patient_adult_knee_pain_q15_a4":"Intenso",
  "patient_adult_knee_pain_q15_a5":"Estremo",
  "patient_adult_knee_pain_q16":"Alzarsi da una sedia ",
  "patient_adult_knee_pain_q16_a1":"Nessun dolore",
  "patient_adult_knee_pain_q16_a2":"Lieve",
  "patient_adult_knee_pain_q16_a3":"Moderato",
  "patient_adult_knee_pain_q16_a4":"Intenso",
  "patient_adult_knee_pain_q16_a5":"Estremo",
  "patient_adult_knee_other_symptoms":"Altri sintomi ",
  "patient_adult_knee_other_symptoms_instruction1":"Per favore, scelga la risposta che corrisponde meglio ai sintomi del suo ginocchio.",
  "patient_adult_knee_other_symptoms_instruction2":"Se non è sicuro su come rispondere a una domanda, la preghiamo di fornire la risposta che ritiene sia più adatta.",
  "patient_adult_knee_other_symptoms_instruction3":"Ha i seguenti sintomi?",
  "patient_adult_knee_other_symptoms_instruction_0":"Nessun",
  "patient_adult_knee_other_symptoms_instruction_1":"Lieve",
  "patient_adult_knee_other_symptoms_instruction_2":"Moderato",
  "patient_adult_knee_other_symptoms_instruction_3":"Notevole",
  "patient_adult_knee_other_symptoms_instruction_4":"Estremo",
  "patient_adult_knee_other_symptoms_q1":"Gonfiore",
  "patient_adult_knee_other_symptoms_q1_a1":"Mai",
  "patient_adult_knee_other_symptoms_q1_a2":"Raramente",
  "patient_adult_knee_other_symptoms_q1_a3":"A volte",
  "patient_adult_knee_other_symptoms_q1_a4":"Spesso",
  "patient_adult_knee_other_symptoms_q1_a5":"Sempre",
  "patient_adult_knee_other_symptoms_q2":"Clic, scrocchi o qualsiasi altro tipo di rumore quando il ginocchio si muove ",
  "patient_adult_knee_other_symptoms_q2_a1":"Mai",
  "patient_adult_knee_other_symptoms_q2_a2":"Raramente",
  "patient_adult_knee_other_symptoms_q2_a3":"A volte",
  "patient_adult_knee_other_symptoms_q2_a4":"Spesso",
  "patient_adult_knee_other_symptoms_q2_a5":"Sempre",
  "patient_adult_knee_other_symptoms_q3":"Sensazione di bloccaggio ",
  "patient_adult_knee_other_symptoms_q3_a1":"Mai",
  "patient_adult_knee_other_symptoms_q3_a2":"Raramente",
  "patient_adult_knee_other_symptoms_q3_a3":"A volte",
  "patient_adult_knee_other_symptoms_q3_a4":"Spesso",
  "patient_adult_knee_other_symptoms_q3_a5":"Sempre",
  "patient_adult_knee_other_symptoms_q4":"Zoppicare",
  "patient_adult_knee_other_symptoms_q4_a1":"Raramente, Mai",
  "patient_adult_knee_other_symptoms_q4_a2":"A volte, o solo all'inizio",
  "patient_adult_knee_other_symptoms_q4_a3":"Spesso, non solo all'inizio",
  "patient_adult_knee_other_symptoms_q4_a4":"La maggior parte del tempo",
  "patient_adult_knee_other_symptoms_q4_a5":"Sempre",
  "patient_adult_knee_other_symptoms_q5":"\nDurante le ultime 4 settimane o da quando ha avuto l'infortunio al ginocchio, quanto era rigido o gonfio il suo ginocchio?",
  "patient_adult_knee_other_symptoms_q5_a1":"Per niente",
  "patient_adult_knee_other_symptoms_q5_a2":"Leggermente",
  "patient_adult_knee_other_symptoms_q5_a3":"Moderatamente",
  "patient_adult_knee_other_symptoms_q5_a4":"Molto",
  "patient_adult_knee_other_symptoms_q5_a5":"Estremamente",
  "patient_adult_knee_other_symptoms_q6":"Quale è il livello più alto di attività che puoi svolgere senza avere un gonfiore significativo al ginocchio?",
  "patient_adult_knee_other_symptoms_q6_a1":"Attività molto intense come salti o cambi di direzione come nel basket o nel calcio",
  "patient_adult_knee_other_symptoms_q6_a2":"Attività intense come lavoro fisico pesante, sci o tennis",
  "patient_adult_knee_other_symptoms_q6_a3":"Attività moderate come lavoro fisico moderato, corsa o jogging",
  "patient_adult_knee_other_symptoms_q6_a4":"Attività leggere come camminare, lavori domestici o lavori in giardino",
  "patient_adult_knee_other_symptoms_q6_a5":"Incapace di svolgere qualsiasi delle attività sopra elencate a causa del gonfiore al ginocchio",
  "patient_adult_knee_other_symptoms_q7":"Quanto è grave la RIGIDITÀ delle tue articolazioni?",
  "patient_adult_knee_other_symptoms_q7_0":"Nessuna",
  "patient_adult_knee_other_symptoms_q7_1":"Leggera",
  "patient_adult_knee_other_symptoms_q7_2":"Moderata",
  "patient_adult_knee_other_symptoms_q7_3":"Grave",
  "patient_adult_knee_other_symptoms_q7_4":"Estrema",
  "patient_adult_knee_other_symptoms_q7_q1":"Dopo il primo risveglio al mattino?",
  "patient_adult_knee_other_symptoms_q7_q1_a1":"Nessuna",
  "patient_adult_knee_other_symptoms_q7_q1_a2":"Leggera",
  "patient_adult_knee_other_symptoms_q7_q1_a3":"Moderata",
  "patient_adult_knee_other_symptoms_q7_q1_a4":"Grave",
  "patient_adult_knee_other_symptoms_q7_q1_a5":"Estrema",
  "patient_adult_knee_other_symptoms_q7_q2":"Dopo essere stati seduti, sdraiati o a riposo durante il giorno?",
  "patient_adult_knee_other_symptoms_q7_q2_a1":"Nessuna",
  "patient_adult_knee_other_symptoms_q7_q2_a2":"Leggera",
  "patient_adult_knee_other_symptoms_q7_q2_a3":"Moderata",
  "patient_adult_knee_other_symptoms_q7_q2_a4":"Grave",
  "patient_adult_knee_other_symptoms_q7_q2_a5":"Estrema",
  "patient_adult_knee_other_symptoms_q8":"Qual è il livello più alto di attività che puoi svolgere senza un significativo cedimento del ginocchio?",
  "patient_adult_knee_other_symptoms_q8_a1":"Nessun cedimento",
  "patient_adult_knee_other_symptoms_q8_a2":"Occasionalmente si verifica un cedimento con sport intensi o lavori pesanti, è possibile partecipare a tutti gli sport ma con qualche cautela o limitazione. Raramente durante attività sportive o sforzi intensi",
  "patient_adult_knee_other_symptoms_q8_a3":"Frequentemente durante attività sportive o sforzi intensi. Occasionalmente si verifica un cedimento con sport leggeri o lavori moderati. In grado di compensare ma limita attività vigorose, sport o lavori pesanti, impossibilità di cambi di direzione o movimenti improvvisamente",
  "patient_adult_knee_other_symptoms_q8_a4":"Il cedimento limita attività sportive o lavori moderati, si verifica raramente durante la camminata o il lavoro leggero (circa 3 volte l'anno). Occasionalmente nelle attività quotidiane",
  "patient_adult_knee_other_symptoms_q8_a5":"Cedimento con attività semplici di camminata e lavori leggeri. Si verifica una volta al mese, richiede cautela. Spesso nelle attività quotidiane",
  "patient_adult_knee_other_symptoms_q8_a6":"Problema grave con attività semplici come camminata, cedimento ad ogni passo, impossibile girare o piegarsi camminando senza cedimenti.",
  "patient_adult_knee_other_symptoms_q9":"Usi qualsiasi tipo di SUPPORTO per camminare?",
  "patient_adult_knee_other_symptoms_q9_a1":"Nessuno",
  "patient_adult_knee_other_symptoms_q9_a2":"Bastone o stampella",
  "patient_adult_knee_other_symptoms_q9_a3":"Impossibilità di caricare peso",
  "patient_adult_knee_function":"Funzionalità",
  "patient_adult_knee_function_instruction":"Indichi il GRADO DI DIFFICOLTÀ che ha riscontrato nell'eseguire le seguenti attività nella scorsa settimana a causa del ginocchio:",
  "patient_adult_knee_function_instruction_0":"Nessuna difficoltà",
  "patient_adult_knee_function_instruction_1":"Difficoltà minima",
  "patient_adult_knee_function_instruction_2":"Difficoltà moderata",
  "patient_adult_knee_function_instruction_3":"Difficoltà estrema",
  "patient_adult_knee_function_instruction_4":"Impossibile da fare",
  "patient_adult_knee_function_note":"Nota: Se non fa una certa attività, immagini come si sentirebbe il suo ginocchio se dovesse provarla",
  "patient_adult_knee_function_q1":"Salire le scale",
  "patient_adult_knee_function_q1_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q1_a2":"Difficoltà minima",
  "patient_adult_knee_function_q1_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q1_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q1_a5":"Impossibile da fare",
  "patient_adult_knee_function_q2":"Scendere le scale",
  "patient_adult_knee_function_q2_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q2_a2":"Difficoltà minima",
  "patient_adult_knee_function_q2_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q2_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q2_a5":"Impossibile da fare",
  "patient_adult_knee_function_q3":"Ginocchiare sulla parte anteriore del ginocchio",
  "patient_adult_knee_function_q3_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q3_a2":"Difficoltà minima",
  "patient_adult_knee_function_q3_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q3_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q3_a5":"Impossibile da fare",
  "patient_adult_knee_function_q4":"Estendere il ginocchio",
  "patient_adult_knee_function_q4_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q4_a2":"Difficoltà minima",
  "patient_adult_knee_function_q4_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q4_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q4_a5":"Impossibile da fare",
  "patient_adult_knee_function_q5":"Piegare il ginocchio",
  "patient_adult_knee_function_q5_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q5_a2":"Difficoltà minima",
  "patient_adult_knee_function_q5_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q5_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q5_a5":"Impossibile da fare",
  "patient_adult_knee_function_q6":"Accovacciarsi",
  "patient_adult_knee_function_q6_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q6_a2":"Difficoltà minima",
  "patient_adult_knee_function_q6_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q6_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q6_a5":"Impossibile da fare",
  "patient_adult_knee_function_q7":"Sedersi con il ginocchio piegato",
  "patient_adult_knee_function_q7_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q7_a2":"Difficoltà minima",
  "patient_adult_knee_function_q7_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q7_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q7_a5":"Impossibile da fare",
  "patient_adult_knee_function_q8":"Alzarsi da una sedia",
  "patient_adult_knee_function_q8_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q8_a2":"Difficoltà minima",
  "patient_adult_knee_function_q8_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q8_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q8_a5":"Impossibile da fare",
  "patient_adult_knee_function_q9":"Alzarsi dal letto",
  "patient_adult_knee_function_q9_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q9_a2":"Difficoltà minima",
  "patient_adult_knee_function_q9_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q9_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q9_a5":"Impossibile da fare",
  "patient_adult_knee_function_q10":"Stare sdraiati a letto (girarsi mantenendo la posizione del ginocchio)",
  "patient_adult_knee_function_q10_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q10_a2":"Difficoltà minima",
  "patient_adult_knee_function_q10_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q10_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q10_a5":"Impossibile da fare",
  "patient_adult_knee_function_q11":"Piegarsi verso il pavimento o raccogliere un oggetto",
  "patient_adult_knee_function_q11_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q11_a2":"Difficoltà minima",
  "patient_adult_knee_function_q11_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q11_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q11_a5":"Impossibile da fare",
  "patient_adult_knee_function_q12":"Mettere le calze",
  "patient_adult_knee_function_q12_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q12_a2":"Difficoltà minima",
  "patient_adult_knee_function_q12_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q12_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q12_a5":"Impossibile da fare",
  "patient_adult_knee_function_q13":"Togliere le calze",
  "patient_adult_knee_function_q13_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q13_a2":"Difficoltà minima",
  "patient_adult_knee_function_q13_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q13_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q13_a5":"Impossibile da fare",
  "patient_adult_knee_function_q14":"Stare in piedi",
  "patient_adult_knee_function_q14_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q14_a2":"Difficoltà minima",
  "patient_adult_knee_function_q14_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q14_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q14_a5":"Impossibile da fare",
  "patient_adult_knee_function_q15":"Camminare",
  "patient_adult_knee_function_q15_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q15_a2":"Difficoltà minima",
  "patient_adult_knee_function_q15_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q15_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q15_a5":"Impossibile da fare",
  "patient_adult_knee_function_q16":"Scattare in avanti",
  "patient_adult_knee_function_q16_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q16_a2":"Difficoltà minima",
  "patient_adult_knee_function_q16_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q16_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q16_a5":"Impossibile da fare",
  "patient_adult_knee_function_q17":"Saltare e atterrare sulla gamba coinvolta",
  "patient_adult_knee_function_q17_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q17_a2":"Difficoltà minima",
  "patient_adult_knee_function_q17_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q17_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q17_a5":"Impossibile da fare",
  "patient_adult_knee_function_q18":"Fermarsi e ripartire velocemente",
  "patient_adult_knee_function_q18_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q18_a2":"Difficoltà minima",
  "patient_adult_knee_function_q18_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q18_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q18_a5":"Impossibile da fare",
  "patient_adult_knee_function_q19":"Girarsi o ruotare sul ginocchio infortunato",
  "patient_adult_knee_function_q19_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q19_a2":"Difficoltà minima",
  "patient_adult_knee_function_q19_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q19_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q19_a5":"Impossibile da fare",
  "patient_adult_knee_function_q20":"Entrare e uscire dall'auto o usare i mezzi pubblici ",
  "patient_adult_knee_function_q20_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q20_a2":"Difficoltà minima",
  "patient_adult_knee_function_q20_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q20_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q20_a5":"Impossibile da fare",
  "patient_adult_knee_function_q21":"Entrare e uscire dalla vasca da bagno ",
  "patient_adult_knee_function_q21_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q21_a2":"Difficoltà minima",
  "patient_adult_knee_function_q21_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q21_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q21_a5":"Impossibile da fare",
  "patient_adult_knee_function_q22":"Entrare e uscire dal bagno ",
  "patient_adult_knee_function_q22_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q22_a2":"Difficoltà minima",
  "patient_adult_knee_function_q22_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q22_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q22_a5":"Impossibile da fare",
  "patient_adult_knee_function_q23":"Lavarsi e asciugarsi",
  "patient_adult_knee_function_q23_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q23_a2":"Difficoltà minima",
  "patient_adult_knee_function_q23_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q23_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q23_a5":"Impossibile da fare",
  "patient_adult_knee_function_q24":"Fare la spesa domestica ",
  "patient_adult_knee_function_q24_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q24_a2":"Difficoltà minima",
  "patient_adult_knee_function_q24_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q24_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q24_a5":"Impossibile da fare",
  "patient_adult_knee_function_q25":"Manzioni domestiche pesanti (spostare scatole pesanti, lavare i pavimenti, ecc...)",
  "patient_adult_knee_function_q25_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q25_a2":"Difficoltà minima",
  "patient_adult_knee_function_q25_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q25_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q25_a5":"Impossibile da fare",
  "patient_adult_knee_function_q26":"Mansioni domestiche leggere (cucinare, spolverare, ecc.)",
  "patient_adult_knee_function_q26_a1":"Nessuna difficoltà",
  "patient_adult_knee_function_q26_a2":"Difficoltà minima",
  "patient_adult_knee_function_q26_a3":"Difficoltà moderata",
  "patient_adult_knee_function_q26_a4":"Difficoltà estrema",
  "patient_adult_knee_function_q26_a5":"Impossibile da fare",
  "patient_adult_knee_function_q27":"Come valuterebbe l'ATTUALE funzione del suo ginocchio su una scala da 0 a 10?",
  "patient_adult_knee_function_q28":"10 indica normale e 0 indica l'incapacità di svolgere qualsiasi delle tue normali attività quotidiane, che potrebbero includere lo sport.",
  "patient_adult_knee_function_q28_a1":"Peggiori",
  "patient_adult_knee_function_q28_a2":"Migliori",
  "patient_adult_knee_function_q29":"Qual è il livello più alto di attività in cui puoi partecipare regolarmente?",
  "patient_adult_knee_function_q29_a1":"Attività molto intense come salti o movimenti rapidi come nel basket o nel calcio",
  "patient_adult_knee_function_q29_a2":"Attività impegnative come lavori fisici pesanti, sci o tennis",
  "patient_adult_knee_function_q29_a3":"Attività moderate come lavori fisici moderati, correre o fare jogging",
  "patient_adult_knee_function_q29_a4":"Attività leggere come camminare, pulire la casa o lavorare in giardino",
  "patient_adult_knee_function_q29_a5":"Incapace di svolgere qualsiasi delle attività sopra elencate a causa del ginocchio",
  "patient_adult_knee_function_q30":"Per favore, specifica il livello più alto di attività in cui puoi partecipare:",
  "patient_adult_knee_function_q30_q1":"Prima dell'infortunio al ginocchio/problema al ginocchio ",
  "patient_adult_knee_function_q30_q1_a1":"Competitivo",
  "patient_adult_knee_function_q30_q1_a2":"Attività ricreative, fitness fisico",
  "patient_adult_knee_function_q30_q1_a3":"Nessuno sport, solo lavoro",
  "patient_adult_knee_function_q30_q1_a4":"Solo attività della vita quotidiana",
  "patient_adult_knee_function_q30_q1_a5":"Congedo per malattia o pensione di invalidità a causa di problemi di salute",
  "patient_adult_knee_function_q30_q1_a1_o1":"Sport competitivi giocati a livello mondiale o olimpico o professionalmente",
  "patient_adult_knee_function_q30_q1_a1_o2":"Sport competitivi come atletica leggera, sport con racchetta o palla, ginnastica, canottaggio, sci o arti marziali giocati a livello nazionale o internazionale",
  "patient_adult_knee_function_q30_q1_a1_o3":"Sport competitivi come sport acquatici, ciclismo, hockey, curling o equitazione o rodeo giocati a livello nazionale o internazionale",
  "patient_adult_knee_function_q30_q1_a2_o1":"Sport ricreativi come corsa, sport con palla o racchetta, allenamento con i pesi, curling, canottaggio, golf, yoga o ginnastica almeno 5 volte a settimana",
  "patient_adult_knee_function_q30_q1_a2_o2":"Sport ricreativi come nuoto, sport acquatici, sci, hockey, pattinaggio in linea, ciclismo, equitazione o arrampicata in montagna almeno 5 volte a settimana",
  "patient_adult_knee_function_q30_q1_a2_o3":"Sport ricreativi almeno due volte a settimana",
  "patient_adult_knee_function_q30_q1_a2_o4":"Sport ricreativi una volta alla settimana o meno",
  "patient_adult_knee_function_q30_q1_a2_o5":"Sport ricreativi meno di una volta al mese",
  "patient_adult_knee_function_q30_q1_a3_o1":"Lavoro pesante come il lavoro edile",
  "patient_adult_knee_function_q30_q1_a3_o2":"Lavoro moderatamente pesante come guidare un camion",
  "patient_adult_knee_function_q30_q1_a3_o3":"Lavoro leggero come il lavoro di infermieristica",
  "patient_adult_knee_function_q30_q1_a3_o4":"Lavoro sedentario come il lavoro segretariale o basato sul computer",
  "patient_adult_knee_function_q30_q1_a4_o1":"Attività quotidiane come il giardinaggio, salire più rampe di scale, trasportare pesi, spingere o tirare un carico, capacità di correre se in ritardo",
  "patient_adult_knee_function_q30_q1_a4_o2":"Attività quotidiane come pulire casa, salire 1 o 2 rampe di scale o camminare portando un carico leggero",
  "patient_adult_knee_function_q30_q1_a4_o3":"Attività quotidiane limitate (ad esempio, non salire scale, impossibilità di trasportare pesi)",
  "patient_adult_knee_function_q30_q2":"Stato attuale",
  "patient_adult_knee_function_q30_q2_a1":"Competitivo",
  "patient_adult_knee_function_q30_q2_a2":"Attività ricreative, fitness fisico",
  "patient_adult_knee_function_q30_q2_a3":"Nessuno sport, solo lavoro",
  "patient_adult_knee_function_q30_q2_a4":"Solo attività della vita quotidiana",
  "patient_adult_knee_function_q30_q2_a5":"Congedo per malattia o pensione di invalidità a causa di problemi di salute",
  "patient_adult_knee_function_q30_q2_a1_o1":"Sport competitivi giocati a livello mondiale o olimpico o professionalmente",
  "patient_adult_knee_function_q30_q2_a1_o2":"Sport competitivi come atletica leggera, sport con racchetta o palla, ginnastica, canottaggio, sci o arti marziali giocati a livello nazionale o internazionale",
  "patient_adult_knee_function_q30_q2_a1_o3":"Sport competitivi come sport acquatici, ciclismo, hockey, curling o equitazione o rodeo giocati a livello nazionale o internazionale",
  "patient_adult_knee_function_q30_q2_a2_o1":"Sport ricreativi come corsa, sport con palla o racchetta, allenamento con i pesi, curling, canottaggio, golf, yoga o ginnastica almeno 5 volte a settimana",
  "patient_adult_knee_function_q30_q2_a2_o2":"Sport ricreativi come nuoto, sport acquatici, sci, hockey, pattinaggio in linea, ciclismo, equitazione o arrampicata in montagna almeno 5 volte a settimana",
  "patient_adult_knee_function_q30_q2_a2_o3":"Sport ricreativi almeno due volte a settimana",
  "patient_adult_knee_function_q30_q2_a2_o4":"Sport ricreativi una volta alla settimana o meno",
  "patient_adult_knee_function_q30_q2_a2_o5":"Sport ricreativi meno di una volta al mese",
  "patient_adult_knee_function_q30_q2_a3_o1":"Lavoro pesante come il lavoro edile",
  "patient_adult_knee_function_q30_q2_a3_o2":"Lavoro moderatamente pesante come guidare un camion",
  "patient_adult_knee_function_q30_q2_a3_o3":"Lavoro leggero come il lavoro di infermieristica",
  "patient_adult_knee_function_q30_q2_a3_o4":"Lavoro sedentario come il lavoro segretariale o basato sul computer",
  "patient_adult_knee_function_q30_q2_a4_o1":"Attività quotidiane come il giardinaggio, salire più rampe di scale, trasportare pesi, spingere o tirare un carico, capacità di correre se in ritardo",
  "patient_adult_knee_function_q30_q2_a4_o2":"Attività quotidiane come pulire casa, salire 1 o 2 rampe di scale o camminare portando un carico leggero",
  "patient_adult_knee_function_q30_q2_a4_o3":"Attività quotidiane limitate (ad esempio, non salire scale, impossibilità di trasportare pesi)",
  "patient_adult_knee_function_q31":"La rotula esce dal suo alloggiamento o sembra che stia uscendo dal suo alloggiamento?",
  "patient_adult_knee_function_q31_a1":"No ",
  "patient_adult_knee_function_q31_a2":"Si",
  "patient_adult_knee_patellofemoral_instability":"Instabilità patellofemorale",
  "patient_adult_knee_patellofemoral_instability_instruction":"Per favore, scelga quale descrizione corrisponde meglio a QUANTE VOLTE LA SUA ROTULA HA LA SENSATIONE DI ESSERE VICINA AD \"USCIRE\" DALL'ARTICOLAZIONE O SI SENTE INSTABILE mentre sta svolgendo ciascuna delle seguenti attività:",
  "patient_adult_knee_patellofemoral_instability_instruction_0":"Mai",
  "patient_adult_knee_patellofemoral_instability_instruction_1":"Raramente",
  "patient_adult_knee_patellofemoral_instability_instruction_2":"A volte",
  "patient_adult_knee_patellofemoral_instability_instruction_3":"Spesso",
  "patient_adult_knee_patellofemoral_instability_instruction_4":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q1":"Girare o cambiare direzione durante sport e/o giochi",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q1_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q1_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q1_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q1_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q1_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q2":"Cambiare direzione durante la corsa",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q2_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q2_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q2_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q2_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q2_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q3":"Correre in linea retta su superfici irregolari",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q3_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q3_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q3_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q3_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q3_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q4":"Camminare su superfici scivolose bagnate o ghiacciate  ",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q4_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q4_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q4_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q4_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q4_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q5":"Correre lateralmente",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q5_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q5_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q5_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q5_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q5_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q6":"Saltellare",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q6_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q6_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q6_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q6_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q6_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q7":"Saltare",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q7_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q7_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q7_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q7_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q7_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q8":"Correre in linea retta su superfici regolari",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q8_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q8_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q8_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q8_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q8_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q9":"Scendere le scale",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q9_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q9_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q9_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q9_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q9_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q10":"Accovacciarsi",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q10_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q10_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q10_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q10_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q10_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q11":"Inginocchiarsi",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q11_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q11_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q11_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q11_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q11_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q12":"Camminare in linea retta su superfici irregolari",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q12_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q12_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q12_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q12_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q12_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q13":"Salire le scale",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q13_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q13_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q13_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q13_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q13_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q14":"Salire o superare un gradino alto",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q14_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q14_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q14_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q14_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q14_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q15":"Incrociare le gambe quando si è seduti",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q15_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q15_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q15_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q15_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q15_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q16":"Camminare in linea retta su superfici regolari",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q16_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q16_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q16_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q16_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q16_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q17":"Salire o scendere da un'auto",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q17_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q17_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q17_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q17_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q17_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q18":"Manovrare un carrello pesante intorno ai corridoi di un supermercato",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q18_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q18_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q18_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q18_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q18_a5":"Sempre",
  "patient_adult_knee_patellofemoral_instability_q19":"Girarsi per guardare dietro la spalla",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do":"Non lo faccio",
  "patient_adult_knee_patellofemoral_instability_q19_a1":"Mai",
  "patient_adult_knee_patellofemoral_instability_q19_a2":"Raramente",
  "patient_adult_knee_patellofemoral_instability_q19_a3":"A volte",
  "patient_adult_knee_patellofemoral_instability_q19_a4":"Spesso",
  "patient_adult_knee_patellofemoral_instability_q19_a5":"Sempre",
  "patient_adult_knee_quality_of_life":"Qualità della vita",
  "patient_adult_knee_quality_of_life_instruction":"Le seguenti domande riguardano preoccupazioni sociali, emotive e di stile di vita che potrebbe provare riguardo al suo problema al ginocchio. La preghiamo di riflettere su come si è sentito nella maggior parte delle occasioni in relazione al suo ginocchio.",
  "patient_adult_knee_quality_of_life_q1":"Con quale frequenza è consapevole del problema al suo ginocchio?",
  "patient_adult_knee_quality_of_life_q1_a1":"Mai",
  "patient_adult_knee_quality_of_life_q1_a2":"Mensilmente",
  "patient_adult_knee_quality_of_life_q1_a3":"Settimanalmente",
  "patient_adult_knee_quality_of_life_q1_a4":"Quotidianamente",
  "patient_adult_knee_quality_of_life_q1_a5":"Costantemente",
  "patient_adult_knee_quality_of_life_q2":"Ha modificato il suo stile di vita per evitare attività potenzialmente dannose per il ginocchio? ",
  "patient_adult_knee_quality_of_life_q2_a1":"Per niente",
  "patient_adult_knee_quality_of_life_q2_a2":"Leggermente",
  "patient_adult_knee_quality_of_life_q2_a3":"Moderatamente",
  "patient_adult_knee_quality_of_life_q2_a4":"Notevolmente",
  "patient_adult_knee_quality_of_life_q2_a5":"Totalmente",
  "patient_adult_knee_quality_of_life_q3":"Quanto è preoccupato per la mancanza di fiducia nel suo ginocchio?",
  "patient_adult_knee_quality_of_life_q3_a1":"Per niente",
  "patient_adult_knee_quality_of_life_q3_a2":"Leggermente",
  "patient_adult_knee_quality_of_life_q3_a3":"Moderatamente",
  "patient_adult_knee_quality_of_life_q3_a4":"Notevolmente",
  "patient_adult_knee_quality_of_life_q3_a5":"Completamente",
  "patient_adult_knee_quality_of_life_q4":"In generale, quanto difficoltà ha con il suo ginocchio?",
  "patient_adult_knee_quality_of_life_q4_a1":"Nessuna",
  "patient_adult_knee_quality_of_life_q4_a2":"Lieve",
  "patient_adult_knee_quality_of_life_q4_a3":"Moderata",
  "patient_adult_knee_quality_of_life_q4_a4":"Notevole",
  "patient_adult_knee_quality_of_life_q4_a5":"Estrema",
  "patient_adult_knee_quality_of_life_q5":"Quanto è ansioso o depresso oggi?",
  "patient_adult_knee_quality_of_life_q5_a1":"Non ansioso o depresso",
  "patient_adult_knee_quality_of_life_q5_a2":"Leggermente",
  "patient_adult_knee_quality_of_life_q5_a3":"Moderatamente",
  "patient_adult_knee_quality_of_life_q5_a4":"Notevolmente",
  "patient_adult_knee_quality_of_life_q5_a5":"Estremamente",
  "patient_adult_knee_quality_of_life_q6":"Qual è il suo livello di salute oggi?",
  "patient_adult_knee_quality_of_life_q6_instruction":"100 significa la migliore salute possibile, 0 significa la peggiore salute immaginabile",
  "patient_adult_knee_quality_of_life_q6_instruction_a1":"Peggiore salute",
  "patient_adult_knee_quality_of_life_q6_instruction_a2":"Migliore salute",
  "patient_adult_knee_satisfaction":"Soddisfazione",
  "patient_adult_knee_satisfaction_instructions":"Quanto è soddisfatto dei risultati dell'intervento o del trattamento al ginocchio?",
  "patient_adult_knee_satisfaction_instructions_1":"Molto insoddisfatto",
  "patient_adult_knee_satisfaction_instructions_2":"Abbastanza insoddisfatto ",
  "patient_adult_knee_satisfaction_instructions_3":"Abbastanza soddisfatto",
  "patient_adult_knee_satisfaction_instructions_4":"Molto soddisfatto",
  "patient_adult_knee_satisfaction_q1":"Complessivamente",
  "patient_adult_knee_satisfaction_q1_not_applicable":"Non applicabile",
  "patient_adult_knee_satisfaction_q1_a1":"Molto insoddisfatto",
  "patient_adult_knee_satisfaction_q1_a2":"Abbastanza insoddisfatto",
  "patient_adult_knee_satisfaction_q1_a3":"Abbastanza soddisfatto",
  "patient_adult_knee_satisfaction_q1_a4":"Molto soddisfatto",
  "patient_adult_knee_satisfaction_q2":"Per migliorare il dolore? ",
  "patient_adult_knee_satisfaction_q2_not_applicable":"Non applicabile",
  "patient_adult_knee_satisfaction_q2_a1":"Molto insoddisfatto",
  "patient_adult_knee_satisfaction_q2_a2":"Abbastanza insoddisfatto",
  "patient_adult_knee_satisfaction_q2_a3":"Abbastanza soddisfatto",
  "patient_adult_knee_satisfaction_q2_a4":"Molto soddisfatto",
  "patient_adult_knee_satisfaction_q3":"Per migliorare la sua capacità di fare lavori domestici o di giardinaggio? ",
  "patient_adult_knee_satisfaction_q3_not_applicable":"Non applicabile",
  "patient_adult_knee_satisfaction_q3_a1":"Molto insoddisfatto",
  "patient_adult_knee_satisfaction_q3_a2":"Abbastanza insoddisfatto",
  "patient_adult_knee_satisfaction_q3_a3":"Abbastanza soddisfatto",
  "patient_adult_knee_satisfaction_q3_a4":"Molto soddisfatto",
  "patient_adult_knee_satisfaction_q4":"Per migliorare la sua capacità di fare attività ricreative?",
  "patient_adult_knee_satisfaction_q4_not_applicable":"Non applicabile",
  "patient_adult_knee_satisfaction_q4_a1":"Molto insoddisfatto",
  "patient_adult_knee_satisfaction_q4_a2":"Abbastanza insoddisfatto",
  "patient_adult_knee_satisfaction_q4_a3":"Abbastanza soddisfatto",
  "patient_adult_knee_satisfaction_q4_a4":"Molto soddisfatto",
  "patient_adult_knee_complete":"Fine del questionario",
  "patient_adult_knee_complete_text1":"Grazie per aver dedicato del tempo per compilare questo modulo.",
  "patient_adult_knee_complete_text2":"Per favore, clicchi su \"Invia\" per completare e visualizzare i suoi punteggi.",
  "patient_adult_knee_complete_text3":"Le tue risposte saranno trattate in modo confidenziale e qualsiasi rapporto utilizzerà solo informazioni anonime.",
  "patient_adult_knee_results":"Risultati",
  "patient_adult_knee_results_text1":"Di seguito sono riportati i suoi punteggi per il dolore e la funzionalità della spalla, calcolati dal questionario.",
  "patient_adult_knee_results_pain":"Dolore",
  "patient_adult_knee_results_function":"Funzionalità",
  "patient_adult_knee_results_health_perception":"Percezione generale della salute",
  "patient_adult_knee_results_self_evaluation":"Punteggio dell'autovalutazione del ginocchio",
  "patient_adult_knee_results_text2":" Il suo medico sarà con lei a breve.",
  "patient_adolescent_knee_pain":"Dolore",
  "patient_adolescent_knee_function_q23":"Fare la spesa",
  "patient_adolescent_knee_function_q23_a1":"Facile",
  "patient_adolescent_knee_function_q23_a2":"Un po' difficile",
  "patient_adolescent_knee_function_q23_a3":"Un po' complicato",
  "patient_adolescent_knee_function_q23_a4":"Molto difficile",
  "patient_adolescent_knee_function_q23_a5":"Impossibile",
  "patient_pedia_knee_pain":"Male",
  "patient_pedia_knee_pain_q1":"Nelle ultime 4 settimane o da quando ti sei fatto male, quante volte il tuo ginocchio ti fa male?",
  "patient_pedia_knee_pain_q1_a1":"Mai",
  "patient_pedia_knee_pain_q1_a2":"Quasi mai",
  "patient_pedia_knee_pain_q1_a3":"Qualche volta",
  "patient_pedia_knee_pain_q1_a4":"Spesso",
  "patient_pedia_knee_pain_q1_a5":"Sempre",
  "patient_pedia_knee_pain_q2":"Quanto fa male oggi il tuo ginocchio? ",
  "patient_pedia_knee_pain_q2_a1":"Non fa male ",
  "patient_pedia_knee_pain_q2_a2":"Fa molto male, non riesco a sopportarlo",
  "patient_pedia_knee_pain_instructions":"Quanto male al ginocchio hai avuto negli ultimi 7 giorni durante le seguenti attività?",
  "patient_pedia_knee_pain_instructions_0":"Nessun male",
  "patient_pedia_knee_pain_instructions_1":"Poco male",
  "patient_pedia_knee_pain_instructions_2":"Un po' di male",
  "patient_pedia_knee_pain_instructions_3":"Tanto male",
  "patient_pedia_knee_pain_instructions_4":"Tantissimo dolore",
  "patient_pedia_knee_pain_note":"crivi le risposte alle seguenti domande riguardanti le tue attività con il ginocchio.",
  "patient_pedia_knee_pain_q3":"Giri o ruoti mentre cammini, stai in piedi o corri?",
  "patient_pedia_knee_pain_q3_a1":"Nessun male",
  "patient_pedia_knee_pain_q3_a2":"Poco male",
  "patient_pedia_knee_pain_q3_a3":"Un po' di male",
  "patient_pedia_knee_pain_q3_a4":"Tanto male",
  "patient_pedia_knee_pain_q3_a5":"Tantissimo dolore",
  "patient_pedia_knee_pain_q4":"Allungare completamente il ginocchio",
  "patient_pedia_knee_pain_q4_a1":"Nessun male",
  "patient_pedia_knee_pain_q4_a2":"Poco male",
  "patient_pedia_knee_pain_q4_a3":"Un po' di male",
  "patient_pedia_knee_pain_q4_a4":"Tanto male",
  "patient_pedia_knee_pain_q4_a5":"Tantissimo dolore",
  "patient_pedia_knee_pain_q5":"Piegare completamente il ginocchio",
  "patient_pedia_knee_pain_q5_a1":"Nessun male",
  "patient_pedia_knee_pain_q5_a2":"Poco male",
  "patient_pedia_knee_pain_q5_a3":"Un po' di male",
  "patient_pedia_knee_pain_q5_a4":"Tanto male",
  "patient_pedia_knee_pain_q5_a5":"Tantissimo dolore",
  "patient_pedia_knee_pain_q6":"Salire le scale  ",
  "patient_pedia_knee_pain_q6_a1":"Nessun male",
  "patient_pedia_knee_pain_q6_a2":"Poco male",
  "patient_pedia_knee_pain_q6_a3":"Un po' di male",
  "patient_pedia_knee_pain_q6_a4":"Tanto male",
  "patient_pedia_knee_pain_q6_a5":"Tantissimo dolore",
  "patient_pedia_knee_pain_q7":"Scendere le scale",
  "patient_pedia_knee_pain_q7_a1":"Nessun male",
  "patient_pedia_knee_pain_q7_a2":"Poco male",
  "patient_pedia_knee_pain_q7_a3":"Un po' di male",
  "patient_pedia_knee_pain_q7_a4":"Tanto male",
  "patient_pedia_knee_pain_q7_a5":"Tantissimo dolore",
  "patient_pedia_knee_pain_q8":"Sedersi con il ginocchio piegato",
  "patient_pedia_knee_pain_q8_a1":"Nessun male",
  "patient_pedia_knee_pain_q8_a2":"Poco male",
  "patient_pedia_knee_pain_q8_a3":"Un po' di male",
  "patient_pedia_knee_pain_q8_a4":"Tanto male",
  "patient_pedia_knee_pain_q8_a5":"Tantissimo dolore",
  "patient_pedia_knee_pain_q9":"Stare in piedi dritto sulle due gambe per qualsiasi periodo di tempo",
  "patient_pedia_knee_pain_q9_a1":"Nessun male",
  "patient_pedia_knee_pain_q9_a2":"Poco male",
  "patient_pedia_knee_pain_q9_a3":"Un po' di male",
  "patient_pedia_knee_pain_q9_a4":"Tanto male",
  "patient_pedia_knee_pain_q9_a5":"Tantissimo dolore",
  "patient_pedia_knee_other_symptoms":"Altri sintomi ",
  "patient_pedia_knee_other_symptoms_instruction1":"Quante volte hai avuto i seguenti:",
  "patient_pedia_knee_other_symptoms_instruction1_0":"Mai",
  "patient_pedia_knee_other_symptoms_instruction1_1":"Quasi mai",
  "patient_pedia_knee_other_symptoms_instruction1_2":"A volte",
  "patient_pedia_knee_other_symptoms_instruction1_3":"Spesso",
  "patient_pedia_knee_other_symptoms_instruction1_4":"Sempre",
  "patient_pedia_knee_other_symptoms_q1":"Il ginocchio fa dei rumori o suoni strani",
  "patient_pedia_knee_other_symptoms_q1_a1":"Mai",
  "patient_pedia_knee_other_symptoms_q1_a2":"Quasi mai",
  "patient_pedia_knee_other_symptoms_q1_a3":"A volte",
  "patient_pedia_knee_other_symptoms_q1_a4":"Spesso",
  "patient_pedia_knee_other_symptoms_q1_a5":"Sempre",
  "patient_pedia_knee_other_symptoms_q2":"Il ginocchio si ferma o si blocca e non si muove più",
  "patient_pedia_knee_other_symptoms_q2_a1":"Mai",
  "patient_pedia_knee_other_symptoms_q2_a2":"Quasi mai",
  "patient_pedia_knee_other_symptoms_q2_a3":"A volte",
  "patient_pedia_knee_other_symptoms_q2_a4":"Spesso",
  "patient_pedia_knee_other_symptoms_q2_a5":"Sempre",
  "patient_pedia_knee_other_symptoms_q3":"Il ginocchio sembra bloccarsi, anche se puoi ancora muoverlo ",
  "patient_pedia_knee_other_symptoms_q3_a1":"Mai",
  "patient_pedia_knee_other_symptoms_q3_a2":"Quasi mai",
  "patient_pedia_knee_other_symptoms_q3_a3":"A volte",
  "patient_pedia_knee_other_symptoms_q3_a4":"Spesso",
  "patient_pedia_knee_other_symptoms_q3_a5":"Sempre",
  "patient_pedia_knee_other_symptoms_q4":"Ginocchio gonfio",
  "patient_pedia_knee_other_symptoms_q4_a1":"Mai",
  "patient_pedia_knee_other_symptoms_q4_a2":"Quasi mai",
  "patient_pedia_knee_other_symptoms_q4_a3":"A volte",
  "patient_pedia_knee_other_symptoms_q4_a4":"Spesso",
  "patient_pedia_knee_other_symptoms_q4_a5":"Sempre",
  "patient_pedia_knee_other_symptoms_q5":"Quanto era GONFIO il ginocchio?",
  "patient_pedia_knee_other_symptoms_q5_a1":"Per niente gonfio",
  "patient_pedia_knee_other_symptoms_q5_a2":"Un po gonfio",
  "patient_pedia_knee_other_symptoms_q5_a3":"Leggermente gonfio",
  "patient_pedia_knee_other_symptoms_q5_a4":"Molto gonfio",
  "patient_pedia_knee_other_symptoms_q5_a5":"Estremamente gonfio",
  "patient_pedia_knee_other_symptoms_instruction2":"Se ti chiedessero di fare queste attività, quante di esse potresti fare OGGI al massimo delle tue capacità attuali…",
  "patient_pedia_knee_other_symptoms_instruction2_0":"Attività molto difficili come saltare o girare rapidamente per cambiare direzione, come nel basket o nel calcio",
  "patient_pedia_knee_other_symptoms_instruction2_1":"Attività difficili come sollevare pesi, sciare o giocare a tennis",
  "patient_pedia_knee_other_symptoms_instruction2_2":"Attività abbastanza difficili come camminare veloce o correre a passo moderato",
  "patient_pedia_knee_other_symptoms_instruction2_3":"Attività leggere come camminare a passo normale",
  "patient_pedia_knee_other_symptoms_instruction2_4":"Non posso fare nessuna delle attività elencate sopra",
  "patient_pedia_knee_other_symptoms_q6":"senza provare un FORTE DOLORE al ginocchio",
  "patient_pedia_knee_other_symptoms_q6_a1":"Attività molto difficili",
  "patient_pedia_knee_other_symptoms_q6_a2":"Attività difficili",
  "patient_pedia_knee_other_symptoms_q6_a3":"Attività un po' difficili",
  "patient_pedia_knee_other_symptoms_q6_a4":"Attività leggere",
  "patient_pedia_knee_other_symptoms_q6_a5":"Non riesco a fare nessuna delle attività sopra elencate",
  "patient_pedia_knee_other_symptoms_q7":"Senza che il ginocchio sembri GONFIO",
  "patient_pedia_knee_other_symptoms_q7_a1":"Attività molto difficili",
  "patient_pedia_knee_other_symptoms_q7_a2":"Attività difficili",
  "patient_pedia_knee_other_symptoms_q7_a3":"Attività un po' difficili",
  "patient_pedia_knee_other_symptoms_q7_a4":"Attività leggere",
  "patient_pedia_knee_other_symptoms_q7_a5":"Non riesco a fare nessuna delle attività sopra elencate",
  "patient_pedia_knee_other_symptoms_q8":"Senza che il ginocchio sembri INSICURO O NON RIESCA A SOPPORTARE IL PESO",
  "patient_pedia_knee_other_symptoms_q8_a1":"Attività molto difficili",
  "patient_pedia_knee_other_symptoms_q8_a2":"Attività difficili",
  "patient_pedia_knee_other_symptoms_q8_a3":"Attività un po' difficili",
  "patient_pedia_knee_other_symptoms_q8_a5":"Non riesco a fare nessuna delle attività sopra elencate",
  "patient_pedia_knee_other_symptoms_q9":"La maggior parte del tempo",
  "patient_pedia_knee_other_symptoms_q9_a1":"Attività molto difficili",
  "patient_pedia_knee_other_symptoms_q9_a2":"Attività difficili",
  "patient_pedia_knee_other_symptoms_q9_a3":"Attività un po' difficili",
  "patient_pedia_knee_other_symptoms_q9_a4":"Attività leggere",
  "patient_pedia_knee_other_symptoms_q9_a5":"Non riesco a fare nessuna delle attività sopra elencate",
  "patient_pedia_knee_function":"Funzionalità",
  "patient_pedia_knee_function_instruction":"Quanto è stato difficile per te svolgere le seguenti attività a causa del suo ginocchio:",
  "patient_pedia_knee_function_instruction_0":"Facile",
  "patient_pedia_knee_function_instruction_1":"Un po difficile",
  "patient_pedia_knee_function_instruction_2":"Difficile",
  "patient_pedia_knee_function_instruction_3":"Molto difficile",
  "patient_pedia_knee_function_instruction_4":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_note":"Nota: Se non fai una determinata attività, immagina come si sentirebbe il tuo ginocchio se dovessi provare a farla",
  "patient_pedia_knee_function_q1":"Salire le scale",
  "patient_pedia_knee_function_q1_a1":"Facile",
  "patient_pedia_knee_function_q1_a2":"Un po difficile",
  "patient_pedia_knee_function_q1_a3":"Difficile",
  "patient_pedia_knee_function_q1_a4":"Molto difficile",
  "patient_pedia_knee_function_q1_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q2":"Scendere le scale",
  "patient_pedia_knee_function_q2_a1":"Facile",
  "patient_pedia_knee_function_q2_a2":"Un po difficile",
  "patient_pedia_knee_function_q2_a3":"Difficile",
  "patient_pedia_knee_function_q2_a4":"Molto difficile",
  "patient_pedia_knee_function_q2_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q3":"Inginocchiarsi sulla parte anteriore del ginocchio",
  "patient_pedia_knee_function_q3_a1":"Facile",
  "patient_pedia_knee_function_q3_a2":"Un po difficile",
  "patient_pedia_knee_function_q3_a3":"Difficile",
  "patient_pedia_knee_function_q3_a4":"Molto difficile",
  "patient_pedia_knee_function_q3_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q4":"Estendere completamente il ginocchio",
  "patient_pedia_knee_function_q4_a1":"Facile",
  "patient_pedia_knee_function_q4_a2":"Un po difficile",
  "patient_pedia_knee_function_q4_a3":"Difficile",
  "patient_pedia_knee_function_q4_a4":"Molto difficile",
  "patient_pedia_knee_function_q4_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q5":"Piegare completamente il ginocchio",
  "patient_pedia_knee_function_q5_a1":"Facile",
  "patient_pedia_knee_function_q5_a2":"Un po difficile",
  "patient_pedia_knee_function_q5_a3":"Difficile",
  "patient_pedia_knee_function_q5_a4":"Molto difficile",
  "patient_pedia_knee_function_q5_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q6":"Muovere il ginocchio subito dopo esserti svegliato al mattino ",
  "patient_pedia_knee_function_q6_a1":"Facile",
  "patient_pedia_knee_function_q6_a2":"Un po difficile",
  "patient_pedia_knee_function_q6_a3":"Difficile",
  "patient_pedia_knee_function_q6_a4":"Molto difficile",
  "patient_pedia_knee_function_q6_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q7":"Muovere il ginocchio più tardi nella giornata dopo essere stato sedentario per un po'",
  "patient_pedia_knee_function_q7_a1":"Facile",
  "patient_pedia_knee_function_q7_a2":"Un po difficile",
  "patient_pedia_knee_function_q7_a3":"Difficile",
  "patient_pedia_knee_function_q7_a4":"Molto difficile",
  "patient_pedia_knee_function_q7_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q8":"Accovacciarsi come un ricevitore di baseball ",
  "patient_pedia_knee_function_q8_a1":"Facile",
  "patient_pedia_knee_function_q8_a2":"Un po difficile",
  "patient_pedia_knee_function_q8_a3":"Difficile",
  "patient_pedia_knee_function_q8_a4":"Molto difficile",
  "patient_pedia_knee_function_q8_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q9":"Sedersi su una sedia con il ginocchio piegato e i piedi appoggiati a terra",
  "patient_pedia_knee_function_q9_a1":"Facile",
  "patient_pedia_knee_function_q9_a2":"Un po difficile",
  "patient_pedia_knee_function_q9_a3":"Difficile",
  "patient_pedia_knee_function_q9_a4":"Molto difficile",
  "patient_pedia_knee_function_q9_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q10":"Alzarsi da una sedia",
  "patient_pedia_knee_function_q10_a1":"Facile",
  "patient_pedia_knee_function_q10_a2":"Un po difficile",
  "patient_pedia_knee_function_q10_a3":"Difficile",
  "patient_pedia_knee_function_q10_a4":"Molto difficile",
  "patient_pedia_knee_function_q10_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q11":"Chinarti e raccogliere un oggetto da terra ",
  "patient_pedia_knee_function_q11_a1":"Facile",
  "patient_pedia_knee_function_q11_a2":"Un po difficile",
  "patient_pedia_knee_function_q11_a3":"Difficile",
  "patient_pedia_knee_function_q11_a4":"Molto difficile",
  "patient_pedia_knee_function_q11_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q12":"Correre durante attività di gioco o sportive ",
  "patient_pedia_knee_function_q12_a1":"Facile",
  "patient_pedia_knee_function_q12_a2":"Un po difficile",
  "patient_pedia_knee_function_q12_a3":"Difficile",
  "patient_pedia_knee_function_q12_a4":"Molto difficile",
  "patient_pedia_knee_function_q12_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q13":"Saltare durante attività di gioco o sportive ",
  "patient_pedia_knee_function_q13_a1":"Facile",
  "patient_pedia_knee_function_q13_a2":"Un po difficile",
  "patient_pedia_knee_function_q13_a3":"Difficile",
  "patient_pedia_knee_function_q13_a4":"Molto difficile",
  "patient_pedia_knee_function_q13_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q14":"Iniziare e fermarsi rapidamente durante attività di gioco o sportive ",
  "patient_pedia_knee_function_q14_a1":"Facile",
  "patient_pedia_knee_function_q14_a2":"Un po difficile",
  "patient_pedia_knee_function_q14_a3":"Difficile",
  "patient_pedia_knee_function_q14_a4":"Molto difficile",
  "patient_pedia_knee_function_q14_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q15":"Girare o ruotare durante attività di gioco o sportive ",
  "patient_pedia_knee_function_q15_a1":"Facile",
  "patient_pedia_knee_function_q15_a2":"Un po difficile",
  "patient_pedia_knee_function_q15_a3":"Difficile",
  "patient_pedia_knee_function_q15_a4":"Molto difficile",
  "patient_pedia_knee_function_q15_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q16":"Mantenere l'equilibrio quando si cammina o si corre su terreni irregolari",
  "patient_pedia_knee_function_q16_a1":"Facile",
  "patient_pedia_knee_function_q16_a2":"Un po difficile",
  "patient_pedia_knee_function_q16_a3":"Difficile",
  "patient_pedia_knee_function_q16_a4":"Molto difficile",
  "patient_pedia_knee_function_q16_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q17":"Fare sport ",
  "patient_pedia_knee_function_q17_a1":"Facile",
  "patient_pedia_knee_function_q17_a2":"Un po difficile",
  "patient_pedia_knee_function_q17_a3":"Difficile",
  "patient_pedia_knee_function_q17_a4":"Molto difficile",
  "patient_pedia_knee_function_q17_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q18":"Entrare o uscire dall'auto",
  "patient_pedia_knee_function_q18_a1":"Facile",
  "patient_pedia_knee_function_q18_a2":"Un po difficile",
  "patient_pedia_knee_function_q18_a3":"Difficile",
  "patient_pedia_knee_function_q18_a4":"Molto difficile",
  "patient_pedia_knee_function_q18_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q19":"Alzarsi dal letto",
  "patient_pedia_knee_function_q19_a1":"Facile",
  "patient_pedia_knee_function_q19_a2":"Un po difficile",
  "patient_pedia_knee_function_q19_a3":"Difficile",
  "patient_pedia_knee_function_q19_a4":"Molto difficile",
  "patient_pedia_knee_function_q19_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q20":"Cambiare posizione del ginocchio mentre si è sdraiati nel letto",
  "patient_pedia_knee_function_q20_a1":"Facile",
  "patient_pedia_knee_function_q20_a2":"Un po difficile",
  "patient_pedia_knee_function_q20_a3":"Difficile",
  "patient_pedia_knee_function_q20_a4":"Molto difficile",
  "patient_pedia_knee_function_q20_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q21":"Entrare o uscire dalla vasca o dalla doccia",
  "patient_pedia_knee_function_q21_a1":"Facile",
  "patient_pedia_knee_function_q21_a2":"Un po difficile",
  "patient_pedia_knee_function_q21_a3":"Difficile",
  "patient_pedia_knee_function_q21_a4":"Molto difficile",
  "patient_pedia_knee_function_q21_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q22":"Portare borse o zaini pesant ecc.",
  "patient_pedia_knee_function_q22_a1":"Facile",
  "patient_pedia_knee_function_q22_a2":"Un po difficile",
  "patient_pedia_knee_function_q22_a3":"Difficile",
  "patient_pedia_knee_function_q22_a4":"Molto difficile",
  "patient_pedia_knee_function_q22_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q23":"Svolgere attività domestiche leggere come pulire la stanza, riempire o svuotare la lavastoviglie, fare il letto ecc.",
  "patient_pedia_knee_function_q23_a1":"Facile",
  "patient_pedia_knee_function_q23_a2":"Un po difficile",
  "patient_pedia_knee_function_q23_a3":"Difficile",
  "patient_pedia_knee_function_q23_a4":"Molto difficile",
  "patient_pedia_knee_function_q23_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q24":"Arrivare a scuola o camminare in giro per la scuola (salire le scale, aprire le porte, portare libri, partecipare alla ricreazione)",
  "patient_pedia_knee_function_q24_a1":"Facile",
  "patient_pedia_knee_function_q24_a2":"Un po difficile",
  "patient_pedia_knee_function_q24_a3":"Difficile",
  "patient_pedia_knee_function_q24_a4":"Molto difficile",
  "patient_pedia_knee_function_q24_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q25":"Fare cose con gli amici ",
  "patient_pedia_knee_function_q25_a1":"Facile",
  "patient_pedia_knee_function_q25_a2":"Un po difficile",
  "patient_pedia_knee_function_q25_a3":"Difficile",
  "patient_pedia_knee_function_q25_a4":"Molto difficile",
  "patient_pedia_knee_function_q25_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_function_q26":"Quanto bene funzionava il tuo ginocchio PRIMA DI FARTI MALE?",
  "patient_pedia_knee_function_q26_instruction":"(su una scala da 0 a 10 dove 10 è normale e 0 è l'incapacità di svolgere qualsiasi attività quotidiana abituale, che può includere lo sport)",
  "patient_pedia_knee_function_q26_instruction_a1":"Non sono stato in grado di fare assolutamente nulla",
  "patient_pedia_knee_function_q26_instruction_a2":"Sono stato capace di fare tutto ciò che volevo",
  "patient_pedia_knee_function_q27":"Quanto bene funziona il tuo ginocchio ADESSO?",
  "patient_pedia_knee_function_q27_instruction":"Il numero 10 rappresenta la normalità e il numero 0 rappresenta l'incapacità di svolgere qualsiasi delle tue attività quotidiane abituali, incluso lo sport.",
  "patient_pedia_knee_function_q27_instruction_a1":"Non sono in grado di fare assolutamente niente",
  "patient_pedia_knee_function_q27_instruction_a2":"Sono in grado di fare qualsiasi cosa voglio fare",
  "patient_pedia_knee_function_q28":"La rotula esce dal suo alloggiamento o sembra che stia uscendo dal suo alloggiamento?",
  "patient_pedia_knee_function_q28_a1":"No ",
  "patient_pedia_knee_function_q28_a2":"Si",
  "patient_pedia_knee_instability":"Instabilità patellofemorale",
  "patient_pedia_knee_instability_instruction":"Scegli la descrizione che corrisponde MEGLIO a QUANTE VOLTE HA LA SENSATIONE CHE LA TUA ROTULA STIA PER \"SCATTARE\" DALL'ARTICOLAZIONE O SI SENTE INSTABILE mentre stai svolgendo ciascuna delle seguenti attività:",
  "patient_pedia_knee_instability_instruction_0":"Mai",
  "patient_pedia_knee_instability_instruction_1":"Quasi mai",
  "patient_pedia_knee_instability_instruction_2":"Qualche volta",
  "patient_pedia_knee_instability_instruction_3":"Spesso",
  "patient_pedia_knee_instability_instruction_4":"Sempre",
  "patient_pedia_knee_instability_note":"Nota: Per favore, risponda alle seguenti domande riguardanti le attività del suo ginocchio.",
  "patient_pedia_knee_instability_q1":"Girare o cambiare direzione durante sport e/o giochi",
  "patient_pedia_knee_instability_q1_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q1_a1":"Mai",
  "patient_pedia_knee_instability_q1_a2":"Quasi mai",
  "patient_pedia_knee_instability_q1_a3":"Qualche volta",
  "patient_pedia_knee_instability_q1_a4":"Spesso",
  "patient_pedia_knee_instability_q1_a5":"Sempre",
  "patient_pedia_knee_instability_q2":"Cambiare direzione durante la corsa",
  "patient_pedia_knee_instability_q2_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q2_a1":"Mai",
  "patient_pedia_knee_instability_q2_a2":"Quasi mai",
  "patient_pedia_knee_instability_q2_a3":"Qualche volta",
  "patient_pedia_knee_instability_q2_a4":"Spesso",
  "patient_pedia_knee_instability_q2_a5":"Sempre",
  "patient_pedia_knee_instability_q3":"Correre in linea retta su superfici irregolari",
  "patient_pedia_knee_instability_q3_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q3_a1":"Mai",
  "patient_pedia_knee_instability_q3_a2":"Quasi mai",
  "patient_pedia_knee_instability_q3_a3":"Qualche volta",
  "patient_pedia_knee_instability_q3_a4":"Spesso",
  "patient_pedia_knee_instability_q3_a5":"Sempre",
  "patient_pedia_knee_instability_q4":"Camminare su superfici scivolose bagnate o ghiacciate  ",
  "patient_pedia_knee_instability_q4_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q4_a1":"Mai",
  "patient_pedia_knee_instability_q4_a2":"Quasi mai",
  "patient_pedia_knee_instability_q4_a3":"Qualche volta",
  "patient_pedia_knee_instability_q4_a4":"Spesso",
  "patient_pedia_knee_instability_q4_a5":"Sempre",
  "patient_pedia_knee_instability_q5":"Correre lateralmente",
  "patient_pedia_knee_instability_q5_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q5_a1":"Mai",
  "patient_pedia_knee_instability_q5_a2":"Quasi mai",
  "patient_pedia_knee_instability_q5_a3":"Qualche volta",
  "patient_pedia_knee_instability_q5_a4":"Spesso",
  "patient_pedia_knee_instability_q5_a5":"Sempre",
  "patient_pedia_knee_instability_q6":"Saltellare",
  "patient_pedia_knee_instability_q6_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q6_a1":"Mai",
  "patient_pedia_knee_instability_q6_a2":"Quasi mai",
  "patient_pedia_knee_instability_q6_a3":"Qualche volta",
  "patient_pedia_knee_instability_q6_a4":"Spesso",
  "patient_pedia_knee_instability_q6_a5":"Sempre",
  "patient_pedia_knee_instability_q7":"Saltare",
  "patient_pedia_knee_instability_q7_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q7_a1":"Mai",
  "patient_pedia_knee_instability_q7_a2":"Quasi mai",
  "patient_pedia_knee_instability_q7_a3":"Qualche volta",
  "patient_pedia_knee_instability_q7_a4":"Spesso",
  "patient_pedia_knee_instability_q7_a5":"Sempre",
  "patient_pedia_knee_instability_q8":"Correre in linea retta su superfici regolari",
  "patient_pedia_knee_instability_q8_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q8_a1":"Mai",
  "patient_pedia_knee_instability_q8_a2":"Quasi mai",
  "patient_pedia_knee_instability_q8_a3":"Qualche volta",
  "patient_pedia_knee_instability_q8_a4":"Spesso",
  "patient_pedia_knee_instability_q8_a5":"Sempre",
  "patient_pedia_knee_instability_q9":"Scendere le scale",
  "patient_pedia_knee_instability_q9_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q9_a1":"Mai",
  "patient_pedia_knee_instability_q9_a2":"Quasi mai",
  "patient_pedia_knee_instability_q9_a3":"Qualche volta",
  "patient_pedia_knee_instability_q9_a4":"Spesso",
  "patient_pedia_knee_instability_q9_a5":"Sempre",
  "patient_pedia_knee_instability_q10":"Accovacciarsi",
  "patient_pedia_knee_instability_q10_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q10_a1":"Mai",
  "patient_pedia_knee_instability_q10_a2":"Quasi mai",
  "patient_pedia_knee_instability_q10_a3":"Qualche volta",
  "patient_pedia_knee_instability_q10_a4":"Spesso",
  "patient_pedia_knee_instability_q10_a5":"Sempre",
  "patient_pedia_knee_instability_q11":"Inginocchiarsi",
  "patient_pedia_knee_instability_q11_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q11_a1":"Mai",
  "patient_pedia_knee_instability_q11_a2":"Quasi mai",
  "patient_pedia_knee_instability_q11_a3":"Qualche volta",
  "patient_pedia_knee_instability_q11_a4":"Spesso",
  "patient_pedia_knee_instability_q11_a5":"Sempre",
  "patient_pedia_knee_instability_q12":"Camminare in linea retta su superfici irregolari",
  "patient_pedia_knee_instability_q12_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q12_a1":"Mai",
  "patient_pedia_knee_instability_q12_a2":"Quasi mai",
  "patient_pedia_knee_instability_q12_a3":"Qualche volta",
  "patient_pedia_knee_instability_q12_a4":"Spesso",
  "patient_pedia_knee_instability_q12_a5":"Sempre",
  "patient_pedia_knee_instability_q13":"Salire le scale",
  "patient_pedia_knee_instability_q13_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q13_a1":"Mai",
  "patient_pedia_knee_instability_q13_a2":"Quasi mai",
  "patient_pedia_knee_instability_q13_a3":"Qualche volta",
  "patient_pedia_knee_instability_q13_a4":"Spesso",
  "patient_pedia_knee_instability_q13_a5":"Sempre",
  "patient_pedia_knee_instability_q14":"Salire o superare un gradino alto",
  "patient_pedia_knee_instability_q14_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q14_a1":"Mai",
  "patient_pedia_knee_instability_q14_a2":"Quasi mai",
  "patient_pedia_knee_instability_q14_a3":"Qualche volta",
  "patient_pedia_knee_instability_q14_a4":"Spesso",
  "patient_pedia_knee_instability_q14_a5":"Sempre",
  "patient_pedia_knee_instability_q15":"Incrociare le gambe quando si è seduti",
  "patient_pedia_knee_instability_q15_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q15_a1":"Mai",
  "patient_pedia_knee_instability_q15_a2":"Quasi mai",
  "patient_pedia_knee_instability_q15_a3":"Qualche volta",
  "patient_pedia_knee_instability_q15_a4":"Spesso",
  "patient_pedia_knee_instability_q15_a5":"Sempre",
  "patient_pedia_knee_instability_q16":"Camminare in linea retta su superfici regolari",
  "patient_pedia_knee_instability_q16_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q16_a1":"Mai",
  "patient_pedia_knee_instability_q16_a2":"Quasi mai",
  "patient_pedia_knee_instability_q16_a3":"Qualche volta",
  "patient_pedia_knee_instability_q16_a4":"Spesso",
  "patient_pedia_knee_instability_q16_a5":"Sempre",
  "patient_pedia_knee_instability_q17":"Entrare o uscire da una macchina",
  "patient_pedia_knee_instability_q17_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q17_a1":"Mai",
  "patient_pedia_knee_instability_q17_a2":"Quasi mai",
  "patient_pedia_knee_instability_q17_a3":"Qualche volta",
  "patient_pedia_knee_instability_q17_a4":"Spesso",
  "patient_pedia_knee_instability_q17_a5":"Sempre",
  "patient_pedia_knee_instability_q18":"Manovrare un carrello pesante intorno ai corridoi di un supermercato",
  "patient_pedia_knee_instability_q18_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q18_a1":"Mai",
  "patient_pedia_knee_instability_q18_a2":"Quasi mai",
  "patient_pedia_knee_instability_q18_a3":"Qualche volta",
  "patient_pedia_knee_instability_q18_a4":"Spesso",
  "patient_pedia_knee_instability_q18_a5":"Sempre",
  "patient_pedia_knee_instability_q19":"Girarsi per guardare dietro la spalla",
  "patient_pedia_knee_instability_q19_do_not_do":"Non lo faccio",
  "patient_pedia_knee_instability_q19_a1":"Mai",
  "patient_pedia_knee_instability_q19_a2":"Quasi mai",
  "patient_pedia_knee_instability_q19_a3":"Qualche volta",
  "patient_pedia_knee_instability_q19_a4":"Spesso",
  "patient_pedia_knee_instability_q19_a5":"Sempre",
  "patient_pedia_knee_quality_of_life":"Qualità della vita",
  "patient_pedia_knee_quality_of_life_instruction":"Come l'infortunio ha cambiato la tua vita?",
  "patient_pedia_knee_quality_of_life_q1":"Quante volte pensi al tuo problema al ginocchio?",
  "patient_pedia_knee_quality_of_life_q1_a1":"Mai",
  "patient_pedia_knee_quality_of_life_q1_a2":"Quasi mai",
  "patient_pedia_knee_quality_of_life_q1_a3":"Qualche volta",
  "patient_pedia_knee_quality_of_life_q1_a4":"Spesso",
  "patient_pedia_knee_quality_of_life_q1_a5":"Sempre",
  "patient_pedia_knee_problem_q2":"In che modo la tua vita è cambiata a causa del ginocchio infortunato?",
  "patient_pedia_knee_problem_q2_a1":"Per niente",
  "patient_pedia_knee_problem_q2_a2":"Un po'",
  "patient_pedia_knee_problem_q2_a3":"Abbastanza",
  "patient_pedia_knee_problem_q2_a4":"Molto",
  "patient_pedia_knee_problem_q2_a5":"Tanto",
  "patient_pedia_knee_problem_q3":"Quanto ti fidi del tuo ginocchio infortunato?",
  "patient_pedia_knee_problem_q3_a1":"Completamente",
  "patient_pedia_knee_problem_q3_a2":"Molto",
  "patient_pedia_knee_problem_q3_a3":"Alcune volte",
  "patient_pedia_knee_problem_q3_a4":"Un po'",
  "patient_pedia_knee_problem_q3_a5":"Per niente",
  "patient_pedia_knee_problem_q4":"Complessivamente, quanto ti è difficile muoverti con il ginocchio infortunato?",
  "patient_pedia_knee_problem_q4_a1":"Nessuna difficoltà",
  "patient_pedia_knee_problem_q4_a2":"Un po",
  "patient_pedia_knee_problem_q4_a3":"Qualche",
  "patient_pedia_knee_problem_q4_a4":"Molto",
  "patient_pedia_knee_problem_q4_a5":"Estremamente difficile, non posso farlo",
  "patient_pedia_knee_satisfaction":"Soddisfazione",
  "patient_pedia_knee_satisfaction_instruction":"Sei contento dei risultati della tua chirurgia o del trattamento al ginocchio?",
  "patient_pedia_knee_satisfaction_instruction_a1":"Molto insoddisfatto",
  "patient_pedia_knee_satisfaction_instruction_a2":"Abbastanza  insoddisfatto",
  "patient_pedia_knee_satisfaction_instruction_a3":"Abbastanza soddisfatto",
  "patient_pedia_knee_satisfaction_instruction_a4":"Molto soddisfatto",
  "patient_pedia_knee_satisfaction_q1":"Complessivamente",
  "patient_pedia_knee_satisfaction_q1_NA":"Non applicabile",
  "patient_pedia_knee_satisfaction_q1_a1":"Molto insoddisfatto",
  "patient_pedia_knee_satisfaction_q1_a2":"Abbastanza  insoddisfatto",
  "patient_pedia_knee_satisfaction_q1_a3":"Abbastanza soddisfatto",
  "patient_pedia_knee_satisfaction_q1_a4":"Molto soddisfatto",
  "patient_pedia_knee_satisfaction_q2":"Per migliorare il dolore? ",
  "patient_pedia_knee_satisfaction_q2_NA":"Non applicabile",
  "patient_pedia_knee_satisfaction_q2_a1":"Molto insoddisfatto",
  "patient_pedia_knee_satisfaction_q2_a2":"Abbastanza  insoddisfatto",
  "patient_pedia_knee_satisfaction_q2_a3":"Abbastanza soddisfatto",
  "patient_pedia_knee_satisfaction_q2_a4":"Molto soddisfatto",
  "patient_pedia_knee_satisfaction_q3":"Per migliorare la sua capacità di fare lavori domestici o di giardinaggio?",
  "patient_pedia_knee_satisfaction_q3_NA":"Non applicabile",
  "patient_pedia_knee_satisfaction_q3_a1":"Molto insoddisfatto",
  "patient_pedia_knee_satisfaction_q3_a2":"Abbastanza  insoddisfatto",
  "patient_pedia_knee_satisfaction_q3_a3":"Abbastanza soddisfatto",
  "patient_pedia_knee_satisfaction_q3_a4":"Molto soddisfatto",
  "patient_pedia_knee_satisfaction_q4":"Per migliorare la sua capacità di fare attività ricreative? ",
  "patient_pedia_knee_satisfaction_q4_NA":"Non applicabile",
  "patient_pedia_knee_satisfaction_q4_a1":"Molto insoddisfatto",
  "patient_pedia_knee_satisfaction_q4_a2":"Abbastanza  insoddisfatto",
  "patient_pedia_knee_satisfaction_q4_a3":"Abbastanza soddisfatto",
  "patient_pedia_knee_satisfaction_q4_a4":"Molto soddisfatto",
  "patient_pedia_knee_complete":"Fine del questionario",
  "patient_pedia_knee_complete_text1":"Grazie di cuore per aver preso il tempo di rispondere alle domande in questo modulo.",
  "patient_pedia_knee_complete_text2":"Ora, per favore, premi il pulsante \"Invia\" per finire e vedere i tuoi punteggi.",
  "patient_pedia_knee_complete_text3":"Le tue risposte saranno tenute segrete e nessuno saprà che sei tu a risponderle.",
  "patient_pedia_knee_results":"Risultati",
  "patient_pedia_knee_results_text1":"Di seguito sono riportati i tuoi punteggi per il dolore e la funzionalità del ginocchio, calcolati dal questionario.",
  "patient_pedia_knee_results_pain":"Male",
  "patient_pedia_knee_results_function":"Funzionalità",
  "patient_pedia_knee_results_self_evaluation":"Punteggio dell'autovalutazione del ginocchio",
  "patient_pedia_knee_results_text2":"Il tuo medico sarà con te a breve.",
  "patient_foot_ankle_pain":"Dolore",
  "patient_foot_ankle_pain_q1":"Qual è il LIVELLO ATTUALE DI DOLORE nel suo piede o nella sua caviglia?",
  "patient_foot_ankle_pain_q1_0":"Nessun dolore",
  "patient_foot_ankle_pain_q1_1":"Il peggiore dolore immaginabile",
  "patient_foot_ankle_pain_q2":"Quale è la MIGLIORE DESCRIZIONE DEL DOLORE dal suo piede o dalla sua caviglia?",
  "patient_foot_ankle_pain_q2_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q2_a2":"Lieve, occasionale",
  "patient_foot_ankle_pain_q2_a3":"Moderato, quotidiano",
  "patient_foot_ankle_pain_q2_a4":"Intenso, quasi sempre presente",
  "patient_foot_ankle_pain_q3":"Quanto spesso sperimenta dolore al piede o alla caviglia?",
  "patient_foot_ankle_pain_q3_a1":"Mai",
  "patient_foot_ankle_pain_q3_a2":"Mensilmente",
  "patient_foot_ankle_pain_q3_a3":"Settimanalmente",
  "patient_foot_ankle_pain_q3_a4":"Quotidianamente",
  "patient_foot_ankle_pain_q3_a5":"Sempre",
  "patient_foot_ankle_pain_instruction":"Quanto dolore al PIEDI e/o ALLA CAVIGLIA ha sperimentato durante le seguenti attività:",
  "patient_foot_ankle_pain_instruction_0":"Nessun dolore",
  "patient_foot_ankle_pain_instruction_1":"Lieve",
  "patient_foot_ankle_pain_instruction_2":"Moderato",
  "patient_foot_ankle_pain_instruction_3":"Intenso",
  "patient_foot_ankle_pain_instruction_4":"Estremo",
  "patient_foot_ankle_pain_q4":"Livello generale di dolore",
  "patient_foot_ankle_pain_q4_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q4_a2":"Lieve",
  "patient_foot_ankle_pain_q4_a3":"Moderato",
  "patient_foot_ankle_pain_q4_a4":"Intenso",
  "patient_foot_ankle_pain_q4_a5":"Estremo",
  "patient_foot_ankle_pain_q5":"A riposo",
  "patient_foot_ankle_pain_q5_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q5_a2":"Lieve",
  "patient_foot_ankle_pain_q5_a3":"Moderato",
  "patient_foot_ankle_pain_q5_a4":"Intenso",
  "patient_foot_ankle_pain_q5_a5":"Estremo",
  "patient_foot_ankle_pain_q6":"Durante l'attività normale",
  "patient_foot_ankle_pain_q6_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q6_a2":"Lieve",
  "patient_foot_ankle_pain_q6_a3":"Moderato",
  "patient_foot_ankle_pain_q6_a4":"Intenso",
  "patient_foot_ankle_pain_q6_a5":"Estremo",
  "patient_foot_ankle_pain_q7":"Al mattino appena svegli",
  "patient_foot_ankle_pain_q7_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q7_a2":"Lieve",
  "patient_foot_ankle_pain_q7_a3":"Moderato",
  "patient_foot_ankle_pain_q7_a4":"Intenso",
  "patient_foot_ankle_pain_q7_a5":"Estremo",
  "patient_foot_ankle_pain_q8":"Rotazione o avvitamento sul piede e/o sulla caviglia",
  "patient_foot_ankle_pain_q8_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q8_a2":"Lieve",
  "patient_foot_ankle_pain_q8_a3":"Moderato",
  "patient_foot_ankle_pain_q8_a4":"Intenso",
  "patient_foot_ankle_pain_q8_a5":"Estremo",
  "patient_foot_ankle_pain_q9":"Allungando completamente il piede e/o la caviglia",
  "patient_foot_ankle_pain_q9_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q9_a2":"Lieve",
  "patient_foot_ankle_pain_q9_a3":"Moderato",
  "patient_foot_ankle_pain_q9_a4":"Intenso",
  "patient_foot_ankle_pain_q9_a5":"Estremo",
  "patient_foot_ankle_pain_q10":"Piegando completamente il piede e/o la caviglia",
  "patient_foot_ankle_pain_q10_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q10_a2":"Lieve",
  "patient_foot_ankle_pain_q10_a3":"Moderato",
  "patient_foot_ankle_pain_q10_a4":"Intenso",
  "patient_foot_ankle_pain_q10_a5":"Estremo",
  "patient_foot_ankle_pain_q11":"Camminando su una superficie piana",
  "patient_foot_ankle_pain_q11_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q11_a2":"Lieve",
  "patient_foot_ankle_pain_q11_a3":"Moderato",
  "patient_foot_ankle_pain_q11_a4":"Intenso",
  "patient_foot_ankle_pain_q11_a5":"Estremo",
  "patient_foot_ankle_pain_q12":"Salendo o scendendo le scale",
  "patient_foot_ankle_pain_q12_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q12_a2":"Lieve",
  "patient_foot_ankle_pain_q12_a3":"Moderato",
  "patient_foot_ankle_pain_q12_a4":"Intenso",
  "patient_foot_ankle_pain_q12_a5":"Estremo",
  "patient_foot_ankle_pain_q13":"Di notte a letto",
  "patient_foot_ankle_pain_q13_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q13_a2":"Lieve",
  "patient_foot_ankle_pain_q13_a3":"Moderato",
  "patient_foot_ankle_pain_q13_a4":"Intenso",
  "patient_foot_ankle_pain_q13_a5":"Estremo",
  "patient_foot_ankle_pain_q14":"Sedersi o distendersi ",
  "patient_foot_ankle_pain_q14_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q14_a2":"Lieve",
  "patient_foot_ankle_pain_q14_a3":"Moderato",
  "patient_foot_ankle_pain_q14_a4":"Intenso",
  "patient_foot_ankle_pain_q14_a5":"Estremo",
  "patient_foot_ankle_pain_q15":"In posizione eretta",
  "patient_foot_ankle_pain_q15_a1":"Nessun dolore",
  "patient_foot_ankle_pain_q15_a2":"Lieve",
  "patient_foot_ankle_pain_q15_a3":"Moderato",
  "patient_foot_ankle_pain_q15_a4":"Intenso",
  "patient_foot_ankle_pain_q15_a5":"Estremo",
  "patient_foot_ankle_instability":"Instabilità",
  "patient_foot_ankle_instability_q1":"Sperimenta ripetuti strappi, cedimenti della caviglia o la sensazione che stia per cedere?",
  "patient_foot_ankle_instability_q1_a1":"No ",
  "patient_foot_ankle_instability_q1_a2":"Si",
  "patient_foot_ankle_instability_q2":"È mai stato diagnosticato o trattato per instabilità alla caviglia o strappi ricorrenti?",
  "patient_foot_ankle_instability_q2_a1":"No ",
  "patient_foot_ankle_instability_q2_a2":"Si",
  "patient_foot_ankle_instability_q3":"La sua caviglia non interessata è completamente stabile?",
  "patient_foot_ankle_instability_q3_a1":"No ",
  "patient_foot_ankle_instability_q3_a2":"Si",
  "patient_foot_ankle_cumberland":"CUMBERLAND ANKLE INSTABILITY TOOL",
  "patient_foot_ankle_cumberland_instruction":"Per favore, scelga una delle seguenti affermazioni in ogni domanda che descrive meglio la sua caviglia.",
  "patient_foot_ankle_cumberland_q1":"Ho dolore alla caviglia",
  "patient_foot_ankle_cumberland_q1_a1":"Mai",
  "patient_foot_ankle_cumberland_q1_a2":"Durante lo sport",
  "patient_foot_ankle_cumberland_q1_a3":"Correndo su superfici irregolari",
  "patient_foot_ankle_cumberland_q1_a4":"Correndo su superfici piane",
  "patient_foot_ankle_cumberland_q1_a5":"Camminando su superfici irregolari",
  "patient_foot_ankle_cumberland_q1_a6":"Camminando su superfici piane",
  "patient_foot_ankle_cumberland_q2":"La mia caviglia si sente INSTABILE",
  "patient_foot_ankle_cumberland_q2_a1":"Mai",
  "patient_foot_ankle_cumberland_q2_a2":"A volte durante lo sport (non ogni volta)",
  "patient_foot_ankle_cumberland_q2_a3":"Frequentemente durante lo sport (ogni volta)",
  "patient_foot_ankle_cumberland_q2_a4":"A volte durante l'attività quotidiana",
  "patient_foot_ankle_cumberland_q2_a5":"Frequentemente durante l'attività quotidiana",
  "patient_foot_ankle_cumberland_q3":"Quando faccio virate BRUSCHE, la mia caviglia si sente INSTABILE",
  "patient_foot_ankle_cumberland_q3_a1":"Mai",
  "patient_foot_ankle_cumberland_q3_a2":"A volte durante la corsa",
  "patient_foot_ankle_cumberland_q3_a3":"Spesso durante la corsa",
  "patient_foot_ankle_cumberland_q3_a4":"Durante la camminata",
  "patient_foot_ankle_cumberland_q4":"Scendendo le scale la mia caviglia risulta INSTABILE",
  "patient_foot_ankle_cumberland_q4_a1":"Mai",
  "patient_foot_ankle_cumberland_q4_a2":"Se vado veloce",
  "patient_foot_ankle_cumberland_q4_a3":"Occasionalmente",
  "patient_foot_ankle_cumberland_q4_a4":"Sempre",
  "patient_foot_ankle_cumberland_q5":"La mia caviglia risulta INSTABILE quando sto in piedi su UNA gamba",
  "patient_foot_ankle_cumberland_q5_a1":"Mai",
  "patient_foot_ankle_cumberland_q5_a2":"Sulla pianta del piede",
  "patient_foot_ankle_cumberland_q5_a3":"Con il piede appoggiato",
  "patient_foot_ankle_cumberland_q6":"La mia caviglia risulta instabile quando",
  "patient_foot_ankle_cumberland_q6_a1":"Mai",
  "patient_foot_ankle_cumberland_q6_a2":"Salto da un lato all'altro",
  "patient_foot_ankle_cumberland_q6_a3":"Salto sul posto",
  "patient_foot_ankle_cumberland_q6_a4":"Salto",
  "patient_foot_ankle_cumberland_q7":"La mia caviglia risulta instabile quando",
  "patient_foot_ankle_cumberland_q7_a1":"Mai",
  "patient_foot_ankle_cumberland_q7_a2":"Corro su superfici irregolari",
  "patient_foot_ankle_cumberland_q7_a3":"Corro ad andatura limitata su superfici irregolari",
  "patient_foot_ankle_cumberland_q7_a4":"Cammino su superfici irregolari",
  "patient_foot_ankle_cumberland_q7_a5":"Cammino su superfici piane",
  "patient_foot_ankle_cumberland_q8":"Di solito, quando inizio a ruotare (o \"contorcere\") la mia caviglia, sono in grado di fermarla",
  "patient_foot_ankle_cumberland_q8_a1":"Immediatamente",
  "patient_foot_ankle_cumberland_q8_a2":"Spesso",
  "patient_foot_ankle_cumberland_q8_a3":"A volte",
  "patient_foot_ankle_cumberland_q8_a4":"Mai",
  "patient_foot_ankle_cumberland_q8_a5":"Non ho mai subito una distorsione alla caviglia",
  "patient_foot_ankle_cumberland_q9":"Dopo un INCIDENTE TIPICO in cui la mia caviglia si è girata, la mia caviglia torna alla \"normalità\"",
  "patient_foot_ankle_cumberland_q9_a1":"Quasi immediatamente",
  "patient_foot_ankle_cumberland_q9_a2":"Meno di un giorno",
  "patient_foot_ankle_cumberland_q9_a3":"1-2 giorni",
  "patient_foot_ankle_cumberland_q9_a4":"Più di 2 giorni",
  "patient_foot_ankle_cumberland_q9_a5":"Non mi sono mai girato la caviglia",
  "patient_foot_ankle_other_symptoms":"Altri sintomi ",
  "patient_foot_ankle_other_symptoms_instruction1":"Per favore, scelga la risposta che corrisponde meglio ai sintomi del suo piede e/o della sua caviglia.",
  "patient_foot_ankle_other_symptoms_instruction2":"Se non è sicuro su come rispondere a una domanda, la preghiamo di fornire la risposta che ritiene sia più adatta.",
  "patient_foot_ankle_other_symptoms_note":"Nota: queste domande dovrebbero essere risposte pensando ai sintomi del suo piede e/o della sua caviglia durante l'ultima settimana.",
  "patient_foot_ankle_other_symptoms_instruction3":"Presenta i seguenti ",
  "patient_foot_ankle_other_symptoms_instruction3_0":"Mai",
  "patient_foot_ankle_other_symptoms_instruction3_1":"Raramente",
  "patient_foot_ankle_other_symptoms_instruction3_2":"A volte",
  "patient_foot_ankle_other_symptoms_instruction3_3":"Spesso",
  "patient_foot_ankle_other_symptoms_instruction3_4":"Sempre",
  "patient_foot_ankle_other_symptoms_q1":"Edema al piede / alla caviglia",
  "patient_foot_ankle_other_symptoms_q1_a1":"Mai",
  "patient_foot_ankle_other_symptoms_q1_a2":"Raramente",
  "patient_foot_ankle_other_symptoms_q1_a3":"A volte",
  "patient_foot_ankle_other_symptoms_q1_a4":"Spesso",
  "patient_foot_ankle_other_symptoms_q1_a5":"Sempre",
  "patient_foot_ankle_other_symptoms_q2":"Scrocchio, clic o qualsiasi altro tipo di rumore proveniente dal piede o dalla caviglia",
  "patient_foot_ankle_other_symptoms_q2_a1":"Mai",
  "patient_foot_ankle_other_symptoms_q2_a2":"Raramente",
  "patient_foot_ankle_other_symptoms_q2_a3":"A volte",
  "patient_foot_ankle_other_symptoms_q2_a4":"Spesso",
  "patient_foot_ankle_other_symptoms_q2_a5":"Sempre",
  "patient_foot_ankle_other_symptoms_q3":"Blocco o arresto durante il movimento",
  "patient_foot_ankle_other_symptoms_q3_a1":"Mai",
  "patient_foot_ankle_other_symptoms_q3_a2":"Raramente",
  "patient_foot_ankle_other_symptoms_q3_a3":"A volte",
  "patient_foot_ankle_other_symptoms_q3_a4":"Spesso",
  "patient_foot_ankle_other_symptoms_q3_a5":"Sempre",
  "patient_foot_ankle_other_symptoms_instruction4":"Quanto è grave la RIGIDITÀ delle suo piede o della sua caviglia?",
  "patient_foot_ankle_other_symptoms_instruction4_0":"Nessuna",
  "patient_foot_ankle_other_symptoms_instruction4_1":"Leggera",
  "patient_foot_ankle_other_symptoms_instruction4_2":"Moderata",
  "patient_foot_ankle_other_symptoms_instruction4_3":"Grave",
  "patient_foot_ankle_other_symptoms_instruction4_4":"Estrema",
  "patient_foot_ankle_other_symptoms_q4":"Dopo il primo risveglio al mattino",
  "patient_foot_ankle_other_symptoms_q4_a1":"Nessuna",
  "patient_foot_ankle_other_symptoms_q4_a2":"Leggera",
  "patient_foot_ankle_other_symptoms_q4_a3":"Moderata",
  "patient_foot_ankle_other_symptoms_q4_a4":"Grave",
  "patient_foot_ankle_other_symptoms_q4_a5":"Estrema",
  "patient_foot_ankle_other_symptoms_q5":"Dopo esserti seduto, disteso o riposato nel corso della giornata",
  "patient_foot_ankle_other_symptoms_q5_a1":"Nessuna",
  "patient_foot_ankle_other_symptoms_q5_a2":"Leggera",
  "patient_foot_ankle_other_symptoms_q5_a3":"Moderata",
  "patient_foot_ankle_other_symptoms_q5_a4":"Grave",
  "patient_foot_ankle_other_symptoms_q5_a5":"Estrema",
  "patient_foot_ankle_function":"Funzionalità",
  "patient_foot_ankle_function_q1":"Quali limitazioni ha nelle attività della vita quotidiana a causa del suo piede e della sua caviglia?",
  "patient_foot_ankle_function_q1_a1":"Nessuna limitazione, nessun supporto",
  "patient_foot_ankle_function_q1_a2":"Nessuna limitazione delle attività quotidiane, limitazione delle attività ricreative, nessun supporto",
  "patient_foot_ankle_function_q1_a3":"Limitazione delle attività quotidiane e ricreative, bastone",
  "patient_foot_ankle_function_q1_a4":"Notevole limitazione delle attività quotidiane e ricreative, deambulatore, stampelle, sedia a rotelle, tutori",
  "patient_foot_ankle_function_q2":"Di solito, che tipo di calzature indossi?",
  "patient_foot_ankle_function_q2_a1":"Calzature alla moda, tradizionali, senza bisogno di solette",
  "patient_foot_ankle_function_q2_a2":"Calzature comode, solette",
  "patient_foot_ankle_function_q2_a3":"Calzature modificate o tutori",
  "patient_foot_ankle_function_q3":"Su quale tipo di superficie riscontri difficoltà nel camminare?",
  "patient_foot_ankle_function_q3_a1":"Nessuna difficoltà su qualsiasi superficie",
  "patient_foot_ankle_function_q3_a2":"Alcune difficoltà su terreno irregolare, scale, salite, scale a pioli",
  "patient_foot_ankle_function_q3_a3":"Gravi difficoltà su terreno irregolare, scale, salite, scale a pioli",
  "patient_foot_ankle_function_instruction":"Indicare il GRADO DI DIFFICOLTÀ che ha riscontrato A CAUSA DEL SUO PIEDE e/o della SUA CAVIGLIA.",
  "patient_foot_ankle_function_instruction_0":"Nessuna difficoltà",
  "patient_foot_ankle_function_instruction_1":"Leggera difficoltà",
  "patient_foot_ankle_function_instruction_2":"Difficoltà moderata",
  "patient_foot_ankle_function_instruction_3":"Difficoltà estrema",
  "patient_foot_ankle_function_instruction_4":"Incapace",
  "patient_foot_ankle_function_note":"Nota: Se l'attività in questione è limitata da qualcosa diverso dal suo piede o dalla sua caviglia, segni 'Non Applicabile' negli elementi in cui questa è un'opzione. Altrimenti, per favore risponda a ogni domanda con una risposta che descriva meglio la sua condizione nell'ultima settimana.",
  "patient_foot_ankle_function_q4":"Estensione completa del piede e/o della caviglia",
  "patient_foot_ankle_function_q4_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q4_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q4_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q4_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q4_a5":"Incapace",
  "patient_foot_ankle_function_q5":"Flessione completa del piede e/o della caviglia",
  "patient_foot_ankle_function_q5_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q5_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q5_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q5_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q5_a5":"Incapace",
  "patient_foot_ankle_function_q6":"Scendere le scale",
  "patient_foot_ankle_function_q6_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q6_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q6_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q6_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q6_a5":"Incapace",
  "patient_foot_ankle_function_q7":"Salire le scale",
  "patient_foot_ankle_function_q7_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q7_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q7_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q7_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q7_a5":"Incapace",
  "patient_foot_ankle_function_q8":"Alzarsi dalla sedia",
  "patient_foot_ankle_function_q8_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q8_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q8_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q8_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q8_a5":"Incapace",
  "patient_foot_ankle_function_q9":"Stare in piedi",
  "patient_foot_ankle_function_q9_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q9_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q9_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q9_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q9_a5":"Incapace",
  "patient_foot_ankle_function_q10":"Piegarsi per prendere un oggetto dal pavimento",
  "patient_foot_ankle_function_q10_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q10_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q10_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q10_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q10_a5":"Incapace",
  "patient_foot_ankle_function_q11":"Camminare su una superficie piana",
  "patient_foot_ankle_function_q11_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q11_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q11_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q11_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q11_a5":"Incapace",
  "patient_foot_ankle_function_q12":"Qual è la distanza massima che puoi camminare?",
  "patient_foot_ankle_function_q12_a1":"Più di 6 isolati",
  "patient_foot_ankle_function_q12_a2":"4-6 isolati",
  "patient_foot_ankle_function_q12_a3":"1-3 isolati",
  "patient_foot_ankle_function_q12_a4":"Meno di 1 isolati",
  "patient_foot_ankle_function_q13":"Camminare su superficie piana senza scarpe",
  "patient_foot_ankle_function_q13_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q13_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q13_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q13_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q13_a5":"Incapace",
  "patient_foot_ankle_function_q14":"Salire le colline",
  "patient_foot_ankle_function_q14_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q14_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q14_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q14_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q14_a5":"Incapace",
  "patient_foot_ankle_function_q15":"Scendere le colline",
  "patient_foot_ankle_function_q15_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q15_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q15_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q15_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q15_a5":"Incapace",
  "patient_foot_ankle_function_q16":"Camminare su superficie irregolare",
  "patient_foot_ankle_function_q16_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q16_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q16_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q16_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q16_a5":"Incapace",
  "patient_foot_ankle_function_q17":"Salire e scendere i cordoli",
  "patient_foot_ankle_function_q17_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q17_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q17_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q17_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q17_a5":"Incapace",
  "patient_foot_ankle_function_q18":"Sollevarsi sulle dita dei piedi",
  "patient_foot_ankle_function_q18_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q18_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q18_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q18_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q18_a5":"Incapace",
  "patient_foot_ankle_function_q19":"Camminare inizialmente",
  "patient_foot_ankle_function_q19_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q19_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q19_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q19_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q19_a5":"Incapace",
  "patient_foot_ankle_function_q20":"Camminare 5 minuti o meno",
  "patient_foot_ankle_function_q20_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q20_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q20_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q20_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q20_a5":"Incapace",
  "patient_foot_ankle_function_q21":"Camminare per circa 10 minuti",
  "patient_foot_ankle_function_q21_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q21_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q21_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q21_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q21_a5":"Incapace",
  "patient_foot_ankle_function_q22":"Camminare per 15 minuti o più a lungo",
  "patient_foot_ankle_function_q22_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q22_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q22_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q22_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q22_a5":"Incapace",
  "patient_foot_ankle_function_q23":"Entrare o uscire dall'auto",
  "patient_foot_ankle_function_q23_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q23_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q23_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q23_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q23_a5":"Incapace",
  "patient_foot_ankle_function_q24":"Fare la spesa",
  "patient_foot_ankle_function_q24_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q24_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q24_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q24_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q24_a5":"Incapace",
  "patient_foot_ankle_function_q25":"Mettere le calze",
  "patient_foot_ankle_function_q25_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q25_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q25_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q25_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q25_a5":"Incapace",
  "patient_foot_ankle_function_q26":"Togliere le calze",
  "patient_foot_ankle_function_q26_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q26_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q26_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q26_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q26_a5":"Incapace",
  "patient_foot_ankle_function_q27":"Alzarsi dal letto",
  "patient_foot_ankle_function_q27_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q27_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q27_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q27_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q27_a5":"Incapace",
  "patient_foot_ankle_function_q28":"Disteso a letto (girarsi mantenendo la posizione del piede e/o della caviglia)",
  "patient_foot_ankle_function_q28_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q28_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q28_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q28_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q28_a5":"Incapace",
  "patient_foot_ankle_function_q29":"Dormire",
  "patient_foot_ankle_function_q29_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q29_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q29_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q29_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q29_a5":"Incapace",
  "patient_foot_ankle_function_q30":"Entrare ed uscire dalla vasca da bagno",
  "patient_foot_ankle_function_q30_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q30_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q30_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q30_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q30_a5":"Incapace",
  "patient_foot_ankle_function_q31":"Sedersi",
  "patient_foot_ankle_function_q31_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q31_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q31_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q31_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q31_a5":"Incapace",
  "patient_foot_ankle_function_q32":"Sedersi e alzarsi dal water",
  "patient_foot_ankle_function_q32_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q32_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q32_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q32_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q32_a5":"Incapace",
  "patient_foot_ankle_function_q33":"Responsabilità domestiche",
  "patient_foot_ankle_function_q33_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q33_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q33_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q33_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q33_a5":"Incapace",
  "patient_foot_ankle_function_q34":"Attività quotidiane",
  "patient_foot_ankle_function_q34_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q34_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q34_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q34_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q34_a5":"Incapace",
  "patient_foot_ankle_function_q35":"Cura personale (compresa l'igiene personale e vestirsi da soli)",
  "patient_foot_ankle_function_q35_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q35_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q35_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q35_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q35_a5":"Incapace",
  "patient_foot_ankle_function_q36":"Lavori pesante (spingere o tirare, arrampicarsi, trasportare scatole pesanti, lavare i pavimenti, ecc.)",
  "patient_foot_ankle_function_q36_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q36_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q36_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q36_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q36_a5":"Incapace",
  "patient_foot_ankle_function_q37":"Mansioni domestiche leggere (cucinare, spolverare, ecc.)",
  "patient_foot_ankle_function_q37_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q37_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q37_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q37_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q37_a5":"Incapace",
  "patient_foot_ankle_function_q38":"Lavori leggeri o moderati (stare in piedi, camminare)",
  "patient_foot_ankle_function_q38_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q38_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q38_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q38_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q38_a5":"Incapace",
  "patient_foot_ankle_function_q39":"Attività ricreative",
  "patient_foot_ankle_function_q39_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q39_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q39_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q39_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q39_a5":"Incapace",
  "patient_foot_ankle_function_q40":"Accovacciarsi",
  "patient_foot_ankle_function_q40_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q40_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q40_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q40_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q40_a5":"Incapace",
  "patient_foot_ankle_function_q41":"Torsione o rotazione sul piede o sulla caviglia ",
  "patient_foot_ankle_function_q41_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q41_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q41_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q41_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q41_a5":"Incapace",
  "patient_foot_ankle_function_q42":"Inginocchiarsi",
  "patient_foot_ankle_function_q42_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q42_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q42_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q42_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q42_a5":"Incapace",
  "patient_foot_ankle_function_q43":"Correre",
  "patient_foot_ankle_function_q43_not_applicable":"Non applicabile",
  "patient_foot_ankle_function_q43_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q43_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q43_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q43_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q43_a5":"Incapace",
  "patient_foot_ankle_function_q44":"Saltare",
  "patient_foot_ankle_function_q44_not_applicable":"Non applicabile",
  "patient_foot_ankle_function_q44_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q44_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q44_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q44_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q44_a5":"Incapace",
  "patient_foot_ankle_function_q45":"Atterrare",
  "patient_foot_ankle_function_q45_not_applicable":"Non applicabile",
  "patient_foot_ankle_function_q45_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q45_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q45_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q45_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q45_a5":"Incapace",
  "patient_foot_ankle_function_q46":"Partire e fermarsi rapidamente",
  "patient_foot_ankle_function_q46_not_applicable":"Non applicabile",
  "patient_foot_ankle_function_q46_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q46_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q46_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q46_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q46_a5":"Incapace",
  "patient_foot_ankle_function_q47":"Movimenti di cambio direzione e laterali",
  "patient_foot_ankle_function_q47_not_applicable":"Non applicabile",
  "patient_foot_ankle_function_q47_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q47_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q47_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q47_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q47_a5":"Incapace",
  "patient_foot_ankle_function_q48":"Attività a basso impatto",
  "patient_foot_ankle_function_q48_not_applicable":"Non applicabile",
  "patient_foot_ankle_function_q48_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q48_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q48_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q48_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q48_a5":"Incapace",
  "patient_foot_ankle_function_q49":"Capacità di eseguire l'attività con la propria tecnica ",
  "patient_foot_ankle_function_q49_not_applicable":"Non applicabile",
  "patient_foot_ankle_function_q49_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q49_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q49_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q49_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q49_a5":"Incapace",
  "patient_foot_ankle_function_q50":"Capacità di praticare il proprio sport preferito quanto si vuole ",
  "patient_foot_ankle_function_q50_not_applicable":"Non applicabile",
  "patient_foot_ankle_function_q50_a1":"Nessuna difficoltà",
  "patient_foot_ankle_function_q50_a2":"Leggera difficoltà",
  "patient_foot_ankle_function_q50_a3":"Difficoltà moderata",
  "patient_foot_ankle_function_q50_a4":"Difficoltà estrema",
  "patient_foot_ankle_function_q50_a5":"Incapace",
  "patient_foot_ankle_function_q51":"Come valuta la sua caviglia e/o il suo piede oggi come percentuale della normalità? (da 0 a 100%, 100% significa normale)",
  "patient_foot_ankle_function_q51_a1":"Normale",
  "patient_foot_ankle_function_q52":"Qual è il livello più alto di attività a cui puoi partecipare regolarmente? ",
  "patient_foot_ankle_function_q52_q1":"Prima dell'infortunio o del problema al piede o alla caviglia.",
  "patient_foot_ankle_function_q52_q1_a1":"Livello top",
  "patient_foot_ankle_function_q52_q1_a2":"Livelli competitivi inferiori",
  "patient_foot_ankle_function_q52_q1_a3":"Ricreativo",
  "patient_foot_ankle_function_q52_q1_a4":"Nessuna attività sportiva",
  "patient_foot_ankle_function_q52_q1_a5":"Nessuno sport, nessun lavoro",
  "patient_foot_ankle_function_q52_q1_a4_o7":"Incapace di camminare, disabile a causa di problemi alla caviglia",
  "patient_foot_ankle_function_q52_q1_a1_title":"Livello top (élite internazionale, professionista, squadra nazionale o prima divisione)",
  "patient_foot_ankle_function_q52_q1_a2_title":"Livelli competitivi inferiori",
  "patient_foot_ankle_function_q52_q1_a3_title":"Ricreativo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1":"Aerobica, fitness",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2":"Sci alpino e snowboard",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3":"Football americano",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4":"Badminton",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5":"Baseball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6":"Basketball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7":"Bowling o curling",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08":"Pugilato",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9":"Corsa campestre",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10":"Ciclismo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11":"Ballare",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12":"Immersioni",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13":"Equitazione",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14":"Scherma",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15":"Floorball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16":"Snowboard freestyle",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17":"Golf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18":"Ginnastica",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19":"Pallamano",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20":"Hockey",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21":"Hockey su ghiaccio",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22":"Korfball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23":"Arti marziali",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24":"Pentathlon moderno",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25":"Sport motoristici, sport tecnici",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26":"Arrampicata in montagna e in collina",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27":"Mountain biking o bmx",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28":"Sci nordico",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29":"Orienteering",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30":"Paracadutismo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31":">Pesistica",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32":"Ginnastica ritmica",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33":"Canottaggio, kayak",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34":"Rugby",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35":"Vela",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36":"Immersioni subacquee",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37":"Tiro, tiro con l'arco",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38":"Pattinaggio, pattinaggio in linea",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39":"Calcio",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40":"Softball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41":"Squash",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42":"Surf windsurf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43":"Tennis da tavolo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44":"Tennis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45":"Atletica leggera: prove su campo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46":"Atletica leggera: prove su pista",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47":"Triathlon",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48":"Pallavolo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49":"Pallanuoto e nuoto",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50":"Sci nautico",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51":"Sollevamento pesi body building",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52":"Lotta",
  "patient_foot_ankle_function_q52_q1_a4_o1":"Lavoro fisico pesante",
  "patient_foot_ankle_function_q52_q1_a4_o2":"Lavoro fisico",
  "patient_foot_ankle_function_q52_q1_a4_o3":"Professioni speciali: ballerino di balletto, soldato professionista, soccorritore speciale, stuntman",
  "patient_foot_ankle_function_q52_q1_a4_o4":"Capace di camminare su terreni irregolari",
  "patient_foot_ankle_function_q52_q1_a4_o5":"Attività quotidiane non limitate",
  "patient_foot_ankle_function_q52_q1_a4_o6":"Capace di camminare su terreno piano, ogni attività limitata",
  "patient_foot_ankle_function_q52_q2":"Stato attuale",
  "patient_foot_ankle_function_q52_q2_a1":"Livello top",
  "patient_foot_ankle_function_q52_q2_a2":"Livelli competitivi inferiori",
  "patient_foot_ankle_function_q52_q2_a3":"Ricreativo",
  "patient_foot_ankle_function_q52_q2_a4":"Nessuna attività sportiva",
  "patient_foot_ankle_function_q52_q2_a5":"Nessuno sport, nessun lavoro",
  "patient_foot_ankle_function_q52_q2_a4_o7":"Incapace di camminare, disabilitato a causa di problemi alla caviglia",
  "patient_foot_ankle_function_q52_q2_a1_title":"Livello top (élite internazionale, professionista, squadra nazionale o prima divisione)",
  "patient_foot_ankle_function_q52_q2_a2_title":"Livelli competitivi inferiori",
  "patient_foot_ankle_function_q52_q2_a3_title":"Ricreativo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1":"Aerobica, fitness",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2":"Sci alpino e snowboard",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3":"Football americano",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4":"Badminton",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5":"Baseball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6":"Basketball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7":"Bowling o curling",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8":"Pugilato",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9":"Corsa campestre",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10":"Ciclismo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11":"Ballare",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12":"Immersioni",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13":"Equitazione",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14":"Scherma",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15":"Floorball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16":"Snowboard freestyle",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17":"Golf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18":"Ginnastica",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19":"Pallamano",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20":"Hockey",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21":"Hockey su ghiaccio",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22":"Korfball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23":"Arti marziali",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24":"Pentathlon moderno",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25":"Sport motoristici, sport tecnici",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26":"Arrampicata in montagna e in collina",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27":"Mountain biking o bmx",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28":"Sci nordico",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29":"Orienteering",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30":"Paracadutismo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31":">Pesistica",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32":"Ginnastica ritmica",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33":"Canottaggio, kayak",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34":"Rugby",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35":"Vela",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36":"Immersioni subacquee",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37":"Tiro, tiro con l'arco",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38":"Pattinaggio, pattinaggio in linea",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39":"Calcio",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40":"Softball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41":"Squash",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42":"Surf windsurf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43":"Tennis da tavolo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44":"Tennis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45":"Atletica leggera: prove su campo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46":"Atletica leggera: prove su pista",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47":"Triathlon",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48":"Pallavolo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49":"Pallanuoto e nuoto",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50":"Sci nautico",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51":"Sollevamento pesi body building",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52":"Lotta",
  "patient_foot_ankle_function_q52_q2_a4_o1":"Lavoro fisico pesante",
  "patient_foot_ankle_function_q52_q2_a4_o2":"Lavoro fisico",
  "patient_foot_ankle_function_q52_q2_a4_o3":"Professioni speciali: ballerino di balletto, soldato professionista, soccorritore speciale, stuntman",
  "patient_foot_ankle_function_q52_q2_a4_o4":"Capace di camminare su terreni irregolari",
  "patient_foot_ankle_function_q52_q2_a4_o5":"Attività quotidiane non limitate",
  "patient_foot_ankle_function_q52_q2_a4_o6":"Capace di camminare su terreno piano, ogni attività limitata",
  "patient_foot_ankle_quality_of_life":"Qualità della vita",
  "patient_foot_ankle_quality_of_life_instructions":"Le seguenti domande riguardano preoccupazioni sociali, emotive e di stile di vita che potrebbe provare riguardo al suo problema al piede o alla caviglia. La preghiamo di riflettere su come è sentito nella maggior parte delle occasioni in relazione al suo piede e/o alla sua caviglia.",
  "patient_foot_ankle_quality_of_life_q1":"Con quale frequenza è consapevole del problema al suo piede e/o alla sua caviglia? ",
  "patient_foot_ankle_quality_of_life_q1_a1":"Mai",
  "patient_foot_ankle_quality_of_life_q1_a2":"Mensilmente",
  "patient_foot_ankle_quality_of_life_q1_a3":"Settimanalmente",
  "patient_foot_ankle_quality_of_life_q1_a4":"Quotidianamente",
  "patient_foot_ankle_quality_of_life_q1_a5":"Costantemente",
  "patient_foot_ankle_quality_of_life_q2":"Ha modificato il suo stile di vita per evitare attività potenzialmente dannose per il suo piede e/o per la sua caviglia? ",
  "patient_foot_ankle_quality_of_life_q2_a1":"Per niente",
  "patient_foot_ankle_quality_of_life_q2_a2":"Leggermente",
  "patient_foot_ankle_quality_of_life_q2_a3":"Moderatamente",
  "patient_foot_ankle_quality_of_life_q2_a4":"Notevolmente",
  "patient_foot_ankle_quality_of_life_q2_a5":"Totalmente",
  "patient_foot_ankle_quality_of_life_q3":"Quanto è preoccupato per la mancanza di fiducia nel suo piede e/o nella sua caviglia? ",
  "patient_foot_ankle_quality_of_life_q3_a1":"Per niente",
  "patient_foot_ankle_quality_of_life_q3_a2":"Leggermente",
  "patient_foot_ankle_quality_of_life_q3_a3":"Moderatamente",
  "patient_foot_ankle_quality_of_life_q3_a4":"Notevolmente",
  "patient_foot_ankle_quality_of_life_q3_a5":"Completamente",
  "patient_foot_ankle_quality_of_life_q4":"In generale, quanto è difficile utilizzare il suo piede e/o la sua caviglia?",
  "patient_foot_ankle_quality_of_life_q4_a1":"Nessuna difficoltà",
  "patient_foot_ankle_quality_of_life_q4_a2":"Lieve",
  "patient_foot_ankle_quality_of_life_q4_a3":"Moderata",
  "patient_foot_ankle_quality_of_life_q4_a4":"Notevole",
  "patient_foot_ankle_quality_of_life_q4_a5":"Estremo",
  "patient_foot_ankle_quality_of_life_q5":"Quanto è ansioso/depresso oggi?",
  "patient_foot_ankle_quality_of_life_q5_a1":"Non ansioso o depresso",
  "patient_foot_ankle_quality_of_life_q5_a2":"Leggermente",
  "patient_foot_ankle_quality_of_life_q5_a3":"Moderatamente",
  "patient_foot_ankle_quality_of_life_q5_a4":"Notevolmente",
  "patient_foot_ankle_quality_of_life_q5_a5":"Estremamente",
  "patient_foot_ankle_quality_of_life_q6":"Oggi, quanto è buona o cattiva la sua salute? (100 significa la migliore salute possibile, 0 significa la peggiore salute che puoi immaginare)",
  "patient_foot_ankle_quality_of_life_q6_a1":"Peggiore salute",
  "patient_foot_ankle_quality_of_life_q6_a2":"Migliore salute",
  "patient_foot_ankle_satisfaction":"Soddisfazione",
  "patient_foot_ankle_satisfaction_instruction":"Quanto è soddisfatto dei risultati della sua chirurgia o del trattamento al piede o alla caviglia?",
  "patient_foot_ankle_satisfaction_instruction_a1":"Molto insoddisfatto",
  "patient_foot_ankle_satisfaction_instruction_a2":"Abbastanza insoddisfatto",
  "patient_foot_ankle_satisfaction_instruction_a3":"Abbastanza soddisfatto",
  "patient_foot_ankle_satisfaction_instruction_a4":"Molto soddisfatto",
  "patient_foot_ankle_satisfaction_q1":"Per la riduzione dei sintomi?",
  "patient_foot_ankle_satisfaction_q1_not_applicable":"Non applicabile",
  "patient_foot_ankle_satisfaction_q1_a1":"Molto insoddisfatto",
  "patient_foot_ankle_satisfaction_q1_a2":"Abbastanza insoddisfatto",
  "patient_foot_ankle_satisfaction_q1_a3":"Abbastanza soddisfatto",
  "patient_foot_ankle_satisfaction_q1_a4":"Molto soddisfatto",
  "patient_foot_ankle_satisfaction_q2":"Per il miglioramento della sua capacità di svolgere le attività quotidiane?",
  "patient_foot_ankle_satisfaction_q2_not_applicable":"Non applicabile",
  "patient_foot_ankle_satisfaction_q2_a1":"Molto insoddisfatto",
  "patient_foot_ankle_satisfaction_q2_a2":"Abbastanza insoddisfatto",
  "patient_foot_ankle_satisfaction_q2_a3":"Abbastanza soddisfatto",
  "patient_foot_ankle_satisfaction_q2_a4":"Molto soddisfatto",
  "patient_foot_ankle_satisfaction_q3":"Quanto le capita di \"dimenticare\" il problema al piede o alla caviglia? (quanto è consapevole del problema al piede o alla caviglia)",
  "patient_foot_ankle_satisfaction_q3_not_applicable":"Non applicabile",
  "patient_foot_ankle_satisfaction_q3_a1":"Molto insoddisfatto",
  "patient_foot_ankle_satisfaction_q3_a2":"Abbastanza insoddisfatto",
  "patient_foot_ankle_satisfaction_q3_a3":"Abbastanza soddisfatto",
  "patient_foot_ankle_satisfaction_q3_a4":"Molto soddisfatto",
  "patient_foot_ankle_complete":"Fine del questionario",
  "patient_foot_ankle_complete_text1":"Grazie per aver dedicato del tempo per compilare questo modulo.",
  "patient_foot_ankle_complete_text2":"Per favore, clicchi su \"Invia\" per completare e visualizzare i suoi punteggi.",
  "patient_foot_ankle_complete_text3":"Le tue risposte saranno trattate in modo confidenziale e qualsiasi rapporto utilizzerà solo informazioni anonime.",
  "patient_foot_ankle_results":"Risultati",
  "patient_foot_ankle_results_text1":"Di seguito sono riportati i suoi punteggi per il dolore e la funzionalità del ginocchio, calcolati dal questionario.",
  "patient_foot_ankle_results_pain":"Dolore",
  "patient_foot_ankle_results_function":"Funzionalità",
  "patient_foot_ankle_results_overall_health_perception":"Percezione generale della salute",
  "patient_foot_ankle_results_feeling_of_instability":"Sensazione di instabilità",
  "patient_foot_ankle_results_self_evaluation":"Punteggio dell'autovalutazione del piede e della caviglia",
  "patient_foot_ankle_results_text2":"Il suo dottore sarà con lei a breve.",
  "patient_elbow_pain":"Dolore",
  "patient_elbow_pain_q1":"Avverte dolore al gomito?",
  "patient_elbow_pain_q1_a1":"No ",
  "patient_elbow_pain_q1_a2":"Si",
  "patient_elbow_pain_q2":"Qual è la migliore descrizione del dolore al gomito?",
  "patient_elbow_pain_q2_a1":"Nessun dolore",
  "patient_elbow_pain_q2_a2":"Lieve con attività, nessun o occasionalmente utilizzo di farmaci o analgesici",
  "patient_elbow_pain_q2_a3":"Moderata con o senza attività, utilizzo di analgesici quotidianamente",
  "patient_elbow_pain_q2_a4":"Moderata a riposo o di notte",
  "patient_elbow_pain_q2_a5":"Intenso a riposo, richiede farmaci costanti, invalidante.",
  "patient_elbow_pain_instruction":"Valuti il suo DOLORE al gomito:",
  "patient_elbow_pain_q3":"In questo momento",
  "patient_elbow_pain_q3_a1":"Nessun dolore",
  "patient_elbow_pain_q3_a2":"Peggiore dolore, insopportabile",
  "patient_elbow_pain_q4":"Dolore abituale durante le ultime 4 settimane",
  "patient_elbow_pain_q4_a1":"Nessun dolore",
  "patient_elbow_pain_q4_a2":"Peggiore dolore, insopportabile",
  "patient_elbow_pain_q5":"Quando è al suo peggio durante le ultime 4 settimane",
  "patient_elbow_pain_q5_a1":"Nessun dolore",
  "patient_elbow_pain_q5_a2":"Peggiore dolore, insopportabile",
  "patient_elbow_pain_q6":"A riposo",
  "patient_elbow_pain_q6_a1":"Nessun dolore",
  "patient_elbow_pain_q6_a2":"Peggiore dolore, insopportabile",
  "patient_elbow_pain_q7":"Sollevare un oggetto pesante",
  "patient_elbow_pain_q7_a1":"Nessun dolore",
  "patient_elbow_pain_q7_a2":"Peggiore dolore, insopportabile",
  "patient_elbow_pain_q8":"Quando si svolge un compito con movimenti ripetuti del gomito",
  "patient_elbow_pain_q8_a1":"Nessun dolore",
  "patient_elbow_pain_q8_a2":"Peggiore dolore, insopportabile",
  "patient_elbow_pain_q9":"Di notte",
  "patient_elbow_pain_q9_a1":"Nessun dolore",
  "patient_elbow_pain_q9_a2":"Peggiore dolore, insopportabile",
  "patient_elbow_pain_q10":"Quante volte è stato disturbato dal dolore al gomito a letto durante la notte?",
  "patient_elbow_pain_q10_a1":"Per niente",
  "patient_elbow_pain_q10_a2":"1-2 notti",
  "patient_elbow_pain_q10_a3":"Alcune notti",
  "patient_elbow_pain_q10_a4":"La maggior parte delle notti",
  "patient_elbow_pain_q10_a5":"Tutte le notti",
  "patient_elbow_pain_q11":"Quanto spesso il suo dolore al gomito ha interferito con il suo sonno?  ",
  "patient_elbow_pain_q11_a1":"Per niente",
  "patient_elbow_pain_q11_a2":"Occasionalmente",
  "patient_elbow_pain_q11_a3":"Qualche volta",
  "patient_elbow_pain_q11_a4":"La maggior parte del tempo",
  "patient_elbow_pain_q11_a5":"Sempre ",
  "patient_elbow_function":"Funzionalità",
  "patient_elbow_function_q1":"Qual è la migliore descrizione della FUNZIONALITA' del suo gomito? ",
  "patient_elbow_function_q1_a1":"Nessuna limitazione",
  "patient_elbow_function_q1_a2":"Leggere limitazioni senza restrizioni nelle attività quotidiane",
  "patient_elbow_function_q1_a3":"Incapacità di sollevare oggetti >4,5 kg",
  "patient_elbow_function_q1_a4":"Moderate limitazioni nelle attività quotidiane",
  "patient_elbow_function_q1_a5":"Incapacità di pettinare i capelli o toccare la testa",
  "patient_elbow_function_q1_a6":"Incapacità di alimentarsi autonomamente",
  "patient_elbow_function_q2":"Qual è la migliore descrizione dell'ATTIVITÀ che puoi svolgere?",
  "patient_elbow_function_q2_a1":"Uso illimitato del gomito",
  "patient_elbow_function_q2_a2":"Limitato uso per attività ricreative",
  "patient_elbow_function_q2_a3":"Limitato alle attività domestiche e lavorative",
  "patient_elbow_function_q2_a4":"Capace di prendersi cura di sé",
  "patient_elbow_function_q2_a5":"Invalido",
  "patient_elbow_function_q3":"Per quanto tempo può usare il suo gomito?",
  "patient_elbow_function_q3_a1":"Posso svolgere attività per 30 minuti",
  "patient_elbow_function_q3_a2":"Posso svolgere attività per 15 minuti",
  "patient_elbow_function_q3_a3":"Posso svolgere attività per 5 minuti",
  "patient_elbow_function_q3_a4":"Incapacità di utilizzare il gomito",
  "patient_elbow_function_instruction":"Indichi IL GRADO DI DIFFICOLTÀ per svolgere le seguenti attività nell'ultima settimana a causa del suo gomito",
  "patient_elbow_function_instruction_0":"Nessuna difficoltà",
  "patient_elbow_function_instruction_1":"Lieve",
  "patient_elbow_function_instruction_2":"Moderata",
  "patient_elbow_function_instruction_3":"Notevole",
  "patient_elbow_function_instruction_4":"Incapace",
  "patient_elbow_function_q4":"Svitare il coperchio di un barattolo ben chiuso o nuovo",
  "patient_elbow_function_q4_a1":"Nessuna difficoltà",
  "patient_elbow_function_q4_a2":"Lieve",
  "patient_elbow_function_q4_a3":"Moderata",
  "patient_elbow_function_q4_a4":"Notevole",
  "patient_elbow_function_q4_a5":"Incapace",
  "patient_elbow_function_q5":"Portare una borsa della spesa o una ventiquattrore",
  "patient_elbow_function_q5_a1":"Nessuna difficoltà",
  "patient_elbow_function_q5_a2":"Lieve",
  "patient_elbow_function_q5_a3":"Moderata",
  "patient_elbow_function_q5_a4":"Notevole",
  "patient_elbow_function_q5_a5":"Incapace",
  "patient_elbow_function_q6":"Sollevare oggetti in casa come portare fuori la spazzatura",
  "patient_elbow_function_q6_a1":"Nessuna difficoltà",
  "patient_elbow_function_q6_a2":"Lieve",
  "patient_elbow_function_q6_a3":"Moderata",
  "patient_elbow_function_q6_a4":"Notevole",
  "patient_elbow_function_q6_a5":"Incapace",
  "patient_elbow_function_q7":"Lavarsi la schiena",
  "patient_elbow_function_q7_a1":"Nessuna difficoltà",
  "patient_elbow_function_q7_a2":"Lieve",
  "patient_elbow_function_q7_a3":"Moderata",
  "patient_elbow_function_q7_a4":"Notevole",
  "patient_elbow_function_q7_a5":"Incapace",
  "patient_elbow_function_q8":"Gestire l'igiene personale",
  "patient_elbow_function_q8_a1":"Nessuna difficoltà",
  "patient_elbow_function_q8_a2":"Lieve",
  "patient_elbow_function_q8_a3":"Moderata",
  "patient_elbow_function_q8_a4":"Notevole",
  "patient_elbow_function_q8_a5":"Incapace",
  "patient_elbow_function_q9":"Lavarsi interamente",
  "patient_elbow_function_q9_a1":"Nessuna difficoltà",
  "patient_elbow_function_q9_a2":"Lieve",
  "patient_elbow_function_q9_a3":"Moderata",
  "patient_elbow_function_q9_a4":"Notevole",
  "patient_elbow_function_q9_a5":"Incapace",
  "patient_elbow_function_q10":"Eseguire la propria igiene personale",
  "patient_elbow_function_q10_a1":"Nessuna difficoltà",
  "patient_elbow_function_q10_a2":"Lieve",
  "patient_elbow_function_q10_a3":"Moderata",
  "patient_elbow_function_q10_a4":"Notevole",
  "patient_elbow_function_q10_a5":"Incapace",
  "patient_elbow_function_q11":"Usare un coltello per tagliare del cibo",
  "patient_elbow_function_q11_a1":"Nessuna difficoltà",
  "patient_elbow_function_q11_a2":"Lieve",
  "patient_elbow_function_q11_a3":"Moderata",
  "patient_elbow_function_q11_a4":"Notevole",
  "patient_elbow_function_q11_a5":"Incapace",
  "patient_elbow_function_q12":"Mangiare con le posate",
  "patient_elbow_function_q12_a1":"Nessuna difficoltà",
  "patient_elbow_function_q12_a2":"Lieve",
  "patient_elbow_function_q12_a3":"Moderata",
  "patient_elbow_function_q12_a4":"Notevole",
  "patient_elbow_function_q12_a5":"Incapace",
  "patient_elbow_function_q13":"Attività ricreative nelle quali si fa forza o si prendono colpi sul braccio, sulla spalla o sulla mano (ad esempio usare il martello, giocare a golf o tennis. ecc.)",
  "patient_elbow_function_q13_a1":"Nessuna difficoltà",
  "patient_elbow_function_q13_a2":"Lieve",
  "patient_elbow_function_q13_a3":"Moderata",
  "patient_elbow_function_q13_a4":"Notevole",
  "patient_elbow_function_q13_a5":"Incapace",
  "patient_elbow_function_q14":"Dormire a causa del dolore al braccio, alla spalla o alla mano",
  "patient_elbow_function_q14_a1":"Nessuna difficoltà",
  "patient_elbow_function_q14_a2":"Lieve",
  "patient_elbow_function_q14_a3":"Moderata",
  "patient_elbow_function_q14_a4":"Notevole",
  "patient_elbow_function_q14_a5":"Incapace",
  "patient_elbow_function_q15":"Indossare una camicia",
  "patient_elbow_function_q15_a1":"Nessuna difficoltà",
  "patient_elbow_function_q15_a2":"Lieve",
  "patient_elbow_function_q15_a3":"Moderata",
  "patient_elbow_function_q15_a4":"Notevole",
  "patient_elbow_function_q15_a5":"Incapace",
  "patient_elbow_function_q16":"Allacciare il bottone superiore della camicia",
  "patient_elbow_function_q16_a1":"Nessuna difficoltà",
  "patient_elbow_function_q16_a2":"Lieve",
  "patient_elbow_function_q16_a3":"Moderata",
  "patient_elbow_function_q16_a4":"Notevole",
  "patient_elbow_function_q16_a5":"Incapace",
  "patient_elbow_function_q17":"Vestirsi",
  "patient_elbow_function_q17_a1":"Nessuna difficoltà",
  "patient_elbow_function_q17_a2":"Lieve",
  "patient_elbow_function_q17_a3":"Moderata",
  "patient_elbow_function_q17_a4":"Notevole",
  "patient_elbow_function_q17_a5":"Incapace",
  "patient_elbow_function_q18":"Pettinarsi",
  "patient_elbow_function_q18_a1":"Nessuna difficoltà",
  "patient_elbow_function_q18_a2":"Lieve",
  "patient_elbow_function_q18_a3":"Moderata",
  "patient_elbow_function_q18_a4":"Notevole",
  "patient_elbow_function_q18_a5":"Incapace",
  "patient_elbow_function_q19":"Indossare le scarpe",
  "patient_elbow_function_q19_a1":"Nessuna difficoltà",
  "patient_elbow_function_q19_a2":"Lieve",
  "patient_elbow_function_q19_a3":"Moderata",
  "patient_elbow_function_q19_a4":"Notevole",
  "patient_elbow_function_q19_a5":"Incapace",
  "patient_elbow_function_q20":"Allacciare le scarpe",
  "patient_elbow_function_q20_a1":"Nessuna difficoltà",
  "patient_elbow_function_q20_a2":"Lieve",
  "patient_elbow_function_q20_a3":"Moderata",
  "patient_elbow_function_q20_a4":"Notevole",
  "patient_elbow_function_q20_a5":"Incapace",
  "patient_elbow_function_q21":"Sollevare un oggetto pesante",
  "patient_elbow_function_q21_a1":"Nessuna difficoltà",
  "patient_elbow_function_q21_a2":"Lieve",
  "patient_elbow_function_q21_a3":"Moderata",
  "patient_elbow_function_q21_a4":"Notevole",
  "patient_elbow_function_q21_a5":"Incapace",
  "patient_elbow_function_q22":"Alzarsi da una sedia spingendo con il braccio",
  "patient_elbow_function_q22_a1":"Nessuna difficoltà",
  "patient_elbow_function_q22_a2":"Lieve",
  "patient_elbow_function_q22_a3":"Moderata",
  "patient_elbow_function_q22_a4":"Notevole",
  "patient_elbow_function_q22_a5":"Incapace",
  "patient_elbow_function_q23":"Svolgere mansioni domestiche pesanti (es. lavare i muri, i pavimenti)",
  "patient_elbow_function_q23_a1":"Nessuna difficoltà",
  "patient_elbow_function_q23_a2":"Lieve",
  "patient_elbow_function_q23_a3":"Moderata",
  "patient_elbow_function_q23_a4":"Notevole",
  "patient_elbow_function_q23_a5":"Incapace",
  "patient_elbow_function_q24":"Girare una chiave",
  "patient_elbow_function_q24_a1":"Nessuna difficoltà",
  "patient_elbow_function_q24_a2":"Lieve",
  "patient_elbow_function_q24_a3":"Moderata",
  "patient_elbow_function_q24_a4":"Notevole",
  "patient_elbow_function_q24_a5":"Incapace",
  "patient_elbow_function_q25":"Lanciare una palla",
  "patient_elbow_function_q25_a1":"Nessuna difficoltà",
  "patient_elbow_function_q25_a2":"Lieve",
  "patient_elbow_function_q25_a3":"Moderata",
  "patient_elbow_function_q25_a4":"Notevole",
  "patient_elbow_function_q25_a5":"Incapace",
  "patient_elbow_function_q26":"Svolgere il lavoro abituale a causa del dolore al braccio, alla spalla o alla mano (comprese le mansioni domestiche se sono parte delle sue attività principali) ",
  "patient_elbow_function_q26_a1":"Nessuna difficoltà",
  "patient_elbow_function_q26_a2":"Lieve",
  "patient_elbow_function_q26_a3":"Moderata",
  "patient_elbow_function_q26_a4":"Notevole",
  "patient_elbow_function_q26_a5":"Incapace",
  "patient_elbow_function_q27":"Specifichi il lavoro abituale",
  "patient_elbow_function_q27_placeholder":"Specifichi il lavoro",
  "patient_elbow_function_i_donot_work":"Non lavoro",
  "patient_elbow_function_q28":"Utilizzare la sua tecnica abituale per lavorare",
  "patient_elbow_function_q28_a1":"Nessuna difficoltà",
  "patient_elbow_function_q28_a2":"Lieve",
  "patient_elbow_function_q28_a3":"Moderata",
  "patient_elbow_function_q28_a4":"Notevole",
  "patient_elbow_function_q28_a5":"Incapace",
  "patient_elbow_function_q29":"Svolgere il lavoro al meglio come vorrebbe",
  "patient_elbow_function_q29_a1":"Nessuna difficoltà",
  "patient_elbow_function_q29_a2":"Lieve",
  "patient_elbow_function_q29_a3":"Moderata",
  "patient_elbow_function_q29_a4":"Notevole",
  "patient_elbow_function_q29_a5":"Incapace",
  "patient_elbow_function_q30":"Dedicare il suo solito tempo al lavoro",
  "patient_elbow_function_q30_a1":"Nessuna difficoltà",
  "patient_elbow_function_q30_a2":"Lieve",
  "patient_elbow_function_q30_a3":"Moderata",
  "patient_elbow_function_q30_a4":"Notevole",
  "patient_elbow_function_q30_a5":"Incapace",
  "patient_elbow_function_q31":"Suonare uno strumento musicale o praticare uno sport a causa del dolore al braccio, alla spalla o alla mano",
  "patient_elbow_function_q31_a1":"Nessuna difficoltà",
  "patient_elbow_function_q31_a2":"Lieve",
  "patient_elbow_function_q31_a3":"Moderata",
  "patient_elbow_function_q31_a4":"Notevole",
  "patient_elbow_function_q31_a5":"Incapace",
  "patient_elbow_function_q32":"Specificare lo sport o lo strumento musicale suonato",
  "patient_elbow_function_q32_placeholder":"Specifica lo sport o lo strumento musicale",
  "patient_elbow_function_i_donot_play":"Non pratico nessuno sport o non suono nessun strumento musicale",
  "patient_elbow_function_q33":"Utilizzare la sua tecnica abituale per suonare lo strumento musicale o praticare lo sport",
  "patient_elbow_function_q33_a1":"Nessuna difficoltà",
  "patient_elbow_function_q33_a2":"Lieve",
  "patient_elbow_function_q33_a3":"Moderata",
  "patient_elbow_function_q33_a4":"Notevole",
  "patient_elbow_function_q33_a5":"Incapace",
  "patient_elbow_function_q34":"Suonare uno strumento musicale o praticare uno sport come vorrebbe",
  "patient_elbow_function_q34_a1":"Nessuna difficoltà",
  "patient_elbow_function_q34_a2":"Lieve",
  "patient_elbow_function_q34_a3":"Moderata",
  "patient_elbow_function_q34_a4":"Notevole",
  "patient_elbow_function_q34_a5":"Incapace",
  "patient_elbow_function_q35":"Dedicare il suo solito tempo alla pratica di uno strumento musicale o sport ",
  "patient_elbow_function_q35_a1":"Nessuna difficoltà",
  "patient_elbow_function_q35_a2":"Lieve",
  "patient_elbow_function_q35_a3":"Moderata",
  "patient_elbow_function_q35_a4":"Notevole",
  "patient_elbow_function_q35_a5":"Incapace",
  "patient_elbow_function_q36":"Durante la settimana scorsa, in che misura il problema al braccio, alla spalla o alla mano ha interferito con le sue normali attività sociali o di svago con familiari, amici, vicini o gruppi? ",
  "patient_elbow_function_q36_a1":"Per niente",
  "patient_elbow_function_q36_a2":"Leggermente",
  "patient_elbow_function_q36_a3":"Moderatamente",
  "patient_elbow_function_q36_a4":"Abbastanza",
  "patient_elbow_function_q36_a5":"Estremamente",
  "patient_elbow_function_q37":"Durante la settimana scorsa è stato limitato nel lavoro o in altre attività quotidiane regolari a causa del problema al braccio, alla spalla o alla mano?",
  "patient_elbow_function_q37_a1":"Per niente",
  "patient_elbow_function_q37_a2":"Leggermente",
  "patient_elbow_function_q37_a3":"Moderatamente",
  "patient_elbow_function_q37_a4":"Abbastanza",
  "patient_elbow_function_q37_a5":"Estremamente",
  "patient_elbow_symptoms":"Altri sintomi ",
  "patient_elbow_symptoms_instruction":"Si prega di valutare la gravità dei seguenti sintomi nell'ultima settimana",
  "patient_elbow_symptoms_instruction_0":"Nessun",
  "patient_elbow_symptoms_instruction_1":"Lieve",
  "patient_elbow_symptoms_instruction_2":"Moderato",
  "patient_elbow_symptoms_instruction_3":"Notevole",
  "patient_elbow_symptoms_instruction_4":"Estremo",
  "patient_elbow_symptoms_q1":"Dolore al braccio, alla spalla o alla mano",
  "patient_elbow_symptoms_q1_a1":"Nessun",
  "patient_elbow_symptoms_q1_a2":"Lieve",
  "patient_elbow_symptoms_q1_a3":"Moderato",
  "patient_elbow_symptoms_q1_a4":"Notevole",
  "patient_elbow_symptoms_q1_a5":"Estremo",
  "patient_elbow_symptoms_q2":"Formicolio nel braccio, nella spalla o nella mano",
  "patient_elbow_symptoms_q2_a1":"Nessun",
  "patient_elbow_symptoms_q2_a2":"Lieve",
  "patient_elbow_symptoms_q2_a3":"Moderato",
  "patient_elbow_symptoms_q2_a4":"Notevole",
  "patient_elbow_symptoms_q2_a5":"Estremo",
  "patient_elbow_symptoms_q3":"Come valuterebbe il suo gomito oggi in percentuale rispetto alla normalità? (0-100% con 100% che indica normalità)",
  "patient_elbow_quality_of_life":"Qualità della vita",
  "patient_elbow_quality_of_life_instruction":"Le seguenti domande riguardano preoccupazioni sociali, emotive e di stile di vita che potrebbe provare riguardo al suo problema al gomito. La preghiamo di riflettere su come si è sentito nella maggior parte delle occasioni in relazione al suo gomito.",
  "patient_elbow_quality_of_life_q1":"Nelle ultime 4 settimane ha avvertito che il problema al gomito sta \"limitando la sua vita\"?",
  "patient_elbow_quality_of_life_q1_a1":"Per niente",
  "patient_elbow_quality_of_life_q1_a2":"Occasionalmente",
  "patient_elbow_quality_of_life_q1_a3":"Alcuni giorni",
  "patient_elbow_quality_of_life_q1_a4":"La maggior parte dei giorni",
  "patient_elbow_quality_of_life_q1_a5":"Tutti i giorni",
  "patient_elbow_quality_of_life_q2":"Nelle ultime 4 settimane quanto il suo gomito è stato \"nella sua mente\"?",
  "patient_elbow_quality_of_life_q2_a1":"Per niente",
  "patient_elbow_quality_of_life_q2_a2":"Occasionalmente",
  "patient_elbow_quality_of_life_q2_a3":"Alcuni giorni",
  "patient_elbow_quality_of_life_q2_a4":"La maggior parte dei giorni",
  "patient_elbow_quality_of_life_q2_a5":"Tutti i giorni",
  "patient_elbow_quality_of_life_instruction1":"Per favore valuti le seguenti opzioni per descrivere al meglio la sua SALUTE OGGI:",
  "patient_elbow_quality_of_life_instruction1_0":"Nessun problema",
  "patient_elbow_quality_of_life_instruction1_1":"Lieve",
  "patient_elbow_quality_of_life_instruction1_2":"Moderato",
  "patient_elbow_quality_of_life_instruction1_3":"Notevole",
  "patient_elbow_quality_of_life_instruction1_4":"Estremo",
  "patient_elbow_quality_of_life_q3":"Problema nel camminare",
  "patient_elbow_quality_of_life_q3_a1":"Nessun problema",
  "patient_elbow_quality_of_life_q3_a2":"Lieve",
  "patient_elbow_quality_of_life_q3_a3":"Moderato",
  "patient_elbow_quality_of_life_q3_a4":"Notevole",
  "patient_elbow_quality_of_life_q3_a5":"Estremo",
  "patient_elbow_quality_of_life_q4":"Problema nel lavarsi o vestirsi",
  "patient_elbow_quality_of_life_q4_a1":"Nessun problema",
  "patient_elbow_quality_of_life_q4_a2":"Lieve",
  "patient_elbow_quality_of_life_q4_a3":"Moderato",
  "patient_elbow_quality_of_life_q4_a4":"Notevole",
  "patient_elbow_quality_of_life_q4_a5":"Estremo",
  "patient_elbow_quality_of_life_q5":"Problema con le attività abituali (come lavoro, studio, attività domestiche, familiari o di svago)",
  "patient_elbow_quality_of_life_q5_a1":"Nessun problema",
  "patient_elbow_quality_of_life_q5_a2":"Lieve",
  "patient_elbow_quality_of_life_q5_a3":"Moderato",
  "patient_elbow_quality_of_life_q5_a4":"Notevole",
  "patient_elbow_quality_of_life_q5_a5":"Estremo",
  "patient_elbow_quality_of_life_q6":"Dolore o fastidio",
  "patient_elbow_quality_of_life_q6_a1":"Nessun problema",
  "patient_elbow_quality_of_life_q6_a2":"Lieve",
  "patient_elbow_quality_of_life_q6_a3":"Moderato",
  "patient_elbow_quality_of_life_q6_a4":"Notevole",
  "patient_elbow_quality_of_life_q6_a5":"Estremo",
  "patient_elbow_quality_of_life_q7":"Ansia/Depressione",
  "patient_elbow_quality_of_life_q7_a1":"Non ansioso e/o depresso",
  "patient_elbow_quality_of_life_q7_a2":"Leggermente ansioso e/o depresso",
  "patient_elbow_quality_of_life_q7_a3":"Moderatamente ansioso e/o depresso",
  "patient_elbow_quality_of_life_q7_a4":"Seriamente ansioso e/o depresso",
  "patient_elbow_quality_of_life_q7_a5":"Estremamente ansioso e/o depresso",
  "patient_elbow_quality_of_life_q8":"Come valuterebbe la sua salute oggi, in generale?",
  "patient_elbow_quality_of_life_q8_instruction":"Su una scala da 0 a 100, dove 0 rappresenta il peggiore stato di salute immaginabile e 100 rappresenta il massimo stato di salute",
  "patient_elbow_quality_of_life_q8_a1":"Peggiore",
  "patient_elbow_quality_of_life_q8_a2":"Migliore",
  "patient_elbow_satisfaction":"Soddisfazione",
  "patient_elbow_satisfaction_q1":"È soddisfatto del trattamento per il suo gomito?",
  "patient_elbow_satisfaction_na":"Non applicabile",
  "patient_elbow_satisfaction_q1_a1":"Per niente soddisfatto",
  "patient_elbow_satisfaction_q1_a2":"Molto soddisfatto",
  "patient_elbow_complete":"Fine del questionario",
  "patient_elbow_complete_text1":"Grazie per aver dedicato del tempo per compilare questo modulo.",
  "patient_elbow_complete_text2":"Per favore, clicchi su \"Invia\" per completare e visualizzare i suoi punteggi.",
  "patient_elbow_complete_text3":"Le tue risposte saranno trattate in modo confidenziale e qualsiasi rapporto utilizzerà solo informazioni anonime.",
  "patient_elbow_results":"Risultati",
  "patient_elbow_results_text1":"Di seguito sono riportati i suoi punteggi per il dolore e la funzionalità del ginocchio, calcolati dal questionario.",
  "patient_elbow_results_function":"Funzionalità",
  "patient_elbow_results_pain":"Dolore",
  "patient_elbow_results_health_perception":"Percezione generale della salute",
  "patient_elbow_results_self_evaluation":"Punteggio dell'autovalutazione del gomito",
  "patient_elbow_results_text4":" Il suo medico sarà con lei a breve.",
  "patient_hand_pain":"Dolore",
  "patient_hand_pain_q1":"Descriva il dolore nella sua mano e/o nel suo polso durante LA SETTIMANA SCORSA",
  "patient_hand_pain_q1_a1":"Nessun dolore o molto lieve",
  "patient_hand_pain_q1_a2":"Lieve",
  "patient_hand_pain_q1_a3":"Moderato",
  "patient_hand_pain_q1_a4":"Intenso",
  "patient_hand_pain_q1_a5":"Dolore molto notevole o estremo",
  "patient_hand_pain_q2":"Quanto è forte il dolore nella sua mano e/o nel suo polso IN QUESTO MOMENTO?",
  "patient_hand_pain_q2_a1":"Nessun dolore",
  "patient_hand_pain_q2_a2":"Dolore estremo",
  "patient_hand_pain_q3":"Quanto spesso il dolore nella mano e/o nel polso ha interferito con le attività quotidiane (come mangiare o fare il bagno) nella settimana scorsa?",
  "patient_hand_pain_q3_a1":"Mai",
  "patient_hand_pain_q3_a2":"Raramente",
  "patient_hand_pain_q3_a3":"A volte",
  "patient_hand_pain_q3_a4":"Spesso",
  "patient_hand_pain_q3_a5":"Sempre",
  "patient_hand_symptoms":"Altri sintomi ",
  "patient_hand_symptoms_q1":"Quanto è intenso il formicolio (sensazione di punture di spillo) nel braccio, spalla o mano nella settimana scorsa?",
  "patient_hand_symptoms_q1_a1":"Nessun",
  "patient_hand_symptoms_q1_a2":"Lieve",
  "patient_hand_symptoms_q1_a3":"Moderato",
  "patient_hand_symptoms_q1_a4":"Notevole",
  "patient_hand_symptoms_q1_a5":"Estremo",
  "patient_hand_symptoms_q2":"Come è stata la sensazione nella sua mano durante la scorsa settimana? ",
  "patient_hand_symptoms_q2_a1":"Molto buona",
  "patient_hand_symptoms_q2_a2":"Buona",
  "patient_hand_symptoms_q2_a3":"Sufficiente",
  "patient_hand_symptoms_q2_a4":"Scarsa",
  "patient_hand_symptoms_q2_a5":"Molto scarsa",
  "patient_hand_symptoms_q3":"L'aspetto della mia mano interferisce con le mie normali attività quotidiane?",
  "patient_hand_symptoms_q3_a1":"Fortemente in disaccordo",
  "patient_hand_symptoms_q3_a2":"In disaccordo",
  "patient_hand_symptoms_q3_a3":"Né d'accordo né in disaccordo",
  "patient_hand_symptoms_q3_a4":"D'accordo",
  "patient_hand_symptoms_q3_a5":"Fortemente d'accordo",
  "patient_hand_symptoms_q3_a6":"Come valuterebbe la sua mano e/o il suo polso oggi come percentuale della normalità? (0-100% con il 100% che rappresenta la normalità)",
  "patient_hand_symptoms_q3_a6_normal":"Normale",
  "patient_hand_function":"Funzionalità",
  "patient_hand_function_instruction1":"Se non esegue una determinata attività, la preghiamo di stimare la difficoltà che avrebbe nello svolgerla",
  "patient_hand_function_instruction2":"Se non è sicuro su come rispondere a una domanda, la preghiamo di fornire la risposta che ritiene sia più adatta.",
  "patient_hand_function_q1":"In generale, quanto bene ha funzionato la sua mano durante l'ultima settimana?",
  "patient_hand_function_q1_a1":"Molto bene",
  "patient_hand_function_q1_a2":"Bene",
  "patient_hand_function_q1_a3":"Sufficientemente bene",
  "patient_hand_function_q1_a4":"Male",
  "patient_hand_function_q1_a5":"Molto male",
  "patient_hand_function_q2":"Quanto spesso NON È STATO IN GRADO DI LAVORARE a causa di problemi alla mano e/o al polso?",
  "patient_hand_function_q2_a1":"Mai",
  "patient_hand_function_q2_a2":"Raramente",
  "patient_hand_function_q2_a3":"A volte",
  "patient_hand_function_q2_a4":"Spesso",
  "patient_hand_function_q2_a5":"Sempre",
  "patient_hand_function_q3":"Quanto spesso ha IMPIEGATO PIU TEMPO DEL SOLITO PER SVOLGERE LE TUE ATTIVITA LAVORATIVE a causa di problemi alla mano o al polso? ",
  "patient_hand_function_q3_a1":"Mai",
  "patient_hand_function_q3_a2":"Raramente",
  "patient_hand_function_q3_a3":"A volte",
  "patient_hand_function_q3_a4":"Spesso",
  "patient_hand_function_q3_a5":"Sempre",
  "patient_hand_function_instruction":"Indichi IL GRADO DI DIFFICOLTÀ per svolgere le seguenti attività nell'ultima settimana a causa di problemi con la tua mano/polso",
  "patient_hand_function_instruction_0":"Nessuna difficoltà",
  "patient_hand_function_instruction_1":"Difficoltà lieve",
  "patient_hand_function_instruction_2":"Difficoltà moderata",
  "patient_hand_function_instruction_3":"Difficolta notevole",
  "patient_hand_function_instruction_4":"Difficoltà estrema, incapace",
  "patient_hand_function_q4":"Svitare il coperchio di un barattolo ben chiuso o nuovo",
  "patient_hand_function_q4_a1":"Nessuna difficoltà",
  "patient_hand_function_q4_a2":"Difficoltà lieve",
  "patient_hand_function_q4_a3":"Difficoltà moderata",
  "patient_hand_function_q4_a4":"Difficolta notevole",
  "patient_hand_function_q4_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q5":"Tenere una padella",
  "patient_hand_function_q5_a1":"Nessuna difficoltà",
  "patient_hand_function_q5_a2":"Difficoltà lieve",
  "patient_hand_function_q5_a3":"Difficoltà moderata",
  "patient_hand_function_q5_a4":"Difficolta notevole",
  "patient_hand_function_q5_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q6":"Allacciare i bottoni di una camicia",
  "patient_hand_function_q6_a1":"Nessuna difficoltà",
  "patient_hand_function_q6_a2":"Difficoltà lieve",
  "patient_hand_function_q6_a3":"Difficoltà moderata",
  "patient_hand_function_q6_a4":"Difficolta notevole",
  "patient_hand_function_q6_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q7":"Svolgere mansioni domestiche pesanti (es. lavare i muri, i pavimenti)",
  "patient_hand_function_q7_a1":"Nessuna difficoltà",
  "patient_hand_function_q7_a2":"Difficoltà lieve",
  "patient_hand_function_q7_a3":"Difficoltà moderata",
  "patient_hand_function_q7_a4":"Difficolta notevole",
  "patient_hand_function_q7_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q8":"Portare una borsa della spesa o una ventiquattrore",
  "patient_hand_function_q8_a1":"Nessuna difficoltà",
  "patient_hand_function_q8_a2":"Difficoltà lieve",
  "patient_hand_function_q8_a3":"Difficoltà moderata",
  "patient_hand_function_q8_a4":"Difficolta notevole",
  "patient_hand_function_q8_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q9":"Lavarsi la schiena",
  "patient_hand_function_q9_a1":"Nessuna difficoltà",
  "patient_hand_function_q9_a2":"Difficoltà lieve",
  "patient_hand_function_q9_a3":"Difficoltà moderata",
  "patient_hand_function_q9_a4":"Difficolta notevole",
  "patient_hand_function_q9_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q10":"Usare un coltello per tagliare del cibo",
  "patient_hand_function_q10_a1":"Nessuna difficoltà",
  "patient_hand_function_q10_a2":"Difficoltà lieve",
  "patient_hand_function_q10_a3":"Difficoltà moderata",
  "patient_hand_function_q10_a4":"Difficolta notevole",
  "patient_hand_function_q10_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q11":"Attività ricreative nelle quali si fa forza o si prendono colpi sul braccio, sulla spalla o sulla mano (ad esempio usare il martello, giocare a golf o tennis. ecc.)",
  "patient_hand_function_q11_a1":"Nessuna difficoltà",
  "patient_hand_function_q11_a2":"Difficoltà lieve",
  "patient_hand_function_q11_a3":"Difficoltà moderata",
  "patient_hand_function_q11_a4":"Difficolta notevole",
  "patient_hand_function_q11_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q12":"Dormire a causa del dolore al braccio, alla spalla o alla mano",
  "patient_hand_function_q12_a1":"Nessuna difficoltà",
  "patient_hand_function_q12_a2":"Difficoltà lieve",
  "patient_hand_function_q12_a3":"Difficoltà moderata",
  "patient_hand_function_q12_a4":"Difficolta notevole",
  "patient_hand_function_q12_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q13":"Svolgere il lavoro abituale a causa del dolore al braccio, alla spalla o alla mano (comprese le mansioni domestiche se sono parte delle sue attività principali) ",
  "patient_hand_function_q13_a1":"Nessuna difficoltà",
  "patient_hand_function_q13_a2":"Difficoltà lieve",
  "patient_hand_function_q13_a3":"Difficoltà moderata",
  "patient_hand_function_q13_a4":"Difficolta notevole",
  "patient_hand_function_q13_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q14":"Specifichi il lavoro abituale",
  "patient_hand_function_q14_placeholder":"Specifichi il lavoro",
  "patient_hand_function_do_dot_work":"Non lavoro",
  "patient_hand_function_q15":"Utilizzare la sua tecnica abituale per lavorare",
  "patient_hand_function_q15_a1":"Nessuna difficoltà",
  "patient_hand_function_q15_a2":"Difficoltà lieve",
  "patient_hand_function_q15_a3":"Difficoltà moderata",
  "patient_hand_function_q15_a4":"Difficolta notevole",
  "patient_hand_function_q15_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q16":"Svolgere il lavoro al meglio come vorrebbe",
  "patient_hand_function_q16_a1":"Nessuna difficoltà",
  "patient_hand_function_q16_a2":"Difficoltà lieve",
  "patient_hand_function_q16_a3":"Difficoltà moderata",
  "patient_hand_function_q16_a4":"Difficolta notevole",
  "patient_hand_function_q16_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q17":"Dedicare il suo solito tempo al lavoro",
  "patient_hand_function_q17_a1":"Nessuna difficoltà",
  "patient_hand_function_q17_a2":"Difficoltà lieve",
  "patient_hand_function_q17_a3":"Difficoltà moderata",
  "patient_hand_function_q17_a4":"Difficolta notevole",
  "patient_hand_function_q17_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q18":"Suonare uno strumento musicale o praticare uno sport a causa del dolore al braccio, alla spalla o alla mano",
  "patient_hand_function_q18_a1":"Nessuna difficoltà",
  "patient_hand_function_q18_a2":"Difficoltà lieve",
  "patient_hand_function_q18_a3":"Difficoltà moderata",
  "patient_hand_function_q18_a4":"Difficolta notevole",
  "patient_hand_function_q18_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q19":"Specificare lo sport o lo strumento musicale suonato",
  "patient_hand_function_q19_placeholder":"Specifica lo sport o lo strumento musicale",
  "patient_hand_function_donot_play":"Non pratico nessuno sport o non suono nessun strumento musicale",
  "patient_hand_function_20":"Utilizzare la sua tecnica abituale per suonare lo strumento musicale o praticare lo sport",
  "patient_hand_function_q20_a1":"Nessuna difficoltà",
  "patient_hand_function_q20_a2":"Difficoltà lieve",
  "patient_hand_function_q20_a3":"Difficoltà moderata",
  "patient_hand_function_q20_a4":"Difficolta notevole",
  "patient_hand_function_q20_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q21":"Suonare uno strumento musicale o praticare uno sport come vorrebbe",
  "patient_hand_function_q21_a1":"Nessuna difficoltà",
  "patient_hand_function_q21_a2":"Difficoltà lieve",
  "patient_hand_function_q21_a3":"Difficoltà moderata",
  "patient_hand_function_q21_a4":"Difficolta notevole",
  "patient_hand_function_q21_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q22":"Dedicare il suo solito tempo alla pratica di uno strumento musicale o sport ",
  "patient_hand_function_q22_a1":"Nessuna difficoltà",
  "patient_hand_function_q22_a2":"Difficoltà lieve",
  "patient_hand_function_q22_a3":"Difficoltà moderata",
  "patient_hand_function_q22_a4":"Difficolta notevole",
  "patient_hand_function_q22_a5":"Difficoltà estrema, incapace",
  "patient_hand_function_q23":"Durante la settimana scorsa, in che misura il problema al braccio, alla spalla o alla mano ha interferito con le sue normali attività sociali o di svago con familiari, amici, vicini o gruppi? ",
  "patient_hand_function_q23_a1":"Per niente",
  "patient_hand_function_q23_a2":"Leggermente",
  "patient_hand_function_q23_a3":"Moderatamente",
  "patient_hand_function_q23_a4":"Abbastanza",
  "patient_hand_function_q23_a5":"Estremamente",
  "patient_hand_function_q24":"Durante la settimana scorsa è stato limitato nel lavoro o in altre attività quotidiane regolari a causa del problema al braccio, alla spalla o alla mano?",
  "patient_hand_function_q24_a1":"Per niente",
  "patient_hand_function_q24_a2":"Leggermente",
  "patient_hand_function_q24_a3":"Moderatamente",
  "patient_hand_function_q24_a4":"Abbastanza",
  "patient_hand_function_q24_a5":"Estremamente",
  "patient_hand_questionnaire":"Boston Carpal Tunnel Questionnaire",
  "patient_hand_questionnaire_symptom":"Sintomi",
  "patient_hand_questionnaire_symptom_instruction":"Per favore, scelga la risposta che corrisponde meglio ai suoi sintomi.",
  "patient_hand_questionnaire_symptom_instruction_0":"Normale",
  "patient_hand_questionnaire_symptom_instruction_1":"Lieve",
  "patient_hand_questionnaire_symptom_instruction_2":"Moderato",
  "patient_hand_questionnaire_symptom_instruction_3":"Notevole",
  "patient_hand_questionnaire_symptom_instruction_4":"Molto grave",
  "patient_hand_questionnaire_symptom_q1":"Quanto intenso è il dolore alla mano o al polso che ha durante la notte?",
  "patient_hand_questionnaire_symptom_q1_a1":"Normale",
  "patient_hand_questionnaire_symptom_q1_a2":"Lieve",
  "patient_hand_questionnaire_symptom_q1_a3":"Moderato",
  "patient_hand_questionnaire_symptom_q1_a4":"Notevole",
  "patient_hand_questionnaire_symptom_q1_a5":"Molto grave",
  "patient_hand_questionnaire_symptom_q2":"Con quale frequenza il dolore alla mano o al polso la sveglia durante la notte nelle ultime 2 settimane?",
  "patient_hand_questionnaire_symptom_q2_a1":"Normale",
  "patient_hand_questionnaire_symptom_q2_a2":"Una volta",
  "patient_hand_questionnaire_symptom_q2_a3":"2-3 volte",
  "patient_hand_questionnaire_symptom_q2_a4":"4-5 volte",
  "patient_hand_questionnaire_symptom_q2_a5":"Più di 5 volte",
  "patient_hand_questionnaire_symptom_q3":"Di solito ha dolore alla mano o al polso durante il giorno?",
  "patient_hand_questionnaire_symptom_q3_a1":"Nessun dolore",
  "patient_hand_questionnaire_symptom_q3_a2":"Lieve",
  "patient_hand_questionnaire_symptom_q3_a3":"Medio",
  "patient_hand_questionnaire_symptom_q3_a4":"Notevole",
  "patient_hand_questionnaire_symptom_q3_a5":"Molto grave",
  "patient_hand_questionnaire_symptom_q4":"Con quale frequenza ha dolore alla mano o al polso durante il giorno?",
  "patient_hand_questionnaire_symptom_q4_a1":"Normale",
  "patient_hand_questionnaire_symptom_q4_a2":"1-2 volte al giorno",
  "patient_hand_questionnaire_symptom_q4_a3":"3-5 volte al giorno",
  "patient_hand_questionnaire_symptom_q4_a4":"Più di 5 volte al giorno",
  "patient_hand_questionnaire_symptom_q4_a5":"Continuato",
  "patient_hand_questionnaire_symptom_q5":"Per quanto tempo in media dura un episodio di dolore durante il giorno?",
  "patient_hand_questionnaire_symptom_q5_a1":"Normale",
  "patient_hand_questionnaire_symptom_q5_a2":"<10 minuti",
  "patient_hand_questionnaire_symptom_q5_a3":"10-60 minuti continuati",
  "patient_hand_questionnaire_symptom_q5_a4":">60 minuti",
  "patient_hand_questionnaire_symptom_q5_a5":"Continuato",
  "patient_hand_questionnaire_symptom_q6":"Ha intorpidimento (perdita di sensazione) nella sua mano?",
  "patient_hand_questionnaire_symptom_q6_a1":"Normale",
  "patient_hand_questionnaire_symptom_q6_a2":"Lieve",
  "patient_hand_questionnaire_symptom_q6_a3":"Moderato",
  "patient_hand_questionnaire_symptom_q6_a4":"Notevole",
  "patient_hand_questionnaire_symptom_q6_a5":"Molto grave",
  "patient_hand_questionnaire_symptom_q7":"Ha debolezza nella sua mano o nel polso?",
  "patient_hand_questionnaire_symptom_q7_a1":"Normale",
  "patient_hand_questionnaire_symptom_q7_a2":"Lieve",
  "patient_hand_questionnaire_symptom_q7_a3":"Moderato",
  "patient_hand_questionnaire_symptom_q7_a4":"Notevole",
  "patient_hand_questionnaire_symptom_q7_a5":"Molto grave",
  "patient_hand_questionnaire_symptom_q8":"Ha sensazione di formicolio nella sua mano?",
  "patient_hand_questionnaire_symptom_q8_a1":"Normale",
  "patient_hand_questionnaire_symptom_q8_a2":"Lieve",
  "patient_hand_questionnaire_symptom_q8_a3":"Moderato",
  "patient_hand_questionnaire_symptom_q8_a4":"Notevole",
  "patient_hand_questionnaire_symptom_q8_a5":"Molto grave",
  "patient_hand_questionnaire_symptom_q9":"Quanto intenso è l'intorpidimento (perdita di sensazione) o il formicolio durante la notte?",
  "patient_hand_questionnaire_symptom_q9_a1":"Normale",
  "patient_hand_questionnaire_symptom_q9_a2":"Lieve",
  "patient_hand_questionnaire_symptom_q9_a3":"Moderato",
  "patient_hand_questionnaire_symptom_q9_a4":"Notevole",
  "patient_hand_questionnaire_symptom_q9_a5":"Molto grave",
  "patient_hand_questionnaire_symptom_q10":"Quanto spesso ti ha svegliato durante una notte tipica il formicolio o intorpidimento della mano nelle ultime 2 settimane?",
  "patient_hand_questionnaire_symptom_q10_a1":"Normale",
  "patient_hand_questionnaire_symptom_q10_a2":"Una volta",
  "patient_hand_questionnaire_symptom_q10_a3":"2-3 volte",
  "patient_hand_questionnaire_symptom_q10_a4":"4-5 volte",
  "patient_hand_questionnaire_symptom_q10_a5":"Più di 5 volte",
  "patient_hand_questionnaire_function":"Funzionalità",
  "patient_hand_questionnaire_function_instructions":"Valuti il grado di difficoltà nel fare le seguenti attività a causa dei suoi sintomi:",
  "patient_hand_questionnaire_function_instructions_0":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_instructions_1":"Difficoltà lieve",
  "patient_hand_questionnaire_function_instructions_2":"Difficoltà moderata",
  "patient_hand_questionnaire_function_instructions_3":"Difficoltà intensa",
  "patient_hand_questionnaire_function_instructions_4":"Non riesco a svolgerla affatto",
  "patient_hand_questionnaire_function_q1":"Difficoltà ad afferrare e usare oggetti piccoli come chiavi o penne",
  "patient_hand_questionnaire_function_q1_a1":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_q1_a2":"Difficoltà lieve",
  "patient_hand_questionnaire_function_q1_a3":"Difficoltà moderata",
  "patient_hand_questionnaire_function_q1_a4":"Difficoltà intensa",
  "patient_hand_questionnaire_function_q1_a5":"Non riesco a svolgerla affatto",
  "patient_hand_questionnaire_function_q2":"Scrivere",
  "patient_hand_questionnaire_function_q2_a1":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_q2_a2":"Difficoltà lieve",
  "patient_hand_questionnaire_function_q2_a3":"Difficoltà moderata",
  "patient_hand_questionnaire_function_q2_a4":"Difficoltà intensa",
  "patient_hand_questionnaire_function_q2_a5":"Non riesco a svolgerla affatto",
  "patient_hand_questionnaire_function_q3":"Abbottonare i vestiti",
  "patient_hand_questionnaire_function_q3_a1":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_q3_a2":"Difficoltà lieve",
  "patient_hand_questionnaire_function_q3_a3":"Difficoltà moderata",
  "patient_hand_questionnaire_function_q3_a4":"Difficoltà intensa",
  "patient_hand_questionnaire_function_q3_a5":"Non riesco a svolgerla affatto",
  "patient_hand_questionnaire_function_q4":"Tenere un libro mentre si legge",
  "patient_hand_questionnaire_function_q4_a1":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_q4_a2":"Difficoltà lieve",
  "patient_hand_questionnaire_function_q4_a3":"Difficoltà moderata",
  "patient_hand_questionnaire_function_q4_a4":"Difficoltà intensa",
  "patient_hand_questionnaire_function_q4_a5":"Non riesco a svolgerla affatto",
  "patient_hand_questionnaire_function_q5":"Afferrare la cornetta del telefono",
  "patient_hand_questionnaire_function_q5_a1":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_q5_a2":"Difficoltà lieve",
  "patient_hand_questionnaire_function_q5_a3":"Difficoltà moderata",
  "patient_hand_questionnaire_function_q5_a4":"Difficoltà intensa",
  "patient_hand_questionnaire_function_q5_a5":"Non riesco a svolgerla affatto",
  "patient_hand_questionnaire_function_q6":"Aprire i barattoli",
  "patient_hand_questionnaire_function_q6_a1":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_q6_a2":"Difficoltà lieve",
  "patient_hand_questionnaire_function_q6_a3":"Difficoltà moderata",
  "patient_hand_questionnaire_function_q6_a4":"Difficoltà intensa",
  "patient_hand_questionnaire_function_q6_a5":"Non riesco a svolgerla affatto",
  "patient_hand_questionnaire_function_q7":"Lavori domestici",
  "patient_hand_questionnaire_function_q7_a1":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_q7_a2":"Difficoltà lieve",
  "patient_hand_questionnaire_function_q7_a3":"Difficoltà moderata",
  "patient_hand_questionnaire_function_q7_a4":"Difficoltà intensa",
  "patient_hand_questionnaire_function_q7_a5":"Non riesco a svolgerla affatto",
  "patient_hand_questionnaire_function_q8":"Portare un cesto della spesa",
  "patient_hand_questionnaire_function_q8_a1":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_q8_a2":"Difficoltà lieve",
  "patient_hand_questionnaire_function_q8_a3":"Difficoltà moderata",
  "patient_hand_questionnaire_function_q8_a4":"Difficoltà intensa",
  "patient_hand_questionnaire_function_q8_a5":"Non riesco a svolgerla affatto",
  "patient_hand_questionnaire_function_q9":"Farsi il bagno e vestirsi",
  "patient_hand_questionnaire_function_q9_a1":"Nessuna difficoltà",
  "patient_hand_questionnaire_function_q9_a2":"Difficoltà lieve",
  "patient_hand_questionnaire_function_q9_a3":"Difficoltà moderata",
  "patient_hand_questionnaire_function_q9_a4":"Difficoltà intensa",
  "patient_hand_questionnaire_function_q9_a5":"Non riesco a svolgerla affatto",
  "patient_hand_uram":"La Scala URAM per Mano e Polso",
  "patient_hand_uram_canyou":"Può...",
  "patient_hand_uram_q1":"Lavarsi con una salvietta mantenendo la mano piatta",
  "patient_hand_uram_q1_a1":"Senza difficoltà",
  "patient_hand_uram_q1_a2":"Con molto poca difficoltà",
  "patient_hand_uram_q1_a3":"Con qualche difficoltà",
  "patient_hand_uram_q1_a4":"Con molta difficoltà",
  "patient_hand_uram_q1_a5":"Quasi impossibile",
  "patient_hand_uram_q1_a6":"Impossibile",
  "patient_hand_uram_q2":"Lavarsi il viso",
  "patient_hand_uram_q2_a1":"Senza difficoltà",
  "patient_hand_uram_q2_a2":"Con molto poca difficoltà",
  "patient_hand_uram_q2_a3":"Con qualche difficoltà",
  "patient_hand_uram_q2_a4":"Con molta difficoltà",
  "patient_hand_uram_q2_a5":"Quasi impossibile",
  "patient_hand_uram_q2_a6":"Impossibile",
  "patient_hand_uram_q3":"Tenere una bottiglia in una mano",
  "patient_hand_uram_q3_a1":"Senza difficoltà",
  "patient_hand_uram_q3_a2":"Con molto poca difficoltà",
  "patient_hand_uram_q3_a3":"Con qualche difficoltà",
  "patient_hand_uram_q3_a4":"Con molta difficoltà",
  "patient_hand_uram_q3_a5":"Quasi impossibile",
  "patient_hand_uram_q3_a6":"Impossibile",
  "patient_hand_uram_q4":"Stringere la mano a qualcuno",
  "patient_hand_uram_q4_a1":"Senza difficoltà",
  "patient_hand_uram_q4_a2":"Con molto poca difficoltà",
  "patient_hand_uram_q4_a3":"Con qualche difficoltà",
  "patient_hand_uram_q4_a4":"Con molta difficoltà",
  "patient_hand_uram_q4_a5":"Quasi impossibile",
  "patient_hand_uram_q4_a6":"Impossibile",
  "patient_hand_uram_q5":"Accarezzare qualcosa o fare carezze a qualcuno",
  "patient_hand_uram_q5_a1":"Senza difficoltà",
  "patient_hand_uram_q5_a2":"Con molto poca difficoltà",
  "patient_hand_uram_q5_a3":"Con qualche difficoltà",
  "patient_hand_uram_q5_a4":"Con molta difficoltà",
  "patient_hand_uram_q5_a5":"Quasi impossibile",
  "patient_hand_uram_q5_a6":"Impossibile",
  "patient_hand_uram_q6":"Battere le mani",
  "patient_hand_uram_q6_a1":"Senza difficoltà",
  "patient_hand_uram_q6_a2":"Con molto poca difficoltà",
  "patient_hand_uram_q6_a3":"Con qualche difficoltà",
  "patient_hand_uram_q6_a4":"Con molta difficoltà",
  "patient_hand_uram_q6_a5":"Quasi impossibile",
  "patient_hand_uram_q6_a6":"Impossibile",
  "patient_hand_uram_q7":"Allargare le dita",
  "patient_hand_uram_q7_a1":"Senza difficoltà",
  "patient_hand_uram_q7_a2":"Con molto poca difficoltà",
  "patient_hand_uram_q7_a3":"Con qualche difficoltà",
  "patient_hand_uram_q7_a4":"Con molta difficoltà",
  "patient_hand_uram_q7_a5":"Quasi impossibile",
  "patient_hand_uram_q7_a6":"Impossibile",
  "patient_hand_uram_q8":"Appoggiare la mano",
  "patient_hand_uram_q8_a1":"Senza difficoltà",
  "patient_hand_uram_q8_a2":"Con molto poca difficoltà",
  "patient_hand_uram_q8_a3":"Con qualche difficoltà",
  "patient_hand_uram_q8_a4":"Con molta difficoltà",
  "patient_hand_uram_q8_a5":"Quasi impossibile",
  "patient_hand_uram_q8_a6":"Impossibile",
  "patient_hand_uram_q9":"Raccogliere oggetti piccoli con il pollice e l'indice",
  "patient_hand_uram_q9_a1":"Senza difficoltà",
  "patient_hand_uram_q9_a2":"Con molto poca difficoltà",
  "patient_hand_uram_q9_a3":"Con qualche difficoltà",
  "patient_hand_uram_q9_a4":"Con molta difficoltà",
  "patient_hand_uram_q9_a5":"Quasi impossibile",
  "patient_hand_uram_q9_a6":"Impossibile",
  "patient_hand_quality_of_life":"Qualità della vita",
  "patient_hand_quality_of_life_instruction":"Per favore valuti le seguenti opzioni per descrivere al meglio la sua salute oggi",
  "patient_hand_quality_of_life_instruction_0":"Nessun problema",
  "patient_hand_quality_of_life_instruction_1":"Lieve",
  "patient_hand_quality_of_life_instruction_2":"Moderato",
  "patient_hand_quality_of_life_instruction_3":"Notevole",
  "patient_hand_quality_of_life_instruction_4":"Problema nel camminare",
  "patient_hand_quality_of_life_q1":"Problema nel camminare",
  "patient_hand_quality_of_life_q1_a1":"Nessun problema",
  "patient_hand_quality_of_life_q1_a2":"Lieve",
  "patient_hand_quality_of_life_q1_a3":"Moderato",
  "patient_hand_quality_of_life_q1_a4":"Notevole",
  "patient_hand_quality_of_life_q1_a5":"Incapace",
  "patient_hand_quality_of_life_q2":"Problema nel lavarsi o asciugarsi",
  "patient_hand_quality_of_life_q2_a1":"Nessun problema",
  "patient_hand_quality_of_life_q2_a2":"Lieve",
  "patient_hand_quality_of_life_q2_a3":"Moderato",
  "patient_hand_quality_of_life_q2_a4":"Notevole",
  "patient_hand_quality_of_life_q2_a5":"Incapace",
  "patient_hand_quality_of_life_q3":"Problema con le attività abituali (come lavoro, studio, attività domestiche, familiari o di svago)",
  "patient_hand_quality_of_life_q3_a1":"Nessun problema",
  "patient_hand_quality_of_life_q3_a2":"Lieve",
  "patient_hand_quality_of_life_q3_a3":"Moderato",
  "patient_hand_quality_of_life_q3_a4":"Notevole",
  "patient_hand_quality_of_life_q3_a5":"Incapace",
  "patient_hand_quality_of_life_q4":"Dolore o fastidio",
  "patient_hand_quality_of_life_q4_a1":"Nessun problema",
  "patient_hand_quality_of_life_q4_a2":"Lieve",
  "patient_hand_quality_of_life_q4_a3":"Moderato",
  "patient_hand_quality_of_life_q4_a4":"Notevole",
  "patient_hand_quality_of_life_q4_a5":"Estremo",
  "patient_hand_quality_of_life_q5":"Ansia/Depressione",
  "patient_hand_quality_of_life_q5_a1":"Non ansioso o depresso",
  "patient_hand_quality_of_life_q5_a2":"Lieve",
  "patient_hand_quality_of_life_q5_a3":"Moderata",
  "patient_hand_quality_of_life_q5_a4":"Seriamente ansioso o depresso",
  "patient_hand_quality_of_life_q5_a5":"Estremamente ansioso o depresso",
  "patient_hand_quality_of_life_q6":"Qual è il suo stato di salute attuale? (100 significa ottima salute, 0 significa il peggiore stato di salute immaginabile)",
  "patient_hand_quality_of_life_q6_a1":"Peggiori",
  "patient_hand_quality_of_life_q6_a2":"Migliori",
  "patient_hand_satisfaction":"Soddisfazione",
  "patient_hand_satisfaction_instrucion":"Indichi quanto è soddisfatto delle seguenti opzioni",
  "patient_hand_satisfaction_instrucion_0":"Molto insoddisfatto",
  "patient_hand_satisfaction_instrucion_1":"Abbastanza insoddisfatto ",
  "patient_hand_satisfaction_instrucion_2":"Abbastanza soddisfatto",
  "patient_hand_satisfaction_instrucion_3":"Molto soddisfatto",
  "patient_hand_satisfaction_instrucion_4":"Complessivamente",
  "patient_hand_satisfaction_q1":"Movimento del DITO?",
  "patient_hand_satisfaction_q1_na":"Non applicabile",
  "patient_hand_satisfaction_q1_a1":"Molto insoddisfatto",
  "patient_hand_satisfaction_q1_a2":"Insoddisfatto",
  "patient_hand_satisfaction_q1_a3":"Né soddisfatto né insoddisfatto",
  "patient_hand_satisfaction_q1_a4":"Soddisfatto",
  "patient_hand_satisfaction_q1_a5":"Molto soddisfatto",
  "patient_hand_satisfaction_q2":"Movimento del POLSO",
  "patient_hand_satisfaction_q2_na":"Non applicabile",
  "patient_hand_satisfaction_q2_a1":"Molto insoddisfatto",
  "patient_hand_satisfaction_q2_a2":"Insoddisfatto",
  "patient_hand_satisfaction_q2_a3":"Né soddisfatto né insoddisfatto",
  "patient_hand_satisfaction_q2_a4":"Soddisfatto",
  "patient_hand_satisfaction_q2_a5":"Molto soddisfatto",
  "patient_hand_satisfaction_q3":"Aspetto della MANO",
  "patient_hand_satisfaction_q3_na":"Non applicabile",
  "patient_hand_satisfaction_q3_a1":"Molto insoddisfatto",
  "patient_hand_satisfaction_q3_a2":"Insoddisfatto",
  "patient_hand_satisfaction_q3_a3":"Né soddisfatto né insoddisfatto",
  "patient_hand_satisfaction_q3_a4":"Soddisfatto",
  "patient_hand_satisfaction_q3_a5":"Molto soddisfatto",
  "patient_hand_satisfaction_q4":"Trattamento della sua mano e/o del suo polso",
  "patient_hand_satisfaction_q4_na":"Non applicabile",
  "patient_hand_satisfaction_q4_a1":"Molto insoddisfatto",
  "patient_hand_satisfaction_q4_a2":"Insoddisfatto",
  "patient_hand_satisfaction_q4_a3":"Né soddisfatto né insoddisfatto",
  "patient_hand_satisfaction_q4_a4":"Soddisfatto",
  "patient_hand_satisfaction_q4_a5":"Molto soddisfatto",
  "patient_hand_complete":"Fine del questionario",
  "patient_hand_complete_text1":"Grazie per aver dedicato del tempo per compilare questo modulo.",
  "patient_hand_complete_text2":"Per favore, clicchi su \"Invia\" per completare e visualizzare i suoi punteggi.",
  "patient_hand_complete_text3":"Le tue risposte saranno trattate in modo confidenziale e qualsiasi rapporto utilizzerà solo informazioni anonime.",
  "patient_hand_result":"Risultati",
  "patient_hand_result_text1":"Di seguito sono riportati i suoi punteggi per il dolore e la funzionalità del ginocchio, calcolati dal questionario.",
  "patient_hand_result_function":"Funzionalità",
  "patient_hand_result_pain":"Dolore",
  "patient_hand_result_health_perception":"Percezione generale della salute",
  "patient_hand_result_self_evaluation":"Punteggio dell'autovalutazione del mano e del polso",
  "patient_hand_result_text2":" Il suo medico sarà con lei a breve.",
  "patient_general_history":"STORIA ATTUALE DEL PROBLEMA/ DELLA LESIONE/ MALATTIA",
  "patient_general_history_q1":"Problema Principale (spiega il problema con le tue parole)",
  "patient_general_history_q2":"Data di inizio problema",
  "patient_general_history_q3":"Ha effettuato delle immagini diagnostiche?",
  "patient_general_history_q3_a1":"No",
  "patient_general_history_q3_a2":"Raggi X",
  "patient_general_history_q3_a3":"Ecografia",
  "patient_general_history_q3_a4":"Risonanza magnetica (MRI)",
  "patient_general_history_q3_a5":"Risonanza magnetica con mezzo di contrasto nell'articolazione",
  "patient_general_history_q3_a6":"Tomografia computerizzata (TAC)",
  "patient_general_history_q3_a7":"Artrografia con tomografia computerizzata (TAC con mezzo di contrasto nell'articolazione)",
  "patient_general_history_q4":"Ha effettuato ulteriori indagini o immagini diagnostiche  sulla sua condizione che non sono elencate sopra?",
  "patient_general_history_q4_placeholder":"Altre indagini",
  "patient_general_past_history":"ANAMNESI MEDICA / PROBLEMI MEDICI IN CORSO",
  "patient_general_past_history_q1":"Ha allergie note? (farmaci o cibo)",
  "patient_general_past_history_q1_a1":"No ",
  "patient_general_past_history_q1_a2":"Si",
  "patient_general_past_history_placeholder":"Specifica l'allergia e il tipo di reazione.",
  "patient_general_past_history_q2":"È allergico al lattice?",
  "patient_general_past_history_q2_a1":"No ",
  "patient_general_past_history_q2_a2":"Si",
  "patient_general_past_history_q3":"Ha avuto o sta ricevendo un trattamento per l'ipertensione arteriosa?",
  "patient_general_past_history_q3_a1":"No ",
  "patient_general_past_history_q3_a2":"Si",
  "patient_general_past_history_q3_a2_placeholder":"Specifica i farmaci per l'ipertensione arteriosa.",
  "patient_general_past_history_q4":"Attualmente ha o ha avuto problemi cardiaci/malattie cardiache?",
  "patient_general_past_history_q4_a1":"No ",
  "patient_general_past_history_q4_a2":"Si",
  "patient_general_past_history_q4_a2_o1":"Seleziona la condizione",
  "patient_general_past_history_q4_a2_o2":"Angina",
  "patient_general_past_history_q4_a2_o3":"Infarto",
  "patient_general_past_history_q4_a2_o4":"Insufficienza cardiaca (liquido nei polmoni)",
  "patient_general_past_history_q4_a2_o5":"Soffio o problema valvolare cardiaco",
  "patient_general_past_history_q4_a2_o6":"Palpitazioni e/o battito cardiaco irregolare",
  "patient_general_past_history_q4_a2_o7":"Dispositivo impiantato (ICD, pacemaker)",
  "patient_general_past_history_q4_a2_o8":"Chirurgia cardiaca",
  "patient_general_past_history_q4_a2_o9":"Chirurgia dell'arteria carotidea",
  "patient_general_past_history_q4_a2_o10":"Altra condizione cardiaca",
  "patient_general_past_history_q4_a2_o8_q1_placeholder":"Specificare",
  "patient_general_past_history_q4_a2_o10_q1_placeholder":"Specificare",
  "patient_general_past_history_q5":"Ha un cardiologo?",
  "patient_general_past_history_q5_a1":"No",
  "patient_general_past_history_q5_a2":"Si",
  "patient_general_past_history_q5_a2_placeholder":"Nome e pratica",
  "patient_general_past_history_q6":"Ha problemi di circolazione alle gambe (malattia vascolare periferica)?",
  "patient_general_past_history_q6_a1":"Nessuno",
  "patient_general_past_history_q6_a2":"Si",
  "patient_general_past_history_q6_a2_placeholder":"Descrizione",
  "patient_general_past_history_q7":"Ha assunto anticoagulanti nell'ultimo mese?",
  "patient_general_past_history_q7_a1":"No ",
  "patient_general_past_history_q7_a2":"Si",
  "patient_general_past_history_q7_a2_q1":"Quali sono?",
  "patient_general_past_history_q7_a2_q1_a1":"Aspirina",
  "patient_general_past_history_q7_a2_q1_a2":"Clopidogrel e/o Plavix",
  "patient_general_past_history_q7_a2_q1_a3":"Rivaroxaban",
  "patient_general_past_history_q7_a2_q1_a4":"Eliquis",
  "patient_general_past_history_q7_a2_q1_a5":"Altri",
  "patient_general_past_history_q7_a2_q1_a5_placeholder":"Specificare",
  "patient_general_past_history_q8":"Ha avuto problemi ai polmoni o al torace?",
  "patient_general_past_history_q8_a1":"No",
  "patient_general_past_history_q8_a2":"Si",
  "patient_general_past_history_q8_a2_o1":"Tosse con catarro?",
  "patient_general_past_history_q8_a2_o2":"Ha avuto difficoltà nella respirazione?",
  "patient_general_past_history_q8_a2_o3":"Altri",
  "patient_general_past_history_q8_a2_o2_o1":"Asma",
  "patient_general_past_history_q8_a2_o2_o2":"Pressione alta nelle arterie polmonari (ipertensione polmonare)",
  "patient_general_past_history_q8_a2_o2_o3":"Fibrosi polmonare",
  "patient_general_past_history_q8_a2_o2_o4":"Sarcoidosi",
  "patient_general_past_history_q8_a2_o2_o5":"Broncopneumopatia cronica ostruttiva o enfisema",
  "patient_general_past_history_q8_a2_o2_o6":"Fibrosi cistica",
  "patient_general_past_history_q8_a2_o2_o7":"Tubercolosi (TB)",
  "patient_general_past_history_q8_a2_o2_o8":"Altri",
  "patient_general_past_history_q8_a2_o3_placehold":"Specificare",
  "patient_general_past_history_q8_a2_o2_o8_placeholder":"Specificare",
  "patient_general_past_history_q9":"Ha assunto steroidi (prednisone o cortisone) nell'ultimo anno?",
  "patient_general_past_history_q9_a1":"No ",
  "patient_general_past_history_q9_a2":"Si",
  "patient_general_past_history_q10":"Usi ossigeno a domicilio?",
  "patient_general_past_history_q10_a1":"No ",
  "patient_general_past_history_q10_a2":"Si",
  "patient_general_past_history_q11":"Ha uno specialista in pneumologia?",
  "patient_general_past_history_q11_a1":"No ",
  "patient_general_past_history_q11_a2":"Si",
  "patient_general_past_history_q11_a2_placeholder":"Nome e pratica",
  "patient_general_past_history_q12":"Ha problemi di russamento e apnea del sonno?",
  "patient_general_past_history_q12_a1":"No ",
  "patient_general_past_history_q12_a2":"Si",
  "patient_general_past_history_q12_a2_q1":"La tua apnea del sonno viene trattata con CPAP o BiPAP?",
  "patient_general_past_history_q12_a2_q1_a1":"No ",
  "patient_general_past_history_q12_a2_q1_a2":"Si",
  "patient_general_past_history_q13":"Ha mai avuto problemi al fegato?",
  "patient_general_past_history_q13_a1":"No ",
  "patient_general_past_history_q13_a2":"Si",
  "patient_general_past_history_q13_a2_o1":"Selezionato",
  "patient_general_past_history_q13_a2_o2":"Cirrosi",
  "patient_general_past_history_q13_a2_o3":"Epatite A, B o C",
  "patient_general_past_history_q13_a2_o4":"Itterizia",
  "patient_general_past_history_q13_a2_o5":"Altri",
  "patient_general_past_history_q13_a2_o5_placeholder":"Specificare",
  "patient_general_past_history_q14":"Soffre spesso di bruciore di stomaco, ulcere o ernia iatale?",
  "patient_general_past_history_q14_a1":"No ",
  "patient_general_past_history_q14_a2":"Si",
  "patient_general_past_history_q15":"Ha mai avuto malattie renali?",
  "patient_general_past_history_q15_a1":"No ",
  "patient_general_past_history_q15_a2":"Si",
  "patient_general_past_history_q15_a2_q1":"È un paziente in dialisi? Peritoneale? Emodialisi?",
  "patient_general_past_history_q15_a2_q1_a1":"No ",
  "patient_general_past_history_q15_a2_q1_a2":"Si",
  "patient_general_past_history_q15_a2_placeholder":"Specifica la malattia renale.",
  "patient_general_past_history_q15_a2_q1_a2_placeholder":"Specifica il tipo e il programma di dialisi.",
  "patient_general_past_history_q16":"Ha il diabete?",
  "patient_general_past_history_q16_a1":"No ",
  "patient_general_past_history_q16_a2":"Si",
  "patient_general_past_history_q16_a2_q1":"Come gestisci il diabete? Con la dieta, farmaci per via orale o con l'insulina?",
  "patient_general_past_history_q16_a2_q1_placeholder":"Specificare",
  "patient_general_past_history_q17":"Ha problemi alla tiroide?",
  "patient_general_past_history_q17_a1":"No ",
  "patient_general_past_history_q17_a2":"Si",
  "patient_general_past_history_q18":"Ha mai avuto epilessia, crisi o convulsioni?",
  "patient_general_past_history_q18_a1":"No ",
  "patient_general_past_history_q18_a2":"Si",
  "patient_general_past_history_q18_a2_q1":"Quando è stata l'ultima crisi convulsiva?",
  "patient_general_past_history_q18_a2_q1_placeholder":"Specificare",
  "patient_general_past_history_q19":"Ha avuto problemi ai nervi o ai muscoli?",
  "patient_general_past_history_q19_a1":"No ",
  "patient_general_past_history_q19_a2":"Si",
  "patient_general_past_history_q19_a2_o1":"Crisi convulsive",
  "patient_general_past_history_q19_a2_o1_placeholder":"Quando",
  "patient_general_past_history_q19_a2_o2":"TIA (Attacco ischemico transitorio / Mini-ictus) o ictus",
  "patient_general_past_history_q19_a2_o2_placeholder":"Quando?",
  "patient_general_past_history_q19_a2_o3":"Debolezza al viso, alle gambe o alle braccia",
  "patient_general_past_history_q19_a2_o4":"Disturbo neurologico (esempi: sclerosi multipla, SLA, Alzheimer)",
  "patient_general_past_history_q19_a2_o4_placeholder":"Specificare",
  "patient_general_past_history_q19_a2_o5":"Disturbo muscolare (esempi: miastenia grave, distrofia muscolare)",
  "patient_general_past_history_q19_a2_o5_placeholder":"Specificare",
  "patient_general_past_history_q19_a2_o6":"Problemi di udito, vista o memoria",
  "patient_general_past_history_q19_a2_o7":"Dolore cronico",
  "patient_general_past_history_q19_a2_o8":"Altri",
  "patient_general_past_history_q19_a2_o8_placeholder":"Specificare",
  "patient_general_past_history_q20":"Soffre di artrite? Osteoartrite? Artrite reumatoide o altro?",
  "patient_general_past_history_q20_a1":"No ",
  "patient_general_past_history_q20_a2":"Si",
  "patient_general_past_history_q20_a2_o1":"Soffre di artrite? Osteoartrite? Artrite reumatoide o altro?",
  "patient_general_past_history_q20_a2_o2":"Artrite reumatoide",
  "patient_general_past_history_q20_a2_o3":"Altri",
  "patient_general_past_history_q20_a2_o3_placeholder":"Specificare",
  "patient_general_past_history_q21":"È mai stato trattato per una malattia psichiatrica?",
  "patient_general_past_history_q21_a1":"No ",
  "patient_general_past_history_q21_a2":"Si",
  "patient_general_past_history_q21_a2_o1":"Ansia grave",
  "patient_general_past_history_q21_a2_o2":"Depressione",
  "patient_general_past_history_q21_a2_o3":"Altri",
  "patient_general_past_history_q21_a2_o3_placeholder":"Specificare",
  "patient_general_past_history_q22":"È mai stato diagnosticato con un disturbo della coagulazione?",
  "patient_general_past_history_q22_a1":"No ",
  "patient_general_past_history_q22_a2":"Si",
  "patient_general_past_history_q22_a2_o1":"Leucemia o linfoma",
  "patient_general_past_history_q22_a2_o2":"Emofilia",
  "patient_general_past_history_q22_a2_o3":"Coaguli di sangue",
  "patient_general_past_history_q22_a2_o4":"Anemia",
  "patient_general_past_history_q22_a2_o5":"Malattia delle cellule falciformi",
  "patient_general_past_history_q22_a2_o6":"Altri",
  "patient_general_past_history_q22_a2_o6_placeholder":"Specificare",
  "patient_general_past_history_q23":"È anemico?",
  "patient_general_past_history_q23_a1":"No ",
  "patient_general_past_history_q23_a2":"Si",
  "patient_general_past_history_q24":"Ha mai avuto un coagulo di sangue alle gambe o ai polmoni?",
  "patient_general_past_history_q24_a1":"No ",
  "patient_general_past_history_q24_a2":"Si",
  "patient_general_past_history_q25":"Lei o suo familiare ha mai avuto gravi problemi con:",
  "patient_general_past_history_q25_a1":"Sanguinamento dal naso",
  "patient_general_past_history_q25_a1_o1":"No ",
  "patient_general_past_history_q25_a1_o2":"Si",
  "patient_general_past_history_q25_a2":"Sanguinamento dopo estrazioni dentali",
  "patient_general_past_history_q25_a2_o1":"No ",
  "patient_general_past_history_q25_a2_o2":"Si",
  "patient_general_past_history_q25_a3":"Sanguinamento dopo intervento chirurgico",
  "patient_general_past_history_q25_a3_o1":"No ",
  "patient_general_past_history_q25_a3_o2":"Si",
  "patient_general_past_history_q26":"È mai stato diagnosticato con il cancro?",
  "patient_general_past_history_q26_a1":"No ",
  "patient_general_past_history_q26_a2":"Si",
  "patient_general_past_history_q26_a2_placeholder1":"Specifica il tipo di cancro ",
  "patient_general_past_history_q26_a2_placeholder2":"Quando?",
  "patient_general_past_history_q27":"È mai stato sottoposto a chemioterapia o radioterapia?",
  "patient_general_past_history_q27_a1":"No ",
  "patient_general_past_history_q27_a2":"Si",
  "patient_general_past_history_q27_a2_placeholder1":"Nome del trattamento",
  "patient_general_past_history_q27_a2_placeholder2":"Data dell'ultimo trattamento",
  "patient_general_past_history_q28":"Lei o un suo familiare ha mai avuto problemi con l'anestesia?",
  "patient_general_past_history_q28_a1":"No ",
  "patient_general_past_history_q28_a2":"Si",
  "patient_general_past_history_q28_a2_q1":"Quali problemi?",
  "patient_general_past_history_q28_a2_q1_placeholder":"Seleziona",
  "patient_general_past_history_q28_a2_q1_o1":"Nausea grave o vomito dopo l'intervento chirurgico",
  "patient_general_past_history_q28_a2_q1_o2":"Ipertermia maligna (in familiari di sangue o in te stesso)",
  "patient_general_past_history_q28_a2_q1_o3":"Problemi respiratori o difficoltà con l'inserimento del tubo respiratorio per l'anestesia?",
  "patient_general_past_history_q28_a2_q1_o4":"Problemi con l'inserimento di un tubo respiratorio",
  "patient_general_past_history_q28_a2_q1_o5":"Altri",
  "patient_general_past_history_q28_a2_q1_o5_placeholder":"Specificare",
  "patient_general_past_history_q29":"Ha uno dei seguenti problemi?",
  "patient_general_past_history_q29_q1":"Denti scheggiati o allentati, dentiere, parziali",
  "patient_general_past_history_q29_q1_a1":"No ",
  "patient_general_past_history_q29_q1_a2":"Si",
  "patient_general_past_history_q29_q2":"Problemi nel muovere il collo",
  "patient_general_past_history_q29_q2_a1":"No ",
  "patient_general_past_history_q29_q2_a2":"Si",
  "patient_general_past_history_q29_q3":"Problemi nell'aprire la bocca",
  "patient_general_past_history_q29_q3_a1":"No ",
  "patient_general_past_history_q29_q3_a2":"Si",
  "patient_general_past_history_q30":"Potresti essere incinta?",
  "patient_general_past_history_q30_a1":"No ",
  "patient_general_past_history_q30_a2":"Si",
  "patient_general_past_history_q30_not_applicable":"Non applicabile",
  "patient_general_past_history_q30_a2_q1":"Ultimo periodo mestruale",
  "patient_general_past_history_q30_a2_q1_placeholder":"Ultimo periodo mestruale",
  "patient_general_past_history_q31":"Storia medica: elenca eventuali altre malattie che non sono state menzionate in precedenza",
  "patient_general_past_history_q31_q1":"Elencare tutti i farmaci (inclusi quelli da banco, inalatori, cerotti, gocce, vitamine, minerali, integratori, erbe)",
  "patient_general_past_history_q31_q2":"Indicare il nome del farmaco, la dose, la frequenza e il motivo per assumerlo.",
  "patient_general_past_history_q31_q2_placeholder":"Nome del medicinale, dose, frequenza, motivo per l'assunzione",
  "patient_general_past_history_q31_q3":"Dose",
  "patient_general_past_history_q31_q4":"Spesso",
  "patient_general_past_history_q31_q5":"Motivo per assumerlo",
  "patient_general_past_history_q31_q6":"Storia chirurgica",
  "patient_general_past_history_q31_q6_placeholder":"Specifica il tipo di procedura e la data della procedura",
  "patient_general_social_history":"STORIA SOCIALE",
  "patient_general_social_history_q1":"Vivi da solo?",
  "patient_general_social_history_q1_a1":"No ",
  "patient_general_social_history_q1_a2":"Si",
  "patient_general_social_history_q1_a1_q1":"Chi vive con te?",
  "patient_general_social_history_q1_a1_q1_placeholder":"Chi vive con te?",
  "patient_general_social_history_q2":"Fumi?",
  "patient_general_social_history_q2_a1":"No ",
  "patient_general_social_history_q2_a2":"Si",
  "patient_general_social_history_q2_a2_q1":"Quanti sigari fumi al giorno?",
  "patient_general_social_history_q2_a2_q1_placeholder":"Numero di sigarette",
  "patient_general_social_history_q2_a2_q2":"Da quanti anni fumi?",
  "patient_general_social_history_q2_a2_q2_placeholder":"Anni",
  "patient_general_social_history_q3":"Ha smesso di fumare in passato?",
  "patient_general_social_history_q3_a1":"No ",
  "patient_general_social_history_q3_a2":"Si",
  "patient_general_social_history_q3_a2_q1":"Quando ha smesso di fumare?",
  "patient_general_social_history_q3_a2_q1_placeholder":"Quando ha smesso di fumare?",
  "patient_general_social_history_q3_a2_q2_placeholder":"Anni",
  "patient_general_social_history_q4":"Bevi alcolici?",
  "patient_general_social_history_q4_a1":"No ",
  "patient_general_social_history_q4_a2":"Si",
  "patient_general_social_history_q4_a2_q1_placeholder":"Numero di bevande standard a settimana",
  "patient_general_social_history_q5":"Usa o ha mai usato droghe ricreative o da strada (illiciti), marijuana o oppioidi non prescritti per lei?",
  "patient_general_social_history_q5_a1":"No ",
  "patient_general_social_history_q5_a2":"Si",
  "patient_general_social_history_q5_a2_placeholder":"Specifica il tipo di droga",
  "patient_general_complete":"Fine del questionario",
  "patient_general_complete_text1":"Grazie per aver dedicato del tempo per compilare questo modulo.",
  "patient_general_complete_text2":"Per favore, clicchi su \"Invia\" per completare e visualizzare i suoi punteggi.",
  "patient_general_complete_text3":"Le tue risposte saranno trattate in modo confidenziale e qualsiasi rapporto utilizzerà solo informazioni anonime.",
  "AppVersion":"Versione",
  "Complete_personal_info":"Completa informazioni personali",
  "To_complete_the_sign_up":"Per completare la registrazione, inserisca le sue informazioni qui sotto",
  "Select_Existing_Profile":"Seleziona un profilo esistente",
  "Get_In":"Inserisca",
  "Create_new_patient":"Crea un nuovo paziente",
  "myProfile":"Il mio profilo",
  "First_Name":"Nome*",
  "Last_Name":"Cognome*",
  "DOB":"Data di nascita*",
  "Sex":"Sesso*",
  "Address":"Indirizzo*",
  "Postcode":"Codice postale*",
  "Suburb":"Citta*",
  "Country_Code":"Prefisso telefonico",
  "Mobile_Phone":"Cellulare",
  "Home_Phone":"Telefono",
  "Email":"Email*",
  "Emergency_Contact":"Contatto di emergenza*",
  "Phone":"Telefono*",
  "Relationship":"Relazione*",
  "Regular_GP_Name":"Nome del medico generico*",
  "Practice_Name":"Nome ambulatorio*",
  "Medicare_Card_Number":"Codice fiscale",
  "Ref":"Riferimento",
  "Message":"Messaggi",
  "Expiry":"Data di Scadenza",
  "Private_Health_Fund":"Fondo Sanitario Privato",
  "Membership_Number":"Numero di iscrizione",
  "Claim_Type":"Tipo di reclamo",
  "Work_Cover":"Infortunio sul lavoro",
  "Employer":"Datore di lavoro",
  "Third_Party_Claim_Number":"Numero di richiesta terza parte",
  "Third_Party_Name":"Nome terza parte",
  "DVA_Concession_Card":"Carta Esercito",
  "Number":"Numero",
  "Defence_Personnel":"Personale militare",
  "I_agree":"Accetto il trattamento dei miei dati personali e ho letto ",
  "Privacy_Policy":"l'Informativa sulla Privacy.",
  "visitClinic":"Prenota appuntamento",
  "selectdocOrg":"Seleziona medico e struttura",
  "doctorOrganization":"Medico e struttura",
  "Select_Treatment_Category":"Seleziona trattamento",
  "Do_you_want_to_submit_patient_form_or_not":"È pronto a procedere con il questionario?",
  "areyousurewanttocontinueyourform":"Il suo modulo precedente è in sospeso. Vuole continuare?",
  "myVisit":"Visite passate",
  "View_score":"Visualizza punteggio",
  "upcoming_visits":"Prossimi appuntamenti",
  "No_data_found":"Nessun documento trovato",
  "Doctor_Name":"Nome del medico",
  "Appointment_Date_Time":"Data e ora dell'appuntamento",
  "Clinic_Name":"Nome della clinica",
  "Clinic_Address":"Indirizzo della clinica",
  "akmed":"Akunah Med",
  "Type":"Digita",
  "Date_Time":"Data e ora",
  "Action":"Azione",
  "View":"Visualizza",
  "No_Messages":"Nessun messaggio",
  "settings":"Impostazioni",
  "Language":"Lingua",
  "language":"Lingua e regione",
  "timezone":"Fuso orario",
  "Timeformat":"Formato dell'ora",
  "List_of_my_profile":"I miei profili",
  "next_button":"Avanti",
  "back_button":"Indietro",
  "Continue":"Continua",
  "Close":"Chiudi",
  "submit":"Invia",
  "Sign_out":"Torna al profilo",
  "Results":"Risultati",
  "update":"Modulo aggiornato con successo",
  "Personal_Data":"Dati personali",
  "Date_of_Birth":"Data di nascita",
  "Hip_arthritis":"Artrite dell'anca?*",
  "Area_of_foot_ankle":"Area del piede / della caviglia interessata?*",
  "Diagnosis":"Diagnosi?*",
  "Evaluated_Shoulder":"Spalla valutata",
  "Dominant_Hand":"Mano dominante*",
  "Age":"Età",
  "No":"No ",
  "Yes":"Si",
  "visitNo":"Nessuna Visita",
  "visitYes":"Visita",
  "shoulderPainR":"shoulderPainR",
  "Is_it_initial_visit":"È questa la sua prima visita dal",
  "for_the_problem":"per questo problema*",
  "Diagnosis_Procedure":"Diagnosi / Procedura",
  "Procedure":"Procedura",
  "Evaluated_sdfs":"Lato valutato*",
  "Please_Select_previous_visits":"Seleziona visite precedenti",
  "Follow_Up":"Follow Up",
  "Assessment_Date":"Data di valutazione",
  "Do_you_have_Dupuytren_disease":"Ha la malattia di Dupuytren?",
  "Do_you_have_carpal_tunnel_syndrome":"Ha la sindrome del tunnel carpale?",
  "Is_your_unaffected_shoulder_pain":"La sua spalla non interessata è priva di dolore e consente una normale funzionalità?",
  "Is_your_unaffected_hip_pain":"La sua anca non interessata è priva di dolore e consente una normale funzionalità?",
  "Is_your_unaffected_knee_pain":"Il suo ginocchio non interessato è privo di dolore e consente una normale funzionalità?",
  "Is_your_unaffected_elbow_pain":"Il suo gomito non interessato è privo di dolore e consente una normale funzionalità?",
  "Is_your_unaffected_foot_and_ankle_pain":"Il suo piede e caviglia non interessati sono privi di dolore e consentono una normale funzionalità?",
  "Is_your_unaffected_hand_pain":"La sua mano e polso non interessati sono privi di dolore e consentono una normale funzionalità?",
  "Patient_Consent":"Modulo di informazioni e consenso del paziente",
  "Patient_Last_Name":"Cognome del paziente",
  "Patient_First_Name":"Nome del paziente",
  "Patient_Date_of_Birth":"Data di nascita del paziente",
  "information":"Informazioni",
  "I_am_informedDDD":"Ai fini della valutazione, pianificazione e valutazione dei risultati del suo trattamento ortopedico, il suo Clinico,",
  "Clinician":"(di seguito denominato \"Clinico\") utilizza servizi e software di valutazione, raccolta e analisi dei dati sui risultati e/o pianificazione pre-operatoria forniti da Akunah Medical Technology Pty Ltd e le sue sussidiarie (“Akunah”).",
  "For_that":"A tale scopo, il Clinico trasmetterà i suoi dati personali ad Akunah, comprese le scansioni tomografiche computerizzate (TAC) del suo corpo e altri dati medici.",
  "Akunah_softawre":"I software e i servizi di Akunah potrebbero prevedere la valutazione delle immagini, la valutazione dei dati medici, la creazione di modelli tridimensionali personalizzati della tua anatomia insieme a misurazioni anatomiche chiave, la pianificazione chirurgica e il supporto per l'intervento chirurgico, che saranno condivisi con il Clinico.",
  "the_clinic":"Il Clinico utilizzerà la valutazione e la pianificazione pre-operatoria per il suo trattamento ortopedico.",
  "oportunnity":"Ha anche l'opportunità di fornire dati personali tramite il portale del paziente di Akunah e accedere ai modelli e ai piani di Akunah.",
  "akunah_approch":"Approccio di Akunah alla privacy e alla sicurezza",
  "akunah_approch1":"La privacy e la sicurezza dei dati che Akunah raccoglierà da lei per assistere il suo Clinico sono la nostra massima priorità.",
  "akunah_approch2":"Akunah si conforma volontariamente alle Leggi Applicabili sulla Protezione dei Dati Europei, che includono il Regolamento Generale sulla Protezione dei Dati dell'UE 2016/679, poiché tali leggi rappresentano le normative sulla privacy più stringenti promulgate a livello globale. Tali leggi superano le leggi sulla privacy promulgate in Australia, negli Stati Uniti e in altri paesi.",
  "akunah_approch3":"Il \"Codice Privacy per il Trattamento delle Informazioni Personali degli Individui Clienti\" di Akunah descrive come Akunah si conforma alle Leggi Applicabili sulla Protezione dei Dati Europei (il ",
  "akunah_approch4":"Codice Privacy",
  "akunah_approch5":"\"L'Accordo di Trattamento dei Dati\" di Akunah descrive le disposizioni che ha con i suoi Medici per proteggere e garantire la sicurezza delle tue informazioni personali (",
  "akunah_approch6":"L'Accordo di Trattamento dei Dati",
  "akunah_approch7":"Tali politiche sono disponibili sul sito web di Akunah all'indirizzo ",
  "terms_":"https://akunah.com/termsofservices.",
  "akunah_approch8":"In caso di eventuali incongruenze tra il Codice Privacy, l'Accordo di Trattamento dei Dati e questo documento, i termini del documento menzionato per primo si applicheranno nella misura dell'incongruenza con questo documento.",
  "Data_Collection_and_Processing":"Raccolta e trattamento dei dati",
  "I_am_informed":"Akunah potrebbe raccogliere i suoi dati, inclusi quelli relativi alle sue informazioni di contatto, autenticazione, identificazione, occupazione e dati demografici. Akunah potrebbe inoltre raccogliere dati medici relativi alla sua salute, come:",
  "Assessment_and_evaluation":"• Dati di valutazione ed esame, caratteristiche fisiche e altre informazioni rilevanti per i trattamenti proposti e i risultati attesi;",
  "Previous_surgery":"• Chirurgie precedenti, diagnosi, scansioni radiologiche, modelli 3D, anatomia e misurazioni anatomiche;",
  "Health_and_medical_history":"• Storia clinica e medica, inclusi sintomi, farmaci e eventuali diagnosi e trattamenti precedenti ricevuti.",
  "I_have_been_assured":"Akunah raccoglie sempre le informazioni in modo lecito e corretto, che sia direttamente da un paziente, condiviso da un Clinico o attraverso i prodotti Akunah. Akunah elaborerà questi dati nel rispetto del suo consenso come espresso di seguito, al fine di fornire, se necessario, il servizio di valutazione e pianificazione pre-operatoria.",
  "My_data":"Inoltre, Akunah potrebbe utilizzare i suoi dati personali per conformarsi a tutte le leggi e normative applicabili. ",
  "your_data":"I suoi dati potrebbero anche essere comunicati a terze parti, se richiesto per fornire i servizi proposti o per legge.",
  "Data_Access_and_Storage":"Accesso ai dati, conservazione e sicurezza",
  "Personal_data_provided":"I dati forniti da lei e dal suo Clinico saranno accessibili solo al personale di Akunah e a terze parti che contribuiscono al suo trattamento o recupero (ad esempio, l'anestesista, il fisioterapista) e che necessitano di accedere ai suoi dati.",
  "data1":"I dati inseriti tramite i software di Akunah sono ospitati in modo sicuro nel Commonwealth of Australia da un provider di hosting approvato. Ciò garantisce un adeguato livello di riservatezza e sicurezza in linea con la natura dei dati elaborati tramite i software di Akuanah.",
  "data2":"Se ti trovi al di fuori del Commonwealth dell'Australia e/o i dati inseriti tramite i software di Akunah possono essere accessibili da sussidiarie di Akunah situate al di fuori del Commonwealth dell'Australia, inclusa l'Unione Europea o gli Stati Uniti, potrebbero essere applicati diversi standard di privacy per l'uso e la protezione dei suoi dati in quei paesi. In tal caso, i suoi dati personali verranno conservati in quel paese.",
  "data3":"Akunah ha messo in atto adeguate misure di sicurezza conformi a tutti i requisiti legali applicabili per garantire che i dati siano mantenuti confidenziali e protetti con un adeguato livello di protezione dei dati con tutte le terze parti. Richiediamo a tutti coloro che hanno accesso di adottare standard che garantiscono un livello equivalente di protezione dei dati come quello adottato da Akunah.",
  "data4":"Il suo Clinico condivide la responsabilità di raccogliere, accedere, analizzare e condividere i suoi dati in conformità ai requisiti legali.",
  "p_data1":"I suoi dati personali saranno conservati da Akunah per un periodo di tempo variabile. Il periodo di conservazione sarà determinato dai seguenti criteri: (i) lo scopo per cui Akunah o il Clinico utilizza i suoi dati personali (i dati saranno conservati solo per il tempo strettamente necessario a tale scopo); e (ii) le leggi o regolamenti applicabili che possono stabilire un periodo minimo per cui Akunah deve conservare i suoi dati personali.",
  "Deidentified_Data":"Dati de-identificati",
  "d_txt1":"Akunah potrebbe de-identificare i suoi dati e utilizzare questi dati de-identificati, di solito aggregati con dati de-identificati di altri pazienti, per fini di registrazione, ricerca e sviluppo, analisi dei dati, sviluppo di software/dispositivi e miglioramento dei prodotti e servizi di Akunah. Questo consente ad Akunah di migliorare continuamente i propri prodotti e servizi a beneficio di pazienti come lei.",
  "Patient_Rights":"I suoi diritti",
  "I_understand_that":"È libero di rifiutare di fornire il consenso per fornire i suoi dati personali ad Akunah. In tal caso, la preghiamo di parlare con il suo Clinico, poiché potrebbe non essere in grado di fornirle servizi medici o trattamenti senza l'utilizzo dei software e dei servizi di Akunah.",
  "I_understand_that_1txt":"Ha il diritto di revocare il suo consenso in qualsiasi momento e con qualsiasi mezzo e, se applicabile, ha il diritto: (i) di ottenere informazioni sul trattamento dei suoi dati personali e di accedere a tali dati personali; (ii) di richiedere la rettifica qualora siano inaccurati; (iii) di richiedere la limitazione del trattamento dei suoi dati personali, compresa l'opposizione a determinati trattamenti dei suoi dati personali e la richiesta di revoca del consenso fornito; (iv) di richiedere la cancellazione dei suoi dati personali; (v) di definire linee guida relative al trattamento dei suoi dati dopo la tua morte; e (vi) di ottenere una copia dei suoi dati personali elaborati nelle suddette condizioni in un formato strutturato, di uso comune e leggibile da dispositivo automatico. Può esercitare questi diritti rivolgendo la sua richiesta direttamente al Clinico.",
  "I_understand_that_2txt":"I suoi diritti sono soggetti alle eccezioni e restrizioni applicabili dalla legge.",
  "I_understand_that_3txt":"Dopo la revoca, il suo Clinico potrebbe non essere in grado di fornirti servizi medici o trattamenti se eserciti tali diritti prima della procedura proposta.",
  "I_understand_that_4txt":"Se lo desidera, ha anche la possibilità di presentare un reclamo riguardo al trattamento dei suoi dati personali all'autorità di controllo del suo paese di residenza.",
  "I_understand_that_5txt":"Per quanto riguarda i dati de-identificati, questi non sono soggetti alle stesse restrizioni legali dei dati personali. Tenga presente che non può revocare il consenso per l'accesso e l'uso dei suoi dati de-identificati o richiedere la cancellazione di tali dati, poiché Akunah non sarà in grado di individuare i suoi dati. Dopo la de-identificazione, Akunah non può risalire ai suoi dati.",
  "Contact":"Consenso",
  "I_am_able_to_contact":"Ho letto e compreso la sezione Informazioni sopra riportata, oppure qualcuno me l'ha letta in una lingua che comprendo.",
  "or_by_post":"Ho avuto l'opportunità di fare domande e sono soddisfatto delle risposte ricevute. Comprendo che le mie informazioni rimarranno confidenziali.",
  "or_by_post2":"Autorizzo il mio Clinico, ",
  "or_by_post2_1":", (il \"Clinico\"), a condividere i miei dati personali con Akunah.",
  "or_by_post3":"Acconsento affinché Akunah raccolga, archivi, utilizzi e condivida i miei dati personali e de-identificati come descritto nella sezione Informazioni sopra riportata.",
  "or_by_post4":"Comprendo che sono libero di revocare il mio consenso in qualsiasi momento.",
  "or_by_post5":"Comprendo che mi verrà consegnata una copia firmata di questo documento da conservare.",
  "or_by_post6":"Posso contattare Akunah riguardo al trattamento dei mie dati tramite email all’indirizzo ",
  "info_akunah":"info@akunah.com",
  "or_by_post7":"o tramite posta all'indirizzo Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australia.",
  "Clear":"Cancella",
  "Date":"Data",
  "Signed_by_Mouse":"Firmato con il mouse *",
  "Name_if_consent_on_behalf_of_patient":"Nome (se consenso a nome del paziente)",
  "Relationship_to_patient_if_applicable":"Relazione con il paziente, se applicabile",
  "Choose_Sign_Type":"Scegliere il tipo di firma*",
  "Draw":"Disegna",
  "Signed_by_Keyboard":"Firmato con la tastiera*",
  "Agreement":"Accordo",
  "agree_that_I_have_read":"dichiaro di aver letto e compreso questo modulo di consenso del paziente e acconsento esplicitamente al trattamento dei miei dati personali per le finalità descritte sopra.",
  "NOTE":"NOTA",
  "If_the_patient":"Se il paziente ha meno di 18 anni, questo deve essere firmato dal genitore o dal tutore legale.",
  "If_the_patient_is_deceased":"Se il paziente è deceduto, questo deve essere firmato dal loro parente più prossimo.",
  "patient_signature":"Firma del paziente",
  "profile_updated":"Profilo aggiornato",
  "profile_not_updated":"Profilo non aggiornato",
  "video":"Video",
  "radiology_form":"Modulo di radiologia",
  "doc_document":"Documento",
  "pathology_form":"Modulo di patologia",
  "text":"Testo",
  "Document_Name":"Nome del documento",
  "email_sent_successfully":"Email inviata con successo",
  "messages":"Messaggi",
  "adult_knee":"Ginocchio Adulto",
  "paediatric_knee":"Ginocchio Pediatrico",
  "document":"I miei documenti",
  "Check_in":"Check-in",
  "info_icon":"Compilare il questionario del paziente",
  "reminder":"Promemoria",
  "Search_with_minimum_three_characters":"Ricerca con un minimo di tre caratteri",
  "Select":"Seleziona",
  "delete_account":"Elimina account",
  "DD_MM_YYYY":"GG/MM/AAAA",
  "Female":"Femmina",
  "Male":"Maschio",
  "want_to_disclose":"Non desidero divulgare",
  "enter_address":"Inserisca indirizzo",
  "logout":"Esci",
  "full_name":"Nome completo",
  "mmyy":"MM / AA",
  "health_found":"Fondo sanitario",
  "Not_Applicable":"Non applicabile",
  "not_available":"Non disponibile",
  "available":"Disponibile",
  "shared":"Condivisi",
  "private":"Privati",
  "add_document":"Aggiungi documento",
  "drap_and_drop":"Trascina e rilascia i file qui",
  "or_browser_files":"o sfoglia i file sul suo computer",
  "browse_files":"Sfoglia file",
  "download":"Scarica",
  "Oops_error":"Ops! I dati richiesti non sono stati trovati",
  "go_to_home":"Torna alla pagina iniziale",
  "This_field_is_required":"Questo campo e' obbligatorio",
  "PendingForm":"Modulo in sospeso",
  "NoFollowUpRecords":"Nessun record di follow-up",
  "Right":"Destra",
  "Both":"Bilaterale",
  "Left":"Sinistra",
  "shoulder":"Spalla",
  "hip_form":"Anca",
  "Foot_And_Ankle":"Piede e caviglia",
  "Hand_And_Wrist":"Mano e polso",
  "General":"Generale",
  "adolescent_knee":"Ginocchio Adolescente",
  "Elbow":"Gomito",
  "title":"Titolo",
  "description":"Descrizione",
  "enter_your_description_here":"Inserisca qui la sua descrizione",
  "enter_your_title_here":"Inserisca qui il suo titolo",
  "progress":"In corso",
  "done":"Completato",
  "reset":"Ripristina",
  "send":"Invia",
  "Edit":"Modifica",
  "delete":"Elimina",
  "Are_you_sure_want_to_done_the_remainder":"È sicuro di voler completare questa nota?",
  "Confirm":"Conferma",
  "Update":"Aggiorna",
  "success":"Completata",
  "error":"Errore",
  "Are_you_sure_you_want_to_delete":"È sicuro di voler eliminare",
  "cancel":"Annulla",
  "Hour_Time":"Ora in formato 24 ore",
  "Knee":"Ginocchio",
  "no":"No ",
  "yes":"Si",
  "Year":"0 Anno, 0 Mesi, 0 Giorni",
  "Bilateral":"Ambidestro",
  "Left_Arm":"Spalla sinistra",
  "Right_Arm":"Spalla destra",
  "out_of":"su",
  "Right_Elbow":"Gomito destro",
  "Left_Elbow":"Gomito sinistro",
  "right_foot_ankle":"Piede e/o caviglia destra",
  "left_foot_ankle":"Piede e/o caviglia sinistra",
  "Right_hand_and_wrist":"Mano e polso destro",
  "Left_hand_and_wrist":"Mano e polso sinistro",
  "Hip_Function_R":"Anca destra",
  "Hip_Function_L":"Anca sinistra",
  "Right_Knee":"Ginocchio Destro",
  "Left_Knee":"Ginocchio Sinistro",
  "Right_Pedi_Knee":"Ginocchio destro",
  "Left_Pedi_Knee":"Ginocchio sinistro",
  "Scoring_Self_Evaluation":"Punteggio dell'autovalutazione",
  "presciption_form":"Ricetta",
  "insurance_form":"Assicurazione",
  "letter_form":"Lettera",
  "refferal_form":"Prescrizione",
  "medical_form":"Medicale",
  "are_you_sure_delete_account":"È sicuro di voler cancellare il proprio account?",
  "Adult_Knee_Pain":"Dolore al ginocchio negli adulti",
  "Knee_pain_13_18_year":"Dolore al ginocchio",
  "day_pdata":"Giorno",
  "days_pdata":"Giorni",
  "month_pdata":"Mese",
  "months_pdata":"Mesi",
  "year_pdata":"Anno",
  "years_pdata":"Anni",
  "Name":"Nome",
  "akunah_id":"Akunah ID",
  "mobile":"Telefono",
  "email":"Email",
  "upload_a_profile":"Carica un'immagine",
  "No_special_characters_are_allowed":"Non sono consentiti caratteri speciali",
  "numbers_are_not_allowed":"I numeri non sono consentiti",
  "maximum_50_character_are_allowed":"Sono consentiti al massimo 50 caratteri",
  "invalid_character":"Carattere non valido",
  "fields_only_contain_10_character":"Il campo contiene solo 10 caratteri",
  "maximum_10_Numbers_are_allowed":"Sono consentiti al massimo 10 numeri",
  "Only_I":"Sono consentiti solo numeri",
  "invalid_home_phone_number":"Numero di telefono di casa non valido",
  "special_characters_are_not_allowed":"I caratteri speciali non sono consentiti",
  "maximum_digit_are_allowed":"È consentito al massimo 1 cifra",
  "invalid_date":"Data non valida",
  "maximum_10_digits_are_allowed":"Sono consentiti al massimo 10 cifre",
  "switch_profile":"Cambia Profilo",
  "loading":"Carimento",
  "error_max_profile_limit":"Hai raggiunto il numero massimo di account consentiti utilizzando un indirizzo email",
  "error_upcoming_appointments":"Nessun appuntamento futuro trovato",
  "error_past_visits":"Nessuna visita passata trovata",
  "error_documents":"Nessun documento trovato",
  "country_hawaii":"(UTC-10:00) Hawaii",
  "country_tahiti":"(UTC-10:00) Tahiti",
  "country_pitcairn":"(UTC-08:00) Pitcairn",
  "country_niue":"(UTC-11:00) Niue",
  "country_mountain":"(UTC-07:00) Mountain Time",
  "country_arizona":"(UTC-07:00) Arizona",
  "country_whitehorse":"(UTC-08:00) Whitehorse",
  "country_chihuahua":"(UTC-07:00) Chihuahua, Mazatlan",
  "country_edmonton":"(UTC-07:00) Edmonton",
  "country_gambier":"(UTC-09:00) Gambier",
  "country_yellowknife":"(UTC-07:00) Yellowknife",
  "country_dawson":"(UTC-07:00) Dawson Creek",
  "country_belize":"(UTC-06:00) Belize",
  "country_hermosillo":"(UTC-07:00) Hermosillo",
  "country_mexico":"(UTC-06:00) Città del Messico ",
  "country_regina":"(UTC-06:00) Regina",
  "country_tegucigalpa":"(UTC-06:00) Tegucigalpa",
  "country_centraltime":"(UTC-06:00) Central Time ",
  "country_rarotonga":"(UTC-10:00) Rarotonga",
  "country_pago":"(UTC-11:00) Pago Pago",
  "country_pacific":"(UTC-08:00) Pacific Time",
  "country_alaska":"(UTC-09:00) Alaska Time",
  "country_vancouver":"(UTC-08:00) Vancouver",
  "country_tijuana":"(UTC-08:00) Tijuana",
  "country_salvador":"(UTC-06:00) El Salvador",
  "country_costa":"(UTC-06:00) Costa Rica",
  "country_winnipeg":"(UTC-06:00) Winnipeg",
  "country_galapagos":"(UTC-06:00) Galapagos",
  "country_managua":"(UTC-06:00) Managua",
  "country_america_cancun":"(UTC-05:00) America Cancun",
  "country_guatemala":"(UTC-06:00) Guatemala",
  "country_bogota":"(UTC-05:00) Bogotá",
  "country_easter_island":"(UTC-05:00) Isola di Pasqua",
  "country_iqaluit":"(UTC-05:00) Iqaluit",
  "country_toronto":"(UTC-05:00) Toronto",
  "country_eastern_time":"(UTC-05:00) Eastern Time",
  "country_havana":"(UTC-05:00) L'Avana",
  "country_lima":"(UTC-05:00) Lima",
  "country_jamaica":"(UTC-05:00) Giamaica",
  "country_panama":"(UTC-05:00) Panama",
  "country_port_au_prince":"(UTC-05:00) Port-au-Prince",
  "country_nassau":"(UTC-05:00) Nassau",
  "country_rio_branco":"(UTC-05:00) Rio Branco",
  "country_barbados":"(UTC-04:00) Barbados",
  "country_halifax":"(UTC-04:00) Halifax",
  "country_bermuda":"(UTC-04:00) Bermuda",
  "country_boa_vista":"(UTC-04:00) Boa Vista",
  "country_curacao":"(UTC-04:00) Curaçao",
  "country_marquesas":"(UTC-09:30) Isole Marchesi",
  "country_caracas":"(UTC-04:00) Caracas",
  "country_grand_turk":"(UTC-04:00) Grand Turk",
  "country_la_paz":"(UTC-04:00) La Paz",
  "timezone_guyana":"(UTC-04:00) Guyana",
  "timezone_port_of_spain":"(UTC-04:00) Port of Spain",
  "timezone_manaus":"(UTC-04:00) Manaus",
  "timezone_martinique":"(UTC-04:00) Martinica",
  "timezone_porto_velho":"(UTC-04:00) Porto Velho",
  "timezone_santo_domingo":"(UTC-04:00) Santo Domingo",
  "timezone_puerto_rico":"(UTC-04:00) Porto Rico",
  "timezone_araguaina":"(UTC-03:00) Araguaina",
  "timezone_belem":"(UTC-03:00) Belém",
  "timezone_buenos_aires":"(UTC-03:00) Buenos Aires",
  "timezone_newfound_time":"(UTC-03:30) St. Johns",
  "timezone_asuncion":"(UTC-03:00) Asunción",
  "timezone_campo":"(UTC-03:00) Campo Grande",
  "timezone_cayenne":"(UTC-03:00) Cayenne",
  "timezone_cuiaba":"(UTC-03:00) Cuiabá",
  "timezone_maceio":"(UTC-03:00) Maceió",
  "timezone_godthab":"(UTC-03:00) Godthab",
  "timezone_fortaleza":"(UTC-03:00) Fortaleza",
  "timezone_miquelon":"(UTC-03:00) Miquelon",
  "timezone_palmer":"(UTC-03:00) Palmer",
  "timezone_montevideo":"(UTC-03:00) Montevideo",
  "timezone_recife":"(UTC-03:00) Recife",
  "timezone_rothera":"(UTC-03:00) Rothera",
  "timezone_paramaribo":"(UTC-03:00) Paramaribo",
  "timezone_punta_arenas":"(UTC-03:00) Punta Arenas",
  "timezone_santig":"(UTC-03:00) Santiago",
  "timezone_salvador":"(UTC-03:00) Salvador",
  "timezone_stanley":"(UTC-03:00) Stanley",
  "timezone_south_georgia":"(UTC-02:00) Georgia del Sud",
  "timezone_noronha":"(UTC-02:00) Noronha",
  "timezone_sao_pulo":"(UTC-02:00) San Paolo",
  "timezone_azores":"(UTC-01:00) Azzorre",
  "timezone_abidjan":"(UTC+00:00) Abidjan",
  "timezone_cape_verde":"(UTC-01:00) Capo Verde",
  "timezone_scoresbysund":"(UTC-01:00) Scoresbysund",
  "timezone_bissau":"(UTC+00:00) Bissau",
  "timezone_accra":"(UTC+00:00) Accra",
  "timezone_danmarkshavn":"(UTC+00:00) Danmarkshavn",
  "timezone_casablanca":"(UTC+00:00) Casablanca",
  "timezone_dublin":"(UTC+00:00) Dublino",
  "timezone_canary_islands":"(UTC+00:00) Isole Canarie",
  "timezone_el_aaiun":"(UTC+00:00) El Aaiun",
  "timezone_thule":"(UTC-04:00) Thule",
  "timezone_gmt":"(UTC+00:00) Ora del meridiano di Greenwich",
  "timezone_lisbon":"(UTC+00:00) Lisbona",
  "timezone_faeroe":"(UTC+00:00) Fær Øer",
  "timezone_reykjavik":"(UTC+00:00) Reykjavik",
  "timezone_monrovia":"(UTC+00:00) Monrovia",
  "timezone_algiers":"(UTC+01:00) Algeri",
  "timezone_london":"(UTC+00:00) Londra",
  "timezone_amsterdam":"(UTC+01:00) Amsterdam",
  "timezone_andorra":"(UTC+01:00) Andorra",
  "timezone_berlin":"(UTC+01:00) Berlino",
  "timezone_budapest":"(UTC+01:00) Budapest",
  "timezone_ceuta":"(UTC+01:00) Ceuta",
  "timezone_brussels":"(UTC+01:00) Bruxelles",
  "timezone_copenhagen":"(UTC+01:00) Copenaghen",
  "timezone_gibrltar":"(UTC+01:00) Gibilterra",
  "timezone_prague":"(UTC+01:00) Praga",
  "timezone_lagos":"(UTC+01:00) Lagos",
  "timezone_luxembourg":"(UTC+01:00) Lussemburgo",
  "timezone_madrid":"(UTC+01:00) Madrid",
  "timezone_malta":"(UTC+01:00) Malta",
  "timezone_ndjamena":"(UTC+01:00) N'Djamena",
  "timezone_paris":"(UTC+01:00) Parigi",
  "timezone_oslo":"(UTC+01:00) Oslo",
  "timezone_monaco":"(UTC+01:00) Monaco",
  "timezone_tirane":"(UTC+01:00) Tirana",
  "timezone_rome":"(UTC+01:00) Roma",
  "timezone_vienna":"(UTC+01:00) Vienna",
  "timezone_tunis":"(UTC+01:00) Tunisi",
  "timezone_warsaw":"(UTC+01:00) Varsavia",
  "timezone_amman":"(UTC+02:00) Amman",
  "timezone_zurich":"(UTC+01:00) Zurigo",
  "timezone_athens":"(UTC+02:00) Atene",
  "timezone_burcharest":"(UTC+02:00) Bucarest",
  "timezone_chisinau":"(UTC+02:00) Chisinau",
  "timezone_stockholm":"(UTC+01:00) Stoccolma",
  "timezone_cairo":"(UTC+02:00) Il Cairo",
  "timezone_gaza":"(UTC+02:00) Gaza",
  "timezone_jerusalem":"(UTC+02:00) Gerusalemme",
  "timezone_johannesburg":"(UTC+02:00) Johannesburg",
  "timezone_helsinki":"(UTC+02:00) Helsinki",
  "timezone_khartoum":"(UTC+02:00) Khartoum",
  "timezone_belgrade":"(UTC+01:00) Belgrado",
  "timezone_damascus":"(UTC+02:00) Damasco",
  "timezone_maputo":"(UTC+02:00) Maputo",
  "timezone_kaliningrad":"(UTC+02:00) Kaliningrad",
  "timezone_riga":"(UTC+02:00) Riga",
  "timezone_kiev":"(UTC+02:00) Kiev",
  "timezone_nicosia":"(UTC+02:00) Nicosia",
  "timezone_tallinn":"(UTC+02:00) Tallinn",
  "timezone_tripoli":"(UTC+02:00) Tripoli",
  "timezone_sofia":"(UTC+02:00) Sofia",
  "timezone_vilnius":"(UTC+02:00) Vilnius",
  "timezone_istanbul":"(UTC+03:00) Istanbul",
  "timezone_baghdad":"(UTC+03:00) Baghdad",
  "timezone_minsk":"(UTC+03:00) Minsk",
  "timezone_windhoek":"(UTC+02:00) Windhoek",
  "timezone_nairobi":"(UTC+03:00) Nairobi",
  "timezone_moscow":"(UTC+03:00) Mosca",
  "timezone_qatar":"(UTC+03:00) Qatar",
  "timezone_beirut":"(UTC+02:00) Beirut",
  "timezone_riyadh":"(UTC+03:00) Riyadh",
  "timezone_syowa":"(UTC+03:00) Syowa",
  "timezone_tehran":"(UTC+03:30) Teheran",
  "timezone_baku":"(UTC+04:00) Baku",
  "timezone_dubai":"(UTC+04:00) Dubai",
  "timezone_mahe":"(UTC+04:00) Mahé",
  "timezone_mauritius":"(UTC+04:00) Mauritius",
  "timezone_tbilisi":"(UTC+04:00) Tbilisi",
  "timezone_samara":"(UTC+04:00) Samara",
  "timezone_reunion":"(UTC+04:00) Riunione",
  "timezone_yerevan":"(UTC+04:00) Erevan",
  "timezone_kabul":"(UTC+04:30) Kabul",
  "timezone_aqtau":"(UTC+05:00) Aqtau",
  "timezone_aqtobe":"(UTC+05:00) Aqtobe",
  "timezone_ashgabat":"(UTC+05:00) Ashgabat",
  "timezone_dushanbe":"(UTC+05:00) Dushanbe",
  "timezone_maldives":"(UTC+05:00) Maldive",
  "timezone_yekaterinburg":"(UTC+05:00) Ekaterinburg",
  "timezone_kerguelen":"(UTC+05:00) Kerguelen",
  "timezone_mawson":"(UTC+05:00) Mawson",
  "timezone_tashkent":"(UTC+05:00) Taskent",
  "timezone_colombo":"(UTC+05:30) Colombo",
  "timezone_kathmandu":"(UTC+05:45) Kathmandu",
  "timezone_omsk":"(UTC+06:00) Omsk",
  "timezone_india_standard_time":"(UTC+05:30) India",
  "timezone_chagos":"(UTC+06:00) Chagos",
  "timezone_dhaka":"(UTC+06:00) Dhaka",
  "timezone_bishkek":"(UTC+06:00) Bishkek",
  "timezone_cocos":"(UTC+06:30) Cocos",
  "timezone_thimphu":"(UTC+06:00) Thimphu",
  "timezone_almaty":"(UTC+06:00) Almaty",
  "timezone_vostok":"(UTC+06:00) Vostok",
  "timezone_rangoon":"(UTC+06:30) Rangoon",
  "timezone_christmas":"(UTC+07:00) Christmas",
  "timezone_davis":"(UTC+07:00) Davis",
  "timezone_karachi":"(UTC+05:00) Karachi",
  "timezone_hanoi":"(UTC+07:00) Hanoi",
  "timezone_beijing":"(UTC+08:00) Pechino",
  "timezone_hongkong":"(UTC+08:00) Hong Kong",
  "timezone_hovd":"(UTC+07:00) Hovd",
  "timezone_jakarta":"(UTC+07:00) Jakarta",
  "timezone_kuala_lumpur":"(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok":"(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk":"(UTC+07:00) Krasnojarsk",
  "timezone_brunei":"(UTC+08:00) Brunei",
  "timezone_macau":"(UTC+08:00) Macao",
  "timezone_makassar":"(UTC+08:00) Makassar",
  "timezone_choibalsan":"(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar":"(UTC+08:00) Ulaanbaatar",
  "timezone_manila":"(UTC+08:00) Manila",
  "timezone_pyongyang":"(UTC+08:30) Pyongyang",
  "timezone_singapore":"(UTC+08:00) Singapore",
  "timezone_irkutsk":"(UTC+08:00) Irkutsk",
  "timezone_taipei":"(UTC+08:00) Taipei",
  "timezone_jayapura":"(UTC+09:00) Jayapura",
  "timezone_dili":"(UTC+09:00) Dili",
  "timezone_perth":"(UTC+08:00) Perth",
  "timezone_yakutsk":"(UTC+09:00) Jakutsk",
  "timezone_palau":"(UTC+09:00) Palau",
  "timezone_seoul":"(UTC+09:00) Seoul",
  "timezone_tokyo":"(UTC+09:00) Tokyo",
  "timezone_darwin":"(UTC+09:30) Darwin",
  "timezone_dumont":"(UTC+10:00) Dumont D'Urville",
  "timezone_brisbane":"(UTC+10:00) Brisbane",
  "timezone_guam":"(UTC+10:00) Guam",
  "timezone_vladivostok":"(UTC+10:00) Vladivostok",
  "timezone_port_moresby":"(UTC+10:00) Port Moresby",
  "timezone_truk":"(UTC+10:00) Truk",
  "timezone_adelaide":"(UTC+10:30) Adelaide",
  "timezone_casey":"(UTC+11:00) Hobart",
  "timezone_melbourne_sydney":"(UTC+11:00) Melbourne, Sydney",
  "timezone_kosrae":"(UTC+11:00) Kosrae",
  "timezone_efate":"(UTC+11:00) Efate",
  "timezone_guadalcanal":"(UTC+11:00) Guadalcanal",
  "timezone_ponape":"(UTC+11:00) Ponape",
  "timezone_norfolk":"(UTC+11:00) Norfolk",
  "timezone_magadan":"(UTC+11:00) Magadan",
  "timezone_noumea":"(UTC+11:00) Nouméa",
  "timezone_majuro":"(UTC+12:00) Majuro",
  "timezone_funafuti":"(UTC+12:00) Funafuti",
  "timezone_nauru":"(UTC+12:00) Nauru",
  "timezone_tarawa":"(UTC+12:00) Tarawa",
  "timezone_kwajalein":"(UTC+12:00) Kwajalein",
  "timezone_enderbury":"(UTC+13:00) Enderbury",
  "timezone_wake":"(UTC+12:00) Wake",
  "timezone_fakaofo":"(UTC+13:00) Fakaofo",
  "timezone_wallis":"(UTC+12:00) Wallis",
  "timezone_fiji":"(UTC+13:00) Figi",
  "timezone_apia":"(UTC+14:00) Apia",
  "timezone_kiritimati":"(UTC+14:00) Kiritimati",
  "timezone_tongatapu":"(UTC+13:00) Tongatapu",
  "timezone_auckland":"(UTC+13:00) Auckland",
  "dateFormet":"Formato data",
  "Org_success":"La richiesta di unirsi all'organizzazione è stata inviata con successo",
  "Org_err":"Hai già aderito all'organizzazione",
  "Request_Organization":"Richiesta di una organizzazione",
  "organization_requested":"Richiesta di unirsi",
  "join":"Unisciti",
  "your_email":"email@address.com",
  "medical_summary":"Non hai ancora compilato il tuo riepilogo medico.",
  "medical_summary_msg":"Il mio riepilogo medico",
  "medical_success":"Il riepilogo medico è stato inviato con successo.",
  "patient_form_popup":"Desidera continuare a compilare il questionario a cui stava lavorando in precedenza?",
  "Please_confirm_that_your_details_are_correct":"Si prega di confermare che i suoi dati sono corretti",
  "Welcome_to_Akunah_your_Health_Passport":"Benvenuto in Akunah, il tuo Passaporto Sanitario.",
  "Your_helth_is_in_your_hands":"La tua salute è nelle tue mani.",
  "Lets_begin_your_journey":"Iniziamo il tuo percorso.",
  "Please_complete_your_profile":"Si prega di completare il suo profilo.",
  "Profile_complete":"Profilo completato!",
  "Would_you_like_to_add_another_profile_for_a_family_member":"Desidera aggiungere un altro profilo per un membro della famiglia?",
  "Alert_detected":"Allerta: Rilevati accessi multipli",
  "To_safeguard_your_data":"Per salvaguardare la sua privacy e la sicurezza dei dati, la pagina web è scaduta.",
  "Kindly_close_all_other_Akunah":"Si prega di chiudere tutte le altre schede di Akunah e premere 'Torna alla home' per continuare.",
  "Akunah_Team_is_dedicated":"Il team di Akunah è dedicato alla protezione dei suoi dati.",
  "Last_Created":"Ultimo Creato",
  "First_Created":"Primo Creato",
  "Alphabetically":"In ordine alfabetico (A-Z)",
  "Alphabetically1":"In ordine alfabetico (Z-A)",
  "Your_form_submission_was_successful_Clinician":"Il suo modulo è stato sottomesso con successo. Stiamo ora reindirizzando automaticamente all'app per i clinici.",
  "Your_form_submission_was_successful_Patient":"Il suo modulo è stato sottomesso con successo. Stiamo ora reindirizzando automaticamente all'app per i pazienti.",
  "If_this_doesnt_happen_within":"Se ciò non accade entro 5 secondi, per favore",
  "click_here":"clicca qui",
  "Save":"Salva",
  "Proceed_to_my_dashboard":"Vai al mio profilo",
  "myJourney":"Il Mio Viaggio",
  "Home":"Home",
  "No_journey":"Nessun viaggio trovato",
  "Share_results_with_your_doctor":"Condividi i risultati con il tuo medico",
  "If_you_would_like_to_share_your_results_with":"Se desideri condividere i tuoi risultati con",
  "Link_PROMs":"Link PROMs",
  "Welcome_to_akunah":"Benvenuto in Akunah",
  "Share_to_your_Doctor_using_one_of_the_3_options_below":"Condividi con il tuo Medico utilizzando una delle 3 opzioni qui sotto",
  "Doctor_Code":"Codice Medico",
  "Enter_the_code_that_has_been_provided_to_you_by_your":"Inserici il codice che ti è stato fornito dal tuo medico tramite email o in ambulatorio. Se il codice non è disponibile, si prega di controllare le tue visite recenti o cercare il tuo medico.",
  "Recent_Visits":"Visite Recenti",
  "Select_your_recent_doctor_or":"Seleziona qui il tuo medico recente o ",
  "organisation_here":"l'organizzazione qui",
  "Search":"Cerca",
  "Search_for_your_doctor_or":"Cerca il tuo medico o",
  "Please_confirm_that_the_following_details":"Conferma che i seguenti dettagli sono corretti",
  "Organisation":"Organizzazione",
  "PROMs_Specialty_Date":"PROMs Specialità & Data",
  "Get_started_by_completing_your":"Inizia completando i tuoi questionari PROMs ",
  "and_exploring_your_progress":"ed esplorando i tuoi progressi",
  "Fill_up_your_PROMs_questionnaire":"Compila il tuo questionario PROMs",
  "Hip":"Anca",
  "My_Progress":"Il Mio Progresso",
  "Completed":"Completato",
  "Expired":"Scaduto",
  "Incomplete":"Incompleto",
  "Specialty":"Specialità",
  "Doctor":"Medico",
  "Status":"Stato",
  "View_form":"Visualizza modulo",
  "Share_to_another_doctor":"Condividi con un altro medico",
  "Edit_form":"Modifica modulo",
  "Answer_the_form":"Rispondi al modulo",
  "Complete_the_form":"Completa il modulo",
  "Delete_the_form":"Elimina il modulo",
  "Share_to_doctor":"Condividi con il medico",
  "Your_account_has_been_created":"Il tuo account è stato creato",
  "Please_click_below_to_proceed_to_your_dashboard":"Per favore clicca qui sotto per andare al tuo profilo",
  "If_you_would_like_to_add_another_profile_for_a_family":"Se desideri aggiungere un altro profilo per un familiare, per favore",
  "Share":"Condividi",
  "Hip_Scoring_Self_Evaluation_Questionnaire":"Punteggio del questionario di autovalutazione dell'Anca",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire":"Punteggio del questionario di autovalutazione della Mano & Polso",
  "Right_Hand_nd_Wrist":"Mano & Polso Destro",
  "Left_Hand_nd_Wrist":"Mano & Polso Sinistro",
  "DOB_1":"Data di nascita",
  "Sex_1":"Sesso",
  "Address_1":"Indirizzo",
  "Please_Choose_PROMs_to_link":"Si prega di scegliere i PROMs da collegare",
  "Please_select_atleast_one_form_or_close":"Si prega di selezionare almeno un PROMs o di chiudere",
  "cant_find_appropriate_PROMs_to_link":"Non puoi trovare i PROMs da collegare?",
  "Request_New_PROMs":"Richiedi nuovi PROMs",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit":"Avviso: Hai un questionario incompleto da una visita recente. ",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted":"Se selezioni \"No\", verrà creato un nuovo questionario e quello incompleto verrà eliminato. ",
  "Would_you_like_to_continue_this_form":"Desideri continuare con questo?",
  "Note":"Nota:",
  "Expiring_in":"Scadenza in",
  "Date_Created":"Data di creazione",
  "Days":"Giorni",
  "Shared":"Condiviso",
  "Pending":"In sospeso",
  "Initial":"Iniziale",
  "Recent":"Recente",
  "Pain":"Dolore",
  "Function":"Funzione",
  "Share_Now":"Condividi ora",
  "Share_Later":"Condividi in seguito",
  "Updated":"Aggiornato",
  "Display_More":"Mostra altro",
  "Address_Now":"Rispondi adesso",
  "Address_Later":"Rispondi dopo",
  "You_have_pending_questionnaires":"Hai questionari in sospeso",
  "Notifications":"Notifiche",
  "Form_shared_successfully":"Modulo condiviso con successo",
  "You_have_already_shared_with_same_doctor_and_organization":"Hai gia' condiviso con lo stesso medico e organizzazione",
  "No_recent_notifications":"Nessuna notifica recente",
  "Have_a_good_day":"Buona giornata",
  "Patient_scores":"Punteggi del paziente",
  "Display less":"Mostra meno",
  "myJourney_Shoulder":"Spalla",
  "myJourney_Shoulder_txt":"Seleziona spalla per visualizzare il tuo percorso della spalla",
  "myJourney_Elbow":"Gomito",
  "myJourney_Elbow_txt":"Seleziona gomito per visualizzare il tuo percorso del gomito",
  "myJourney_Hand_Wrist":"Mano e polso",
  "myJourney_Hand_Wrist_txt":"Seleziona mano e polso per visualizzare il tuo percorso della mano e del polso",
  "myJourney_Hip":"Anca",
  "myJourney_Hip_txt":"Seleziona anca per visualizzare il tuo percorso dell'anca",
  "myJourney_knee":"Ginocchio",
  "myJourney_knee_txt":"Seleziona ginocchio per visualizzare il tuo percorso del ginocchio",
  "myJourney_Foot_Ankle":"Piede e caviglia",
  "myJourney_Foot_Ankle_txt":"Seleziona piede e caviglia per visualizzare il tuo percorso del piede e della caviglia",
  "data_sort":"Ordina",
  "Visit_Clinic":"Visita Clinica",
  "My_Visits":"Le mie visite",
  "Patient_Scoring_Forms":"Moduli di valutazione del paziente",
  "Other_findings":"Altre scoperte",
  "Organization":"Organizzazione",
  "more":"Altro",
  "mediacal_summary":"Riepilogo Medico",
  "my_consent":"I Miei Consensi",
  "Are_you_sure_you_want_to_reject":"Sei sicuro di voler bloccare questo rinvio del medico?",
  "reject":"Rifiuta",
  "i_accept_consent":"Consento a questo",
  "grant":"Concedi",
  "no_conscent_found":"Nessun consenso trovato",
  "rejected":"Respinto",
  "No_Time_Lines":"Nessuna Tempistica",
  "Hide_Score":"Nascondi Punteggio",
  "Show_Score":"Mostra Punteggio",
  "patient_sex":"Sesso",
  "Dominant_hand_1":"Mano Dominante",
  "patient_Initial_Assessment":"Valutazione Iniziale",
  "patient_Treatment_Category":"Categoria di Trattamento",
  "View_all":"Visualizza tutti",
  "Display_Less":"Mostra Meno",
  "patient_general_past_Medical_history":"Anamnesi Medica",
  "patient_general_past_Medication_name":"Elenco dei farmaci",
  "Evaluated_Knee":"Ginocchio Valutato",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function":"La tua mano e polso non interessati sono privi di dolore e consentono una funzione normale?",
  "Evaluated_Hip":"Anca Valutata",
  "Evaluated_Foot_Ankle":"Piede e Caviglia Valutati",
  "patient_Evaluated_Elbow":"Gomito Valutato",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function":"Il tuo piede e caviglia non interessati sono privi di dolore e consentono una funzione normale?",
  "Evaluated_Hand_Wrist":"Mano e Polso Valutati",
  "patient_shoulder_feeling_instability_q2":"Si prega di indicare quanto la sua spalla è instabile",
  "patient_shoulder_feeling_instability_q3":"Si prega di indicare quanto la sua spalla è instabile",
  "patient_Hand_BCTQ":"BCTQ Mano e Polso",
  "Paediatric_Knee_Satisfaction":"Soddisfazione Pediatrica al Ginocchio",
  "patient_foot_ankle_symptoms":"Altri Sintomi al Piede e Caviglia",
  "Right_Foot_and_Ankle":"Piede e Caviglia Destra",
  "Left_Foot_and_Ankle":"Piede e Caviglia Sinistra",
  "summary":"Sommario",
  "Shoulder_Treatment_Form":"Modulo di Trattamento della Spalla",
  "Adolescent_knee_Treatment_Form":"Modulo di Trattamento del Ginocchio Adolescente",
  "Adult_Knee_Treatment_Form":"Modulo di Trattamento del Ginocchio Adulto",
  "Paediatric_Knee_Treatment_Form":"Modulo di Trattamento del Ginocchio Pediatrico",
  "Hip_Treatment_Form":"Modulo di Trattamento dell'Anca",
  "Foot_Ankle_Treatment_Form":"Modulo di Trattamento del Piede e della Caviglia",
  "Elbow_Treatment_Form":"Modulo di Trattamento del Gomito",
  "Hand_Wrist_Treatment_Form":"Modulo di Trattamento della Mano e del Polso",
  "Upload_complete":"Caricamento completato",
  "Select_Date_Format":"Seleziona il Formato della Data",
  "timezone_casey1":"(UTC+11:00) Casey",
  "timezone_Kamchatskiy":"(UTC+12:00) Petropavlovsk-Kamchatskiy",
  "timezone_Guayaquil":"(UTC-05:00) Guayaquil",
  "requested":"Richiesta una",
  "Display_name":"Mostra nome",
  "Are_you_sure_you_want_to_delete_Consent":"Sei sicuro di voler rifiutare questo consenso?",
  "form":"modulo",
  "Requested_by":"Riecheda da",
  "requested_hip_form":"Richiesta del modulo per l'anca",
  "requested_shoulder_form":"Richiesta del modulo per la spalla",
  "requested_knee_form":"Richiesta del modulo per il ginocchio",
  "requested_foot_form":"Richiesta del modulo per il piede e la caviglia",
  "requested_hand_form":"Richiesta del modulo per la mano e il polso",
  "requested_elbow_form":"Richiesta del modulo per il gomito",
  "requested_General_form":"Richiesta del modulo generale",
  "requested_Paediatric_form":"requested a Paediatric Knee form",
  "requested_Adolescent_form":"requested a Adolescent Knee form",
  "Expiring_in_Tomorrow":"Expiring tomorrow",
  "PROMs_questionnaire":"Il Dr. [nome completo del medico che ha fatto la richiesta] ha richiesto di compilare il questionario PROMs per [specialità]",
  "new_message":"Hai un nuovo messaggio da [nome completo del mittente del messaggio]",
  "new_appointment":"Hai un nuovo appuntamento con il Dr. [nome completo del medico]",
  "upcoming_appointment":"Promemoria: prossimo appuntamento con il Dr. [nome completo] il [data]",
  "pending_message":"Promemoria: hai un questionario PROMs per [specialità] in sospeso.",
  "document_message":"Il Dr. [nome completo di chi ha condiviso] ha condiviso un documento con te.",
  "requested_message":"Il Dr. [nome completo di chi ha fatto la richiesta] ha richiesto il tuo consenso.",
  "patient_added_message":"Sei stato aggiunto come paziente del Dr. [nome completo] presso [organizzazione].",
  "cancelled_appointment_message":"Il tuo appuntamento con il Dr. [nome completo] il [data dell'appuntamento] è stato annullato.",
  "Mark_all_read":"Segna tutto come letto",
  "Delete_All":"Elimina tutto",
  "No_Notification":"Nessuna notifica",
  "You_have_not":"Non hai ancora iniziato il tuo percorso. Compila un questionario PROMs per iniziare il tuo percorso.",
  "pass_verification1":"La password deve contenere almeno 8 caratteri.*",
  "pass_verification2":"La password deve contenere almeno 1 lettera maiuscola.* ",
  "pass_verification3":"La password deve contenere almeno 1 lettera minuscola.*",
  "pass_verification4":"La password deve contenere almeno 1 cifra.*",
  "loginTitle":"Akunah: Accedi ::",
  "Create_patient_mobile":"Create new profile",
  "Select_Profile_mobile":"Select your profile",
  "Profile_Created_mobile":"Profile Created",
  "Your_account_has_been_created_mobile":"Your account has been created.",
  "Please_tap_below_to_sign_in_to_your_workspace_mobile":"Please tap below to sign in to your workspace.",
  "Start_Journey_mobile":"Start Journey",
  "If_add_another_profile_mobile":"If you would like to add another profile for a family member, please",
  "Create_Profile_mobile":"Create Profile",
  "change_Profile_Pic_mobile":"Change profile photo",
  "MobileImp_mobile":"Mobile*",
  "Gender_mobile":"Gender",
  "Country_1_mobile":"Country",
  "Med_1_mobile":"Med",
  "Appoinments_mobile":"Appointments",
  "View_Profile_mobile":"View Profile",
  "Sign_out_mobile":"Sign out",
  "My_Appointments_mobile":"My Appointments",
  "Appoinments_Past_mobile":"Past",
  "No_appointments_mobile":"No appointments",
  "When_appointment_available_mobile":"When your next appointment is available, it will be displayed here",
  "Available_soon_mobile":"Available soon!",
  "please_akunah_desktop_mobile":"To access it now, please login tome.akunah.comon your desktop.",
  "All_Notes_mobile":"All Notes",
  "AddButton_mobile":"AddButton",
  "Start_your_note_mobile":"Start your note...",
  "Done_mobile":"Done",
  "Note_Title_mobile":"Note Title",
  "New_Consents_mobile":"New Consents",
  "Signed_Consents_mobile":"Signed Consents",
  "Consent_mobile":"Consent",
  "Signed_Type_mobile":"Signed by Type",
  "Submit_Consent_mobile":"Submit Consent",
  "Reject_Consent_mobile":"Reject Consent",
  "Type_name_mobile":"Type your name",
  "Signature_mobile":"Signature",
  "No_consents_mobile":"No consents",
  "Any_display_ready_mobile":"Any new or signed consents will display here when ready",
  "Clinician_Documents_mobile":"Clinician Documents",
  "Uploaded_Documents_mobile":"Uploaded Documents",
  "Upload_Document_mobile":"Upload Document",
  "Upload_file_mobile":"Upload file",
  "Upload_photo_mobile":"Upload photo",
  "Take_photo_mobile":"Take photo",
  "Uploading_1_mobile":"Uploading",
  "New_1_mobile":"New",
  "Share_document_mobile":"Share this document",
  "Enter_email_mobile":"Enter email",
  "Add_message_like_mobile":"Add a message, if you’d like.",
  "Are_delete_account_mobile":"Are you sure you want to delete your account?",
  "No_display_mobile":"No documents to display",
  "All_clinician_documents_mobile":"All clinician documents will display here",
  "New_Messages_mobile":"New Messages",
  "Read_Messages_mobile":"Read Messages",
  "Any_new_unread_messages_ready_mobile":"Any new or unread messages will be displayed here when ready",
  "Shoulder_Journey_mobile":"Shoulder Journey",
  "Hip_Journey_mobile":"Hip Journey",
  "Foot_Ankle_Journey_mobile":"Foot and Ankle Journey",
  "Elbow_Journey_mobile":"Elbow Journey",
  "Knee_Journey_mobile":"Knee Journey",
  "Hand_Wrists_Journey_mobile":"Hand and Wrist Journey",
  "New_Form_mobile":"New Form",
  "Start_new_form_mobile":"Start new form",
  "Requested_Forms_mobile":"Requested Forms",
  "Incomplete_Forms_mobile":"Incomplete Forms",
  "Completed_Forms_mobile":"Completed Forms",
  "No_Available_mobile":"No Forms Available",
  "Start_new_journey_mobile":"Start a new form to begin your journey.",
  "View_Results_mobile":"View Results",
  "Complete_Form_mobile":"Complete Form",
  "Delete_Form_mobile":"Delete Form",
  "Edit_Details_mobile":"Edit Details",
  "Personal_Details_mobile":"Personal Details",
  "DOB_1_mobile":"DOB",
  "Unknown_Gender_mobile":"Unknown Gender",
  "Mobile_mobile":"Mobile",
  "Next_kin’s_name_mobile":"Next of kin’s name",
  "Next_kin’s_relationship_mobile":"Next of kin’s relationship",
  "GP_Details_mobile":"GP Details",
  "GP_Name_mobile":"GP Name",
  "Practice_mobile":"Practice",
  "Edit_Profile_mobile":"Edit Profile",
  "Full_Name_mobile":"Full Name",
  "Country_Code_mobile":"Country Code",
  "GP_Full_Name_mobile":"GP Full Name",
  "Doctor’s_full_name_mobile":"Doctor’s full name",
  "All_mobile":"All",
  "No_notifications_mobile":"No recent notifications",
  "Any_new_available_mobile":"Any new or unread notifications will be displayed here when available",
  "Account_mobile":"Account",
  "Manage_My_Account_mobile":"Manage My Account",
  "Change_Password_mobile":"Change Password",
  "Privacy_mobile":"Privacy",
  "Time_mobile":"Time",
  "Date_Format_mobile":"Date & Format",
  "Support_mobile":"Support",
  "All_Notifications_mobile":"All Notifications",
  "Receive_notifications_mobile":"Receive all notifications",
  "Journey_Updates_mobile":"Journey Updates",
  "Receive_appointment_notifications_mobile":"Receive all appointment notifications",
  "Start_Form_mobile":"Start Form",
  "Your_journey_starts_here_mobile":"Your journey starts here",
  "To_recovery_questions_mobile":"To get started with your journey to recovery, we just ask you to please best answer the following questions.",
  "Please_estimate_questions_mobile":"Please estimate 12-15 minutes to answer these questions.",
  "Shoulder_Form_mobile":"Shoulder Form",
  "Save_and_Continue_mobile":"Save & Continue",
  "How_years_smoke_mobile":"How many years did you smoke?",
  "patient_shoulder_pain_inner_value_mobile":"(0 means no pain, 15 means intolerable pain)",
  "Scoring_Key_mobile":"Scoring Key",
  "patient_shoulder_wosi_1_mobile":"Western Ontario Shoulder Instability Index (WOSI)",
  "patient_shoulder_wosi_sectionA_phy_mobile":"PHYSICAL SYMPTOMS",
  "patient_shoulder_wosi_sectionB_sp_mobile":"SPORTS/RECREATION/WORK",
  "patient_shoulder_wosi_sectionC_life_mobile":"LIFESTYLE",
  "patient_shoulder_wosi_sectionD_emo_mobile":"EMOTIONS",
  "Specific_AC_Score_mobile":"Specific AC Score (SACS)",
  "patient_shoulder_sacs_sectionA_pain_mobile":"Pain",
  "patient_shoulder_sacs_sectionB_function_mobile":"FUNCTION",
  "patient_shoulder_sacs_sectionC_Qua_mobile":"Quality of life and satisfaction",
  "Finish_mobile":"Finish",
  "Share_Results_mobile":"Share Results",
  "Share_Doctor_mobile":"Share to your Doctor",
  "Choose_share_results_doctor_mobile":"Choose one of the 3 options below to share your results with your doctor",
  "Share_your_Clinician_mobile":"Share with your Clinician",
  "Select_recent_doctor_organisation_mobile":"Select your recent doctor or organisation here",
  "Clinician_Search_mobile":"Clinician Search",
  "Search_doctor_organisation_mobile":"Search for your doctor or organisation here",
  "Clinician_Code_mobile":"Clinician Code",
  "Enter_doctor_code_search_mobile":"Enter the code that has been provided to you by your doctor in your email or in the clinic. If the code is not available, please check you recent visits or use the search.",
  "Skip_sharing_now_mobile":"Skip sharing for now",
  "Code_please_try_again_mobile":"Code incorrect, please try again.",
  "Confirm_details_mobile":"Confirm details",
  "PROMs_Speciality_Date_mobile":"PROMs Speciality & Date",
  "Results_Shared_mobile":"Results Shared!",
  "Your_successfully_doctor_mobile":"Your results has been successfully shared with your doctor.",
  "Please_results_mobile":"Please tap below to view your results.",
  "If_another_doctor_mobile":"If you would like to share with another doctor",
  "please_mobile":"please",
  "click_here_mobile":"click here",
  "Pain_Score_mobile":"Pain Score",
  "Recent_Scores_mobile":"Recent Scores",
  "Right_Arm_mobile":"Right Arm",
  "SACS_Score_mobile":"SACS",
  "complete_mobile":"Complete",
  "Add_Note_mobile":"Add Note",
  "Pending_Forms_mobile":"Pending Forms",
  "Your_pending__will_display_mobile":"Your pending forms will be displayed here",
  "Your_Incomplete_display_mobile":"Your Incomplete Forms will be displayed here",
  "Your_complete_display_mobile":"Your complete forms will be displayed here",
  "PROMs_Shoulder_request_mobile":"PROMs Shoulder request",
  "New_message_mobile":"New message",
  "New_appointment_mobile":"New appointment",
  "New_Consent_request_mobile":"New Consent request",
  "New_shared_document_mobile":"New shared document",
  "New_Organisation_Clinician_mobile":"New Organisation/Clinician",
  "This_field_cannot_empty_mobile":"This field cannot be empty.",
  "List_work_activities_mobile":"List work activities ...",
  "List_hobby_activities_mobile":"List hobby and sport activities ...",
  "patient_shoulder_wosi_sectionA_mobile":"SECTION A:",
  "patient_shoulder_wosi_sectionB_mobile":"SECTION B:",
  "patient_shoulder_wosi_sectionC_mobile":"Section C:",
  "patient_shoulder_wosi_sectionD_mobile":"Section D:",
  "patient_shoulder_sacs_sectionA_mobile":"Section A:",
  "patient_shoulder_sacs_sectionB_mobile":"Section B:",
  "patient_shoulder_sacs_sectionC_mobile":"Section C:",
  "sac_explaination":"Explanation",
  "patient_shoulder_sacs_explanation_mobile":"For each question, you will be asked to rate either your level of pain or difficulty performing different tasks. The rating scale is designed so that 0 reflects no pain or difficulty while 10 reflects the most severe amount of pain or difficulty.",
  "patient_shoulder_sacs_a3_note_mobile":"(e.g. Laptop or hand bag strap).",
  "patient_shoulder_sacs_b2_note_mobile":"(e.g. Resting your arm in a pocket or using a sling).",
  "patient_shoulder_sacs_b3_note_mobile":"(e.g. Push up, cycling, yoga).",
  "patient_shoulder_sacs_b4_note_mobile":"(e.g. Bag of shopping).",
  "patient_shoulder_sacs_b5_note_mobile":"(e.g. To reach across and touch the unaffected shoulder).",
  "patient_shoulder_sacs_b6_note_mobile":"(e.g. Greater than five kilograms).",
  "patient_shoulder_sacs_b7_note_mobile":"(e.g. Holding the steering wheel whilst driving).",
  "patient_shoulder_sacs_c1_note_mobile":"(Including the presence of a lump).",
  "Function_Score_mobile":"Function Score",
  "Instability_Score_mobile":"Instability Score",
  "patient_shoulder_pain_q2_mobile":"On a scale of 0-15, what is the highest level of pain that you have experienced in your shoulder during ordinary activities within the last 24 hours?",
  "patient_shoulder_pain_q6_mobile":"Do you take pain medication",
  "patient_shoulder_pain_q6_mobile_sub":"(eg paracetamol etc)?",
  "patient_shoulder_pain_q6_a2_q1_mobile":"Do you take strong pain medication",
  "patient_shoulder_pain_q6_a2_q1_mobile_sub":"(eg codeine, tramadol, morphine etc)?",
  "patient_shoulder_feeling_instability_q1_b1_mobile_sub":"(as if it is going to dislocate)?",
  "patient_shoulder_feeling_instability_q1_mobile":"Does your shoulder feel unstable",
  "patient_shoulder_wosi_instruction_mobile":"INSTRUCTIONS",
  "Next_of_kins_number_mobile":"Next of kin’s number",
  "Next_of_Kin_mobile":"Next of Kin",
  "Doctor_Name_mobile":"Doctor's name",
  "Doctors_practice_mobile":"Doctor’s practice",
  "Doctors_practice_address_mobile":"Doctor’s practice address",
  "Language_mobile":"Languages",
  "No_Internet_Connection_mobile":"No Internet Connection",
  "Please_type_your_name_mobile":"Please type your name",
  "Choose_Sign_Type_mobile":"Choose Sign Type",
  "Signed_by_Draw_mobile":"Signed by Draw",
  "select_image_mobile":"select image",
  "Consent_rejected_mobile":"Consent rejected",
  "Consent_submitted_mobile":"Consent submitted"
}