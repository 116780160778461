import React, { useState, useEffect } from "react";
import settingsStyle from "../Settings.module.css";
import Checkbox from "../../custom/checkbox/Checkbox";
import Dropdown from "../../custom/dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../../axiosinstance";
import useTranslation from "../../customHooks/translations";
import LanguageHandler from "../../LanguageHandler";
import Message from "../../custom/toster/Message";

import { loginData, loginVisitsData } from "../../../redux/actions/index";
import { getKeyBySimilarValue } from "../../../utilities/LocationTranslation";
function Language() {
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const login2 = useSelector((state) => state.loginPatient.arr[0].patient);

  const dispatch = useDispatch();
  const axiosInstance = useAxios();
  const [profile, setProfile] = useState("");
  const [dateFormatData, setdateFormatData] = useState([]);
  const [datatimezone, setDatatimeZone] = useState([]);
  const [timezone, setTimezone] = useState("");
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [dateFormat, setdateFormat] = useState(login2.dateFormat);
  const [refs, setRefs] = useState("");
  const [timeformat, setTimeformat] = useState(login2.timeformat);
  const [show, setShow] = useState(login2.timeformat === "1" ? false : true);
  useEffect(() => {
    axiosInstance.current
      .get(`extapp/doctors/getAllDateFormats/${login.patient.ak_id}`)
      .then((res) => {
        setdateFormatData(res.data.data);
        // console.log(res.data.data);
      });
  }, []);

  const data1 = dateFormatData.map((item) => {
    return { value: item.value, label: item.text };
  });
  useEffect(() => {
    if (timeformat === "1") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [timeformat]);

  let akToken = localStorage.getItem("AkTOKENI");

  const translation = useTranslation();
  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getDetailsOfPatient?patient_id=${akToken}&use_for=patient`
      );
      if (result.status === 200) {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          dispatch(loginVisitsData(result.data));
          sessionStorage.setItem(
            "lang_",
            result.data.data[0].patient.user_language
          );
          sessionStorage.setItem("isConsent", result.data.data[0].patientInfo.isConsent)
          setRefs("");
          setTimeout(() => {
            setMessage(false);
          }, 3000);
        }
      }
    } catch (err) {
     
    } finally {
     
    }
  };

  useEffect(() => {
    if (login) {
      setProfile(login.patient);
      setTimezone(login.patient.timezone);
    }
  }, [login]);

  useEffect(() => {
    const getData = async () => {
      const response = await axiosInstance.current.get(`extapp/alltimezones`);
      setDatatimeZone(response.data.data);
    };
    getData();
  }, []);

  const changeTimezone = (event) => {
    setTimezone(event);
    setRefs(1);
  };
  const handleTimeformat = (e) => {


    if (e === false) {
      setTimeformat("1");
      setRefs(2);
    }
    if (e === true) {
      setTimeformat("2");
      setRefs(2);
    }
  };

  const handleSave = () => {
    const json = JSON.stringify({
      timeformat: timeformat,
      timezone: timezone,
      dateFormat: dateFormat,
    });
    axiosInstance.current
      .put(`extapp/doctors/doctor_update_profile?ak_id=${akToken}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleConnectMe();
        setMessage(translation.profile_updated);
        setMessageType("success");
      })
      .catch((err) => {
        setMessage(translation.profile_not_updated);
        setMessageType("error");
      });
    setTimeout(() => {
      setMessage(false);
    }, 3000);
  };

  useEffect(() => {
    if (refs === 2) {
      handleSave();
    }
    if (refs === 1) {
      handleSave();
    }
  }, [refs]);
 
// Change timezone function
 const data = datatimezone.map((item) => {
  
  // let tdata = getKeyBySimilarValue(item.utctime);
  // return { value: item.time, label: translation[tdata] };

  return { value: item.time, label: item.utctime }
});


  const [langData, setlangData] = useState([]);
  const getData = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getLanguages/${login.patient.ak_id}`
      );
    
      if (result.status === 200) {
        setlangData(result.data.data);
      }
    } catch (err) {
    
    } finally {
     
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const changeDateFormat = (event) => {
    setdateFormat(event);
    handleSave();
  };

  return (
    <div className="">
      {message && <Message message={message} type={messageType} />}
      <div className="mb_15">
        <h4 class={`${settingsStyle.content_main_title}`}>
          {translation.Language}
        </h4>
        <LanguageHandler langData={langData}></LanguageHandler>
      </div>
      <br></br>
      <div className="mb_15">
        <h4 class={`${settingsStyle.content_main_title}`}>
          {translation.timezone}
        </h4>
        <Dropdown
          data={data}
          label=""
          styleClass="language_drp_dwn"
          value={timezone}
          placeholder={translation.Select}
          onChange={(e) => changeTimezone(e)}
        />
      </div>
      <br />
      <div className="mb_15">
        <h4 class={`${settingsStyle.content_main_title}`}>
          {translation.Timeformat}
        </h4>

        <Checkbox
          label={translation.Hour_Time}
          selected={show}
          onChange={(e) => handleTimeformat(e)}
          styleClass="custom_check_box tick"
          id="Time_format"
        />
      </div>
      <div className="mb_15">
        <h4 class={`${settingsStyle.content_main_title}`}>
          {translation.dateFormet}
        </h4>
        <Dropdown
          data={data1}
          label=""
          styleClass="language_drp_dwn"
          value={dateFormat}
          placeholder={translation.Select_Date_Format}
          onChange={(e) => changeDateFormat(e)}
        />
      </div>
    </div>
  );
}

export default Language;
