import React, { useState } from "react";
import PropTypes from "prop-types";

import { validateRadioSwitch } from "../../../utilities/Validator";

const RadioSwitch = ({

  title,
  labelLeft,
  labelRight,
  idLeft,
  idRight,
  selectedRadio,
  placeholder,
  validators,
  type,
  styleClass,
  onChange,
  formGroupClass,
  value,
  name
}) => {
  const [error, setError] = useState(false);
  const handleChangeLeft = (event) => {
    let value = event.target.type === 'radio' ? event.target.checked : event.target.value;
    let updatedValue = value === true ? "0" : "";
    setError(validateRadioSwitch(validators, value));
   
    onChange(updatedValue);
  };
  const handleChangeRight = (event) => {
    let value = event.target.type === 'radio' ? event.target.checked : event.target.value;
    let updatedValue = value === true ? "1" : "";
    setError(validateRadioSwitch(validators, value));

    onChange(updatedValue);
  };
  
  let lang= sessionStorage.getItem("lang_")

  return (
    <div className={`${lang == "ara" ? "areradio" : ""}`}>
      <div className={`form-group custom_radio_switch ${value ? "switch_active" : ""} ${formGroupClass}`}>
        <div className={`switch-title`}>{title}</div>
        <input
          type="radio"
          id={idLeft}
          name={name}
          value=""
          onChange={handleChangeLeft}
          checked={value === "0"}
        />
        <label htmlFor={idLeft}>{labelLeft}</label>

        <input
          type="radio"
          id={idRight}
          name={name}
          value=""
          onChange={handleChangeRight}
          checked={value === "1"}
        />
        <label htmlFor={idRight}>{labelRight}</label>
        {error && <span className="text-danger">{error.message}</span>}
      </div>
    </div>
  );
};

RadioSwitch.propTypes = {
  // valueLeft: PropTypes.string,
  // valueRight: PropTypes.string,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
};

RadioSwitch.defaultProps = {
  selectedRadio: "",
  idLeft: "",
  idRight: "",
  name: "",
  value: "",
  labelLeft: "No",
  labelRight: "Yes",
  placeholder: "",
  type: "radio",
  validators: [],
};

export default RadioSwitch;
